import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Tooltip,
  Legend,
  Label,
  Line,
  ResponsiveContainer,
} from 'recharts';

const VerticalLineChartComponent = (props) => {
  const {
    data, dataKey, yAxisLabel, showToolTip, showLegend,
  } = props;
  return (
    <ResponsiveContainer width="80%" aspect={4.0 / 2.5}>
      <LineChart>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={dataKey}
          type={dataKey}
          stroke="#444444"
          fontSize="80%"
          tickLine={false}
          allowDuplicatedCategory={false}
        />
        <YAxis stroke="#444444" fontSize="80%" tickLine={false}>
          <Label
            value={yAxisLabel}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle', fontSize: '80%', fill: '#bababa' }}
          />
        </YAxis>
        {showToolTip && <Tooltip />}
        {showLegend && <Legend />}
        {data.map(s => (
          <Line
            dataKey="value"
            data={s.data}
            name={s.name}
            key={s.name}
            stroke={s.color}
            dot={{ r: 0 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default VerticalLineChartComponent;
