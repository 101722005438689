import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getInsightPoster } from '../../Actions/taggingAction';
import Loader from '../common/customLoader';
import TaggingFixedPanel from './common/taggingFixedPanel';
import PosterInput from './posterInput';
import '../../css/common.scss';
import '../../css/common/responsive.scss';
import '../../css/tagging/main.scss';
import '../../css/tagging/insights.scss';

class SelectPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMe: null,
      choosePoster: false,
      error: '',
    };
  }

  componentDidMount() {
    const { fetchInsightsPoster } = this.props;
    const data = {};
    data.insightID = '1';
    fetchInsightsPoster(data);
  }

  posterEvent = (id) => {
    const { activeMe } = this.state;
    if (activeMe === id) {
      this.setState({
        activeMe: null,
      });
    } else {
      this.setState({ activeMe: id });
    }
  };

  handleChoosePoster = () => {
    const { activeMe } = this.state;
    let error = '';
    let check = true;
    if (activeMe === null) {
      error = 'Please choose poster';
      check = false;
    }
    if (check) {
      this.setState({
        choosePoster: true,
        error: '',
      });
    } else {
      console.log('error', error);
      // poster selection not mandatory
      this.setState({
        choosePoster: true,
        error: '',
      });
    }
  };

  handleBack = () => {
    this.setState({
      choosePoster: false,
    });
  };

  render() {
    const { activeMe, choosePoster, error } = this.state;
    const {
      posterListData, isLoading, calledFrom, anotherTab,
    } = this.props;
    return (
      <div className="root scrollableTagging">
        {choosePoster === false ? (
          <Grid container className="taggingMain" spacing={2}>
            <Grid item lg={9} sm={9} xs={12} className="insightLeftPanel">
              <h5>Tagging</h5>
              {calledFrom ? (
                <ul className="cardHolder">
                  <li>
                    <i className="cardCount">15</i>
                  </li>
                  <li>
                    <h5>Send pesonalize posters to your farmers</h5>
                    <p className="labelList">
                      <span className="greenTxt">Recomended</span>
                    </p>
                  </li>
                </ul>
              ) : null}
              <div className="posterWrap cardDetails card-panel">
                <h6>Select poster from below</h6>
                <ul className="posterList">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    posterListData.map(list => (
                      <li key={Math.random()}>
                        <div
                          className={
                            list === activeMe ? 'plItem selected' : 'plItem'
                          }
                          onClick={() => this.posterEvent(list)}
                          id={list.posterImageID}
                          role="button"
                          tabIndex="0"
                          onKeyDown={() => this.posterEvent(list)}
                        >
                          <img
                            src={list.mediaLocation}
                            alt={list.posterImageID}
                          />
                          <span />
                        </div>
                      </li>
                    ))
                  )}
                </ul>
                <ul className="posterBtm">
                  <li>
                    <Button
                      type="button"
                      className="thinBtn"
                      onClick={this.handleChoosePoster}
                    >
                      choose posters
                    </Button>
                    {error ? (
                      <p className="errorMsg1">
                        {' '}
                        {error}
                        {' '}
                      </p>
                    ) : null}
                  </li>
                </ul>
                <div className="disclaimer">
                  <p>
                    <span>Disclaimer: </span>
                    {' '}
Poster selection is not compulsory
                    you can continue without selecting any poster.
                  </p>
                </div>
              </div>
            </Grid>
            <TaggingFixedPanel />
          </Grid>
        ) : (
          <PosterInput
            selectedPoster={activeMe}
            handleBack={this.handleBack}
            calledFrom={calledFrom}
            anotherTab={anotherTab}
          />
        )}
      </div>
    );
  }
}
export default withRouter(
  connect(
    ({ tagging }) => ({
      isLoading: tagging.isLoading,
      posterListData: tagging.posterListData,
    }),
    dispatch => ({
      fetchInsightsPoster: data => dispatch(getInsightPoster(data)),
    }),
  )(withCookies(SelectPoster)),
);
