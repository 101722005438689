/* eslint-disable no-param-reassign */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MultiSelect from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import {
  Select,
  FormControl,
  MenuItem,
  Grid,
  Switch,
  Button,
} from '@material-ui/core';
import { config } from '../../../config/index';
import Loader from '../../common/customLoader';
import LineChartComponent from '../lineChart';
import DateRange from '../dateRange';
// import AreaChartComponent from '../areaChart';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
// import TextCarousel from '../../common/textCarousel';
import '../../../css/agricultureIndices.scss';
import {
  updateBoundsAction,
  updateBoundsComparisionAction,
  resetNDVIAction,
  getSourceAction,
  getIndicesUpdateAction,
  fetchIndicesImagesAct,
  clearIndicesImagesAct,
  fetchCompareIndicesImagesAct,
  clearCompareIndicesImagesAct,
  getTimeSeriesDataAct,
  getTimeSeriesChangeDataAct,
  getIndicesLegends,
} from '../../../Actions/agricultureIndicesAction';
// import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import { TextWithGraphCarousel } from '../../common/TextCarouselHOC';

let indicesOptions = [];

class AgricultureIndicesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // value: '',
      NDVIDATA: [],
      satellitesSource: '',
      indicesValue: [],
      range: 'median',
      startDate: moment('2019-6-10'),
      endDate: moment('2019-10-15'),
      indicesComparision: false,
      startDateComparision: null,
      endDateComparision: null,
      indicesValueComparision: [],
      NDVIDATAComparision: [],
      rangeComparision: 'median',
      isGenerate: false,
      error: '',
      indexData: [],
      indexDataComparision: [],
      expandPanel: false,
      indicesTimeLineError: '',
      lFlag: false,
      satFlag: false,
    };
    this.updateDates = this.updateDates.bind(this);
  }

  componentDidMount() {
    const {
      getSatSource, updateBounds, bounds, selectedFarm,
    } = this.props;
    const {
      startDate, endDate, indicesValue, satellitesSource,
    } = this.state;
    getSatSource();
    // debugger;
    updateBounds(
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      selectedFarm,
    );
  }

  componentDidUpdate(prevProps) {
    const {
      agricultureIndicesTimeline,
      agricultureIndicesTimelineComparison,
      location,
      indicesTimeLineError,
      // indicesImages,
      getMapReference,
      selectedTimelineDate,
      indexToView,
      getTimeSeriesData,
      getTimeSeriesChangeData,
      indicesTimeline,
      indicesTimelineComparison,
      satSource,
      bounds,
      indicesCompare,
      updateBounds,
      selectedFarm,
    } = this.props;

    const { satellitesSource, startDate, endDate } = this.state;

    const { indicesValue, lFlag, satFlag } = this.state;
    if (satSource.length && !satFlag) {
      this._handleSatelliteSource({ target: { value: 'S2' } }, true);
      this._handleIndices([{ label: 'NDVI', value: 'ndvi' }], true);
      this.updateFlag('satFlag', true);
      updateBounds(
        bounds,
        [{ label: 'NDVI', value: 'ndvi' }],
        'S2',
        startDate,
        endDate,
        selectedFarm,
      );
    }
    if (
      !_.isEqual(
        prevProps.agricultureIndicesTimeline,
        agricultureIndicesTimeline,
      )
    ) {
      let NDVIDATA = [];
      if (agricultureIndicesTimeline.length) {
        NDVIDATA = agricultureIndicesTimeline;
      }
      let indexData = [];
      NDVIDATA.forEach((item) => {
        // for (const key in item) {
        //   if (key.indexOf('index') !== -1 && indexData.indexOf(key) === -1) {
        //     indexData.push(key);
        //   }
        // }
        Object.keys(item).forEach((key) => {
          if (key.indexOf('index') !== -1 && indexData.indexOf(key) === -1) {
            indexData.push(key);
          }
        });
      });
      indexData = indexData.map(item => item.split('-')[0]);
      this._updateNDVIDATA(NDVIDATA, indexData);
    }

    if (!_.isEqual(prevProps.indicesTimeline, indicesTimeline)) {
      const data = indicesTimeline;
      const filteredData = {};
      indicesValue.forEach((item) => {
        if (data[item.value]) {
          filteredData[item.value] = data[item.value];
        }
      });

      const keysOfiltered = Object.keys(filteredData);
      let mainData = {};
      keysOfiltered.forEach((item) => {
        const firstLoopData = filteredData[item];
        Object.keys(firstLoopData).forEach((inItem) => {
          const inItemData = firstLoopData[inItem];
          inItemData.forEach((doubleInItem) => {
            if (!mainData[doubleInItem.date]) {
              mainData[doubleInItem.date] = {};
              mainData[doubleInItem.date].date = doubleInItem.date;
              mainData[doubleInItem.date][`${item}-index`] = item;
              mainData[doubleInItem.date][`${item}-${inItem}`] = doubleInItem.value;
            } else {
              mainData[doubleInItem.date].date = doubleInItem.date;
              mainData[doubleInItem.date][`${item}-index`] = item;
              mainData[doubleInItem.date][`${item}-${inItem}`] = doubleInItem.value;
            }
          });
        });
      });

      mainData = Object.values(mainData);

      let NDVIDATA = [];
      if (mainData.length) {
        NDVIDATA = mainData;
      }
      let indexData = [];
      NDVIDATA.forEach((item) => {
        // for (const key in item) {
        //   if (key.indexOf('index') !== -1 && indexData.indexOf(key) === -1) {
        //     indexData.push(key);
        //   }
        // }
        Object.keys(item).forEach((key) => {
          if (key.indexOf('index') !== -1 && indexData.indexOf(key) === -1) {
            indexData.push(key);
          }
        });
      });
      indexData = indexData.map(item => item.split('-')[0]);
      this._updateNDVIDATA(NDVIDATA, indexData);
    }
    if (
      !_.isEqual(prevProps.indicesTimelineComparison, indicesTimelineComparison)
    ) {
      const { indicesValueComparision } = this.state;
      const data = indicesTimelineComparison;
      this._updateNDVIDATAComparisionNew(data, indicesValueComparision);
    }
    if (
      !_.isEqual(
        prevProps.agricultureIndicesTimelineComparison,
        agricultureIndicesTimelineComparison,
      )
    ) {
      let NDVIDATAComparision = [];
      if (agricultureIndicesTimelineComparison.length) {
        NDVIDATAComparision = agricultureIndicesTimelineComparison;
      }
      let indexDataComparision = [];
      NDVIDATAComparision.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (
            key.indexOf('index') !== -1
            && indexDataComparision.indexOf(key) === -1
          ) {
            indexDataComparision.push(key);
          }
        });
      });
      indexDataComparision = indexDataComparision.map(
        item => item.split('-')[0],
      );
      this._updateNDVIDATAComparision(
        NDVIDATAComparision,
        indexDataComparision,
      );
    }
    if (
      location.selectedTehsilID === '76008ce3-bb1f-4ce1-a685-7b3060577e6c'
      && !lFlag
    ) {
      this.updateFlag('lFlag', true);
    }

    if (location.selectedTehsilID !== prevProps.location.selectedTehsilID) {
      let today = new Date();
      let priorDate = new Date().setDate(today.getDate() - 90);
      priorDate = new Date(priorDate);
      today = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
      priorDate = `${priorDate.getFullYear()}-${priorDate.getMonth()}-${priorDate.getDate()}`;
      const data = {
        tehsilID: location.selectedTehsilID,
        startDate: priorDate,
        endDate: today,
        source: satellitesSource,
      };
      getTimeSeriesData(data);
      getTimeSeriesChangeData({
        tehsilID: location.selectedTehsilID,
        startDate: today,
      });
    }

    if (
      indicesTimeLineError !== prevProps.indicesTimeLineError
      && indicesTimeLineError
    ) {
      this.updateError(indicesTimeLineError);
    }

    /* if (prevProps.indicesImages.length === 0 && indicesImages.length !== 0) {
      // Add First Image
      const map = getMapReference();
      const firstObj = indicesImages[0];
      if (
        firstObj
        && indexToView !== ''
        && firstObj[`${indexToView}-imageUrl`]
      ) {
        if (map.getSource('imageSource')) {
          map.getSource('imageSource').updateImage({
            url: firstObj[`${indexToView}-imageUrl`],
            coordinates: [
              [firstObj.bbox[0], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[1]],
              [firstObj.bbox[0], firstObj.bbox[1]],
            ],
          });
        } else {
          map.addSource('imageSource', {
            type: 'image',
            url: firstObj[`${indexToView}-imageUrl`],
            coordinates: [
              [firstObj.bbox[0], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[1]],
              [firstObj.bbox[0], firstObj.bbox[1]],
            ],
          });
          map.addLayer(
            {
              id: 'overlay',
              source: 'imageSource',
              type: 'raster',
              paint: { 'raster-opacity': 1 },
            },
            'clickedFarm',
          );
        }
      }
    }
    if (prevProps.indicesImages.length !== 0 && indicesImages.length === 0) {
      // Remove Image
      const map = getMapReference();
      if (map.getSource('imageSource')) {
        map.removeLayer('overlay');
        map.removeSource('imageSource');
      }
<<<<<<< HEAD
<<<<<<< HEAD
    }
    if (
=======
    } */

    /* if (
>>>>>>> a2620c3a73dd3e99d982f1773ddc1e4763a3ae70
=======
    } */

    /* if (
>>>>>>> 11571062877ddfa576af2652655a3cecf24f21bc
      prevProps.selectedTimelineDate !== selectedTimelineDate
      && selectedTimelineDate !== ''
    ) {
      // The Date Timeline is updated in Dashboard
      // console.log('Date Changed');
      console.log(
        'DATE CHANGED FROM TIMELINE COMPONENT ',
        selectedTimelineDate,
      );
      const map = getMapReference();
      const dataObj = indicesImages.find(o => o.date === selectedTimelineDate);
      if (dataObj) {
        if (indexToView !== '' && dataObj[`${indexToView}-imageUrl`]) {
          if (map.getSource('imageSource')) {
            map.getSource('imageSource').updateImage({
              url: dataObj[`${indexToView}-imageUrl`],
            });
          } else {
            map.addSource('imageSource', {
              type: 'image',
              url: dataObj[`${indexToView}-imageUrl`],
              coordinates: [
                [dataObj.bbox[0], dataObj.bbox[3]],
                [dataObj.bbox[2], dataObj.bbox[3]],
                [dataObj.bbox[2], dataObj.bbox[1]],
                [dataObj.bbox[0], dataObj.bbox[1]],
              ],
            });
            map.addLayer({
              id: 'overlay',
              source: 'imageSource',
              type: 'raster',
              paint: { 'raster-opacity': 1 },
            });
          }

          /!* if (this.mapContainer.getLayer('indices-tiles')) {
           this.mapContainer.removeLayer('indices-tiles');
           this.mapContainer.removeSource('indices-tiles');
           this.mapContainer.addLayer({
           id: 'indices-tiles',
           type: 'raster',
           source: {
           type: 'raster',
           scheme: 'xyz',
           tiles: [
           `https://kr9naia1o3.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server/{z}/{x}/{y}?index=${indexToView}&cmap=green&min=-1&max=1&tilesize=256&scale=2&satellite=${satellitesSource}&date=${dataObj.date}`,
           ],
           bounds,
           },
           paint: {},
           }, 'level3Fill');
           } else {
           this.mapContainer.addLayer({
           id: 'indices-tiles',
           type: 'raster',
           source: {
           type: 'raster',
           scheme: 'xyz',
           tiles: [
           `https://kr9naia1o3.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server/{z}/{x}/{y}?index=${indexToView}&cmap=green&min=-1&max=1&tilesize=256&scale=2&satellite=${satellitesSource}&date=${dataObj.date}`,
           ],
           bounds,
           },
           paint: {},
           }, 'level3Fill');
           } *!/
        }
      }
    }
    if (prevProps.indexToView !== indexToView) {
      /!*
      * Index Changed from Dropdown
        Set selectedDateTimelineDate to Empty String on Dropdown Change
        to prevent above block functioning
      * *!/
      const map = getMapReference();
      // Get First Date Object
      const firstObj = indicesImages[0];
      if (
        firstObj
        && indexToView !== ''
        && firstObj[`${indexToView}-imageUrl`]
      ) {
        if (map.getSource('imageSource')) {
          map.getSource('imageSource').updateImage({
            url: firstObj[`${indexToView}-imageUrl`],
            coordinates: [
              [firstObj.bbox[0], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[1]],
              [firstObj.bbox[0], firstObj.bbox[1]],
            ],
          });
        } else {
          map.addSource('imageSource', {
            type: 'image',
            url: firstObj[`${indexToView}-imageUrl`],
            coordinates: [
              [firstObj.bbox[0], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[3]],
              [firstObj.bbox[2], firstObj.bbox[1]],
              [firstObj.bbox[0], firstObj.bbox[1]],
            ],
          });
          map.addLayer(
            {
              id: 'overlay',
              source: 'imageSource',
              type: 'raster',
              paint: { 'raster-opacity': 1 },
            },
            'clickedFarm',
          );
        }
      }
    } */

    if (
      prevProps.selectedTimelineDate !== selectedTimelineDate
      && selectedTimelineDate !== ''
    ) {
      // Date Changed Set the Raster Layer Here
      if (!indicesCompare) {
        const map = getMapReference();
        if (map) {
          if (map.getLayer('wms')) {
            map.removeLayer('wms');
            map.removeSource('wms');
          }
          map.addLayer(
            {
              id: 'wms',
              type: 'raster',
              // minzoom: 6,
              source: {
                type: 'raster',
                scheme: 'xyz',
                tiles: [
                  `${
                    config.tileServerAPI
                  }/{z}/{x}/{y}?index=${indexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedTimelineDate}`,
                ],
                // bounds: bounds || [],
              },
              paint: {},
            },
            'level2Fill',
          );
        }
      }
    }

    if (!_.isEmpty(prevProps.indicesTimeline) && _.isEmpty(indicesTimeline)) {
      // console.log('Empty Data Remove Layer Here');
      if (!indicesCompare) {
        const map = getMapReference();
        if (map) {
          if (map.getLayer('wms')) {
            map.removeLayer('wms');
            map.removeSource('wms');
          }
        }
      }
    }
    if (
      prevProps.indexToView !== indexToView
      && prevProps.indexToView !== ''
      && indexToView !== ''
    ) {
      // Index To View is Changed from the DropDown in Dashboard
      const map = getMapReference();
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
        map.addLayer(
          {
            id: 'wms',
            type: 'raster',
            // minzoom: 6,
            source: {
              type: 'raster',
              scheme: 'xyz',
              tiles: [
                `${
                  config.tileServerAPI
                }/{z}/{x}/{y}?index=${indexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedTimelineDate}`,
              ],
              // bounds: bounds || [],
            },
            paint: {},
          },
          'level2Fill',
        );
      }
    }
  }

  updateFlag = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  _updateNDVIDATAComparisionNew = (data, indicesValueComparision) => {
    const filteredData = {};
    indicesValueComparision.forEach((item) => {
      if (data[item.value]) {
        filteredData[item.value] = data[item.value];
      }
    });

    const keysOfiltered = Object.keys(filteredData);
    let mainData = {};
    keysOfiltered.forEach((item) => {
      const firstLoopData = filteredData[item];
      Object.keys(firstLoopData).forEach((inItem) => {
        const inItemData = firstLoopData[inItem];
        inItemData.forEach((doubleInItem) => {
          if (!mainData[doubleInItem.date]) {
            mainData[doubleInItem.date] = {};
            mainData[doubleInItem.date].date = doubleInItem.date;
            mainData[doubleInItem.date][`${item}-index`] = item;
            mainData[doubleInItem.date][`${item}-${inItem}`] = doubleInItem.value;
          } else {
            mainData[doubleInItem.date].date = doubleInItem.date;
            mainData[doubleInItem.date][`${item}-index`] = item;
            mainData[doubleInItem.date][`${item}-${inItem}`] = doubleInItem.value;
          }
        });
      });
    });
    // console.log('filteredData', mainData);

    mainData = Object.values(mainData);

    let NDVIDATAComparision = [];
    if (mainData.length) {
      NDVIDATAComparision = mainData;
    }
    let indexDataComparision = [];
    NDVIDATAComparision.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (
          key.indexOf('index') !== -1
          && indexDataComparision.indexOf(key) === -1
        ) {
          indexDataComparision.push(key);
        }
      });
    });
    indexDataComparision = indexDataComparision.map(item => item.split('-')[0]);
    this._updateNDVIDATAComparision(NDVIDATAComparision, indexDataComparision);
  };

  clearDates = () => {
    // console.log('no dates found!!');
    // const { clearIndicesImages } = this.props;
    // clearIndicesImages();
  };

  _handleSatelliteSource = (ev, noUpdate) => {
    const { indicesValue, startDate, endDate } = this.state;
    const {
      sourceIndice, bounds, selectedFarm, updateBounds,
    } = this.props;
    // bounds, updateBounds,
    Object.keys(sourceIndice).forEach((item) => {
      if (item === ev.target.value) {
        // console.log(item, ev.target.value, sourceIndice[item]);
        indicesOptions = sourceIndice[item];
      }
    });
    this.setState({
      satellitesSource: ev.target.value,
      indicesValue: [],
    });
    if (!noUpdate) {
      updateBounds(
        bounds,
        indicesValue,
        ev.target.value,
        startDate,
        endDate,
        selectedFarm,
      );
    }
  };

  _handleIndices = (value, noUpdate) => {
    const { satellitesSource, startDate, endDate } = this.state;
    const {
      broadcastIndicesSelectChange,
      bounds,
      selectedFarm,
      updateBounds,
    } = this.props;
    // bounds, updateBounds,
    this.setState({
      indicesValue: value,
    });
    if (!noUpdate) {
      updateBounds(
        bounds,
        value,
        satellitesSource,
        startDate,
        endDate,
        selectedFarm,
      );
    }
    if (broadcastIndicesSelectChange && value) {
      broadcastIndicesSelectChange(value);
    }
  };

  _handleIndicesComparision = (value) => {
    // const {
    //   // satellitesSource,
    //   // startDateComparision,
    //   // endDateComparision,
    // } = this.state;

    const {
      indicesTimelineComparison,
      broadcastCompareIndicesSelectChange,
    } = this.props;
    // const { bounds, updateBoundsComparision } = this.props;
    this.setState({
      indicesValueComparision: value,
    });
    if (value) {
      this._updateNDVIDATAComparisionNew(indicesTimelineComparison, value);
    }

    if (broadcastCompareIndicesSelectChange && value) {
      broadcastCompareIndicesSelectChange(value);
    }
    // updateBoundsComparision(
    //   bounds,
    //   value,
    //   satellitesSource,
    //   startDateComparision,
    //   endDateComparision,
    // );
  };

  _hanldeRange = (ev) => {
    this.setState({
      range: ev.target.value,
    });
  };

  _hanldeRangeComparision = (ev) => {
    this.setState({
      rangeComparision: ev.target.value,
    });
  };

  getDate = (startDate, endDate, secondTab) => {
    const {
      bounds,
      updateBounds,
      // fetchIndicesImages,
      selectedFarm,
      location,
      getTimeSeriesData,
    } = this.props;
    const { indicesValue, satellitesSource } = this.state;
    // console.log('startDate', JSON.stringify(startDate));
    // console.log('endDate', JSON.stringify(endDate));
    this.setState({
      startDate,
      endDate,
    });
    if (secondTab) {
      if (selectedFarm) {
        updateBounds(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        );
        // fetchIndicesImages(
        //   bounds,
        //   indicesValue,
        //   satellitesSource,
        //   startDate,
        //   endDate,
        //   selectedFarm,
        // );
      } else {
        updateBounds(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        );
        const data = {
          tehsilID: location.selectedTehsilID,
          startDate,
          endDate,
          source: satellitesSource,
        };
        getTimeSeriesData(data, true);
      }
    }
  };

  getDateComparison = (startDateComparision, endDateComparision) => {
    const {
      compareBounds,
      updateBoundsComparision,
      selectedCompareFarm,
      getTimeSeriesData,
      location,
    } = this.props;
    const { indicesValueComparision, satellitesSource } = this.state;
    this.setState({
      startDateComparision,
      endDateComparision,
    });

    if (selectedCompareFarm) {
      // console.log('SelectedCompare farm ', selectedCompareFarm);
      updateBoundsComparision(
        compareBounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparision,
        endDateComparision,
        selectedCompareFarm,
      );
      // fetchCompareIndicesImages(
      //   compareBounds,
      //   indicesValueComparision,
      //   satellitesSource,
      //   startDateComparision,
      //   endDateComparision,
      //   selectedCompareFarm,
      // );
    } else {
      updateBoundsComparision(
        compareBounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparision,
        endDateComparision,
        selectedCompareFarm,
      );
      const data = {
        tehsilID: location.selectedTehsilID,
        startDate: startDateComparision,
        endDate: endDateComparision,
        source: satellitesSource,
      };
      getTimeSeriesData(data, null, true);
    }
  };

  _handleComparision = name => (event) => {
    this.setState({ [name]: event.target.checked });
    const { broadcastComparison } = this.props;
    if (broadcastComparison) {
      broadcastComparison(event.target.checked);
    }
    const {
      bounds,
      selectedFarm,
      satellitesSource,
      updateBoundsComparision,
      // clearCompareIndicesImages,
      // fetchCompareIndicesImages,
      getTimeSeriesData,
      location,
    } = this.props;

    const { indicesValue, startDate, endDate } = this.state;
    if (event.target.checked) {
      this.setState({
        indicesValueComparision: indicesValue,
        startDateComparision: startDate,
        endDateComparision: endDate,
      });
      const data = {
        tehsilID: location.selectedTehsilID,
        startDate,
        endDate,
        source: satellitesSource,
      };
      getTimeSeriesData(data, null, true);
      updateBoundsComparision(
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        selectedFarm,
      );
      // fetchCompareIndicesImages(
      //   bounds,
      //   indicesValue,
      //   satellitesSource,
      //   '',
      //   '',
      //   selectedFarm,
      // );
    } else {
      this.setState({
        indicesValueComparision: [],
        startDateComparision: '',
        endDateComparision: '',
      });
      updateBoundsComparision([], [], satellitesSource, '', '', '');
      // clearCompareIndicesImages();
    }
  };

  _resetLocation = (ev) => {
    const { resetNDVI, broadcastComparison } = this.props;
    ev.preventDefault();
    this.setState({
      // satellitesSource: '',
      // indicesValue: [],
      indicesValueComparision: [],
      // startDate: '',
      startDateComparision: '',
      // endDate: '',
      endDateComparision: '',
      NDVIDATA: [],
      NDVIDATAComparision: [],
      isGenerate: false,
      indexData: [],
      indexDataComparision: [],
      indicesComparision: false,
      indicesTimeLineError: '',
    });
    resetNDVI();
    if (broadcastComparison) {
      broadcastComparison(false);
    }
  };

  _generateData = (ev) => {
    const {
      satellitesSource,
      indicesValue,
      startDate,
      endDate,
      indicesValueComparision,
      startDateComparision,
      endDateComparision,
    } = this.state;
    const {
      bounds,
      updateBounds,
      // fetchIndicesImages,
      selectedFarm,
      updateBoundsComparision,
      selectedCompareFarm,
      comparison,
      getTimeSeriesData,
      location,
    } = this.props;

    // if (satellitesSource !== 'S2') {
    //   return;
    // }
    ev.preventDefault();
    let error = '';
    let check = true;
    if (!indicesValue.length) {
      check = false;
      error = 'please select Indices from above';
    }
    if (!satellitesSource) {
      check = false;
      error = 'please select Satellites Source from above';
    }

    // if (!bounds.length) {
    //   check = false;
    //   error = 'please select Location from above or from map';
    // }

    if (!location.selectedTehsilID) {
      check = false;
      error = 'please select Location from above or from map';
    }
    if (check) {
      this.setState({
        isGenerate: true,
        error: '',
      });

      if (selectedFarm) {
        updateBounds(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        );
        if (comparison) {
          updateBoundsComparision(
            bounds,
            indicesValueComparision,
            satellitesSource,
            startDateComparision,
            endDateComparision,
            selectedCompareFarm,
          );
        }
      } else {
        const data = {
          tehsilID: location.selectedTehsilID,
          startDate,
          endDate,
          source: satellitesSource,
        };
        getTimeSeriesData(data, true);
        if (comparison) {
          getTimeSeriesData(data, null, true);
        }
      }

      // getIndicesUpdate(satellitesSource, indicesValue, bounds);
    } else {
      this.setState({
        isGenerate: false,
        error,
      });
    }
  };

  _handleNextTextCarousel = (indicesValue) => {
    const { bounds, getIndicesUpdate } = this.props;
    const { satellitesSource } = this.state;

    const arr = [indicesValue];
    getIndicesUpdate(satellitesSource, arr, bounds);
  };

  _updateNDVIDATA(NDVIDATA, indexData) {
    // console.log("NDVI DATA >> >>>>>", NDVIDATA, indexData, this.state);
    // if (indexData.includes('ndvi') && NDVIDATA.length > 0) {
    //   console.log("Modify The NDVI Data");
    //   NDVIDATA = NDVIDATA.filter((item) => {
    //     if (item['ndvi-median'] === 0
    //       && item['ndvi-mode'] === 0
    //       && item['ndvi-mean'] === 0
    //       && item['ndvi-min'] === 0
    //       && item['ndvi-max'] === 0) {
    //       return null;
    //     }
    //     return item;
    //   });
    // }
    // console.log("AFter Modify The NDVI Data ", NDVIDATA);
    this.setState({ NDVIDATA, indexData });
  }

  _updateNDVIDATAComparision(NDVIDATAComparision, indexDataComparision) {
    this.setState({ NDVIDATAComparision, indexDataComparision });
  }

  _getSatelliteSource(value) {
    switch (value) {
      case 'S1':
        return 'Sentinel 1';
      case 'S2':
        return 'Sentinel 2';
      case 'L8':
        return 'Landsat';
      case 'M6':
        return 'MODIS';
      default:
        return null;
    }
  }

  updateError(indicesTimeLineError) {
    this.setState({ indicesTimeLineError });
  }

  updateDates(
    startDate,
    endDate,
    startDateComparision,
    endDateComparision,
    comparison,
  ) {
    if (comparison) {
      if (!startDateComparision && !endDateComparision) {
        this.setState({
          startDateComparision: null,
          endDateComparision: null,
        });
      }
      if (startDateComparision) {
        this.setState({
          startDateComparision,
        });
      }
      if (endDateComparision) {
        this.setState({
          endDateComparision,
        });
      }
    } else {
      if (!startDate && !endDate) {
        this.setState({
          startDate: null,
          endDate: null,
        });
      }
      if (startDate) {
        this.setState({
          startDate,
        });
      }
      if (endDate) {
        this.setState({
          endDate,
        });
      }
    }
  }

  // onExpandClick = () => {
  //   const { expandPanel } = this.state;
  //   this.setState({
  //     expandPanel: !expandPanel,
  //   });
  // };

  // downloadMap = (e) => {
  //   // e.preventDefault();
  //   const { getMapReference } = this.props;
  //   const map = getMapReference();
  //   if (map) {
  //     const url = map.getCanvas().toDataURL('image/png');
  //     const img = '<img src="'+url+'">';
  //     const popup = window.open();
  //     popup.document.write(img);
  //   }
  // }

  render() {
    const {
      NDVIDATA,
      satellitesSource,
      indicesValue,
      range,
      indicesComparision,
      NDVIDATAComparision,
      indicesValueComparision,
      rangeComparision,
      isGenerate,
      error,
      indexData,
      indexDataComparision,
      expandPanel,
      indicesTimeLineError,
      startDate,
      endDate,
      startDateComparision,
      endDateComparision,
      lFlag,
    } = this.state;
    const {
      broadcastLocationChange,
      bounds,
      satSource,
      // indicesChange,
      levelStatsFetching,
      timeseriesData,
      timeseriesDataChange,
      fitLevelBounds,
      mapLoaded,
      location,
    } = this.props;

    const {
      state,
      district,
      tehsil,
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
    } = location;

    let statename = '';
    let districtname = '';
    let tehsilname = '';
    const stateObj = state.find(o => o.stateID === selectedStateID);
    if (stateObj) {
      const { stateName } = stateObj;
      statename = stateName;
    }
    const districtObj = district.find(o => o.districtID === selectedDistrictID);
    if (districtObj) {
      const { districtName } = districtObj;
      districtname = districtName;
    }
    const tehsilObj = tehsil.find(o => o.tehsilID === selectedTehsilID);
    if (tehsilObj) {
      const { tehsilName } = tehsilObj;
      tehsilname = tehsilName;
    }
    const dateRange = (
      <DateRange
        getDate={this.getDate}
        clearDates={this.clearDates}
        startDate={startDate}
        endDate={endDate}
        updateDates={this.updateDates}
      />
    );

    const textCarouselData = [];
    const timeSeriesDataChangeObj = timeseriesDataChange.status
      ? timeseriesDataChange.data
      : {};
    const timeSeriesDataObj = timeseriesData.status ? timeseriesData.data : {};
    if (!_.isEmpty(timeSeriesDataChangeObj)) {
      Object.keys(timeSeriesDataChangeObj).forEach((item) => {
        const keyFound = indicesOptions.find(o => o.value === item);
        if (keyFound) {
          let graphData = [];
          if (!_.isEmpty(timeSeriesDataObj)) {
            const dataKey = timeSeriesDataObj[item];
            if (!_.isEmpty(dataKey)) {
              if (dataKey.mean) {
                const { mean } = dataKey;
                graphData = mean;
              }
            }
          }
          const newObj = {
            label: item,
            value: timeSeriesDataChangeObj[item].value,
            graphData,
            xAxisDataKey: 'date',
            yAxisDataKey: 'value',
            alertData: 'hello',
            updatedSinceString: 'Since past 15 days',
            isFooterDataAvailable: false,
            footerData: [],
          };
          textCarouselData.push(newObj);
        }
      });
    }

    return (
      <div>
        {bounds
        && satellitesSource
        && indicesValue
        && indicesValue.length
        && isGenerate ? (
          <div className="indicesMainPanel">
            <div
              className={
                expandPanel ? 'indicesList expandPanel' : 'indicesList'
              }
            >
              {/* <div className=""> */}
              <h4>
                Indices report
                <a href="/" onClick={this._resetLocation}>
                  Edit Selection
                </a>
              </h4>
              <ul>
                <li>{statename}</li>
                <li>{districtname}</li>
                <li>{tehsilname}</li>
              </ul>
              <ul>
                <li>{this._getSatelliteSource(satellitesSource)}</li>
                <li>{indicesValue.map(item => `${item.label} `)}</li>
              </ul>
              {/* </div> */}
              {/* <div className="expandPanelIcon" onClick={this.onExpandClick}>
                {
                  expandPanel ?
                  <i className="material-icons">keyboard_arrow_down</i> :
                  <i className="material-icons">keyboard_arrow_up</i>
                }
              </div> */}

              <TextWithGraphCarousel data={textCarouselData} autoPlaySteps />

              {/* <div className="indicesFixedLink">
                Target farmers in the region through farmguide
                <Link to="/">Lead Generator</Link>
              </div> */}
            </div>
            {!levelStatsFetching ? (
              <div className="indicesScrollableLink">
                <div className="indicesScrollBlock selectController">
                  {!indicesTimeLineError ? (
                    <React.Fragment>
                      <DateRange
                        getDate={this.getDate}
                        clearDates={this.clearDates}
                        startDate={startDate}
                        endDate={endDate}
                        updateDates={this.updateDates}
                        secondTab
                      />
                      <FormControl className="formControl inputHead coloredForms modeSec">
                        <Select
                          name="dashboard-range"
                          inputProps={{ 'aria-label': 'age' }}
                          className="tableDropdown"
                          onChange={this._hanldeRange}
                          value={range}
                        >
                          <MenuItem value="mean">Mean</MenuItem>
                          <MenuItem value="mode">Mode</MenuItem>
                          <MenuItem value="median">Median</MenuItem>
                          <MenuItem value="max">Max</MenuItem>
                          <MenuItem value="min">Min</MenuItem>
                        </Select>
                      </FormControl>
                      <LineChartComponent
                        data={NDVIDATA}
                        yAxisLabel="Indices Values"
                        dataKey={`${indexData[0]}-${range}`}
                        xAxisDataKey="date"
                        compareChart={indexData.length > 1 ? indexData : false}
                        range={range}
                      />
                    </React.Fragment>
                  ) : (
                    <div className="notfound">
                      <img
                        src="../images/notFound.svg"
                        alt="not-found"
                        title="not-found"
                      />
                      <h2>
                        Oops… Please try with different location hierarchy and
                        bounds
                      </h2>
                      <i className="material-icons">arrow_downward</i>
                      <Link
                        to="/"
                        className="darkgreenBtn"
                        onClick={this._resetLocation}
                      >
                        Edit
                      </Link>
                    </div>
                  )}

                  <div className="comparisonPanel">
                    Comparison
                    <Switch
                      value="indicesComparision"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={indicesComparision}
                      onChange={this._handleComparision('indicesComparision')}
                    />
                    {indicesComparision ? (
                      <FormControl className="formControl inputHead coloredForms multiSelect half-width">
                        <MultiSelect
                          isMulti
                          name="user"
                          options={indicesOptions}
                          onChange={ev => this._handleIndicesComparision(ev)}
                          value={indicesValueComparision}
                          className="selectInner"
                          classNamePrefix="select"
                          placeholder="Select Multiple"
                        />
                      </FormControl>
                    ) : null}
                  </div>
                  {indicesComparision ? (
                    <React.Fragment>
                      <DateRange
                        getDateComparison={this.getDateComparison}
                        comparison
                        startDate={startDateComparision}
                        endDate={endDateComparision}
                        updateDates={this.updateDates}
                      />
                      <FormControl className="formControl inputHead coloredForms modeSec">
                        <Select
                          name="dashboard-range2"
                          inputProps={{ 'aria-label': 'age' }}
                          className="tableDropdown"
                          onChange={this._hanldeRangeComparision}
                          value={rangeComparision}
                        >
                          <MenuItem value="mean">Mean</MenuItem>
                          <MenuItem value="mode">Mode</MenuItem>
                          <MenuItem value="median">Median</MenuItem>
                          <MenuItem value="max">Max</MenuItem>
                          <MenuItem value="min">Min</MenuItem>
                        </Select>
                      </FormControl>
                      <LineChartComponent
                        data={NDVIDATAComparision}
                        yAxisLabel="Indices Values"
                        dataKey={`${
                          indexDataComparision[0]
                        }-${rangeComparision}`}
                        xAxisDataKey="date"
                        compareChart={
                          indexDataComparision.length > 1
                            ? indexDataComparision
                            : false
                        }
                        range={rangeComparision}
                      />
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
          ) : (
            <div className="agriIndices selectController btn">
              <h4 className="tabpanelHeading">Indices Report</h4>
              <Grid item xs={12}>
                <LocationHeirarchy
                  type="selectEmpty modalDropdown"
                  calledFrom="dashboard"
                  broadcastLocationChange={broadcastLocationChange}
                  hideVillage
                  restrictedStates={['5c77da4f-bc9b-4099-bed7-15e06a45f376']}
                //  restrictedDistricts={[
                //    'efd7a4d0-8c59-4e65-b1d9-5d9bfcc1bd20',
                //    '6daa44c2-be7d-4359-89ca-04e7213361e2',
                //    '8aae7e12-2fb2-49a2-a58f-b54550ed3c6d',
                //    '34ac5d8b-f49b-40ac-8e84-331c8c7adfd7',
                //    'd14c2906-e134-422f-941c-ac25b0ecf3bd',
                //  ]}
                  agriIndices
                  lFlag={lFlag}
                  fitLevelBounds={fitLevelBounds}
                  mapLoaded={mapLoaded}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h4 className="tabpanelHeading">SOURCE</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="formControl inputHead coloredForms inputFullWidth">
                    <Select
                      disableUnderline
                      name="dashboard-user"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={satellitesSource}
                      displayEmpty
                      onChange={ev => this._handleSatelliteSource(ev)}
                    >
                      <MenuItem value="" disabled>
                      Satellites
                      </MenuItem>
                      {satSource.map(item => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl className="formControl inputHead coloredForms multiSelect full-width">
                    <MultiSelect
                      isMulti
                      name="user"
                      options={indicesOptions}
                      onChange={ev => this._handleIndices(ev)}
                      value={indicesValue}
                      className="selectInner"
                      classNamePrefix="select"
                      placeholder="Select Multiple"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {dateRange}
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-success floatRight"
                    style={{ marginTop: '10px' }}
                    onClick={this._generateData}
                  >
                  Generate Data
                    <i className="material-icons">arrow_right_alt</i>
                  </Button>
                  {error ? (
                    <p className="errorMsg">
                      {' '}
                      {error}
                      {' '}
                    </p>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ agricultureIndices, location, landing }) => ({
      agricultureIndicesTimeline: agricultureIndices.agricultureIndicesTimeline,
      bounds: agricultureIndices.bounds,
      indicesValue: agricultureIndices.indicesValue,
      compareBounds: agricultureIndices.compareBounds,
      selectedCompareFarm: agricultureIndices.selectedCompareFarm,
      location,
      agricultureIndicesTimelineComparison:
        agricultureIndices.agricultureIndicesTimelineComparison,
      satSource: agricultureIndices.satSource,
      satellitesSource: agricultureIndices.satellitesSource,
      sourceIndice: agricultureIndices.sourceIndice,
      indicesChange: agricultureIndices.indicesChange,
      selectedFarm: agricultureIndices.selectedFarm,
      indicesImages: agricultureIndices.indicesImages,
      indicesTimeLineError: agricultureIndices.indicesTimeLineError,
      levelStatsFetching: landing.levelStatsFetching,
      timeseriesData: agricultureIndices.timeseriesData,
      timeseriesDataChange: agricultureIndices.timeseriesDataChange,
      indicesTimeline: agricultureIndices.indicesTimeline,
      indicesTimelineComparison: agricultureIndices.indicesTimelineComparison,
    }),
    dispatch => ({
      updateBounds: (
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        farmid,
      ) => {
        dispatch(
          updateBoundsAction(
            bounds,
            indicesValue,
            satellitesSource,
            startDate,
            endDate,
            farmid,
          ),
        );
      },
      updateBoundsComparision: (
        bounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparision,
        endDateComparision,
      ) => {
        dispatch(
          updateBoundsComparisionAction(
            bounds,
            indicesValueComparision,
            satellitesSource,
            startDateComparision,
            endDateComparision,
          ),
        );
      },
      resetNDVI: () => dispatch(resetNDVIAction()),
      getIndicesUpdate: (satellitesSource, indicesValue, bounds) => dispatch(
        getIndicesUpdateAction(satellitesSource, indicesValue, bounds),
      ),
      getSatSource: () => dispatch(getSourceAction()),
      fetchIndicesImages: (
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        selectedFarm,
      ) => {
        dispatch(
          fetchIndicesImagesAct(
            bounds,
            indicesValue,
            satellitesSource,
            startDate,
            endDate,
            selectedFarm,
          ),
        );
      },
      clearIndicesImages: () => dispatch(clearIndicesImagesAct()),
      fetchCompareIndicesImages: (
        compareBounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparison,
        endDateComparison,
        selectedCompareFarm,
      ) => {
        dispatch(
          fetchCompareIndicesImagesAct(
            compareBounds,
            indicesValueComparision,
            satellitesSource,
            startDateComparison,
            endDateComparison,
            selectedCompareFarm,
          ),
        );
      },
      clearCompareIndicesImages: () => dispatch(clearCompareIndicesImagesAct()),
      getTimeSeriesData: (data, flag, flag1) => dispatch(getTimeSeriesDataAct(data, flag, flag1)),
      getTimeSeriesChangeData: data => dispatch(getTimeSeriesChangeDataAct(data)),
      getLegendsData: indice => dispatch(getIndicesLegends(indice, false)),
    }),
  )(withCookies(AgricultureIndicesSelect)),
);
