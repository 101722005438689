import React, { Component } from 'react';
import {
  Grid,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import wkx from 'wkx';
import bbox from '@turf/bbox';
import center from '@turf/center';
import _ from 'lodash';
import { config } from '../../config/index';
import DashboardSidePanel from '../common/dashboardSidePannel';
// import DashboardLeftPanel from './dashboardLeftPanel';
import FarmEngineMap from '../farmEngine/farmEngineMap';
import { getCropsRegionWiseAct } from '../../Actions/landingPageAction';
import { getHierarchyDataAct } from '../../Actions/locationHierarchyAction';
import { fitLevelBounds } from '../../Functions/helper';
import {
  updateBoundsAction,
  resetNDVIAction,
  getIndicesLegends,
  clearIndicesLegends,
} from '../../Actions/agricultureIndicesAction';
import {
  resetWeatherSourceDetails,
  clearIndicesSmapHeatMap,
} from '../../Actions/weatherAction';
import { resetSurveyTab } from '../../Actions/surveyAction';
import { loaderStart, loaderStop } from '../../Actions/commonActions';
import Windy from './weather/windy';
import LanguageContext from '../common/languageContext';
import AgricultureIndices from './agricultureIndices/agricultureIndices';
import Weather from './weather/index';
import YieldProcessing from './yieldProcessing/index';
import FarmTagSelectTab from './farmTags/index';
import Coverage from './coverage/index';
import Crop from './crop/index';
import Mandi from './mandi/index';
import Survey from './survey/index';
import ScrollableTabPanel from '../common/ScrollableTabPanel';
import IndicesCompare from './indicesCompare/indicesCompare';
import MyAccount from './myAccounts/index';
import '../../css/common.scss';
import '../../css/button.scss';
import '../../css/rightPanel.scss';
import '../../css/gis/gis.scss';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
// const SampleNextArrow = (props) => {
//   const { style, className, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style }}
//       onClick={onClick}
//       aria-controls="btn"
//     />
//   );
// };

// const SamplePrevArrow = (props) => {
//   const { style, className, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style }}
//       onClick={onClick}
//       aria-controls="btn"
//     />
//   );
// };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandPanel: false,
      activeTab: 0,
      windyLat: 26.684,
      windyLng: 73.878,
      windyZoom: 5,
      sourceType: '',
      timelineOptions: [],
      comparetimelineOptions: [],
      selectedTimelineDate: '',
      indexToView: '',
      selectedCompareTimelineDate: '',
      compareIndexToView: '',
      indicesCompare: false,
      currentIndex: 0,
      selectedNOAADataFilter: 'mintemp',
      selectedGPMDate: '',
      selectedNOAADate: '',
      selectedCHIRPSDate: '',
      weatherTab: 0,
      selectedSPIYear: '',
      mapLoaded: false,
    };
    this.child = React.createRef();
    this.compareChild = React.createRef();
  }

  componentDidMount() {
    // const { getHierarchyData } = this.props;
    // setTimeout(() => {
    //   getHierarchyData('STATE', null);
    // }, 2000);
  }

  componentDidUpdate(prevProps) {
    const {
      noaaHeatmap,
      gpmHeatmap,
      // indicesImages,
      chirpsHeatmap,
      indicesTimeline,
      indicesTimelineComparison,
      spiHeatmap,
    } = this.props;
    const {
      timelineOptions,
      indexToView,
      compareIndexToView,
      comparetimelineOptions,
    } = this.state;
    // if (prevProps.states.length === 0 && states.length !== 0) {
    //   const state = states.find(
    //     stateItem => stateItem.stateID === '0a5be76c-bdc2-4405-a32c-c46448ff136b',
    //   );
    //   if (state) {
    //     getCropsRegionWise({
    //       fetchLevel: 'STATE',
    //       stateID: state.stateID,
    //       year: '2018',
    //       season: '1',
    //       cropRankingParam: 'ACREAGE',
    //     });
    //     setTimeout(() => {
    //       const map = this.getMapReference();
    //       fitLevelBounds(map, 'STATE', state.bounds);
    //     }, 2000);
    //   }
    // }

    // if (!_.isEqual(prevState.mapLoaded, mapLoaded) && mapLoaded) {
    //   stopCommonLoader();
    // }

    if (_.isEmpty(prevProps.noaaHeatmap) && !_.isEmpty(noaaHeatmap)) {
      // NOAA Data Updated
      // Select First Date with Non Empty Data
      // let firstDate = ''
      const firstDate = Object.keys(noaaHeatmap).find(
        o => !_.isEmpty(noaaHeatmap[o]),
      );
      // if ()
      this.setTimelineDate('NOAA', firstDate);
    }
    if (!_.isEmpty(prevProps.noaaHeatmap) && _.isEmpty(noaaHeatmap)) {
      // NOAA Data Emptied
      // Reset selectedDate
      this.setTimelineDate('NOAA', '');
    }

    if (_.isEmpty(prevProps.gpmHeatmap) && !_.isEmpty(gpmHeatmap)) {
      // GPM Data Updated
      // Select First Date
      const firstDate = Object.keys(gpmHeatmap).find(
        o => !_.isEmpty(gpmHeatmap[o]),
      );
      this.setTimelineDate('GPM', firstDate);
    }
    if (!_.isEmpty(prevProps.gpmHeatmap) && _.isEmpty(gpmHeatmap)) {
      // GPM Data Emptied
      // Reset selectedDate
      this.setTimelineDate('GPM', '');
    }

    /* if (prevProps.indicesImages.length === 0 && indicesImages.length !== 0) {
      // Select First Date
      const firstDate = indicesImages[0].date;
      this.setTimelineDate('indices', firstDate);
    }

    if (prevProps.indicesImages.length !== 0 && indicesImages.length === 0) {
      // Select First Date
      this.setTimelineDate('indices', '');
    }
    */

    if (_.isEmpty(prevProps.chirpsHeatmap) && !_.isEmpty(chirpsHeatmap)) {
      // GPM Data Updated
      // Select First Date
      const firstDate = Object.keys(chirpsHeatmap).find(
        o => !_.isEmpty(chirpsHeatmap[o]),
      );
      this.setTimelineDate('CHIRPS', firstDate);
    }
    if (!_.isEmpty(prevProps.chirpsHeatmap) && _.isEmpty(chirpsHeatmap)) {
      // GPM Data Emptied
      // Reset selectedDate
      this.setTimelineDate('CHIRPS', '');
    }

    if (_.isEmpty(prevProps.indicesTimeline) && !_.isEmpty(indicesTimeline)) {
      // if (indicesTimeline.data) {
      Object.keys(indicesTimeline).forEach((item) => {
        if (
          item === indexToView
          && item[timelineOptions.findIndex(p => p.value === item)]
        ) {
          const data = indicesTimeline[item];
          if (data.mean.length > 0) {
            const firstDate = data.mean[0].date;
            this.setTimelineDate('indices', firstDate);
          }
        }
      });
      // }
    }

    if (!_.isEmpty(prevProps.indicesTimeline) && _.isEmpty(indicesTimeline)) {
      this.setTimelineDate('indices', '');
    }

    if (
      _.isEmpty(prevProps.indicesTimelineComparison)
      && !_.isEmpty(indicesTimelineComparison)
    ) {
      Object.keys(indicesTimelineComparison).forEach((item) => {
        if (
          item === compareIndexToView
          && item[comparetimelineOptions.findIndex(p => p.value === item)]
        ) {
          const data = indicesTimelineComparison[item];
          if (data.mean.length > 0) {
            const firstDate = data.mean[0].date;
            this.setTimelineDate('compareindices', firstDate);
          }
        }
      });
    }

    if (_.isEmpty(prevProps.spiHeatmap) && !_.isEmpty(spiHeatmap)) {
      // SPI Data Updated
      // Select First Year
      const firstObj = Object.values(spiHeatmap).find(o => !_.isEmpty(o));
      this.setTimelineDate('SPI', firstObj.year);
    }
    if (!_.isEmpty(prevProps.spiHeatmap) && _.isEmpty(spiHeatmap)) {
      // SPI Data Emptied
      // Reset selectedDate
      this.setTimelineDate('SPI', '');
    }
  }

  setTimelineDate = (type, value) => {
    if (type === 'NOAA') {
      this.setState({
        selectedNOAADate: value,
      });
    } else if (type === 'GPM') {
      this.setState({
        selectedGPMDate: value,
      });
    } else if (type === 'indices') {
      this.setState({
        selectedTimelineDate: value,
      });
    } else if (type === 'CHIRPS') {
      this.setState({
        selectedCHIRPSDate: value,
      });
    } else if (type === 'SPI') {
      this.setState({
        selectedSPIYear: value,
      });
    } else if (type === 'compareindices') {
      this.setState({
        selectedCompareTimelineDate: value,
      });
    }
  };

  onExpandClick = () => {
    const { expandPanel } = this.state;
    this.setState({
      expandPanel: !expandPanel,
    });
  };

  getMapReference = () => {
    let map;
    if (this.child.current) {
      const { wrappedInstance } = this.child.current;
      if (wrappedInstance) {
        map = wrappedInstance.mapContainer;
      }
    }
    return map;
  };

  getMapClassReference = () => {
    let classRef;
    if (this.child.current) {
      const { wrappedInstance } = this.child.current;
      if (wrappedInstance) {
        classRef = wrappedInstance;
      }
    }
    return classRef;
  };

  broadcastLocationChange = (levelType, levelID) => {
    const {
      states,
      districts,
      tehsils,
      updateBounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      selectedFarm,
    } = this.props;
    let levelData;
    let windyZoom = 5;
    switch (levelType) {
      case 'COUNTRY':
        levelData = { bounds: '' };
        break;

      case 'STATE':
        levelData = states.find(state => state.stateID === levelID);
        windyZoom = 7;
        break;

      case 'DISTRICT':
        levelData = districts.find(district => district.districtID === levelID);
        windyZoom = 9;
        break;

      case 'TEHSIL':
        levelData = tehsils.find(tehsil => tehsil.tehsilID === levelID);
        windyZoom = 10;
        break;

      case 'VILLAGE':
        levelData = { bounds: '' };
        windyZoom = 12;
        break;

      default:
        break;
    }
    const map = this.getMapReference();
    if (levelData && levelData.bounds !== '') {
      fitLevelBounds(map, levelType, levelData.bounds);
      const geom = wkx.Geometry.parse(levelData.bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      updateBounds(
        boundingBox,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        selectedFarm,
      );
      // console.log('levelData.bounds------', boundingBox);
      const { activeTab, sourceType } = this.state;
      if (activeTab === 1 && sourceType === 'Windy') {
        const centerCoord = center(geom);
        // console.log('Center Coord ', centerCoord);
        this.setState({
          windyLat: centerCoord.geometry.coordinates[1],
          windyLng: centerCoord.geometry.coordinates[0],
          windyZoom,
        });
      }
    }
  };

  broadcastTabChange = (tab) => {
    const { activeTab } = this.state;
    this.setState({
      activeTab: tab,
    });
    if (activeTab === 1 && tab !== 1) {
      // Remove Weather Data
      const { resetIMDdetails, resetSmapHeatmap } = this.props;
      resetSmapHeatmap();
      resetIMDdetails();
      const map = this.getMapReference();
      if (map) {
        if (map.getLayer('imdPointsHistorical')) {
          map.removeLayer('imdPointsHistorical');
          map.removeImage('pulsing-historical-dot');
          map.removeSource('imdPointsHistorical');
        }
        if (map.getLayer('imdPoints')) {
          map.removeLayer('imdPoints');
          map.removeImage('pulsing-dot');
          map.removeSource('imdPoints');
        }
        if (map.getSource('smapheatmap')) {
          map.removeLayer('smapLayer');
          map.removeSource('smapheatmap');
        }
        if (map.getSource('gpmheatmap')) {
          map.removeLayer('gpmheatmap');
          map.removeLayer('gpmheatmapline');
          map.removeSource('gpmheatmap');
        }
        if (map.getSource('noaaHeatmap')) {
          map.removeLayer('noaaHeatmap');
          map.removeLayer('noaaheatmapline');
          map.removeSource('noaaHeatmap');
        }
        if (map.getSource('spiheatmap')) {
          map.removeLayer('spiheatmap');
          map.removeLayer('spiheatmapline');
          map.removeSource('spiheatmap');
        }
      }
    }
    if (activeTab === 0 && tab !== 0) {
      const { resetNDVI } = this.props;
      resetNDVI();
      const map = this.getMapReference();
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
      }
      this.setState({
        indicesCompare: false,
      });
    }
    if (activeTab === 5 && tab !== 5) {
      // Remove My Account Farm Boundary
      const map = this.getMapReference();
      if (map) {
        if (map.getLayer('farmaccountBoundary')) {
          map.removeLayer('farmaccountBoundary');
          map.removeLayer('farmaccountBoundaryLine');
          map.removeSource('farmaccountBoundary');
        }
      }
    }
    if (activeTab === 7 && tab !== 7) {
      const { resetSurveyTabFunc } = this.props;
      resetSurveyTabFunc();
      const map = this.getMapReference();
      if (map) {
        if (map.getLayer('surveyExperimentsPoints')) {
          map.removeImage('pulsing-dot-survey');
          map.removeLayer('surveyExperimentsPoints');
          map.removeSource('surveyExperimentsPoints');
        }
      }
    }
    const classRef = this.getMapClassReference();
    const mapStyle = this.getMapStyleForTabs(tab);
    if (classRef) {
      /* switch (tab) {
        case 0:
          classRef.changeMapStyle({
            styleURL: config.mapboxConfig.defaultStyle,
            segmentedView: true,
            cropMapView: false,
          });
          break;
        case 1:
          classRef.changeMapStyle({
            styleURL: config.mapboxConfig.defaultStyle,
            segmentedView: false,
            cropMapView: false,
          });
          break;

        // case 9:
        //   classRef.changeMapStyle({
        //     styleURL: config.mapboxConfig.blankStyle,
        //     segmentedView: false,
        //     cropMapView: true,
        //   });
        //   break;
        default:
          if (activeTab === 0 || activeTab === 1) {
            classRef.changeMapStyle({
              styleURL: config.mapboxConfig.defaultStyle,
              segmentedView: true,
              cropMapView: true,
            });
          }
          break;
      } */
      classRef.changeMapStyle(mapStyle);
    }
  };

  getMapStyleForTabs = (activeTab) => {
    const styles = {
      0: {
        styleURL: config.mapboxConfig.defaultStyle,
        segmentedView: true,
        cropMapView: false,
      },
      1: {
        styleURL: config.mapboxConfig.defaultStyle,
        segmentedView: false,
        cropMapView: false,
      },
      7: {
        styleURL: config.mapboxConfig.defaultStyle,
        segmentedView: false,
        cropMapView: false,
      },
    };

    if (styles[activeTab]) {
      return styles[activeTab];
    }
    return {
      styleURL: config.mapboxConfig.defaultStyle,
      segmentedView: true,
      cropMapView: true,
    };
  };

  broadcastWeatherSourceChange = (sourceType) => {
    console.log('source', sourceType);
    this.setState({
      sourceType,
    });
  };

  broadcastWeatherTabChangeInParent = (tab) => {
    this.setState({
      weatherTab: tab,
    });
  };

  updateIndexToView = (index) => {
    const { getLegendsData } = this.props;
    this.setState({
      indexToView: index,
    });
    getLegendsData(index.toUpperCase(), false);
  };

  updateCompareIndexToView = (index) => {
    const { getLegendsData } = this.props;
    this.setState({
      compareIndexToView: index,
    });
    getLegendsData(index.toUpperCase(), true);
  };

  broadcastComparison = (checked) => {
    // console.log('Comparison >>>>> ', checked);
    const { timelineOptions, indexToView } = this.state;
    const { getLegendsData, clearLegends } = this.props;
    const stateToUpdate = {
      indicesCompare: checked,
    };
    if (checked) {
      stateToUpdate.comparetimelineOptions = timelineOptions;
      stateToUpdate.compareIndexToView = indexToView;
      getLegendsData(indexToView.toUpperCase(), true);
    } else {
      stateToUpdate.comparetimelineOptions = [];
      stateToUpdate.compareIndexToView = '';
      clearLegends(true);
    }
    this.setState(stateToUpdate);
  };

  broadcastIndicesSelectChange = (indices) => {
    const firstIndex = indices[0];
    const indexToView = firstIndex ? firstIndex.value : '';
    const { getLegendsData } = this.props;
    this.setState({
      timelineOptions: indices,
      indexToView,
    });
    getLegendsData(indexToView.toUpperCase(), false);
  };

  broadcastCompareIndicesSelectChange = (indices) => {
    const firstIndex = indices[0];
    const compareIndexToView = firstIndex ? firstIndex.value : '';
    const { getLegendsData } = this.props;
    this.setState({
      comparetimelineOptions: indices,
      compareIndexToView,
    });
    getLegendsData(compareIndexToView.toUpperCase(), true);
  };

  goToPrevDate = (type) => {
    // if (this.state.currentIndex === 0) return;
    console.log('Type ', type);
    const { currentIndex } = this.state;
    const content = type !== 'compareindices'
      ? document.getElementById('content')
      : document.getElementById('content2');
    content.scrollLeft += 100;

    let index = currentIndex;
    if (index < 0) {
      return;
    }
    index -= 1;

    this.setState(
      {
        currentIndex: index,
      },
      () => {
        console.log('currentIndex>>', currentIndex);
      },
    );
  };

  goToNextDate = (type) => {
    // console.log('Type ', type);
    const { currentIndex } = this.state;
    const {
      indicesImages,
      gpmHeatmap,
      noaaHeatmap,
      chirpsHeatmap,
      spiHeatmap,
    } = this.props;
    const content = type !== 'compareindices'
      ? document.getElementById('content')
      : document.getElementById('content2');
    content.scrollLeft -= 100;
    let index = currentIndex;
    if (type === 'gpm') {
      if (index === Object.keys(gpmHeatmap).length - 1) {
        return;
      }
    } else if (type === 'noaa') {
      if (index === Object.keys(noaaHeatmap).length - 1) {
        return;
      }
    } else if (type === 'chirps') {
      if (index === Object.keys(chirpsHeatmap).length - 1) {
        return;
      }
    } else if (type === 'spi') {
      if (index === Object.values(spiHeatmap).length - 1) {
        return;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (index === indicesImages.length - 1) {
        return;
      }
      // eslint-enable-next-line no-lonely-if
    }
    index += 1;
    // if(this.state.currentIndex === dates.length -1) {

    //   return this.setState({
    //     currentIndex: 0
    //   })
    // }
    this.setState({
      currentIndex: index,
    });
  };

  goToSelectedDate = (type, index, date) => {
    // const { currentIndex,  } = this.state;
    // const { indicesImages, gpmHeatmap } = this.props;
    let state = {};
    if (type === 'gpm') {
      state = {
        currentIndex: index,
        selectedGPMDate: date,
      };
    } else if (type === 'noaa') {
      state = {
        currentIndex: index,
        selectedNOAADate: date,
      };
    } else if (type === 'chirps') {
      state = {
        currentIndex: index,
        selectedCHIRPSDate: date,
      };
    } else if (type === 'compareindices') {
      state = {
        currentIndex: index,
        selectedCompareTimelineDate: date,
      };
    } else if (type === 'spi') {
      state = {
        currentIndex: index,
        selectedSPIYear: date,
      };
    } else {
      state = {
        currentIndex: index,
        selectedTimelineDate: date,
      };
    }
    this.setState(state);
  };

  setSegmentProperties = (properties) => {
    this.setState({
      properties,
    });
  };

  fitLevelBounds = (levelType, bounds) => {
    const map = this.getMapReference();
    if (map) {
      if (levelType === 'COUNTRY') {
        map.flyTo({
          center: config.mapboxConfig.defaultCenter,
          zoom: config.mapboxConfig.defaultZoom,
        });
      } else if (typeof bounds === 'object' && bounds) {
        if (bounds.length > 0) {
          map.fitBounds(bounds, { padding: 20 });
        }
      } else if (typeof bounds === 'string') {
        const geometry = wkx.Geometry.parse(bounds).toGeoJSON();
        const bounding = bbox(geometry);
        // const boundsCenter = center(geometry);
        map.fitBounds(bounding, { padding: 20 });
        // getWeatherStats({
        //   lat: boundsCenter.geometry.coordinates[1],
        //   lng: boundsCenter.geometry.coordinates[0],
        //   limit: 1,
        // });
      }
    }
  };

  broadcastMapLoad = () => {
    this.setState({
      mapLoaded: true,
    });
  };

  render() {
    const {
      activeTab,
      expandPanel,
      windyLat,
      windyLng,
      windyZoom,
      sourceType,
      selectedTimelineDate,
      indexToView,
      indicesCompare,
      selectedCompareTimelineDate,
      compareIndexToView,
      timelineOptions,
      comparetimelineOptions,
      selectedGPMDate,
      selectedNOAADate,
      selectedNOAADataFilter,
      selectedCHIRPSDate,
      weatherTab,
      properties,
      selectedSPIYear,
      mapLoaded,
    } = this.state;
    // console.log('timelineOptions >>', timelineOptions, indexToView);
    console.log('Dashboard');
    const {
      // indicesImages,
      // compareIndicesImages,
      gpmHeatmap,
      noaaHeatmap,
      chirpsHeatmap,
      indicesTimeline,
      indicesTimelineComparison,
      spiHeatmap,
      indicesLegends,
    } = this.props;
    // const settings = {
    //   focusOnSelect: true,
    //   centerMode: false,
    //   infinite: false,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   speed: 500,
    //   // nextArrow: <SampleNextArrow />,
    //   // prevArrow: <SamplePrevArrow />,
    // };
    const { language } = this.context;
    // console.log('properties >>>> ', properties);
    const tabsData = [
      {
        tabName: 'Agriculture Indices',
        component: (
          <AgricultureIndices
            broadcastLocationChange={this.broadcastLocationChange}
            broadcastIndicesSelectChange={this.broadcastIndicesSelectChange}
            broadcastCompareIndicesSelectChange={
              this.broadcastCompareIndicesSelectChange
            }
            broadcastComparison={this.broadcastComparison}
            getMapReference={this.getMapReference}
            selectedTimelineDate={selectedTimelineDate}
            indexToView={indexToView}
            timelineOptions={timelineOptions}
            indicesCompare={indicesCompare}
            mapLoaded={mapLoaded}
            fitLevelBounds={this.fitLevelBounds}
            getMapClassReference={this.getMapClassReference}
          />
        ),
      },
      {
        tabName: 'Weather',
        component: (
          <Weather
            broadcastLocationChange={this.broadcastLocationChange}
            broadcastWeatherSourceChange={this.broadcastWeatherSourceChange}
            getMapReference={this.getMapReference}
            selectedGPMDate={selectedGPMDate}
            selectedNOAADate={selectedNOAADate}
            selectedNOAADataFilter={selectedNOAADataFilter}
            selectedCHIRPSDate={selectedCHIRPSDate}
            selectedSPIYear={selectedSPIYear}
            broadcastWeatherTabChangeInParent={
              this.broadcastWeatherTabChangeInParent
            }
          />
        ),
      },
      {
        tabName: 'Crop Maps',
        component: (
          <Crop broadcastLocationChange={this.broadcastLocationChange} />
        ),
      },
      {
        tabName: 'Yield Processing',
        // disabled: true,
        component: (
          <YieldProcessing
            broadcastLocationChange={this.broadcastLocationChange}
          />
        ),
      },
      {
        tabName: 'Coverage',
        // disabled: true,
        component: (
          <Coverage broadcastLocationChange={this.broadcastLocationChange} />
        ),
      },
      {
        tabName: 'My Accounts',
        // disabled: true,
        component: <MyAccount getMapReference={this.getMapReference} />,
      },
      {
        tabName: 'Mandi',
        // disabled: true,
        component: (
          <Mandi broadcastLocationChange={this.broadcastLocationChange} />
        ),
      },
      {
        tabName: 'Survey',
        // disabled: true,
        component: (
          <Survey
            broadcastLocationChange={this.broadcastLocationChange}
            getMapReference={this.getMapReference}
          />
        ),
      },
      {
        tabName: 'Farm Tags',
        // disabled: true,
        component: (
          <FarmTagSelectTab
            broadcastLocationChange={this.broadcastLocationChange}
          />
        ),
      },
      {
        tabName: 'FG Analytics',
        disabled: true,
        component: 'FG Analytics',
      },
      {
        tabName: 'Info Response',
        disabled: true,
        component: 'Info Response',
      },
    ];
    // console.log('Indices Time Series ', indicesTimeline);
    let indicesDates = [];
    if (!_.isEmpty(indicesTimeline) && indexToView) {
      if (indicesTimeline[indexToView]) {
        const data = indicesTimeline[indexToView];
        if (data.mean) {
          indicesDates = data.mean;
        }
      }
    }
    let compareIndicesDates = [];
    if (!_.isEmpty(indicesTimelineComparison) && compareIndexToView) {
      if (indicesTimelineComparison[compareIndexToView]) {
        const data = indicesTimelineComparison[compareIndexToView];
        if (data.mean) {
          compareIndicesDates = data.mean;
        }
      }
    }
    // console.log('indicesDates ', indicesDates);

    let mapStyleParams = {
      styleURL: config.mapboxConfig.defaultStyle,
      segmentedView: true,
      cropMapView: false,
    };
    mapStyleParams = this.getMapStyleForTabs(activeTab) || mapStyleParams;
    return (
      <div>
        <section
          className={
            !expandPanel
              ? 'root panel mainPanel miniPanel'
              : 'root panel mainPanel miniPanel expandPanel'
          }
        >
          <Grid container>
            <DashboardSidePanel />
            <Grid item xs={12} sm={9} className="mapPanel">
              <button
                type="button"
                className="app-nav-action"
                onClick={this.onExpandClick}
              >
                <span className="app-nav-action__item" />
              </button>
              <ScrollableTabPanel
                tabsData={tabsData}
                broadcastTabChange={this.broadcastTabChange}
              />
              {(activeTab !== 1 || sourceType !== 'Windy')
                && !indicesCompare && (
                  <FarmEngineMap
                    ref={this.child}
                    calledFrom="dashboard"
                    selectedTimelineDate={selectedTimelineDate}
                    indexToView={indexToView}
                    styleParams={mapStyleParams}
                    selectedTab={activeTab}
                    setSegmentProperties={this.setSegmentProperties}
                    broadcastMapLoad={this.broadcastMapLoad}
                  />
              )}
              {indicesCompare && (
                <div className="mapDivision">
                  <IndicesCompare
                    ref={this.compareChild}
                    calledFrom="dashboard"
                    selectedTab={0}
                    indexToView={indexToView}
                    compareIndexToView={compareIndexToView}
                    selectedTimelineDate={selectedTimelineDate}
                    selectedCompareTimelineDate={selectedCompareTimelineDate}
                    setSegmentProperties={this.setSegmentProperties}
                  />
                </div>
              )}

              {/* {indicesCompare && activeTab === 0 && (
              <div className="mapDivision">
                <FarmEngineMap
                  ref={this.mapChild1}
                  calledFrom="dashboard"
                  selectedTimelineDate={selectedTimelineDate}
                  indexToView={indexToView}
                  styleParams={{
                    styleURL: config.mapboxConfig.blankStyle,
                    segmentedView: true,
                    cropMapView: false,
                  }}
                  selectedTab={activeTab}
                />

              </div>
              )} */}

              {activeTab === 1 && sourceType === 'Windy' && (
                <Windy
                  windyLat={windyLat}
                  windyLng={windyLng}
                  windyZoom={windyZoom}
                />
              )}
              {/* Timeline Panel Start */}

              {activeTab === 0 && !_.isEmpty(indicesDates) && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="itemContainer"
                >
                  <div className="map-tools">
                    <div className="custom-select">
                      <select
                        name="slct"
                        id="slct"
                        onChange={e => this.updateIndexToView(e.target.value)}
                        value={indexToView}
                      >
                        {timelineOptions.map(item => (
                          <option
                            key={`indicesTimeline-${Math.random()}`}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="left">
                    <button
                      type="button"
                      onClick={() => this.goToNextDate('indices')}
                    >
                      <i className="material-icons">arrow_back</i>
                    </button>
                  </div>
                  <div className="center" id="content">
                    {indicesDates
                      && indicesDates.map((item, index) => {
                        let date = new Date(item.date);
                        date = `${date.getDate()}-${
                          months[date.getMonth()]
                        }-${date.getFullYear()}`;
                        return (
                          <div
                            className="internal"
                            key={`internal-${Math.random()}`}
                          >
                            <button
                              type="button"
                              onClick={() => this.goToSelectedDate(
                                'indices',
                                index,
                                item.date,
                              )
                              }
                              className={
                                selectedTimelineDate === item.date
                                  ? 'button-item active'
                                  : 'button-item'
                              }
                            >
                              {date}
                            </button>
                          </div>
                        );
                      })}
                    {/* {indicesImages.map((item, index) => {
                      let date = new Date(item.date);
                      date = `${date.getDate()}-${
                        months[date.getMonth()]
                      }-${date.getFullYear()}`;
                      return (
                        <div className="internal" key={Math.random()}>
                          <button
                            type="button"
                            onClick={() => this.goToSelectedDate('indices', index, item.date)
                            }
                            className={
                              selectedTimelineDate === item.date
                                ? 'button-item active'
                                : 'button-item'
                            }
                          >
                            {date}
                          </button>
                        </div>
                      );
                    })} */}
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      onClick={() => this.goToPrevDate('indices')}
                    >
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </div>

                  {/* <div className="top-toolbar">
                 <div className="map-tools">
                 <div className="select">
                 <select name="slct" id="slct">
                 <option value="NDVI">NDVI</option>
                 <option value="MNDWI">MNDWI</option>
                 </select>
                 </div>
                 </div>
                 <div className="map-timeline-list">
                 <ul>
                 {dates.map((item, index) => (
                 <li
                 key={Math.random()}
                 className={
                 index === currentIndex
                 ? 'item-slide item-slide--active'
                 : 'item-slide'
                 }
                 >
                 <button
                 type="button"
                 className="map-timeline-area-item"
                 onClick={() => this.goToSelectedDate(index)}
                 >
                 {item}
                 </button>
                 </li>
                 ))}
                 </ul>
                 <div className="map-arrow-icons">
                 <ul>
                 <li className="arrow-icon">
                 <button type="button" onClick={this.goToPrevDate}>
                 <i className="material-icons">arrow_back</i>
                 </button>
                 </li>
                 <li className="arrow-icon">
                 <button type="button" onClick={this.goToNextDate}>
                 <i className="material-icons">arrow_forward</i>
                 </button>
                 </li>
                 </ul>
                 </div>
                 </div>
                 </div> */}
                </Grid>
              )}

              {/* Timeline Panel End */}

              {/* Compare Timeline Panel Start */}

              {activeTab === 0
                && indicesCompare
                && !_.isEmpty(compareIndicesDates) && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="compareItemContainer"
                  >
                    <div className="map-tools">
                      <div className="custom-select">
                        <select
                          name="slct"
                          id="slct"
                          onChange={e => this.updateCompareIndexToView(e.target.value)
                          }
                          value={compareIndexToView}
                        >
                          {comparetimelineOptions.map(item => (
                            <option
                              key={`indicesTimeline-${Math.random()}`}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="left">
                      <button
                        type="button"
                        onClick={() => this.goToNextDate('compareindices')}
                      >
                        <i className="material-icons">arrow_back</i>
                      </button>
                    </div>
                    <div className="center" id="content2">
                      {compareIndicesDates.map((item, index) => {
                        let date = new Date(item.date);
                        date = `${date.getDate()}-${
                          months[date.getMonth()]
                        }-${date.getFullYear()}`;
                        return (
                          <div
                            className="internal"
                            key={`internal-${Math.random()}`}
                          >
                            <button
                              type="button"
                              onClick={() => this.goToSelectedDate(
                                'compareindices',
                                index,
                                item.date,
                              )
                              }
                              className={
                                selectedCompareTimelineDate === item.date
                                  ? 'button-item active'
                                  : 'button-item'
                              }
                            >
                              {date}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="right">
                      <button
                        type="button"
                        onClick={() => this.goToPrevDate('compareindices')}
                      >
                        <i className="material-icons">arrow_forward</i>
                      </button>
                    </div>

                    {/* <div className="top-toolbar">
                   <div className="map-tools">
                   <div className="select">
                   <select name="slct" id="slct">
                   <option value="NDVI">NDVI</option>
                   <option value="MNDWI">MNDWI</option>
                   </select>
                   </div>
                   </div>
                   <div className="map-timeline-list">
                   <ul>
                   {dates.map((item, index) => (
                   <li
                   key={Math.random()}
                   className={
                   index === currentIndex
                   ? 'item-slide item-slide--active'
                   : 'item-slide'
                   }
                   >
                   <button
                   type="button"
                   className="map-timeline-area-item"
                   onClick={() => this.goToSelectedDate(index)}
                   >
                   {item}
                   </button>
                   </li>
                   ))}
                   </ul>
                   <div className="map-arrow-icons">
                   <ul>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToPrevDate}>
                   <i className="material-icons">arrow_back</i>
                   </button>
                   </li>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToNextDate}>
                   <i className="material-icons">arrow_forward</i>
                   </button>
                   </li>
                   </ul>
                   </div>
                   </div>
                   </div> */}
                  </Grid>
              )}

              {/* Compare Timeline Panel End */}
              {/* Legend Component Start (Need to fix its CSS) */}
              {activeTab === 0 && !_.isEmpty(indicesDates) && (
                <div className="custome-backround">
                  <div className="map-legend">
                    <div className="map-legend-item">
                      <div className="map-legend-scale">
                        {indicesLegends.map((item) => {
                          if (item.value > 0 || item.value === -1) {
                            return (
                              <div
                                key={`Legends-${Math.random()}`}
                                className="map-legend-scale-item"
                                style={{
                                  backgroundColor: `rgb(${item.rgb[0]}, ${
                                    item.rgb[1]
                                  }, ${item.rgb[2]})`,
                                }}
                              >
                                <div className="text-hover">{item.value}</div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Legend Component End */}

              {/* GPM Timeline Starts */}
              {activeTab === 1 && weatherTab === 1 && !_.isEmpty(gpmHeatmap) && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="itemContainer"
                >
                  <div className="map-tools">
                    <div className="custom-select">
                      <select
                        name="slct"
                        id="slct"
                        onChange={e => console.log('Selected ', e.target.value)}
                      >
                        <option value="GPM Index">GPM Index</option>
                      </select>
                    </div>
                  </div>
                  <div className="left">
                    <button
                      type="button"
                      onClick={() => this.goToNextDate('gpm')}
                    >
                      <i className="material-icons">arrow_back</i>
                    </button>
                  </div>
                  <div className="center" id="content">
                    {Object.keys(gpmHeatmap).map((item, index) => {
                      if (!_.isEmpty(gpmHeatmap[item])) {
                        let date = new Date(item);
                        date = `${date.getDate()}-${
                          months[date.getMonth()]
                        }-${date.getFullYear()}`;
                        return (
                          <div
                            className="internal"
                            key={`gpmData-${Math.random()}`}
                          >
                            <button
                              type="button"
                              onClick={() => this.goToSelectedDate('gpm', index, item)
                              }
                              className={
                                selectedGPMDate === item
                                  ? 'button-item active'
                                  : 'button-item'
                              }
                            >
                              {date}
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      onClick={() => this.goToPrevDate('gpm')}
                    >
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </div>
                </Grid>
              )}
              {/* GPM Timeline Ends */}

              {/* NOAA Timeline Starts */}
              {activeTab === 1 && weatherTab === 1 && !_.isEmpty(noaaHeatmap) && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="itemContainer"
                >
                  <div className="map-tools">
                    <div className="custom-select">
                      <select
                        name="slct"
                        id="slct"
                        onChange={e => this.setState({
                          selectedNOAADataFilter: e.target.value,
                        })
                        }
                      >
                        <option value="mintemp">Min Temp</option>
                        <option value="maxtemp">Max Temp</option>
                      </select>
                    </div>
                  </div>
                  <div className="left">
                    <button
                      type="button"
                      onClick={() => this.goToNextDate('noaa')}
                    >
                      <i className="material-icons">arrow_back</i>
                    </button>
                  </div>
                  <div className="center" id="content">
                    {Object.keys(noaaHeatmap).map((item, index) => {
                      if (!_.isEmpty(noaaHeatmap[item])) {
                        let date = new Date(item);
                        date = `${date.getDate()}-${
                          months[date.getMonth()]
                        }-${date.getFullYear()}`;
                        return (
                          <div
                            className="internal"
                            key={`noaaData${Math.random()}`}
                          >
                            <button
                              type="button"
                              onClick={() => this.goToSelectedDate('noaa', index, item)
                              }
                              className={
                                selectedNOAADate === item
                                  ? 'button-item active'
                                  : 'button-item'
                              }
                            >
                              {date}
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      onClick={() => this.goToPrevDate('noaa')}
                    >
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </div>

                  {/* <div className="top-toolbar">
                   <div className="map-tools">
                   <div className="select">
                   <select name="slct" id="slct">
                   <option value="NDVI">NDVI</option>
                   <option value="MNDWI">MNDWI</option>
                   </select>
                   </div>
                   </div>
                   <div className="map-timeline-list">
                   <ul>
                   {dates.map((item, index) => (
                   <li
                   key={Math.random()}
                   className={
                   index === currentIndex
                   ? 'item-slide item-slide--active'
                   : 'item-slide'
                   }
                   >
                   <button
                   type="button"
                   className="map-timeline-area-item"
                   onClick={() => this.goToSelectedDate(index)}
                   >
                   {item}
                   </button>
                   </li>
                   ))}
                   </ul>
                   <div className="map-arrow-icons">
                   <ul>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToPrevDate}>
                   <i className="material-icons">arrow_back</i>
                   </button>
                   </li>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToNextDate}>
                   <i className="material-icons">arrow_forward</i>
                   </button>
                   </li>
                   </ul>
                   </div>
                   </div>
                   </div> */}
                </Grid>
              )}
              {/* NOAA Timeline Ends */}

              {/* CHIRPS Timeline Starts */}
              {activeTab === 1
                && weatherTab === 1
                && !_.isEmpty(chirpsHeatmap) && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="itemContainer"
                  >
                    <div className="map-tools">
                      <div className="custom-select">
                        <select name="slct" id="slct">
                          <option value="chirpsIndex">Chirps Index</option>
                        </select>
                      </div>
                    </div>
                    <div className="left">
                      <button
                        type="button"
                        onClick={() => this.goToNextDate('chirps')}
                      >
                        <i className="material-icons">arrow_back</i>
                      </button>
                    </div>
                    <div className="center" id="content">
                      {Object.keys(chirpsHeatmap).map((item, index) => {
                        if (!_.isEmpty(chirpsHeatmap[item])) {
                          let date = new Date(item);
                          date = `${date.getDate()}-${
                            months[date.getMonth()]
                          }-${date.getFullYear()}`;
                          return (
                            <div
                              className="internal"
                              key={`chirps-${Math.random()}`}
                            >
                              <button
                                type="button"
                                onClick={() => this.goToSelectedDate('chirps', index, item)
                                }
                                className={
                                  selectedCHIRPSDate === item
                                    ? 'button-item active'
                                    : 'button-item'
                                }
                              >
                                {date}
                              </button>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className="right">
                      <button
                        type="button"
                        onClick={() => this.goToPrevDate('chirps')}
                      >
                        <i className="material-icons">arrow_forward</i>
                      </button>
                    </div>

                    {/* <div className="top-toolbar">
                   <div className="map-tools">
                   <div className="select">
                   <select name="slct" id="slct">
                   <option value="NDVI">NDVI</option>
                   <option value="MNDWI">MNDWI</option>
                   </select>
                   </div>
                   </div>
                   <div className="map-timeline-list">
                   <ul>
                   {dates.map((item, index) => (
                   <li
                   key={Math.random()}
                   className={
                   index === currentIndex
                   ? 'item-slide item-slide--active'
                   : 'item-slide'
                   }
                   >
                   <button
                   type="button"
                   className="map-timeline-area-item"
                   onClick={() => this.goToSelectedDate(index)}
                   >
                   {item}
                   </button>
                   </li>
                   ))}
                   </ul>
                   <div className="map-arrow-icons">
                   <ul>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToPrevDate}>
                   <i className="material-icons">arrow_back</i>
                   </button>
                   </li>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToNextDate}>
                   <i className="material-icons">arrow_forward</i>
                   </button>
                   </li>
                   </ul>
                   </div>
                   </div>
                   </div> */}
                  </Grid>
              )}
              {/* CHIRPS Timeline Ends */}

              {/* SPI Timeline Starts */}
              {activeTab === 1 && weatherTab === 0 && !_.isEmpty(spiHeatmap) && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="itemContainer"
                >
                  <div className="map-tools">
                    <div className="custom-select">
                      <select name="slct" id="slct">
                        <option value="chirpsIndex">SPI Index</option>
                      </select>
                    </div>
                  </div>
                  <div className="left">
                    <button
                      type="button"
                      onClick={() => this.goToNextDate('spi')}
                    >
                      <i className="material-icons">arrow_back</i>
                    </button>
                  </div>
                  <div className="center" id="content">
                    {Object.values(spiHeatmap).map((item, index) => {
                      if (!_.isEmpty(item)) {
                        return (
                          <div
                            className="internal"
                            key={`spi-${Math.random()}`}
                          >
                            <button
                              type="button"
                              onClick={() => this.goToSelectedDate('spi', index, item.year)
                              }
                              className={
                                selectedSPIYear === item.year
                                  ? 'button-item active'
                                  : 'button-item'
                              }
                            >
                              {item.year}
                            </button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      onClick={() => this.goToPrevDate('spi')}
                    >
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </div>

                  {/* <div className="top-toolbar">
                   <div className="map-tools">
                   <div className="select">
                   <select name="slct" id="slct">
                   <option value="NDVI">NDVI</option>
                   <option value="MNDWI">MNDWI</option>
                   </select>
                   </div>
                   </div>
                   <div className="map-timeline-list">
                   <ul>
                   {dates.map((item, index) => (
                   <li
                   key={Math.random()}
                   className={
                   index === currentIndex
                   ? 'item-slide item-slide--active'
                   : 'item-slide'
                   }
                   >
                   <button
                   type="button"
                   className="map-timeline-area-item"
                   onClick={() => this.goToSelectedDate(index)}
                   >
                   {item}
                   </button>
                   </li>
                   ))}
                   </ul>
                   <div className="map-arrow-icons">
                   <ul>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToPrevDate}>
                   <i className="material-icons">arrow_back</i>
                   </button>
                   </li>
                   <li className="arrow-icon">
                   <button type="button" onClick={this.goToNextDate}>
                   <i className="material-icons">arrow_forward</i>
                   </button>
                   </li>
                   </ul>
                   </div>
                   </div>
                   </div> */}
                </Grid>
              )}
              {/* SPI Timeline Ends */}

              {/* GPM & CHIRPS Legends Start */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {(!_.isEmpty(gpmHeatmap) || !_.isEmpty(chirpsHeatmap))
                  && weatherTab === 1 && (
                    <div className="map-legend">
                      <div className="map-legend-item">
                        <div className="map-legend-scale">
                          {config.gpmLegends.map(item => (
                            <div
                              key={`mapLegend-${Math.random()}`}
                              className="map-legend-scale-item"
                              style={{ backgroundColor: item.color }}
                            >
                              <div className="text-hover">{item.text}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                )}
              </Grid>
              {/* GPM Legends Ends */}

              {/* SPI Legends Start */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {!_.isEmpty(spiHeatmap) && activeTab === 1 && weatherTab === 0 && (
                  <div className="map-legend">
                    <div className="map-legend-item">
                      <div className="map-legend-scale">
                        {config.spiLegends.map(item => (
                          <div
                            key={`mapLegend-${Math.random()}`}
                            className="map-legend-scale-item"
                            style={{ backgroundColor: item.color }}
                          >
                            <div className="text-hover">{item.text}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
              {/* SPI Legends Ends */}

              {/* Top Crops Section Start  */}
              {(activeTab === 0 || activeTab === 2 || activeTab === 9)
                && properties && (
                  <aside className="dashboardtopCrops">
                    {typeof properties.MAJORITY === 'string' ? (
                      <ul>
                        <li>{properties.MAJORITY}</li>
                        <li>
                          Size:
                          {' '}
                          {(parseFloat(properties.farm_area) * 0.0001).toFixed(
                            1,
                          )}
                          {' '}
                        </li>
                        <li>
                          Score:
                          {parseFloat(properties.score).toFixed(1)}
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        {config.crops[properties.MAJORITY] && (
                          <li
                            style={{
                              color: config.crops[properties.MAJORITY].cropColor,
                            }}
                          >
                            {(language.hindi
                              && language.hindi[
                                config.crops[properties.MAJORITY].cropName
                              ])
                              || config.crops[properties.MAJORITY].cropName}
                          </li>
                        )}
                        <li style={{ color: '#fff' }}>
                          {(language.hindi && language.hindi.Area) || 'Area'}
:
                          {' '}
                          {(parseFloat(properties.farm_area) * 0.0001).toFixed(
                            1,
                          )}
                          {' '}
                        </li>
                        <li style={{ color: '#fff' }}>
                          {(language.hindi && language.hindi.Score) || 'Score'}
:
                          {parseFloat(properties.score).toFixed(1)}
                        </li>
                      </ul>
                    )}
                  </aside>
              )}
              {/* Top Crops Section End  */}
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}

Dashboard.contextType = LanguageContext;
export default withRouter(
  connect(
    ({
      landing, location, agricultureIndices, weather,
    }) => ({
      cropsArray: landing.cropsArray,
      states: location.state,
      districts: location.district,
      tehsils: location.tehsil,
      selectedStateID: location.selectedStateID,
      indicesImages: agricultureIndices.indicesImages,
      compareIndicesImages: agricultureIndices.compareIndicesImages,
      chirpsHeatmap: weather.chirpsHeatmap,
      noaaHeatmap: weather.noaaHeatmap,
      gpmHeatmap: weather.gpmHeatmap,
      spiHeatmap: weather.spiHeatmap,
      indicesTimeline: agricultureIndices.indicesTimeline,
      indicesValue: agricultureIndices.indicesValue,
      satellitesSource: agricultureIndices.satellitesSource,
      startDate: agricultureIndices.startDate,
      endDate: agricultureIndices.endDate,
      selectedFarm: agricultureIndices.selectedFarm,
      indicesTimelineComparison: agricultureIndices.indicesTimelineComparison,
      indicesLegends: agricultureIndices.indicesLegends,
      compareIndicesLegends: agricultureIndices.compareIndicesLegends,
    }),
    dispatch => ({
      getHierarchyData: (level, plID) => dispatch(getHierarchyDataAct(level, plID)),
      getCropsRegionWise: data => dispatch(getCropsRegionWiseAct(data)),
      updateBounds: (
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        farmid,
      ) => {
        dispatch(
          updateBoundsAction(
            bounds,
            indicesValue,
            satellitesSource,
            startDate,
            endDate,
            farmid,
          ),
        );
      },
      resetIMDdetails: () => dispatch(resetWeatherSourceDetails()),
      resetSmapHeatmap: () => dispatch(clearIndicesSmapHeatMap()),
      resetNDVI: () => dispatch(resetNDVIAction()),
      getLegendsData: (indice, compare) => dispatch(getIndicesLegends(indice, compare)),
      clearLegends: compare => dispatch(clearIndicesLegends(compare)),
      startCommonLoader: () => dispatch(loaderStart()),
      stopCommonLoader: () => dispatch(loaderStop()),
      resetSurveyTabFunc: () => dispatch(resetSurveyTab()),
    }),
  )(Dashboard),
);
