const initialState = {
  message: null,
  otp: '',
  isMobileVerified: false,
  verifiedMobile: '',
  isRegistered: false,
  isLogin: false,
  userData: {},
  loginError: '',
  otpError: '',
  registrationError: '',
  fetching: false,
  openRegisterModal: false,
  userType: [],
  openLoginModal: false,
  stakeHolderOrg: [],
  userRoles: [],
  verfiyMobileToken: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return {
        ...state,
        message: action.message,
      };

    case 'GET_OTP_SUCCESS':
      return {
        ...state,
        otp: action.payload.data.otp,
        otpError: '',
      };

    case 'GET_OTP_FAILURE':
      return {
        ...state,
        otp: '',
        otpError: action.payload,
      };

    case 'RESET_OTP_SUCCESS':
      return {
        ...state,
        otp: '',
        otpError: '',
      };

    case 'MOBILE_VERIFY_SUCCESS':
      return {
        ...state,
        isMobileVerified: action.payload.status,
        verifiedMobile: action.mobileNumber,
        verfiyMobileToken: action.payload.data.token,
        otpError: '',
      };

    case 'MOBILE_VERIFY_FAILURE':
      return {
        ...state,
        isMobileVerified: false,
        verifiedMobile: '',
        otpError: action.payload,
        verfiyMobileToken: '',
      };

    case 'REGISTRATION_SUCCESS':
      return {
        ...state,
        isRegistered: action.payload.status,
        isLogin: false,
        userData: { ...action.payload.data },
        registrationError: '',
      };

    case 'REGISTRATION_FAILURE':
      return {
        ...state,
        isRegistered: false,
        isLogin: false,
        userData: {},
        registrationError: action.payload,
      };

    case 'RESET_REGISTER':
      return {
        ...state,
        otp: '',
        isMobileVerified: false,
        verifiedMobile: '',
        isRegistered: false,
        loginError: '',
        otpError: '',
        registrationError: '',
        openRegisterModal: false,
        openLoginModal: false,
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLogin: true,
        userData: { ...action.payload.data },
        loginError: '',
      };

    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLogin: false,
        userData: {},
        loginError: action.payload,
      };

    case 'LOGIN_AS_SEED_COMPANY_SUCCESS':
      return {
        ...state,
        isLogin: true,
        userData: { ...action.payload.data },
        loginError: '',
      };

    case 'LOGIN_AS_SEED_COMPANY_FAILURE':
      return {
        ...state,
        isLogin: false,
        userData: {},
        loginError: action.payload,
      };

    case 'LOADER_START':
      return {
        ...state,
        fetching: action.payload,
      };

    case 'LOADER_STOP':
      // debugger
      return {
        ...state,
        fetching: action.payload,
      };

    case 'OPEN_REGISTER_MODAL':
      return {
        ...state,
        openRegisterModal: action.payload,
      };

    case 'USER_TYPE_SUCCESS':
      return {
        ...state,
        userType: action.payload.data,
      };

    case 'USER_TYPE_FAILURE':
      return {
        ...state,
        userType: [],
      };

    case 'UPDATE_USERDATA_SUCCESS':
      return {
        ...state,
        userData: action.payload,
      };

    case 'UPDATE_USERDATA_FAILURE':
      return {
        ...state,
        userData: {},
      };
    case 'HANDLE_LOGIN_MODAL':
      return {
        ...state,
        openLoginModal: action.payload,
      };

    case 'STAKE_HOLDERS_SUCCESS':
      return {
        ...state,
        stakeHolderOrg: action.payload,
      };

    case 'STAKE_HOLDERS_FAILURE':
      return {
        ...state,
        stakeHolderOrg: [],
      };

    case 'USER_ROLE_SUCCESS':
      return {
        ...state,
        userRoles: action.payload,
      };

    case 'USER_ROLE_FAILURE':
      return {
        ...state,
        userRoles: [],
      };

    default:
      return state;
  }
};
