const initialState = {
  isLandRegistered: false,
  farmDetails: [],
  isLandRemoved: false,
  farmTagError: '',
  removeFarmError: '',
  farmCard: {},
  error: '',
  landData: [],
  showLandSuccessMsg: '',
  showLandRemoveSuccessMsg: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LAND_REGISTERED_SUCCESS':
      console.log('newData>>', action.payload.data.message);
      return {
        ...state,
        isLandRegistered: action.payload.status,
        farmDetails: [...state.farmDetails, action.payload.data.landDetails],
        farmTagError: '',
        showLandSuccessMsg: action.payload.data.message,
      };

    case 'LAND_REGISTERED_FAILURE':
      return {
        ...state,
        isLandRegistered: false,
        farmTagError: action.payload,
      };

    case 'LAND_REMOVED_SUCCESS': {
      return {
        ...state,
        isLandRemoved: action.payload.status,
        farmDetails: state.farmDetails.filter(
          farm => farm.farmID !== action.farmId,
        ),
        removeFarmError: '',
        showLandRemoveSuccessMsg: action.payload.data,
      };
    }
    case 'LAND_REMOVED_FAILURE': {
      return {
        ...state,
        isLandRemoved: false,
        removeFarmError: action.payload,
      };
    }

    case 'RESET_ACCOUNT_SETUP_DETAILS': {
      return {
        ...state,
        farmTagError: '',
        isLandRegistered: false,
        isLandRemoved: false,
        removeFarmError: '',
        showLandSuccessMsg: '',
        showLandRemoveSuccessMsg: '',
      };
    }
    case 'FARM_CARD_SUCCESS': {
      return {
        ...state,
        farmCard: action.payload,
      };
    }
    case 'FARM_CARD_FAILURE': {
      return {
        ...state,
        farmCard: {},
        error: action.payload,
      };
    }

    case 'LAND_DATA_SUCCESS': {
      return {
        ...state,
        landData: action.payload,
      };
    }

    case 'LAND_DATA_FAILURE': {
      return {
        ...state,
        landData: [],
      };
    }

    case 'RESET_LAND_REMOVE_DETAILS': {
      return {
        ...state,
        isLandRemoved: false,
      };
    }

    default:
      return state;
  }
};
