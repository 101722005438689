import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';

const TableHOC = (props) => {
  const {
    isTHeadCheckbox,
    tHeadList,
    tBody,
    customClass,
    isCheckboxChecked,
    checkboxHandler,
  } = props;
  const classes = `table ${customClass}`;
  return (
    <React.Fragment>
      {tBody && tBody.length ? (
        <Table className={classes}>
          <TableHead>
            <TableRow>
              {isTHeadCheckbox ? (
                <TableCell>
                  <Checkbox
                    checked={isCheckboxChecked}
                    onChange={checkboxHandler}
                  />
                </TableCell>
              ) : null}
              {tHeadList.map(th => (
                <TableCell key={Math.random()}>{th}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tBody}</TableBody>
        </Table>
      ) : (
        <h1>Sorry we could not find any data</h1>
      )}
    </React.Fragment>
  );
};

export default TableHOC;
