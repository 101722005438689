import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
} from '@material-ui/core';
// import Modal from 'react-responsive-modal';
import '../../css/leftPanel.scss';
import '../../css/modal.scss';

const FarmerCard = (props) => {
  const { farmCardData, language, closeFarmCard } = props;
  const fullList = () => (
    <div
      className="fullList drawerMain"
      role="presentation"
      onClick={() => closeFarmCard}
      onKeyDown={() => closeFarmCard}
    >
      {/* Basic Detail Start */}
      <Grid item xs={12} className="profileDetail">
        <div className="profileImg">
          <img src="../images/farmCard/Profilepic.png" alt="profile-img" />
        </div>
        <ul className="statSec">
          <li>
            {(language.hindi && language.hindi.name) || 'Name'}
            {' '}
:
            <span>
              {farmCardData
                && farmCardData.farmerDetails
                && farmCardData.farmerDetails.farmerName}
            </span>
          </li>
          <li>
            {(language.hindi && language.hindi['Mobile No.'])
              || 'Mobile Number'}
            {' '}
            :
            <span>
              {farmCardData
                && farmCardData.farmerDetails
                && farmCardData.farmerDetails.farmerMobile}
            </span>
          </li>
          <li>
            {(language.hindi && language.hindi.Village) || 'Village Name'}
            {' '}
:
            <span>
              {farmCardData
                && farmCardData.farmerDetails
                && farmCardData.farmerDetails.villageName}
            </span>
          </li>
          <li>
            {(language.hindi && language.hindi['Language Prefered'])
              || 'Language Prefered'}
            {' '}
            :
            <span>
              {farmCardData
                && farmCardData.farmerDetails
                && farmCardData.farmerDetails.preferedLanguage[0]}
            </span>
          </li>
          <li>
            {(language.hindi && language.hindi.State) || 'State'}
            {' '}
:
            <span>
              {farmCardData
                && farmCardData.farmerDetails
                && farmCardData.farmerDetails.stateName}
            </span>
          </li>
        </ul>
        <div className="qrCodeSec">
          <img src="../images/farmCard/QRcode.png" alt="qr-code" />
        </div>
      </Grid>
      {/* Basic Detail End */}
      {/* Season/Weather Detail Start */}
      <Grid item xs={6} className="seasonWeatherSec">
        <div className="seasonSec">
          <img
            src="../images/farmCard/Farm-01.svg"
            alt="farm-img"
            className="satelliteImg"
          />
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="right">
                  {(language.hindi && language.hindi.Kharif) || 'Kharif'}
                </TableCell>
                <TableCell align="right">
                  {(language.hindi && language.hindi.Rabi) || 'Rabi'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {(language.hindi && language.hindi.Area) || 'Area'}
                  <img src="../images/farmCard/Area.svg" alt="area-img" />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && `${farmCardData.farmDetails.area} ${farmCardData.farmDetails.areaUnit}`}
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && `${farmCardData.farmDetails.area} ${farmCardData.farmDetails.areaUnit}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Crop Name
                  <img
                    src="../images/farmCard/Cropname.svg"
                    alt="crop-name-img"
                  />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.cropName}
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.cropName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Sowing Month
                  <img src="../images/farmCard/sowing.svg" alt="sowing-img" />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.sowingMonth}
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.sowingMonth}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Harvesting Month
                  <img
                    src="../images/farmCard/harvesting.svg"
                    alt="harvesting-img"
                  />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.harvestingMonth}
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.harvestingMonth}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Irrigated (Yes/No)
                  <img
                    src="../images/farmCard/Irrigated.svg"
                    alt="irrigated-img"
                  />
                </TableCell>
                <TableCell align="right">Yes</TableCell>
                <TableCell align="right">Yes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Insurance company
                  <img
                    src="../images/farmCard/Insurancecompany.svg"
                    alt="insurance-company-img"
                  />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.icName}
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.icName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Insurance premium
                  <img
                    src="../images/farmCard/Insurancepremium.svg"
                    alt="insurance-premium-img"
                  />
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.premium}
                  /-
                </TableCell>
                <TableCell align="right">
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.premium}
                  /-
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Expected Production
                  <img
                    src="../images/farmCard/expectedproduction.svg"
                    alt="expected-production-img"
                  />
                </TableCell>
                <TableCell align="right">
                  {' '}
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.expectedHarvestQuantity}
                  {' '}
                  KG
                </TableCell>
                <TableCell align="right">
                  {' '}
                  {farmCardData
                    && farmCardData.farmDetails
                    && farmCardData.farmDetails.expectedHarvestQuantity}
                  {' '}
                  KG
                </TableCell>
              </TableRow>
              {/* <TableRow>
                  <TableCell component="th" scope="row">
                    Soil Composition(Sand:Silt:Clay)
                    <img src="../images/farmCard/soil.svg" alt="soil-img" />
                  </TableCell>
                  <TableCell align="right">68 : 16 : 16</TableCell>
                  <TableCell align="right">68 : 16 : 16</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Soil pH
                    <img src="../images/farmCard/soilpH.svg" alt="soil-ph-img" />
                  </TableCell>
                  <TableCell align="right">6.4</TableCell>
                  <TableCell align="right">6.4</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sun Hour
                    <img src="../images/farmCard/sunhour.svg" alt="sun-hour-img" />
                  </TableCell>
                  <TableCell align="right">1400</TableCell>
                  <TableCell align="right">1400</TableCell>
                </TableRow> */}
              <TableRow>
                <TableCell component="th" scope="row">
                  Catastrophic Risk
                  <img
                    src="../images/farmCard/catastrophicrisk.svg"
                    alt="catastrophic-risk-img"
                  />
                </TableCell>
                <TableCell align="right">2/5</TableCell>
                <TableCell align="right">2/5</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="weatherSec">
          <h4>Local Weather</h4>
          <ul>
            <li>
              <i>Jul 22nd</i>
              <img src="../images/rain.svg" alt="rain-img" />
              <span>33 - 27</span>
            </li>
            <li>
              <i>Jul 22nd</i>
              <img src="../images/rain.svg" alt="rain-img" />
              <span>33 - 27</span>
            </li>
            <li>
              <i>Jul 22nd</i>
              <img src="../images/rain.svg" alt="rain-img" />
              <span>33 - 27</span>
            </li>
            <li>
              <i>Jul 22nd</i>
              <img src="../images/rain.svg" alt="rain-img" />
              <span>33 - 27</span>
            </li>
            <li>
              <i>Jul 22nd</i>
              <img src="../images/rain.svg" alt="rain-img" />
              <span>33 - 27</span>
            </li>
          </ul>
        </div>
      </Grid>
      {/* Season/Weather Detail End */}

      <Grid item xs={6} className="farmcardRightPanel">
        {/* Farm Rating Start */}
        <div className="farmRating">
          <div className="ratingList">
            <h3>Farm Rating</h3>
            <ul>
              <li>
                <i className="material-icons checked">star</i>
              </li>
              <li>
                <i className="material-icons checked">star</i>
              </li>
              <li>
                <i className="material-icons checked">star</i>
              </li>
              <li>
                <i className="material-icons checked">star</i>
              </li>
              <li>
                <i className="material-icons">star_border</i>
              </li>
            </ul>
          </div>
          <span className="ratingNumber">
            {farmCardData
              && farmCardData.farmDetails
              && Math.round(farmCardData.farmDetails.farmRating * 10) / 10}
            /5
          </span>
        </div>
        {/* Farm Rating End */}
        {/* Farm Connectivity Start */}
        <div className="farmConnectSec">
          <h4>Farm Connectivity</h4>
          <ul>
            <li>
              <img src="../images/farmCard/Mandi.svg" alt="mandi-img" />
              <h6>
                {farmCardData
                  && farmCardData.nearbuy
                  && farmCardData.nearbuy.marketName}
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.marketDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
            <li>
              <img src="../images/farmCard/Road.svg" alt="road-img" />
              <h6>
                Road
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.roadDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
            <li>
              <img src="../images/farmCard/Mandi.svg" alt="mandi-img" />
              <h6>
                {farmCardData
                  && farmCardData.nearbuy
                  && farmCardData.nearbuy.storageName}
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.storageDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
            <li>
              <img
                src="../images/farmCard/watersource.svg"
                alt="water-source-img"
              />
              <h6>
                {farmCardData
                  && farmCardData.nearbuy
                  && farmCardData.nearbuy.waterwayType}
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.waterDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
            <li>
              <img
                src="../images/farmCard/watersource.svg"
                alt="water-source-img"
              />
              <h6>
                Rail
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.railDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
            <li>
              <img src="../images/farmCard/bank.svg" alt="water-source-img" />
              <h6>
                {farmCardData
                  && farmCardData.nearbuy
                  && farmCardData.nearbuy.bankName}
                <span>
                  {farmCardData
                    && farmCardData.nearbuy
                    && farmCardData.nearbuy.bankDistance}
                  {' '}
                  km
                </span>
              </h6>
            </li>
          </ul>
        </div>
        {/* Farm Connectivity End */}
        {/* Historical Satellite Start */}
        <div className="historicalSatelliteSec">
          <h4>Historical Satellite Images</h4>
          <ul>
            <li>
              <img
                src="../images/farmCard/historicData.png"
                alt="historic-data-img"
              />
              <span>2017</span>
            </li>
            <li>
              <img
                src="../images/farmCard/historicData.png"
                alt="historic-data-img"
              />
              <span>2017</span>
            </li>
            <li>
              <img
                src="../images/farmCard/historicData.png"
                alt="historic-data-img"
              />
              <span>2017</span>
            </li>
            <li>
              <img
                src="../images/farmCard/historicData.png"
                alt="historic-data-img"
              />
              <span>2017</span>
            </li>
          </ul>
        </div>
        {/* Historical Satellite End */}

        <div className="missedCallPanel">
          Give us a missed call to have your farm card
          <span>1800 103 2770</span>
        </div>

        <div className="cardLinksPanel">
          <ol>
            <li>
              <a href="/">
                Farmguide
                {' '}
                <br />
                App link
              </a>
            </li>
            <li>
              <a href="/">www.bit.ly/eouW1n</a>
            </li>
          </ol>
          <ul className="socialIcons">
            <li>
              <a href="/">
                <img
                  src="../images/farmCard/facebook.svg"
                  alt="facebook-icon"
                  title="facebook-icon"
                />
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="../images/farmCard/whatsapp.svg"
                  alt="whatsapp-icon"
                  title="whatsapp-icon"
                />
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  src="../images/farmCard/messenger.svg"
                  alt="messenger-icon"
                  title="messenger-icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </Grid>
    </div>
  );

  return (
    <div>
      {/* <Button onClick={() => props.closeFarmCard()} className="openBotButton">
        Open Bottom
      </Button> */}
      {/* <Modal

        anchor="bottom"
        open
        center
        onClose={() => props.closeFarmCard()}
      > */}
      <Dialog
        maxWidth="xl"
        onClose={() => props.closeFarmCard()}
        className="registerModal modalHead"
        open
      >
        <div className="paper farmcardModal">
          <div className="modalSec">
            <div className="modalContent">{fullList('bottom')}</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FarmerCard;
