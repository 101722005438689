import React from 'react';
import {
  Grid, TableRow, TableCell, Button,
} from '@material-ui/core';
import ModalHOC from '../common/Modal/index';
import TableHOC from '../common/Table';
import '../../css/agricultureIndices.scss';

const THEAD_LIST = [
  'Multi-Input',
  'Dynamic-Input',
  'Key Pressed',
  'Value Saved',
];

function createData(multiInput, dynamicInput, input, value) {
  return {
    multiInput,
    dynamicInput,
    input,
    value,
  };
}

class CardSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachSms: false,
      smsData: {
        type: 'all',
        values: '',
      },
    };
  }

  handleClose = () => {
    const { onCloseModal } = this.props;
    const { smsData } = this.state;
    const smsDataCopy = { ...smsData };
    smsDataCopy.values = '';
    this.setState({
      attachSms: false,
      smsData: smsDataCopy,
    });
    onCloseModal();
  };

  embedSms = () => {
    this.setState({ attachSms: true });
  };

  attachSms = () => {
    const { smsData } = this.state;
    const { onSmsAttach, onCloseModal, card } = this.props;
    onSmsAttach(smsData, card);
    const smsDataCopy = { ...smsData };
    smsDataCopy.values = '';
    this.setState({
      smsData: smsDataCopy,
    });
    onCloseModal();
  };

  handleSms = (event, type) => {
    const { smsData } = this.state;
    const smsDataCopy = { ...smsData };
    smsDataCopy[type] = event.target.value;
    this.setState({
      smsData: smsDataCopy,
    });
  };

  render() {
    const { attachSms, smsData } = this.state;
    const { show, activeChip } = this.props;
    const { values } = smsData;
    const rows = [];
    if (activeChip !== null) {
      rows.push(
        createData(
          activeChip.isMultiInput,
          activeChip.isDynamicInput,
          activeChip.inputs,
          activeChip.values,
        ),
      );
    }
    const TBODY_DATA = rows.map(row => (
      <TableRow hover key={Math.random()}>
        <TableCell>
          {row.multiInput
            ? 'Accepts multiple key presses as input during call'
            : 'Accepts only one key press as input during call'}
        </TableCell>
        <TableCell>
          {row.dynamicInput
            ? 'This card plays an audio based on dynamic input by call receiver'
            : 'Plays a static audio with no dependency on previous cards'}
        </TableCell>
        <TableCell>
          {Object.prototype.hasOwnProperty.call(activeChip, 'inputs') ? (
            <ul>
              {row.input.map(item => (
                <li key={Math.random()}>{item.toString()}</li>
              ))}
            </ul>
          ) : (
            'Not Available'
          )}
        </TableCell>
        <TableCell>
          {Object.prototype.hasOwnProperty.call(activeChip, 'values') ? (
            <ul>
              {row.value.map(item => (
                <li key={Math.random()}>{item.toString()}</li>
              ))}
            </ul>
          ) : (
            'Not Available'
          )}
        </TableCell>
      </TableRow>
    ));
    return (
      <div>
        <ModalHOC
          isOpen={show}
          onClose={this.handleClose}
          customContent="embed-sms"
          titleContent={
            activeChip !== null ? `${activeChip.name} - Details` : null
          }
          footerComponent={
            !attachSms ? (
              <Button
                className="addBtn"
                style={{ backgroundColor: '#09B466', color: '#fff' }}
                onClick={this.embedSms}
              >
                Embed SMS
              </Button>
            ) : (
              <Grid container>
                <Grid item lg={8} md={8} xs={12}>
                  <textarea
                    rows="3"
                    cols="55"
                    placeholder="Enter SMS Text"
                    onChange={e => this.handleSms(e, 'values')}
                    value={values}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12} className="btn">
                  <Button className="btn-success" onClick={this.attachSms}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            )
          }
          customClass="modal-position-top"
        >
          <Grid container spacing={2} className="customizeModal">
            <div className="insightTable tableWrapper">
              <TableHOC tHeadList={THEAD_LIST} tBody={TBODY_DATA} />
            </div>
          </Grid>
        </ModalHOC>
      </div>
    );
  }
}

export default CardSetting;
