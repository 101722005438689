import React, { Component } from 'react';
// import { Grid } from '@material-ui/core';
// import { GeolocateControl, NavigationControl } from 'mapbox-gl';
import center from '@turf/center';
import axios from 'axios';
import { debounce } from 'lodash';
// import ajax from '../../Functions/ajax';
import { config } from '../../config';
// import '../../css/rightPanel.scss';

let ReactMapboxGl;
let Map;
// let geolocate;

export default class AccountSetUpMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPlace: '',
      showMap: false,
      // setZoom: '',
    };
  }

  componentDidMount() {
    /* eslint-disable global-require */
    ReactMapboxGl = require('react-mapbox-gl');
    /* eslint-enable global-require */
    Map = ReactMapboxGl.Map({
      accessToken: config.mapboxConfig.publicToken,
      hash: true,
      minZoom: 1,
      maxZoom: 17,
    });
    this.setState({ showMap: true });
    this.searchAddress = debounce(this.searchAddress, 200);
  }

  componentDidUpdate(prevProps) {
    const { landData } = this.props;
    const map = this.mapContainer;
    if (prevProps.landData.length !== landData.length) {
      let newMappedFarmIds = landData.map(land => land.farmID);
      if (newMappedFarmIds.length === 0) {
        newMappedFarmIds = [''];
      }
      const newArrays = Array.from(new Set(newMappedFarmIds));
      if (map) {
        // Get For Ganganagar
        const fillColorStyle = map.getPaintProperty(
          'ganganagarsegments',
          'fill-color',
        );
        const fillOpacityStyle = map.getPaintProperty(
          'ganganagarsegments',
          'fill-opacity',
        );
        // Get for Mankrola
        const fillColorStyle2 = map.getPaintProperty(
          'mankrolasegments',
          'fill-color',
        );
        const fillOpacityStyle2 = map.getPaintProperty(
          'mankrolasegments',
          'fill-opacity',
        );
        if (
          fillColorStyle
          && fillOpacityStyle
          && fillColorStyle2
          && fillOpacityStyle2
        ) {
          fillColorStyle[2] = newArrays;
          fillOpacityStyle[2] = newArrays;
          fillColorStyle2[2] = newArrays;
          fillOpacityStyle2[2] = newArrays;
          // Set for Ganganagar
          map.setPaintProperty(
            'ganganagarsegments',
            'fill-color',
            fillColorStyle,
          );
          map.setPaintProperty(
            'ganganagarsegments',
            'fill-opacity',
            fillOpacityStyle,
          );
          // Set for Mankrola
          map.setPaintProperty(
            'mankrolasegments',
            'fill-color',
            fillColorStyle2,
          );
          map.setPaintProperty(
            'mankrolasegments',
            'fill-opacity',
            fillOpacityStyle2,
          );
        }
      }
    }
  }

  _onMapLoad = (map) => {
    this.mapContainer = map;
    const { landData } = this.props;
    if (
      window.location.hash === '#11/51.5286/-0.2417'
      || window.location.hash === ''
    ) {
      map.setZoom(config.mapboxConfig.defaultZoom);
      map.setCenter(config.mapboxConfig.defaultCenter);
    }

    /* setTimeout(()=>{
      map.flyTo({
        center:[73.87998050000002,29.9093759],
        zoom:13,
        speed:1
      })
    },2000) */

    /* map.addControl(
     new NavigationControl({
     showCompass: false,
     style: { backgroundColor: '#222' },
     }),
     );

     geolocate = new GeolocateControl({
     positionOptions: { enableHighAccuracy: true },
     showUserLocation: false,
     });
     map.addControl(geolocate); */

    /**
     * Adding WMS Layer Here
     * */

    map.addLayer({
      id: 'wms-test-layer',
      type: 'raster',
      source: {
        type: 'raster',
        scheme: 'xyz',
        tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
      },
      paint: {},
    });

    /**
     * WMS Layer Add End
     * */

    /**
     * Adding Segments Here
     * */

    map.addSource('ganganagarsegments', {
      type: 'vector',
      url: 'mapbox://farmguide.ganganagarsegments2',
    });

    let mappedFarmIds = landData.map(land => land.farmID);
    if (mappedFarmIds.length === 0) {
      mappedFarmIds = [''];
    }
    const newArrays = Array.from(new Set(mappedFarmIds));
    map.addLayer({
      id: 'ganganagarsegments',
      type: 'fill',
      minzoom: 15,
      source: 'ganganagarsegments',
      'source-layer': 'ganganagarsegments2',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': [
          'match',
          ['get', 'farmid'],
          newArrays,
          'hsl(124, 78%, 70%)',
          [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#f4df6e',
            'rgba(0,0,0,0)',
          ],
        ],
        'fill-opacity': [
          'match',
          ['get', 'farmid'],
          newArrays,
          0.75,
          ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
        ],
        'fill-outline-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          'rgba(0,0,0,0)',
          14,
          '#000000',
        ],
      },
    });

    map.addLayer({
      id: 'ganganagarsegmentsLine',
      type: 'line',
      source: 'ganganagarsegments',
      'source-layer': 'ganganagarsegments2',
      minzoom: 15,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#f4df6e',
          '#ffffff',
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          3.5,
          3,
        ],
      },
    });

    let hoversegment;
    // const self = this;
    map.on('mousemove', 'ganganagarsegments', (e) => {
      if (e.features.length > 0 && map.getZoom() > 13) {
        const addCursor = map.getCanvas();
        addCursor.style.cursor = 'pointer';
        // self.setState({
        //   properties: e.features[0].properties,
        // });

        if (hoversegment) {
          // set the hover attribute to false with feature state
          map.setFeatureState(
            {
              source: 'ganganagarsegments',
              sourceLayer: 'ganganagarsegments2',
              id: hoversegment,
            },
            {
              hover: false,
            },
          );
        }

        hoversegment = e.features[0].id;

        // set the hover attribute to true with feature state
        map.setFeatureState(
          {
            source: 'ganganagarsegments',
            sourceLayer: 'ganganagarsegments2',
            id: hoversegment,
          },
          {
            hover: true,
          },
        );
      }
    });

    map.on('mouseleave', 'ganganagarsegments', () => {
      // console.log('e', e);
      // self.setState({
      //   properties: undefined,
      // });
      if (hoversegment) {
        map.setFeatureState(
          {
            source: 'ganganagarsegments',
            sourceLayer: 'ganganagarsegments2',
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }
    });

    map.on('click', 'ganganagarsegments', (e) => {
      if (e.features.length > 0 && map.getZoom() > 13) {
        const featureGeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: e.features[0].geometry,
              properties: e.features[0].properties,
            },
          ],
        };
        const featureCenter = center(featureGeoJSON);
        map.flyTo({
          center: featureCenter.geometry.coordinates,
          zoom: 16,
          speed: 1,
        });

        if (map.getLayer('clickedFarm')) {
          const { handleDeSelectFarm } = this.props;
          const selectedFarmId = map.getSource('clickedFarm')._data.features[0]
            .properties.farmid;
          if (e.features[0].properties.farmid === selectedFarmId) {
            // Deselect Same Selected Farm
            map.removeLayer('clickedFarm');
            map.removeLayer('clickedFarmLine');
            map.removeSource('clickedFarm');
            handleDeSelectFarm();
          } else {
            // Selecting Farm Other than the earlier selected
            map.getSource('clickedFarm').setData(featureGeoJSON);
            this.handleFarmClick(e.features[0].properties.farmid);
          }
        } else {
          // Selecting Farm for the First Time
          map.addSource('clickedFarm', {
            type: 'geojson',
            data: featureGeoJSON,
          });

          map.addLayer({
            id: 'clickedFarm',
            type: 'fill',
            minzoom: 15,
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-opacity': 0.4,
              'fill-color': '#f4df6e',
            },
          });

          map.addLayer({
            id: 'clickedFarmLine',
            type: 'line',
            minzoom: 15,
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#f4df6e',
              'line-width': 3,
            },
          });
          this.handleFarmClick(e.features[0].properties.farmid);
        }
        // map.setPaintProperty('background', 'background-opacity', 0.4);
      }
    });

    // Mankrola Segments

    map.addSource('mankrolasegments', {
      type: 'vector',
      url: 'mapbox://farmguide.mankrolasegments',
    });

    map.addLayer({
      id: 'mankrolasegments',
      type: 'fill',
      minzoom: 15,
      source: 'mankrolasegments',
      'source-layer': 'mankrolasegments',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': [
          'match',
          ['get', 'farmid'],
          ['303c0a86-7034-4e89-9770-ab7130b1ee4d'],
          'hsl(124, 78%, 70%)',
          [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#f4df6e',
            'rgba(0,0,0,0)',
          ],
        ],
        'fill-opacity': [
          'match',
          ['get', 'farmid'],
          newArrays,
          0.75,
          ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
        ],
        'fill-outline-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          'rgba(0,0,0,0)',
          14,
          '#000000',
        ],
      },
    });

    map.addLayer({
      id: 'mankrolasegmentsLine',
      type: 'line',
      source: 'mankrolasegments',
      'source-layer': 'mankrolasegments',
      minzoom: 15,
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#f4df6e',
          '#ffffff',
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          3.5,
          3,
        ],
      },
    });

    let hovermankrolasegment;
    map.on('mousemove', 'mankrolasegments', (e) => {
      if (e.features.length > 0 && map.getZoom() > 13) {
        const addCursor = map.getCanvas();
        addCursor.style.cursor = 'pointer';

        if (hovermankrolasegment) {
          // set the hover attribute to false with feature state
          map.setFeatureState(
            {
              source: 'mankrolasegments',
              sourceLayer: 'mankrolasegments',
              id: hovermankrolasegment,
            },
            {
              hover: false,
            },
          );
        }

        hovermankrolasegment = e.features[0].id;

        // set the hover attribute to true with feature state
        map.setFeatureState(
          {
            source: 'mankrolasegments',
            sourceLayer: 'mankrolasegments',
            id: hovermankrolasegment,
          },
          {
            hover: true,
          },
        );
      }
    });

    map.on('mouseleave', 'mankrolasegments', () => {
      if (hoversegment) {
        map.setFeatureState(
          {
            source: 'mankrolasegments',
            sourceLayer: 'mankrolasegments',
            id: hovermankrolasegment,
          },
          {
            hover: false,
          },
        );
      }
    });

    map.on('click', 'mankrolasegments', (e) => {
      if (e.features.length > 0 && map.getZoom() > 13) {
        const featureGeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: e.features[0].geometry,
              properties: e.features[0].properties,
            },
          ],
        };

        const featureCenter = center(featureGeoJSON);
        map.flyTo({
          center: featureCenter.geometry.coordinates,
          zoom: 16,
          speed: 1,
        });

        if (map.getLayer('clickedFarm')) {
          const selectedFarmId = map.getSource('clickedFarm')._data.features[0]
            .properties.farmid;
          if (e.features[0].properties.farmid === selectedFarmId) {
            map.removeLayer('clickedFarm');
            map.removeLayer('clickedFarmLine');
            map.removeSource('clickedFarm');
          } else {
            map.getSource('clickedFarm').setData(featureGeoJSON);
          }
        } else {
          map.addSource('clickedFarm', {
            type: 'geojson',
            data: featureGeoJSON,
          });

          map.addLayer({
            id: 'clickedFarm',
            type: 'fill',
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-opacity': 0.4,
              'fill-color': '#f4df6e',
            },
          });

          map.addLayer({
            id: 'clickedFarmLine',
            type: 'line',
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#f4df6e',
              'line-width': 3,
            },
          });
        }
        this.handleFarmClick(e.features[0].properties.farmid);
        // console.log('Farm Properties ', e.features[0].properties);
        // map.setPaintProperty('background', 'background-opacity', 0.3);
      }
    });
  };

  _onMapClick = (map, evt) => {
    const features = map.queryRenderedFeatures(evt.point, {
      layers: ['ganganagarsegments', 'mankrolasegments'],
    });
    if (features.length === 0) {
      if (map.getLayer('clickedFarm')) {
        map.removeLayer('clickedFarm');
        map.removeLayer('clickedFarmLine');
        map.removeSource('clickedFarm');
      }
      this.handleFarmClick('');
    }
  };

  handleFarmClick = (farmid) => {
    const { searchPlace } = this.state;
    const { handleFarmTag } = this.props;
    // console.log('Call Farm Tag API Here ', farmid);
    handleFarmTag(farmid, searchPlace);
  };

  handlePlaceChange = (e) => {
    const self = this;
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete_search'),
      {
        types: ['geocode'],
      },
    );

    /**
     * fire event when a suggetsed place is selected
     * */

    autocomplete.addListener('place_changed', () => {
      // const place = autocomplete.getPlace();
      // console.log('place', place);
      self.searchAddress(document.getElementById('autocomplete_search').value);
    });
    this.setState({
      searchPlace: e.target.value,
    });
  };

  searchAddress = async (address) => {
    const map = this.mapContainer;
    if (address) {
      this.setState({
        searchPlace: address,
      });
    }
    const response = await axios(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBFKufGnFZrQZeCPLpt1ALrzUlVETWBI_I`,
    );
    // console.log('response>>>>', response);

    if (response.status) {
      const data = response.data.results[0].geometry.location;
      map.flyTo({ center: [data.lng, data.lat], zoom: 17 });
      this.setState({
        searchPlace: '',
        addExpandClass: false,
      });
    }
  };

  /* handleSearchPlace = async () => {
    const { searchPlace } = this.state;
    const map = this.mapContainer;
    const response = await ajax(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${searchPlace}&key=${config.GOOGLE_MAP_API_KEY}`,
    );
    if (response.status) {
      const data = response.data.results[0].geometry.location;
      map.flyTo({ center: [data.lng, data.lat], zoom: 17 });
    }
  }; */

  handleZoomChange = (type) => {
    if (this.mapContainer) {
      switch (type) {
        case 'plus':
          this.mapContainer.zoomIn();
          break;

        case 'minus':
          this.mapContainer.zoomOut();
          break;

        default:
          break;
      }
    }
  };

  _onZoomEnd = (map) => {
    const { handleDeSelectFarm } = this.props;
    const zoom = map.getZoom();
    console.log('zoom', zoom);
    if (zoom < 15 && map.getLayer('clickedFarm')) {
      map.removeLayer('clickedFarm');
      map.removeLayer('clickedFarmLine');
      map.removeSource('clickedFarm');
      handleDeSelectFarm();
    }
  };

  geolocateUser = () => {
    // console.log('Window  ', window.navigator.geolocation);
    if (window.navigator.geolocation) {
      this.setState({
        searchPlace: '',
      });
      window.navigator.geolocation.getCurrentPosition((position) => {
        this.mapContainer.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 17,
          bearing: 0,
          speed: 1, // make the flying slow
        });
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  /* _handleKeyPress = (e) => {
    console.log('e', e.key);
    if (e.key === 'Enter') {
      this.handleSearchPlace();
    }
  }; */
  handleClickSearch = () => {
    this.setState({
      addExpandClass: true,
    });
  };

  render() {
    const { searchPlace, showMap, addExpandClass } = this.state;
    return (
      <React.Fragment>
        <div
          className={
            addExpandClass ? 'searchPanel expandSearch' : 'searchPanel'
          }
        >
          <input
            type="text"
            id="autocomplete_search"
            value={searchPlace}
            onChange={e => this.handlePlaceChange(e)}
            placeholder="Enter search location"
          />
          <button
            type="button"
            className="searchBtn"
            onClick={this.handleClickSearch}
          >
            <i className="material-icons">search</i>
          </button>
        </div>

        {/* <div className="searchPanel">
          <input
            type="text"
            id="autocomplete_search"
            value={searchPlace}
            onChange={this.handlePlaceChange}
            placeholder="Enter search location"
          />
          <button type="button" onClick={this.handleSearchPlace}>
            <i className="material-icons">search</i>
          </button>
        </div> */}
        {/* Chat Control Start */}
        <div className="chatHead">
          <i className="material-icons">
            chat
            <span>9</span>
          </i>
        </div>
        {/* Chat Control End */}
        {showMap
          ? [
            <Map
              key={0}
              attributionControl={false}
              className="mapComponent mapCtrlChanges"
              container="map"
              logoPosition="bottom-left"
              movingMethod="flyTo"
              onStyleLoad={this._onMapLoad}
              onClick={this._onMapClick}
              onZoomEnd={this._onZoomEnd}
              style={config.mapboxConfig.blankStyle}
            />,
            <div key={1} className="mapboxgl-ctrl-top-right">
              <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                <button
                  className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
                  type="button"
                  title="Zoom in"
                  aria-label="Zoom in"
                  onClick={() => this.handleZoomChange('plus')}
                />
                <button
                  className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
                  type="button"
                  title="Zoom out"
                  aria-label="Zoom out"
                  onClick={() => this.handleZoomChange('minus')}
                />
              </div>
              <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                <button
                  className="mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate"
                  type="button"
                  aria-label="Geolocate"
                  onClick={this.geolocateUser}
                />
              </div>
            </div>,
          ]
          : 'Loading map'}
      </React.Fragment>
    );
  }
}
