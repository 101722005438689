/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Button, Grid } from '@material-ui/core';
import CampaignList from './campaginList';
import CampaignResult from './campaginResult';
import Loader from '../common/customLoader';
import {
  getCampaignList,
  getCampaignReport,
  resetCampaignDetails
} from '../../Actions/taggingAction';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCampaignId: ''
    };
  }

  componentDidMount() {
    const { fetchCampaignList, resetDetails, cookies, type } = this.props;
    resetDetails();
    const data = {};
    data.orgId = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.type = type === 'sms' ? 3 : 1;
    const userData = cookies.get('userData');
    if (userData) {
      fetchCampaignList(data, userData.token, 'tagging');
    }
  }

  componentDidUpdate(prevProps) {
    const { campaignList } = this.props;
    if (campaignList.length > prevProps.campaignList) {
      this.updateCampaignReport();
    }
  }

  updateCampaignReport = () => {
    const { fetchCampaignReport, campaignList } = this.props;
    const defaultCampaign = campaignList[0];
    this.setState({
      selectedCampaignId: defaultCampaign.campaignID
    });
    if (!_.isEmpty(defaultCampaign)) {
      const data = {};
      data.campaignId = defaultCampaign.campaignID.toString();
      fetchCampaignReport(data);
    }
  };

  handelCampaignSelected = id => {
    const { fetchCampaignReport } = this.props;
    this.setState({
      selectedCampaignId: id
    });
    const data = {};
    data.campaignId = id.toString();
    fetchCampaignReport(data);
  };

  handleTabChange = () => {
    const { anotherTab } = this.props;
    anotherTab();
  };

  render() {
    const { selectedCampaignId } = this.state;
    const { campaignList, campaignReport, isLoading, type } = this.props;
    return (
      <React.Fragment>
        {isLoading ? (
          <div className="ivrsReportMain">
            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                <div className="ivrsReportContent">
                  <div className="ivrsReportBody">
                    <Loader />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : campaignList && campaignList.length > 0 ? (
          <div className="taggingMain insightLeftPanel">
            <h5>Reports</h5>
            <Grid
              container
              spacing={0}
              className="reportSection"
              style={{ border: '2px solid #01a053' }}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <CampaignList
                  campaignList={campaignList}
                  campaignSelected={this.handelCampaignSelected}
                  selectedCampaignId={selectedCampaignId}
                  type={type}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <CampaignResult
                  campaignReport={campaignReport}
                  type={type}
                  handleTabChange={this.handleTabChange}
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="ivrsReportMain">
            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                <div className="ivrsReportContent">
                  <div className="ivrsReportBody">
                    <img src="images/campaign.svg" alt="" title="" />
                    <h1>No campaign available</h1>
                    <div className="btn">
                      <Button
                        onClick={this.handleTabChange}
                        className="btn-success"
                      >
                        Create new campaign
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging }) => ({
      campaignList: tagging.campaignList,
      campaignReport: tagging.campaignReport,
      isLoading: tagging.isLoading
    }),
    dispatch => ({
      fetchCampaignReport: data => dispatch(getCampaignReport(data)),
      fetchCampaignList: (data, token, type) =>
        dispatch(getCampaignList(data, token, type)),
      resetDetails: () => dispatch(resetCampaignDetails())
    })
  )(withCookies(Reports))
);
