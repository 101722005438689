const initialState = {
  levelStats: {
    cropsRankingResult: [],
    generalStats: {
      totalFarmArea: '',
      totalFarms: '',
      averageFarmSize: '',
      averageFarmRating: '',
    },
  },
  farmStats: {
    generalStats: {
      cropCode: '',
      cropName: '',
      farmArea: '',
      farmrating: '',
      ndvi: '',
      sowingDate: '',
      harvestDate: '',
      irrigation: '',
    },
    cropsRankingResult: [],
  },
  fetching: false,
  weatherData: [],
  nearbyData: {},
  farmDetails: {},
  cropRankingParam: [
    'ACREAGE',
    'IRRIGATION',
    'DISTANCE',
    'AVG_FARM_RATING',
    'AVG_FARM_SIZE',
  ],
  selectedCropRankingParam: 'ACREAGE',
  cropsArray: [],
  selectedCrop: '',
  levelStatsFetching: '',
  topCrops: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LEVEL_STATS_SUCCESS':
      return {
        ...state,
        levelStats: action.payload,
        levelStatsFetching: false,
      };

    case 'GET_LEVEL_STATS_FAILURE':
      return {
        ...state,
        levelStats: {
          cropsRankingResult: [],
          generalStats: {
            totalFarmArea: '',
            totalFarms: '',
            averageFarmSize: '',
            averageFarmRating: '',
          },
        },
        levelStatsFetching: false,
      };

    case 'FETCHING_FARM_STATS':
      return {
        ...state,
        fetching: true,
      };

    case 'GET_FARM_STATS_SUCCESS':
      return {
        ...state,
        farmStats: action.payload,
        fetching: false,
        levelStatsFetching: false,
      };

    case 'GET_FARM_STATS_FAILURE':
      return {
        ...state,
        farmStats: {
          generalStats: {
            cropCode: '',
            cropName: '',
            farmArea: '',
            farmrating: '',
            ndvi: '',
            sowingDate: '',
            harvestDate: '',
            irrigation: '',
          },
          cropsRankingResult: [],
        },
        fetching: false,
        levelStatsFetching: false,
      };
    case 'CLEAR_FARM_STAT':
      return {
        ...state,
        farmStats: {
          generalStats: {
            cropCode: '',
            cropName: '',
            farmArea: '',
            farmrating: '',
            ndvi: '',
            sowingDate: '',
            harvestDate: '',
            irrigation: '',
          },
          cropsRankingResult: [],
        },
        nearbyData: {},
        farmDetails: {},
        weatherData: [],
        fetching: false,
      };

    case 'GET_WEATHER_STATS_SUCCESS':
      return {
        ...state,
        weatherData: action.payload,
      };

    case 'GET_WEATHER_STATS_FAILURE':
      return {
        ...state,
        weatherData: [],
      };
    case 'GET_NEARBY_STATS_SUCCESS':
      return {
        ...state,
        nearbyData: action.payload.nearbuy,
        farmDetails: action.payload.farmDetails,
      };

    case 'GET_NEARBY_STATS_FAILURE':
      return {
        ...state,
        nearbyData: {},
        farmDetails: {},
      };

    case 'SET_CROP_RANKING_PARAM':
      return {
        ...state,
        selectedCropRankingParam: action.payload,
      };

    case 'GET_CROPS_REGIONWISE_SUCCESS':
      return {
        ...state,
        cropsArray: action.payload,
      };

    case 'GET_CROPS_REGIONWISE_FAILURE':
      return {
        ...state,
        cropsArray: [],
      };

    case 'SET_CROP':
      return {
        ...state,
        selectedCrop: action.payload,
      };

    case 'GET_TOP_CROPS_SUCCESS':
      return {
        ...state,
        topCrops: action.payload,
      };

    case 'GET_TOP_CROPS_FAILURE':
      return {
        ...state,
        topCrops: [],
      };

    case 'SET_LEVEL_STATS_LOADER':
      return {
        ...state,
        levelStatsFetching: action.payload,
      };

    default:
      return state;
  }
};
