import {
  createStore, combineReducers, compose, applyMiddleware,
} from 'redux';

import ReduxThunk from 'redux-thunk';
// import axios from 'axios';

import appReducer from './appReducer';
import locationReducer from '../commonReducers/locationHierarchyReducer';
import acccountSetUpReducer from './accountSetUpReducer';
import landingPageReducer from './landingPageReducer';
import afterLoginHomeReducer from './afterLoginHomeReducer';
import agricultureIndicesReducer from './agricultureIndicesReducer';
import cropReducer from './cropReducer';
import weatherReducer from './weatherReducer';
import ivrsReducer from './ivrsReducer';
import taggingReducer from './taggingReducer';
import farmAccountsReducer from './farmAccountsReducer';
import coverageReducer from './coverageReducer';
import surveyReducer from './surveyReducer';

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => (action) => {
  console.group(action.type);
  console.info('dispatching', action);
  const result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

// if you're also using redux-thunk, add it as a middleware
const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk, logger))(
  createStore,
);

const rootReducer = combineReducers({
  app: appReducer,
  landing: landingPageReducer,
  location: locationReducer,
  farmTag: acccountSetUpReducer,
  home: afterLoginHomeReducer,
  agricultureIndices: agricultureIndicesReducer,
  crop: cropReducer,
  weather: weatherReducer,
  ivrs: ivrsReducer,
  tagging: taggingReducer,
  farmaccounts: farmAccountsReducer,
  coverage: coverageReducer,
  survey: surveyReducer,
});

function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}

const store = module.hot
  ? configureStore(window.__REDUX_STATE__ || {})
  : configureStore({});

export { configureStore, store };
