import React from 'react';
import {
  Grid, TableRow, TableCell, Button,
} from '@material-ui/core';
import ModalHOC from '../common/Modal/index';
import TableHOC from '../common/Table';

const THEAD_LIST = [
  'Multi-Input',
  'Dynamic-Input',
  'Key Pressed',
  'Value Saved',
];

function createData(multiInput, dynamicInput, input, value) {
  return {
    multiInput,
    dynamicInput,
    input,
    value,
  };
}

class ChipInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    const { onCloseModal } = this.props;
    onCloseModal();
  };

  render() {
    const { show, activeChip } = this.props;
    const rows = [];
    if (activeChip !== null) {
      rows.push(
        createData(
          activeChip.isMultiInput,
          activeChip.isDynamicInput,
          activeChip.inputs,
          activeChip.values,
        ),
      );
    }
    const TBODY_DATA = rows.map(row => (
      <TableRow hover key={Math.random()}>
        <TableCell>
          {row.multiInput
            ? 'Accepts multiple key presses as input during call'
            : 'Accepts only one key press as input during call'}
        </TableCell>
        <TableCell>
          {row.dynamicInput
            ? 'This card plays an audio based on dynamic input by call receiver'
            : 'Plays a static audio with no dependency on previous cards'}
        </TableCell>
        <TableCell>
          {Object.prototype.hasOwnProperty.call(activeChip, 'inputs') ? (
            <ul>
              {row.input.map(item => (
                <li key={Math.random()}>{item.toString()}</li>
              ))}
            </ul>
          ) : (
            'Not Available'
          )}
        </TableCell>
        <TableCell>
          {Object.prototype.hasOwnProperty.call(activeChip, 'values') ? (
            <ul>
              {row.value.map(item => (
                <li key={Math.random()}>{item.toString()}</li>
              ))}
            </ul>
          ) : (
            'Not Available'
          )}
        </TableCell>
      </TableRow>
    ));
    return (
      <div>
        <ModalHOC
          isOpen={show}
          customContent="chipInfoModal"
          onClose={this.handleClose}
          titleContent={
            activeChip !== null ? `${activeChip.name} - Details` : null
          }
          footerComponent={(
            <Button
              style={{ backgroundColor: '#09B466', color: '#fff' }}
              onClick={this.handleClose}
            >
              Ok
            </Button>
)}
          customClass="modal-position-top"
        >
          <Grid container>
            <div className="insightTable tableWrapper">
              <TableHOC tHeadList={THEAD_LIST} tBody={TBODY_DATA} />
            </div>
          </Grid>
        </ModalHOC>
      </div>
    );
  }
}

export default ChipInfoModal;
