import React from 'react';
import {
  Grid, Link, Button, Paper,
} from '@material-ui/core';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';
// import TaggingFixedPanelCopy from './common/taggingFixedPanelCopy';
import TaggingFixedPanel from './common/taggingFixedPanel';
// import SelectPoster from './selectPoster';
import TaggingTab from './taggingTab';
import '../../css/common.scss';
import '../../css/tagging/main.scss';
import '../../css/button.scss';
import '../../css/tagging/insights.scss';

const piChartData = [
  { name: 'Group A', value: 400, color: '#0088FE' },
  { name: 'Group B', value: 200, color: '#36B37E' },
  { name: 'Group C', value: 100, color: '#FF8B00' },
];

const COLORS = ['#425cfb', '#f5a623', '#fe6b29'];

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeTab: 0,
      calledFrom: '',
    };
  }

  handleTabChange = () => {
    const { anotherTab } = this.props;
    // this.setState({
    //   activeTab: 1,
    // });
    this.setState({
      calledFrom: 'insightPage',
    });
    anotherTab('Tagging');
  };

  render() {
    const { calledFrom } = this.state;
    const { activeTab } = this.props;
    return (
      <React.Fragment>
        {activeTab === 0 ? (
          <div className="insightPanel">
            {/* <Grid container className="taggingMain">
              <Grid item lg={9} xs={12} className="insightLeftPanel">
                <div className="recommendedPanel darkgreenBtnHeader">

                </div>
              </Grid>
            </Grid> */}
            <Grid container className="taggingMain" spacing={2}>
              <Grid item lg={9} md={9} xs={12} className="insightLeftPanel">
                <div className="recommendedPanel darkgreenBtnHeader">
                  <Grid container className="portfolio">
                    <Grid item lg={6} md={6} xs={12}>
                      <Paper className="box">
                        <div className="box-content">
                          <div className="box-header">
                            <h1>My Portfolio</h1>
                          </div>
                          <div className="box-body">
                            <Grid container className="box-details">
                              <Grid item lg={6} md={6} xs={12}>
                                <ul>
                                  <li>Total Applications: 1100</li>
                                  <li>Total Acreage: 1100</li>
                                  <li>Major 3 crop: Wheat,Maze,Rice</li>
                                </ul>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <ul>
                                  <li>Sum Insured: 50%</li>
                                  <li>Total premium: 20% </li>
                                </ul>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="good">Good 50%</span>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="average">Average 30%</span>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="poor">Poor 20%</span>
                              </Grid>
                            </Grid>
                            <Grid container className="box-btn">
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                                className="greenBtnBorderHeader"
                              >
                                <Button className="greenBorderBtn">
                                  My Resources
                                </Button>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                                className="greenBtnBorderHeader"
                              >
                                <Button className="greenBorderBtn">GIS</Button>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Paper className="box">
                        <div className="box-content">
                          <div className="box-header">
                            <h1>New Leads</h1>
                          </div>
                          <div className="box-body">
                            <Grid container className="box-details">
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                                className="leads"
                              >
                                <ul>
                                  <li>Total Number of Farmers: 50</li>
                                  <li>Average Farm Acreage: 1100</li>
                                  <li>Farm Rating: 3.5</li>
                                  <li>Potential Premium: 55</li>
                                  <li>Claim Risk: 55%</li>
                                </ul>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <div className="box-chart">
                                  <ResponsiveContainer
                                    width="100%"
                                    height={300}
                                  >
                                    <PieChart>
                                      <Pie
                                        data={piChartData}
                                        cx={120}
                                        cy={200}
                                        dataKey="value"
                                        innerRadius={40}
                                        outerRadius={60}
                                        fill="#8884d8"
                                        paddingAngle={5}
                                      >
                                        {piChartData.map((entry, index) => (
                                          <Cell
                                            key={Math.random()}
                                            fill={COLORS[index % COLORS.length]}
                                          />
                                        ))}
                                      </Pie>
                                      <Tooltip />
                                    </PieChart>
                                  </ResponsiveContainer>
                                  {/* <PieChart
                                    data={piChartData}
                                    dataKey="value"
                                    showLegend={false}
                                  /> */}
                                </div>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="good">Good 50%</span>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="average">Average 30%</span>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <span className="poor">Poor 20%</span>
                              </Grid>
                            </Grid>
                            <Grid container className="box-btn">
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                                className="greenBtnBorderHeader"
                              >
                                <Button className="greenBorderBtn">
                                  New Opportunity
                                </Button>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                                className="greenBtnBorderHeader"
                              >
                                <Button className="greenBorderBtn">GIS</Button>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <h5>
                        <span>Disclaimer: </span>
                        {' '}
At vero eos et accusamus et
                        iusto odio dignissimos ducimus qui blanditiis
                        praesentium voluptatum deleniti atque corrupti
                        voluptatum deleniti atque corr.
                      </h5>
                    </Grid>
                  </Grid>
                </div>
                <h5>
                  Insights
                  <Link to="/">edit setting</Link>
                </h5>
                <div className="recommendedPanel darkgreenBtnHeader">
                  <h6>Recomended</h6>
                  <ul>
                    <li>
                      <i>15</i>
                      <div className="tagInfo">
                        <p>
                          Send pesonalize posters to your farmers to generate
                          leads.
                        </p>
                        <ol>
                          <li className="highRisk">High risk</li>
                          <li>Crop Health 89%</li>
                          <li className="business">Business Opportunity</li>
                          <li>Download Report</li>
                        </ol>
                      </div>
                      <div className="greenBtnBorderHeader">
                        <Button
                          className="greenBorderBtn"
                          onClick={this.handleTabChange}
                        >
                          Choose Posters
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="resultPanel greenBtnBorderHeader">
                  <h6>Related Results</h6>
                  <ul>
                    <li>
                      <i>15</i>
                      <div className="tagInfo">
                        <p>
                          Send pesonalize posters to your farmers to generate
                          leads.
                        </p>
                        <ol>
                          <li className="highRisk">High risk</li>
                          <li>Rainfall: 130mm (30% above average!)</li>
                          <li className="business">Business Opportunity</li>
                        </ol>
                      </div>
                      <div className="tagBtn">
                        <Button className="greenBorderBtn">
                          Get farm tags
                        </Button>
                        <Button className="greenBorderBtn">
                          Send Advisory
                        </Button>
                      </div>
                    </li>
                    <li>
                      <i>15</i>
                      <div className="tagInfo">
                        <p>
                          Send pesonalize posters to your farmers to generate
                          leads.
                        </p>
                        <ol>
                          <li className="business">Business Opportunity</li>
                          <li>Business Opportunity</li>
                          <li className="highRisk">High risk</li>
                        </ol>
                      </div>
                      <div className="tagBtn">
                        <Button className="greenBorderBtn">
                          Get farm tags
                        </Button>
                        <Button className="greenBorderBtn">
                          Send Advisory
                        </Button>
                      </div>
                    </li>
                    <li>
                      <i>15</i>
                      <div className="tagInfo">
                        <p>
                          Send pesonalize posters to your farmers to generate
                          leads.
                        </p>
                        <ol>
                          <li>Business Opportunity</li>
                          <li className="business">Business Opportunity</li>
                          <li className="highRisk">High risk</li>
                        </ol>
                      </div>
                      <div className="tagBtn">
                        <Button className="greenBorderBtn">
                          Get farm tags
                        </Button>
                        <Button className="greenBorderBtn">
                          Send Advisory
                        </Button>
                      </div>
                    </li>
                    <li>
                      <i>15</i>
                      <div className="tagInfo">
                        <p>
                          Send pesonalize posters to your farmers to generate
                          leads.
                        </p>
                        <ol>
                          <li className="business">Business Opportunity</li>
                          <li>Business Opportunity</li>
                          <li className="highRisk">High risk</li>
                        </ol>
                      </div>
                      <div className="tagBtn">
                        <Button className="greenBorderBtn">
                          Get farm tags
                        </Button>
                        <Button className="greenBorderBtn">
                          Send Advisory
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </Grid>
              <TaggingFixedPanel />
              {/*   <TaggingFixedPanelCopy /> */}
            </Grid>
          </div>
        ) : (
          <TaggingTab calledFrom={calledFrom} />
        )}
      </React.Fragment>
    );
  }
}

export default Insights;
