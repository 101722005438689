import React from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import ModalHOC from '../common/Modal/index';
import Pagination from '../common/pagination';
import '../../css/tagging/farmerList.scss';

function createData(
  name,
  mobile,
  farmerID,
  state,
  district,
  tehsil,
  viilage,
  pincode,
) {
  return {
    name,
    mobile,
    farmerID,
    state,
    district,
    tehsil,
    viilage,
    pincode,
  };
}

class FarmerListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { show, taggedList } = this.props;
    const rows = [];
    if (taggedList && taggedList.length > 0) {
      for (let i = 0; i < taggedList.length; i += 1) {
        rows.push(
          createData(
            taggedList[i].name,
            taggedList[i].mobile,
            taggedList[i].farmerID,
            taggedList[i].state,
            taggedList[i].district,
            taggedList[i].tehsil,
            taggedList[i].viilage,
            taggedList[i].pincode,
          ),
        );
      }
    }
    return (
      <div>
        <ModalHOC
          isOpen={show}
          onClose={this.handleClose}
          titleContent="Farmers Added"
          customContent="uploaded-list-modal"
        >
          <Grid container spacing={2}>
            <div className="insightTable tableWrapper">
              <div className="scrollPanel">
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>District</TableCell>
                      <TableCell>Tehsil</TableCell>
                      <TableCell>Village</TableCell>
                      <TableCell>Pincode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map(row => (
                        <TableRow key={row.farmerID}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.mobile}</TableCell>
                          <TableCell>{row.state}</TableCell>
                          <TableCell>{row.district}</TableCell>
                          <TableCell>{row.tehsil}</TableCell>
                          <TableCell>{row.village}</TableCell>
                          <TableCell>{row.pincode}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <Pagination
                rowsPerPage={rowsPerPage}
                page={page}
                totalCount={rows.length}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </ModalHOC>
      </div>
    );
  }
}

export default FarmerListModal;
