import _ from 'lodash';

const initialState = {
  agricultureIndicesTimeline: [],
  bounds: [],
  compareBounds: [],
  indicesValue: [],
  indicesValueComparision: [],
  agricultureIndicesTimelineComparison: [],
  satSource: [],
  sourceIndice: {},
  indicesChange: '',
  startDate: '',
  endDate: '',
  startDateComparision: '',
  endDateComparision: '',
  indicesImages: [],
  compareIndicesImages: [],
  selectedFarm: '',
  selectedCompareFarm: '',
  indicesTimeLineError: '',
  timeseriesData: {},
  timeseriesDataChange: {},
  indicesTimeline: {},
  indicesTimelineComparison: {},
  indicesLegends: [],
  compareIndicesLegends: [],
};

export default (state = initialState, action) => {
  let result;
  switch (action.type) {
    case 'INDICES_TIMELINE_SUCCESS':
      result = [...state.agricultureIndicesTimeline, ...action.payload];
      result = _.uniqBy(result, 'date');
      return {
        ...state,
        agricultureIndicesTimeline: result,
        bounds: action.bounds,
        satellitesSource: action.satellitesSource,
        indicesValue: action.indicesValue,
        startDate: action.startDate,
        endDate: action.endDate,
        error: '',
      };

    case 'SKIP_TIMELINE':
      return {
        ...state,
        bounds: action.bounds,
        satellitesSource: action.satellitesSource,
        indicesValue: action.indicesValue,
        startDate: action.startDate,
        endDate: action.endDate,
        error: '',
      };

    case 'BOUNDS_UPDATE':
      return {
        ...state,
        bounds: action.bounds,
        indicesValue: action.indicesValue,
        satellitesSource: action.satellitesSource,
        startDate: action.startDate,
        endDate: action.endDate,
        selectedFarm: action.selectedFarm,
      };

    case 'INDICES_TIMELINE_FAILURE':
      return {
        ...state,
        agricultureIndicesTimeline: [],
        bounds: action.bounds,
        indicesValue: action.indicesValue,
        satellitesSource: action.satellitesSource,
        startDate: action.startDate,
        endDate: action.endDate,
        indicesTimeLineError: action.payload,
        selectedFarm: action.selectedFarm,
      };

    case 'INDICES_TIMELINE_COMPARISON_SUCCESS':
      return {
        ...state,
        agricultureIndicesTimelineComparison: action.payload,
        indicesValueComparision: action.indicesValueComparision,
        startDateComparision: action.startDateComparision,
        endDateComparision: action.endDateComparision,
        selectedCompareFarm: action.selectedCompareFarm,
      };

    case 'BOUNDS_UPDATE_COMPARISION':
      return {
        ...state,
        compareBounds: action.bounds,
        indicesValueComparision: action.indicesValueComparision,
        startDateComparision: action.startDateComparision,
        endDateComparision: action.endDateComparision,
        selectedCompareFarm: action.selectedCompareFarm,
      };

    case 'INDICES_TIMELINE_COMPARISON_FAILURE':
      return {
        ...state,
        agricultureIndicesTimelineComparison: [],
        indicesValueComparision: action.indicesValueComparision,
        startDateComparision: action.startDateComparision,
        endDateComparision: action.endDateComparision,
        selectedCompareFarm: action.selectedCompareFarm,
      };

    case 'RESET_NDVI_ACTION':
      return {
        ...state,
        agricultureIndicesTimeline: [],
        agricultureIndicesTimelineComparison: [],
        // bounds: [],
        // endDate: '',
        endDateComparision: '',
        // startDate: '',
        startDateComparision: '',
        indicesChange: '',
        indicesTimeLineError: '',
        selectedFarm: '',
        selectedCompareFarm: '',
        indicesImages: [],
        compareIndicesImages: [],
        indicesTimeline: {},
        indicesTimelineComparison: {},
      };

    case 'SATSOURCE_SUCCESS':
      return {
        ...state,
        satSource: action.payload.sources,
        sourceIndice: action.payload.sourceIndice,
      };

    case 'SATSOURCE_FAILURE':
      return {
        ...state,
        satSource: [],
        sourceIndice: {},
      };

    case 'INDICES_ALERT_SUCCESS':
      return {
        ...state,
        indicesChange: action.payload,
      };

    case 'INDICES_ALERT_FAILURE':
      return {
        ...state,
        indicesChange: '',
      };

    case 'INDICES_IMAGES_FETCHING':
      return {
        ...state,
        indicesImages: [],
        selectedFarm: action.payload,
      };

    case 'INDICES_IMAGES_SUCCESS':
      return {
        ...state,
        indicesImages: action.payload,
      };

    case 'INDICES_IMAGES_FAILURE':
      return {
        ...state,
        indicesImages: [],
        selectedFarm: '',
      };

    case 'COMPARE_INDICES_IMAGES_FETCHING':
      return {
        ...state,
        compareIndicesImages: [],
        selectedCompareFarm: action.payload,
      };

    case 'COMPARE_INDICES_IMAGES_SUCCESS':
      return {
        ...state,
        compareIndicesImages: action.payload,
      };

    case 'COMPARE_INDICES_IMAGES_FAILURE':
      return {
        ...state,
        compareIndicesImages: [],
        selectedCompareFarm: '',
      };

    case 'TIMESERIES_DATA_FETCHED':
      return {
        ...state,
        timeseriesData: action.payload,
      };
    case 'TIMESERIES_DATA_FETCHING_FAILED':
      return {
        ...state,
        timeseriesData: {},
      };
    case 'TIMESERIES_CHANGE_DATA_FETCHED':
      return {
        ...state,
        timeseriesDataChange: action.payload,
      };
    case 'TIMESERIES_CHANGE_DATA_FETCHING_FAILED':
      return {
        ...state,
        timeseriesDataChange: {},
      };

    case 'TIMESERIES_DATA_WITHOUT_COMPARE_FETCHED':
      return {
        ...state,
        indicesTimeline: action.payload,
      };
    case 'TIMESERIES_DATA_WITHOUT_COMPARE_FAILED':
      return {
        ...state,
        indicesTimeline: {},
      };
    case 'TIMESERIES_DATA_WITH_COMPARE_FETCHED':
      return {
        ...state,
        indicesTimelineComparison: action.payload,
      };
    case 'TIMESERIES_DATA_WITH_COMPARE_FAILED':
      return {
        ...state,
        indicesTimelineComparison: {},
      };
    case 'INDICE_LEGENDS_SUCCESS':
      return {
        ...state,
        indicesLegends: action.payload,
      };
    case 'CLEAR_INDICE_LEGENDS':
    case 'INDICE_LEGENDS_FAILURE':
      return {
        ...state,
        indicesLegends: [],
      };

    case 'COMPARE_INDICE_LEGENDS_SUCCESS':
      return {
        ...state,
        compareIndicesLegends: action.payload,
      };
    case 'CLEAR_COMPARE_INDICE_LEGENDS':
    case 'COMPARE_INDICE_LEGENDS_FAILURE':
      return {
        ...state,
        compareIndicesLegends: [],
      };
    default:
      return state;
  }
};
