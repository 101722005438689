import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from '@material-ui/core';
// import MultiSelect from 'react-select';
import DateRangeClone from '../dashboard/dateRangeClone';
import { farmeList } from '../../Actions/taggingAction';
import { resetLocationAct } from '../../Actions/locationHierarchyAction';
import '../../css/ivrs/ivrsModal.scss';
import Pagination from '../common/pagination';
import LocationHeirarchy from '../farmEngine/locationHeirarchy';
import '../../css/common/commonModal.scss';

function createData(
  name,
  mobile,
  farmerID,
  state,
  district,
  tehsil,
  viilage,
  pincode,
) {
  return {
    name,
    mobile,
    farmerID,
    state,
    district,
    tehsil,
    viilage,
    pincode,
  };
}

// const indicesOptions = [];

class ListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openDrawer: false,
      // indicesValue: [],
      selected: [],
      selectedFarmerList: [],
      // isSelectAllChecked: false,
      allSelectedFarmerPerPage: [],
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    const { resetLocationDetails } = this.props;
    resetLocationDetails();
    this.fetchFarmerData();
  }

  fetchFarmerData = (stateName, districtName, tehsilName) => {
    const { getFarmerList, cookies, resetLocationDetails } = this.props;
    const { page, startDate, endDate } = this.state;
    const userData = cookies.get('userData');
    let orgId = '';
    if (userData) {
      const { data } = userData;
      if (data) {
        if (data.userData) {
          if (data.userData.orgID) {
            orgId = data.userData.orgID;
          }
        }
      }
    }
    const data = {
      orgId,
      page: page + 1,
      limit: 10,
      filters: {
        state: stateName || '',
        district: districtName || '',
        tehsil: tehsilName || '',
        village: '',
        pincode: '',
        from: startDate
          ? startDate
            .split('-')
            .reverse()
            .join('-')
          : '',
        to: endDate
          ? endDate
            .split('-')
            .reverse()
            .join('-')
          : '',
      },
    };
    getFarmerList(data);
    resetLocationDetails();
    this.setState({
      startDate: null,
      endDate: null,
    });
  };

  handleChangePage = (event, newPage) => {
    const { allSelectedFarmerPerPage, selected, page } = this.state;
    const { farmersList } = this.props;
    const { farmers } = farmersList;
    if (allSelectedFarmerPerPage.length > 0) {
      const farmersData = farmers.filter((item) => {
        console.log('item', item);
        return allSelectedFarmerPerPage.includes(item.farmerID);
      });
      const findIndex = selected.findIndex(item => item.page === page);
      if (findIndex !== -1) {
        selected[findIndex].id = allSelectedFarmerPerPage;
        selected[findIndex].data = farmersData;
      } else {
        const newObject = {
          id: allSelectedFarmerPerPage,
          page,
          data: farmersData,
        };
        selected.push(newObject);
      }
    }
    console.log('selected12', selected);
    this.setState(
      {
        page: newPage,
        selected,
        allSelectedFarmerPerPage: [],
      },
      () => {
        const result = selected.filter(item => item.page === newPage);
        if (result.length > 0) {
          this.setState(
            {
              allSelectedFarmerPerPage: result[0].id,
            },
            () => {
              console.log('allSelectedFarmerPerPage', allSelectedFarmerPerPage);
            },
          );
        }
        this.fetchFarmerData();
      },
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  toggleFilter = () => {
    const { openDrawer } = this.state;
    this.setState({
      openDrawer: !openDrawer,
    });
  };

  handleSelectAll = (event, rows) => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.farmerID);
      this.setState({
        // selected:newSelected,
        allSelectedFarmerPerPage: newSelected,
      });
    } else {
      this.setState({
        //  selected: [],
        allSelectedFarmerPerPage: [],
      });
    }
  };

  isSelected = (id) => {
    const { allSelectedFarmerPerPage } = this.state;
    return allSelectedFarmerPerPage.indexOf(id) !== -1;
  };

  handleCheck = (farmerId) => {
    const { selected, allSelectedFarmerPerPage } = this.state;
    const selectedIndex = allSelectedFarmerPerPage.indexOf(farmerId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(allSelectedFarmerPerPage, farmerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(allSelectedFarmerPerPage.slice(1));
    } else if (selectedIndex === allSelectedFarmerPerPage.length - 1) {
      newSelected = newSelected.concat(allSelectedFarmerPerPage.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        allSelectedFarmerPerPage.slice(0, selectedIndex),
        allSelectedFarmerPerPage.slice(selectedIndex + 1),
      );
    }
    this.setState(
      {
        // selected: newSelected,
        allSelectedFarmerPerPage: newSelected,
      },
      () => {
        console.log('>>>>>>', selected);
      },
    );
  };

  handleAdd = () => {
    const {
      selected,
      selectedFarmerList,
      allSelectedFarmerPerPage,
      page,
    } = this.state;
    const { farmersList, onClose, updateTaggedList } = this.props;
    const { farmers } = farmersList;
    let selectedFarmers = [];
    let selectedFarmersData = [];
    if (allSelectedFarmerPerPage.length > 0) {
      if (selected.length > 0) {
        const findIndex = selected.findIndex(item => item.page === page);
        if (findIndex !== -1) {
          selected[findIndex].id = allSelectedFarmerPerPage;
          selected[findIndex].data = farmers.filter((item) => {
            console.log(item);
            return allSelectedFarmerPerPage.includes(item.farmerID);
          });
        } else {
          const newObject = {
            id: allSelectedFarmerPerPage,
            page,
            data: farmers.filter(item => allSelectedFarmerPerPage.includes(item.farmerID)),
          };
          selected.push(newObject);
        }
        selectedFarmers = selected.reduce(
          (item, index) => item.concat(index.id),
          [],
        );
        selectedFarmersData = selected.reduce(
          (item, index) => item.concat(index.data),
          [],
        );
      } else {
        selectedFarmers = allSelectedFarmerPerPage;
        selectedFarmersData = farmers.filter((item) => {
          console.log(item);
          return allSelectedFarmerPerPage.includes(item.farmerID);
        });
      }
    }
    console.log('selectedFarmers', selectedFarmers, selectedFarmerList);
    // const newFarmerList = farmers.filter(item => selected.includes(item.orgFarmerID));
    // const newList = _.unionBy(
    //   selectedFarmerList,
    //   selectedFarmersData,
    //   item => item.orgFarmerID,
    // );
    // const updatedList = newList.filter(item => selected.includes(item.orgFarmerID));
    updateTaggedList(selectedFarmersData);
    if (selectedFarmersData && selectedFarmersData.length > 0) {
      this.setState(
        {
          selectedFarmerList: selectedFarmersData,
        },
        () => {
          onClose();
        },
      );
    } else {
      onClose();
    }
  };

  handleFilterList = () => {
    const { location } = this.props;
    const { startDate } = this.state;
    const {
      state,
      district,
      tehsil,
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
    } = location;
    let check = true;
    let error = '';
    let statename = '';
    let districtname = '';
    let tehsilname = '';
    const stateObj = state.find(o => o.stateID === selectedStateID);
    if (stateObj) {
      const { stateName } = stateObj;
      statename = stateName;
    }
    const districtObj = district.find(o => o.districtID === selectedDistrictID);
    if (districtObj) {
      const { districtName } = districtObj;
      districtname = districtName;
    }
    const tehsilObj = tehsil.find(o => o.tehsilID === selectedTehsilID);
    if (tehsilObj) {
      const { tehsilName } = tehsilObj;
      tehsilname = tehsilName;
    }
    if (statename === '' && startDate === null) {
      error = 'Please select the filter';
      check = false;
    }
    if (check) {
      this.fetchFarmerData(statename, districtname, tehsilname);
      this.setState({
        openDrawer: false,
        error: '',
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  getDate = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };

  clearDates = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
  };

  updateDates(startDate, endDate) {
    if (!startDate && !endDate) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    if (startDate) {
      this.setState({
        startDate,
      });
    }
    if (endDate) {
      this.setState({
        endDate,
      });
    }
  }

  render() {
    const {
      page,
      rowsPerPage,
      openDrawer,
      // indicesValue,
      selected,
      allSelectedFarmerPerPage,
      error,
    } = this.state;
    console.log('selected', selected);
    console.log('allSelectedFarmerPerPage', allSelectedFarmerPerPage);
    const { show, farmersList, broadcastLocationChange } = this.props;
    const { farmers, count } = farmersList;
    const totalCount = Number(count);
    const rows = [];
    if (farmers && farmers.length > 0) {
      for (let i = 0; i < farmers.length; i += 1) {
        rows.push(
          createData(
            farmers[i].name,
            farmers[i].mobile,
            farmers[i].farmerID,
            farmers[i].state,
            farmers[i].district,
            farmers[i].tehsil,
            farmers[i].viilage,
            farmers[i].pincode,
          ),
        );
      }
    }

    return (
      <React.Fragment>
        {show ? (
          <div className="list-modal">
            <div className="list-modal__content">
              <div className="list-modal__content__title">
                <h1>Farmers List</h1>
                <i
                  className="material-icons"
                  role="button"
                  tabIndex="0"
                  onKeyDown={this.handleClose}
                  onClick={this.handleClose}
                >
                  clear
                </i>
              </div>
              <div className="list-modal__content__body">
                <div className="list-modal__content__body__filter">
                  <button
                    type="button"
                    className="list-modal__content__body__filter--btn"
                    onClick={this.toggleFilter}
                  >
                    <i className="material-icons">filter_list</i>
                    {' '}
Filter
                  </button>
                </div>
                {openDrawer ? (
                  <div className="list-modal__content__body__filter-panel">
                    <Grid container>
                      <Grid
                        item
                        lg={12}
                        className="agriIndices selectController"
                      >
                        <h4 className="tabpanelHeading">Crop Name</h4>
                        <FormControl className="formControl inputHead coloredForms inputFullWidth m-b-0">
                          <Select
                            disableUnderline
                            name="dashboard-user"
                            className="selectEmpty modalDropdown"
                            inputProps={{ 'aria-label': 'user' }}
                            value=""
                            displayEmpty
                            disabled
                          >
                            <MenuItem value="">Crop Name</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        lg={12}
                        className="agriIndices selectController"
                      >
                        <h4 className="tabpanelHeading">Location</h4>
                        <LocationHeirarchy
                          type="selectEmpty modalDropdown"
                          calledFrom="dashboard"
                          broadcastLocationChange={broadcastLocationChange}
                          hideVillage
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="agriIndices selectController"
                      >
                        <DateRangeClone
                          getDate={this.getDate}
                          clearDates={this.clearDates}
                          updateDates={this.updateDates}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        className="agriIndices selectController"
                      >
                        {error ? (
                          <p className="errorMsg1">
                            {' '}
                            {error}
                            {' '}
                          </p>
                        ) : null}
                        <Button
                          className="filter-panel-btn"
                          onClick={this.handleFilterList}
                        >
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
                <div className="insightTable tableWrapper">
                  <div className="scrollPanel">
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                rows.length > 0
                                  ? rows.length
                                    === allSelectedFarmerPerPage.length
                                  : false
                              }
                              onChange={e => this.handleSelectAll(e, rows)}
                            />
                          </TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Mobile</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>District</TableCell>
                          <TableCell>Tehsil</TableCell>
                          <TableCell>Village</TableCell>
                          <TableCell>Pincode</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(row => (
                          <TableRow
                            key={row.farmerID}
                            role="checkbox"
                            aria-checked={this.isSelected(row.farmerID)}
                            onClick={() => this.handleCheck(row.farmerID)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={this.isSelected(row.farmerID)}
                              />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.state}</TableCell>
                            <TableCell>{row.district}</TableCell>
                            <TableCell>{row.tehsil}</TableCell>
                            <TableCell>{row.village}</TableCell>
                            <TableCell>{row.pincode}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <Pagination
                    rowsPerPage={rowsPerPage}
                    page={page}
                    totalCount={totalCount}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className="list-modal__content__footer">
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <Button
                      className="list-modal__content__footer--btn"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="list-modal__content__footer--btn"
                      onClick={this.handleAdd}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging, location }) => ({
      farmersList: tagging.farmersList,
      location,
    }),
    dispatch => ({
      getFarmerList: data => dispatch(farmeList(data)),
      resetLocationDetails: () => dispatch(resetLocationAct()),
    }),
  )(withCookies(ListModal)),
);
