import React from 'react';
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MultiSelect from 'react-select';
import {
  getHierarchyDataAct,
  setLocationAct,
} from '../../Actions/locationHierarchyAction';

import { submitAccountSetup2Action } from '../../Actions/afterLoginHomeAction';

import { config } from '../../config';
import {
  isNaturalNumber,
  isDecimalUptofourDigit,
} from '../../Functions/commonValidation';

export const cropsOptions = [
  {
    value: 'ocean',
    label: 'Ocean',
    color: '#00B8D9',
    isFixed: true,
  },
  { value: 'blue', label: 'Blue', color: '#0052CC' },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  {
    value: 'red',
    label: 'Red',
    color: '#FF5630',
    isFixed: true,
  },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

class GisAccountMission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: [],
      selectedStateError: '',
      numberOfAccounts: '',
      numberOfAccountsError: '',
      cbsOperational: false,
      targetFarmerCount: '',
      targetFarmerCountError: '',
      averageLoan: '',
      averageLoanError: '',
      numBranches: '',
      numBranchesError: '',
      totalOpsDistricts: '',
      totalOpsDistrictsError: '',
      selectedProductType: '',
      selectedProductTypeError: '',
      selectedCrops: [],
      selectedCropsError: '',
      selectedProductPriceRange: [],
      selectedProductPriceRangeError: '',
      netMonthlyProcurement: '',
      netMonthlyProcurementError: '',
      selectedProcurementCenter: '',
      selectedProcurementCenterError: '',
      anythingSpecific: '',
      orgObj: {
        orgName: '',
        orgID: '',
        token: '',
      },
    };
  }

  componentDidMount() {
    console.log(config);
    const { getHierarchyData, cookies } = this.props;
    const { cookies: inCookies } = cookies;
    const orgObj = {};
    let { userData } = inCookies;

    if (userData) {
      userData = JSON.parse(userData);

      // orgObj.orgID = userData.data.userData.organizationTypeID;
      orgObj.orgID = userData.data.userOrganisationDetails.organisationTypeID;
      orgObj.orgName = userData.data.userOrganisationDetails.organizationName;

      orgObj.token = userData.token;

      this.setState({
        orgObj,
      });
    }

    getHierarchyData('STATE', null);
  }

  _handleAverageLoan = (ev) => {
    console.log(ev.target.value);
    this.setState({
      averageLoan: ev.target.value,
      averageLoanError: '',
    });
  };

  _handleNumberOfBranches = (ev) => {
    console.log(ev);
    this.setState({
      numBranches: ev,
      numBranchesError: '',
    });
  };

  _handleNumberOfAccounts = (ev) => {
    console.log(ev.target.value);
    this.setState({
      numberOfAccounts: ev.target.value,
      numberOfAccountsError: '',
    });
  };

  _handleTargetFarmerCount = (ev) => {
    console.log(ev.target.value);
    this.setState({
      targetFarmerCount: ev.target.value,
      targetFarmerCountError: '',
    });
  };

  _handleOperationalDistrict = (ev) => {
    console.log(ev.target.value);
    this.setState({
      totalOpsDistricts: ev.target.value,
      totalOpsDistrictsError: '',
    });
  };

  _handleProductType = (ev) => {
    this.setState({
      selectedProductType: ev,
      selectedProductTypeError: '',
    });
  };

  _handleSelectCrops = (event) => {
    if (event) {
      this.setState({
        selectedCrops: event,
        selectedCropsError: '',
      });
    } else {
      this.setState({
        selectedCrops: [],
      });
    }
  };

  _handleProductPriceRange = (ev) => {
    if (ev) {
      this.setState({
        selectedProductPriceRange: ev,
        selectedProductPriceRangeError: '',
      });
    } else {
      this.setState({
        selectedProductPriceRange: [],
      });
    }
  };

  _submitForm = () => {
    // const { updateStep } = this.props;
    const { submitAccountSetup2, history } = this.props;
    const { orgObj } = this.state;
    const { orgName, token } = orgObj;
    const { accountSetupStep2 } = config;
    const {
      bank,
      insurance,
      seedCompany,
      fertAndAgro,
      farmEquipments,
      procurement,
    } = accountSetupStep2;
    const {
      selectedState,
      numberOfAccounts,
      cbsOperational,
      targetFarmerCount,
      averageLoan,
      numBranches,
      totalOpsDistricts,
      selectedProductType,
      selectedCrops,
      selectedProductPriceRange,
      netMonthlyProcurement,
      selectedProcurementCenter,
      anythingSpecific,
    } = this.state;

    let selectedStateErrors;

    let numberOfAccountsErrors;

    let targetFarmerCountErrors;

    let averageLoanErrors;
    let totalOpsDistrictsErrors;

    let selectedProductTypeErrors;

    let selectedCropsErrors;

    let selectedProductPriceRangeErrors;
    let netMonthlyProcurementErrors;

    let selectedProcurementCenterErrors;

    let numBranchesErrors;

    // if(!selectedState.length) {
    //   selectedStateError = 'Please select atleast one state';
    // }
    // if(!numberOfAccounts) {
    //   numberOfAccountsError = 'Please enter number of accounts';
    // }
    // if(!targetFarmerCount) {
    //   targetFarmerCountError = 'Please enter target farmer count';
    // }
    // if(!averageLoan) {
    //   averageLoanError = 'Please enter average loan';
    // }

    // if(!numBranches) {
    //   numBranchesError = 'Please select number of branches';
    // }

    // if(!totalOpsDistricts) {
    //   totalOpsDistrictsError = 'Please enter total ops value';
    // }

    // if(!selectedProductType) {
    //   selectedProductTypeError = 'Please select product type';
    // }
    // if(!selectedCrops.length) {
    //   selectedCropsError = "Please select atleast one crop";
    // }
    // if(!selectedProductPriceRange.length) {
    //   selectedProductPriceRangeError = "Please select atleast one product price range";
    // }

    // if(!netMonthlyProcurement) {
    //   netMonthlyProcurementError = 'Please enter net monthly procurement';
    // }

    // if(!selectedProcurementCenter) {
    //   selectedProcurementCenterError = 'Please select procurement center';
    // }

    const stateQuestion = 'Select State';
    const numBankAccountQuestion = 'No. of accounts';
    const targetFarmerQuestion = 'Target Farmers Count';
    const anythingSpecificQuestion = 'Looking for anything specific? Please write here';
    const averageLoanQuestion = 'Average Loan/Farmers';
    const numBranchesQuestion = 'No. of Branches';
    const totatOpsDistrictQuestion = 'Total Operational Districts';
    const productTypeQuestion = 'Product Type';
    const productPriceRangeQuestion = 'Product Price Range';
    const procurementQuestion = 'Procurement Centers';
    const selectedCropsQuestion = 'Select Crops';
    const netMonthlyProcurementQuestion = 'Net Monthly Procurement(Metric Tonnes)';
    const cbsOperationalQuestion = 'CBS Operational?';
    const data = {};
    data.questionJson = {};
    switch (orgName) {
      case bank:
        if (
          selectedState.length
          && numBranches
          && isNaturalNumber(numberOfAccounts)
          && isNaturalNumber(targetFarmerCount)
          && isNaturalNumber(averageLoan)
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[numBranchesQuestion] = numBranches;
          data.questionJson[numBankAccountQuestion] = numberOfAccounts;
          data.questionJson[targetFarmerQuestion] = targetFarmerCount;
          data.questionJson[averageLoanQuestion] = averageLoan;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[cbsOperationalQuestion] = cbsOperational;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });
          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }

          if (!numBranches) {
            numBranchesErrors = 'Please select number of branches';
          }

          if (!numberOfAccounts || !isNaturalNumber(numberOfAccounts)) {
            numberOfAccountsErrors = 'Please enter valid number of accounts';
          }

          if (!targetFarmerCount || !isNaturalNumber(targetFarmerCount)) {
            targetFarmerCountErrors = 'Please enter valid target farmer count';
          }

          if (!averageLoan || !isNaturalNumber(averageLoan)) {
            averageLoanErrors = 'Please enter average loan';
          }
          this.setState({
            selectedStateError: selectedStateErrors,
            numBranchesError: numBranchesErrors,
            numberOfAccountsError: numberOfAccountsErrors,
            targetFarmerCountError: targetFarmerCountErrors,
            averageLoanError: averageLoanErrors,
          });
        }
        break;
      case insurance:
        if (
          selectedState.length
          && isNaturalNumber(totalOpsDistricts)
          && selectedProductType
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[totatOpsDistrictQuestion] = totalOpsDistricts;
          data.questionJson[productTypeQuestion] = selectedProductType;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });

          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }
          if (!totalOpsDistricts || !isNaturalNumber(totalOpsDistricts)) {
            totalOpsDistrictsErrors = 'Please enter vaild total ops value';
          }

          if (!selectedProductType) {
            selectedProductTypeErrors = 'Please select product type';
          }

          this.setState({
            selectedStateError: selectedStateErrors,
            totalOpsDistrictsError: totalOpsDistrictsErrors,
            selectedProductTypeError: selectedProductTypeErrors,
          });
        }
        break;
      case seedCompany:
        if (
          selectedState.length
          && selectedCrops.length
          && isNaturalNumber(targetFarmerCount)
          && selectedProductType
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[selectedCropsQuestion] = selectedCrops;
          data.questionJson[productTypeQuestion] = selectedProductType;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });

          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }

          if (!selectedCrops.length) {
            selectedCropsErrors = 'Please select atleast one crop';
          }

          if (!targetFarmerCount || !isNaturalNumber(targetFarmerCount)) {
            targetFarmerCountErrors = 'Please enter vaild target farmer count';
          }

          if (!selectedProductType) {
            selectedProductTypeErrors = 'Please select product type';
          }
          this.setState({
            selectedStateError: selectedStateErrors,
            selectedCropsError: selectedCropsErrors,
            targetFarmerCountError: targetFarmerCountErrors,
            selectedProductTypeError: selectedProductTypeErrors,
          });
        }
        break;
      case fertAndAgro:
        if (
          selectedState.length
          && selectedCrops.length
          && isNaturalNumber(targetFarmerCount)
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[selectedCropsQuestion] = selectedCrops;
          data.questionJson[targetFarmerQuestion] = targetFarmerCount;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });

          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }

          if (!selectedCrops.length) {
            selectedCropsErrors = 'Please select atleast one crop';
          }

          if (!targetFarmerCount || !isNaturalNumber(targetFarmerCount)) {
            targetFarmerCountErrors = 'Please enter valid target farmer count';
          }

          this.setState({
            selectedStateError: selectedStateErrors,
            selectedCropsError: selectedCropsErrors,
            targetFarmerCountError: targetFarmerCountErrors,
          });
        }
        break;
      case farmEquipments:
        if (
          selectedState.length
          && isNaturalNumber(targetFarmerCount)
          && selectedProductType
          && selectedProductPriceRange.length
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[productTypeQuestion] = selectedProductType;
          data.questionJson[
            productPriceRangeQuestion
          ] = selectedProductPriceRange;
          data.questionJson[targetFarmerQuestion] = targetFarmerCount;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });

          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }

          if (!targetFarmerCount || !isNaturalNumber(targetFarmerCount)) {
            targetFarmerCountErrors = 'Please enter target farmer count';
          }

          if (!selectedProductType) {
            selectedProductTypeErrors = 'Please select product type';
          }

          if (!selectedProductPriceRange.length) {
            selectedProductPriceRangeErrors = 'Please select atleast one product price range';
          }

          this.setState({
            selectedStateError: selectedStateErrors,
            targetFarmerCountError: targetFarmerCountErrors,
            selectedProductTypeError: selectedProductTypeErrors,
            selectedProductPriceRangeError: selectedProductPriceRangeErrors,
          });
        }
        break;
      case procurement:
        if (
          selectedState.length
          && selectedCrops.length
          && isDecimalUptofourDigit(netMonthlyProcurement)
          && selectedProcurementCenter
        ) {
          data.questionJson[stateQuestion] = selectedState;
          data.questionJson[anythingSpecificQuestion] = anythingSpecific;
          data.questionJson[selectedCropsQuestion] = selectedCrops;
          data.questionJson[
            netMonthlyProcurementQuestion
          ] = netMonthlyProcurement;
          data.questionJson[procurementQuestion] = selectedProcurementCenter;

          const newData = JSON.parse(JSON.stringify(data));
          data.questionJson = [];
          Object.keys(newData.questionJson).forEach((item) => {
            data.questionJson.push({
              ques: item,
              ans: newData.questionJson[item],
            });
          });

          submitAccountSetup2(data, token, history);
        } else {
          if (!selectedState.length) {
            selectedStateErrors = 'Please select atleast one state';
          }

          if (!selectedCrops.length) {
            selectedCropsErrors = 'Please select atleast one crop';
          }

          if (
            !netMonthlyProcurement
            || !isDecimalUptofourDigit(netMonthlyProcurement)
          ) {
            netMonthlyProcurementErrors = 'Please enter valid net monthly procurement';
          }

          if (!selectedProcurementCenter) {
            selectedProcurementCenterErrors = 'Please select procurement center';
          }

          this.setState({
            selectedStateError: selectedStateErrors,
            selectedCropsError: selectedCropsErrors,
            netMonthlyProcurementError: netMonthlyProcurementErrors,
            selectedProcurementCenterError: selectedProcurementCenterErrors,
          });
        }
        break;
      default:
        break;
    }
    // updateStep(3);
  };

  _handleCbsOperational = () => {
    // if()
    const { cbsOperational } = this.state;
    this.setState({
      cbsOperational: !cbsOperational,
    });
  };

  _handleProcurementCenter = (ev) => {
    this.setState({
      selectedProcurementCenter: ev,
      selectedProcurementCenterError: '',
    });
  };

  _handlenetMonthlyProcurement = (ev) => {
    this.setState({
      netMonthlyProcurement: ev.target.value,
      netMonthlyProcurementError: '',
    });
  };

  _handleAnythingSpecific = (ev) => {
    console.log(ev.target.value);
    this.setState({
      anythingSpecific: ev.target.value,
    });
  };

  handleSelectState(event) {
    if (event) {
      this.setState({
        selectedState: event,
        selectedStateError: '',
      });
    } else {
      this.setState({
        selectedState: [],
      });
    }
    console.log(event);
  }

  render() {
    const { states } = this.props;
    const {
      selectedState,
      selectedStateError,
      numberOfAccounts,
      numberOfAccountsError,
      cbsOperational,
      targetFarmerCount,
      targetFarmerCountError,
      averageLoan,
      averageLoanError,
      numBranches,
      numBranchesError,
      totalOpsDistricts,
      totalOpsDistrictsError,
      selectedProductType,
      selectedProductTypeError,
      selectedCrops,
      selectedCropsError,
      selectedProductPriceRange,
      selectedProductPriceRangeError,
      netMonthlyProcurement,
      netMonthlyProcurementError,
      selectedProcurementCenter,
      selectedProcurementCenterError,
      anythingSpecific,
      orgObj,
    } = this.state;
    const statesOptions = states.map(state => ({
      value: state.stateID,
      label: state.stateName,
      color: '#00B8D9',
    }));

    const {
      noOfBranches,
      productTypeOptions,
      productPriceRangeOptions,
      procurementCenterOptions,
      accountSetupStep2,
    } = config;

    const {
      bank,
      insurance,
      seedCompany,
      fertAndAgro,
      farmEquipments,
      procurement,
    } = accountSetupStep2;

    const { orgName: name } = orgObj;
    return (
      <React.Fragment>
        <Grid container className="root gis-main1" justify="center">
          <Grid item xs={8}>
            <div className="gis-account">
              <h5>Account Setup</h5>
            </div>
          </Grid>
        </Grid>
        <Grid container className="root gis-main" justify="center">
          <Grid item xs={8}>
            {/* <div className="gis-account"> */}
            <div className="card-section">
              <p>Please select your interests for customised offerings</p>
              <Grid container className="custom-space form-section">
                <Grid item xs={12} sm={6} className="custom-padding">
                  <FormControl className="formControl inputHead">
                    <InputLabel className="select-label" shrink>
                      Select State
                    </InputLabel>
                    <MultiSelect
                      disableUnderline
                      isMulti
                      name="state"
                      options={statesOptions}
                      onChange={event => this.handleSelectState(event)}
                      value={selectedState}
                      // className="basic-multi-select"
                      className="selectEmpty modalDropdown commonMultiSelect" // classNamePrefix="select"
                    />
                    {selectedStateError ? (
                      <span className="selectError">{selectedStateError}</span>
                    ) : null}
                  </FormControl>
                </Grid>
                {name === bank ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <TextField
                      disableUnderline
                      error={numberOfAccountsError}
                      helperText={numberOfAccountsError || ''}
                      label="No. of accounts"
                      className="textField inputHead"
                      value={numberOfAccounts}
                      onChange={this._handleNumberOfAccounts}
                    />
                  </Grid>
                ) : null}
                {name === bank
                || name === seedCompany
                || name === fertAndAgro
                || name === farmEquipments ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <TextField
                      disableUnderline
                      id=""
                      error={targetFarmerCountError}
                      helperText={targetFarmerCountError || ''}
                      label="Target Farmers Count"
                      className="textField inputHead"
                      value={targetFarmerCount}
                      onChange={this._handleTargetFarmerCount}
                    />
                  </Grid>
                  ) : null}

                {name === bank ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <TextField
                      disableUnderline
                      error={averageLoanError}
                      helperText={averageLoanError || ''}
                      value={averageLoan}
                      label="Average Loan/Farmers"
                      className="textField inputHead"
                      onChange={this._handleAverageLoan}
                    />
                  </Grid>
                ) : null}
                {name === bank ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <FormControl className="formControl inputHead">
                      <InputLabel className="select-label" shrink>
                        No. of Branches
                      </InputLabel>
                      <MultiSelect
                        disableUnderline
                        isMulti={false}
                        name="numbranches"
                        value={numBranches}
                        options={noOfBranches}
                        onChange={this._handleNumberOfBranches}
                        // className="basic-multi-select"
                        className="selectEmpty modalDropdown commonMultiSelect"
                        // classNamePrefix="select"
                      />
                      {numBranchesError ? (
                        <span className="selectError">{numBranchesError}</span>
                      ) : null}
                    </FormControl>
                  </Grid>
                ) : null}
                {name === insurance ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <TextField
                      disableUnderline
                      error={totalOpsDistrictsError}
                      helperText={totalOpsDistrictsError || ''}
                      label="Total Operational Districts"
                      className="textField inputHead"
                      value={totalOpsDistricts}
                      onChange={this._handleOperationalDistrict}
                    />
                  </Grid>
                ) : null}
                {name === insurance
                || name === seedCompany
                || name === farmEquipments ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <FormControl className="formControl inputHead">
                      <InputLabel className="select-label" shrink>
                        Product Type
                      </InputLabel>
                      <MultiSelect
                        disableUnderline
                        isMulti={false}
                        name="productType"
                        value={selectedProductType}
                        options={productTypeOptions}
                        onChange={this._handleProductType}
                        // className="basic-multi-select"
                        className="selectEmpty modalDropdown commonMultiSelect"
                        // classNamePrefix="select"
                      />
                    </FormControl>
                    {selectedProductTypeError ? (
                      <p className="selectError">{selectedProductTypeError}</p>
                    ) : null}
                  </Grid>
                  ) : null}
                {name === farmEquipments ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <FormControl className="formControl inputHead">
                      <InputLabel className="select-label" shrink>
                        Product Price Range
                      </InputLabel>
                      <MultiSelect
                        disableUnderline
                        isMulti
                        name="productType"
                        value={selectedProductPriceRange}
                        options={productPriceRangeOptions}
                        onChange={this._handleProductPriceRange}
                        // className="basic-multi-select"
                        className="selectEmpty modalDropdown commonMultiSelect"
                        // classNamePrefix="select"
                      />
                    </FormControl>
                    {selectedProductPriceRangeError ? (
                      <span className="selectError">
                        {selectedProductPriceRangeError}
                      </span>
                    ) : null}
                  </Grid>
                ) : null}
                {name === procurement ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <FormControl className="formControl inputHead">
                      <InputLabel className="select-label" shrink>
                        Procurement Centers
                      </InputLabel>
                      <MultiSelect
                        disableUnderline
                        isMulti={false}
                        name="productType"
                        value={selectedProcurementCenter}
                        options={procurementCenterOptions}
                        onChange={this._handleProcurementCenter}
                        // className="basic-multi-select"
                        className="selectEmpty modalDropdown commonMultiSelect"
                        // classNamePrefix="select"
                      />
                    </FormControl>
                    {selectedProcurementCenterError ? (
                      <p className="selectError">
                        {selectedProcurementCenterError}
                      </p>
                    ) : null}
                  </Grid>
                ) : null}
                {name === seedCompany
                || name === fertAndAgro
                || name === procurement ? (
                  <Grid item xs={12} sm={6} className="custom-padding">
                    <FormControl className="formControl inputHead">
                      <InputLabel className="select-label" shrink>
                        Select Crops
                      </InputLabel>
                      <MultiSelect
                        disableUnderline
                        isMulti
                        name="state"
                        options={cropsOptions}
                        onChange={this._handleSelectCrops}
                        value={selectedCrops}
                        // className="basic-multi-select"
                        className="selectEmpty modalDropdown commonMultiSelect"
                        // classNamePrefix="select"
                      />
                    </FormControl>
                    {selectedCropsError ? (
                      <p className="selectError">{selectedCropsError}</p>
                    ) : null}
                  </Grid>
                  ) : null}
                {name === procurement ? (
                  <Grid item xs={12} sm={12} className="custom-padding">
                    <TextField
                      disableUnderline
                      error={netMonthlyProcurementError}
                      helperText={netMonthlyProcurementError || ''}
                      label="Net Monthly Procurement(Metric Tonnes)"
                      className="textField inputHead"
                      value={netMonthlyProcurement}
                      onChange={this._handlenetMonthlyProcurement}
                    />
                  </Grid>
                ) : null}
                {name === bank ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="custom-padding CBC-lable"
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={cbsOperational}
                          value={cbsOperational}
                          color="primary"
                          onChange={this._handleCbsOperational}
                          className="CBC-label"
                        />
)}
                      label="CBS Operational?"
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={6} className="custom-padding">
                  <TextField
                    disableUnderline
                    id="standard-textarea"
                    placeholder="Looking for anything specific?
                    Please write here"
                    multiline
                    className="textField inputHead"
                    rowsMax="4"
                    value={anythingSpecific}
                    onChange={this._handleAnythingSpecific}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="custom-padding">
                  <div className="submit-btn">
                    <Button variant="contained" onClick={this._submitForm}>
                      Proceed to Dashboard
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ location }) => ({
      states: location.state,
      selectedStateID: location.selectedStateID,
    }),

    dispatch => ({
      getHierarchyData: (level, plID) => dispatch(getHierarchyDataAct(level, plID)),
      setLocation: (level, selectedLocation) => dispatch(setLocationAct(level, selectedLocation)),
      submitAccountSetup2: (d, t, h) => dispatch(submitAccountSetup2Action(d, t, h)),
    }),
  )(withCookies(GisAccountMission)),
);
