const initialState = {
  accountSetupQuestions: {},
  tabContent: {},
  ndviTimelineData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACCCOUNT_SETUP_QUESTION_SUCCESS':
      return {
        ...state,
        accountSetupQuestions: action.payload.accountSetupJson
          ? action.payload.accountSetupJson
          : {},
      };

    case 'ACCCOUNT_SETUP_QUESTION_FAILURE':
      return {
        ...state,
        accountSetupQuestions: {},
      };
    case 'TAB_CONTENT_SUCCESS':
      return {
        ...state,
        tabContent: action.payload.tabContent,
      };

    case 'TAB_CONTENT_FAILURE':
      return {
        ...state,
        tabContent: {},
      };
    case 'NDVI_TIMELINE_HOMEPAGE_DATA_SUCCESS':
      return {
        ...state,
        ndviTimelineData: action.payload,
      };
    case 'NDVI_TIMELINE_HOMEPAGE_DATA_FAILURE':
      return {
        ...state,
        ndviTimelineData: [],
      };
    default:
      return state;
  }
};
