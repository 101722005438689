exports.hindi = {
  lang: 'hindi',
  'Top 3 Crops': 'शीर्ष 3 फसलें',
  'About Region': 'क्षेत्र के बारे में',
  Year: 'साल',
  'of season Kharif in': 'के ऋतु ख़रीफ़ में',
  'of season rabi in': 'के ऋतु रबी‎ में',
  of: '  के',
  season: 'ऋतु',
  Kharif: 'ख़रीफ़',
  Rabi: 'रबी‎',
  'Total Farms': 'कुल खेत',
  'Total Agri. Area': 'कुल कृषि क्षेत्र',
  'Avg. Farm Size': 'औसत खेत का आकार',
  'Avg. Farm Ratings': 'औसत कृषि रेटिंग',
  'Ranking of Crops by': ' सभी फसलों की रैंकिंग ',
  Crop: 'फसल',
  Rank: 'श्रेणी',
  acreage: 'एकड़',
  'Irrigation coverage': 'सिंचाई का  क्षेत्र',
  'Avg Distance from road': 'औसत  दूरी रस्ते से ',
  'Average Farm Rating': 'औसत खेत की  रेटिंग',
  'is this your farm? if yes, you can':
    'क्या यह आपका खेत है? यदि हाँ, तो आप कर सकते हैं',
  'Generate Credit Worthiness': 'ऋण पात्रता उत्पन्न करना',
  'Find Insurance Rate': 'बीमा दर का पता लगाएं',
  'Subscribe for market prices': 'बाजार मूल्य के लिए सदस्यता लें',
  'Apply for Credit Card': 'क्रेडिट कार्ड के लिए आवेदन करें',
  'Want to Know More': 'और जानकारी प्राप्त करे ',
  'join farm engine': 'खेत इंजन में शामिल हों',
  'map type': 'नक्शा प्रकार',
  'search by address': 'पते से खोजें',
  'type area name you are looking for': 'जिस क्षेत्र का नाम आप खोज रहे हैं',
  'Enter pincode': 'पिनकोड दर्ज करें',
  State: 'राज्य',
  district: 'जिला',
  'sub district': 'उप जिला ',
  Village: 'गाँव',
  'find address': 'पता ढूंढ़ो',
  'Selected farm': 'चयनित खेत',
  'this is my field': 'यह मेरा क्षेत्र है',
  'crop name': 'फसल का नाम',
  Area: 'क्षेत्र',
  'farm ratings': 'खेत की रेटिंग',
  'crop health': 'फसल का  स्वास्थ्य',
  'insurance co': ' बीमा  कंपनी',
  'sowing week': 'बुवाई सप्ताह',
  'harvesting week': 'कटाई सप्ताह',
  'market price': 'बाजार मूल्य',
  'nearest mandi': 'कटाई सप्ताह',
  'nearest bank branch': '',
  'weater report': 'मौसम की रिपोर्ट',
  'something wrong': '',
  'generate full report': 'जनरेट करें पूरी रिपोर्ट',
  'share your profile with friends': 'अपनी प्रोफाइल शेयर करे दोस्तों के साथ ',
  'invite your friends': 'आपने दोस्तों को निमन्त्रण दे',
  'benefits of joining us': 'हमसे जुड़ने के लाभ ',
  Farmer: 'किसान',
  'you can create an account from': 'आप एक खाता बना सकते हैं',
  Mobile: 'मोबाइल',
  gmail: 'जीमेल ',
  facebook: 'फेसबुक',
  Whatsapp: 'व्हाट्सप्प',
  'already a member': 'पहले से सदस्य हैं',
  Login: 'लॉगइन',
  Register: 'रजिस्टर ',
  'You are one step away to': 'तुम एक कदम दूर हो',
  'Create New Account': 'नया खाता बनाएँ ',
  'Mobile No.': 'मोबाइल नंबर',
  Close: 'बन्द',
  'Request OTP': 'अनुरोध ओटीपी',
  'login by email ID': 'ईमेल से प्रवेश करें',
  'Email ID': 'ईमेल',
  'Login By': ' ',
  'login by mobile no': 'मोबाइल नंबर से प्रवेश करें',
  'OTP has been sent to': 'ओटीपी को भेज दिया गया है',
  'Enter OTP': 'ओटीपी डालें',
  'get otp on call': 'कॉल पर ओटीपी प्राप्त करें',
  'Resend OTP': 'ओटीपी को पुनः भेजें',
  'Hurray You have succesfully Created': 'hurray आपने सफलतापूर्वक ',
  'A New Account': 'एक नया खाता बनाया है',
  'just enter your primary information': 'बस अपनी प्राथमिक जानकारी दर्ज करें',
  name: 'नाम',
  'Last Name': 'सरनेम',
  'enter password': 'पासवर्ड डालें',
  'password should be minimum of 8 letters':
    'पासवर्ड  कम से कम 8 अक्षर का होना चाहिए ',
  'confirm password': 'पासवर्ड की पुष्टि कीजिये',
  Finish: 'समाप्त',
  'Hurray you have succesfully Submitted': 'आपने सफलतापूर्वक सबमिट किया है',
  'Welcome to Family': 'परिवार में आपका स्वागत है',
  'Complete Your Profile': 'अपनी प्रोफाइल पूरी कीजिए',
  "i'll do it later": 'मैं इसे बाद में करूंगा',
  'welcome back': 'वापसी पर स्वागत है',
  'login to your profile': 'अपने प्रोफ़ाइल में प्रवेश करें',
  'Login With facebook': 'फेसबुक लॉगइन',
  'enter your mobile no': 'अपना मोबाइल नंबर दर्ज करें।',
  'forgot password': 'पासवर्ड भूल गए',
  'password assistance': 'पासवर्ड सहायता ',
  'enter the email adress or mobile no associated with your account':
    'ईमेल एड्रेस या मोबाइल नंबर  जो आपके खाते से  संबंधित है उसे दर्ज करे ',
  "we'll ask for this password whenever you sign in":
    'हम यह पासवर्ड हर साइन इन पर पूछेंगे ',
  'authentication required': '  अभिप्रमाणन आवश्यक',
  'Please enter OTP to complete verification':
    'वेरिफिकेशन के लिए कृपया अपना ओटीपी दर्ज करे ',
  continue: 'जारी रहे ',
  'My Account': 'मेरा खता ',
  'Exit setup': 'सेटअप बंद करें',
  'Find your farm': 'अपना खेत ढूंढो',
  services: 'सेवाएं',
  'Farmer Card': 'किसान का कार्ड',
  'listen again': 'फिर से सुनो',
  skip: 'छोड़े ',
  'Details of selected farm': 'चयनित खेत का विवरण',
  'Add to my farm': 'मेरे खेत में जोड़े ',
  address: 'पता ',
  remove: 'हटाए ',
  'Added Farm': 'जुड़ा हुआ खेत ',
  'continue to services': 'जानकारी के लिए जारी रहिये  ',
  'farm 1': ' खेत 1 ',
  'This selected farm will be Added! Are you sure?': 'चयनित खेत जुड़ जायेगा ?',
  Yes: 'हाँ',
  No: 'नहीं',
  'This selected farm will be removed! Are you sure?': 'चयनित खेत हट जायेगा ',
  Weather: 'मौसम',
  'Weather report': 'मौसम की रिपोर्ट',
  Mandi: 'मंडी',
  Insurance: 'बीमा',
  Bank: 'बैंक',
  'top 3 crops selling in this mandi': 'मंडी में बिक रही टॉप 3 फसलें',
  share: ' शेयर',
  'finish setup': 'ढांचा पूरा करे ',
  'Language Prefered': 'भाषा को प्राथमिकता दी',
  'sowing month': 'बुवाई का महीना',
  'harvesting month': 'कटाई का महीना',
  'irrigated (yes/no)': 'सिंचाई (हाँ/नहीं)',
  'insurance premium': 'बीमा प्रीमियम',
  'expected production': 'फसल की मात्रा',
  'soil composition': ' मिट्टी की  बनावट ',
  'soil ph': ' मिट्टी में एसिड मापक',
  'sun hour': 'सूरज का घंटा',
  'catastrophic risk': 'आपातपूर्ण खतरा ',
  'local weather': 'स्थानिक मौसम',
  'farm connectivity': 'खेत की संयोजकता ',
  'historical satellite images': 'ऐतिहासिक   सेटलाइट  तस्वीरें',
  'give us a missed call to have your farm card':
    'हमें अपना फ़ार्म कार्ड देने के लिए मिस्ड कॉल दें',
  'farmguide app link ': 'फार्मगाइड ऐप लिंक',
  'last 4 years farm photos': 'पिछले 4 साल की तस्वीरें',
  menu: 'मेन्यू',
  'my dashboard': 'मेरा डैशबोर्ड',
  'my profile': '',
  'my farm': 'मेरे खेत',
  'farmer ID': '',
  'Major crops': 'प्रमुख फसलें',
  'Land area': 'भूमि क्षेत्रफल',
  'Generate your farm card': 'खेत कार्ड उत्पन्न करे',
  Minimum: 'न्यूनतम',
  Maximum: 'अधिकतम',
  'Current rate ': 'वर्तमान दर',
  'Calculate your insurance premium': 'बीमा प्रीमियम कैलकूलेट करे',
  'information services': 'जानकारी सेवाएँ',
  'Sell/buy at best prices': ' बेचे/खरीदे सर्वोतम दामों पर  ',
  maps: 'नक्शे',
  'phone book': 'फोन बुक',
  'nafa-nuksaan': 'नफा नुक्सान ',
  jaankari: 'जानकारी ',
  wallet: 'बटुआ ',
  help: 'मदद ',
  logout: 'लोग आउट',
  'add more': 'और जोड़े',
  'your profile is almost completed': 'आपकी  प्रोफाइल अधिकतर पूरी है  ',
  'personal details': 'व्यक्तिगत विवरण',
  'bank details': 'बैंक विवरण',
  'bank name': 'बैंक का नाम',
  'branch name': 'शाखा का नाम',
  'account no': 'खाता नंबर',
  'IFSC code': 'ifsc कोड',
  'kisan credit card details': 'किसान क्रेडिट कार्ड डिटेल',
  'name on card': 'कार्ड पर नाम',
  'card no': 'कार्ड नंबर ',
  'loan details': 'ऋण विवरण',
  'loan amount': 'ऋण की राशी',
  'rate of interest': 'ब्याज दर ',
  'all farm': 'सारे खेत ',
  'total farm area': 'कुल खेतो का  क्षेत्र',
  'view and share your farm card': 'अपना फ़ार्म कार्ड देखें और साझा करें',
  'owner name': ' मालिक का नाम ',
  'khasra no': 'खसरा नंबर',
  'khata no': 'खाता नंबर',
  'soil health': 'मिट्टी की सेहत',
  'fertilisers used': 'उर्वरकों का इस्तेमाल किया',
  'crops sown': 'बोई गई फसलें',
  'irrigation source': 'सिंचाई स्रोत',
  'last week rate of': 'पिछले सप्ताह  का अनुमान',
  'view your farm photos from last 3 years ':
    'पिछले 3 वर्षों के अपने खेत की तस्वीरें देखें',
  'apply for loan': 'ऋण के लिए आवेदन करें',
  'apply for insurance': 'बीमा के लिए आवेदन करें',
  'updated few minutes ago': 'कुछ मिनट पहले अद्यतन किया गया',
  'wind flow': 'विंडफ्लॉव ',
  precipitation: 'अवक्षेपण',
  humidity: 'नमी',
  alert: 'चेतावनी',
  'open now till -- pm ': 'शाम -- बजे तक खुला रहेगा',
  '5km away': '5 किमी दूर',
  'mandi incharge details': 'मनिड प्रभारी विवरण',
  'more mandis near you': 'आप के पास और अधिक मंडियां',
  'agent details': 'एजेंट विवरण',
  'enter my insurance detials': 'मेरा बीमा विवरण दर्ज करें',
  'pradhan mantri fasal bima yojana': 'प्रधान मन्त्री फसल बिमा योजना',
  'insurance premium calculator': 'बीमा प्रीमियम कैलकुलेटर',
  scheme: 'अनुसंधान',
  calculate: 'कैलकुलेट',
  'insurance calculation': 'बीमा गणना',
  'sum imsured': 'बीमा राशि',
  'farmer share': 'किसान का  हिस्सा',
  'acturial rate': 'बीमांकिक दर',
  'cut off date': 'विच्छेदक तिथि',
  'premium paid by farmer': 'किसान द्वारा दिया गया प्रीमियम',
  'premium paid by govt': 'सरकार द्वारा प्रीमियम का भुगतान',
  back: 'पीछे जाये ',
  'enter your bank details to know how much loan you can get':
    'यह जानने के लिए कि आपको कितना ऋण मिल सकता है, अपने बैंक डिटेल्स दर्ज करें',
  'this eligibility calculator gives an approximate value of the amount of loan money you can avail from the bank':
    'यह पात्रता कैलकुलेटर आपको बैंक से प्राप्त अनुमानित ऋण राशि का एक अनुमानित मूल्य देता है',
  'please select your state and district': 'अपने  राज्य और जिले का चयन करें',
  'irrigated land in acres': ' सिंचित भूमि (एकड़ में)',
  'type of funding': 'धन का प्रकार',
  'unirrigated land (in acres)': 'असिंचित भूमि (एकड़ में)',
  'land value': 'भूमि का मूल्य',
  'entire land': 'पूरी जमीन',
  'requested loan amount': 'अनुरोध किया हुआ ऋण की राशी',
  ok: 'ठीक',
  'details added successfully': 'विवरण सफलतापूर्वक जोड़ा गया',
  'for more details': 'अधिक जानकारी के लिए',
  warehouse: 'गोदाम',
  'learn more': 'और अधिक जानें',
  Parameter: 'पैरामीटर',
  'Is this your farm ? If yes, You can':
    'क्या यह आपका खेत है? यदि हाँ, तो आप कर सकते हैं',
  'Benefits for Joining Us!': 'हमसे जुड़ने के फायदे!',
  Farm: 'खेत',
  Grass: 'घास',
  Cotton: 'कपास',
  'Moong Dal': 'मूंग दाल',
  Bajra: 'बाजरे',
  Paddy: 'धान',
  Horticulture: 'बागवानी',
  Wheat: 'गेहूँ',
  Mustard: 'सरसों',
  Score: 'स्कोर',
  Population: 'जनसंख्या',
  'Irrigated Farms': 'सिंचित खेत',
  'Rainfed Farms': 'बरसाती खेत',
  'Nearest Market': 'निकटतम बाजार',
  'Nearest Bank': 'निकटतम बैंक',
  'Nearest Storage': 'निकटतम संग्रहण',
  'Insurance Company': 'बीमा कंपनी',
  'Sum Insured': 'बीमा राशि',
  'Premium Amount': 'प्रीमियम राशि',
  'Wind Speed': 'हवा की गति',
  'Wrong field boundaries ?': 'गलत क्षेत्र सीमाएँ ?',
  'Inform Us': 'हमे सूचित करे',
  'Thank you': 'धन्यवाद',
  'Wrong Crop ?': 'गलत फसल?',
  'Nearest Waterway': 'निकटतम जल संसाधन',
  'kms away': 'किमी दूर',
  'km/hr': 'कि.मी / घंटा',
  Company: 'कंपनी',
  'Distance Mapping': 'दूरी के आँकड़े',
  'Crop Name': 'फसल का नाम',
  'Farm Area': 'कृषि क्षेत्रफल',
  'Region not open. If you want to view this region please':
    'क्षेत्र नहीं खुला। यदि आप कृपया इस क्षेत्र को देखना चाहते हैं',
  'to inform us': 'हमें सूचित करने के लिए',
  'Click here': 'यहाँ क्लिक करें',
  by: 'द्वारा',
  Ranking: 'रैंकिंग',
  'All State': 'सभी राज्य',
  'All Districts': 'सभी जिले',
  'All Tehsils': 'सभी तहसीलें',
  'All Villages': 'सभी गाँव',
  'All Crops': 'सभी फसलें',
  'Sowing Date': 'बुवाई की तारीख',
  'Harvest Date': 'कटाई की तारीख',
  Irrigation: 'सिंचाई',
  'Crop Health': 'फसल स्वास्थ्य',
  Yield: 'पैदावार',
  Parameters: 'पैरामीटर',
};
