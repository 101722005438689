import React, { useState } from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TableHead,
} from '@material-ui/core';
import _ from 'lodash';
import TablePagination from '@material-ui/core/TablePagination';
import { PieChart } from '../../common/chart';

const colors = {
  0: '#0088FE',
  1: '#36B37E',
  2: '#FF8B00',
};

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

const IndividualClaimsReport = (props) => {
  const {
    chart,
    countDetails,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDownload,
    misReportListData,
    onReportDownload,
    misDownloadReportData,
    misReportType,
    handleMISReport,
    handleSubmitMisReport,
    misReportSubmit,
    handleFileSelected,
    selectedFile,
    handleUpload,
    uploadFileStatus,
    experimentList,
    handleChangeExperimentPage,
    handleChangeExpRowsPerPage,
    experimentPage,
    expRowsPerPage,
    experimentListCount,
  } = props;
  const pieData = Object.keys(chart).map((key, index) => ({
    name: _.capitalize(key),
    value: chart[key],
    color: colors[index],
  }));
  let message = '';
  if (!_.isEmpty(uploadFileStatus)) {
    message = uploadFileStatus.status
      ? 'Uploaded data shall be processed within 3 hours'
      : 'File not uploaded';
  }

  let misReportMessage = '';
  if (!_.isEmpty(misReportSubmit)) {
    misReportMessage = misReportSubmit.status
      ? 'Requested report shall be available for download'
      : misReportSubmit.error;
  }

  let misDownloadReportMessage = '';
  if (!_.isEmpty(misDownloadReportData)) {
    misDownloadReportMessage = misDownloadReportData.status
      ? ''
      : misDownloadReportData.error;
  }
  const [search, setSearch] = useState('');
  return (
    <div>
      <Grid container className="survey__chart">
        <Grid item xs={12}>
          <h1>General stats</h1>
        </Grid>
        <Grid item xs={6}>
          <div className="pie-chart">
            <PieChart
              data={pieData}
              showLegend={false}
              dataKey="value"
              outerRadius={50}
            />
            <h2>Yield loss on basis of Survey completed</h2>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Table className="survey__chart--table">
            <TableBody>
              <TableRow>
                <TableCell>Total Planned:</TableCell>
                <TableCell>{countDetails.targetCount}</TableCell>
                <TableCell>Exp.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Completed Count:</TableCell>
                <TableCell>{countDetails.completedCount}</TableCell>
                <TableCell>Exp.</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Part 2 Completed:</TableCell>
                <TableCell>{countDetails.part2Completed}</TableCell>
                <TableCell>Exp.</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Missed Count:</TableCell>
                <TableCell>{countDetails.missedCount}</TableCell>
                <TableCell>Exp.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pending Count:</TableCell>
                <TableCell>{countDetails.pendingCount}</TableCell>
                <TableCell>Exp.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container className="survey__report">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <h1>MIS Reporting</h1>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <h2>Select Report Type</h2>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5} className="selectController">
          <FormControl className="formControl inputHead coloredForms modeSec">
            <Select
              disableUnderline
              name="dashboard-user"
              className="selectEmpty modalDropdown"
              inputProps={{ 'aria-label': 'user' }}
              value={misReportType}
              displayEmpty
              onChange={handleMISReport}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value="agent">FDO Wise</MenuItem>
              <MenuItem value="location">Area Wise</MenuItem>
              <MenuItem value="bulk">Bulk Data</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Button
            variant="contained"
            disabled={!misReportType}
            className="btn-enable"
            onClick={handleSubmitMisReport}
          >
            Submit
          </Button>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {misReportMessage && (
            <p className="survey__report--error">
              {misReportMessage}
              {' '}
            </p>
          )}
        </Grid>
      </Grid>
      <Grid container className="survey__table bb">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="survey__table__panel">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Report Type</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {misReportListData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const date = new Date(item.createdOn);
                    return (
                      <TableRow key={Math.random()}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.reportType}</TableCell>
                        <TableCell>CSV</TableCell>
                        <TableCell>
                          {`${date.getDate()} ${
                            months[date.getMonth()]
                          } ${date.getFullYear()}`}
                        </TableCell>
                        <TableCell>
                          <span
                            className="material-icons icon"
                            style={{ cursor: 'pointer' }}
                            role="button"
                            tabIndex="0"
                            onClick={() => onReportDownload(item)}
                            onKeyPress={() => onReportDownload(item)}
                          >
                            get_app
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={misReportListData.length || 10}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
          {misDownloadReportMessage && (
            <p className="survey__report--error">
              {misDownloadReportMessage}
              {' '}
            </p>
          )}
        </Grid>
      </Grid>

      <div className="survey__report bb">
        <h1>
          Assign & Upload
          {' '}
          <strong
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex="0"
            onClick={handleDownload}
            onKeyPress={handleDownload}
          >
            Download Format
          </strong>
        </h1>
        <Grid container className="mb5">
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <h2>Select File Type</h2>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <input
              type="file"
              className="survey__report--input"
              onChange={handleFileSelected}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Button
              variant="contained"
              disabled={selectedFile === null}
              className="btn-enable"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
        {message && (
        <p className="survey__report--error">
          {message}
          {' '}
        </p>
        )}
      </div>
      <div className="survey__report mt">
        <Grid container>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <h3>Search ID</h3>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5} className="selectController">
            <FormControl className="formControl inputHead">
              <input
                type="text"
                placeholder="Enter ID"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Button variant="contained" className="btn-enable">
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="survey__table">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="survey__table__panel">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                                                 <TableRow>
                                                 <TableCell colspan="5" align="center">
                                                 <h1>No Pending Request</h1>
                                                 </TableCell>
                                                 </TableRow>
                                                 </TableBody> */}
                <TableBody>
                  {experimentList.map((item, index) => {
                    if (search !== '') {
                      if (item.applicationNo.startsWith(search)) {
                        const date = new Date(item.createdOn);
                        return (
                          <TableRow key={item.applicationNo}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.applicationNo}</TableCell>
                            <TableCell>
                              {`${date.getDate()} ${
                                months[date.getMonth()]
                              } ${date.getFullYear()}`}
                            </TableCell>
                            <TableCell>
                              <span
                                className="icon"
                                role="button"
                                tabIndex="0"
                                onKeyDown={() => props.handlePreviewClick(item, index)
                                }
                                onClick={() => props.handlePreviewClick(item, index)
                                }
                              >
                                Preview
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    }
                    const date = new Date(item.createdOn);
                    return (
                      <TableRow key={item.applicationNo}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.applicationNo}</TableCell>
                        <TableCell>
                          {`${date.getDate()} ${
                            months[date.getMonth()]
                          } ${date.getFullYear()}`}
                        </TableCell>
                        <TableCell>
                          <span
                            className="icon"
                            role="button"
                            tabIndex="0"
                            onKeyDown={() => props.handlePreviewClick(item, index)
                            }
                            onClick={() => props.handlePreviewClick(item, index)
                            }
                          >
                            Preview
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {experimentList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="5" align="center">
                        <h1>No Data Available</h1>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {experimentList.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={experimentListCount}
                  rowsPerPage={expRowsPerPage}
                  page={experimentPage}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangeExperimentPage}
                  onChangeRowsPerPage={handleChangeExpRowsPerPage}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IndividualClaimsReport;
