import ajax from '../Functions/ajax';

export const getLevelStatsAct = params => (dispatch) => {
  dispatch({
    type: 'GET_LEVEL_STATS_FAILURE',
    payload: '',
  });
  dispatch({
    type: 'SET_LEVEL_STATS_LOADER',
    payload: true,
  });

  ajax
    .get('/farms/farms/generalStatsPanel', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'GET_LEVEL_STATS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GET_LEVEL_STATS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GET_LEVEL_STATS_FAILURE',
        payload: error,
      });
    });
};
function genFarmStatsFunction(response, dispatch) {
  if (response.data.status) {
    dispatch({
      type: 'GET_FARM_STATS_SUCCESS',
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: 'GET_FARM_STATS_FAILURE',
      payload: response.data.error,
    });
  }
}
export const getFarmStatsAct = params => (dispatch) => {
  dispatch({
    type: 'FETCHING_FARM_STATS',
    payload: null,
  });
  ajax
    .get('/farms/farms/generalStatsPanel', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      genFarmStatsFunction(response, dispatch);
    })
    .catch((error) => {
      dispatch({
        type: 'GET_FARM_STATS_FAILURE',
        payload: error,
      });
    });
};

export const getCropsRegionWiseAct = params => (dispatch) => {
  dispatch({
    type: 'GET_CROPS_REGIONWISE_FAILURE',
    payload: '',
  });
  ajax
    .get('/farms/farms/cropListLocationWise', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        if (response.data.data.crops) {
          dispatch({
            type: 'GET_CROPS_REGIONWISE_SUCCESS',
            payload: response.data.data.crops,
          });
        } else {
          dispatch({
            type: 'GET_CROPS_REGIONWISE_FAILURE',
            payload: 'Data Not Available',
          });
        }
      } else {
        dispatch({
          type: 'GET_CROPS_REGIONWISE_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CROPS_REGIONWISE_FAILURE',
        payload: error,
      });
    });
};

export const getTopCropsAct = params => (dispatch) => {
  dispatch({
    type: 'GET_TOP_CROPS_FAILURE',
    payload: '',
  });
  ajax
    .get('/farms/farms/topCrops', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'GET_TOP_CROPS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GET_TOP_CROPS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GET_TOP_CROPS_FAILURE',
        payload: error,
      });
    });
};

export const clearFarmStatsAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_FARM_STAT',
    payload: params,
  });
};

function weatherFunction(response, dispatch) {
  if (response.data.status) {
    dispatch({
      type: 'GET_WEATHER_STATS_SUCCESS',
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: 'GET_WEATHER_STATS_FAILURE',
      payload: response.data.error,
    });
  }
}
export const getWeatherStatsAct = params => (dispatch) => {
  ajax
    .get('/weather/weather/getWeather', {
      params,
      isLoader: true,
    })
    .then((response) => {
      weatherFunction(response, dispatch);
    })
    .catch((error) => {
      dispatch({
        type: 'GET_WEATHER_STATS_FAILURE',
        payload: error,
      });
    });
};

function nearestMandiFunction(response, dispatch) {
  if (response.data.status) {
    dispatch({
      type: 'GET_NEARBY_STATS_SUCCESS',
      payload: response.data.data,
    });
  } else {
    dispatch({
      type: 'GET_NEARBY_STATS_FAILURE',
      payload: response.data.error,
    });
  }
}

export const getNearestMandiAct = params => (dispatch) => {
  ajax
    .get('/farms/farms/farmnearbuy', {
      params,
      isLoader: true,
    })
    .then((response) => {
      nearestMandiFunction(response, dispatch);
    })
    .catch((error) => {
      dispatch({
        type: 'GET_NEARBY_STATS_FAILURE',
        payload: error,
      });
    });
};

export const getNdviIndicesAct = params => (dispatch) => {
  ajax
    .get('/indices/indices/timeline', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        console.log(response.data);
        const modData = [];
        Object.keys(response.data.data).forEach((item) => {
          if (response.data.data[item]['ndvi-mode']) {
            modData.push({
              name: response.data.data[item].date,
              index: response.data.data[item]['ndvi-index'],
              max: response.data.data[item]['ndvi-max'],
              mean: response.data.data[item]['ndvi-mean'],
              median: response.data.data[item]['ndvi-median'],
              min: response.data.data[item]['ndvi-min'],
              mode: response.data.data[item]['ndvi-mode'],
            });
          }
        });

        dispatch({
          type: 'NDVI_TIMELINE_HOMEPAGE_DATA_SUCCESS',
          payload: modData,
        });
      } else {
        dispatch({
          type: 'NDVI_TIMELINE_HOMEPAGE_DATA_FAILURE',
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'NDVI_TIMELINE_HOMEPAGE_DATA_FAILURE',
        payload: error,
      });
    });
};

export const setCropRankingParamAct = param => (dispatch) => {
  dispatch({
    type: 'SET_CROP_RANKING_PARAM',
    payload: param,
  });
};

export const setCropAct = param => (dispatch) => {
  dispatch({
    type: 'SET_CROP',
    payload: param,
  });
};

export const callApisOnFarmClickAct = (param1, param2, param3) => (dispatch) => {
  dispatch(clearFarmStatsAct(''));
  const fetch1 = ajax.get('/farms/farms/generalStatsPanel', {
    params: param1,
    isLoader: 'false',
  });
  const fetch2 = ajax.get('/weather/weather/getWeather', {
    params: param3,
    isLoader: true,
  });
  const fetch3 = ajax.get('/farms/farms/farmnearbuy', {
    params: param2,
    isLoader: true,
  });
  dispatch({
    type: 'SET_LEVEL_STATS_LOADER',
    payload: true,
  });

  return Promise.all([fetch1, fetch2, fetch3])
    .then((values) => {
      weatherFunction(values[1], dispatch);
      nearestMandiFunction(values[2], dispatch);
      genFarmStatsFunction(values[0], dispatch);
    })
    .catch((err) => {
      dispatch({
        type: 'GET_NEARBY_STATS_FAILURE',
        payload: err,
      });

      dispatch({
        type: 'GET_WEATHER_STATS_FAILURE',
        payload: err,
      });

      dispatch({
        type: 'GET_FARM_STATS_FAILURE',
        payload: err,
      });
      dispatch({
        type: 'SET_LEVEL_STATS_LOADER',
        payload: true,
      });
    });
};

export const reportFarmBoundaryAct = (farmID, type) => dispatch => ajax
  .post('/farms/farms/farm_flag', {
    data: {
      farmID,
      flagType: type,
    },
    isLoader: true,
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'AREA_REPORT_ACTION_SUCCESS',
        payload: response.data,
      });
    } else {
      // dispatch({
      //   type: 'LAND_REGISTERED_FAILURE',
      //   payload: response.data.error,
      // });
    }
  })
  .catch((err) => {
    dispatch({ type: 'AREA_REPORT_ACTION_SUCCESS', payload: err });
  });

export const reportAreaDataAct = data => dispatch => ajax
  .post('/farms/farms/segment_flag', {
    data,
    isLoader: true,
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'AREA_REPORT_ACTION_SUCCESS',
        payload: response.data,
      });
    } else {
      // dispatch({
      //   type: 'LAND_REGISTERED_FAILURE',
      //   payload: response.data.error,
      // });
    }
  })
  .catch((err) => {
    // console.log('err>>>', err);
    dispatch({ type: 'AREA_REPORT_ACTION_SUCCESS', payload: err });
  });
