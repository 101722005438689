import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../css/gisLeftPanel.scss';

class DashboardLeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLogout = () => {
    const { cookies, history } = this.props;
    cookies.remove('userData', null);
    cookies.remove('loggedInTimeStamp', null);
    history.push('/');
  };

  goToHome = () => {
    const { history } = this.props;
    history.push('/home');
  };

  handleDisable = (ev) => {
    ev.preventDefault();
  };

  render() {
    return (
      <Grid item xs={12} sm={5} className="leftPanelMini">
        <a href="/home" className="logoHead">
          <img
            src="images/logoCircle.svg"
            alt="logo"
            title="logo"
            img="responsive"
          />
          <img
            src="images/logoName.svg"
            alt="logo"
            title="logo"
            img="responsive"
            className="logoName"
          />
        </a>
        <ul>
          <li className="menu-active">
            <a href="/" className="menu-active">
              <img src="images/leftPanelIcons/gis.svg" alt="gis" title="gis" />
              <span>GIS</span>
            </a>
            {/* <button type="button" onClick={() => this.goToHome()}>
                         <img src="images/leftPanelIcons/gis.svg" alt="gis" title="gis" />
                         <span>GIS</span>
                       </button> */}
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/farmer-registration.svg"
                alt="farmer-registration"
                title="farmer-registration"
              />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/analytics.svg"
                alt="analytics"
                title="analytics"
              />
              <span>Analytics & API’s</span>
            </a>
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/ivrs.svg"
                alt="ivrs"
                title="ivrs"
              />
              <span>IVRS</span>
            </a>
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/pricing.svg"
                alt="pricing"
                title="pricing"
              />
              <span>Pricing</span>
            </a>
          </li>
          {/* <li>
            <a href="/">
              <img
                src="images/leftPanelIcons/target.svg"
                alt="target"
                title="target"
              />
              <span>Target</span>
            </a>
          </li> */}
        </ul>
        <a href="/" className="logout" onClick={this.handleLogout}>
          <img
            src="images/leftPanelIcons/logout.svg"
            alt="logout"
            title="logout"
          />
          <span>User</span>
        </a>
      </Grid>
    );
  }
}

export default withRouter(
  connect(
    null,
    null,
  )(withCookies(DashboardLeftPanel)),
);
