import React from 'react';
import { getNewAudioInstance } from './audioPlayerUtil';
import CardPlayerView from './cardPlayerView';

class MiniPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePlayMiniPlayer = () => {
    const { staticMedia, broadcastPlay } = this.props;
    const getmyAudioInstance = getNewAudioInstance(
      staticMedia.mediaSources[0],
      this.onEnded,
    );
    if (getmyAudioInstance) {
      getmyAudioInstance.play();
      // this.setState({
      //   myAudioInstance: getmyAudioInstance,
      // });
      broadcastPlay(staticMedia.id, getmyAudioInstance);
    }
  };

  onEnded = () => {
    const { broadcastPlay } = this.props;
    broadcastPlay();
  };

  handleStopMiniPlayer = () => {
    const { broadcastPlay } = this.props;
    // const { myAudioInstance } = this.state;
    // console.log('iiii',myAudioInstance);
    // myAudioInstance.pause();
    // this.setState({
    //   myAudioInstance: undefined,
    // });
    broadcastPlay();
  };

  render() {
    const { isMediaPlaying } = this.props;
    return (
      <CardPlayerView
        isMediaPlaying={isMediaPlaying}
        handlePlayMiniPlayer={this.handlePlayMiniPlayer}
        handleStopMiniPlayer={this.handleStopMiniPlayer}
      />
    );
  }
}

export default MiniPlayer;
