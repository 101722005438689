/* eslint-disable */
import bbox from '@turf/bbox';
import wkx from 'wkx';
import buffer from 'buffer';
import config from '../config/index';

const languageConverter = (str, langObj) => {
  if (langObj) {
    if (str in langObj) {
      return langObj[str];
    }
    let newStr = '';
    str.split(' ').forEach(item => {
      if (item in langObj) {
        newStr += `${langObj[item]} `;
      } else {
        newStr += `${item} `;
      }
    });
    return newStr;
  }
  return str;
};

const findMissingBounds = (bounds, angle) => {
  const x1 = bounds[0];

  const y1 = bounds[1];

  const x3 = bounds[2];

  const y3 = bounds[3];

  // Step 1: find center point (origin)
  const mx = x1 + (x3 - x1) * 0.5;

  const my = y1 + (y3 - y1) * 0.5;

  // unrotate known points (negative angle)
  const x1u = Math.cos(-angle) * (x1 - mx) - Math.sin(-angle) * (y1 - my) + mx;

  const y1u = Math.sin(-angle) * (x1 - mx) + Math.cos(-angle) * (y1 - my) + my;

  const x3u = Math.cos(-angle) * (x3 - mx) - Math.sin(-angle) * (y3 - my) + mx;

  const y3u = Math.sin(-angle) * (x3 - mx) + Math.cos(-angle) * (y3 - my) + my;

  // To get width and height:
  // var
  //   width = Math.abs(x3u - x1u),
  //   height = Math.abs(y3u - y1u);

  // Mix known coordinates
  const x2u = x1u;

  const y2u = y3u;

  const x4u = x3u;

  const y4u = y1u;

  // rotate new points using angle
  const x2 = Math.cos(angle) * (x2u - mx) - Math.sin(angle) * (y2u - my) + mx;

  const y2 = Math.sin(angle) * (x2u - mx) + Math.cos(angle) * (y2u - my) + my;

  const x4 = Math.cos(angle) * (x4u - mx) - Math.sin(angle) * (y4u - my) + mx;

  const y4 = Math.sin(angle) * (x4u - mx) + Math.cos(angle) * (y4u - my) + my;

  return {
    x1,
    x2,
    x3,
    x4,
    y1,
    y2,
    y3,
    y4
  };
};

const fitLevelBounds = (map, levelType, bounds) => {
  if (map) {
    if (levelType === 'COUNTRY') {
      map.flyTo({
        center: config.mapboxConfig.defaultCenter,
        zoom: config.mapboxConfig.defaultZoom
      });
    } else if (typeof bounds === 'object' && bounds) {
      if (bounds.length > 0) {
        map.fitBounds(bounds, { padding: 20 });
      }
    } else if (typeof bounds === 'string') {
      const geometry = wkx.Geometry.parse(bounds).toGeoJSON();
      const bounding = bbox(geometry);
      // const boundsCenter = center(geometry);
      map.fitBounds(bounding, { padding: 20 });
      // updateBounds(bounding)
    }
  }
};

const addVectorLayer = params => {
  const {
    mapRef, // Reference of Map
    tileSetURL, // Mapbox TileSet URL
    withLineLayer, // Fill Layer includes Line Layer as Well
    sourceLayer,
    sourceId,
    fillLayerId,
    lineLayerId,
    fillZoomExtent,
    fillPaint,
    fillLayout,
    fillmouseMoveFunc,
    fillmouseLeaveFunc,
    fillclickFunc,
    lineZoomExtent,
    linePaint,
    lineLayout,
    linemouseMoveFunc,
    linemouseLeaveFunc,
    lineclickFunc,
    beforeLayerId
  } = params;

  if (mapRef.getSource(sourceId)) {
    mapRef.removeLayer(lineLayerId);
    if (withLineLayer) {
      mapRef.removeLayer(fillLayerId);
    }
    mapRef.removeSource(sourceId);
  }
  mapRef.addSource(sourceId, {
    type: 'vector',
    url: tileSetURL
  });
  if (beforeLayerId) {
    mapRef.addLayer(
      {
        id: fillLayerId,
        type: 'fill',
        source: sourceId,
        'source-layer': sourceLayer,
        minzoom: fillZoomExtent[0],
        maxzoom: fillZoomExtent[1],
        layout: fillLayout,
        paint: fillPaint
      },
      beforeLayerId
    );
  } else {
    mapRef.addLayer({
      id: fillLayerId,
      type: 'fill',
      source: sourceId,
      'source-layer': sourceLayer,
      minzoom: fillZoomExtent[0],
      maxzoom: fillZoomExtent[1],
      layout: fillLayout,
      paint: fillPaint
    });
  }

  if (fillmouseMoveFunc) {
    mapRef.off('mousemove', fillLayerId, fillmouseMoveFunc);
    mapRef.on('mousemove', fillLayerId, fillmouseMoveFunc);
  }

  if (fillmouseLeaveFunc) {
    mapRef.off('mouseleave', fillLayerId, fillmouseLeaveFunc);
    mapRef.on('mouseleave', fillLayerId, fillmouseLeaveFunc);
  }

  if (fillclickFunc) {
    mapRef.off('click', fillLayerId, fillclickFunc);
    mapRef.on('click', fillLayerId, fillclickFunc);
  }

  if (withLineLayer) {
    if (beforeLayerId) {
      mapRef.addLayer(
        {
          id: lineLayerId,
          type: 'line',
          source: sourceId,
          'source-layer': sourceLayer,
          minzoom: lineZoomExtent[0],
          maxzoom: lineZoomExtent[1],
          layout: lineLayout,
          paint: linePaint
        },
        beforeLayerId
      );
    } else {
      mapRef.addLayer({
        id: lineLayerId,
        type: 'line',
        source: sourceId,
        'source-layer': sourceLayer,
        minzoom: lineZoomExtent[0],
        maxzoom: lineZoomExtent[1],
        layout: lineLayout,
        paint: linePaint
      });
    }

    if (linemouseMoveFunc) {
      mapRef.off('mousemove', lineLayerId, linemouseMoveFunc);
      mapRef.on('mousemove', lineLayerId, linemouseMoveFunc);
    }

    if (linemouseLeaveFunc) {
      mapRef.off('mouseleave', lineLayerId, linemouseLeaveFunc);
      mapRef.on('mouseleave', lineLayerId, linemouseLeaveFunc);
    }

    if (lineclickFunc) {
      mapRef.on('click', lineLayerId, lineclickFunc);
      mapRef.on('click', lineLayerId, lineclickFunc);
    }
  }
};

const convertWKBGeom = wkbBuffer => {
  /* // const wkbBuffer = '0103000020E610000001000000E9000000FCFFFF7F3E7D52400000000032F83D40
  // 040000C0497D5240100000C017F63D40000000203F7D5240200000C076F33D4000000080EB7D524020000
  // 0A02AF33D4004000060217E5240F0FFFFDF9BF23D4000000040497E5240200000E093F13D40FCFFFFBFF3
  // 7D5240100000809DF13D4004000040A17D5240F0FFFFBF47F13D40000000A0487D5240200000A0EFEF3D4
  // 0040000E0CF7C52400000000068EF3D40040000C0977C5240F0FFFF5F1EEE3D40080000C07F7C52402000
  // 0020F6EC3D40040000E0817C5240000000206AEB3D40000000A0387D5240100000E050EB3D40FCFFFF3F4
  // E7D5240100000C051EA3D40000000200D7D5240F0FFFF9F54E83D40000000404B7D5240100000205BE83D
  // 40080000C0537D5240200000A00DE73D4008000040AB7C5240200000E0C1E53D40000000E0C47C5240000
  // 000009FE43D40040000C0497D524020000080D2E33D4008000000157D52401000008017E23D4008000020
  // A87C524000000060ADDF3D40040000C03C7C5240000000806ADC3D40040000A0C57B5240100000604DDB3
  // D4004000000C37B5240000000C07BDA3D4008000000CD7B524000000040CDD93D40FCFFFFBFE37B524000
  // 0000C08FD93D40080000E0407C52402000002086D93D40040000C0CC7C524000000060ABD93D40080000C
  // 0027D524020000020B2DA3D4000000020557D5240000000608DDA3D4000000080197D5240200000A02AD8
  // 3D40000000A0387D5240200000E0A6D73D40000000C0427D52401000004033D63D40040000C0C27C52402
  // 00000E09ED43D4008000020927C52402000006087D43D40000000A0617C5240100000A0C5D43D40000000
  // 60617C5240000000C074D53D40000000C0007C52400000004022D63D4004000040E57B5240F0FFFFFF1AD
  // 63D4004000060C97B5240100000C06CD53D40040000C0D27B5240000000008ED43D4000000060007C5240
  // 200000A091D33D4004000080497C5240100000E04AD33D40080000E0087D52400000004044D33D4004000
  // 0C0117D524000000060BBD23D40040000E0017D524000000040FFD13D40040000E0C67D524020000060E2
  // D13D4008000060E37D52401000006079D13D40040000A0FB7D524000000040A1D03D4000000040017E524
  // 0F0FFFF1FB9CF3D40000000403F7E5240000000602ACF3D4008000000257E52401000006081CE3D40FCFF
  // FF1FCB7D5240100000A091CE3D4004000020947D52401000008014CE3D40FCFFFF3FE77D524010000000B
  // CCD3D40040000402D7E5240200000C0E3CC3D40000000E0647E52402000008085CA3D40000000A0647D52
  // 401000004058CA3D4004000080337D524000000060F3C93D4000000060207D5240F0FFFF3FB4C83D40000
  // 00000DB7C5240100000008AC73D40FCFFFF1F867C524020000000B7C63D4008000020797C5240F0FFFF1F
  // 2DC53D4008000020927C52400000002061C33D40000000C0577C524000000060A0C23D4004000020597C5
  // 24010000020F3C03D40040000C08D7C52402000008045C03D40080000E0A47C5240F0FFFF9F56BE3D4000
  // 0000005E7C524000000040FABD3D40080000C0087B52400000002041BE3D40000000E0DA7A5240F0FFFF3
  // F3ABD3D40FCFFFF9FBD795240200000803DBD3D4004000080357952402000004097BC3D4004000080EA78
  // 524010000080EFBC3D40000000E0AA78524010000060CCBD3D40040000E073785240200000E028BE3D400
  // 00000C031785240F0FFFF1FDABD3D4008000060E27752400000000006BB3D400800000094775240100000
  // 00AAB93D40080000A0687752400000000094BA3D40080000602077524000000040CCBA3D4008000040017
  // 75240200000204CBB3D40040000C0A87652400000004087BB3D4000000080EC7552401000006054BA3D40
  // 08000080BF7552400000002045B93D40040000601F7552401000004051B83D40000000A0DC74524020000
  // 0E033B83D40080000E0BB7452401000004089B93D40000000404F745240F0FFFFFF0DB93D40FCFFFFDF48
  // 745240000000603DB73D40040000C0347452401000008088B63D4004000080E9735240100000A0BDB63D4
  // 0040000A0AA735240000000A068B73D40040000401B745240000000E0C1BA3D40080000E0C17352400000
  // 0020F3BB3D40040000E0F57252402000002021BE3D40000000C0D57252401000002027BF3D40000000E0D
  // E725240000000E09FC03D4000000060C97252400000002086C13D40000000000E73524000000080F1C13D
  // 40000000A00773524010000060FAC23D4008000060C47252401000002057C33D4000000000AA725240100
  // 0008044C43D4004000060667252402000004065C43D400400006091725240100000C003C73D40080000C0
  // E9715240F0FFFF7FD2C63D40080000E0CD715240000000C052C63D40080000404471524010000080BFC63
  // D400000000033715240200000805BC73D4004000040C3705240000000C08AC73D40000000009770524020
  // 00000046C83D4000000060456F5240100000A0E8C83D40040000C0776E52400000008095C83D40080000C
  // 01D6E524020000000C4C83D4008000060AD6D5240000000E055C83D4004000060BF6C5240000000A043C8
  // 3D40080000E09D6C5240200000A05EC93D40000000A0FC6C5240200000001BCB3D4000000020066D52402
  // 0000080BACC3D4008000040E56C5240000000E07BCE3D40000000C07F6C524010000040F4CD3D40040000
  // 60ED6B524000000080B7CD3D4000000000256B524010000020F1CD3D40000000E0856A524000000040D8C
  // D3D40000000002E69524020000000D7CB3D40040000C0AE6852402000000060CC3D4008000020F7675240
  // 00000000ABCD3D40000000A0B3675240000000C0D5CD3D40040000207967524020000040ACCD3D4008000
  // 020646752401000006040CE3D40000000A05F67524020000000DFCE3D40000000A0C6675240200000A0BD
  // CE3D4004000040C4675240000000A057CF3D40000000E0A66752400000000011D03D40080000E06D67524
  // 02000002026D03D40040000A06F67524020000060E7D03D40080000402F6752400000004002D13D400400
  // 00601B675240000000C0BDD13D4004000020F6665240100000E036D23D40FCFFFFBFFD665240F0FFFF9F3
  // 0D33D40080000A04B6752402000004031D33D40040000A04367524010000020FDD33D4008000040166852
  // 401000002049D43D40040000604468524010000020BBD63D40080000E080685240100000C0C8D63D40080
  // 0004080685240F0FFFFFF4DD83D4000000060EC685240000000202DD83D400000000002695240000000C0
  // 06D93D40000000602A69524010000020BCD83D40040000803E69524000000080C7D93D400400000035695
  // 240000000A0A9DA3D400000000015695240000000A097DB3D40FCFFFF1F146952401000002092DD3D4008
  // 000000DB68524010000000B3DD3D4004000080CD685240100000C08FDE3D40FCFFFFFFA6695240200000C
  // 0E2DD3D40000000E0CF695240F0FFFFDFD5DE3D40040000801C6A5240000000007DDF3D40080000E0EE6A
  // 5240F0FFFF9F71DF3D4004000060FA6A524010000080A7DF3D4004000060036B5240000000C070E23D400
  // 00000E0F56A5240100000202FE53D40000000609E6A5240000000201EE53D4000000040886A5240200000
  // 8004EA3D40000000408F6A52400000006096F13D40080000C0D56A52400000008026F23D4004000060C56
  // A5240F0FFFF3F48F33D4004000020ED6A524000000080E7F33D40000000401E6B5240200000C0F0F63D40
  // FCFFFF1F216B5240000000A0EBF73D40000000C0596B524020000040A3F83D40080000A0686B524000000
  // 0E09AF93D4004000020676B524000000000DDFB3D40080000A0BC6B52400000004085FC3D4004000000B9
  // 6B5240100000C09DFD3D40040000A0296C52401000000096FD3D4004000080426C5240F0FFFFDF92FE3D4
  // 000000040476C5240100000A0F6FE3D40040000C04E6C52401000000034013E4004000020776C52400000
  // 0020EE013E40FCFFFFDF786C5240200000801F033E4000000080FC6B5240000000A051043E40080000404
  // 66C524010000000BC053E4008000060FB6A5240200000C0BA053E4000000080FF6A524000000060E1083E
  // 4004000040006B52400000004074093E40080000806B6B524020000040F9093E40080000E0626D5240200
  // 00020100C3E40080000404170524010000060790E3E40000000C061725240200000A0FF103E4000000040
  // D272524020000020D5113E40040000C03A735240200000608E133E40000000C09A735240F0FFFFBF1E153
  // E40080000009D755240000000E0D31B3E40000000A04C76524010000020B81D3E40080000E0AC76524020
  // 000060C91E3E4004000000D877524010000020DF213E40080000208B785240100000C08B233E40080000E
  // 04F795240200000C03F263E40FCFFFFDFD979524010000020AE273E40040000201A7B524010000060FB2A
  // 3E40000000404F7D5240100000208D2F3E4000000020D87D5240000000C0EE313E40040000A0D97D52402
  // 0000060CA313E4004000080F57D5240200000402E2F3E4008000020DA7D5240100000E0512D3E40080000
  // E0A77D524020000000FD2B3E40000000207E7D5240100000C0AA243E4000000000847D5240F0FFFFDF5C2
  // 23E40FCFFFF1F997D5240200000E0A3203E4004000020B17D524000000060D71F3E40080000A0DD7D5240
  // 20000040061F3E40000000004F7D5240200000809B1E3E40040000A0CC7C5240000000401B1C3E4008000
  // 0406C7C5240200000E0AE1A3E40040000C0587C5240100000005E193E40080000209E7B5240000000A074
  // 153E4008000000C47A52402000004072113E40000000A0257A524010000060630F3E40000000807F79524
  // 000000000BC0D3E40000000804479524020000060FE0B3E40080000A02079524020000020DD093E400400
  // 0040AC7852402000004076043E400400008070785240100000A0E0023E400400006064785240F0FFFF3F0
  // CFF3D40040000808378524020000040C5FD3D4000000020DD785240200000E055FB3D40000000A0EC7852
  // 40000000A044FA3D4000000020097952400000008056F83D4000000080B57B52401000008082F83D40FCF
  // FFF7F3E7D52400000000032F83D40'; */
  // console.log("Before Parsed Geometery >>>>>>",wkbBuffer);
  // Split WKB into array of integers (necessary to turn it into buffer)
  const hexAry = wkbBuffer.match(/.{2}/g);
  const intAry = [];
  for (let i = 0; i < hexAry.length; i += 1) {
    intAry.push(parseInt(hexAry[i], 16));
  }

  // Generate the buffer
  const buf = new buffer.Buffer(intAry);

  // Parse buffer into geometric object
  // var geom = wkx.Geometry.parse(buf);
  const wkbParsed = wkx.Geometry.parse(buf).toGeoJSON();
  // console.log('After Parsed Geometery >>>>>>', wkbParsed);
  return wkbParsed;
};

const objectToCsv = data => {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map(header => {
      const escaped = `${row[header]}`.replace(/"/g, '\\"');
      return `${escaped}`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

const download = data => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'download.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export {
  addVectorLayer,
  languageConverter,
  findMissingBounds,
  fitLevelBounds,
  convertWKBGeom,
  objectToCsv,
  download
};
