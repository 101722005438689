import React from 'react';
import {
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';

const CustomTooltip = (props) => {
  console.log('>>>>>>', props);
  const { payload } = props;
  const newPayload = payload[0];
  let data1 = {};
  if (newPayload) {
    console.log('newPayload>>>', newPayload.payload);
    data1 = newPayload.payload;
  }
  // console.log('newPayload>>>',newPayload.color);
  return (
    <React.Fragment>
      {!_.isEmpty(data1) && (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            padding: '5px',
            width: '200px',
            float: 'left',
          }}
        >
          <p className="label">{data1.day}</p>
          <p className="label" style={{ color: '#4a90e2' }}>
            maxTemp:
            {data1.maxTemp}
          </p>
          <p className="label" style={{ color: '#09b466' }}>
            minTemp:
            {data1.minTemp}
          </p>
          <p>{data1.weather}</p>
        </div>
      )}
    </React.Fragment>
  );
};

const BarChartComponent = (props) => {
  const {
    data,
    dataKey,
    yAxisLabel,
    showToolTip,
    showLegend,
    children,
  } = props;
  return (
    <ResponsiveContainer width="80%" aspect={4.0 / 2.5}>
      <BarChart data={data} barGap={0}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={dataKey}
          stroke="#444444"
          fontSize="80%"
          tickLine={false}
        />
        <YAxis stroke="#444444" fontSize="80%" tickLine={false}>
          <Label
            value={yAxisLabel}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle', fontSize: '80%', fill: '#bababa' }}
          />
        </YAxis>
        {showToolTip ? <Tooltip /> : <Tooltip content={<CustomTooltip />} />}
        {showLegend && (
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        )}
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
