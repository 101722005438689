import React from 'react';
import { Button, Dialog } from '@material-ui/core';

class ThankuCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open } = this.props;
    return (
      <React.Fragment>
        <Dialog
          maxWidth={false}
          onClose={this._handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div className="paper custom-paper">
            <div className="modalSec">
              <div className="modalContent thankuContent">
                <ul>
                  <li>
                    <img src="/images/Gis/thanku.svg" alt="thanku-img" />
                  </li>
                </ul>

                <div className="demoText">
                  <div
                    role="button"
                    tabIndex="0"
                    className="modalCloseIcon"
                    // onClick={this._handleClose}
                    // onKeyDown={this._handleClose}
                  >
                    <i className="material-icons">close</i>
                  </div>
                  <h1>
                    Thank you for your Demo request. We will contact you shortly
                    to schedule your demo.
                  </h1>
                </div>
                <div className="darkgreenBtnHeader thankuBtn">
                  <Button variant="contained" className="button darkgreenBtn">
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default ThankuCard;
