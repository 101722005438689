/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField
} from '@material-ui/core';
import Datetime from 'react-datetime';
import moment from 'moment';
import Toastr from '../common/toastr';
import FgFilterModal from '../tagging/fgFilterModal';
import UploadViewFormatModal from '../ivrs/uploadViewFormatModal';
import FarmerListModal from '../tagging/farmerListModal';
import ListModal from '../tagging/listModal';
import TaggingFixedPanel from '../tagging/common/taggingFixedPanel';
import UploadModal from '../tagging/uploadModal';
import { objectToCsv, download } from '../../Functions/helper';
import {
  farmeList,
  getCreateCampaign,
  resetTaggedFarmerList,
  resetTaggingDetails,
  resetTaggingFarmerDetails,
  resetUploadFarmerDetails,
  uploadFarmers
} from '../../Actions/taggingAction';
import { resetLocationAct } from '../../Actions/locationHierarchyAction';
import '../../css/common.scss';
import '../../css/form.scss';
import '../../css/table.scss';
import '../../css/tagging/main.scss';
import '../../css/tagging/selectFarmer.scss';
import _ from 'lodash';

class SmsSelectFarmer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFgModalOpen: false,
      uploadTaggedFarmerList: [],
      confirmUpload: false,
      showModal: false,
      showUploadModal: false,
      isFormatModalOpen: false,
      isFarmerTagged: false,
      showModalOpen: false,
      dateTime: '',
      mobileNo: '',
      checked: false,
      error: '',
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      selectedFarmerList: [],
      uploadCheckbox: false,
      uploadError: '',
      uploaded: false
    };
  }

  componentDidMount() {
    const { resetLocationDetails, resetTaggedList } = this.props;
    resetLocationDetails();
    resetTaggedList();
  }

  componentDidUpdate(prevProps) {
    const { createCampaign } = this.props;
    if (!_.isEmpty(createCampaign) && _.isEmpty(prevProps.createCampaign)) {
      this.showMessageType(createCampaign);
    }
  }

  showMessageType = createCampaign => {
    const { anotherTab } = this.props;
    if (createCampaign.status) {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Campaign Created Successfully',
        toastrMessageType: 'success'
      });
      setTimeout(() => {
        anotherTab();
      }, 1000);
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Campaign Not Created',
        toastrMessageType: 'error'
      });
    }
  };

  handleOpenFgModal = () => {
    this.setState({
      isFgModalOpen: true
    });
  };

  handleCloseFgModal = () => {
    this.setState({
      isFgModalOpen: false
    });
  };

  handleModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  uploadModal = () => {
    this.setState({
      showUploadModal: true
    });
  };

  handleCloseUploadModal = () => {
    this.setState({
      showUploadModal: false,
      uploadCheckbox: false
    });
  };

  handleViewFormatModal = () => {
    this.setState({
      isFormatModalOpen: true
    });
  };

  handleViewFormatClose = () => {
    this.setState({
      isFormatModalOpen: false
    });
  };

  handleFileSelected = e => {
    this.setState({
      selectedFile: e.target.files[0]
    });
  };

  handleViewTaggedList = () => {
    this.setState({
      showModalOpen: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showModalOpen: false
    });
  };

  handleDateTime = momt => {
    this.setState({
      dateTime: moment(momt._d)
        .format()
        .slice(0, 16)
    });
  };

  valid = current => {
    const yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  handleMobileChange = e => {
    const mobileRegex = /^[6-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(mobileRegex) && e.target.value !== '') {
      return;
    }
    this.setState({
      mobileNo: e.target.value
    });
  };

  handleChange = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
  };

  handleSchedule = type => {
    const { dateTime, mobileNo, checked, uploadTaggedFarmerList } = this.state;
    const {
      resetDetails,
      scheduleCampaign,
      taggedListFarmer,
      cookies,
      smsText,
      smsTitle,
      smsDesc,
      smsLink
    } = this.props;
    const uploadFilterList = uploadTaggedFarmerList.map(obj => {
      return {
        name: obj.name,
        farmerID: obj.farmerID,
        mobile: obj.mobile,
        sms: true,
        ivrs: false
      };
    });
    const userData = cookies.get('userData');
    const { farmerList } = taggedListFarmer;
    let newilterFarmerList = [];
    let filterFarmerList = [];
    let farmersToBeSchedule = [];
    if (!_.isEmpty(taggedListFarmer)) {
      newilterFarmerList =
        farmerList &&
        farmerList.length &&
        farmerList.map(({ farmerName: name, ...rest }) => ({ name, ...rest }));
    }
    if (newilterFarmerList.length > 0) {
      // adding extra parameters
      filterFarmerList = newilterFarmerList.map(item => ({
        ...item,
        sms: true,
        ivrs: false
      }));
    }
    farmersToBeSchedule = [...filterFarmerList, ...uploadFilterList];

    const data = {};
    data.weblink_url = smsLink || '';
    data.campaign_title = smsTitle || 'Sms Title';
    data.poster_image_id = '49ba9907-bd39-481b-9da6-a59d8bb4c9d2';
    data.sms_text_description = smsText || 'Sms Text Description';
    data.campaignDescription = smsDesc || 'Test Description';
    data.insight_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.org_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.data_collection_type = smsLink !== '' ? 3 : 2;
    data.farmer_type = 1;
    data.start_time = dateTime;
    data.type = type === 'schedule' ? 1 : 2;
    data.data_points = [];
    data.farmers =
      type === 'schedule'
        ? filterFarmerList
        : [
            {
              farmerID: '06e38693-9a80-4cf5-b4bf-c9873ffedddd',
              mobile: mobileNo,
              name: 'test name',
              sms: true,
              ivrs: false
            }
          ];

    let error = '';
    let check = true;

    if (!mobileNo && type !== 'schedule') {
      error = 'Please enter mobile no.';
      check = false;
    }
    if (
      farmersToBeSchedule &&
      farmersToBeSchedule.length === 0 &&
      type === 'schedule'
    ) {
      error = ' Please select the farmers to send sms';
      check = false;
    }
    if (!checked && type === 'schedule') {
      error = 'Please Acknowledge';
      check = false;
    }
    if (!dateTime) {
      error = 'Please select date and time';
      check = false;
    }
    if (check) {
      if (userData) {
        scheduleCampaign(data, userData.token);
      }
      resetDetails();
      this.setState({
        error: ''
      });
    } else {
      this.setState({
        error
      });
    }
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false
    });
  };

  handleUpdateTaggedList = list => {
    if (list.length > 0) {
      this.setState({
        uploadTaggedFarmerList: list,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
        isFarmerTagged: true
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
        isFarmerTagged: false
      });
    }
  };

  handleUploadCheckbox = () => {
    const { uploadCheckbox } = this.state;
    this.setState({
      uploadCheckbox: !uploadCheckbox
    });
  };

  fileUploadHandler = () => {
    const { selectedFile, uploadCheckbox } = this.state;
    const { uploadFile, cookies } = this.props;
    const userData = cookies.get('userData');
    let check = true;
    let uploadError = '';
    if (!uploadCheckbox) {
      check = false;
      uploadError = 'Please Acknowledge';
    }
    if (selectedFile === null) {
      check = false;
      uploadError = 'Please select the file';
    }
    if (check) {
      if (selectedFile && uploadCheckbox) {
        const formData = new FormData();
        formData.append('farmer', selectedFile);
        if (userData) {
          uploadFile(formData, userData.token);
        }
        // uploadFile(formData);
        this.setState({
          uploaded: true,
          uploadError: ''
        });
      }
    } else {
      this.setState({
        uploadError
      });
    }
  };

  uploadAgain = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      uploaded: false,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  downloadFile = () => {
    const { taggedListFarmer } = this.props;
    const { farmerList } = taggedListFarmer;
    const data =
      farmerList &&
      farmerList.map(item => ({
        name: item.name,
        mobile: item.mobile
      }));
    const csvData = objectToCsv(data);
    download(csvData);
  };

  handleConfirmUpload = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      confirmUpload: true,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  render() {
    const {
      handlePrevious,
      farmersUploadError,
      farmersUploaded,
      taggedListFarmer,
      smsTitle,
      smsDesc,
      smsText,
      smsLink
    } = this.props;
    const {
      isFgModalOpen,
      uploadTaggedFarmerList,
      confirmUpload,
      showModal,
      showUploadModal,
      isFormatModalOpen,
      selectedFile,
      isFarmerTagged,
      showModalOpen,
      dateTime,
      mobileNo,
      checked,
      error,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      selectedFarmerList,
      uploadError,
      uploaded,
      uploadCheckbox
    } = this.state;
    const splitDate = dateTime.split('T');
    const { totalCount, farmerList } = taggedListFarmer;
    let count = 0;
    if (totalCount) {
      count += parseInt(totalCount);
    }
    if (uploadTaggedFarmerList.length && uploadTaggedFarmerList.length > 0) {
      count += uploadTaggedFarmerList.length;
    }
    return (
      <div className="root">
        <Grid container className="taggingMain" spacing={2}>
          <Grid item lg={9} xs={12} className="insightLeftPanel">
            <h5>SMS</h5>
            <ul className="breadCrumb">
              <li className="backLink">
                <Button
                  variant="contained"
                  style={{
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                  }}
                  onClick={() => handlePrevious()}
                >
                  <i className="material-icons">keyboard_backspace</i>
                  Back
                </Button>
                <i className="material-icons line">remove</i>
              </li>
            </ul>
            <div className="farmerSelectionPanel">
              <Grid container spacing={2} className="addFarmerWithOutput">
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className="selectAddFarmers darkgreenBtnHeader"
                >
                  <h4>Select Farmers</h4>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            totalCount !== 0 && totalCount !== undefined
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {totalCount !== 0 && totalCount !== undefined
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6}>
                        <h6>Add Farmers</h6>
                        <p>
                          Total farmers :<span> {totalCount || 0} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <Button
                          className={
                            farmerList && farmerList.length > 0
                              ? 'upload-btn-enable textCenter'
                              : 'upload-btn textCenter'
                          }
                          onClick={this.downloadFile}
                          disabled={farmerList === undefined}
                        >
                          Download
                        </Button>
                      </Grid>
                      <Grid item lg={2} />
                      <Grid item lg={10} md={12} xs={12} className="btn-panel">
                        <Button
                          className="upload-enable textCenter"
                          onClick={this.handleOpenFgModal}
                          // disabled
                        >
                          FG Database
                        </Button>
                        <Button
                          className="upload-btn-disable textCenter"
                          disabled
                        >
                          My Resources
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            uploadTaggedFarmerList &&
                            uploadTaggedFarmerList.length > 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {uploadTaggedFarmerList &&
                          uploadTaggedFarmerList.length > 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={7}>
                        <h6>Upload your farmers</h6>
                        <p>Upload your farmer’s database</p>
                        <p>
                          Total farmers :
                          <span>{uploadTaggedFarmerList.length}</span>
                        </p>
                      </Grid>
                      <Grid item lg={3}>
                        <Button
                          className={`${
                            !confirmUpload ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!confirmUpload}
                          onClick={this.handleModal}
                        >
                          View List
                        </Button>
                      </Grid>
                      <Grid item lg={2} />
                      <Grid item lg={3} className="btn-panel">
                        <Button
                          className="upload-btn-disable textCenter"
                          onClick={this.uploadModal}
                          disabled
                        >
                          Upload
                        </Button>
                      </Grid>
                      <Grid item lg={7}>
                        <h5 className="upload-view">
                          <Button
                            onClick={this.handleViewFormatModal}
                            style={{ pointerEvents: 'none', cursor: 'default' }}
                          >
                            View Format
                          </Button>
                        </h5>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12}>
                        <div className="upload-box-footer">
                          {!confirmUpload ? (
                            <p className="footer-content">No file uploaded</p>
                          ) : farmersUploadError === '' ? (
                            <p className="footer-content">
                              Uploaded file: <span>{selectedFile.name}</span>
                              <i className="material-icons">clear</i>
                            </p>
                          ) : (
                            <p className="footer-content">No file uploaded</p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            count !== 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {count !== 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6}>
                        <h6>To Be Tagged Farmers</h6>
                        <p>
                          Total farmers :<span> {count} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <Button
                          className={`${
                            !isFarmerTagged ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!isFarmerTagged}
                          onClick={this.handleViewTaggedList}
                        >
                          View List
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className="finalOutput">
                  <h4 className="blackBorder">{smsTitle}</h4>
                  <div className="finalOutputImg blackBorder">
                    <div className="finalOutputDetail">
                      <h5>SMS Text Description: {smsText}</h5>
                      <h5>SMS Link: {smsLink || 'NA'}</h5>
                      <p>
                        <span>Campaign Details:</span>
                        {smsDesc}
                      </p>
                    </div>
                  </div>
                  <div className="scheduleTime blackBorder">
                    <h4>Schedule SMS time</h4>
                    <div className="dateTimePicker">
                      <i className="material-icons calender-icon">date_range</i>
                      <Datetime
                        isValidDate={this.valid}
                        onChange={this.handleDateTime}
                        inputProps={{ placeholder: 'Select date and time' }}
                      />
                    </div>
                    <ul className="dateTimeValue">
                      <li>
                        Date:
                        <span className="nobileGreyTxt">{splitDate[0]}</span>
                      </li>
                      <li>
                        Time:
                        <span className="nobileGreyTxt">{splitDate[1]}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="scheduleSms darkgreenBtnHeader">
                    <div className="enterNumber">
                      <TextField
                        id="mobilenum"
                        placeholder="Enter Mobile Number"
                        className="textField inputHead customText-width"
                        onChange={this.handleMobileChange}
                        value={mobileNo}
                        inputProps={{ maxLength: 10 }}
                      />
                      <Button
                        className="darkgreenBtn"
                        onClick={() => this.handleSchedule('test')}
                      >
                        Test SMS
                      </Button>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={this.handleChange}
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="I hereby acknowledge that this message doesn’t carry any unwanted or abusive words "
                      className="acknowledgeCheckbox"
                    />
                    <Paper className="scheduleSmsBtn">
                      <Button
                        className="darkgreenBtn"
                        onClick={() => this.handleSchedule('schedule')}
                      >
                        Schedule SMS
                      </Button>
                    </Paper>
                    {error ? <p className="errorMsg1"> {error} </p> : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <TaggingFixedPanel />
          {showModal && (
            <ListModal
              show={showModal}
              onClose={this.handleCloseModal}
              farmerList={selectedFarmerList}
              updateTaggedList={this.handleUpdateTaggedList}
            />
          )}
          <UploadModal
            show={showUploadModal}
            onClose={this.handleCloseUploadModal}
            handleChange={this.handleFileSelected}
            uploadHandler={this.fileUploadHandler}
            uploaded={uploaded}
            uploadAgain={this.uploadAgain}
            farmersUploaded={farmersUploaded}
            uploadCheckbox={uploadCheckbox}
            handleUploadCheckbox={this.handleUploadCheckbox}
            confirmUpload={this.handleConfirmUpload}
            uploadError={uploadError}
            farmersUploadError={farmersUploadError}
          />
          {isFgModalOpen && (
            <FgFilterModal
              show={isFgModalOpen}
              onClose={this.handleCloseFgModal}
            />
          )}
          <FarmerListModal
            show={showModalOpen}
            onClose={this.handleModalClose}
            taggedList={uploadTaggedFarmerList}
          />
          <UploadViewFormatModal
            showModal={isFormatModalOpen}
            onClose={this.handleViewFormatClose}
          />
          <Toastr
            message={toastrMessage}
            show={istoastrOpen}
            handleClose={this.handleToastrClose}
            messageType={toastrMessageType}
          />
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging, location }) => ({
      createCampaign: tagging.createCampaign,
      farmersList: tagging.farmersList,
      farmersUploaded: tagging.farmersUploaded,
      farmerListError: tagging.farmerListError,
      taggedListFarmer: tagging.taggedListFarmer,
      farmersUploadError: tagging.farmersUploadError,
      uploadPosterUrl: tagging.uploadPosterUrl,
      location
    }),
    dispatch => ({
      scheduleCampaign: (data, token) =>
        dispatch(getCreateCampaign(data, token)),
      resetDetails: () => dispatch(resetTaggingDetails()),
      getFarmerList: data => dispatch(farmeList(data)),
      uploadFile: (data, token) => dispatch(uploadFarmers(data, token)),
      resetFarmerDetails: () => dispatch(resetTaggingFarmerDetails()),
      resetLocationDetails: () => dispatch(resetLocationAct()),
      resetTaggedList: () => dispatch(resetTaggedFarmerList()),
      resetUploadDetails: () => dispatch(resetUploadFarmerDetails())
    })
  )(withCookies(SmsSelectFarmer))
);
