import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { Grid, TextField, Button } from '@material-ui/core';
import _ from 'lodash';
import {
  getChipsApi,
  createIvrs,
  resetDetails,
} from '../../Actions/ivrsAction';
import InventoryChip from './inventoryChip';
import GraphCanvas from './graphCanvas';
import ChipInfoModal from './chipInfoModal';
import CardSetting from './cardSettings';
import IvrsModal from './ivrsModal';
import Schedule from './schedule';
import Toastr from '../common/toastr';
import '../../css/ivrs/inventoryChip.scss';
import AddAudio from './addAudio';

const options = {
  layout: {
    hierarchical: false,
  },
  physics: {
    enabled: false,
  },
  interaction: {
    //  dragNodes: false,// do not allow dragging nodes
    zoomView: false, // do not allow zooming
    dragView: false, // do not allow dragging
  },
  height: '360px',
  width: '1338px',
  edges: {
    color: {
      color: 'blue',
    },
    font: {
      align: 'middle',
      size: 20,
    },
    width: 2,
  },
  nodes: {
    shape: 'box',
    color: '#f0f8ff',
    heightConstraint: 140,
    widthConstraint: 180,
  },
};

class IvrsSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      graph: {
        nodes: [],
        edges: [],
      },
      activeCard: {},
      showModal: false,
      activeNode: '',
      showAddMediaModal: false,
      ivrsName: '',
      ivrsDesc: '',
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      isDisabled: true,
      show: false,
      activeChip: null,
      showSettings: false,
      chipList: [],
      modifyChipList: [],
    };
  }

  componentDidMount() {
    const { fetchChipsData, resetCreateIvrsDetails } = this.props;
    resetCreateIvrsDetails();
    fetchChipsData();
  }

  componentDidUpdate(prevProps) {
    const { media, ivrsSaved, ivrsChips } = this.props;
    const { cards, activeNode } = this.state;
    if (ivrsChips.length > prevProps.ivrsChips.length) {
      this.updateChips();
    }
    if (ivrsSaved === true && prevProps.ivrsSaved === false) {
      this.showMessage(ivrsSaved);
    }
    if (!_.isEmpty(media) && _.isEmpty(prevProps.media)) {
      const cardsCopy = [...cards];
      for (let i = 0; i < cardsCopy.length; i += 1) {
        if (cardsCopy[i].id.toString() === activeNode.toString()) {
          const cardCopy = _.cloneDeep(cardsCopy[i]);
          const { name } = cardCopy.medias[cardCopy.medias.length - 1];
          cardCopy.medias.pop();
          const mediaObj = { ...media.data };
          mediaObj.name = name;
          cardCopy.medias.push(mediaObj);
          cardsCopy[i] = { ...cardCopy };
        }
      }
      this.updateCard(cardsCopy);
    }
  }

  showMessage = (status) => {
    const { resetCreateIvrsDetails } = this.props;
    if (status === true) {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'IVRS Created Successfully',
        toastrMessageType: 'success',
        isDisabled: false,
      });
      resetCreateIvrsDetails();
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'IVRS Not Created',
        toastrMessageType: 'error',
        isDisabled: true,
      });
      resetCreateIvrsDetails();
    }
  };

  updateChips = () => {
    const { ivrsChips } = this.props;
    const { modifyChipList } = this.state;
    const chipListCopy = [...ivrsChips];
    const newChip = { id: chipListCopy.length + 1, name: 'Custom' };
    chipListCopy.push(newChip);
    modifyChipList.push(newChip);
    this.setState({ chipList: chipListCopy, modifyChipList });
  };

  updateCard = (cardCopy) => {
    this.setState({
      cards: cardCopy,
    });
  };

  handleCardDrop = (chip, inventoryName) => {
    console.log('chip>>>', chip, inventoryName);
    const { graph, cards } = this.state;
    const { nodes } = graph;
    this.setState(
      {
        show: true,
        activeChip: _.cloneDeep(chip),
      },
      () => {
        let customCount = 0;
        let id;
        const nodesCopy = [...nodes];
        // handle is of nodes in graph
        if (nodes.length > 0) {
          // handle when there is already a node
          // increment id accordingly
          let max = nodesCopy[0].id;
          for (let i = 0; i < nodesCopy.length; i += 1) {
            if (nodesCopy[i].label.indexOf('Custom') > -1) {
              customCount += 1;
            }
            // if there are more than two nodes check any nodes id is greater than max or not
            if (nodesCopy[i].id > max) {
              max = nodesCopy[i].id;
            }
          }
          id = max + 1;
        } else {
          // first time when chip is dragged 'handle chip id'
          id = nodes.length + 1;
        }
        let nodeData = {};
        let card = {};
        if (inventoryName.indexOf('Custom') > -1) {
          nodeData = {
            id,
            label: inventoryName + (customCount + 1).toString(),
            heightConstraint: 140,
          };
        } else {
          nodeData = { id, label: inventoryName, heightConstraint: 140 };
        }
        const graphData = _.cloneDeep(graph);
        graphData.nodes.push(nodeData);
        this.setState(
          {
            graph: graphData,
          },
          () => {
            console.log('graph>>>', graph);
            const cardsCopy = [...cards];
            if (inventoryName.indexOf('Custom') > -1) {
              card = {
                id,
                name: inventoryName + (customCount + 1).toString(),
                medias: [],
                outs: [],
              };
            } else {
              card = {
                id,
                name: inventoryName,
                medias: [],
                outs: [],
              };
            }
            if (card.name.indexOf('Custom') === -1) {
              card.cardProps = _.cloneDeep(chip);
            }
            cardsCopy.push(card);
            this.setState(
              {
                cards: cardsCopy,
              },
              () => {
                console.log('cards', cards);
              },
            );
          },
        );
      },
    );
  };

  handleConnection = (value, card) => {
    console.log('card>>>>>>>>>>>>>>>>>>>>', card);
    this.setState(
      {
        activeCard: _.cloneDeep(card),
      },
      () => this.setState({
        showModal: value,
      }),
    );
  };

  handleClose = (value) => {
    console.log('value', value);
    this.setState({ showModal: value });
  };

  handleAdd = (fromNode, toNode, inPoint) => {
    const { graph, cards } = this.state;
    const graphCopy = _.cloneDeep(graph);
    // update the properties of edge
    const edge = { from: fromNode.id, to: toNode, label: inPoint };
    graphCopy.edges.push(edge);
    console.log('graph', graphCopy);
    this.setState({ graph: graphCopy, showModal: false }, () => {
      const cardsCopy = [...cards];
      for (let i = 0; i < cardsCopy.length; i += 1) {
        if (cardsCopy[i].id.toString() === fromNode.id.toString()) {
          const cardCopy = _.cloneDeep(cardsCopy[i]);
          console.log('cardsCopy', cardCopy);
          const keyArray = [];
          keyArray.push(inPoint, toNode);
          // add inPoint and toNode to outs
          cardCopy.outs.push(keyArray);
          cardsCopy[i] = { ...cardCopy };
        }
      }
      this.setState({ cards: cardsCopy }, () => {
        console.log('cards', cardsCopy);
      });
    });
  };

  handleMinimise = (nodeId) => {
    const { graph } = this.state;
    const graphCopy = _.cloneDeep(graph);
    const nodesCopy = [...graphCopy.nodes];
    for (let i = 0; i < nodesCopy.length; i += 1) {
      if (nodesCopy[i].id === nodeId) {
        if (nodesCopy[i].heightConstraint === 140) nodesCopy[i].heightConstraint = 27;
        // reduce the height to 27
        else nodesCopy[i].heightConstraint = 140; // reduce to original height
      }
    }
    graphCopy.nodes = nodesCopy;
    this.setState({ graph: graphCopy });
  };

  handleDelete = (index, CardId) => {
    const { cards } = this.state;
    const cardsCopy = [...cards];
    for (let i = 0; i < cardsCopy.length; i += 1) {
      if (cardsCopy[i].id.toString() === CardId.toString()) {
        const cardCopy = _.cloneDeep(cardsCopy[i]);
        const mediasCopy = [...cardCopy.medias];
        const updatedMedias = mediasCopy.filter((media, idx) => idx !== index);
        cardCopy.medias = [...updatedMedias];
        cardsCopy[i] = { ...cardCopy };
      }
    }
    this.setState({ cards: cardsCopy });
  };

  handleDeleteCard = (nodeId) => {
    const { cards, graph } = this.state;
    console.log('Node ID ===== ', nodeId);
    let cardsCopy = [...cards];
    console.log('cardCopy>>>', cardsCopy);
    cardsCopy = cardsCopy.filter(card => card.id !== nodeId);
    for (let i = 0; i < cardsCopy.length; i += 1) {
      const cardCopy = { ...cardsCopy[i] };
      // filter the out point connection
      cardCopy.outs = cardCopy.outs.filter(out => out[1] !== nodeId.toString());
      cardsCopy[i] = { ...cardCopy };
    }
    this.setState({ cards: cardsCopy }, () => {
      const graphCopy = _.cloneDeep(graph);
      // delete the node and edges connected with any other nodes and edges
      graphCopy.nodes = graphCopy.nodes.filter(node => node.id !== nodeId);
      graphCopy.edges = graphCopy.edges.filter(
        edge => edge.from.toString() !== nodeId.toString()
          && edge.to.toString() !== nodeId.toString(),
      );
      this.setState({ graph: graphCopy });
    });
  };

  handleDeleteEdge = (
    notSelectedEdges,
    selectedCardEdge,
    selectedEdgeLabel,
  ) => {
    const { cards, graph } = this.state;
    const cardsCopy = [...cards];
    const graphCopy = { ...graph };
    // find index of selectedCard Edge
    const index = cardsCopy.findIndex(o => o.id === selectedCardEdge.id);
    // update outs array
    const updatedOuts = cardsCopy[index].outs.filter(
      out => !(out[0] === selectedEdgeLabel),
    );
    cardsCopy[index].outs = updatedOuts;
    // update the not selected edges
    graphCopy.edges = notSelectedEdges;
    this.setState({
      graph: graphCopy,
      cards: cardsCopy,
    });
  };

  openMediaModal = (value, nodeId) => {
    this.setState({
      showAddMediaModal: value,
      activeNode: nodeId.toString(),
    });
  };

  handleAddMediaModalClose = (staticMedia, nodeId) => {
    console.log('aagaya', staticMedia, nodeId);
    const { cards, activeNode } = this.state;
    this.setState({
      showAddMediaModal: false,
    });
    const cardsCopy = [...cards];
    for (let i = 0; i < cardsCopy.length; i += 1) {
      if (cardsCopy[i].id.toString() === activeNode.toString()) {
        const cardCopy = _.cloneDeep(cardsCopy[i]);
        cardCopy.medias.push(staticMedia);
        cardsCopy[i] = { ...cardCopy };
      }
    }
    this.setState({ cards: cardsCopy }, () => {
      console.log('cards>>>', cards);
    });
  };

  handleClear = () => {
    this.setState({
      showAddMediaModal: false,
    });
  };

  handleSaveIvrs = () => {
    const { cards, ivrsName, ivrsDesc } = this.state;
    const { saveIvrs } = this.props;
    let toastrMessage = '';
    let check = true;
    if (cards.length === 0) {
      toastrMessage = 'Please drag card';
      check = false;
    }
    if (ivrsDesc === '') {
      toastrMessage = 'Please enter description';
      check = false;
    }
    if (ivrsName === '') {
      toastrMessage = 'Please enter title';
      check = false;
    }
    if (check) {
      const data = {
        ivrsData: cards,
        name: ivrsName,
        description: ivrsDesc,
      };
      saveIvrs(data);
      this.setState({
        toastrMessage: '',
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage,
        toastrMessageType: 'error',
      });
    }
  };

  handleIvrsName = (e) => {
    this.setState({
      ivrsName: e.target.value,
    });
  };

  handleIvrsDesc = (e) => {
    this.setState({
      ivrsDesc: e.target.value,
    });
  };

  handelProceedClick = () => {
    const { ivrsName, ivrsDesc } = this.state;
    const { proceedClick } = this.props;
    const data = {
      ivrsName,
      ivrsDesc,
    };
    proceedClick(true, data, 'setup');
  };

  handleModalClose = () => {
    this.setState({ show: false });
  };

  handleSettingModalClose = () => {
    this.setState({ showSettings: false });
  };

  handleSettings = (value, card) => {
    this.setState({ showSettings: value, activeCard: card });
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false,
    });
  };

  handleCardName = (cardId, cardName) => {
    const { cards, graph } = this.state;
    const graphCopy = { ...graph };
    const cardsCopy = [...cards];
    for (let i = 0; i < cardsCopy.length; i += 1) {
      if (cardsCopy[i].id === cardId) {
        const cardCopy = _.cloneDeep(cardsCopy[i]);
        cardCopy.name = cardName;
        cardsCopy[i] = { ...cardCopy };
      }
    }
    this.setState({ cards: cardsCopy }, () => {
      for (let i = 0; i < graphCopy.nodes.length; i += 1) {
        if (graphCopy.nodes[i].id === cardId) {
          const nodeCopy = _.cloneDeep(graphCopy.nodes[i]);
          nodeCopy.label = cardName;
          graphCopy.nodes[i] = { ...nodeCopy };
        }
      }
      this.setState({
        graph: graphCopy,
      });
    });
  };

  handleAttachSms = (smsData, card) => {
    const { cards } = this.state;
    const cardsCopy = [...cards];
    for (let i = 0; i < cardsCopy.length; i += 1) {
      if (cardsCopy[i].id === card.id) {
        const cardCopy = { ...cardsCopy[i] };
        cardCopy.sms = { ...smsData };
        cardsCopy[i] = { ...cardCopy };
      }
    }
    this.setState({ cards: cardsCopy });
  };

  render() {
    const {
      graph,
      cards,
      activeCard,
      showModal,
      showAddMediaModal,
      activeNode,
      ivrsName,
      ivrsDesc,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      isDisabled,
      activeChip,
      show,
      showSettings,
      chipList,
      modifyChipList,
    } = this.state;
    console.log(chipList);
    const { proceed, handlePrevious } = this.props;
    return (
      <React.Fragment>
        {proceed === false ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={0} className="pramotionalIvrs" />
            <Grid container spacing={0} className="ivrsChips">
              <Grid item lg={12} xs={12}>
                <div className="ivrsHead">
                  {modifyChipList.length !== 0
                    && modifyChipList.map(chip => (
                      <div key={chip.id} className="inventoryHead">
                        <InventoryChip
                          key={chip.id}
                          inventoryName={chip.name}
                          index={chip.id}
                          onChipDrop={this.handleCardDrop}
                          chip={chip}
                        />
                      </div>
                    ))}
                </div>
                <div className="inventoryFoot">
                  <Grid container>
                    <Grid item lg={2} xs={2}>
                      <h1>
                        1-Drag & drop card from here .
                        {/* <Button className="add-btn">
                    <i className="material-icons">add_circle_outline</i>
                    {' '}
Create
                    New card
                  </Button> */}
                      </h1>
                    </Grid>
                    <Grid item lg={10} xs={10}>
                      <h1>
                        2-You can rename the card by double clicking click on
                        card name and pressing enter.
                      </h1>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="ivrsSection">
              <Grid container spacing={0} className="ivrsInput ivrsTextDetails">
                <Grid item lg={3} xs={12}>
                  <TextField
                    id="mobile"
                    label="IVRS Title"
                    className="ivrsTitle"
                    margin="normal"
                    value={ivrsName}
                    onChange={this.handleIvrsName}
                  />
                </Grid>
                <Grid item lg={9} xs={12}>
                  <TextField
                    id="mobile"
                    label="Add Description"
                    className="ivrsDesc"
                    margin="normal"
                    value={ivrsDesc}
                    onChange={this.handleIvrsDesc}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="ivrsCard">
                <h2>Drop Here</h2>
                <GraphCanvas
                  graph={graph}
                  options={options}
                  cards={cards}
                  onConnection={this.handleConnection}
                  minimiseNode={this.handleMinimise}
                  deleteCard={this.handleDeleteCard}
                  deleteMedia={this.handleDelete}
                  handleDeleteEdge={this.handleDeleteEdge}
                  onClickPlus={this.openMediaModal}
                  openSettings={this.handleSettings}
                  updateCardName={this.handleCardName}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <ChipInfoModal
                  show={show}
                  onCloseModal={this.handleModalClose}
                  activeChip={activeChip}
                />
                <CardSetting
                  show={showSettings}
                  onCloseModal={this.handleSettingModalClose}
                  onSmsAttach={this.handleAttachSms}
                  activeChip={activeChip}
                  card={activeCard}
                />
                <IvrsModal
                  showModal={showModal}
                  onClose={this.handleClose}
                  graph={graph}
                  activeCard={activeCard}
                  onAdd={this.handleAdd}
                />
                <AddAudio
                  showAddMediaModal={showAddMediaModal}
                  activeNode={activeNode}
                  handleClose={this.handleAddMediaModalClose}
                  onClear={this.handleClear}
                />
              </Grid>
              <Grid item lg={12} className="ivrsProceed">
                <div className="darkgreenBtnHeader alignRight">
                  <Button
                    className="darkgreenBtn"
                    onClick={this.handleSaveIvrs}
                  >
                    Create Ivrs
                  </Button>
                  <Button
                    className={`${!isDisabled ? 'enableBtn' : 'disableBtn'}`}
                    onClick={this.handelProceedClick}
                    disabled={isDisabled}
                  >
                    Select Farmers
                    <i className="material-icons">arrow_forward</i>
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Toastr
              message={toastrMessage}
              show={istoastrOpen}
              handleClose={this.handleToastrClose}
              messageType={toastrMessageType}
            />
          </Grid>
        ) : (
          <Schedule
            title={ivrsName}
            desc={ivrsDesc}
            selectedId=""
            handlePrevious={handlePrevious}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ ivrs }) => ({
      ivrsChips: ivrs.ivrsChips,
      media: ivrs.media,
      ivrsSaved: ivrs.ivrsSaved,
    }),
    dispatch => ({
      fetchChipsData: () => dispatch(getChipsApi()),
      saveIvrs: data => dispatch(createIvrs(data)),
      resetCreateIvrsDetails: () => dispatch(resetDetails()),
    }),
  )(withCookies(IvrsSetup)),
);
