import React from 'react';
import { Area } from 'recharts';
import { AreaChart } from '../common/chart';

const AreaChartComponent = (props) => {
  const {
    data, xAxisDataKey, color, dataKey, strokeColor,
  } = props;
  return (
    <AreaChart
      data={data}
      dataKey={xAxisDataKey}
      showToolTip
      showLegend={false}
      color={color}
    >
      <Area
        type="monotone"
        dataKey={dataKey}
        stroke={strokeColor}
        fill="url(#colorUv)"
        strokeWidth={2}
        fillOpacity={1}
      />
    </AreaChart>
  );
};

export default AreaChartComponent;
