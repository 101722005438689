/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper
} from '@material-ui/core';
import Datetime from 'react-datetime';
import moment from 'moment';
import TaggingFixedPanel from './common/taggingFixedPanel';
import ListModal from './listModal';
import UploadModal from './uploadModal';
import FarmerListModal from './farmerListModal';
import FgFilterModal from './fgFilterModal';
import UploadViewFormatModal from '../ivrs/uploadViewFormatModal';
import { objectToCsv, download } from '../../Functions/helper';
import {
  farmeList,
  getCreateCampaign,
  resetTaggingDetails,
  uploadFarmers,
  resetTaggingFarmerDetails,
  resetTaggedFarmerList,
  resetUploadFarmerDetails
} from '../../Actions/taggingAction';
import { resetLocationAct } from '../../Actions/locationHierarchyAction';
import Toastr from '../common/toastr';
import '../../css/common.scss';
import '../../css/form.scss';
import '../../css/table.scss';
import '../../css/tagging/main.scss';
import '../../css/tagging/selectFarmer.scss';

class SelectFarmer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTime: '',
      checked: false,
      mobileNo: '',
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      // selected: [],
      showModal: false,
      selectedFarmerList: [],
      error: '',
      showModalOpen: false,
      // selectedFarmerListCopy: [],
      showUploadModal: false,
      uploaded: false,
      uploadCheckbox: false,
      confirmUpload: false,
      uploadTaggedFarmerList: [],
      isFarmerTagged: false,
      isFgModalOpen: false,
      isFormatModalOpen: false,
      uploadError: ''
    };
  }

  componentDidMount() {
    // this.fetchFarmerData();
    const { resetLocationDetails, resetTaggedList } = this.props;
    resetLocationDetails();
    resetTaggedList();
  }

  componentDidUpdate(prevProps) {
    const { createCampaign } = this.props;
    if (!_.isEmpty(createCampaign) && _.isEmpty(prevProps.createCampaign)) {
      this.showMessageType(createCampaign);
    }
  }

  /*  fetchFarmerData = () => {
    const { getFarmerList, resetFarmerDetails } = this.props;
    const { page, locationObject } = this.state;
    const data = {
      orgId: '5b4b4621-d81c-4f11-9e5e-7c7003cde1b8',
      page: page + 1,
      limit: 10,
      filters: {
        state: locationObject.state || 'Haryana',
        district: locationObject.district || '',
        tehsil: locationObject.tehsil || '',
        village: locationObject.village || '',
        pincode: locationObject.pincode || '',
      },
    };
    resetFarmerDetails();
    getFarmerList(data);
  }; */

  showMessageType = createCampaign => {
    const { anotherTab } = this.props;
    if (createCampaign.status) {
      this.setState(
        {
          istoastrOpen: true,
          toastrMessage: 'Campaign Created Successfully',
          toastrMessageType: 'success'
        },
        () => {
          setTimeout(() => {
            anotherTab();
          }, 1000);
        }
      );
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Campaign Not Created',
        toastrMessageType: 'error'
      });
    }
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false
    });
  };

  handleChange = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
  };

  handleDateTime = momt => {
    this.setState({
      dateTime: moment(momt._d)
        .format()
        .slice(0, 16)
    });
  };

  valid = current => {
    const yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  handleMobileChange = e => {
    const mobileRegex = /^[6-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(mobileRegex) && e.target.value !== '') {
      return;
    }
    this.setState({
      mobileNo: e.target.value
    });
  };

  /*  isSelected = (id) => {
    const { selected } = this.state;
    return selected.indexOf(id) !== -1;
  };

  handleCheck = (farmerId) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(farmerId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, farmerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState(
      {
        selected: newSelected,
      },
      () => {
        console.log('>>>>>>', selected);
      },
    );
  }; */

  handleSchedule = type => {
    const {
      dateTime,
      mobileNo,
      // selected,
      checked,
      // selectedFarmerList,
      uploadTaggedFarmerList
    } = this.state;
    const {
      scheduleCampaign,
      resetDetails,
      dataPoints1,
      textDesc,
      // farmersList,
      posterTitle,
      selectedPoster,
      taggedListFarmer,
      cookies,
      uploadPosterUrl,
      posterDescription
    } = this.props;
    const uploadFilterList = uploadTaggedFarmerList.map(obj => {
      return {
        name: obj.name,
        farmerID: obj.farmerID,
        mobile: obj.mobile,
        sms: true,
        ivrs: false
      };
    });
    console.log('uploaded', uploadFilterList);
    const userData = cookies.get('userData');
    // const { farmers } = farmersList;
    const dataPointCopy = [...dataPoints1];
    const filterDataPoint = dataPointCopy.filter(item => delete item.isChecked);
    const { farmerList } = taggedListFarmer;
    let newilterFarmerList = [];
    let filterFarmerList = [];
    let farmersToBeSchedule = [];
    // change key farmerName to name
    if (!_.isEmpty(taggedListFarmer)) {
      newilterFarmerList =
        farmerList &&
        farmerList.length &&
        farmerList.map(({ farmerName: name, ...rest }) => ({ name, ...rest }));
    }
    if (newilterFarmerList.length > 0) {
      // adding extra parameters
      filterFarmerList = newilterFarmerList.map(item => ({
        ...item,
        sms: true,
        ivrs: false
      }));
    }
    farmersToBeSchedule = [...filterFarmerList, ...uploadFilterList];
    console.log('farmersToBeSchedule', farmersToBeSchedule);
    // adding extra parameters
    // const filterTaggedList = uploadTaggedFarmerList.filter(item => delete item.count);
    //  const farmerList = filterTaggedList.map(item => ({
    //    ...item,
    //    sms: true,
    //    ivrs: false
    //  }));
    // const selectedFarmerListCopy = farmers.filter(item => selected.includes(item.orgFarmerID));
    //  const farmerList = selectedFarmerListCopy.map(item => ({
    //    ...item,
    //    sms: true,
    //    ivrs: false,
    //  }));
    let posterId = '';
    if (selectedPoster && typeof selectedPoster === 'object') {
      posterId = selectedPoster.posterImageID;
    } else {
      const { posterImageID } = uploadPosterUrl;
      posterId = posterImageID;
    }
    let error = '';
    let check = true;
    const data = {};
    data.weblink_url = '';
    data.campaign_title = posterTitle;
    // data.poster_image_id = 'f0579d59-da2b-4b37-9ba6-6775aecbe044';
    data.poster_image_id = posterId || '49ba9907-bd39-481b-9da6-a59d8bb4c9d2';
    data.sms_text_description = textDesc;
    data.campaignDescription = posterDescription || 'Testing Campaign';
    data.insight_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.org_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.data_collection_type = 1;
    data.farmer_type = 1;
    data.start_time = dateTime;
    data.type = type === 'schedule' ? 1 : 2;
    data.data_points = filterDataPoint;
    data.farmers =
      type === 'schedule'
        ? filterFarmerList
        : [
            {
              //farmerID: '1c5517cb-4ea1-4e48-97b1-32a58ad8f0ee',
              farmerID: '06e38693-9a80-4cf5-b4bf-c9873ffedddd',
              mobile: mobileNo,
              name: 'test name',
              sms: true,
              ivrs: false
            }
          ];

    if (!mobileNo && type !== 'schedule') {
      error = 'Please enter mobile no.';
      check = false;
    }
    // if (
    //   filterFarmerList &&
    //   filterFarmerList.length === 0 &&
    //   type === 'schedule'
    // ) {
    //   error = ' Please select the farmers to send sms';
    //   check = false;
    // }
    if (
      farmersToBeSchedule &&
      farmersToBeSchedule.length === 0 &&
      type === 'schedule'
    ) {
      error = ' Please select the farmers to send sms';
      check = false;
    }
    // if (uploadTaggedFarmerList.length === 0 && type === 'schedule') {
    //   error = 'Please Add Farmer to the List';
    //   check = false;
    // }
    // if (selected.length === 0 && type === 'schedule') {
    //   error = 'Please select the farmer';
    //   check = false;
    // }
    if (!checked && type === 'schedule') {
      error = 'Please Acknowledge';
      check = false;
    }
    if (!dateTime) {
      error = 'Please select date and time';
      check = false;
    }
    if (check) {
      if (userData) {
        scheduleCampaign(data, userData.token);
      }
      resetDetails();
      this.setState({
        error: ''
      });
    } else {
      this.setState({
        error
      });
    }
  };

  handleModal = () => {
    this.setState({
      showModal: true
    });
  };

  /* handleModalOpen = () => {
    this.setState({
      showModalOpen: true,
    });
  }; */

  /*  handleUpdateFarmerList = (data) => {
    if (data.length > 0) {
      this.setState({
        selectedFarmerListCopy: data,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
      });
    }
  }; */

  handleModalClose = () => {
    this.setState({
      showModalOpen: false
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  handleUploadCheckbox = () => {
    const { uploadCheckbox } = this.state;
    this.setState({
      uploadCheckbox: !uploadCheckbox
    });
  };

  /* addToList = () => {
    const { selected, selectedFarmerList } = this.state;
    const { farmersList } = this.props;
    const { farmers } = farmersList;
    const newFarmerList = farmers.filter(item => selected.includes(item.orgFarmerID));
    const newList = _.unionBy(
      selectedFarmerList,
      newFarmerList,
      item => item.orgFarmerID,
    );
    const updatedList = newList.filter(item => selected.includes(item.orgFarmerID));
    if (newFarmerList && newFarmerList.length > 0) {
      this.setState({
        selectedFarmerList: updatedList,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
      });
    }
  }; */

  handleFileSelected = e => {
    this.setState({
      selectedFile: e.target.files[0]
    });
  };

  fileUploadHandler = () => {
    const { selectedFile, uploadCheckbox } = this.state;
    const { uploadFile, cookies } = this.props;
    const userData = cookies.get('userData');
    let check = true;
    let uploadError = '';
    if (!uploadCheckbox) {
      check = false;
      uploadError = 'Please Acknowledge';
    }
    if (selectedFile === null) {
      check = false;
      uploadError = 'Please select the file';
    }
    if (check) {
      if (selectedFile && uploadCheckbox) {
        const formData = new FormData();
        formData.append('farmer', selectedFile);
        if (userData) {
          uploadFile(formData, userData.token);
        }
        // uploadFile(formData);
        this.setState({
          uploaded: true,
          uploadError: ''
        });
      }
    } else {
      this.setState({
        uploadError
      });
    }
  };

  uploadAgain = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      uploaded: false,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  uploadModal = () => {
    this.setState({
      showUploadModal: true
    });
  };

  handleCloseUploadModal = () => {
    this.setState({
      showUploadModal: false,
      uploadCheckbox: false
    });
  };

  handleConfirmUpload = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      confirmUpload: true,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  handleViewTaggedList = () => {
    this.setState({
      showModalOpen: true
    });
  };

  handleUpdateTaggedList = list => {
    if (list.length > 0) {
      this.setState({
        uploadTaggedFarmerList: list,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
        isFarmerTagged: true
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
        isFarmerTagged: false
      });
    }
  };

  handleOpenFgModal = () => {
    this.setState({
      isFgModalOpen: true
    });
  };

  handleViewFormatModal = () => {
    this.setState({
      isFormatModalOpen: true
    });
  };

  handleViewFormatClose = () => {
    this.setState({
      isFormatModalOpen: false
    });
  };

  handleCloseFgModal = () => {
    this.setState({
      isFgModalOpen: false
    });
  };

  downloadFile = () => {
    const { taggedListFarmer } = this.props;
    const { farmerList } = taggedListFarmer;
    const data =
      farmerList &&
      farmerList.map(item => ({
        name: item.name,
        mobile: item.mobile
      }));
    const csvData = objectToCsv(data);
    download(csvData);
  };

  render() {
    const {
      checked,
      mobileNo,
      // rowsPerPage,
      // page,
      dateTime,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      showModal,
      selectedFarmerList,
      error,
      showModalOpen,
      // selectedFarmerListCopy,
      showUploadModal,
      uploaded,
      uploadCheckbox,
      confirmUpload,
      isFarmerTagged,
      selectedFile,
      uploadTaggedFarmerList,
      isFgModalOpen,
      uploadError,
      isFormatModalOpen
    } = this.state;
    // console.log('selectedFarmerListCopy', selectedFarmerListCopy);
    const {
      // farmersList,
      selectedPoster,
      handlePrevious,
      //   broadcastLocationChange,
      posterTitle,
      farmersUploaded,
      calledFrom,
      taggedListFarmer,
      farmersUploadError,
      posterDescription,
      textDesc
      // farmerListError,
    } = this.props;
    const { totalCount, farmerList } = taggedListFarmer;
    let count = 0;
    if (totalCount) {
      count = count + parseInt(totalCount);
    }
    if (uploadTaggedFarmerList.length && uploadTaggedFarmerList.length > 0) {
      count = count + uploadTaggedFarmerList.length;
    }
    const splitDate = dateTime.split('T');
    return (
      <div className="root">
        <Grid container className="taggingMain" spacing={2}>
          <Grid item lg={9} sm={9} xs={12} className="insightLeftPanel">
            <h5>Tagging</h5>
            <ul className="breadCrumb">
              <li className="backLink">
                <Button
                  variant="contained"
                  style={{
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                  }}
                  onClick={() => handlePrevious()}
                >
                  <i className="material-icons">keyboard_backspace</i>
                  Back
                </Button>
                <i className="material-icons line">remove</i>
              </li>
            </ul>
            <div className="farmerSelectionPanel">
              {calledFrom ? (
                <div className="selectedPoster">
                  <i>15</i>
                  <div className="tagInfo">
                    <p>
                      Send pesonalize posters to your farmers to generate leads.
                      <span>
                        Choose from a stunning collection of poster templates
                        guaranteed to turn heads. Free to customize to suit
                        every event or occasion.
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
              <Grid container spacing={2} className="addFarmerWithOutput">
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  className="selectAddFarmers darkgreenBtnHeader"
                >
                  <h4>Select Farmers</h4>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2} sm={2} xs={12}>
                        <i
                          className={`${
                            totalCount !== 0 && totalCount !== undefined
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {totalCount !== 0 && totalCount !== undefined
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6} sm={6} xs={12}>
                        <h6>Add Farmers</h6>
                        {/* <p>Upload your farmer’s database</p> */}
                        <p>
                          Total farmers :<span> {totalCount || 0} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4} sm={4} xs={12}>
                        {/* <Button
                          className="upload-btn textCenter"
                          onClick={this.downloadFile}
                          //disabled
                        >
                          View List
                        </Button>*/}
                        <Button
                          className={
                            farmerList && farmerList.length > 0
                              ? 'upload-btn-enable textCenter'
                              : 'upload-btn textCenter'
                          }
                          onClick={this.downloadFile}
                          disabled={farmerList === undefined}
                        >
                          Download
                        </Button>
                      </Grid>
                      <Grid item lg={2} sm={2} />
                      <Grid
                        item
                        lg={10}
                        sm={10}
                        md={10}
                        xs={12}
                        className="btn-panel"
                      >
                        <Button
                          className="upload-enable textCenter"
                          onClick={this.handleOpenFgModal}
                          //disabled
                        >
                          FG Database
                        </Button>
                        <Button
                          className="upload-btn-disable textCenter"
                          disabled
                        >
                          My Resources
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2} sm={2} md={2}>
                        <i
                          className={`${
                            uploadTaggedFarmerList &&
                            uploadTaggedFarmerList.length > 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {uploadTaggedFarmerList &&
                          uploadTaggedFarmerList.length > 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={7} sm={7} md={7}>
                        <h6>Upload your farmers</h6>
                        {/* <p>Upload your farmer’s database</p> */}
                        {/*<p>Upload your farmer’s database</p>*/}
                        <p>
                          Total farmers :
                          <span>{uploadTaggedFarmerList.length}</span>
                        </p>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3}>
                        <Button
                          className={`${
                            !confirmUpload ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!confirmUpload}
                          onClick={this.handleModal}
                        >
                          View List
                        </Button>
                        {/*<Button
                      <Grid item lg={3}>
                        <Button
                          className={`${
                            !confirmUpload ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!confirmUpload}
                          onClick={this.handleModal}
                        >
                          View List
                        </Button>
                        {/* <Button
                          className={`${
                            !confirmUpload
                              ? 'upload-btn-enable'
                              : 'upload-btn-enable'
                          } textCenter`}
                          // disabled={!confirmUpload}
                          onClick={this.handleModal}
                        >
                          Download
                        </Button>*/}
                      </Grid>
                      <Grid item lg={2} sm={2} md={2} />
                      <Grid item lg={3} sm={3} md={3} className="btn-panel">
                        <Button
                          className="upload-btn-disable textCenter"
                          onClick={this.uploadModal}
                          disabled
                        >
                          Upload
                        </Button>
                      </Grid>
                      <Grid item lg={7} sm={7} md={7}>
                        <h5 className="upload-view">
                          <Button
                            onClick={this.handleViewFormatModal}
                            style={{ pointerEvents: 'none', cursor: 'default' }}
                          >
                            View Format
                          </Button>
                        </h5>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12}>
                        <div className="upload-box-footer">
                          {!confirmUpload ? (
                            <p className="footer-content">No file uploaded</p>
                          ) : farmersUploadError === '' ? (
                            <p className="footer-content">
                              Uploaded file: <span>{selectedFile.name}</span>
                              <i className="material-icons">clear</i>
                            </p>
                          ) : (
                            <p className="footer-content">No file uploaded</p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2} md={2} sm={2}>
                        <i
                          className={`${
                            count !== 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {count !== 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6}>
                        <h6>To Be Tagged Farmers</h6>
                        <p>
                          Total farmers :<span> {count} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4}>
                        <Button
                          className={`${
                            !isFarmerTagged ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!isFarmerTagged}
                          onClick={this.handleViewTaggedList}
                        >
                          View List
                        </Button>
                        {/* <Button
=======
                      <Grid item lg={4}>
                        <Button
>>>>>>> dd2edec6a9fb0c45af7280e232791ae1c42bc83e
                          className={`${
                            !isFarmerTagged ? 'upload-btn' : 'upload-btn-enable'
                          } textCenter`}
                          disabled={!isFarmerTagged}
                          onClick={this.handleViewTaggedList}
                        >
                          View List
                        </Button>
                        {/*  <Button
                          className={`${
                            !isFarmerTagged
                              ? 'upload-btn-enable'
                              : 'upload-btn-enable'
                          } textCenter`}
                          // disabled={!isFarmerTagged}
                          onClick={this.handleViewTaggedList}
                        >
                          Download
                        </Button>*/}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg={6} sm={6} md={6} xs={12} className="finalOutput">
                  <h4 className="blackBorder">{posterTitle}</h4>
                  <div className="finalOutputImg blackBorder">
                    <div className="small-img">
                      <img
                        src={
                          selectedPoster
                            ? typeof selectedPoster === 'object'
                              ? selectedPoster.mediaLocation
                              : selectedPoster
                            : 'images/defaultPoster.png'
                        }
                        alt="default poster"
                        className="img-responsive"
                      />
                    </div>
                    {/* {selectedPoster ? (
                        <img
                          src={selectedPoster}
                          alt="poster"
                          className="img-responsive"
                        />
                      ) : null} */}
                    <div className="finalOutputDetail">
                      <h5>SMS Text Description: {textDesc}</h5>
                      <p>
                        <span>Campaign Details:</span>
                        {posterDescription}
                      </p>
                    </div>
                  </div>
                  <div className="scheduleTime blackBorder">
                    <h4>Schedule SMS time</h4>
                    <div className="dateTimePicker">
                      <i className="material-icons calender-icon">date_range</i>
                      <Datetime
                        isValidDate={this.valid}
                        onChange={this.handleDateTime}
                        inputProps={{ placeholder: 'Select date and time' }}
                      />
                    </div>
                    <ul className="dateTimeValue">
                      <li>
                        Date:
                        <span className="nobileGreyTxt">{splitDate[0]}</span>
                      </li>
                      <li>
                        Time:
                        <span className="nobileGreyTxt">{splitDate[1]}</span>
                      </li>
                    </ul>
                  </div>
                  {/*    <div className="farmerCount blackBorder">
                    <h6>
                      Total Scheduled Farmers:
                      {' '}
                      <span>2,400</span>
                    </h6>
                    <p>
                      Total Credits:
                      {' '}
                      <span>1500/-</span>
                    </p>
                  </div> */}
                  <div className="scheduleSms darkgreenBtnHeader">
                    <div className="enterNumber">
                      <TextField
                        id="mobilenum"
                        placeholder="Enter Mobile Number"
                        className="textField inputHead customText-width"
                        onChange={this.handleMobileChange}
                        value={mobileNo}
                        inputProps={{ maxLength: 10 }}
                      />
                      <Button
                        className="darkgreenBtn"
                        onClick={() => this.handleSchedule('test')}
                      >
                        Test SMS
                      </Button>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={this.handleChange}
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="I hereby acknowledge that this message doesn’t carry any unwanted or abusive words "
                      className="acknowledgeCheckbox"
                    />
                    <Paper className="scheduleSmsBtn">
                      <Button
                        className="darkgreenBtn"
                        onClick={() => this.handleSchedule('schedule')}
                      >
                        Schedule SMS
                      </Button>
                    </Paper>
                    {error ? <p className="errorMsg1"> {error} </p> : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <TaggingFixedPanel />
          {showModal && (
            <ListModal
              show={showModal}
              onClose={this.handleCloseModal}
              farmerList={selectedFarmerList}
              updateTaggedList={this.handleUpdateTaggedList}
            />
          )}
          <UploadModal
            show={showUploadModal}
            onClose={this.handleCloseUploadModal}
            handleChange={this.handleFileSelected}
            uploadHandler={this.fileUploadHandler}
            uploaded={uploaded}
            uploadAgain={this.uploadAgain}
            farmersUploaded={farmersUploaded}
            uploadCheckbox={uploadCheckbox}
            handleUploadCheckbox={this.handleUploadCheckbox}
            confirmUpload={this.handleConfirmUpload}
            uploadError={uploadError}
            farmersUploadError={farmersUploadError}
          />
          {isFgModalOpen && (
            <FgFilterModal
              show={isFgModalOpen}
              onClose={this.handleCloseFgModal}
            />
          )}
          <FarmerListModal
            show={showModalOpen}
            onClose={this.handleModalClose}
            taggedList={uploadTaggedFarmerList}
          />
          <UploadViewFormatModal
            showModal={isFormatModalOpen}
            onClose={this.handleViewFormatClose}
          />
          <Toastr
            message={toastrMessage}
            show={istoastrOpen}
            handleClose={this.handleToastrClose}
            messageType={toastrMessageType}
          />
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging, location }) => ({
      createCampaign: tagging.createCampaign,
      farmersList: tagging.farmersList,
      farmersUploaded: tagging.farmersUploaded,
      farmerListError: tagging.farmerListError,
      taggedListFarmer: tagging.taggedListFarmer,
      farmersUploadError: tagging.farmersUploadError,
      uploadPosterUrl: tagging.uploadPosterUrl,
      location
    }),
    dispatch => ({
      scheduleCampaign: (data, token) =>
        dispatch(getCreateCampaign(data, token)),
      resetDetails: () => dispatch(resetTaggingDetails()),
      getFarmerList: data => dispatch(farmeList(data)),
      uploadFile: (data, token) => dispatch(uploadFarmers(data, token)),
      resetFarmerDetails: () => dispatch(resetTaggingFarmerDetails()),
      resetLocationDetails: () => dispatch(resetLocationAct()),
      resetTaggedList: () => dispatch(resetTaggedFarmerList()),
      resetUploadDetails: () => dispatch(resetUploadFarmerDetails())
    })
  )(withCookies(SelectFarmer))
);
