import React from 'react';
import {
  compose, lifecycle, withProps, withState,
} from 'recompose';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { connect } from 'react-redux';
import wkx from 'wkx';
import _ from 'lodash';
// import FarmEngineMap from '../farmEngine/farmEngineMap';
import { config } from '../../../config/index';
import {
  updateBoundsAction,
  fetchIndicesImagesAct,
  clearIndicesImagesAct,
  fetchCompareIndicesImagesAct,
  clearCompareIndicesImagesAct,
  updateBoundsComparisionAction,
} from '../../../Actions/agricultureIndicesAction';
import { addVectorLayer } from '../../../Functions/helper';
import '../../../css/rightPanel.scss';
import '../../../css/common.scss';

let Map;
let ReactMapboxGl;
// let Compare;
// let mapboxgl;
let level1Hover;
let level0Hover;
let level2Hover;
let level3Hover;
let hoversegment;
let popup;
const MyMap = compose(
  withState('showMap', 'setMap', false),
  withProps({}),
  lifecycle({
    componentDidMount() {
      /* eslint-disable global-require */
      ReactMapboxGl = require('react-mapbox-gl');
      /* eslint-enable global-require */
      Map = ReactMapboxGl.Map({
        accessToken: config.mapboxConfig.publicToken,
        hash: true,
        minZoom: 1.5,
        maxZoom: 18,
        preserveDrawingBuffer: true,
      });
      this.setState({
        showMap: true,
      });
    },
  }),
)(props => (props.showMap ? (
  <Map
    attributionControl={false}
    className="mapController"
    container="map"
    logoPosition="bottom-left"
    movingMethod="flyTo"
    onStyleLoad={props._onMapLoad}
    onClick={props._onMapClick}
    onDragEnd={props._onDragEnd}
    onZoomEnd={props._onZoomEnd}
    style={config.mapboxConfig.blankStyle}
    center={props.center}
    zoom={props.zoom}
  />
) : (
  <div>Loading Map</div>
)));

class IndicesCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const {
      // bounds,
      // compareBounds,
      // indicesImages,
      // compareIndicesImages,
      selectedTimelineDate,
      indexToView,
      selectedCompareTimelineDate,
      compareIndexToView,
      indicesTimeline,
      satellitesSource,
    } = this.props;
    // const map1 = this.before;
    // const map2 = this.after;

    if (
      prevProps.selectedCompareTimelineDate !== selectedCompareTimelineDate
      && selectedCompareTimelineDate !== ''
    ) {
      // The Date Timeline is updated in Dashboard
      // console.log('Date Changed');
      const map = this.after;
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
        map.addLayer(
          {
            id: 'wms',
            type: 'raster',
            // minzoom: 6,
            source: {
              type: 'raster',
              scheme: 'xyz',
              tiles: [
                `${
                  config.tileServerAPI
                }/{z}/{x}/{y}?index=${compareIndexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedCompareTimelineDate}`,
              ],
              // bounds: bounds || [],
            },
            paint: {},
          },
          'level2Fill',
        );
      }
    }

    if (
      prevProps.selectedTimelineDate !== selectedTimelineDate
      && selectedTimelineDate !== ''
    ) {
      // Date Changed Set the Raster Layer Here
      const map = this.before;
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
        map.addLayer(
          {
            id: 'wms',
            type: 'raster',
            // minzoom: 6,
            source: {
              type: 'raster',
              scheme: 'xyz',
              tiles: [
                `${
                  config.tileServerAPI
                }/{z}/{x}/{y}?index=${indexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedTimelineDate}`,
              ],
              // bounds: bounds || [],
            },
            paint: {},
          },
          'level2Fill',
        );
      }
    }

    if (!_.isEmpty(prevProps.indicesTimeline) && _.isEmpty(indicesTimeline)) {
      // console.log('Empty Data Remove Layer Here');
      const map = this.before;
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
      }
    }

    if (
      prevProps.indexToView !== indexToView
      && prevProps.indexToView !== ''
      && indexToView !== ''
    ) {
      // Index To View is Changed from the DropDown in Dashboard
      const map = this.before;
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
        map.addLayer(
          {
            id: 'wms',
            type: 'raster',
            // minzoom: 6,
            source: {
              type: 'raster',
              scheme: 'xyz',
              tiles: [
                `${
                  config.tileServerAPI
                }/{z}/{x}/{y}?index=${indexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedTimelineDate}`,
              ],
              // bounds: bounds || [],
            },
            paint: {},
          },
          'level2Fill',
        );
      }
    }

    if (
      !_.isEqual(prevProps.compareIndexToView, compareIndexToView)
      && prevProps.compareIndexToView !== ''
      && compareIndexToView !== ''
    ) {
      const map = this.after;
      if (map) {
        if (map.getLayer('wms')) {
          map.removeLayer('wms');
          map.removeSource('wms');
        }
        map.addLayer(
          {
            id: 'wms',
            type: 'raster',
            // minzoom: 6,
            source: {
              type: 'raster',
              scheme: 'xyz',
              tiles: [
                `${
                  config.tileServerAPI
                }/{z}/{x}/{y}?index=${compareIndexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedCompareTimelineDate}`,
              ],
              // bounds: bounds || [],
            },
            paint: {},
          },
          'level2Fill',
        );
      }
    }
  }

  _onMapLoad1 = (map) => {
    this.before = map;
    map.addLayer({
      id: 'wms-test-layer',
      type: 'raster',
      source: {
        type: 'raster',
        scheme: 'xyz',
        // tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
        tiles: [
          'https://mt0.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt1.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt2.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt3.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
        ],
        // tiles: ['http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
        // tiles: ['http://imagico.de/map/osmim_tiles.php?z={z}&x={x}&y=-{y}'],
        // tiles: ['http://b.tiles.mapbox.com/v3/openstreetmap.map-4wvf9l0l/{z}/{x}/{y}.png'],
      },
      paint: {
        'raster-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12,
          0,
          14,
          0.5,
          16,
          1,
          22,
          1,
        ],
        'raster-fade-duration': 300,
        'raster-saturation': -0.75,
        'raster-contrast': 0,
        'raster-brightness-min': 0,
        'raster-brightness-max': 1,
      },
    });

    this.addLevel0(map, 0);

    this.addLevel1(map, 0);

    this.addLevel2(map, 0);

    this.addLevel3(map, 0);

    this.addSegments(map, false, 0);

    const {
      selectedTimelineDate,
      // bounds,
      indexToView,
      satellitesSource,
    } = this.props;
    if (selectedTimelineDate !== '') {
      if (map.getLayer('wms')) {
        map.removeLayer('wms');
        map.removeSource('wms');
      }
      map.addLayer(
        {
          id: 'wms',
          type: 'raster',
          // minzoom: 6,
          source: {
            type: 'raster',
            scheme: 'xyz',
            tiles: [
              `${
                config.tileServerAPI
              }/{z}/{x}/{y}?index=${indexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedTimelineDate}`,
            ],
            // bounds: bounds || [],
          },
          paint: {},
        },
        'level2Fill',
      );
    }
  };

  _onMapLoad2 = (map) => {
    this.after = map;
    map.addLayer({
      id: 'wms-test-layer',
      type: 'raster',
      source: {
        type: 'raster',
        scheme: 'xyz',
        // tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
        tiles: [
          'https://mt0.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt1.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt2.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt3.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
        ],
        // tiles: ['http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
        // tiles: ['http://imagico.de/map/osmim_tiles.php?z={z}&x={x}&y=-{y}'],
        // tiles: ['http://b.tiles.mapbox.com/v3/openstreetmap.map-4wvf9l0l/{z}/{x}/{y}.png'],
      },
      paint: {
        'raster-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12,
          0,
          14,
          0.5,
          16,
          1,
          22,
          1,
        ],
        'raster-fade-duration': 300,
        'raster-saturation': -0.75,
        'raster-contrast': 0,
        'raster-brightness-min': 0,
        'raster-brightness-max': 1,
      },
    });
    // console.log('Before', this.before);
    // console.log('After', this.after);
    /* /!* eslint-disable global-require *!/
    Compare = ''; /!* require('mapbox-gl-compare') *!/
    mapboxgl = require('mapbox-gl');
    /!* eslint-enable global-require *!/
    mapboxgl.Compare = Compare;
    const comp = new mapboxgl.Compare(this.before, this.after, {
      // mousemove: true,
    });

    console.log('Current Position ', comp.currentPosition);

    comp.on('slideend', (e) => {
      console.log('Slide End ', e.currentPosition);
    });
    const self = this;
    setTimeout(() => {
      self.before.setStyle(config.mapboxConfig.satelliteStyle);
    }, 10000);
    */

    this.addLevel0(map, 1);

    this.addLevel1(map, 1);

    this.addLevel2(map, 1);

    this.addLevel3(map, 1);

    this.addSegments(map, false, 1);

    const {
      selectedFarm,
      selectedCompareTimelineDate,
      // bounds,
      compareIndexToView,
      satellitesSource,
    } = this.props;
    if (selectedCompareTimelineDate !== '') {
      if (map.getLayer('wms')) {
        map.removeLayer('wms');
        map.removeSource('wms');
      }
      map.addLayer(
        {
          id: 'wms',
          type: 'raster',
          // minzoom: 6,
          source: {
            type: 'raster',
            scheme: 'xyz',
            tiles: [
              `${
                config.tileServerAPI
              }/{z}/{x}/{y}?index=${compareIndexToView}&tilesize=256&scale=2&satellite=${satellitesSource.toLowerCase()}&date=${selectedCompareTimelineDate}`,
            ],
            // bounds: bounds || [],
          },
          paint: {},
        },
        'level2Fill',
      );
    }
    setTimeout(() => {
      const { before } = this;
      if (before) {
        const features = before.queryRenderedFeatures({
          layers: ['ganganagarsegments'],
        });
        console.log('Featuress ', features);

        const afterFeatures = map.queryRenderedFeatures({
          layers: ['ganganagarsegments'],
        });

        console.log('After Featuress ', afterFeatures);

        features.forEach((item) => {
          if (item.properties.farmid === selectedFarm) {
            console.log('ITEM', item);
            const featureGeoJSON = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: item.geometry,
                  properties: item.properties,
                },
              ],
            };

            if (before.getLayer('clickedFarm')) {
              before.getSource('clickedFarm').setData(featureGeoJSON);
            } else {
              before.addSource('clickedFarm', {
                type: 'geojson',
                data: featureGeoJSON,
              });

              before.addLayer({
                id: 'clickedFarm',
                type: 'fill',
                source: 'clickedFarm',
                layout: {
                  visibility: 'visible',
                },
                paint: {
                  'fill-opacity': 1,
                  'fill-color': 'rgba(0,0,0,0)',
                },
              });

              before.addLayer({
                id: 'clickedFarmLine',
                type: 'line',
                source: 'clickedFarm',
                layout: {
                  visibility: 'visible',
                },
                paint: {
                  'line-color': '#fff000',
                  'line-width': 4,
                },
              });
            }
          }
        });

        afterFeatures.forEach((item) => {
          if (item.properties.farmid === selectedFarm) {
            console.log('ITEM 2 ?>>>', item);

            const featureGeoJSON = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: item.geometry,
                  properties: item.properties,
                },
              ],
            };

            if (map.getLayer('clickedFarm')) {
              map.getSource('clickedFarm').setData(featureGeoJSON);
            } else {
              map.addSource('clickedFarm', {
                type: 'geojson',
                data: featureGeoJSON,
              });

              map.addLayer({
                id: 'clickedFarm',
                type: 'fill',
                source: 'clickedFarm',
                layout: {
                  visibility: 'visible',
                },
                paint: {
                  'fill-opacity': 1,
                  'fill-color': 'rgba(0,0,0,0)',
                },
              });

              map.addLayer({
                id: 'clickedFarmLine',
                type: 'line',
                source: 'clickedFarm',
                layout: {
                  visibility: 'visible',
                },
                paint: {
                  'line-color': '#fff000',
                  'line-width': 4,
                },
              });
            }
          }
        });
      }
    }, 5000);
  };

  addLevel0 = (map, mapIndex) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.country.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
      sourceId: 'level0',
      fillLayerId: 'level0Fill',
      lineLayerId: 'level0Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.country.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.level0MouseMoveHandler
          : this.level0MouseMoveHandler2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.level0MouseLeaveHandler
          : this.level0MouseLeaveHandler2,
      fillclickFunc:
        mapIndex === 0 ? this.level0ClickHandler : this.level0ClickHandler2,
      lineZoomExtent: config.mapboxConfig.adminLayers.country.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel1 = (map, mapIndex) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.state.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
      sourceId: 'level1',
      fillLayerId: 'level1Fill',
      lineLayerId: 'level1Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.state.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.level1MouseMoveHandler
          : this.level1MouseMoveHandler2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.level1MouseLeaveHandler
          : this.level1MouseLeaveHandler2,
      fillclickFunc:
        mapIndex === 0 ? this.level1ClickHandler : this.level1ClickHandler2,
      lineZoomExtent: config.mapboxConfig.adminLayers.state.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel2 = (map, mapIndex) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.district.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
      sourceId: 'level2',
      fillLayerId: 'level2Fill',
      lineLayerId: 'level2Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.district.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.level2MouseMoveHandler
          : this.level2MouseMoveHandler2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.level2MouseLeaveHandler
          : this.level2MouseLeaveHandler2,
      fillclickFunc:
        mapIndex === 0 ? this.level2ClickHandler : this.level2ClickHandler2,
      lineZoomExtent: config.mapboxConfig.adminLayers.district.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel3 = (map, mapIndex) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.tehsil.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
      sourceId: 'level3',
      fillLayerId: 'level3Fill',
      lineLayerId: 'level3Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.tehsil.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.level3MouseMoveHandler
          : this.level3MouseMoveHandler2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.level3MouseLeaveHandler
          : this.level3MouseLeaveHandler2,
      fillclickFunc:
        mapIndex === 0 ? this.level3ClickHandler : this.level3ClickHandler2,
      lineZoomExtent: config.mapboxConfig.adminLayers.tehsil.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addSegments = (map, cropMapView, mapIndex) => {
    let { isCropMapShown } = this.state;
    if (cropMapView) {
      isCropMapShown = cropMapView;
    }
    const { selectedSeason, selectedYear, setSegmentProperties } = this.props;

    // Ganganagar Segments
    let fillColor;
    if (isCropMapShown) {
      fillColor = [
        'match',
        ['get', 'MAJORITY'],
        [1],
        config.crops['1'].cropColor,
        [2],
        config.crops['2'].cropColor,
        [0],
        config.crops['0'].cropColor,
        [3],
        config.crops['3'].cropColor,
        [4],
        config.crops['4'].cropColor,
        '#000000',
      ];
    } else {
      fillColor = 'rgba(0,0,0,0)'; /* [
       'case',
       ['boolean', ['feature-state', 'hover'], false],
       [
       'match',
       ['get', 'MAJORITY'],
       [1],
       config.crops['1'].cropColor,
       [2],
       config.crops['2'].cropColor,
       [0],
       config.crops['0'].cropColor,
       'rgba(0,0,0,0)',
       ],
       'rgba(0,0,0,0)',
       ]; */
    }
    const ganganagarSegmentParams = {
      mapRef: map,
      tileSetURL: 'mapbox://farmguide.ganganagarsegments2',
      withLineLayer: true,
      sourceLayer: 'ganganagarsegments2',
      sourceId: 'ganganagarsegments',
      fillLayerId: 'ganganagarsegments',
      lineLayerId: 'ganganagarsegmentsLine',
      fillZoomExtent: [1, 20],
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': fillColor,
        'fill-opacity': 1,
        'fill-outline-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          14.5,
          'rgba(0,0,0,0)',
          15,
          isCropMapShown ? '#000000' : '#fff',
        ],
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.ganganagarsegmentMouseMove
          : this.ganganagarsegmentMouseMove2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.ganganagarsegmentMouseLeave
          : this.ganganagarsegmentMouseLeave2,
      fillclickFunc:
        mapIndex === 0
          ? this.ganganagarsegmentClick
          : this.ganganagarsegmentClick2,
      lineZoomExtent: [15, 20],
      linePaint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? '#c0c0c0' : '#fff',
          isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? 2 : 3.5,
          isCropMapShown ? 0 : 1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(ganganagarSegmentParams);

    // Mankrola Segments
    let cropKey = '';
    if (selectedSeason === 1) {
      // Kharif
      switch (selectedYear) {
        case 2016:
          cropKey = 'Crop_Kharif_2016';
          break;
        case 2017:
          cropKey = 'Crop_Kharif_2017';
          break;
        case 2018:
          cropKey = 'Crop_Kharif_2018';
          break;
        case 2019:
          cropKey = 'Crop_Kharif_2019';
          break;
        default:
          cropKey = 'Crop_Kharif_2018';
          break;
      }
    } else if (selectedSeason === 2) {
      // Rabi
      switch (selectedYear) {
        case 2016:
          cropKey = 'Crop_Rabi_2016.17';
          break;
        case 2017:
          cropKey = 'Crop_Rabi_2017.18';
          break;
        case 2018:
          cropKey = 'Crop_Rabi_2018.19';
          break;
        case 2019:
          cropKey = 'Crop_Rabi_2019.20';
          break;
        default:
          cropKey = 'Crop_Rabi_2018.19';
          break;
      }
    }
    let hovermankrolasegment;
    const mankrolaSegmentsParams = {
      mapRef: map,
      tileSetURL: 'mapbox://farmguide.mankrolasegments2',
      withLineLayer: true,
      sourceLayer: 'mankrolasegments2',
      sourceId: 'mankrolasegments2',
      fillLayerId: 'mankrolasegments2',
      lineLayerId: 'mankrolasegmentsLine2',
      fillZoomExtent: [1, 20],
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': isCropMapShown
          ? [
            'match',
            ['get', cropKey],
            [config.crops['1'].cropName],
            config.crops['1'].cropColor,
            [config.crops['2'].cropName],
            config.crops['2'].cropColor,
            [config.crops['0'].cropName],
            config.crops['0'].cropColor,
            [config.crops['3'].cropName],
            config.crops['3'].cropColor,
            [config.crops['4'].cropName],
            config.crops['4'].cropColor,
            [config.crops['5'].cropName],
            config.crops['5'].cropColor,
            [config.crops['6'].cropName],
            config.crops['6'].cropColor,
            [config.crops['7'].cropName],
            config.crops['7'].cropColor,
            '#7ec850',
          ]
          : 'rgba(0,0,0,0)',
        'fill-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          1,
          8,
          1,
          10,
          1,
          13,
          1,
          22,
          1,
        ],
        'fill-outline-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          14.5,
          'rgba(0,0,0,0)',
          15,
          '#000000',
        ],
      },
      fillmouseMoveFunc: (e) => {
        if (e.features.length > 0 && map.getZoom() > 13) {
          const addCursor = map.getCanvas();
          addCursor.style.cursor = 'pointer';
          const { selectedSeason: season, selectedYear: year } = this.props;
          if (season === 1) {
            // Kharif
            switch (year) {
              case 2016:
                cropKey = 'Crop_Kharif_2016';
                break;
              case 2017:
                cropKey = 'Crop_Kharif_2017';
                break;
              case 2018:
                cropKey = 'Crop_Kharif_2018';
                break;
              case 2019:
                cropKey = 'Crop_Kharif_2019';
                break;
              default:
                cropKey = 'Crop_Kharif_2018';
                break;
            }
          } else if (season === 2) {
            // Rabi
            switch (year) {
              case 2016:
                cropKey = 'Crop_Rabi_2016.17';
                break;
              case 2017:
                cropKey = 'Crop_Rabi_2017.18';
                break;
              case 2018:
                cropKey = 'Crop_Rabi_2018.19';
                break;
              case 2019:
                cropKey = 'Crop_Rabi_2019.20';
                break;
              default:
                cropKey = 'Crop_Rabi_2018.19';
                break;
            }
          }

          if (!map.getLayer('clickedFarm')) {
            if (setSegmentProperties) {
              setSegmentProperties({
                MAJORITY: e.features[0].properties[cropKey],
                farm_area: e.features[0].properties['farm_area.1'],
                score: e.features[0].properties['Rating.out.of.10'],
              });
            }
          }

          if (hovermankrolasegment) {
            // set the hover attribute to false with feature state
            map.setFeatureState(
              {
                source: 'mankrolasegments2',
                sourceLayer: 'mankrolasegments2',
                id: hovermankrolasegment,
              },
              {
                hover: false,
              },
            );
          }

          hovermankrolasegment = e.features[0].id;

          // set the hover attribute to true with feature state
          map.setFeatureState(
            {
              source: 'mankrolasegments2',
              sourceLayer: 'mankrolasegments2',
              id: hovermankrolasegment,
            },
            {
              hover: true,
            },
          );
        }
      },
      fillmouseLeaveFunc: () => {
        if (!map.getLayer('clickedFarm')) {
          if (setSegmentProperties) {
            setSegmentProperties();
          }
        }

        if (hoversegment) {
          map.setFeatureState(
            {
              source: 'mankrolasegments2',
              sourceLayer: 'mankrolasegments2',
              id: hovermankrolasegment,
            },
            {
              hover: false,
            },
          );
        }
      },
      fillclickFunc: (e) => {
        if (e.features.length > 0 && map.getZoom() > 13) {
          if (setSegmentProperties) {
            setSegmentProperties(e.features[0].properties);
          }
          const featureGeoJSON = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: e.features[0].geometry,
                properties: e.features[0].properties,
              },
            ],
          };
          const boundingBox = bbox(featureGeoJSON);
          this.getBounds(boundingBox);
          const featureCenter = center(featureGeoJSON);
          map.flyTo({
            center: featureCenter.geometry.coordinates,
            zoom: 16,
            speed: 1,
          });

          const { selectedSeason: season, selectedYear: year } = this.props;
          if (season === 1) {
            // Kharif
            switch (year) {
              case 2016:
                cropKey = 'Crop_Kharif_2016';
                break;
              case 2017:
                cropKey = 'Crop_Kharif_2017';
                break;
              case 2018:
                cropKey = 'Crop_Kharif_2018';
                break;
              case 2019:
                cropKey = 'Crop_Kharif_2019';
                break;
              default:
                cropKey = 'Crop_Kharif_2018';
                break;
            }
          } else if (season === 2) {
            // Rabi
            switch (year) {
              case 2016:
                cropKey = 'Crop_Rabi_2016.17';
                break;
              case 2017:
                cropKey = 'Crop_Rabi_2017.18';
                break;
              case 2018:
                cropKey = 'Crop_Rabi_2018.19';
                break;
              case 2019:
                cropKey = 'Crop_Rabi_2019.20';
                break;
              default:
                cropKey = 'Crop_Rabi_2018.19';
                break;
            }
          }

          if (map.getLayer('clickedFarm')) {
            map.getSource('clickedFarm').setData(featureGeoJSON);
          } else {
            map.addSource('clickedFarm', {
              type: 'geojson',
              data: featureGeoJSON,
            });

            map.addLayer({
              id: 'clickedFarm',
              type: 'fill',
              source: 'clickedFarm',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-opacity': 1,
                'fill-color': [
                  'match',
                  ['get', cropKey],
                  [config.crops['1'].cropName],
                  config.crops['1'].cropColor,
                  [config.crops['2'].cropName],
                  config.crops['2'].cropColor,
                  [config.crops['0'].cropName],
                  config.crops['0'].cropColor,
                  [config.crops['3'].cropName],
                  config.crops['3'].cropColor,
                  [config.crops['4'].cropName],
                  config.crops['4'].cropColor,
                  [config.crops['5'].cropName],
                  config.crops['5'].cropColor,
                  [config.crops['6'].cropName],
                  config.crops['6'].cropColor,
                  [config.crops['7'].cropName],
                  config.crops['7'].cropColor,
                  '#7ec850',
                ],
              },
            });

            map.addLayer({
              id: 'clickedFarmLine',
              type: 'line',
              source: 'clickedFarm',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#fff',
                'line-width': 2,
              },
            });
          }
          map.setPaintProperty('background', 'background-opacity', 0.5);
        }
      },
      lineZoomExtent: [13, 18],
      linePaint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? '#c0c0c0' : '#fff',
          isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? 1.5 : 2.5,
          isCropMapShown ? 0 : 1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(mankrolaSegmentsParams);

    /* // New Segments
     let hovernewsegments;
     const newSegmentsParams = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.newsegments',
     withLineLayer: true,
     sourceLayer: 'newsegments',
     sourceId: 'newsegments',
     fillLayerId: 'newsegments',
     lineLayerId: 'newsegmentsLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': isCropMapShown ? '#7ec850' : 'rgba(0,0,0,0)',
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: (e) => {
     if (e.features.length > 0 && map.getZoom() > 13) {
     const addCursor = map.getCanvas();
     addCursor.style.cursor = 'pointer';
     if (!map.getLayer('clickedFarm')) {
     if (setSegmentProperties) {
     setSegmentProperties({
     MAJORITY: 'No Crop',
     farm_area: 'NA',
     score: 'NA',
     });
     }
     }

     if (hovernewsegments) {
     // set the hover attribute to false with feature state
     map.setFeatureState(
     {
     source: 'newsegments',
     sourceLayer: 'newsegments',
     id: hovernewsegments,
     },
     {
     hover: false,
     },
     );
     }

     hovernewsegments = e.features[0].id;

     // set the hover attribute to true with feature state
     map.setFeatureState(
     {
     source: 'newsegments',
     sourceLayer: 'newsegments',
     id: hovernewsegments,
     },
     {
     hover: true,
     },
     );
     }
     },
     fillmouseLeaveFunc: () => {
     if (!map.getLayer('clickedFarm')) {
     if (setSegmentProperties) {
     setSegmentProperties();
     }
     }

     if (hoversegment) {
     map.setFeatureState(
     {
     source: 'newsegments',
     sourceLayer: 'newsegments',
     id: hovermankrolasegment,
     },
     {
     hover: false,
     },
     );
     }
     },
     fillclickFunc: (e) => {
     if (e.features.length > 0 && map.getZoom() > 13) {
     if (setSegmentProperties) {
     setSegmentProperties(e.features[0].properties);
     }
     const featureGeoJSON = {
     type: 'FeatureCollection',
     features: [
     {
     type: 'Feature',
     geometry: e.features[0].geometry,
     properties: e.features[0].properties,
     },
     ],
     };
     const boundingBox = bbox(featureGeoJSON);
     this.getBounds(boundingBox);
     const featureCenter = center(featureGeoJSON);
     map.flyTo({
     center: featureCenter.geometry.coordinates,
     zoom: 16,
     speed: 1,
     });

     if (map.getLayer('clickedFarm')) {
     map.getSource('clickedFarm').setData(featureGeoJSON);
     } else {
     map.addSource('clickedFarm', {
     type: 'geojson',
     data: featureGeoJSON,
     });

     map.addLayer({
     id: 'clickedFarm',
     type: 'fill',
     source: 'clickedFarm',
     layout: {
     visibility: 'visible',
     },
     paint: {
     'fill-opacity': 1,
     'fill-color': '#7ec850',
     },
     });

     map.addLayer({
     id: 'clickedFarmLine',
     type: 'line',
     source: 'clickedFarm',
     layout: {
     visibility: 'visible',
     },
     paint: {
     'line-color': '#fff',
     'line-width': 2,
     },
     });
     }
     // calledFrom === 'farmEngine'
     // && openFarmCard(e.features[0].properties.farmid, featureCenter);
     if (calledFrom === 'farmEngine')
     openFarmCard(e.features[0].properties.farmid, featureCenter);
     map.setPaintProperty('background', 'background-opacity', 0.5);
     }
     },
     lineZoomExtent: [13, 18],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(newSegmentsParams); */

    /* // Fatehabad Segments
     // let hoverFatehabadSegments;
     let fillFatehabadColor;
     if (isCropMapShown) {
     fillFatehabadColor = [
     'match',
     ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     [3],
     config.crops['3'].cropColor,
     [4],
     config.crops['4'].cropColor,
     [5],
     config.crops['5'].cropColor,
     [6],
     config.crops['6'].cropColor,
     [7],
     config.crops['7'].cropColor,
     '#000000',
     ];
     } else {
     fillFatehabadColor = 'rgba(0,0,0,0)';
     }
     const fatehabadSegments = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.fatehabad',
     withLineLayer: true,
     sourceLayer: 'fatehabad',
     sourceId: 'fatehabad',
     fillLayerId: 'fatehabad',
     lineLayerId: 'fatehabadLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': fillFatehabadColor,
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: this.fatehabadsegmentMouseMove,
     fillmouseLeaveFunc: this.fatehabadsegmentMouseLeave,
     fillclickFunc: this.fatehabadsegmentClick,
     lineZoomExtent: [13, 19],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(fatehabadSegments);

     // Sonipat Segments
     let hoverSonipatSegments;
     let fillSonipatColor;
     if (isCropMapShown) {
     fillSonipatColor = [
     'match',
     ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     [3],
     config.crops['3'].cropColor,
     [4],
     config.crops['4'].cropColor,
     [5],
     config.crops['5'].cropColor,
     [6],
     config.crops['6'].cropColor,
     [7],
     config.crops['7'].cropColor,
     '#000000',
     ];
     } else {
     fillSonipatColor = 'rgba(0,0,0,0)'; /!* [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     [
     'match',
     ['get', 'MAJORITY'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     'rgba(0,0,0,0)',
     ],
     'rgba(0,0,0,0)',
     ]; *!/
     }
     const sonipatSegmentsParam = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.sonipat',
     withLineLayer: true,
     sourceLayer: 'sonipat',
     sourceId: 'sonipat',
     fillLayerId: 'sonipat',
     lineLayerId: 'sonipatLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': fillSonipatColor,
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: this.haryanasegmentMouseMove,
     fillmouseLeaveFunc: this.haryanasegmentMouseLeave,
     fillclickFunc: this.haryanasegmentClick,
     lineZoomExtent: [13, 19],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(sonipatSegmentsParam);

     // Rohtak Segments
     let hoverRohtakSegments;
     const rohtakSegmentsParam = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.rohtak',
     withLineLayer: true,
     sourceLayer: 'rohtak',
     sourceId: 'rohtak',
     fillLayerId: 'rohtak',
     lineLayerId: 'rohtakLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': isCropMapShown ? '#7ec850' : 'rgba(0,0,0,0)',
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: (e) => {
     if (e.features.length > 0 && map.getZoom() > 13) {
     const addCursor = map.getCanvas();
     addCursor.style.cursor = 'pointer';
     if (!map.getLayer('clickedFarm')) {
     if (setSegmentProperties) {
     setSegmentProperties({
     MAJORITY: 'No Crop',
     farm_area: 'NA',
     score: 'NA',
     });
     }
     }

     if (hoverRohtakSegments) {
     // set the hover attribute to false with feature state
     map.setFeatureState(
     {
     source: 'rohtak',
     sourceLayer: 'rohtak',
     id: hoverRohtakSegments,
     },
     {
     hover: false,
     },
     );
     }

     hoverRohtakSegments = e.features[0].id;

     // set the hover attribute to true with feature state
     map.setFeatureState(
     {
     source: 'rohtak',
     sourceLayer: 'rohtak',
     id: hoverRohtakSegments,
     },
     {
     hover: true,
     },
     );
     }
     },
     fillmouseLeaveFunc: () => {
     if (!map.getLayer('clickedFarm')) {
     if (setSegmentProperties) {
     setSegmentProperties();
     }
     }

     if (hoverSonipatSegments) {
     map.setFeatureState(
     {
     source: 'rohtak',
     sourceLayer: 'rohtak',
     id: hoverRohtakSegments,
     },
     {
     hover: false,
     },
     );
     }
     },
     fillclickFunc: (e) => {
     if (e.features.length > 0 && map.getZoom() > 13) {
     // if (setSegmentProperties) {
     //   setSegmentProperties(e.features[0].properties);
     // }
     const featureGeoJSON = {
     type: 'FeatureCollection',
     features: [
     {
     type: 'Feature',
     geometry: e.features[0].geometry,
     properties: e.features[0].properties,
     },
     ],
     };
     // const boundingBox = bbox(featureGeoJSON);
     // this.getBounds(boundingBox);
     const featureCenter = center(featureGeoJSON);
     map.flyTo({
     center: featureCenter.geometry.coordinates,
     zoom: 16,
     speed: 1,
     });

     if (map.getLayer('clickedFarm')) {
     map.getSource('clickedFarm').setData(featureGeoJSON);
     } else {
     map.addSource('clickedFarm', {
     type: 'geojson',
     data: featureGeoJSON,
     });

     map.addLayer({
     id: 'clickedFarm',
     type: 'fill',
     source: 'clickedFarm',
     layout: {
     visibility: 'visible',
     },
     paint: {
     'fill-opacity': 1,
     'fill-color': '#7ec850',
     },
     });

     map.addLayer({
     id: 'clickedFarmLine',
     type: 'line',
     source: 'clickedFarm',
     layout: {
     visibility: 'visible',
     },
     paint: {
     'line-color': '#fff',
     'line-width': 2,
     },
     });
     }
     // calledFrom === 'farmEngine'
     //   && openFarmCard(e.features[0].properties.farmid, featureCenter);
     // if (calledFrom === 'farmEngine')
     // openFarmCard(e.features[0].properties.farmid, featureCenter);
     map.setPaintProperty('background', 'background-opacity', 0.5);
     }
     },
     lineZoomExtent: [13, 19],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(rohtakSegmentsParam);

     // Jind Segments
     let fillJindColor;
     if (isCropMapShown) {
     fillJindColor = [
     'match',
     ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     [3],
     config.crops['3'].cropColor,
     [4],
     config.crops['4'].cropColor,
     [5],
     config.crops['5'].cropColor,
     [6],
     config.crops['6'].cropColor,
     [7],
     config.crops['7'].cropColor,
     '#000000',
     ];
     } else {
     fillJindColor = 'rgba(0,0,0,0)'; /!* [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     [
     'match',
     ['get', 'MAJORITY'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     'rgba(0,0,0,0)',
     ],
     'rgba(0,0,0,0)',
     ]; *!/
     }
     const jindSegmentsParam = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.jind',
     withLineLayer: true,
     sourceLayer: 'jind',
     sourceId: 'jind',
     fillLayerId: 'jind',
     lineLayerId: 'jindLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': fillJindColor,
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: this.jindsegmentMouseMove,
     fillmouseLeaveFunc: this.jindsegmentMouseLeave,
     fillclickFunc: this.jindsegmentClick,
     lineZoomExtent: [13, 19],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(jindSegmentsParam);

     // Hisar Segments
     let fillHisarColor;
     if (isCropMapShown) {
     fillHisarColor = [
     'match',
     ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     [3],
     config.crops['3'].cropColor,
     [4],
     config.crops['4'].cropColor,
     [5],
     config.crops['5'].cropColor,
     [6],
     config.crops['6'].cropColor,
     [7],
     config.crops['7'].cropColor,
     '#000000',
     ];
     } else {
     fillHisarColor = 'rgba(0,0,0,0)'; /!* [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     [
     'match',
     ['get', 'MAJORITY'],
     [1],
     config.crops['1'].cropColor,
     [2],
     config.crops['2'].cropColor,
     [0],
     config.crops['0'].cropColor,
     'rgba(0,0,0,0)',
     ],
     'rgba(0,0,0,0)',
     ]; *!/
     }
     const hisarSegmentsParam = {
     mapRef: map,
     tileSetURL: 'mapbox://farmguide.hisar',
     withLineLayer: true,
     sourceLayer: 'hisar',
     sourceId: 'hisar',
     fillLayerId: 'hisar',
     lineLayerId: 'hisarLine',
     fillZoomExtent: [1, 19],
     fillLayout: {
     visibility: 'visible',
     },
     fillPaint: {
     'fill-color': fillHisarColor,
     'fill-opacity': [
     'interpolate',
     ['linear'],
     ['zoom'],
     0,
     1,
     8,
     1,
     10,
     1,
     13,
     1,
     22,
     1,
     ],
     'fill-outline-color': [
     'interpolate',
     ['linear'],
     ['zoom'],
     13,
     'rgba(0,0,0,0)',
     14,
     '#000000',
     ],
     },
     fillmouseMoveFunc: this.hisarsegmentMouseMove,
     fillmouseLeaveFunc: this.hisarsegmentMouseLeave,
     fillclickFunc: this.hisarsegmentClick,
     lineZoomExtent: [13, 19],
     linePaint: {
     'line-color': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? '#c0c0c0' : '#fff',
     isCropMapShown ? 'rgba(0,0,0,0)' : '#fff',
     ],
     'line-width': [
     'case',
     ['boolean', ['feature-state', 'hover'], false],
     isCropMapShown ? 1.5 : 2.5,
     isCropMapShown ? 0 : 1,
     ],
     },
     lineLayout: {
     visibility: 'visible',
     },
     };
     addVectorLayer(hisarSegmentsParam); */

    // Haryana Segments
    let fillHaryanaColor;
    if (isCropMapShown) {
      fillHaryanaColor = [
        'match',
        ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
        [1],
        config.crops['1'].cropColor,
        [2],
        config.crops['2'].cropColor,
        [0],
        config.crops['0'].cropColor,
        [3],
        config.crops['3'].cropColor,
        [4],
        config.crops['4'].cropColor,
        [5],
        config.crops['5'].cropColor,
        [6],
        config.crops['6'].cropColor,
        [7],
        config.crops['7'].cropColor,
        '#000000',
      ];
    } else {
      fillHaryanaColor = 'rgba(0,0,0,0)';
    }
    const haryanaSegmentsParam = {
      mapRef: map,
      tileSetURL: 'mapbox://farmguide.haryana-2019',
      withLineLayer: true,
      sourceLayer: 'haryana-2019',
      sourceId: 'haryana-2019',
      fillLayerId: 'haryana-2019',
      lineLayerId: 'haryana-2019Line',
      fillZoomExtent: [1, 20],
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': fillHaryanaColor,
        'fill-opacity': 1,
        'fill-outline-color': [
          'interpolate',
          ['linear'],
          ['zoom'],
          14.5,
          'rgba(0,0,0,0)',
          15,
          '#000',
        ],
      },
      fillmouseMoveFunc:
        mapIndex === 0
          ? this.haryanasegmentMouseMove
          : this.haryanasegmentMouseMove2,
      fillmouseLeaveFunc:
        mapIndex === 0
          ? this.haryanasegmentMouseLeave
          : this.haryanasegmentMouseLeave2,
      fillclickFunc:
        mapIndex === 0 ? this.haryanasegmentClick : this.haryanasegmentClick2,
      lineZoomExtent: [15, 20],
      linePaint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? '#c0c0c0' : '#000',
          isCropMapShown ? 'rgba(0,0,0,0)' : '#000',
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          isCropMapShown ? 2 : 3.5,
          isCropMapShown ? 0 : 1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(haryanaSegmentsParam);

    if (map.getLayer('background')) {
      map.moveLayer('background');
    }
  };

  level0MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.before;
      if (level0Hover) {
        map.setFeatureState(
          {
            source: 'level0',
            sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
            id: level0Hover,
          },
          {
            hover: false,
          },
        );
      }
      level0Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level0MouseLeaveHandler = () => {
    const map = this.before;
    if (level0Hover) {
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level0ClickHandler = (e) => {
    if (e.features.length > 0) {
      const map = this.before;
      // const {
      //   selectedYear,
      //   selectedSeason,
      //   selectedCropRankingParam,
      //   getLevelStats,
      //   getCropsRegionWise,
      // } = this.props;
      const geom = e.features[0].geometry;
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: geom,
            properties: {},
          },
        ],
      };

      const bounds = bbox(featureGeoJSON);
      map.fitBounds(bounds, { padding: 20 });
      // const params = {
      //   fetchLevel: 'INDIA',
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(bounds);
    }
  };

  level0MouseMoveHandler2 = (e) => {
    if (e.features.length) {
      const map = this.after;
      if (level0Hover) {
        map.setFeatureState(
          {
            source: 'level0',
            sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
            id: level0Hover,
          },
          {
            hover: false,
          },
        );
      }
      level0Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level0MouseLeaveHandler2 = () => {
    const map = this.after;
    if (level0Hover) {
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level0ClickHandler2 = (e) => {
    if (e.features.length > 0) {
      const map = this.after;
      // const {
      //   selectedYear,
      //   selectedSeason,
      //   selectedCropRankingParam,
      //   getLevelStats,
      //   getCropsRegionWise,
      // } = this.props;
      const geom = e.features[0].geometry;
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: geom,
            properties: {},
          },
        ],
      };

      const bounds = bbox(featureGeoJSON);
      map.fitBounds(bounds, { padding: 20 });
      // const params = {
      //   fetchLevel: 'INDIA',
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(bounds);
    }
  };

  level1MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.before;
      if (level1Hover) {
        map.setFeatureState(
          {
            source: 'level1',
            sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
            id: level1Hover,
          },
          {
            hover: false,
          },
        );
      }
      level1Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level1MouseLeaveHandler = () => {
    const map = this.before;
    if (level1Hover) {
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level1ClickHandler = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.before;
      // const {
      //   selectedCropRankingParam,
      //   selectedSeason,
      //   selectedYear,
      //   getCropsRegionWise,
      //   getLevelStats,
      //   getHierarchyData,
      //   setLocation,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      // setLocation('STATE', stateID);
      // getHierarchyData('DISTRICT', stateID);
      localStorage.setItem('stateSelected', '');
      // const params = {
      //   fetchLevel: 'STATE',
      //   stateID,
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(boundingBox);
    }
  };

  level1MouseMoveHandler2 = (e) => {
    if (e.features.length) {
      const map = this.after;
      if (level1Hover) {
        map.setFeatureState(
          {
            source: 'level1',
            sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
            id: level1Hover,
          },
          {
            hover: false,
          },
        );
      }
      level1Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level1MouseLeaveHandler2 = () => {
    const map = this.after;
    if (level1Hover) {
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level1ClickHandler2 = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.after;
      // const {
      //   selectedCropRankingParam,
      //   selectedSeason,
      //   selectedYear,
      //   getCropsRegionWise,
      //   getLevelStats,
      //   getHierarchyData,
      //   setLocation,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      // setLocation('STATE', stateID);
      // getHierarchyData('DISTRICT', stateID);
      localStorage.setItem('stateSelected', '');
      // const params = {
      //   fetchLevel: 'STATE',
      //   stateID,
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(boundingBox);
    }
  };

  level2MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.before;
      if (level2Hover) {
        map.setFeatureState(
          {
            source: 'level2',
            sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
            id: level2Hover,
          },
          {
            hover: false,
          },
        );
      }
      level2Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level2MouseLeaveHandler = () => {
    const map = this.before;
    if (level2Hover) {
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level2ClickHandler = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.before;
      // const {
      //   selectedCropRankingParam,
      //   selectedSeason,
      //   selectedYear,
      //   getCropsRegionWise,
      //   selectedStateID,
      //   getHierarchyData,
      //   setLocation,
      //   getLevelStats,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      // const districtID = e.features[0].properties.district_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      // if (stateID !== selectedStateID) {
      //   getHierarchyData('DISTRICT', stateID);
      //   setLocation('STATE', stateID);
      // }
      // setLocation('DISTRICT', districtID);
      // getHierarchyData('TEHSIL', districtID);
      // const params = {
      //   fetchLevel: 'STATE',
      //   stateID,
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(boundingBox);
    }
  };

  level2MouseMoveHandler2 = (e) => {
    if (e.features.length) {
      const map = this.after;
      if (level2Hover) {
        map.setFeatureState(
          {
            source: 'level2',
            sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
            id: level2Hover,
          },
          {
            hover: false,
          },
        );
      }
      level2Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level2MouseLeaveHandler2 = () => {
    const map = this.after;
    if (level2Hover) {
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level2ClickHandler2 = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.after;
      // const {
      //   selectedCropRankingParam,
      //   selectedSeason,
      //   selectedYear,
      //   getCropsRegionWise,
      //   selectedStateID,
      //   getHierarchyData,
      //   setLocation,
      //   getLevelStats,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      // const districtID = e.features[0].properties.district_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      // if (stateID !== selectedStateID) {
      //   getHierarchyData('DISTRICT', stateID);
      //   setLocation('STATE', stateID);
      // }
      // setLocation('DISTRICT', districtID);
      // getHierarchyData('TEHSIL', districtID);
      // const params = {
      //   fetchLevel: 'STATE',
      //   stateID,
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(boundingBox);
    }
  };

  level3MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.before;
      if (level3Hover) {
        map.setFeatureState(
          {
            source: 'level3',
            sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
            id: level3Hover,
          },
          {
            hover: false,
          },
        );
      }
      level3Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: true,
        },
      );
      // this.tileHover(e, 0);
    }
  };

  level3MouseLeaveHandler = () => {
    const map = this.before;
    if (level3Hover) {
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: false,
        },
      );
    }
    if (popup) {
      popup.remove();
    }
  };

  level3ClickHandler = (e) => {
    if (e.features.length > 0) {
      const map = this.before;
      // const {
      //   selectedStateID,
      //   selectedDistrictID,
      //   selectedTehsilID,
      //   selectedYear,
      //   selectedSeason,
      //   selectedCropRankingParam,
      //   getCropsRegionWise,
      //   getHierarchyData,
      //   setLocation,
      //   getLevelStats,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      // const districtID = e.features[0].properties.district_id;
      // const tehsilID = e.features[0].properties.tehsil_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      // if (stateID !== selectedStateID) {
      //   getHierarchyData('DISTRICT', stateID);
      //   setLocation('STATE', stateID);
      //   getHierarchyData('TEHSIL', districtID);
      //   setLocation('DISTRICT', districtID);
      //   setLocation(
      //     'TEHSIL',
      //     tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
      //       ? '77962571-7d6f-4186-9310-b72baffc700d'
      //       : tehsilID,
      //   );
      //   // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      // } else if (districtID !== selectedDistrictID) {
      //   getHierarchyData('TEHSIL', districtID);
      //   setLocation('DISTRICT', districtID);
      //   setLocation(
      //     'TEHSIL',
      //     tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
      //       ? '77962571-7d6f-4186-9310-b72baffc700d'
      //       : tehsilID,
      //   );
      //   // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      // } else if (tehsilID !== selectedTehsilID) {
      //   setLocation(
      //     'TEHSIL',
      //     tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
      //       ? '77962571-7d6f-4186-9310-b72baffc700d'
      //       : tehsilID,
      //   );
      //   // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      // }
      // const params = {
      //   fetchLevel: 'TEHSIL',
      //   tehsilID:
      //     tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
      //       ? '77962571-7d6f-4186-9310-b72baffc700d'
      //       : tehsilID,
      //   districtID,
      //   stateID,
      //   year: selectedYear,
      //   season: selectedSeason,
      //   cropRankingParam: selectedCropRankingParam,
      // };
      // getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(boundingBox);

      // self.fetchLevelStats(2, e.features[0].properties.district_id);
      // map.setFilter('districtFill', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
      // map.setFilter('districtLine', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
    }
  };

  level3MouseMoveHandler2 = (e) => {
    if (e.features.length) {
      const map = this.after;
      if (level3Hover) {
        map.setFeatureState(
          {
            source: 'level3',
            sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
            id: level3Hover,
          },
          {
            hover: false,
          },
        );
      }
      level3Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: true,
        },
      );
      // this.tileHover(e, 1);
    }
  };

  level3MouseLeaveHandler2 = () => {
    const map = this.after;
    if (level3Hover) {
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: false,
        },
      );
    }
    if (popup) {
      popup.remove();
    }
  };

  level3ClickHandler2 = (e) => {
    if (e.features.length > 0) {
      const map = this.after;
      // const {
      //   selectedStateID,
      //   selectedDistrictID,
      //   selectedTehsilID,
      //   selectedYear,
      //   selectedSeason,
      //   selectedCropRankingParam,
      //   getCropsRegionWise,
      //   getHierarchyData,
      //   setLocation,
      //   getLevelStats,
      // } = this.props;
      const { bounds } = e.features[0].properties;
      // const stateID = e.features[0].properties.state_id;
      // const districtID = e.features[0].properties.district_id;
      // const tehsilID = e.features[0].properties.tehsil_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      /* if (stateID !== selectedStateID) {
        getHierarchyData('DISTRICT', stateID);
        setLocation('STATE', stateID);
        getHierarchyData('TEHSIL', districtID);
        setLocation('DISTRICT', districtID);
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      } else if (districtID !== selectedDistrictID) {
        getHierarchyData('TEHSIL', districtID);
        setLocation('DISTRICT', districtID);
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      } else if (tehsilID !== selectedTehsilID) {
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      }
      const params = {
        fetchLevel: 'TEHSIL',
        tehsilID:
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        districtID,
        stateID,
        year: selectedYear,
        season: selectedSeason,
        cropRankingParam: selectedCropRankingParam,
      };
      getLevelStats(params);
      getCropsRegionWise(params); */
      this.getBounds(boundingBox);

      // self.fetchLevelStats(2, e.features[0].properties.district_id);
      // map.setFilter('districtFill', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
      // map.setFilter('districtLine', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
    }
  };

  ganganagarsegmentMouseMove = (e) => {
    const map = this.before;
    const { setSegmentProperties } = this.props;
    if (e.features.length > 0 && map.getZoom() > 13) {
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      if (!map.getLayer('clickedFarm')) {
        if (setSegmentProperties) {
          setSegmentProperties(e.features[0].properties);
        }
      }

      if (hoversegment) {
        // set the hover attribute to false with feature state
        map.setFeatureState(
          {
            source: 'ganganagarsegments',
            sourceLayer: 'ganganagarsegments2',
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }

      hoversegment = e.features[0].id;

      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'ganganagarsegments',
          sourceLayer: 'ganganagarsegments2',
          id: hoversegment,
        },
        {
          hover: true,
        },
      );
    }
  };

  ganganagarsegmentMouseLeave = () => {
    const map = this.before;
    const { setSegmentProperties } = this.props;
    if (!map.getLayer('clickedFarm')) {
      if (setSegmentProperties) {
        setSegmentProperties();
      }
    }

    if (hoversegment) {
      map.setFeatureState(
        {
          source: 'ganganagarsegments',
          sourceLayer: 'ganganagarsegments2',
          id: hoversegment,
        },
        {
          hover: false,
        },
      );
    }
  };

  ganganagarsegmentClick = (e) => {
    const map = this.before;
    console.log('Map On Segment Click', map);
    if (e.features.length > 0 && map.getZoom() > 13) {
      const { calledFrom, setSegmentProperties, selectedTab } = this.props;
      // console.log("Event ",e);
      /* const { point } = e;
       const { x, y } = point;
       console.log("Pixel Coords ",x,y);
       const canvas = map.getCanvas();
       const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
       if (gl) {
       const data = new Uint8Array(4);
       const canvasX = x - canvas.offsetLeft;
       const canvasY = canvas.height - y - canvas.offsetTop;
       gl.readPixels(
       canvasX,
       canvasY,
       1,
       1,
       gl.RGBA,
       gl.UNSIGNED_BYTE,
       data,
       );
       const [r, g, b, a] = data;
       const color = `rgba(${r}, ${g}, ${b}, ${a})`;
       console.log(`Color at (${x}, ${y}) = ${color}`);
       } */
      if (setSegmentProperties) {
        setSegmentProperties(e.features[0].properties);
      }
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties,
          },
        ],
      };

      const boundingBox = bbox(featureGeoJSON);

      /* if (calledFrom === 'farmEngine') {
        const completeBounds = findMissingBounds(boundingBox, 0);
        const {
          x1, x2, x3, x4, y1, y2, y3, y4,
        } = completeBounds;
        const xBounds = `${x1},${x2},${x3},${x4}`;
        const yBounds = `${y1},${y2},${y3},${y4}`;
        // let x = completeBounds.slice(0,4);
        // x = x.join(',');
        // let y = completeBounds.slice(5);
        // y.join(',')
        let today = new Date();
        let priorDate = new Date().setDate(today.getDate() - 90);
        priorDate = new Date(priorDate);
        today = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
        priorDate = `${priorDate.getDate()}-${priorDate.getMonth()}-${priorDate.getFullYear()}`;
        console.log(
          '======>',
          boundingBox,
          completeBounds,
          xBounds,
          yBounds,
          today,
          priorDate,
        );

        const ndviParams = {
          x: xBounds,
          y: yBounds,
          dateTo: today,
          dateFrom: priorDate,
          source: 's2',
        };
        getNdviIndices(ndviParams);
      } */
      this.getBounds(boundingBox, e.features[0].properties.farmid);
      const featureCenter = center(featureGeoJSON);
      map.flyTo({
        center: featureCenter.geometry.coordinates,
        zoom: 16,
        speed: 1,
      });
      if (map.getLayer('clickedFarm')) {
        map.getSource('clickedFarm').setData(featureGeoJSON);
      } else {
        map.addSource('clickedFarm', {
          type: 'geojson',
          data: featureGeoJSON,
        });

        map.addLayer({
          id: 'clickedFarm',
          type: 'fill',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 1,
            'fill-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? [
                  'match',
                  ['get', 'MAJORITY'],
                  [1],
                  config.crops['1'].cropColor,
                  [2],
                  config.crops['2'].cropColor,
                  [0],
                  config.crops['0'].cropColor,
                  [3],
                  config.crops['3'].cropColor,
                  [4],
                  config.crops['4'].cropColor,
                  '#000000',
                ]
                : 'rgba(0,0,0,0)',
          },
        });

        map.addLayer({
          id: 'clickedFarmLine',
          type: 'line',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? '#fff'
                : '#fff000',
            'line-width':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? 2
                : 4,
          },
        });
      }
    }
  };

  ganganagarsegmentMouseMove2 = (e) => {
    const map = this.after;
    const { setSegmentProperties } = this.props;
    if (e.features.length > 0 && map.getZoom() > 13) {
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      if (!map.getLayer('clickedFarm')) {
        if (setSegmentProperties) {
          setSegmentProperties(e.features[0].properties);
        }
      }

      if (hoversegment) {
        // set the hover attribute to false with feature state
        map.setFeatureState(
          {
            source: 'ganganagarsegments',
            sourceLayer: 'ganganagarsegments2',
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }

      hoversegment = e.features[0].id;

      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'ganganagarsegments',
          sourceLayer: 'ganganagarsegments2',
          id: hoversegment,
        },
        {
          hover: true,
        },
      );
    }
  };

  ganganagarsegmentMouseLeave2 = () => {
    const map = this.after;
    const { setSegmentProperties } = this.props;
    if (!map.getLayer('clickedFarm')) {
      if (setSegmentProperties) {
        setSegmentProperties();
      }
    }

    if (hoversegment) {
      map.setFeatureState(
        {
          source: 'ganganagarsegments',
          sourceLayer: 'ganganagarsegments2',
          id: hoversegment,
        },
        {
          hover: false,
        },
      );
    }
  };

  ganganagarsegmentClick2 = (e) => {
    const map = this.after;
    console.log('Map On Segment Click', map);
    if (e.features.length > 0 && map.getZoom() > 13) {
      const {
        calledFrom,
        openFarmCard,
        setSegmentProperties,
        selectedTab,
      } = this.props;
      // console.log("Event ",e);
      /* const { point } = e;
       const { x, y } = point;
       console.log("Pixel Coords ",x,y);
       const canvas = map.getCanvas();
       const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
       if (gl) {
       const data = new Uint8Array(4);
       const canvasX = x - canvas.offsetLeft;
       const canvasY = canvas.height - y - canvas.offsetTop;
       gl.readPixels(
       canvasX,
       canvasY,
       1,
       1,
       gl.RGBA,
       gl.UNSIGNED_BYTE,
       data,
       );
       const [r, g, b, a] = data;
       const color = `rgba(${r}, ${g}, ${b}, ${a})`;
       console.log(`Color at (${x}, ${y}) = ${color}`);
       } */
      if (setSegmentProperties) {
        setSegmentProperties(e.features[0].properties);
      }
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties,
          },
        ],
      };

      const boundingBox = bbox(featureGeoJSON);

      /* if (calledFrom === 'farmEngine') {
        const completeBounds = findMissingBounds(boundingBox, 0);
        const {
          x1, x2, x3, x4, y1, y2, y3, y4,
        } = completeBounds;
        const xBounds = `${x1},${x2},${x3},${x4}`;
        const yBounds = `${y1},${y2},${y3},${y4}`;
        // let x = completeBounds.slice(0,4);
        // x = x.join(',');
        // let y = completeBounds.slice(5);
        // y.join(',')
        let today = new Date();
        let priorDate = new Date().setDate(today.getDate() - 90);
        priorDate = new Date(priorDate);
        today = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
        priorDate = `${priorDate.getDate()}-${priorDate.getMonth()}-${priorDate.getFullYear()}`;
        console.log(
          '======>',
          boundingBox,
          completeBounds,
          xBounds,
          yBounds,
          today,
          priorDate,
        );

        const ndviParams = {
          x: xBounds,
          y: yBounds,
          dateTo: today,
          dateFrom: priorDate,
          source: 's2',
        };
        getNdviIndices(ndviParams);
      } */
      this.getCompareBounds(boundingBox, e.features[0].properties.farmid);
      const featureCenter = center(featureGeoJSON);
      map.flyTo({
        center: featureCenter.geometry.coordinates,
        zoom: 16,
        speed: 1,
      });
      if (map.getLayer('clickedFarm')) {
        map.getSource('clickedFarm').setData(featureGeoJSON);
      } else {
        map.addSource('clickedFarm', {
          type: 'geojson',
          data: featureGeoJSON,
        });

        map.addLayer({
          id: 'clickedFarm',
          type: 'fill',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 1,
            'fill-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? [
                  'match',
                  ['get', 'MAJORITY'],
                  [1],
                  config.crops['1'].cropColor,
                  [2],
                  config.crops['2'].cropColor,
                  [0],
                  config.crops['0'].cropColor,
                  [3],
                  config.crops['3'].cropColor,
                  [4],
                  config.crops['4'].cropColor,
                  '#000000',
                ]
                : 'rgba(0,0,0,0)',
          },
        });

        map.addLayer({
          id: 'clickedFarmLine',
          type: 'line',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? '#fff'
                : '#fff000',
            'line-width':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? 2
                : 4,
          },
        });
      }
      if (calledFrom === 'farmEngine') {
        openFarmCard(e.features[0].properties.farmid, featureCenter);
      }
      if (
        calledFrom === 'farmEngine'
        || (calledFrom === 'dashboard' && selectedTab !== 0)
      ) {
        map.setPaintProperty('background', 'background-opacity', 0.5);
      }
    }
  };

  haryanasegmentMouseMove = (e) => {
    const map = this.before;
    const { setSegmentProperties } = this.props;
    if (e.features.length > 0 && map.getZoom() > 13) {
      // console.log("Featuress >> ",e.features[0]);
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      if (!map.getLayer('clickedFarm')) {
        if (setSegmentProperties) {
          setSegmentProperties({
            MAJORITY: e.features[0].properties.Kharif,
            score: e.features[0].properties.Rating,
            farm_area: e.features[0].properties.farm_area,
          });
        }
      }

      if (hoversegment) {
        // set the hover attribute to false with feature state
        map.setFeatureState(
          {
            source: 'haryana-2019',
            sourceLayer: 'haryana-2019',
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }

      hoversegment = e.features[0].id;

      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'haryana-2019',
          sourceLayer: 'haryana-2019',
          id: hoversegment,
        },
        {
          hover: true,
        },
      );
      // this.tileHover(e, 0);
    }
  };

  haryanasegmentMouseLeave = () => {
    const map = this.before;
    const { setSegmentProperties } = this.props;
    if (!map.getLayer('clickedFarm')) {
      if (setSegmentProperties) {
        setSegmentProperties();
      }
    }

    if (hoversegment) {
      map.setFeatureState(
        {
          source: 'haryana-2019',
          sourceLayer: 'haryana-2019',
          id: hoversegment,
        },
        {
          hover: false,
        },
      );
    }
  };

  haryanasegmentClick = (e) => {
    const map = this.before;
    // console.log('Map On Segment Click', map);
    if (e.features.length > 0 && map.getZoom() > 13) {
      console.log('Clicked ', e.features[0]);
      const {
        calledFrom,
        openFarmCard,
        setSegmentProperties,
        selectedTab,
      } = this.props;
      // console.log("Event ",e);
      /* const { point } = e;
       const { x, y } = point;
       console.log("Pixel Coords ",x,y);
       const canvas = map.getCanvas();
       const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
       if (gl) {
       const data = new Uint8Array(4);
       const canvasX = x - canvas.offsetLeft;
       const canvasY = canvas.height - y - canvas.offsetTop;
       gl.readPixels(
       canvasX,
       canvasY,
       1,
       1,
       gl.RGBA,
       gl.UNSIGNED_BYTE,
       data,
       );
       const [r, g, b, a] = data;
       const color = `rgba(${r}, ${g}, ${b}, ${a})`;
       console.log(`Color at (${x}, ${y}) = ${color}`);
       } */
      if (setSegmentProperties) {
        setSegmentProperties({
          MAJORITY: e.features[0].properties.Kharif,
          score: e.features[0].properties.Rating,
          farm_area: e.features[0].properties.farm_area,
        });
      }
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties,
          },
        ],
      };
      const boundingBox = bbox(featureGeoJSON);
      this.getBounds(boundingBox, e.features[0].properties.farmid);
      const featureCenter = center(featureGeoJSON);
      map.flyTo({
        center: featureCenter.geometry.coordinates,
        zoom: 17,
        speed: 1,
      });
      if (map.getLayer('clickedFarm')) {
        map.getSource('clickedFarm').setData(featureGeoJSON);
      } else {
        map.addSource('clickedFarm', {
          type: 'geojson',
          data: featureGeoJSON,
        });

        map.addLayer({
          id: 'clickedFarm',
          type: 'fill',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 1,
            'fill-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? [
                  'match',
                  ['get', 'Kharif'],
                  [1],
                  config.crops['1'].cropColor,
                  [2],
                  config.crops['2'].cropColor,
                  [0],
                  config.crops['0'].cropColor,
                  [3],
                  config.crops['3'].cropColor,
                  [4],
                  config.crops['4'].cropColor,
                  [5],
                  config.crops['5'].cropColor,
                  [6],
                  config.crops['6'].cropColor,
                  [7],
                  config.crops['7'].cropColor,
                  '#000000',
                ]
                : 'rgba(0,0,0,0)',
          },
        });

        map.addLayer({
          id: 'clickedFarmLine',
          type: 'line',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? '#fff'
                : '#fff000',
            'line-width':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? 2
                : 4,
          },
        });
      }
      if (calledFrom === 'farmEngine') {
        openFarmCard(e.features[0].properties.farmid, featureCenter);
      }
      if (
        calledFrom === 'farmEngine'
        || (calledFrom === 'dashboard' && selectedTab !== 0)
      ) {
        map.setPaintProperty('background', 'background-opacity', 0.5);
      }
    }
  };

  tileHover = (e, mapIndex) => {
    const map = mapIndex === 0 ? this.before : this.after;
    const {
      selectedTimelineDate,
      indexToView,
      compareIndexToView,
      indicesTimeline,
      indicesTimelineComparison,
      selectedCompareTimelineDate,
      indicesLegends,
      compareIndicesLegends,
    } = this.props;
    if (
      (mapIndex === 0 && !_.isEmpty(indicesTimeline))
      || (mapIndex === 1 && !_.isEmpty(indicesTimelineComparison))
    ) {
      // if (indicesTimeline.data) {
      if (
        (mapIndex === 0 && indexToView && selectedTimelineDate)
        || (mapIndex === 1 && compareIndexToView && selectedCompareTimelineDate)
      ) {
        const canvas = map.getCanvas();
        const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
        if (gl) {
          const { point } = e;
          const { x, y } = point;
          const data = new Uint8Array(4);
          const canvasX = x - canvas.offsetLeft;
          const canvasY = canvas.height - y - canvas.offsetTop;
          gl.readPixels(
            canvasX,
            canvasY,
            1,
            1,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            data,
          );
          const [r, g, b] = data;
          // const color = `rgba(${r}, ${g}, ${b})`;
          // console.log('Canvas >>> ', canvas.offsetLeft, canvas.offsetTop);
          // console.log(`Point${x}, ${y}, ${canvasX}, ${canvasY}, ${data}`);
          // console.log(`color ${color}`);
          if (mapIndex === 0 || mapIndex === 1) {
            let minRDiffSoFar = 11;
            let minGDiffSoFar = 11;
            let minBDiffSoFar = 11;
            let indexWithMinValue = -1;
            const { length } = mapIndex === 0 ? indicesLegends : compareIndicesLegends;
            for (let i = 0; i < length; i += 1) {
              const item = indicesLegends[i];
              const { rgb } = item;
              const rDiff = Math.abs(rgb[0] - r);
              const gDiff = Math.abs(rgb[1] - g);
              const bDiff = Math.abs(rgb[2] - b);
              if (rDiff < minRDiffSoFar) {
                minRDiffSoFar = rDiff;
                if (gDiff < minGDiffSoFar) {
                  minGDiffSoFar = gDiff;
                  if (bDiff < minBDiffSoFar) {
                    minBDiffSoFar = bDiff;
                    indexWithMinValue = i;
                  }
                }
              }
            }
            // const ndviValue = config.ndviLegends.find(o => _.isEqual(o.rgb, [r, g, b]));
            // console.log("NDVI VALUE ",ndviValue);
            if (indexWithMinValue !== -1) {
              const ndviValue = mapIndex === 0
                ? indicesLegends[indexWithMinValue]
                : compareIndicesLegends[indexWithMinValue];
              // console.log("NDVI VALUE ",ndviValue);
              /* eslint-disable global-require */
              const mapboxgl = require('mapbox-gl');
              /* eslint-enable global-require */
              if (popup) {
                popup
                  .setLngLat(e.lngLat)
                  .setHTML(`<h2>${ndviValue.value}</h2>`)
                  .addTo(map);
              } else {
                popup = new mapboxgl.Popup()
                  .setLngLat(e.lngLat)
                  .setHTML(`<h2>${ndviValue.value}</h2>`)
                  .addTo(map);
              }
            } else if (popup) {
              // console.log('color ', color);
              popup.remove();
            }
          }
        }
      }
      // }
    }
  };

  haryanasegmentMouseMove2 = (e) => {
    const map = this.after;
    const { setSegmentProperties } = this.props;
    if (e.features.length > 0 && map.getZoom() > 13) {
      // console.log("Featuress >> ",e.features[0]);
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      if (!map.getLayer('clickedFarm')) {
        if (setSegmentProperties) {
          setSegmentProperties({
            MAJORITY: e.features[0].properties.Kharif,
            score: e.features[0].properties.Rating,
            farm_area: e.features[0].properties.farm_area,
          });
        }
      }

      if (hoversegment) {
        // set the hover attribute to false with feature state
        map.setFeatureState(
          {
            source: 'haryana-2019',
            sourceLayer: 'haryana-2019',
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }

      hoversegment = e.features[0].id;

      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'haryana-2019',
          sourceLayer: 'haryana-2019',
          id: hoversegment,
        },
        {
          hover: true,
        },
      );

      // this.tileHover(e, 1);
    }
  };

  haryanasegmentMouseLeave2 = () => {
    const map = this.after;
    const { setSegmentProperties } = this.props;
    if (!map.getLayer('clickedFarm')) {
      if (setSegmentProperties) {
        setSegmentProperties();
      }
    }

    if (hoversegment) {
      map.setFeatureState(
        {
          source: 'haryana-2019',
          sourceLayer: 'haryana-2019',
          id: hoversegment,
        },
        {
          hover: false,
        },
      );
    }
  };

  haryanasegmentClick2 = (e) => {
    const map = this.after;
    // console.log('Map On Segment Click', map);
    if (e.features.length > 0 && map.getZoom() > 13) {
      console.log('Clicked ', e.features[0]);
      const {
        calledFrom,
        openFarmCard,
        setSegmentProperties,
        selectedTab,
      } = this.props;
      // console.log("Event ",e);
      /* const { point } = e;
       const { x, y } = point;
       console.log("Pixel Coords ",x,y);
       const canvas = map.getCanvas();
       const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
       if (gl) {
       const data = new Uint8Array(4);
       const canvasX = x - canvas.offsetLeft;
       const canvasY = canvas.height - y - canvas.offsetTop;
       gl.readPixels(
       canvasX,
       canvasY,
       1,
       1,
       gl.RGBA,
       gl.UNSIGNED_BYTE,
       data,
       );
       const [r, g, b, a] = data;
       const color = `rgba(${r}, ${g}, ${b}, ${a})`;
       console.log(`Color at (${x}, ${y}) = ${color}`);
       } */
      if (setSegmentProperties) {
        setSegmentProperties({
          MAJORITY: e.features[0].properties.Kharif,
          score: e.features[0].properties.Rating,
          farm_area: e.features[0].properties.farm_area,
        });
      }
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties,
          },
        ],
      };
      const boundingBox = bbox(featureGeoJSON);
      this.getCompareBounds(boundingBox, e.features[0].properties.farmid);
      const featureCenter = center(featureGeoJSON);
      map.flyTo({
        center: featureCenter.geometry.coordinates,
        zoom: 17,
        speed: 1,
      });
      if (map.getLayer('clickedFarm')) {
        map.getSource('clickedFarm').setData(featureGeoJSON);
      } else {
        map.addSource('clickedFarm', {
          type: 'geojson',
          data: featureGeoJSON,
        });

        map.addLayer({
          id: 'clickedFarm',
          type: 'fill',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 1,
            'fill-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? [
                  'match',
                  ['get', 'Kharif'],
                  [1],
                  config.crops['1'].cropColor,
                  [2],
                  config.crops['2'].cropColor,
                  [0],
                  config.crops['0'].cropColor,
                  [3],
                  config.crops['3'].cropColor,
                  [4],
                  config.crops['4'].cropColor,
                  [5],
                  config.crops['5'].cropColor,
                  [6],
                  config.crops['6'].cropColor,
                  [7],
                  config.crops['7'].cropColor,
                  '#000000',
                ]
                : 'rgba(0,0,0,0)',
          },
        });

        map.addLayer({
          id: 'clickedFarmLine',
          type: 'line',
          source: 'clickedFarm',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? '#fff'
                : '#fff000',
            'line-width':
              calledFrom === 'farmEngine'
              || (calledFrom === 'dashboard' && selectedTab !== 0)
                ? 2
                : 4,
          },
        });
      }
      if (calledFrom === 'farmEngine') {
        openFarmCard(e.features[0].properties.farmid, featureCenter);
      }
      if (
        calledFrom === 'farmEngine'
        || (calledFrom === 'dashboard' && selectedTab !== 0)
      ) {
        map.setPaintProperty('background', 'background-opacity', 0.5);
      }
    }
  };

  getBounds = (bounds, currentFarm) => {
    const {
      updateBounds,
      satellitesSource,
      indicesValue,
      startDate,
      endDate,
      // fetchIndicesImages,
      selectedFarm,
    } = this.props;
    console.log(
      'Bounds >>>>>>>>>>>>>>>>',
      bounds,
      indicesValue,
      satellitesSource,
      selectedFarm,
      startDate,
      endDate,
      currentFarm,
    );
    updateBounds(
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      currentFarm,
    );
  };

  getCompareBounds = (compareBounds, compareFarm) => {
    const {
      updateCompareBounds,
      satellitesSource,
      indicesValue,
      indicesValueComparision,
      // startDate,
      // endDate,
      // selectedCompareFarm,
      // fetchCompareIndicesImages,
      startDateComparison,
      endDateComparison,
    } = this.props;
    console.log(
      'Bounds >>>>>>>>>>>>>>>>',
      compareBounds,
      indicesValue,
      satellitesSource,
      compareFarm,
    );
    updateCompareBounds(
      compareBounds,
      indicesValueComparision,
      satellitesSource,
      startDateComparison,
      endDateComparison,
      compareFarm,
    );

    // if (compareFarm) {
    //   fetchCompareIndicesImages(
    //     compareBounds,
    //     indicesValueComparision,
    //     satellitesSource,
    //     startDate,
    //     endDate,
    //     compareFarm,
    //   );
    // }
  };

  render() {
    return (
      <React.Fragment>
        <div className="dashboardMapHead">
          <MyMap _onMapLoad={this._onMapLoad1} />
        </div>
        <div className="dashboardMapHead">
          <MyMap _onMapLoad={this._onMapLoad2} />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  ({ agricultureIndices, location }) => ({
    indicesValue: agricultureIndices.indicesValue,
    indicesValueComparision: agricultureIndices.indicesValueComparision,
    satellitesSource: agricultureIndices.satellitesSource,
    startDate: agricultureIndices.startDate,
    endDate: agricultureIndices.endDate,
    indicesImages: agricultureIndices.indicesImages,
    compareIndicesImages: agricultureIndices.compareIndicesImages,
    selectedFarm: agricultureIndices.selectedFarm,
    bounds: agricultureIndices.bounds,
    compareBounds: agricultureIndices.compareBounds,
    startDateComparision: agricultureIndices.startDateComparision,
    endDateComparision: agricultureIndices.endDateComparison,
    selectedSeason: location.selectedSeason,
    selectedYear: location.selectedYear,
    indicesTimeline: agricultureIndices.indicesTimeline,
    indicesTimelineComparison: agricultureIndices.indicesTimelineComparison,
    indicesLegends: agricultureIndices.indicesLegends,
    compareIndicesLegends: agricultureIndices.compareIndicesLegends,
  }),
  dispatch => ({
    updateBounds: (
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      farmid,
    ) => {
      dispatch(
        updateBoundsAction(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          farmid,
        ),
      );
    },
    updateCompareBounds: (
      compareBounds,
      indicesValue,
      satellitesSource,
      startDateComparision,
      endDateComparision,
      selectedCompareFarm,
    ) => {
      dispatch(
        updateBoundsComparisionAction(
          compareBounds,
          indicesValue,
          satellitesSource,
          startDateComparision,
          endDateComparision,
          selectedCompareFarm,
        ),
      );
    },
    fetchIndicesImages: (
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      selectedFarm,
    ) => {
      dispatch(
        fetchIndicesImagesAct(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        ),
      );
    },
    fetchCompareIndicesImages: (
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      selectedFarm,
    ) => {
      dispatch(
        fetchCompareIndicesImagesAct(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        ),
      );
    },
    clearIndicesImages: () => dispatch(clearIndicesImagesAct()),
    clearCompareIndicesImages: () => dispatch(clearCompareIndicesImagesAct()),
  }),
  null,
  { withRef: true },
)(IndicesCompare);
