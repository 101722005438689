import React from 'react';
import { Grid } from '@material-ui/core';
import '../../../css/common.scss';
import '../../../css/tagging/promotional.scss';

class TaggingFixedPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid item lg={3} sm={3} xs={12}>
        <h4>You can start with</h4>
        <div className="promotionalPanel">
          <div className="promotionalSec">
            <ul>
              <li>
                <span>
                  {/* <h5>Farmer Voice</h5> */}
                  <img src="" alt="" title="" />
                  <p>
                    <span className="info">
                      Send Potential Ads
                      {/* UP govt asks sugar mills to clear dues of farmers by October */}
                      {/* 31, warns action */}
                    </span>
                  </p>
                  {/* <h4>
                  <span>dcdscsdcsdsd</span>
                  <span>dcdscsdcsdsd</span>
                </h4> */}
                  {/* <h6>
                  <span className="left">Jalgaon, Mahrashtra</span>
                  <span className="right">Rice & Maize</span>
                </h6> */}
                </span>
              </li>
              <li>
                <span>
                  {/* <h5>News</h5> */}
                  <img src="" alt="" title="" />
                  <p>
                    <span className="info">
                      Lead Generation
                      {/* UP govt asks sugar mills to clear dues of farmers by October
                    31, warns action */}
                    </span>
                  </p>
                  <p>
                    Find new customers
                    {/* <span className="date">2019-09-21T06:15:29Z</span> */}
                  </p>
                </span>
              </li>
            </ul>
          </div>
          <div className="promotionalSec">
            <ul>
              <li>
                <span>
                  {/* <h5>Farmer Voice</h5> */}
                  <img src="" alt="" title="" />
                  <h5>Video</h5>
                  <p>Watch video</p>
                </span>
              </li>
              <li>
                <span>
                  <img src="" alt="" title="" />
                  <h5>GIS</h5>
                  <p>Agriculture Indice, weather etc.</p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Grid>
    );
  }
}
export default TaggingFixedPanel;
