import React from 'react';
import { Grid } from '@material-ui/core';
import ScrollableTabPanel from '../common/ScrollableTabPanel';
import SelectPoster from './selectPoster';
import Reports from '../scheduleReport/reports';
import '../../css/common.scss';
import '../../css/button.scss';

class TaggingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  broadcastTabChange = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleTabChange = () => {
    this.setState({
      activeTab: 0,
    });
  };

  handleCampaignTab = () => {
    this.setState({
      activeTab: 1,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { calledFrom } = this.props;
    const tabsData = [
      {
        tabName: 'Tagging',
        component: (
          <SelectPoster
            anotherTab={this.handleCampaignTab}
            calledFrom={calledFrom}
          />
        ),
      },
      {
        tabName: 'Reports',
        component:
          activeTab === 1 ? (
            <Reports anotherTab={this.handleTabChange} type="tagging" />
          ) : null,
      },
    ];
    return (
      <React.Fragment>
        <Grid container className="taggingMain removePadding" spacing={0}>
          <Grid item lg={12} md={12} sm={12} xs={12} className="ivrsWhiteBack">
            <ScrollableTabPanel
              tabsData={tabsData}
              customStyle="innerTabPanel"
              // customStyle="ivrsTabPanel"
              renderOnce
              activeTab={activeTab}
              broadcastTabChange={this.broadcastTabChange}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default TaggingTab;
