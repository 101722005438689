import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { showDemoPopupAction } from '../../../Actions/surveyAction';
import ScrollableTabPanel from '../../common/ScrollableTabPanel';
import SurveySelectTab from './surveySelectTab';
import '../../../css/tabPanel.scss';

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // console.log("Survey Mounted", this.props);
    // const { getMapReference, showPopupFunc } = this.props;
    // const map = getMapReference();
    // // console.log("Map >> ", map);
    // const size = 200;
    // const pulsingDot = {
    //   width: size,
    //   height: size,
    //   data: new Uint8Array(size * size * 4),
    //   onAdd() {
    //     const canvas = document.createElement('canvas');
    //     canvas.width = this.width;
    //     canvas.height = this.height;
    //     this.context = canvas.getContext('2d');
    //   },
    //   render() {
    //     const duration = 1000;
    //     const t = (window.performance.now() % duration) / duration;
    //     const radius = (size / 2) * 0.3;
    //     const outerRadius = (size / 2) * 0.7 * t + radius;
    //     const { context } = this;
    //     // draw outer circle
    //     context.clearRect(0, 0, this.width, this.height);
    //     context.beginPath();
    //     context.arc(
    //       this.width / 2,
    //       this.height / 2,
    //       outerRadius,
    //       0,
    //       Math.PI * 2,
    //     );
    //     context.fillStyle = `rgba(207, 255, 100,${1 - t})`;
    //     context.fill();
    //     // draw inner circle
    //     context.beginPath();
    //     context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    //     context.fillStyle = 'rgba(207, 255, 0, 1)';
    //     context.strokeStyle = 'white';
    //     context.lineWidth = 2 + 4 * (1 - t);
    //     context.fill();
    //     context.stroke();
    //     // update this image's data with data from the canvas
    //     this.data = context.getImageData(0, 0, this.width, this.height).data;
    //     // keep the map repainting
    //     map.triggerRepaint();
    //     // return `true` to let the map know that the image was updated
    //     return true;
    //   },
    // };
    // if (map) {
    //   map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
    //   const geojson = {
    //     type: 'FeatureCollection',
    //     features: [
    //       {
    //         type: 'Feature',
    //         geometry: {
    //           type: 'Point',
    //           coordinates: [-77.032, 38.913],
    //         },
    //         properties: {
    //           id: 0,
    //           title: 'Mapbox',
    //           description: 'Washington, D.C.',
    //         },
    //       },
    //       {
    //         type: 'Feature',
    //         geometry: {
    //           type: 'Point',
    //           coordinates: [-122.414, 37.776],
    //         },
    //         properties: {
    //           id: 1,
    //           title: 'Mapbox',
    //           description: 'San Francisco, California',
    //         },
    //       },
    //     ],
    //   };
    //   map.addLayer({
    //     id: 'surveyPoints',
    //     type: 'symbol',
    //     source: {
    //       type: 'geojson',
    //       data: geojson,
    //     },
    //     layout: {
    //       'icon-image': 'pulsing-dot',
    //       visibility: 'visible',
    //     },
    //   });
    //   map.on('click', 'surveyPoints', (e) => {
    //     console.log('Point Clicked >>', e.features[0]);
    //     showPopupFunc(e.features[0].properties.id);
    //   });
    // }
  }

  render() {
    // console.log("Props >>>", this.props);
    // const { surveyType } = this.props;
    const tabsData = [
      {
        tabName: 'Select',
        component: <SurveySelectTab {...this.props} />,
      },
    ];
    return (
      <ScrollableTabPanel tabsData={tabsData} customStyle="innerTabPanel" />
    );
  }
}

export default withRouter(
  connect(
    ({ survey }) => ({
      surveyData: survey.surveyData,
      surveyType: survey.surveyType,
    }),
    dispatch => ({
      showPopupFunc: markerIndex => dispatch(showDemoPopupAction(markerIndex)),
    }),
  )(withCookies(Survey)),
);
