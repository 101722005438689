const initialState = {
  indiceSpiData: [],
  spiHeatmap: {},
  imdData: {},
  imdHistoricalData: {},
  noaaData: [],
  noaaHeatmap: {},
  chirpsData: [],
  indiceSmapData: {},
  smapHeatmap: {},
  chirpsHeatmap: {},
  gpmData: [],
  gpmHeatmap: {},
  dataError: '',
  pdsiData: [],
  currentTabDataError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'INDICES_SPI_DATA_SUCCESS':
      return {
        ...state,
        indiceSpiData: action.payload,
      };
    case 'INDICES_SPI_DATA_FAILURE':
      return {
        ...state,
        indiceSpiData: [],
        currentTabDataError: action.payload,
      };
    case 'SMAP_DATA_SUCCESS':
      return {
        ...state,
        indiceSmapData: action.payload,
      };
    case 'SMAP_DATA_FAILURE':
      return {
        ...state,
        indiceSmapData: {},
        currentTabDataError: action.payload,
      };
    case 'IMD_DATA_SUCCESS':
      return {
        ...state,
        imdData: action.payload,
      };
    case 'IMD_DATA_FETCHING':
    case 'IMD_DATA_FAILURE':
      return {
        ...state,
        imdData: {},
        currentTabDataError: action.payload,
      };
    case 'IMD_HISTORICAL_DATA_SUCCESS':
      return {
        ...state,
        imdHistoricalData: action.payload,
      };
    case 'IMD_HISTORICAL_DATA_FETCHING':
    case 'IMD_HISTORICAL_DATA_FAILURE':
      return {
        ...state,
        imdHistoricalData: {},
        dataError: action.payload,
      };
    case 'NOAA_DATA_SUCCESS':
      return {
        ...state,
        noaaData: action.payload,
      };
    case 'NOAA_DATA_FAILURE':
      return {
        ...state,
        noaaData: [],
        dataError: action.payload,
      };
    case 'CHIRPS_DATA_SUCCESS':
      return {
        ...state,
        chirpsData: action.payload,
      };
    case 'CHIRPS_DATA_FAILURE':
      return {
        ...state,
        chirpsData: [],
        dataError: action.payload,
      };
    case 'GPM_DATA_SUCCESS':
      return {
        ...state,
        gpmData: action.payload,
      };
    case 'GPM_DATA_FAILURE':
      return {
        ...state,
        gpmData: [],
        dataError: action.payload,
      };
    case 'RESET_WEATHER_SOURCE_DETAILS':
      if (action.payload === 0) {
        return {
          ...state,
          indiceSpiData: [],
          imdData: {},
          indiceSmapData: {},
          smapHeatmap: {},
          pdsiData: [],
          currentTabDataError: '',
        };
      }
      return {
        ...state,
        indiceSpiData: [],
        imdHistoricalData: {},
        noaaData: [],
        chirpsData: [],
        gpmData: [],
        noaaHeatmap: {},
        chirpsHeatmap: {},
        gpmHeatmap: {},
        dataError: '',
      };

    case 'SMAP_HEATMAP_SUCCESS':
      return {
        ...state,
        smapHeatmap: action.payload,
      };

    case 'SMAP_HEATMAP_FAILURE':
      return {
        ...state,
        smapHeatmap: {},
      };

    case 'CLEAR_SMAP_HEATMAP':
      return {
        ...state,
        smapHeatmap: {},
      };

    case 'CHIRPS_HEATMAP_SUCCESS':
      return {
        ...state,
        chirpsHeatmap: action.payload,
      };

    case 'CHIRPS_HEATMAP_FAILURE':
      return {
        ...state,
        chirpsHeatmap: {},
      };

    case 'CLEAR_CHIRPS_HEATMAP':
      return {
        ...state,
        chirpsHeatmap: {},
      };

    case 'NOAA_HEATMAP_SUCCESS':
      return {
        ...state,
        noaaHeatmap: action.payload,
      };

    case 'NOAA_HEATMAP_FAILURE':
      return {
        ...state,
        noaaHeatmap: {},
      };

    case 'CLEAR_NOAA_HEATMAP':
      return {
        ...state,
        noaaHeatmap: {},
      };

    case 'GPM_HEATMAP_SUCCESS':
      return {
        ...state,
        gpmHeatmap: action.payload,
      };

    case 'GPM_HEATMAP_FAILURE':
      return {
        ...state,
        gpmHeatmap: {},
      };

    case 'CLEAR_GPM_HEATMAP':
      return {
        ...state,
        gpmHeatmap: {},
      };

    case 'CLEAR_HEATMAPS':
      return {
        ...state,
        noaaHeatmap: {},
        // smapHeatmap: {},
        chirpsHeatmap: {},
        gpmHeatmap: {},
        spiHeatmap: {},
      };

    case 'PDSI_DATA_SUCCESS':
      return {
        ...state,
        pdsiData: action.payload,
      };
    case 'PDSI_DATA_FAILURE':
      return {
        ...state,
        pdsiData: [],
        currentTabDataError: action.payload,
      };
    case 'SPI_HEATMAP_SUCCESS':
      return {
        ...state,
        spiHeatmap: action.payload,
      };
    case 'CLEAR_SPI_HEATMAP':
    case 'SPI_HEATMAP_FAILURE':
      return {
        ...state,
        spiHeatmap: {},
      };

    default:
      return state;
  }
};
