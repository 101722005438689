import ajax from '../Functions/ajax';
import { objectToCsv, download } from '../Functions/helper';

/* ======================== POPUP ACTION STARTS ====================== */

export const showDemoPopupAction = markerIndex => (dispatch) => {
  dispatch({
    type: 'SHOW_DEMO_SURVEY_POPUP',
    payload: markerIndex,
  });
};

export const closeDemoPopupAction = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_DEMO_SURVEY_POPUP',
    payload: null,
  });
};

export const showPopupAction = markerIndex => (dispatch) => {
  dispatch({
    type: 'SHOW_SURVEY_POPUP',
    payload: markerIndex,
  });
};

export const closePopupAction = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_SURVEY_POPUP',
    payload: null,
  });
};

/* ======================== POPUP ACTION ENDS ======================== */

/* ======================== DROPDOWN ACTION STARTS =================== */

export const setSurveyLocationAct = (
  level,
  selectedLocation,
  levelName,
) => (dispatch) => {
  dispatch({
    type: `SET_SURVEY_SELECTED_${level.toUpperCase()}`,
    payload: selectedLocation,
    level,
    levelName,
  });
};

export const getSurveyHierarchyDataAct = (
  level,
  parentLevel,
  parentLevelID,
  icID,
) => dispatch => ajax
  .get('/maps/maps/locationHeirarchy_new', {
    params: {
      fetchLevel: level,
      parentLevel,
      parentLevelID,
      icID,
    },
    isLoader: 'false',
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: `GET_SURVEY_${level.toUpperCase()}_SUCCESS`,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: `GET_SURVEY_${level.toUpperCase()}_FAILURE`,
        payload: response.data.error,
      });
    }
  })
  .catch((error) => {
    dispatch({
      type: `GET_SURVEY_${level.toUpperCase()}_FAILURE`,
      payload: error,
    });
  });

export const getSurveyListAct = () => dispatch => ajax
  .get('/master/master/surveyList')
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'SURVEY_LIST_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'SURVEY_LIST_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'SURVEY_LIST_FAILURE',
      payload: err,
    });
  });

export const getProductListAct = () => dispatch => ajax
  .get('/master/master/productList')
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'PRODUCT_LIST_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'PRODUCT_LIST_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'PRODUCT_LIST_FAILURE',
      payload: err,
    });
  });

export const getAgentListAct = params => dispatch => ajax
  .get('/master/master/agentList', {
    params: {
      levelID: params.levelID,
      level: params.level,
    },
  })
  .then((response) => {
    console.log('response>>>>', response);
    if (response.data.status) {
      dispatch({ type: 'AGENT_LIST_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'AGENT_LIST_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'AGENT_LIST_FAILURE',
      payload: err,
    });
  });

export const setSurveySeasonAct = season => (dispatch) => {
  dispatch({
    type: 'SET_SURVEY_SEASON',
    payload: season,
  });
};

export const setSurveyYearAct = year => (dispatch) => {
  dispatch({
    type: 'SET_SURVEY_YEAR',
    payload: year,
  });
};

export const setSelectedSurveyAct = (surveyID, surveyName) => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_SURVEY',
    payload: {
      surveyID,
      surveyName,
    },
  });
};

export const setSurveyProductAct = (productID, productName) => (dispatch) => {
  dispatch({
    type: 'SET_SURVEY_PRODUCT',
    payload: {
      productID,
      productName,
    },
  });
};

export const setSurveyCropAct = (cropID, cropName) => (dispatch) => {
  dispatch({
    type: 'SET_SURVEY_CROP',
    payload: {
      cropID,
      cropName,
    },
  });
};

export const resetSurveyTab = () => (dispatch) => {
  dispatch({
    type: 'RESET_SURVEY_TAB',
    payload: null,
  });
};

/* ======================== DROPDOWN ACTION ENDS ===================== */

/* ======================== CCE ACTION STARTS ======================== */

export const getSurveyCropListAct = params => dispatch => ajax
  .get('/master/master/cceCropList', {
    params: {
      districtID: params.districtID,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'SURVEY_CROP_LIST_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'SURVEY_CROP_LIST_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'SURVEY_CROP_LIST_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseCCECountAct = params => dispatch => ajax
  .get('/survey/cce/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    console.log('response>>>>', response);
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const resetCCEDataAct = () => (dispatch) => {
  dispatch({
    type: 'RESET_CCE_SURVEY_DATA',
    payload: null,
  });
};

export const downloadCCEFileAct = params => dispatch => ajax
  .get('/survey/cce/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_CCE_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_CCE_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_CCE_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const uploadCCESurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/cce/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_CCE_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_CCE_SURVEY_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_CCE_SURVEY_FILE_FAILURE',
        payload: error,
      });
    });
};

export const getCCEMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_CCE_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_CCE_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_CCE_REPORT_FAILURE',
      payload: err,
    });
  });

export const getCCEExperimentListAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SURVEY_EXPERIMENT_LIST',
    payload: null,
  });
  ajax
    .get('/survey/cce/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getCCEExperimentDetails = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/cce/surveyDetails', {
      params: {
        experimentNumber,
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

export const misCCEReportlistAct = params => dispatch => ajax
  .get('/survey/mis/reportList', {
    params: { ...params },
  })
  .then((response) => {
    console.log('rr', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_REPORT_LIST_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'MIS_REPORT_LIST_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_REPORT_LIST_FAILURE',
      payload: err,
    });
  });

export const misCCEReportSubmitAct = data => dispatch => ajax
  .post('/survey/mis/submitReport', {
    data: { ...data },
  })
  .then((response) => {
    console.log('rr', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_REPORT_SUBMIT_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'MIS_REPORT_SUBMIT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_REPORT_SUBMIT_FAILURE',
      payload: err,
    });
  });

/* ======================== CCE ACTION ENDS ========================== */

/* ======================== CROP VERIFICATION ACTION STARTS ========== */

export const uploadCropVerificationSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/cropVerification/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_CROP_VERIFICATION_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_CROP_VERIFICATION_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_CROP_VERIFICATION_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadCropVerificationFileAct = params => dispatch => ajax
  .get('/survey/cropVerification/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_CROP_VERIFICATION_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_CROP_VERIFICATION_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_CROP_VERIFICATION_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseCropVerificationCountAct = params => dispatch => ajax
  .get('/survey/cropVerification/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getCropVerificationMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_CROP_VERIFICATION_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_CROP_VERIFICATION_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_CROP_VERIFICATION_REPORT_FAILURE',
      payload: err,
    });
  });

export const getInsuredCropExperimentListAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SURVEY_EXPERIMENT_LIST',
    payload: null,
  });
  ajax
    .get('/survey/cropVerification/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getInsuredCropExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/cropVerification/surveyDetails', {
      params: {
        experimentNumber,
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== CROP VERIFICATION ACTION ENDS ============ */

/* ======================== CROP LOSS SURVEY ACTION STARTS =========== */

export const uploadCropLossSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/cropLossAssessment/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_CROP_LOSS_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_CROP_LOSS_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_CROP_LOSS_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadCropLossFileAct = params => dispatch => ajax
  .get('/survey/cropLossAssessment/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_CROP_LOSS_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_CROP_LOSS_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_CROP_LOSS_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseCropLossCountAct = params => dispatch => ajax
  .get('/survey/cropLossAssessment/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getCropLossMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_CROP_LOSS_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_CROP_LOSS_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_CROP_LOSS_REPORT_FAILURE',
      payload: err,
    });
  });

export const getCropLossExperimentListAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SURVEY_EXPERIMENT_LIST',
    payload: null,
  });
  ajax
    .get('/survey/cropLossAssessment/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getCropLossExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/cropLossAssessment/surveyDetails', {
      params: {
        experimentNumber,
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== CROP LOSS SURVEY ACTION ENDS ============================ */

/* ======================== APPLICATION SOURCING ACTION STARTS ====================== */

export const uploadAppSourcingSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/applicationSourcing/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_SOURCING_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_SOURCING_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_SOURCING_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadAppSourcingFileAct = params => dispatch => ajax
  .get('/survey/applicationSourcing/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_SOURCING_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_SOURCING_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_SOURCING_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseApplicationSourcingCountAct = params => dispatch => ajax
  .get('/survey/applicationSourcing/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getAppSourcingMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_SOURCING_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_SOURCING_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_SOURCING_REPORT_FAILURE',
      payload: err,
    });
  });

export const getApplicationSourcingExperimentListAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SURVEY_EXPERIMENT_LIST',
    payload: null,
  });
  ajax
    .get('/survey/applicationSourcing/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getApplicationSourcingExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/applicationSourcing/surveyDetails', {
      params: {
        experimentNumber, /* : '0401272012345678000' */
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== APPLICATION SOURCING ACTION ENDS ====================== */

/* ======================== SOWING SURVEY ACTION STARTS =========================== */

export const uploadSowingSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/sowingSurvey/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_SOWING_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_SOWING_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_SOWING_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadSowingFileAct = params => dispatch => ajax
  .get('/survey/sowingSurvey/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_SOWING_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_SOWING_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_SOWING_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseSowingSurveyCountAct = params => dispatch => ajax
  .get('/survey/sowingSurvey/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getSowingMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_SOWING_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_SOWING_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_SOWING_REPORT_FAILURE',
      payload: err,
    });
  });

export const getSowingSurveyExperimentListAct = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SURVEY_EXPERIMENT_LIST',
    payload: null,
  });
  ajax
    .get('/survey/sowingSurvey/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getSowingSurveyExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/sowingSurvey/surveyDetails', {
      params: {
        experimentNumber, /* : '0401272012345678000', */
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== SOWING SURVEY ACTION ENDS =========================== */

/* ======================== WEATHER SURVEY ACTION STARTS ======================== */

export const uploadWeatherSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/weatherStationInspection/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_WEATHER_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_WEATHER_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_WEATHER_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadWeatherFileAct = params => dispatch => ajax
  .get('/survey/weatherStationInspection/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_WEATHER_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_WEATHER_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_WEATHER_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseWeatherSurveyCountAct = params => dispatch => ajax
  .get('/survey/weatherStationInspection/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getWeatherMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_WEATHER_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_WEATHER_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_WEATHER_REPORT_FAILURE',
      payload: err,
    });
  });

export const getWeatherStationExperimentListAct = params => (dispatch) => {
  ajax
    .get('/survey/weatherStationInspection/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getWeatherSurveyExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/weatherStationInspection/surveyDetails', {
      params: {
        experimentNumber,
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== WEATHER SURVEY ACTION ENDS ======================== */

/* ======================== CROP HEALTH INSPECTION START ====================== */

export const uploadCropHealthSurveyFileAct = bodyFormdata => (dispatch) => {
  ajax
    .post('/survey/cropHealth/allocationfileUpload', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_CROP_HEALTH_SURVEY_FILE_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_CROP_HEALTH_FILE_FAILURE',
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_CROP_HEALTH_FILE_FAILURE',
        payload: error,
      });
    });
};

export const downloadCropHealthFileAct = params => dispatch => ajax
  .get('/survey/cropHealth/allocationfileDownload', {
    params: { ...params },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'FILE_CROP_HEALTH_DOWNLOAD_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'FILE_CROP_HEALTH_DOWNLOAD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FILE_CROP_HEALTH_DOWNLOAD_FAILURE',
      payload: err,
    });
  });

export const getLocationWiseCropHealthSurveyCountAct = params => dispatch => ajax
  .get('/survey/cropHealth/locationWiseCount', {
    params: {
      levelID: params.levelID,
      level: params.level,
      year: params.year,
      season: params.season,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'LOCATION_WISE_COUNT_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'LOCATION_WISE_COUNT_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LOCATION_WISE_COUNT_FAILURE',
      payload: err,
    });
  });

export const getCropHealthMISReportAct = params => dispatch => ajax
  .get('survey/mis/downloadReport', {
    params: { ...params },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({
        type: 'MIS_CROP_HEALTH_REPORT_SUCCESS',
        payload: response.data,
      });
      const csvData = objectToCsv(response.data.data);
      download(csvData);
    } else {
      dispatch({
        type: 'MIS_CROP_HEALTH_REPORT_FAILURE',
        payload: response.data,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'MIS_CROP_HEALTH_FAILURE',
      payload: err,
    });
  });

export const getCropHealthExperimentListAct = params => (dispatch) => {
  ajax
    .get('/survey/cropHealth/surveyList', {
      params: { ...params },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SURVEY_EXPERIMENT_LIST_FAILURE',
        payload: err,
      });
    });
};

export const getCropHealthSurveyExperimentDetailsAct = experimentNumber => (dispatch) => {
  dispatch({
    type: 'EXPERIMENT_DETAIL_FETCHING',
    payload: experimentNumber,
  });
  ajax
    .get('/survey/cropHealth/surveyDetails', {
      params: {
        experimentNumber,
      },
      isLoader: true,
    })
    .then((response) => {
      // console.log('response>>>>', response);
      if (response.data.status) {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'EXPERIMENT_DETAIL_FETCH_FAILURE',
        payload: err,
      });
    });
};

/* ======================== CROP HEALTH INSPECTION ENDS ====================== */
