/* eslint-disable */
import React from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Button,
  Switch
} from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MultiSelect from 'react-select';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import BarChartComponent from '../barChart';
import { TextWithGraphCarousel } from '../../common/TextCarouselHOC';
import {
  fetchCropsAct,
  fetchCropCoverageAct,
  fetchCropCoverageChangeAct,
  resetCoverageDataAct,
  fetchCoverageReportAct,
  fetchCoverageReportCompareAct
} from '../../../Actions/coverageAction';
import '../../../css/agricultureIndices.scss';

const options = [
  { value: 'totalSumInsured', label: 'Sum Insured', color: '#00b0ff' },
  { value: 'totalPremium', label: 'Premium Amt.', color: '#9013fe' }
];

const stackBarchartOption = [
  { value: 'farmerPremium', label: 'Farms', color: '#7ed321' },
  { value: 'statePremium', label: 'State', color: '#9013fe' },
  { value: 'centerPremium', label: 'GOI', color: '#00b0ff' }
];

class CoverageSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenerate: false,
      expandPanel: false,
      isCompare: false,
      seasonSelected: '',
      cropSelected: '',
      error: '',
      districtOptions: [],
      selectedDistrictOptions: [],
      compareDistrictOptions: [],
      coverageOptions: [
        { value: 'totalSumInsured', label: 'Sum Insured', color: '#00b0ff' },
        { value: 'totalPremium', label: 'Premium Amt.', color: '#9013fe' }
      ],
      year: ''
    };
  }

  componentDidMount() {
    this.updateDistrictOptions();
  }

  componentDidUpdate(prevProps) {
    const { districts } = this.props;
    if (prevProps.districts.length === 0 && districts.length > 0) {
      this.updateDistrictOptions();
    }
  }

  updateDistrictOptions = () => {
    const { districts } = this.props;
    const newData = districts.map(
      ({ districtID: value, districtName: label }) => ({ value, label })
    );
    this.setState({
      districtOptions: newData
    });
  };

  getCoverageData = () => {
    const { fetchCoverageReport, selectedDistrictID } = this.props;
    const { cropSelected, year, selectedDistrictOptions } = this.state;
    let newArray = [];
    if (selectedDistrictOptions && selectedDistrictOptions.length > 0) {
      newArray = selectedDistrictOptions.reduce(
        (item, index) => item.concat(index.value),
        []
      );
    }
    const data = {
      locationID: [selectedDistrictID, ...newArray],
      year,
      fetchLevel: 'DISTRICT',
      cropID: cropSelected
    };
    fetchCoverageReport(data);
  };

  getCompareCoverageData = () => {
    const { fetchCompareCoverageData, selectedDistrictID } = this.props;
    const { cropSelected, year, compareDistrictOptions } = this.state;
    let newArray = [];
    if (compareDistrictOptions && compareDistrictOptions.length > 0) {
      newArray = compareDistrictOptions.reduce(
        (item, index) => item.concat(index.value),
        []
      );
    }
    const data = {
      locationID: [selectedDistrictID, ...newArray],
      year,
      fetchLevel: 'DISTRICT',
      cropID: cropSelected
    };
    fetchCompareCoverageData(data);
  };

  _generateData = () => {
    const {
      selectedStateID,
      selectedDistrictID,
      // selectedTehsilID,
      // fetchCropCoverage,
      // fetchCropCoverageChange,
      cookies,
      history
      // fetchCoverageReport
    } = this.props;
    const { seasonSelected, cropSelected, year } = this.state;
    const userData = cookies.get('userData');
    if (userData) {
      if (selectedStateID && selectedDistrictID) {
        /* if (selectedTehsilID) {
          // Fetch For Selected Tehsil
          if (seasonSelected && cropSelected) {
            this.setState({
              isGenerate: true,
              error: '',
            });
            fetchCropCoverage(
              {
                seasonName: seasonSelected,
                levelNumber: 4,
                levelID: selectedTehsilID.toUpperCase(),
                cropID: cropSelected,
              },
              userData.token,
              history,
              cookies,
            );
            fetchCropCoverageChange(
              {
                seasonName: seasonSelected,
                levelNumber: 4,
                levelID: selectedTehsilID.toUpperCase(),
                cropID: cropSelected,
              },
              userData.token,
              history,
              cookies,
            );
          } else {
            // Select Season & Crop
            this.setState({
              error: 'Select Season Or Crop',
            });
          }
        } else {
          // Fetch For Selected District
          console.log('Inside If');
        } */
        if (seasonSelected && cropSelected && year) {
          this.setState({
            isGenerate: true,
            error: ''
          });
          this.getCoverageData();
          // fetchCropCoverage(
          //   {
          //     seasonName: seasonSelected,
          //     districtIDs: JSON.stringify([selectedDistrictID.toUpperCase()]),
          //     cropID: cropSelected,
          //     year,
          //   },
          //   userData.token,
          //   history,
          //   cookies,
          // );
        } else {
          // Select Season & Crop
          this.setState({
            error: 'Select Season Or Crop'
          });
        }
      } else {
        // Select State & District
        this.setState({
          error: 'Select State, District'
        });
      }
    } else {
      // Logout
      cookies.remove('userData', null);
      cookies.remove('loggedInTimeStamp', null);
      history.push('/');
    }
  };

  _resetData = e => {
    e.preventDefault();
    const { resetCoverageData } = this.props;
    this.setState({
      isGenerate: false,
      selectedDistrictOptions: [],
      compareDistrictOptions: []
    });
    resetCoverageData();
  };

  _handleParameterChange = value => {
    this.setState({
      coverageOptions: value
    });
  };

  _handleSeasonChange = e => {
    const {
      selectedStateID,
      selectedDistrictID,
      fetchCrops,
      cookies,
      history
    } = this.props;
    this.setState({
      seasonSelected: e.target.value
    });
    const userData = cookies.get('userData');
    if (userData) {
      if (selectedStateID) {
        if (selectedDistrictID) {
          fetchCrops(
            {
              seasonName: e.target.value,
              districtID: selectedDistrictID.toUpperCase()
            },
            userData.token,
            history,
            cookies
          );
        } else {
          // Set Error State to Select District
        }
      } else {
        // Set Error State to Select State
      }
    } else {
      // Logout
      cookies.remove('userData', null);
      cookies.remove('loggedInTimeStamp', null);
      history.push('/');
    }
  };

  _handleCropChange = e => {
    this.setState({
      cropSelected: e.target.value
    });
  };

  _handleMultiChange = value => {
    this.setState(
      {
        selectedDistrictOptions: value
      },
      () => {
        this.getCoverageData();
      }
    );
  };

  _handleCompareDistrictChange = value => {
    this.setState(
      {
        compareDistrictOptions: value
      },
      () => {
        this.getCompareCoverageData();
      }
    );
  };

  _handleComparison = event => {
    const { isCompare } = this.state;
    this.setState({
      isCompare: !isCompare
    });
    if (event.target.checked) {
      this.getCompareCoverageData();
    } else {
      this.setState({
        compareDistrictOptions: []
      });
    }
  };

  _handleYearChange = (e, type) => {
    this.setState(
      {
        year: e.target.value
      },
      () => {
        if (type === 'inner') {
          this.getCoverageData();
        }
      }
    );
  };

  render() {
    const {
      isGenerate,
      expandPanel,
      seasonSelected,
      cropSelected,
      error,
      year,
      coverageOptions,
      isCompare,
      districtOptions,
      selectedDistrictOptions,
      compareDistrictOptions
    } = this.state;
    const {
      broadcastLocationChange,
      cropData,
      // cropCoverage,
      states,
      districts,
      tehsils,
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
      changeInCropCoverage,
      coverageReportData,
      compareCoverageData
    } = this.props;
    let seasonHtml = [];
    let districtMultiSelectOption = [];
    districtMultiSelectOption = districtOptions.filter(
      item => item.value !== selectedDistrictID
    );
    seasonHtml = [
      { label: 'Kharif', value: 'kharif' },
      { label: 'Rabi', value: 'rabi' }
      // { label: 'Zaid', value: 3 },
    ].map(item => (
      <MenuItem value={item.value} key={item.label}>
        {item.label}
      </MenuItem>
    ));
    const cropsHtml = [];
    if (cropData.length) {
      cropData.forEach(item => {
        cropsHtml.push(
          <MenuItem value={item.cropID} key={item.cropID}>
            {item.cropName}
          </MenuItem>
        );
      });
    }
    let stateName = '';
    let districtName = '';
    let tehsilName = '';
    let cropName = '';
    if (isGenerate) {
      if (selectedStateID) {
        const stateObj = states.find(o => o.stateID === selectedStateID);
        if (stateObj) {
          stateName = stateObj.stateName;
        }
      }
      if (selectedDistrictID) {
        const districtObj = districts.find(
          o => o.districtID === selectedDistrictID
        );
        if (districtObj) {
          districtName = districtObj.districtName;
        }
      }
      if (selectedTehsilID) {
        const tehsilObj = tehsils.find(o => o.tehsilID === selectedTehsilID);
        if (tehsilObj) {
          tehsilName = tehsilObj.tehsilName;
        }
      }
      if (cropSelected) {
        const cropObj = cropData.find(o => o.cropID === cropSelected);
        if (cropObj) {
          cropName = cropObj.cropName;
        }
      }
    }
    const textCarouselData = [];
    if (!_.isEmpty(changeInCropCoverage)) {
      textCarouselData.push({
        label: 'Total Premium',
        value: changeInCropCoverage.totalApplication,
        graphData: [],
        yAxisDataKey: '',
        xAxisDataKey: '',
        updatedSinceString: 'Compared to Past Year',
        isFooterDataAvailable: true,
        alertData: 'hello',
        footerData: [
          {
            label: 'Insured Area',
            value: changeInCropCoverage.sumInsured
          },
          {
            label: 'Premium Amount',
            value: changeInCropCoverage.totalAreaInsured
          }
        ]
      });
    }

    return (
      <div>
        {isGenerate ? (
          <div className="indicesMainPanel">
            <div
              className={
                expandPanel ? 'indicesList expandPanel' : 'indicesList'
              }
            >
              {/* <div className=""> */}
              <Grid container>
                <Grid item xs={6}>
                  <h4>Coverage report</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>
                    <a href="/" onClick={this._resetData}>
                      Edit Selection
                    </a>
                  </h4>
                </Grid>
              </Grid>
              <ul>
                <li>{stateName}</li>
                <li>{districtName}</li>
                {/*<li>{tehsilName}</li>*/}
                <li>{seasonSelected}</li>
                <li>{cropName}</li>
              </ul>
              <TextWithGraphCarousel data={textCarouselData} />
              {/* <Grid
                container
                className="indicesList bg-gray"
                style={{ borderBottom: 'solid 1px #d7d7d7' }}
              >
                <Grid item xs={9} className="indicesList bg-gray">
                  <h4>Target farmers in the region through farmguide</h4>
                </Grid>
                <Grid item xs={3} className="indicesList bg-gray">
                  <h4>
                    <a href="/">Lead Generator</a>
                  </h4>
                </Grid>
              </Grid> */}
              <Grid
                container
                className="indicesList bg-sky-Palette"
                style={{ borderBottom: 'solid 1px #d7d7d7' }}
              >
                <Grid item xs={12}>
                  <h4>
                    Target farmers in the region through farmguide{' '}
                    <span>
                      <a
                        href="/"
                        style={{ pointerEvents: 'none', cursor: 'default' }}
                      >
                        Lead Generator
                      </a>
                    </span>
                  </h4>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="indicesList bg-gray">
                  <h4>Graphical Analysis</h4>
                </Grid>
              </Grid>
            </div>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <Grid container>
                  <Grid item lg={2} md={2} xs={12} />
                  <Grid item lg={4} md={4} xs={12}>
                    <FormControl className="formControl inputHead coloredForms inputFullWidth">
                      <Select
                        disableUnderline
                        inputProps={{ 'aria-label': 'age' }}
                        className="selectEmpty modalDropdown"
                        value={year}
                        displayEmpty
                        onChange={ev => this._handleYearChange(ev, 'inner')}
                      >
                        <MenuItem value="">Year</MenuItem>
                        <MenuItem value="2018">2018</MenuItem>
                        <MenuItem value="2019">2019</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <FormControl className="formControl inputHead coloredForms multiSelect inputFullWidth">
                      <MultiSelect
                        isMulti
                        name="user"
                        options={
                          selectedDistrictOptions &&
                          selectedDistrictOptions.length <= 6
                            ? districtMultiSelectOption
                            : selectedDistrictOptions === null
                            ? districtMultiSelectOption
                            : []
                        }
                        onChange={ev => this._handleMultiChange(ev)}
                        value={selectedDistrictOptions}
                        className="selectInner"
                        classNamePrefix="select"
                        placeholder="Select any 7"
                        style={{ padding: '0px' }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="rechartsPanel">
                  <h1>Number of Application</h1>
                  <div className="line-chart">
                    <BarChartComponent
                      data={coverageReportData}
                      yAxisLabel="Count"
                      dataKey="noOfApplication"
                      showToolTip
                      xAxisDataKey="locationName"
                      compareBarChart={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} className="panel-border" />
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="rechartsPanel">
                  <h1>Total Area Insured</h1>
                  <div className="line-chart">
                    <BarChartComponent
                      data={coverageReportData}
                      yAxisLabel="Count"
                      dataKey="totalAreaInsured"
                      xAxisDataKey="locationName"
                      showToolTip
                      compareBarChart={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} className="panel-border" />
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="rechartsPanel">
                  <h1>Total Sum Insured</h1>
                  <div className="line-chart">
                    <BarChartComponent
                      data={coverageReportData}
                      yAxisLabel="Count"
                      dataKey="totalSumInsured"
                      xAxisDataKey="locationName"
                      showToolTip
                      compareBarChart={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} className="panel-border" />
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="rechartsPanel">
                  <h1>Total Premium Amount</h1>
                  <div className="line-chart">
                    <BarChartComponent
                      data={coverageReportData}
                      yAxisLabel="Count"
                      dataKey=""
                      xAxisDataKey="locationName"
                      showToolTip
                      compareBarChart={false}
                      stackBarchart={stackBarchartOption}
                      isStackBarChart
                    />
                  </div>
                  <ul className="legendMain">
                    {stackBarchartOption && stackBarchartOption.length > 0
                      ? stackBarchartOption.map(item => (
                          <li key={Math.random()}>
                            <i style={{ backgroundColor: item.color }} />
                            <span>{item.label}</span>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
            </div>
            <Grid container className="indicesList bg-gray">
              <Grid item xs={12} className="indicesList bg-gray">
                <h4>
                  Need More Precision ?{' '}
                  <span>
                    <a
                      href="/"
                      style={{ pointerEvents: 'none', cursor: 'default' }}
                    >
                      Upload ground truth points
                    </a>
                  </span>
                </h4>
              </Grid>
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                {/*  <div className="panelDivision rechartsPanel"> */}
                <div className="comparisonPanel">
                  Comparison
                  <Switch
                    value="indicesComparision"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={isCompare}
                    onChange={this._handleComparison}
                  />
                  <br />
                  {isCompare && (
                    <React.Fragment>
                      {/* Coming Soon */}
                      <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <FormControl
                            className="formControl inputHead coloredForms multiSelect inputFullWidth"
                            style={{ marginRight: '5px' }}
                          >
                            <MultiSelect
                              isMulti
                              name="user"
                              options={
                                compareDistrictOptions &&
                                compareDistrictOptions.length <= 2
                                  ? districtMultiSelectOption
                                  : compareDistrictOptions === null
                                  ? districtMultiSelectOption
                                  : []
                              }
                              onChange={ev =>
                                this._handleCompareDistrictChange(ev)
                              }
                              value={compareDistrictOptions}
                              className="selectInner"
                              classNamePrefix="select"
                              placeholder="Select any 3"
                              style={{ padding: '0px' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <FormControl className="formControl inputHead coloredForms multiSelect inputFullWidth">
                            <MultiSelect
                              isMulti
                              name="user"
                              options={options}
                              onChange={ev => this._handleParameterChange(ev)}
                              value={coverageOptions}
                              className="selectInner"
                              classNamePrefix="select"
                              placeholder="Parameters"
                              style={{ padding: '0px' }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <BarChartComponent
                        data={compareCoverageData}
                        yAxisLabel="Count"
                        dataKey=""
                        xAxisDataKey="locationName"
                        showToolTip
                        compareBarChart={
                          coverageOptions &&
                          coverageOptions.length &&
                          coverageOptions.length > 0
                            ? coverageOptions
                            : false
                        }
                      />
                      <div className="indicesScrollableLink">
                        <div className="indicesScrollBlock selectController">
                          <div
                            className="rechartsPanel"
                            style={{ float: 'none' }}
                          >
                            <ul
                              className="legendMain"
                              style={{ marginRight: '-15px' }}
                            >
                              {coverageOptions && coverageOptions.length > 0
                                ? coverageOptions.map(item => (
                                    <li key={Math.random()}>
                                      <i
                                        style={{ backgroundColor: item.color }}
                                      />
                                      <span>{item.label}</span>
                                    </li>
                                  ))
                                : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
            <Grid container className="indicesList bg-gray">
              <Grid item xs={12} className="indicesList bg-gray">
                <h4>
                  Alert:{' '}
                  {/* with every application created in District 2, there is
                  a risk of &gt; 70% Claims. */}
                  <a
                    href="/"
                    style={{ pointerEvents: 'none', cursor: 'default' }}
                  >
                    See how to mitigate
                  </a>
                </h4>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="agriIndices selectController">
            <h4 className="tabpanelHeading">Coverage Report</h4>
            <Grid item xs={12}>
              <LocationHeirarchy
                type="selectEmpty modalDropdown"
                calledFrom="dashboard"
                hideTehsil
                hideVillage
                broadcastLocationChange={broadcastLocationChange}
              />
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value={seasonSelected}
                  displayEmpty
                  onChange={this._handleSeasonChange}
                >
                  <MenuItem value="" key={Math.random()}>
                    Season
                  </MenuItem>
                  {seasonHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value={cropSelected}
                  displayEmpty
                  onChange={this._handleCropChange}
                >
                  <MenuItem value="" key={Math.random()}>
                    Crop
                  </MenuItem>
                  {cropsHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value={year}
                  displayEmpty
                  onChange={ev => this._handleYearChange(ev, 'outer')}
                >
                  <MenuItem value="">Year</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6} className="agriIndices btn">
                <Button
                  type="submit"
                  variant="contained"
                  className="button btn-success"
                  onClick={this._generateData}
                >
                  Generate Data
                  <i className="material-icons">arrow_right_alt</i>
                </Button>
                {error ? <p className="errorMsg"> {error} </p> : null}
              </Grid>
            </Grid>
          </div>
        )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ coverage, location }) => ({
      cropData: coverage.cropData,
      cropCoverage: coverage.cropCoverage,
      selectedStateID: location.selectedStateID,
      selectedDistrictID: location.selectedDistrictID,
      selectedTehsilID: location.selectedTehsilID,
      states: location.state,
      districts: location.district,
      tehsils: location.tehsil,
      changeInCropCoverage: coverage.changeInCropCoverage,
      coverageReportData: coverage.coverageReportData,
      compareCoverageData: coverage.compareCoverageData
    }),
    dispatch => ({
      fetchCrops: (params, token, history, cookies) => {
        dispatch(fetchCropsAct(params, token, history, cookies));
      },
      fetchCropCoverage: (params, token, history, cookies) => {
        dispatch(fetchCropCoverageAct(params, token, history, cookies));
      },
      fetchCropCoverageChange: (params, token, history, cookies) => {
        dispatch(fetchCropCoverageChangeAct(params, token, history, cookies));
      },
      resetCoverageData: () => dispatch(resetCoverageDataAct()),
      fetchCoverageReport: data => dispatch(fetchCoverageReportAct(data)),
      fetchCompareCoverageData: data =>
        dispatch(fetchCoverageReportCompareAct(data))
    })
  )(withCookies(CoverageSelectTab))
);
