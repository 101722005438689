import React from 'react';

const CardPlayerView = (props) => {
  const { handlePlayMiniPlayer, handleStopMiniPlayer, isMediaPlaying } = props;
  return (
    <React.Fragment>
      {!isMediaPlaying ? (
        <span
          onClick={handlePlayMiniPlayer}
          onKeyDown={handlePlayMiniPlayer}
          role="button"
          tabIndex="0"
        >
          <i
            className="material-icons customPlayIcon"
            style={{ cursor: 'pointer' }}
          >
            play_circle_outline
          </i>
        </span>
      ) : (
        <span
          onClick={handleStopMiniPlayer}
          onKeyDown={handleStopMiniPlayer}
          role="button"
          tabIndex="0"
        >
          <i
            className="material-icons customPlayIcon"
            style={{ cursor: 'pointer' }}
          >
            pause_circle_outline
          </i>
        </span>
      )}
    </React.Fragment>
  );
};

export default CardPlayerView;
