import React, { Component } from 'react';
import {
  Grid, Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import ModalHOC from '../common/Modal/index';
import '../../css/tagging/fileUploadModal.scss';

class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleFileChange = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  handleUpload = () => {
    const { uploadHandler } = this.props;
    uploadHandler();
  };

  handleUploadAgain = () => {
    const { uploadAgain } = this.props;
    uploadAgain();
  };

  handleConfirm = () => {
    const { onClose, confirmUpload } = this.props;
    // uploadAgain();
    confirmUpload();
    onClose();
  };

  handleChange = () => {
    const { handleUploadCheckbox } = this.props;
    handleUploadCheckbox();
  };

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  beforeUpload = () => (
    <Grid container spacing={2} className="customizeModal">
      <Grid item lg={2} />
      <Grid item lg={10}>
        <Button className="upload-btn" onClick={this.handleClose}>
          Discard
        </Button>
        <Button className="upload-btn" onClick={this.handleUpload}>
          Upload
        </Button>
      </Grid>
    </Grid>
  );

  afterUpload = () => {
    const { farmersUploadError, farmersUploaded } = this.props;
    return (
      <Grid container spacing={2} className="customizeModal">
        <Grid item lg={2} />
        <Grid item lg={10}>
          {farmersUploadError === '' && farmersUploaded ? (
            <Button className="upload-btn" onClick={this.handleConfirm}>
              Confirm
            </Button>
          ) : (
            <Button className="upload-btn" onClick={this.handleCancel}>
              Cancel
            </Button>
          )}
          <Button className="upload-btn" onClick={this.handleUploadAgain}>
            Upload Again
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      show,
      uploaded,
      uploadCheckbox,
      uploadError,
      farmersUploadError,
      farmersUploaded,
    } = this.props;
    return (
      <div>
        <ModalHOC
          isOpen={show}
          onClose={this.handleClose}
          titleContent="Upload your farmers"
          footerComponent={!uploaded ? this.beforeUpload() : this.afterUpload()}
          customContent="upload-farmer-modal"
        >
          {!uploaded ? (
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <div className="upload-container">
                  <h5>Upload your file</h5>
                  <div className="dropzone format">
                    <input
                      type="file"
                      className="select-file"
                      accept=".csv,.xlsx"
                      onChange={this.handleFileChange}
                    />
                  </div>
                </div>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={uploadCheckbox}
                      value="checkedB"
                      color="primary"
                      onChange={this.handleChange}
                    />
)}
                  label="Acknowledgment about the message doesn’t carry any unwanted things, or abusive words etc"
                  className="acknowledgeCheckbox"
                />
                {uploadError ? (
                  <p
                    className="errorMsg1"
                    style={{
                      marginBottom: '2px',
                      textAlign: 'center',
                      marginTop: '0px',
                    }}
                  >
                    {uploadError}
                  </p>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} className="customizeModal">
              <Grid item lg={12}>
                <div className="uploaded-message">
                  <img src="/images/folder.svg" alt="left-loader" />
                  {farmersUploadError === '' && farmersUploaded ? (
                    <Grid container>
                      <Grid item lg={4} />
                      <Grid item lg={1}>
                        <h6>
                          <i className="material-icons tickIcon">
                            check_circle_outline
                          </i>
                        </h6>
                      </Grid>
                      <Grid item lg={3}>
                        <h6>
                          <span>File Successfully uploaded</span>
                        </h6>
                      </Grid>
                      <Grid item lg={4} />
                    </Grid>
                  ) : null}
                </div>
              </Grid>
              {farmersUploadError !== '' ? (
                <Grid container>
                  <Grid item lg={9} className="upload-message-left">
                    <p>
                      {farmersUploadError}
.
                      {/*  <span>confirm</span> */}
                    </p>
                  </Grid>
                  <Grid item lg={3} className="upload-message-right">
                    <p>
                      <span>Download error file</span>
                    </p>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          )}
        </ModalHOC>
      </div>
    );
  }
}

export default UploadModal;
