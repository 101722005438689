import ajax from '../Functions/ajax';

export const getCropsInCropMapsAct = params => (dispatch) => {
  ajax
    .get('/maps/maps/cropInCropMaps', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'GET_CROPS_IN_CROPMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GET_CROPS_IN_CROPMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CROPS_IN_CROPMAP_FAILURE',
        payload: error,
      });
    });
};

export const getCropMapParameter = () => (dispatch) => {
  ajax
    .get('/maps/maps/cropMapParameter', {
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        // const data = response.data.data.map((item) => {
        //   if (item.label) {
        //     //
        //     item.label = item.label.replace(/_([a-z])/g, g => g[1].toUpperCase());
        //   }
        //   return item;
        // });
        dispatch({
          type: 'CROP_MAP_PARAMETER_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CROP_MAP_PARAMETER_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CROP_MAP_PARAMETER_FAILURE',
        payload: error,
      });
    });
};

export const getCropMap = params => (dispatch) => {
  ajax
    .get('/maps/maps/cropMap', {
      params,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'CROP_MAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CROP_MAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CROP_MAP_FAILURE',
        payload: error,
      });
    });
};

export const indicesSpi = data => (dispatch) => {
  ajax
    .post('/services/services/spiIndex', {
      data: {
        level: data.level,
        month: data.month,
        stateID: data.stateID,
        districtID: data.districtID,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'INDICES_SPI_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'INDICES_SPI_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'INDICES_SPI_DATA_FAILURE',
        payload: error,
      });
    });
};

function modifyData(data, key) {
  const obj = {};
  data.forEach((item) => {
    if (obj[item.tehsilID]) {
      obj[item.tehsilID][item[key]] = item.totalAcreage;
    } else {
      //
      const a = item[key];
      obj[item.tehsilID] = {
        name: item.tehsilName,
        [a]: item.totalAcreage,
        acreageUnit: item.acreageUnit,
      };
    }
  });
  return obj;
}

export const fetchCropMapGraphAct = params => (dispatch) => {
  ajax
    .get('/maps/maps/cropMapGraph', {
      params,
      isLoader: 'false',
    })
    .then((response) => {
      if (response.data.status) {
        if (params.parameterName === 'crop_health') {
          let data = [];

          data = Object.values(modifyData(response.data.data, 'cropHealth'));

          dispatch({
            type: 'GET_CROPS_IN_CROPMAPGRAPH_SUCCESS',
            payload: data,
          });
        } else if (params.parameterName === 'avg_ndvi_peak') {
          let data = [];

          data = Object.values(
            modifyData(response.data.data, 'avgPeakNdviRange'),
          );

          dispatch({
            type: 'GET_CROPS_IN_CROPMAPGRAPH_SUCCESS',
            payload: data,
          });
        }
      } else {
        dispatch({
          type: 'GET_CROPS_IN_CROPMAPGRAPH_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CROPS_IN_CROPMAPGRAPH_FAILURE',
        payload: error,
      });
    });
};

export function emptyCropMapGraphDataAct() {
  return {
    type: 'GET_CROPS_IN_CROPMAPGRAPH_FAILURE',
    payload: '',
  };
}
