import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
// import { Link } from 'react-router-dom';
// import { FormControl, MenuItem, Select } from '@material-ui/core';
// import TextCarousel from '../../common/textCarousel';
import ScrollableTabPanel from '../../common/ScrollableTabPanel';
import AgricultureIndicesSelect from './agricultureIndicesSelect';
import AgriIndiceInputCoord from './agriIndicesInputCoord';
import AgriIndicesSearch from './agriIndicesSearch';
import '../../../css/tabPanel.scss';
import '../../../css/common/calendar.scss';
import { config } from '../../../config';
import {
  clearCompareIndicesImagesAct,
  clearIndicesImagesAct,
  fetchCompareIndicesImagesAct,
  fetchIndicesImagesAct,
  getIndicesLegends,
  getIndicesUpdateAction,
  getSourceAction,
  getTimeSeriesChangeDataAct,
  getTimeSeriesDataAct,
  resetNDVIAction,
  updateBoundsAction,
  updateBoundsComparisionAction,
} from '../../../Actions/agricultureIndicesAction';

// import LineChartComponent from '../lineChart';
// import 'react-dates/initialize';
// import { SingleDatePicker } from 'react-dates';
// import moment from 'moment';
// import 'react-dates/lib/css/_datepicker.css';

class AgricultureIndices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndicesTab: 0,
    };
  }

  broadcastIndicesTabChange = (tab) => {
    const { activeIndicesTab } = this.state;
    if (activeIndicesTab !== tab) {
      const { getMapClassReference, getMapReference, resetNDVI } = this.props;
      const levels = ['level0', 'level1', 'level2', 'level3'];
      const classRef = getMapClassReference();
      const map = getMapReference();
      this.setState({
        activeIndicesTab: tab,
      });
      if (classRef && map) {
        resetNDVI();
        if (map) {
          if (map.getLayer('wms')) {
            map.removeLayer('wms');
            map.removeSource('wms');
          }
        }
        switch (tab) {
          case 0:
            classRef.changeMapStyle({
              styleURL: config.mapboxConfig.defaultStyle,
              segmentedView: true,
              cropMapView: false,
            });
            levels.forEach((level) => {
              map.setLayoutProperty(`${level}Fill`, 'visibility', 'visible');
              map.setLayoutProperty(`${level}Line`, 'visibility', 'visible');
            });
            break;

          case 1:
            classRef.changeMapStyle({
              styleURL: config.mapboxConfig.defaultStyle,
              segmentedView: false,
              cropMapView: false,
            });
            levels.forEach((level) => {
              map.setLayoutProperty(`${level}Fill`, 'visibility', 'none');
              map.setLayoutProperty(`${level}Line`, 'visibility', 'none');
            });
            break;

          case 2:
            classRef.changeMapStyle({
              styleURL: config.mapboxConfig.defaultStyle,
              segmentedView: false,
              cropMapView: false,
            });
            break;

          default:
            classRef.changeMapStyle({
              styleURL: config.mapboxConfig.defaultStyle,
              segmentedView: true,
              cropMapView: false,
            });
            break;
        }
      }
    }
  };

  render() {
    const { activeIndicesTab } = this.state;
    const tabsData = [
      {
        tabName: 'Select',
        component: <AgricultureIndicesSelect {...this.props} />,
      },
      {
        tabName: 'Input',
        // disabled: true,
        component: <AgriIndiceInputCoord {...this.props} />,
      },
      {
        tabName: 'Search',
        // disabled: true,
        component: <AgriIndicesSearch />,
      },
    ];
    return (
      <ScrollableTabPanel
        tabsData={tabsData}
        activeTab={activeIndicesTab}
        customStyle="innerTabPanel"
        broadcastTabChange={this.broadcastIndicesTabChange}
      />
    );
  }
}

export default withRouter(
  connect(
    ({ agricultureIndices, location, landing }) => ({
      agricultureIndicesTimeline: agricultureIndices.agricultureIndicesTimeline,
      bounds: agricultureIndices.bounds,
      indicesValue: agricultureIndices.indicesValue,
      compareBounds: agricultureIndices.compareBounds,
      selectedCompareFarm: agricultureIndices.selectedCompareFarm,
      location,
      agricultureIndicesTimelineComparison:
        agricultureIndices.agricultureIndicesTimelineComparison,
      satSource: agricultureIndices.satSource,
      satellitesSource: agricultureIndices.satellitesSource,
      sourceIndice: agricultureIndices.sourceIndice,
      indicesChange: agricultureIndices.indicesChange,
      selectedFarm: agricultureIndices.selectedFarm,
      indicesImages: agricultureIndices.indicesImages,
      indicesTimeLineError: agricultureIndices.indicesTimeLineError,
      levelStatsFetching: landing.levelStatsFetching,
      timeseriesData: agricultureIndices.timeseriesData,
      timeseriesDataChange: agricultureIndices.timeseriesDataChange,
      indicesTimeline: agricultureIndices.indicesTimeline,
      indicesTimelineComparison: agricultureIndices.indicesTimelineComparison,
    }),
    dispatch => ({
      updateBounds: (
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        farmid,
      ) => {
        dispatch(
          updateBoundsAction(
            bounds,
            indicesValue,
            satellitesSource,
            startDate,
            endDate,
            farmid,
          ),
        );
      },
      updateBoundsComparision: (
        bounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparision,
        endDateComparision,
      ) => {
        dispatch(
          updateBoundsComparisionAction(
            bounds,
            indicesValueComparision,
            satellitesSource,
            startDateComparision,
            endDateComparision,
          ),
        );
      },
      resetNDVI: () => dispatch(resetNDVIAction()),
      getIndicesUpdate: (satellitesSource, indicesValue, bounds) => dispatch(
        getIndicesUpdateAction(satellitesSource, indicesValue, bounds),
      ),
      getSatSource: () => dispatch(getSourceAction()),
      fetchIndicesImages: (
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        selectedFarm,
      ) => {
        dispatch(
          fetchIndicesImagesAct(
            bounds,
            indicesValue,
            satellitesSource,
            startDate,
            endDate,
            selectedFarm,
          ),
        );
      },
      clearIndicesImages: () => dispatch(clearIndicesImagesAct()),
      fetchCompareIndicesImages: (
        compareBounds,
        indicesValueComparision,
        satellitesSource,
        startDateComparison,
        endDateComparison,
        selectedCompareFarm,
      ) => {
        dispatch(
          fetchCompareIndicesImagesAct(
            compareBounds,
            indicesValueComparision,
            satellitesSource,
            startDateComparison,
            endDateComparison,
            selectedCompareFarm,
          ),
        );
      },
      clearCompareIndicesImages: () => dispatch(clearCompareIndicesImagesAct()),
      getTimeSeriesData: (data, flag, flag1) => dispatch(getTimeSeriesDataAct(data, flag, flag1)),
      getTimeSeriesChangeData: data => dispatch(getTimeSeriesChangeDataAct(data)),
      getLegendsData: indice => dispatch(getIndicesLegends(indice, false)),
    }),
  )(withCookies(AgricultureIndices)),
);
