import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';

class DateRangeClone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  componentDidMount() {
    const { showDefaultDate } = this.props;
    this.setState({
      startDate: showDefaultDate ? moment().subtract(4, 'months') : null,
      endDate: showDefaultDate ? moment().subtract(2, 'months') : null,
    });
  }

  handleDateChange = ({ startDate, endDate }) => {
    const {
      getDate, comparison, getDateComparison, clearDates,
    } = this.props;
    if (!startDate && !endDate) {
      clearDates();
    }
    let startDateFormatted;
    let endDateFormatted;
    if (startDate) {
      startDateFormatted = startDate._d
        .toLocaleDateString('en-IN')
        .split('/')
        .join('-');
    }
    if (endDate) {
      endDateFormatted = endDate._d
        .toLocaleDateString('en-IN')
        .split('/')
        .join('-');
    }
    this.setState({
      startDate,
      endDate,
    });
    if (startDate && endDate && comparison) getDateComparison(startDateFormatted, endDateFormatted);
    else if (startDate && endDate) getDate(startDateFormatted, endDateFormatted);
  };

  handleFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  isOutsideRange = () => false;

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    return (
      <div className="datePickerPanel">
        <h5>Date Range</h5>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate"
          endDate={endDate}
          endDateId="endDate"
          focusedInput={focusedInput}
          isOutsideRange={this.isOutsideRange} // for showing previous dates
          onDatesChange={this.handleDateChange}
          onFocusChange={this.handleFocusChange}
          startDatePlaceholderText="Start Date"
          endDatePlaceholderText="End Date"
          readOnly
          showDefaultInputIcon // calendar icon
          showClearDates={!!(startDate && endDate)} // clear icon cross
          hideKeyboardShortcutsPanel // hide question icon
          inputIconPosition="after"
          displayFormat="DD/MM/YY"
          small
          withPortal
        />
      </div>
    );
  }
}

export default DateRangeClone;
