import ajax from '../Functions/ajax';

export const getInsightPoster = params => (dispatch) => {
  dispatch({
    type: 'DATA_FETCHING',
    payload: true,
  });
  ajax
    .get('/campaign/insightPoster', {
      params,
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'INSIGHT_POSTER_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'INSIGHT_POSTER_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'INSIGHT_POSTER_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getCampaignDataPoints = () => (dispatch) => {
  dispatch({
    type: 'DATA_FETCHING',
    payload: true,
  });
  ajax
    .get('/campaign/listCampaignDataPoint', {
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'CAMPAIGN_DATA_POINTS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CAMPAIGN_DATA_POINTS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CAMPAIGN_DATA_POINTS_FAILURE',
        payload: error,
      });
    });
};

export const getCreateCampaign = (data, token) => (dispatch) => {
  ajax
    .post('/campaign/createCampaign', {
      data: {
        weblinkURL: data.weblink_url,
        campaignTitle: data.campaign_title,
        posterImageID: data.poster_image_id,
        smsTextDescription: data.sms_text_description,
        insightID: data.insight_id,
        orgID: data.org_id,
        startTime: data.start_time,
        type: data.type,
        dataPoints: data.data_points,
        farmers: data.farmers,
        dataCollectionType: data.data_collection_type,
        farmerType: data.farmer_type,
        campaignDescription: data.campaignDescription,
      },
      headers: {
        token,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CAMPAIGN_CREATED_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CAMPAIGN_CREATED_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CAMPAIGN_CREATED_FAILURE',
        payload: error,
      });
    });
};

export const getTestCampaign = (data, token) => (dispatch) => {
  ajax
    .post('/campaign/createCampaign', {
      data: {
        weblinkURL: data.weblink_url,
        campaignTitle: data.campaign_title,
        posterImageID: data.poster_image_id,
        smsTextDescription: data.sms_text_description,
        insightID: data.insight_id,
        orgID: data.org_id,
        startTime: data.start_time,
        type: data.type,
        dataPoints: data.data_points,
        farmers: data.farmers,
        dataCollectionType: data.data_collection_type,
        farmerType: data.farmer_type,
        campaignDescription: data.campaignDescription,
      },
      headers: {
        token,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CAMPAIGN_TESTED_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CAMPAIGN_TESTED_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CAMPAIGN_TESTED_FAILURE',
        payload: error,
      });
    });
};

export const uploadPoster = bodyFormdata => (dispatch) => {
  ajax
    .post('/campaign/uploadCampaignPoster', {
      data: bodyFormdata,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_POSTER_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_POSTER_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_POSTER_FAILURE',
        payload: error,
      });
    });
};

export const farmeList = data => (dispatch) => {
  ajax
    .post('/farmers/farmers/orgFarmers', {
      data: {
        orgId: data.orgId,
        page: data.page,
        limit: data.limit,
        filters: data.filters,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'FARMER_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FARMER_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'FARMER_LIST_FAILURE',
        payload: error,
      });
    });
};

export const uploadFarmers = (bodyFormdata, token) => (dispatch) => {
  ajax
    .post('/users/users/uploadBulkFarmer', {
      data: bodyFormdata,
      headers: {
        token,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'UPLOAD_FARMER_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'UPLOAD_FARMER_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({
        type: 'UPLOAD_FARMER_FAILURE',
        payload: error,
      });
    });
};

export const getCampaignReport = data => (dispatch) => {
  ajax
    .post('/campaign/campaignReport', {
      data: {
        campaignId: data.campaignId,
      },
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CAMPAIGN_REPORT_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CAMPAIGN_REPORT_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CAMPAIGN_REPORT_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getCampaignList = (params, token, type) => (dispatch) => {
  dispatch({
    type: 'DATA_FETCHING',
    payload: true,
  });
  ajax
    .get('/campaign/listCampaigns', {
      params,
      headers: {
        token,
      },
      isLoader: type === 'sms' ? null : true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CAMPAIGN_LIST_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CAMPAIGN_LIST_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CAMPAIGN_LIST_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getTaggedFarmerList = params => (dispatch) => {
  ajax
    .get('/farmers/farmers/farmerListForIVRS', {
      params,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'TAGGED_FARMER_LIST_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'TAGGED_FARMER_LIST_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'TAGGED_FARMER_LIST_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getCropByLocation = params => (dispatch) => {
  ajax
    .get('/master/pmfby/cropsByLocation', {
      params,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CROP_BY_LOCATION_LIST_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CROP_BY_LOCATION_LIST_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CROP_BY_LOCATION_LIST_DATA_FAILURE',
        payload: error,
      });
    });
};

export const resetTaggingDetails = () => ({
  type: 'RESET_TAGGING_DETAILS',
});

export const resetTaggingFarmerDetails = () => ({
  type: 'RESET_TAGGING_FARMER_DETAILS',
});

export const resetCampaignDetails = () => ({
  type: 'RESET_CAMPAIGN_DETAILS',
});

export const resetTaggedFarmerList = () => ({
  type: 'RESET_TAGGED_FARMER_LIST',
});

export const resetUploadFarmerDetails = () => ({
  type: 'RESET_UPLOAD_FARMER_DETAILS',
});
