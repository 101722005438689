const initialState = {
  posterListData: [],
  campaignDataPoints: [],
  createCampaign: {},
  uploadPosterUrl: {},
  farmersList: {},
  isLoading: false,
  farmersUploaded: '',
  farmerListError: '',
  campaignList: [],
  campaignReport: {},
  cropListByLoc: [],
  taggedListFarmer: {},
  farmersUploadError: '',
  testedCampaign: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'DATA_FETCHING':
      return {
        ...state,
        isLoading: true,
      };
    case 'INSIGHT_POSTER_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        posterListData: action.payload,
      };
    case 'INSIGHT_POSTER_DATA_FAILURE':
      return {
        ...state,
        isLoading: false,
        posterListData: [],
      };
    case 'CAMPAIGN_DATA_POINTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        campaignDataPoints: action.payload,
      };
    case 'CAMPAIGN_DATA_POINTS_FAILURE':
      return {
        ...state,
        isLoading: false,
        campaignDataPoints: [],
      };
    case 'CAMPAIGN_CREATED_SUCCESS':
      return {
        ...state,
        createCampaign: action.payload,
      };
    case 'CAMPAIGN_CREATED_FAILURE':
      return {
        ...state,
        createCampaign: action.payload,
      };
    case 'UPLOAD_POSTER_SUCCESS':
      return {
        ...state,
        uploadPosterUrl: action.payload,
      };
    case 'UPLOAD_POSTER_FAILURE':
      return {
        ...state,
        uploadPosterUrl: {},
      };
    case 'RESET_TAGGING_DETAILS':
      return {
        ...state,
        createCampaign: {},
        campaignDataPoints: [],
      };
    case 'FARMER_LIST_SUCCESS':
      return {
        ...state,
        farmersList: action.payload,
      };
    case 'FARMER_LIST_FAILURE':
      return {
        ...state,
        farmersList: {},
        farmerListError: action.payload,
      };
    case 'UPLOAD_FARMER_SUCCESS':
      return {
        ...state,
        farmersUploaded: action.payload,
        farmersUploadError: '',
      };
    case 'UPLOAD_FARMER_FAILURE':
      return {
        ...state,
        farmersUploaded: '',
        farmersUploadError: action.payload,
      };
    case 'RESET_TAGGING_FARMER_DETAILS':
      return {
        ...state,
        farmerListError: '',
      };
    case 'CAMPAIGN_LIST_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        campaignList: action.payload,
      };
    case 'CAMPAIGN_LIST_DATA_FAILURE':
      return {
        ...state,
        isLoading: false,
        campaignList: [],
      };
    case 'CAMPAIGN_REPORT_DATA_SUCCESS':
      return {
        ...state,
        campaignReport: action.payload,
      };
    case 'CAMPAIGN_REPORT_DATA_FAILURE':
      return {
        ...state,
        campaignReport: {},
      };
    case 'RESET_CAMPAIGN_DETAILS':
      return {
        ...state,
        campaignList: [],
        campaignReport: {},
      };
    case 'CROP_BY_LOCATION_LIST_DATA_SUCCESS':
      return {
        ...state,
        cropListByLoc: action.payload,
      };
    case 'CROP_BY_LOCATION_LIST_DATA_FAILURE':
      return {
        ...state,
        cropListByLoc: [],
      };
    case 'TAGGED_FARMER_LIST_DATA_SUCCESS':
      return {
        ...state,
        taggedListFarmer: action.payload,
      };
    case 'TAGGED_FARMER_LIST_DATA_FAILURE':
      return {
        ...state,
        taggedListFarmer: {},
      };
    case 'RESET_TAGGED_FARMER_LIST':
      return {
        ...state,
        taggedListFarmer: {},
      };
    case 'RESET_UPLOAD_FARMER_DETAILS':
      return {
        ...state,
        farmersUploaded: '',
        farmersUploadError: '',
      };

    case 'CAMPAIGN_TESTED_SUCCESS':
      return {
        ...state,
        testedCampaign: action.payload,
      };
    case 'CAMPAIGN_TESTED_FAILURE':
      return {
        ...state,
        testedCampaign: action.payload,
      };
    default:
      return state;
  }
};
