import React from 'react';
import '../../css/welcomePage.scss';

export default () => (
  <div className="loaderHead">
    <div className="loaderLeftPanel">
      <img src="/images/loader-left.gif" alt="left-loader" />
    </div>
    <div className="loaderRightPanel">
      <img src="/images/loader-right.png" alt="right-loader" />
    </div>
  </div>
);
