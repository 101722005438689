import React, { Component } from 'react';
import {
  Button, Grid, FormControl, Select, MenuItem,
} from '@material-ui/core';
import '../../css/ivrs/ivrsModal.scss';
import ModalHOC from '../common/Modal/index';

const INPUTS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*'];

export default class IvrsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inPoint: '',
      outCard: '',
      connectionPoints: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { activeCard } = this.props;
    const { connectionPoints } = this.state;
    if (
      (activeCard.id !== undefined && prevProps.activeCard.id === undefined)
      || activeCard.id !== prevProps.activeCard.id
    ) {
      this.updateConnectionPoint(INPUTS);
      // this.setState({ connectionPoints: INPUTS });
    } else if (
      !(
        Object.keys(activeCard).length === 0
        && activeCard.constructor === Object
      )
      && prevProps.activeCard.id === activeCard.id
    ) {
      const mappedPoints = [];
      for (let i = 0; i < activeCard.outs.length; i += 1) {
        mappedPoints.push(activeCard.outs[i][0]);
        console.log('mmmm', mappedPoints);
      }
      const pointsCopy = [...connectionPoints];
      console.log('pointCopy', pointsCopy);
      const finalPointsCopy = pointsCopy.filter(
        el => mappedPoints.indexOf(el) < 0,
      );
      console.log('pppp', pointsCopy);
      if (finalPointsCopy.length < pointsCopy.length) {
        this.updateConnectionPoint(finalPointsCopy);
        // this.setState({
        //   connectionPoints: finalPointsCopy,
        // });
      }
    }
  }

  updateConnectionPoint = (point) => {
    this.setState({
      connectionPoints: point,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleAdd = () => {
    const { onAdd, activeCard } = this.props;
    const { outCard, inPoint } = this.state;
    this.setState({ inPoint: '', outCard: '' });
    onAdd(activeCard, outCard, inPoint);
  };

  handleClear = () => {
    this.setState({ inPoint: '', outCard: '' });
  };

  _handlePointChange = (ev) => {
    console.log('vvv', ev);
    this.setState({
      inPoint: ev.target.value,
    });
  };

  _handleOutcardChange = (ev) => {
    this.setState({
      outCard: ev.target.value,
    });
  };

  render() {
    const { showModal, graph } = this.props;
    const { connectionPoints, inPoint, outCard } = this.state;
    return (
      <React.Fragment>
        <ModalHOC
          isOpen={showModal}
          customContent="ivrs-connection-modal"
          onClose={this.handleClose}
          titleContent="Add Misc Connection"
          footerComponent={(
            <div container className="btn">
              <Button
                variant="contained"
                className="button btn-success mr-20"
                onClick={this.handleClear}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                className="button btn-success"
                onClick={this.handleAdd}
              >
                Add
              </Button>
            </div>
)}
          customClass="modal-position-top"
        >
          <Grid container className="inner">
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="separateFormLeft"
            >
              <h4>Input Point</h4>
              <FormControl className="formBlocks commonForm form-group customForm">
                <Select
                  name="dashboard-range"
                  inputProps={{ 'aria-label': 'age' }}
                  className="tableDropdown"
                  onChange={ev => this._handlePointChange(ev)}
                  value={inPoint}
                  displayEmpty
                >
                  <MenuItem value="">Select</MenuItem>
                  {connectionPoints.map(input => (
                    <MenuItem key={input} value={input}>
                      {input}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="separateFormLeft"
            >
              <h4>Output Point</h4>
              <FormControl className="formBlocks commonForm form-group customForm">
                <Select
                  // disableUnderline
                  name="dashboard-range"
                  inputProps={{ 'aria-label': 'age' }}
                  className="tableDropdown"
                  onChange={ev => this._handleOutcardChange(ev)}
                  value={outCard}
                  displayEmpty
                >
                  <MenuItem value="">Select</MenuItem>
                  {graph.nodes.map(node => (
                    <MenuItem key={node.id} value={node.id}>
                      {node.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </ModalHOC>
        {/*      <Dialog
        maxWidth={false}
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        className="registerModal modalHead"
      >
        <div className="paper">
          <div className="modal-header">
            <div className="modal-title">
              <h4>Add Misc Connection</h4>
            </div>
            <div
              role="button"
              tabIndex="0"
              className="modalCloseIcon mobileCloseIcon"
              onClick={this.handleClose}
              onKeyDown={this.handleClose}
            >
              <i className="material-icons customClose">close</i>
            </div>
          </div>
          <div className="modalSec">
            <div className="modalContent">
              <Grid container className="inner">
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="separateFormLeft"
                >
                  <h4>Input Point</h4>
                  <FormControl className="formBlocks commonForm form-group customForm">
                    <Select
                      name="dashboard-range"
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={ev => this._handlePointChange(ev)}
                      value={inPoint}
                    >
                      {connectionPoints.map(input => (
                        <MenuItem key={input} value={input}>
                          {input}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="separateFormLeft"
                >
                  <h4>Output Point</h4>
                  <FormControl className="formBlocks commonForm form-group customForm">
                    <Select
                      name="dashboard-range"
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={ev => this._handleOutcardChange(ev)}
                      value={outCard}
                    >
                      {graph.nodes.map(node => (
                        <MenuItem key={node.id} value={node.id}>
                          {node.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              variant="contained"
              className="button darkgreenBtn customClearBtn"
              onClick={this.handleClear}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              className="button darkgreenBtn customClearBtn"
              onClick={this.handleAdd}
            >
              Add
            </Button>
          </div>
        </div>
      </Dialog> */}
      </React.Fragment>
    );
  }
}
