import React from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Button,
  Switch,
} from '@material-ui/core';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import DateRangeClone from '../dateRangeClone';
import LineChartComponent from '../lineChart';
import '../../../css/agricultureIndices.scss';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
];

class MandiSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenerate: false,
      expandPanel: false,
    };
  }

  _generateData = () => {
    this.setState({
      isGenerate: true,
    });
  };

  render() {
    const { isGenerate, expandPanel } = this.state;
    const { broadcastLocationChange } = this.props;

    let yearHtml = [];
    yearHtml = [2016, 2017, 2018, 2019].map(item => (
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    ));

    let seasonHtml = [];
    seasonHtml = [
      { label: 'Kharif', value: 1 },
      { label: 'Rabi', value: 2 },
      // { label: 'Zaid', value: 3 },
    ].map(item => (
      <MenuItem value={item.value} key={item.label}>
        {item.label}
      </MenuItem>
    ));

    return (
      <div>
        {isGenerate ? (
          <div className="indicesMainPanel">
            <div
              className={
                expandPanel ? 'indicesList expandPanel' : 'indicesList'
              }
            >
              {/* <div className=""> */}
              <Grid container>
                <Grid item xs={3}>
                  <h4>Yield report</h4>
                </Grid>
                <Grid item xs={6} className="indicesList">
                  <ul>
                    <li>2018</li>
                    <li>Rabi</li>
                  </ul>
                </Grid>
                <Grid item xs={3}>
                  <h4>
                    <a href="/" onClick={this._resetLocation}>
                      Edit Selection
                    </a>
                  </h4>
                </Grid>
              </Grid>

              <ul>
                <li>Haryana</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
              </ul>
              <Grid
                container
                style={{
                  borderBottom: '1px solid #d7d7d7',
                  paddingBottom: '15px',
                }}
              >
                <Grid item xs={3}>
                  <h4>Crop</h4>
                </Grid>
                <Grid item xs={9} className="indicesList">
                  <ul>
                    <li>Maize</li>
                    <li>Yield Forcast Model2</li>
                  </ul>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="indicesList bg-gray">
                  <h4>Graphical Analysis</h4>
                </Grid>
              </Grid>
            </div>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="rechartsPanel">
                  <DateRangeClone />
                  <div className="line-chart">
                    <LineChartComponent
                      data={data}
                      yAxisLabel="Indices Values"
                      dataKey="pv"
                      xAxisDataKey="name"
                    />
                  </div>
                  <ul className="legendMain">
                    <li>
                      <i style={{ backgroundColor: '#4a90e2' }} />
                      <span>Max Temp</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: '#09b466' }} />
                      <span>Min Temp</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Grid container className="indicesList bg-gray">
              <Grid item xs={6} className="indicesList bg-gray">
                <h4>Need More Precision ?</h4>
              </Grid>
              <Grid item xs={6} className="indicesList bg-gray">
                <h4>
                  <a href="/">Ask farmers from the region</a>
                </h4>
              </Grid>
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                {/*  <div className="panelDivision rechartsPanel"> */}
                <div className="comparisonPanel">
                  Comparison
                  <Switch
                    value="indicesComparision"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked
                  />
                  <DateRangeClone />
                  <FormControl className="formControl inputHead coloredForms modeSec">
                    <Select
                      disableUnderline
                      name="dashboard-user"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value=""
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Market Select
                      </MenuItem>
                      <MenuItem value="market1">market1</MenuItem>
                    </Select>
                  </FormControl>
                  <LineChartComponent
                    data={data}
                    yAxisLabel="Indices Values"
                    dataKey="pv"
                    xAxisDataKey="name"
                  />
                  <div className="indicesScrollableLink">
                    <div className="indicesScrollBlock selectController">
                      <div className="rechartsPanel" style={{ float: 'none' }}>
                        <ul className="legendMain">
                          <li>
                            <i style={{ backgroundColor: '#4a90e2' }} />
                            <span>Max Temp</span>
                          </li>
                          <li>
                            <i style={{ backgroundColor: '#09b466' }} />
                            <span>Min Temp</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="agriIndices selectController">
            <h4 className="tabpanelHeading">Market Report</h4>
            <Grid item xs={12}>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value="2016"
                  displayEmpty
                >
                  {yearHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value="1"
                  displayEmpty
                >
                  {seasonHtml}
                </Select>
              </FormControl>
              <LocationHeirarchy
                type="selectEmpty modalDropdown"
                calledFrom="dashboard"
                broadcastLocationChange={broadcastLocationChange}
              />
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <h4 className="tabpanelHeading">Market</h4>
              </Grid>
              <Grid item xs={6}>
                <h4 className="tabpanelHeading">Crop</h4>
              </Grid>
              <Grid item xs={6}>
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value="Mandi 1"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="Mandi 1">Mandi 1</MenuItem>
                    <MenuItem value="Mandi 2">Mandi 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value="Maize"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="Maize">Maize</MenuItem>
                    <MenuItem value="Wheat">Wheet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6} className="agriIndices btn">
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-success floatRight"
                    onClick={this._generateData}
                  >
                    Generate Data
                    <i className="material-icons">arrow_right_alt</i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MandiSelectTab;
