import React, { Component } from 'react';
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DateRangeClone from '../dateRangeClone';
import ModalHOC from '../../common/Modal/index';

let MapboxDraw;
let draw;
export default class AgriIndiceInputCoord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadModalOpen: false,
      disableCoordInput: false,
      disableFileInput: false,
      disableDrawInput: false,
    };
  }

  handleUploadModal = () => {
    this.setState({
      isUploadModalOpen: true,
      disableCoordInput: true,
      disableDrawInput: true,
    });
  };

  handleClose = () => {
    this.setState({
      isUploadModalOpen: false,
    });
  };

  handleDrawFields = (e) => {
    this.setState({
      disableCoordInput: true,
      disableFileInput: true,
    });
    e.preventDefault();
    const { getMapReference } = this.props;
    const map = getMapReference();
    if (map) {
      /* eslint-disable global-require */
      MapboxDraw = require('@mapbox/mapbox-gl-draw');
      /* eslint-enable global-require */

      draw = new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: 'draw_polygon',
        controls: {
          trash: true,
        },
      });
      map.addControl(draw, 'top-left');

      map.on('draw.create', this.updateArea);
      map.on('draw.delete', this.updateArea);
      map.on('draw.update', this.updateArea);
    }
  };

  submitCoord = (e) => {
    e.preventDefault();
    this.setState({
      disableFileInput: true,
      disableDrawInput: true,
    });
  };

  updateArea = (e) => {
    console.log('Draw Type ', e.type);
  };

  resetSelection = (e) => {
    e.preventDefault();
    this.setState({
      disableCoordInput: false,
      disableFileInput: false,
      disableDrawInput: false,
    });
  };

  render() {
    const {
      isUploadModalOpen,
      disableCoordInput,
      disableFileInput,
      disableDrawInput,
    } = this.state;
    return (
      <div className="indices-input-coordinate">
        <aside>
          <div className="aside-bg-gray">
            <h1>Co-ordinate</h1>
            <Grid container className="aside-panel">
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  id="standard-start-adornment"
                  className="aside-panel__input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        Lat
                        {' '}
                        <span>|</span>
                        {' '}
                      </InputAdornment>
                    ),
                  }}
                  disabled={disableCoordInput}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  id="standard-start-adornment"
                  className="aside-panel__input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        Long
                        {' '}
                        <span>|</span>
                        {' '}
                      </InputAdornment>
                    ),
                  }}
                  disabled={disableCoordInput}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Button
                  className="btn-success mt-10 mb-20"
                  onClick={e => this.submitCoord(e)}
                  disabled={disableCoordInput}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <h2 className="text-border">
              <span>OR</span>
            </h2>
            <h3>Upload fields</h3>
            <Grid container className="aside-panel">
              <Grid item lg={12} md={12} xs={12} className="aside-panel__btn">
                <div className="btn">
                  <Button
                    className="btn-outline-success"
                    onClick={this.handleUploadModal}
                    disabled={disableFileInput}
                  >
                    Upload
                  </Button>
                </div>
              </Grid>
            </Grid>
            <h2 className="text-border">
              <span>OR</span>
            </h2>
            <h3>Draw polygon</h3>
            <Grid container className="aside-panel border">
              <Grid item lg={12} md={12} xs={12} className="aside-panel__btn">
                <div className="btn">
                  <Button
                    className="btn-outline-success mb-20"
                    onClick={this.handleDrawFields}
                    disabled={disableDrawInput}
                  >
                    DRAW
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className="aside-panel agriIndices selectController"
            >
              <Grid item lg={12} md={12} xs={12}>
                <h4>Source</h4>
              </Grid>
              <Grid item lg={5} md={5} xs={12}>
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                  >
                    <MenuItem value="" disabled>
                      Satellites
                    </MenuItem>
                    <MenuItem>hfdd</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={5} md={5} xs={12}>
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    displayEmpty
                    value=""
                  >
                    <MenuItem avlue="">Select</MenuItem>
                    <MenuItem>Satellites</MenuItem>
                    <MenuItem>hfdd</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={7} md={7} xs={12}>
                <DateRangeClone />
              </Grid>
              <Grid item lg={12} md={12} xs={12} className="btn">
                <Button className="btn-success mt-10 mb-20">
                  Generate Data
                </Button>
              </Grid>
            </Grid>
          </div>
        </aside>

        {/* Second Screen start */
        /* <aside>
            <header>
              Add Fields
            </header>
            <div className="aside-body">
              <h1>No Fields</h1>
              <p>Place the first point and draw the border on the map</p>
            </div>
            <div className="aside-footer btn">
              <Grid container>
                <Grid item lg={6} md={6} xs={12}>
                  <Button className="btn-outline-success">Cancel</Button>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Button className="btn-disable">Save</Button>
                </Grid>
              </Grid>
            </div>
          </aside> */
        /* Second screen end */}

        {/* Third screen start */
        /*        <aside>
          <header>Add Fields</header>
          <div className="aside-field">
            <ul>
              <li>
                <div className="aside-field__left">1:</div>
                <div className="aside-field__right">
                  <h1>
                    Fields 1
                    {' '}
                    <span>0.8 Ha</span>
                  </h1>
                  <ul>
                    <li><strong>Lat</strong><span>28.4595° N</span></li>
                    <li><strong>Long</strong><span>28.4595° N</span></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="aside-body" />
          <div className="aside-footer btn">
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <Button className="btn-outline-success">Cancel</Button>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Button className="btn-disable">Save</Button>
              </Grid>
            </Grid>
          </div>
        </aside> */
        /* Third screen end */}

        {/*        <aside>
          <div className="aside-field">
            <ul>
              <li>
                <h2>Selected Co-ordinate <span>Edit Selection</span></h2>
                <div className="aside-field__left">1:</div>
                <div className="aside-field__right">
                  <h1>
                    Fields 1
                    {' '}
                    <span>0.8 Ha</span>
                  </h1>
                  <ul>
                    <li><strong>Lat</strong><span>28.4595° N</span></li>
                    <li><strong>Long</strong><span>28.4595° N</span></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <Grid container className="aside-panel agriIndices selectController">
            <Grid item lg={12} md={12} xs={12}>
              <h4>Source</h4>
            </Grid>
            <Grid item lg={5} md={5} xs={12}>
              <FormControl className="formControl inputHead coloredForms inputFullWidth">
                <Select
                  disableUnderline
                  name="dashboard-user"
                  className="selectEmpty modalDropdown"
                  inputProps={{ 'aria-label': 'user' }}
                >
                  <MenuItem value="" disabled>Satellites</MenuItem>
                  <MenuItem>hfdd</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={5} md={5} xs={12}>
              <FormControl className="formControl inputHead coloredForms inputFullWidth">
                <Select
                  disableUnderline
                  name="dashboard-user"
                  className="selectEmpty modalDropdown"
                  inputProps={{ 'aria-label': 'user' }}
                  displayEmpty
                  value=""
                >
                  <MenuItem avlue="">Select</MenuItem>
                  <MenuItem>Satellites</MenuItem>
                  <MenuItem>hfdd</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={7} md={7} xs={12}>
              <DateRangeClone />
            </Grid>
            <Grid item lg={12} md={12} xs={12} className="btn">
              <Button className="btn-success mt-10 mb-20">Generate Data</Button>
            </Grid>
          </Grid>
        </aside> */}
        <ModalHOC
          isOpen={isUploadModalOpen}
          customContent="upload-farm-modal"
          onClose={this.handleClose}
        >
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <div className="dropzone format">
                <div className="dropzone__text">
                  <h1>Drag & Drop File</h1>
                  <p>GeoJSON,DBF,PRJ,SHP & SHX</p>
                  <h2>Or</h2>
                </div>
                <input type="file" className="select-file" />
              </div>
            </Grid>
          </Grid>
        </ModalHOC>
      </div>
    );
  }
}
