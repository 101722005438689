import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {
  Select,
  FormControl,
  MenuItem,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import BarChartComponent from '../barChart';
import { VerticalLineChart } from '../../common/chart';
import {
  indicesSpi,
  resetWeatherSourceDetails,
  getIndicesSmap,
  getIndicesSmapHeatMap,
  clearIndicesSmapHeatMap,
  getPDSIData,
  indicesSpiHeatmap,
  clearSpiHeatMap,
} from '../../../Actions/weatherAction';
// import TextCarousel from '../../common/textCarousel';
import '../../../css/agricultureIndices.scss';

const Month = [
  {
    month: 'January',
    key: 1,
  },
  {
    month: 'February',
    key: 2,
  },
  {
    month: 'March',
    key: 3,
  },
  {
    month: 'April',
    key: 4,
  },
  {
    month: 'May',
    key: 5,
  },
  {
    month: 'June',
    key: 6,
  },
  {
    month: 'July',
    key: 7,
  },
  {
    month: 'August',
    key: 8,
  },
  {
    month: 'September',
    key: 9,
  },
  {
    month: 'October',
    key: 10,
  },
  {
    month: 'November',
    key: 11,
  },
  {
    month: 'December',
    key: 12,
  },
];

class WeatherSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weatherSource: 'IMD AWS',
      indicesParameter: 'SPI',
      // locationObject: {},
      monthName: 1,
      error: '',
      isGenerate: false,
      smapParameter: 'Mean',
      smapYear: '2019',
      spiAvgParameter: 1,
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.selectedStateID !== prevProps.location.selectedStateID) {
      this.updateDataOnLocChange(location.selectedStateID);
    }
    if (location.selectedDistrictID !== prevProps.location.selectedDistrictID) {
      this.updateDataOnLocChange(location.selectedDistrictID);
    }
    if (
      location.selectedStateID === prevProps.location.selectedStateID
      && location.selectedDistrictID === ''
      && prevProps.location.selectedDistrictID !== ''
    ) {
      this.updateDataOnLocChange(location.selectedStateID);
    }
  }

  createData = (parameter, value) => ({
    parameter,
    value,
  });

  _handleWeatherSource = (ev) => {
    console.log('eee', ev.target.value);
    const { broadcastWeatherSourceChange } = this.props;
    this.setState({
      weatherSource: ev.target.value,
    });
    broadcastWeatherSourceChange(ev.target.value);
  };

  _resetData = (ev) => {
    const { resetDetails, resetSmapHeatmap, resetSpiHeatmap } = this.props;
    ev.preventDefault();
    this.setState({
      // weatherSource: 'Windy',
      isGenerate: false,
      error: '',
      // indicesParameter: 'SPI',
      // locationObject: {},
      // monthName: 1,
    });
    resetDetails(0);
    resetSmapHeatmap();
    resetSpiHeatmap();
  };

  _getWeatherSource = (value) => {
    switch (value) {
      case 'IMD AWS':
        return 'IMD AWS';
      case 'Windy':
        return 'Windy';
      case 'Indices':
        return 'Indices';
      default:
        return null;
    }
  };

  _handleIndicesParameter = (ev) => {
    this.setState({
      indicesParameter: ev.target.value,
    });
  };

  _handleSmapParameter = (ev) => {
    console.log('ev', ev.target.value);
    this.setState({
      smapParameter: ev.target.value,
    });
  };

  _handleSmapYear = (ev) => {
    console.log('ev', ev.target.value);
    this.setState({
      smapYear: ev.target.value,
    });
  };

  _handleSpiAvgParameter = (ev) => {
    this.setState(
      {
        spiAvgParameter: ev.target.value,
      },
      () => {
        this.updateIndicesSPIData();
      },
    );
  };

  _getIndicesParameter = (value) => {
    switch (value) {
      case 'SPI':
        return 'SPI';
      case 'PDSI':
        return 'PDSI';
      case 'LST':
        return 'LST';
      case 'Soil Moisture':
        return 'Soil Moisture';
      default:
        return null;
    }
  };

  _handleMonthChange = (ev) => {
    const { indicesParameter } = this.state;
    this.setState(
      {
        monthName: ev.target.value,
      },
      () => {
        if (indicesParameter === 'SPI') {
          this.updateIndicesSPIData();
        }
        if (indicesParameter === 'PDSI') {
          this.updatePDSIData();
        }
      },
    );
  };

  updateIndicesSPIData = () => {
    const {
      fetchindicesSpiData,
      location,
      fetchindicesSpiHeatmap,
    } = this.props;
    const { monthName, spiAvgParameter } = this.state;
    let level;
    if (location.selectedStateID) {
      level = 2;
    }
    if (location.selectedDistrictID) {
      level = 3;
    }
    const data = {};
    data.level = level;
    data.month = monthName;
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    data.avgSelector = Number(spiAvgParameter);
    fetchindicesSpiData(data);
    fetchindicesSpiHeatmap(data);
  };

  updateSMAPData = () => {
    const {
      location,
      fetchindicesSmapData,
      fetchIndicesSmapHeatmap,
    } = this.props;
    let level;
    if (location.selectedStateID) level = 2;
    if (location.selectedDistrictID) level = 3;
    const data = {};
    data.level = level;
    data.year = 2019;
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;

    fetchindicesSmapData(data);
    // if (!update) {
    data.dateFrom = '2019-09-01';
    data.dateTo = '2019-10-01';
    fetchIndicesSmapHeatmap(data);
    // }
  };

  // updateImdData = () => {
  //   const { location, fetchImdData } = this.props;
  //   const data = {};
  //   data.districtID = location.selectedDistrictID;
  //   fetchImdData(data);
  // };

  updatePDSIData = () => {
    const { fetchPDSIData, location } = this.props;
    const { monthName } = this.state;
    const data = {};
    data.month = monthName;
    data.districtID = location.selectedDistrictID;
    fetchPDSIData(data);
  };

  _generateData = (ev) => {
    const { weatherSource, indicesParameter } = this.state;
    const { locationObject, updateImdData } = this.props;
    console.log('locationObject1', locationObject);
    ev.preventDefault();
    let error = '';
    let check = true;
    if (!weatherSource) {
      check = false;
      error = 'please select Weather Source from above';
    }
    if (!indicesParameter) {
      check = false;
      error = 'please select Indices Parameter from above';
    }
    if (_.isEmpty(locationObject)) {
      check = false;
      error = 'please select Location from above or from map';
    }
    if (!locationObject.state) {
      check = false;
      error = 'please select Location from above or from map';
    }
    if (check) {
      this.setState(
        {
          isGenerate: true,
          error: '',
        },
        () => {
          if (weatherSource === 'Indices' && indicesParameter === 'SPI') {
            this.updateIndicesSPIData();
          }
          if (
            weatherSource === 'Indices'
            && indicesParameter === 'Soil Moisture'
          ) {
            this.updateSMAPData();
          }
          if (weatherSource === 'IMD AWS') {
            updateImdData();
          }
          if (weatherSource === 'Indices' && indicesParameter === 'PDSI') {
            this.updatePDSIData();
          }
        },
      );
    } else {
      this.setState({
        isGenerate: false,
        error,
      });
    }
  };

  updateDataOnLocChange(value) {
    const { isGenerate, weatherSource, indicesParameter } = this.state;
    const {
      location,
      resetDetails,
      updateImdData,
      activeWeatherTab,
    } = this.props;
    if (
      isGenerate
      && value
      && weatherSource === 'Indices'
      && indicesParameter === 'SPI'
      && activeWeatherTab === 0
    ) {
      this.updateIndicesSPIData();
    }
    if (
      isGenerate
      && value
      && weatherSource === 'Indices'
      && indicesParameter === 'Soil Moisture'
      && activeWeatherTab === 0
    ) {
      this.updateSMAPData();
    }
    if (
      isGenerate
      && value
      && weatherSource === 'Indices'
      && indicesParameter === 'PDSI'
      && activeWeatherTab === 0
    ) {
      this.updatePDSIData();
    }
    if (
      isGenerate
      && value
      && weatherSource === 'IMD AWS'
      && location.selectedDistrictID
      && activeWeatherTab === 0
    ) {
      updateImdData();
    }
    if (
      isGenerate
      && weatherSource === 'IMD AWS'
      && location.selectedDistrictID === ''
      && activeWeatherTab === 0
    ) {
      resetDetails(0);
      updateImdData();
    }
  }

  render() {
    const {
      weatherSource,
      indicesParameter,
      monthName,
      error,
      isGenerate,
      smapParameter,
      smapYear,
      spiAvgParameter,
    } = this.state;
    const {
      broadcastLocationChange,
      indiceSpiData,
      location,
      imdData,
      locationObject,
      indiceSmapData,
      pdsiData,
      currentTabDataError,
    } = this.props;
    let rows = [];
    const smapindexData = [];
    let smapData = [];
    let imdForecastData = [];
    console.log(typeof indiceSmapData);
    if (
      location.selectedDistrictID
      && !_.isEmpty(imdData)
      && weatherSource === 'IMD AWS'
    ) {
      const imdWeatherData = imdData.weather;
      imdForecastData = imdData.weather.forecast;
      rows = [
        this.createData(
          'Min/Max Temp(ºC)',
          `${
            imdWeatherData.maxTemp % 1 !== 0
              ? imdWeatherData.maxTemp.toFixed(2)
              : imdWeatherData.maxTemp
          } 
            / ${
  imdWeatherData.minTemp % 1 !== 0
    ? imdWeatherData.minTemp.toFixed(2)
    : imdWeatherData.minTemp
}`,
        ),
        this.createData(
          '24 Hours Rainfall (mm)',
          imdWeatherData.rainfall || 'null',
        ),
        this.createData(
          'Sunrise & Sunset Time',
          `${
            imdWeatherData.sunrise !== null
              ? `${imdWeatherData.sunrise.hours || '00'}: ${imdWeatherData
                .sunrise.minutes || '00'}`
              : 'null'
          } & 
         ${
  imdWeatherData.sunset !== null
    ? `${imdWeatherData.sunset.hours || '00'}: ${imdWeatherData.sunset
      .minutes || '00'}`
    : 'null'
}
          `,
        ),
        this.createData(
          'Moonrise & Moonset Time',
          `${
            imdWeatherData.moonrise !== null
              ? `${imdWeatherData.moonrise.hours || '00'}: ${imdWeatherData
                .moonrise.minutes || '00'}`
              : 'null'
          } & 
         ${
  imdWeatherData.moonset !== null
    ? `${imdWeatherData.moonset.hours || '00'}: ${imdWeatherData
      .moonset.minutes || '00'}`
    : 'null'
}
          `,
        ),
        this.createData(
          'Relative Humidity at 8:30 am (%)',
          imdWeatherData.humidity830,
        ),
        this.createData(
          'Relative Humidity at 5:30 pm (%)',
          imdWeatherData.humidity1730,
        ),
      ];
    }
    if (
      !_.isEmpty(indiceSmapData)
      && weatherSource === 'Indices'
      && indicesParameter === 'Soil Moisture'
    ) {
      console.log('indiceSmapData', indiceSmapData);
      const smapIndexData = indiceSmapData.indexData;
      smapIndexData.forEach((item) => {
        const smapTempObj = {
          category: item.month,
          value: item.soilMoisture || 0,
        };
        smapindexData.push(smapTempObj);
      });
    }
    if (smapindexData.length > 0 && smapindexData.length > 0) {
      smapData = [
        {
          name: 'Soil Moisture Index',
          color: '#09b466',
          data: smapindexData,
        },
      ];
    }
    return (
      <React.Fragment>
        {weatherSource
        && !_.isEmpty(locationObject)
        && locationObject.state
        && isGenerate ? (
          <div className="indicesMainPanel">
            <div className="indicesList">
              <h4>
                Current & Forecast Report
                <a href="/" onClick={this._resetData}>
                  Edit Selection
                </a>
              </h4>
              <ul>
                {locationObject.state ? <li>{locationObject.state}</li> : null}
                {location.selectedDistrictID && locationObject.district ? (
                  <li>{locationObject.district}</li>
                ) : null}
                {/* locationObject.tehsil ? (
                  <li>{locationObject.tehsil}</li>
                ) : null */}
                {/* locationObject.village ? (
                  <li>{locationObject.village}</li>
                ) : null */}
              </ul>
              <ul>
                <li className="listHeading">Source</li>
                <li>{this._getWeatherSource(weatherSource)}</li>
                {indicesParameter && weatherSource === 'Indices' ? (
                  <li className="listHeading">Parameter</li>
                ) : null}
                {indicesParameter && weatherSource === 'Indices' ? (
                  <li>{this._getIndicesParameter(indicesParameter)}</li>
                ) : null}
              </ul>
              <ul className="bg-gray-heading">Graphical Analysis</ul>
            </div>
            <div className="indicesScrollableLink">
              {indicesParameter === 'SPI'
              && weatherSource === 'Indices'
              && currentTabDataError === '' ? (
                <div className="indicesScrollBlock selectController">
                  <h4
                    style={{
                      float: 'left',
                      fontSize: '15px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    Standardized Precipitation Index
                  </h4>
                  <FormControl
                    className="formControl inputHead coloredForms"
                    style={{ float: 'right', marginLeft: '20px' }}
                  >
                    <Select
                      disableUnderline
                      name="weather-range"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={spiAvgParameter}
                      displayEmpty
                      onChange={ev => this._handleSpiAvgParameter(ev)}
                    >
                      <MenuItem value="1">1 Month Avg</MenuItem>
                      <MenuItem value="3">3 Months Avg</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    className="formControl inputHead coloredForms"
                    style={{ float: 'right' }}
                  >
                    <Select
                      name="dashboard-range"
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={this._handleMonthChange}
                      value={monthName}
                    >
                      <MenuItem value="" disabled>
                        Select Month
                      </MenuItem>
                      {Month.map(item => (
                        <MenuItem value={item.key} key={item.key}>
                          {item.month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {indiceSpiData && indiceSpiData.length > 0 && (
                    <div className="rechartsPanel">
                      <BarChartComponent
                        data={indiceSpiData}
                        yAxisLabel="SPI"
                        dataKey="SPI"
                        xAxisDataKey="year"
                        compareChart={false}
                        showToolTip
                      />
                      <ul className="legendMain">
                        <li>
                          <i style={{ backgroundColor: '#4a90e2' }} />
                          <span>SPI</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                ) : null}
              {indicesParameter === 'Soil Moisture'
              && weatherSource === 'Indices'
              && currentTabDataError === '' ? (
                <div className="indicesScrollBlock selectController">
                  <h4
                    style={{
                      float: 'left',
                      fontSize: '15px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    Soil Moisture Index
                  </h4>
                  <FormControl
                    className="formControl inputHead coloredForms"
                    style={{ float: 'right', marginLeft: '20px' }}
                  >
                    <Select
                      disableUnderline
                      name="weather-range"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={smapParameter}
                      displayEmpty
                      onChange={ev => this._handleSmapParameter(ev)}
                    >
                      <MenuItem value="Mean">Mean</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    className="formControl inputHead coloredForms"
                    style={{ float: 'right' }}
                  >
                    <Select
                      disableUnderline
                      name="weather-range"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={smapYear}
                      displayEmpty
                      onChange={ev => this._handleSmapYear(ev)}
                    >
                      <MenuItem value="2019">2019</MenuItem>
                    </Select>
                  </FormControl>
                  {smapData && smapData.length > 0 && (
                    <div className="rechartsPanel">
                      <VerticalLineChart
                        data={smapData}
                        yAxisLabel="Soil Moisture (cm3)"
                        dataKey="category"
                        showToolTip
                      />
                      <ul className="legendMain">
                        <li>
                          <i style={{ backgroundColor: '#09b466' }} />
                          <span>Soil Moisture</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                ) : null}
              {indicesParameter === 'PDSI'
              && weatherSource === 'Indices'
              && currentTabDataError === '' ? (
                <div className="indicesScrollBlock selectController">
                  <h4
                    style={{
                      float: 'left',
                      fontSize: '15px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    Palmer Drought Severity Index
                  </h4>
                  <FormControl
                    className="formControl inputHead coloredForms"
                    style={{ float: 'right' }}
                  >
                    <Select
                      name="dashboard-range"
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={this._handleMonthChange}
                      value={monthName}
                    >
                      <MenuItem value="" disabled>
                        Select Month
                      </MenuItem>
                      {Month.map(item => (
                        <MenuItem value={item.key} key={item.key}>
                          {item.month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {pdsiData && pdsiData.length > 0 && (
                    <div className="rechartsPanel">
                      <BarChartComponent
                        data={pdsiData}
                        yAxisLabel="PDSI"
                        dataKey="pdsi"
                        xAxisDataKey="dateAdded"
                        compareChart={false}
                        showToolTip
                      />
                      <ul className="legendMain">
                        <li>
                          <i style={{ backgroundColor: '#4a90e2' }} />
                          <span>PDSI</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                ) : null}
              {currentTabDataError !== '' ? (
                <div className="notfound">
                  <img
                    src="../images/notFound.svg"
                    alt="not-found"
                    title="not-found"
                  />
                  <h2>
                    Oops… Please try with different location hierarchy and
                    bounds
                  </h2>
                  <i className="material-icons">arrow_downward</i>
                  <a
                    href="/"
                    className="darkgreenBtn"
                    onClick={this._resetData}
                  >
                    Edit
                  </a>
                </div>
              ) : null}
              {/* <TextCarousel indicesChange={indicesChange}/> */}
              {/* weatherSource === 'IMD AWS' && location.selectedDistrictID && (
                <p className="nearestValue">Nearest AWS District 2.5 Km</p>
              ) */}
              {weatherSource === 'IMD AWS'
              && location.selectedDistrictID
              && rows
              && rows.length > 0 ? (
                <React.Fragment>
                  <div className="dashboardTable panelDivision">
                    <Table className="table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parameter</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          && rows.length > 0
                          && rows.map(item => (
                            <TableRow key={item.parameter}>
                              <TableCell>{item.parameter}</TableCell>
                              <TableCell>{item.value}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                  {imdForecastData && imdForecastData.length > 0 && (
                    <div className="indicesScrollBlock selectController">
                      <div className="panelDivision rechartsPanel">
                        <h4
                          style={{
                            float: 'left',
                            fontSize: '15px',
                            fontWeight: '600',
                            color: '#000000',
                          }}
                        >
                          Forecast
                        </h4>

                        <BarChartComponent
                          data={imdForecastData}
                          yAxisLabel="Min/Max Temp(ºC)"
                          dataKey="maxTemp"
                          dataKey1="minTemp"
                          xAxisDataKey="day"
                          compareChart
                          showToolTip={false}
                        />
                        <ul className="legendMain">
                          <li>
                            <i style={{ backgroundColor: '#4a90e2' }} />
                            <span>Max Temp</span>
                          </li>
                          <li>
                            <i style={{ backgroundColor: '#09b466' }} />
                            <span>Min Temp</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </React.Fragment>
                ) : null}
              {/* <div className="indicesScrollBlock selectController">
                <h4>Standard Perception Index</h4>
              </div> */}
            </div>
          </div>
          ) : (
            <div className="agriIndices selectController btn">
              <h4 className="tabpanelHeading">Currrent & Forecast Report</h4>
              <Grid item xs={12}>
                <LocationHeirarchy
                  type="selectEmpty modalDropdown"
                  calledFrom="dashboard"
                  broadcastLocationChange={broadcastLocationChange}
                  hideTehsil
                  hideVillage
                />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h4 className="tabpanelHeading">Source</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="formControl inputHead coloredForms inputFullWidth">
                    <Select
                      disableUnderline
                      name="weather-range"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={weatherSource}
                      displayEmpty
                      onChange={ev => this._handleWeatherSource(ev)}
                    >
                      <MenuItem value="IMD AWS">IMD AWS</MenuItem>
                      <MenuItem value="Windy">Windy</MenuItem>
                      <MenuItem value="Indices">Indices</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {weatherSource === 'Indices' ? (
                  <Grid item xs={6}>
                    <FormControl className="formControl inputHead coloredForms inputFullWidth">
                      <Select
                        disableUnderline
                        name="dashboard-user"
                        className="selectEmpty modalDropdown"
                        inputProps={{ 'aria-label': 'user' }}
                        value={indicesParameter}
                        displayEmpty
                        onChange={ev => this._handleIndicesParameter(ev)}
                      >
                        <MenuItem value="SPI">SPI</MenuItem>
                        <MenuItem value="PDSI">PDSI</MenuItem>
                        {/* <MenuItem value="LST">LST</MenuItem> */}
                        <MenuItem value="Soil Moisture">Soil Moisture</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {weatherSource !== 'Windy' ? (
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn-success floatRight"
                      onClick={this._generateData}
                    >
                    Generate Data
                      <i className="material-icons">arrow_right_alt</i>
                    </Button>
                    {error ? (
                      <p className="errorMsg">
                        {' '}
                        {error}
                        {' '}
                      </p>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            </div>
          )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ location, weather }) => ({
      location,
      indiceSpiData: weather.indiceSpiData,
      imdData: weather.imdData,
      indiceSmapData: weather.indiceSmapData,
      pdsiData: weather.pdsiData,
      currentTabDataError: weather.currentTabDataError,
    }),
    dispatch => ({
      fetchindicesSpiData: data => dispatch(indicesSpi(data)),
      fetchindicesSpiHeatmap: data => dispatch(indicesSpiHeatmap(data)),
      fetchindicesSmapData: data => dispatch(getIndicesSmap(data)),
      fetchIndicesSmapHeatmap: data => dispatch(getIndicesSmapHeatMap(data)),
      resetSmapHeatmap: () => dispatch(clearIndicesSmapHeatMap()),
      //  fetchImdData: data => dispatch(getImdData(data)),
      resetDetails: clearParam => dispatch(resetWeatherSourceDetails(clearParam)),
      fetchPDSIData: data => dispatch(getPDSIData(data)),
      resetSpiHeatmap: () => dispatch(clearSpiHeatMap()),
    }),
  )(withCookies(WeatherSelectTab)),
);
