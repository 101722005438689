import React, { Component } from 'react';
import '../../css/ivrs/ivrsModal.scss';
import ModalHOC from '../common/Modal/index';

export default class UploadViewFormatModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  render() {
    const { showModal } = this.props;
    return (
      <React.Fragment>
        <ModalHOC
          isOpen={showModal}
          customContent="view-format-modal"
          onClose={this.handleClose}
        >
          <img
            src="images/view-format.png"
            alt="img"
            className="img-responsive"
          />
        </ModalHOC>
      </React.Fragment>
    );
  }
}
