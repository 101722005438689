import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import {
  getCampaignList,
  resetCampaignDetails,
  getTestCampaign,
} from '../../Actions/taggingAction';
import ModalHOC from '../common/Modal/index';
import Toastr from '../common/toastr';
import SmsSelectFarmer from './smsSelectFarmer';
import '../../css/common/commonModal.scss';
import '../../css/table.scss';

class SmsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: '',
      selectFarmer: false,
      selectedId: 0,
      smsTitle: '',
      smsDetail: '',
      selectedDataForTest: {},
      mobile: '',
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      linkText: '',
      smsText: '',
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  componentDidUpdate(prevProps) {
    const { testedCampaign } = this.props;
    if (!_.isEmpty(testedCampaign) && _.isEmpty(prevProps.testedCampaign)) {
      this.showMessageType(testedCampaign);
    }
  }

  fetchList = () => {
    const { fetchCampaignList, resetDetails, cookies } = this.props;
    resetDetails();
    const data = {};
    data.orgId = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
    data.type = 3;
    const userData = cookies.get('userData');
    if (userData) {
      fetchCampaignList(data, userData.token, 'sms');
    }
  };

  showMessageType = (testedCampaign) => {
    if (testedCampaign.status) {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Sms Sent Successfully',
        toastrMessageType: 'success',
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Sms Not Sent',
        toastrMessageType: 'error',
      });
    }
  };

  doAction = (e, itemdata) => {
    this.setState({
      show: true,
      selectedDataForTest: itemdata,
    });
  };

  handleTestMessage = () => {
    const { mobile, selectedDataForTest } = this.state;
    const { scheduleCampaign, cookies } = this.props;
    const userData = cookies.get('userData');
    let check = true;
    let error = '';
    if (mobile.length < 10) {
      check = false;
      error = 'Please enter a valid mobile number';
    }
    if (!mobile) {
      check = false;
      error = 'Please enter a mobile number';
    }
    if (check) {
      const data = {};
      data.weblink_url = selectedDataForTest.weblinkUrl || '';
      data.campaign_title = selectedDataForTest.campaignTitle || 'Sms Title';
      data.poster_image_id = '49ba9907-bd39-481b-9da6-a59d8bb4c9d2';
      data.sms_text_description = 'Sms Text Description';
      data.campaignDescription = selectedDataForTest.campaignDescription || 'Test Description';
      data.insight_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
      data.org_id = 'b4ad7861-e689-48d7-911d-49a1d465d25d';
      data.data_collection_type = 3;
      data.farmer_type = 1;
      data.start_time = moment()
        .format()
        .slice(0, 16);
      data.type = 2;
      data.data_points = [];
      data.farmers = [
        {
          farmerID: '06e38693-9a80-4cf5-b4bf-c9873ffedddd',
          mobile,
          name: 'test name',
          sms: true,
          ivrs: false,
        },
      ];
      if (userData) {
        scheduleCampaign(data, userData.token);
      }
      this.setState({
        error: '',
        show: false,
      });
    } else {
      this.setState({
        error,
        show: true,
      });
    }
  };

  onClose = () => {
    this.setState({
      show: false,
      error: '',
    });
  };

  handelClick = () => {
    this.setState({
      selectFarmer: true,
    });
  };

  handlePrevious = () => {
    this.setState({
      selectFarmer: false,
    });
    this.fetchList();
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false,
    });
  };

  captureMobile = (e) => {
    const mobileRegex = /^[6-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(mobileRegex) && e.target.value !== '') {
      return;
    }
    this.setState({ mobile: e.target.value });
  };

  handleCheck = (id, name, desc, link, smsText) => {
    this.setState({
      selectedId: id,
      smsTitle: name,
      smsDetail: desc,
      linkText: link,
      smsText,
    });
  };

  render() {
    const {
      show,
      error,
      selectFarmer,
      selectedId,
      smsTitle,
      smsDetail,
      mobile,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      linkText,
      smsText,
    } = this.state;
    const { campaignList, anotherTab } = this.props;
    return (
      <React.Fragment>
        {selectFarmer === false ? (
          <div className="ivrs-area">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="insightTable tableWrapper">
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>CreatedAt</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaignList
                        && campaignList.map(item => (
                          <TableRow key={item.campaignID}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={item.campaignID === selectedId}
                                onClick={() => this.handleCheck(
                                  item.campaignID,
                                  item.campaignTitle,
                                  item.campaignDescription,
                                  item.weblinkUrl,
                                  item.smsTextDescription,
                                )
                                }
                              />
                            </TableCell>
                            <TableCell>{item.campaignTitle}</TableCell>
                            <TableCell>{item.campaignDescription}</TableCell>
                            <TableCell>
                              {item.createdAt.split(' ')[0]}
                            </TableCell>
                            <TableCell>
                              <Button
                                className="playBtn"
                                name="listen"
                                onClick={e => this.doAction(e, item)}
                              >
                                <i className="material-icons">
                                  play_circle_filled
                                </i>
                                Test
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/*    <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalCount || 10}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              /> */}
                </div>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ivrsProceed"
                style={{ margin: '8px 0px 0px 0px' }}
              >
                <div className="darkgreenBtnHeader alignRight">
                  <Button
                    className={`${!selectedId ? 'disableBtn' : 'enableBtn'}`}
                    onClick={this.handelClick}
                  >
                    Select Farmers
                    <i className="material-icons">arrow_forward</i>
                  </Button>
                </div>
              </Grid>
            </Grid>
            <ModalHOC
              isOpen={show}
              onClose={this.onClose}
              titleContent="Enter Mobile number"
              customContent="addMobileNo"
              footerComponent={(
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <Button
                      className="upload-btn-default"
                      onClick={this.handleTestMessage}
                    >
                      OK
                    </Button>
                  </Grid>
                </Grid>
)}
            >
              <TextField
                id="mobileNum"
                label="Mobile No."
                className="textField inputHead ivrscustomText-width"
                margin="normal"
                value={mobile}
                onChange={this.captureMobile}
                inputProps={{ maxLength: 10 }}
              />
              {error ? (
                <p className="errorMsg1">
                  {' '}
                  {error}
                  {' '}
                </p>
              ) : null}
            </ModalHOC>
          </div>
        ) : (
          <SmsSelectFarmer
            handlePrevious={this.handlePrevious}
            smsTitle={smsTitle}
            smsDesc={smsDetail}
            smsLink={linkText}
            smsText={smsText}
            anotherTab={anotherTab}
          />
        )}
        <Toastr
          message={toastrMessage}
          show={istoastrOpen}
          handleClose={this.handleToastrClose}
          messageType={toastrMessageType}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging }) => ({
      campaignList: tagging.campaignList,
      isLoading: tagging.isLoading,
      testedCampaign: tagging.testedCampaign,
    }),
    dispatch => ({
      fetchCampaignList: (data, token, type) => dispatch(getCampaignList(data, token, type)),
      resetDetails: () => dispatch(resetCampaignDetails()),
      scheduleCampaign: (data, token) => dispatch(getTestCampaign(data, token)),
    }),
  )(withCookies(SmsList)),
);
