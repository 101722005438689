import React from 'react';
import MobileStepperHOC from '../MobileStepper';
import AreaChartComponent from '../../dashboard/areaChart';

/**
 * data = [
 *    {
 *      label: 'LABEL',
 *      value: 12,
 *      graphData: [
 *        {
 *          "date": "",
 *          "value": 1232
 *        }
 *      ],
 *      xAxisDataKey: "date",
 *      yAxisDataKey: "value",
 *      alertData: "",
 *    }
 * ]
 * */

class TextWithGraphCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, autoPlaySteps } = this.props;
    const childrenHTML = data.map((item) => {
      const {
        label,
        value,
        graphData,
        xAxisDataKey,
        yAxisDataKey,
        alertData,
        updatedSinceString,
        isFooterDataAvailable,
        footerData,
      } = item;
      return (
        <div
          key={Math.random()}
          className="statusCarousel"
          style={{ width: '100%' }}
        >
          {data.length ? (
            <div className="statusCarouselInner">
              <h4>
                Current Status
                <span>
(
                  {updatedSinceString}
)
                </span>
              </h4>
              <h5>
                {label.toUpperCase()}
                {value ? (
                  <i
                    className="material-icons"
                    style={value < 0 ? { color: 'red' } : { color: 'green' }}
                  >
                    {value < 0 ? 'arrow_downward' : 'arrow_upward'}
                  </i>
                ) : null}
                {value ? value.toFixed(2) : ''}
%
              </h5>
              {isFooterDataAvailable ? (
                <ul>
                  {footerData
                    && footerData.map(footerItem => (
                      <li key={footerItem.label} style={{ display: 'block' }}>
                        {footerItem.label}
                        {' '}
:
                        {footerItem.value}
                      </li>
                    ))}
                </ul>
              ) : null}
              {/* <ul>
               <li>Total Sowing: 7 Hec</li>
               <li>Toc</li>
               <li>Total Sowing: 7 Hec</li>
               </ul> */}
            </div>
          ) : null}
          {graphData.length ? (
            <AreaChartComponent
              data={graphData}
              color="#43D269"
              dataKey={yAxisDataKey}
              strokeColor="#09b466"
              xAxisDataKey={xAxisDataKey}
            />
          ) : null}
          {alertData && (
            <div className="alertPanel">
              Alert(s):
              {/* <ol>
             <li>- Crop supply has decreased due to Mandi Price</li>
             <li>- Lorem hui hyu io gs iuyt bbs bsh hhss hhshj jsi uri.</li>
             </ol> */}
            </div>
          )}
        </div>
      );
    });
    return (
      <div className="root textCarousel">
        <MobileStepperHOC autoPlaySteps={autoPlaySteps}>
          {childrenHTML}
        </MobileStepperHOC>
      </div>
    );
  }
}

export default TextWithGraphCarousel;
