import ajax from '../Functions/ajax';

export const getAccountSetupQuestionsAction = params => (dispatch) => {
  ajax
    .get(
      '/users/accountSetupOrQuestonList',
      {
        params: {
          requirement: params.requirement,
          organizationTypeID: params.orgID,
        },
        isLoader: 'true',
      },
      { headers: { token: params.token } },
    )
    .then((response) => {
      if (response.data.status) {
        if (params.requirement === 'ACCOUNT_SETUP') {
          dispatch({
            type: 'ACCCOUNT_SETUP_QUESTION_SUCCESS',
            payload: response.data.data,
          });
        }
        if (params.requirement === 'TAB_CONTENT') {
          dispatch({
            type: 'TAB_CONTENT_SUCCESS',
            payload: response.data.data,
          });
        }
      } else {
        if (params.requirement === 'ACCOUNT_SETUP') {
          dispatch({
            type: 'ACCCOUNT_SETUP_QUESTION_FAILURE',
            payload: response.data.error,
          });
        }
        if (params.requirement === 'TAB_CONTENT') {
          dispatch({
            type: 'TAB_CONTENT_SUCCESS',
            payload: response.data.data,
          });
        }
      }
    })
    .catch((error) => {
      if (params.requirement === 'ACCOUNT_SETUP') {
        dispatch({
          type: 'ACCCOUNT_SETUP_QUESTION_FAILURE',
          payload: error,
        });
      }
      if (params.requirement === 'TAB_CONTENT') {
        dispatch({
          type: 'TAB_CONTENT_FAILURE',
          payload: error,
        });
      }
    });
};

export const submitAccountSetupAction = (data, token, history) => dispatch => ajax
  .post('/users/accountSetupPost', {
    data,
    headers: {
      token,
    },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      history.push('/setup/step2');
      dispatch({
        type: 'ACCOUNT_SETUP_STEP1_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'ACCOUNT_SETUP_STEP1_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err>>>', err);
    dispatch({
      type: 'ACCOUNT_SETUP_STEP1_FAILURE',
      payload: err,
    });
  });

export const submitAccountSetup2Action = (data, token, history) => dispatch => ajax
  .post('/users/questionariePost', {
    data,
    headers: {
      token,
    },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      history.push('/setup/step3');
      dispatch({
        type: 'ACCOUNT_SETUP_STEP2_SUCCESS',
        payload: response.data,
      });
    } else {
      history.push('/setup/step3');
      dispatch({
        type: 'ACCOUNT_SETUP_STEP2_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err>>>', err);
    dispatch({
      type: 'ACCOUNT_SETUP_STEP2_FAILURE',
      payload: err,
    });
  });
