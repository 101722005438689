import React from 'react';
import Datetime from 'react-datetime';
import {
  Grid, Button, TextField, Card, CardContent,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import '../../css/button.scss';
import '../../css/gis/reactDatetime.scss';
import '../../css/form.scss';
import {
  getAccountSetupQuestionsAction,
  submitAccountSetupAction,
} from '../../Actions/afterLoginHomeAction';

import ThankuCard from './common/thankuCard';

const IMAGES_SRC = [
  '/images/Gis/location.svg',
  '/images/Gis/farmer.svg',
  '/images/Gis/analysis.svg',
  '/images/Gis/advisory.svg',
  '/images/Gis/price.svg',
];

const GOTO = ['/dashboard', '/tagging', '/home', '/home', '/home'];

class GisWelcomeOnBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    window.location.hash = '';
    const { cookies, getAccountSetupQuestions } = this.props;
    const { cookies: inCookies } = cookies;

    let { userData } = inCookies;
    if (userData) {
      userData = JSON.parse(userData);
      const data = {};

      // data.orgID = userData.data.userData.organizationTypeID;
      data.orgID = userData.data.userOrganisationDetails.organisationTypeID;
      data.token = userData.token;
      data.requirement = 'QUESTION';
      // getAccountSetupQuestions(data);
      const newData = JSON.parse(JSON.stringify(data));
      newData.requirement = 'TAB_CONTENT';
      getAccountSetupQuestions(newData);
    }
  }

  getCard() {
    const { tabContent, history } = this.props;
    const keysArray = Object.keys(tabContent);
    let cardArray = [];
    if (Object.keys(tabContent).length) {
      cardArray = keysArray.map((key, index) => (
        <Grid item lg={4} md={12} sm={12} xs={12} key={Math.random()}>
          <div className="cardSection">
            <Card>
              <CardContent>
                <div className="cardTitle">
                  {tabContent[key].head}
                  <img
                    src={IMAGES_SRC[index]}
                    alt="location-img"
                    className="responsive"
                  />
                </div>
                <div className="cardContent">{tabContent[key].body}</div>
                <div className="cardFooter">
                  <div className="btn darkgreenBtnHeader">
                    <Button
                      variant="contained"
                      className="button btn-success startBtn"
                      onClick={() => history.push(GOTO[index])}
                      disabled={index > 1}
                    >
                      Get Started
                    </Button>
                    <Button
                      variant="contained"
                      className="button btn-success playBtn"
                      // onClick={() => this.thanku()}
                    >
                      <i className="material-icons">play_arrow</i>
                    </Button>
                    <span className="playContent">See how it works</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </Grid>
      ));
    }

    return cardArray;
  }

  thanku = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Grid container className="root gis-main1" justify="center">
          <Grid item xs={10}>
            <div className="gis-account">
              <h5>Welcome Onboard! </h5>
            </div>
          </Grid>
        </Grid>
        <Grid container className="root card-section1" justify="center">
          <Grid item lg={10} md={12} xs={12}>
            <p className="sectionContent">
              Connect with farms and farmers through FarmEngine Offerings
              <i className="material-icons">arrow_right_alt</i>
            </p>
            {/* <ul className="card-ul darkgreenBtnHeader">{this.getCard()}</ul> */}
          </Grid>
          <Grid container item lg={10} md={10} sm={10} xs={12} spacing={0}>
            {this.getCard()}
          </Grid>
          <Grid item xs={10} className="demo-heading">
            <div className="demoDetails">
              <h2>For Demo</h2>
              <span>
                Enter details below to choose your slot and get a call back
              </span>
            </div>

            <div className="custom-date">
              <Grid container spacing={2} justify="center">
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  {/* <TextField
                      id="datetime-local"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      className="abc"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /> */}

                  <i className="material-icons calender-icon">date_range</i>
                  {/* <i className="material-icons calender-icon">date_range</i> */}

                  <Datetime />
                </Grid>
                <Grid
                  container
                  item
                  lg={7}
                  md={7}
                  sm={7}
                  xs={12}
                  spacing={2}
                  justify="center"
                >
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      // error={!!mobileError}
                      // helperText="Please enter valid mobile number"
                      id="mobile"
                      label="Name"
                      className="textField inputHead customText-width"
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      // error={!!mobileError}
                      // helperText="Please enter valid mobile number"
                      id="mobile"
                      label="Mobile Number"
                      className="textField inputHead customText-width"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      // error={!!mobileError}
                      // helperText="Please enter valid mobile number"
                      id="mobile"
                      label="Email"
                      className="textField inputHead customText-width"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="whiteBtnHeader"
                >
                  <Button className="whiteBtn">Submit</Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <ThankuCard open={open} />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ home }) => ({
      tabContent: home.tabContent,
    }),
    dispatch => ({
      getAccountSetupQuestions: params => dispatch(getAccountSetupQuestionsAction(params)),
      submitAccountSetup: (d, t, h) => dispatch(submitAccountSetupAction(d, t, h)),
    }),
  )(withCookies(GisWelcomeOnBoard)),
);
