const initialState = {
  cropInCropMaps: [],
  cropParameter: [],
  cropMap: [],
  indiceSpiData: [],
  cropGraphData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CROPS_IN_CROPMAP_SUCCESS':
      return {
        ...state,
        cropInCropMaps: action.payload,
      };
    case 'GET_CROPS_IN_CROPMAP_FAILURE':
      return {
        ...state,
        cropInCropMaps: [],
      };

    case 'CROP_MAP_PARAMETER_SUCCESS':
      return {
        ...state,
        cropParameter: action.payload,
      };

    case 'CROP_MAP_PARAMETER_FAILURE':
      return {
        ...state,
        cropParameter: [],
      };

    case 'CROP_MAP_SUCCESS':
      return {
        ...state,
        cropMap: action.payload,
      };

    case 'CROP_MAP_FAILURE':
      return {
        ...state,
        cropMap: [],
      };
    case 'INDICES_SPI_DATA_SUCCESS':
      return {
        ...state,
        indiceSpiData: action.payload,
      };
    case 'INDICES_SPI_DATA_FAILURE':
      return {
        ...state,
        indiceSpiData: [],
      };

    case 'GET_CROPS_IN_CROPMAPGRAPH_SUCCESS':
      return {
        ...state,
        cropGraphData: action.payload,
      };

    case 'GET_CROPS_IN_CROPMAPGRAPH_FAILURE':
      return {
        ...state,
        cropGraphData: [],
      };
    default:
      return state;
  }
};
