import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  getAccountSetupQuestionsAction,
  submitAccountSetupAction,
} from '../../Actions/afterLoginHomeAction';

class GisAccountHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIDs: {},
      flag: true,
    };
  }

  componentDidMount() {
    const { cookies, getAccountSetupQuestions } = this.props;
    const { cookies: inCookies } = cookies;

    let { userData } = inCookies;
    if (userData) {
      userData = JSON.parse(userData);
      const data = {};

      // data.orgID = userData.data.userData.organizationTypeID;
      data.orgID = userData.data.userOrganisationDetails.organisationTypeID;
      data.token = userData.token;
      data.requirement = 'ACCOUNT_SETUP';
      getAccountSetupQuestions(data);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { accountSetupQuestions } = nextProps;
    const { flag } = prevState;

    if (Object.keys(accountSetupQuestions).length && flag) {
      const data = {};
      const keysArray = Object.keys(accountSetupQuestions);
      for (let i = 0; i < keysArray.length; i += 1) {
        data[keysArray[i]] = {};
        data[keysArray[i]].ques = accountSetupQuestions[keysArray[i]];
        data[keysArray[i]].ans = 0;
      }
      return {
        selectedIDs: data,
        flag: false,
      };
    }
    return null;
  }

  updateStep = () => {
    const { updateStep, history } = this.props;
    const { cookies, submitAccountSetup } = this.props;
    const { cookies: inCookies } = cookies;
    // debugger;
    let { userData } = inCookies;
    if (userData) {
      userData = JSON.parse(userData);
      const { token } = userData;
      const { selectedIDs } = this.state;
      const data = {};
      data.accountSetupQA = selectedIDs;
      submitAccountSetup(data, token, history);
      updateStep(2);
    }
  };

  handleCardSelection(key) {
    console.log(key);
    const { selectedIDs } = this.state;
    if (selectedIDs[key]) {
      let { ans } = selectedIDs[key];
      if (ans === 0) {
        ans = 1;
      } else {
        ans = 0;
      }
      selectedIDs[key].ans = ans;
      this.setState({
        selectedIDs,
      });
    }
  }

  handleSubmit() {}

  render() {
    const lis = [];
    const { selectedIDs } = this.state;
    const { accountSetupQuestions } = this.props;
    let checkAnyOneTrue = false;
    if (Object.keys(selectedIDs).length) {
      const keysArray = Object.keys(selectedIDs);
      for (let i = 0; i < keysArray.length; i += 1) {
        if (selectedIDs[keysArray[i]].ans === 1) {
          checkAnyOneTrue = true;
        }
      }
    }

    if (Object.keys(accountSetupQuestions).length) {
      console.log('selectedIDs', selectedIDs);
      const keyArray = Object.keys(accountSetupQuestions);

      for (let i = 0; i < keyArray.length; i += 1) {
        console.log('===i', i);
        let className = null;
        if (Object.keys(selectedIDs).length) {
          console.log('===selectedIDs[keyArray[i]]', selectedIDs[keyArray[i]]);
          if (selectedIDs[keyArray[i]].ans) {
            className = 'card-active';
          }
        }
        lis.push(
          <li key={keyArray[i]} className={className}>
            <Button onClick={() => this.handleCardSelection(keyArray[i])}>
              {accountSetupQuestions[keyArray[i]]}
              {' '}
            </Button>
          </li>,
        );
      }
    }

    return (
      <div>
        <Grid container className="root gis-main1" justify="center">
          <Grid item xs={10}>
            <div className="gis-account">
              <h5>Account Setup</h5>
            </div>
          </Grid>
        </Grid>
        <Grid container className="root gis-main" justify="center">
          <Grid item xs={10}>
            <div className="card-section">
              <p>What do you want to do?</p>
              <ul>
                {lis}
                {/* <li>
                    <Button>Market loan products  to Farmer groups of your choice </Button>
                  </li> */}
                {/*
                  <li className="forward-icon">
                    <i
                      className="material-icons"
                      onClick={this.updateStep}
                      role="presentation"
                    >
                      arrow_right_alt
                    </i>
                  </li>
                */}
              </ul>
              {/* <div className="forward-icon">

                </div> */}
              {checkAnyOneTrue ? (
                <div className="offer-para">
                  <p>Great! See our offerings for your missions </p>
                  <Button onClick={this.updateStep} role="presentation">
                    <i className="material-icons">arrow_right_alt</i>
                  </Button>
                  {/* <ul>
                    <p>Great! See our offerings for your missions </p>
                    <li className="forward-icon">
                      <i
                        className="material-icons"
                        onClick={this.updateStep}
                        role="presentation"
                      >
                        arrow_right_alt
                      </i>
                    </li>
                  </ul> */}
                </div>
              ) : null}
            </div>
            {/* </div> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ home }) => ({
      accountSetupQuestions: home.accountSetupQuestions,
    }),
    dispatch => ({
      getAccountSetupQuestions: params => dispatch(getAccountSetupQuestionsAction(params)),
      submitAccountSetup: (d, t, h) => dispatch(submitAccountSetupAction(d, t, h)),
    }),
  )(withCookies(GisAccountHome)),
);
