const initialState = {
  ivrsChips: [],
  media: {},
  ivrsData: {},
  pingedIvrs: false,
  mediaList: {},
  ivrsSchedule: false,
  ivrsSaved: false,
  ivrsList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHIPS_DATA_SUCCESS':
      return {
        ...state,
        ivrsChips: action.payload,
      };
    case 'CHIPS_DATA_FAILURE':
      return {
        ...state,
        ivrsChips: [],
      };
    case 'RESET_DETAILS':
      return {
        ...state,
        ivrsChips: [],
        ivrsSchedule: false,
        pingedIvrs: false,
        ivrsSaved: false,
      };
    case 'ADD_MEDIA_SUCCESS':
      return {
        ...state,
        media: action.payload,
      };
    case 'ADD_MEDIA_FAILURE':
      return {
        ...state,
        media: {},
      };
    case 'CREATE_IVRS_SUCCESS':
      return {
        ...state,
        ivrsData: action.payload,
        ivrsSaved: true,
      };
    case 'CREATE_IVRS_FAILURE':
      return {
        ...state,
        ivrsData: {},
        ivrsSaved: false,
      };
    case 'PLAY_IVRS_SUCCESS':
      return {
        ...state,
        pingedIvrs: action.payload,
      };
    case 'PLAY_IVRS_FAILURE':
      return {
        ...state,
        pingedIvrs: action.payload,
      };
    case 'MEDIA_LIST_SUCCESS':
      return {
        ...state,
        mediaList: action.payload,
      };
    case 'MEDIA_LIST_FAILURE':
      return {
        ...state,
        mediaList: {},
      };
    case 'SCHEDULE_IVRS_SUCCESS':
      return {
        ...state,
        ivrsSchedule: action.payload,
      };
    case 'SCHEDULE_IVRS_FAILURE':
      return {
        ...state,
        ivrsSchedule: action.payload,
      };
    case 'IVRS_LIST_SUCCESS':
      return {
        ...state,
        ivrsList: action.payload,
      };
    case 'IVRS_LIST_FAILURE':
      return {
        ...state,
        ivrsList: {},
      };
    default:
      return state;
  }
};
