import React from 'react';
import ScrollableTabPanel from '../../common/ScrollableTabPanel';
import MandiSelectTab from './mandiSelectTab';
import '../../../css/tabPanel.scss';

class Mandi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const tabsData = [
      {
        tabName: 'Select',
        component: <MandiSelectTab {...this.props} />,
      },
    ];
    return (
      <ScrollableTabPanel tabsData={tabsData} customStyle="innerTabPanel" />
    );
  }
}

export default Mandi;
