import React from 'react';
import {
  Table, TableBody, TableCell, TableRow,
} from '@material-ui/core';

const recentSearches = [
  {
    field: 'Field 1',
    area: '0.8 Ha',
  },
  {
    field: 'Field 2',
    area: '0.8 Ha',
  },
  {
    field: 'Field 3',
    area: '0.8 Ha',
  },
  {
    field: 'Field 4',
    area: '0.8 Ha',
  },
  {
    field: 'Field 5',
    area: '0.8 Ha',
  },
  {
    field: 'Field 6',
    area: '0.8 Ha',
  },
];

class AgriIndicesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecentAction: undefined,
    };
  }

  toggleActionView = (index) => {
    const { showRecentAction } = this.state;
    if (showRecentAction === index) {
      this.setState({
        showRecentAction: undefined,
      });
    } else {
      this.setState({
        showRecentAction: index,
      });
    }
  };

  render() {
    const { showRecentAction } = this.state;
    return (
      <main className="search-panel">
        <section>
          <article>
            <header>Recent Searches</header>
            <div className="search-panel__scrollableTable">
              <Table className="search-panel__table table">
                <TableBody>
                  {recentSearches.map((item, index) => (
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.field}</TableCell>
                      <TableCell>{item.area}</TableCell>
                      <TableCell>
                        {showRecentAction === index && (
                          <nav>
                            <ul>
                              <li>Rename</li>
                              <li>Save</li>
                              <li>Delete</li>
                            </ul>
                          </nav>
                        )}
                        <i
                          className="material-icons"
                          onClick={() => this.toggleActionView(index)}
                          role="button"
                          tabIndex="0"
                          onKeyDown={() => this.toggleActionView(index)}
                        >
                          more_horiz
                        </i>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </article>
          <article>
            <header>Saved Searches</header>
            <Table className="search-panel__table table">
              <TableBody>
                <TableRow search-panel__table--bgcolor>
                  <TableCell>1</TableCell>
                  <TableCell>Fields 1</TableCell>
                  <TableCell>0.8 Ha</TableCell>
                  <TableCell>
                    <i className="material-icons">more_horiz</i>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2</TableCell>
                  <TableCell>Fields 2</TableCell>
                  <TableCell>1.0 Ha</TableCell>
                  <TableCell>
                    <i className="material-icons">more_horiz</i>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </article>
        </section>
      </main>
    );
  }
}

export default AgriIndicesSearch;
