/*
 */
exports.config = {
  lang: [
    'english',
    'hindi',
    'assamese',
    'bengali',
    'gujarati',
    'kannada',
    'malayalam',
    'marathi',
    'odia',
    'punjabi',
    'tamil',
    'telugu',
  ],
  header: {
    bank_branch_head: [
      {
        name: 'User Console',
        link: 'userConsole',
        breadcrum: '/User Console',
      },
    ],
    bank_state_admin: [
      {
        name: 'User Console',
        link: 'userConsole',
        breadcrum: '/User Console',
      },
    ],
    bank_Hq: [
      {
        name: 'User Console',
        link: 'userConsole',
        breadcrum: '/User Console',
      },
    ],
    GOI_ADMIN: [
      {
        name: 'User Console',
        link: 'userConsole',
        breadcrum: '/User Console',
      },
    ],
    DEVELOPER_FARMGUIDE: [
      {
        name: 'Developer Console',
        link: 'developerConsole',
        breadcrum: '/DeveloperConsole',
      },
    ],
    STATE_CCE_USER: [
      {
        name: 'User Console',
        link: 'userConsole',
        breadcrum: '/User Console',
      },
    ],
  },
  hashPassword: {
    salt: '865ab5ce-0d67-11e8-ba89-0ed5f89f718b',
    iterations: 10000,
    keylen: 64,
    digest: 'sha512',
  },
  mapboxConfig: {
    /* eslint-disable */
    publicToken:
    'pk.eyJ1IjoicGstYWciLCJhIjoiY2w1bWN4N2FzMHNoNTNlbzF1bGh5YWYxOCJ9.2WcJ4J0f9NYzUACVqREC-Q',
    // 'pk.eyJ1IjoiZmFybWd1aWRlIiwiYSI6ImNqeDRqMDNzNjAweDkzeW44NWgzNDlyNDEifQ.a8mu5rXqVwJocWOXKLp2UQ',
    defaultStyle:
      'mapbox://styles/mapbox/dark-v10',
    // defaultStyle: 'mapbox://styles/farmguide/cjxmv4mxm00b31co307mv5x2u',
    satelliteStyle:
      'mapbox://styles/mapbox/satellite-v9',
    // defaultStyle: 'mapbox://styles/farmguide/cjxa5ql9l05ya1cmxtwwvzgx6',
    // blankStyle: 'mapbox://styles/farmguide/cjy8acde50ge51cobf42ms5gl',
    blankStyle:
      'mapbox://styles/farmguide/ck25hm87z05y31co80uv84ipi?optimize=true',
    defaultCenter: [77.5988918, 23.69342],
    defaultZoom: 3.5,
    // accountSetupStyle: 'mapbox://styles/farmguide/cjy8acde50ge51cobf42ms5gl',
    accountSetupStyle: 'mapbox://styles/farmguide/cjxmv4mxm00b31co307mv5x2u',
    adminLayers: {
      country: {
        url: 'mapbox://farmguide.country',
        sourceLayer: 'country',
        zoomRange: [0, 4],
      },
      state: {
        url: 'mapbox://farmguide.state',
        sourceLayer: 'state',
        zoomRange: [4, 7],
      },
      district: {
        url: 'mapbox://farmguide.district',
        sourceLayer: 'district',
        zoomRange: [7, 8],
      },
      tehsil: {
        url: 'mapbox://farmguide.tehsil',
        sourceLayer: 'tehsil',
        zoomRange: [8, 13],
      },
      village: {
        url: 'mapbox://farmguide.village',
        sourceLayer: 'Admin',
        zoomRange: [13, 15],
      },
    },
    username: 'pk-ag',
  },
  GOOGLE_MAP_API_KEY: 'AIzaSyBFKufGnFZrQZeCPLpt1ALrzUlVETWBI_I', //AIzaSyCYIexFEkK7e75RKiy0sMp_wEOBEl6BVh0
  crops: {
    0: {
      cropName: 'Grass',
      cropColor: '#7ec850',
    },
    1: {
      cropName: 'Paddy',
      cropColor: '#20aca6',
    },
    2: {
      cropName: 'Cotton',
      cropColor: '#fcf4a3',
    },
    // 2: {
    //   cropName: 'Moong Dal',
    //   cropColor: '#0096ff',
    // },
    3: {
      cropName: 'Bajra',
      cropColor: '#FF4500',
    },
    4: {
      cropName: 'Horticulture',
      cropColor: '#86fea2',
    },
    5: {
      cropName: 'Other Crop',
      cropColor: '#ffff00',
    },
    6: {
      cropName: 'Wheat',
      cropColor: '#f5d3b3',
    },
    7: {
      cropName: 'Mustard',
      cropColor: '#e1ad01',
    },
    8: {
      cropName: 'Gram',
      cropColor: '#e1ad01',
    },
  },
  noOfBranches: [
    {
      value: '0-5',
      label: '0-5',
    },
    {
      value: '5-10',
      label: '5-10',
    },
    {
      value: '10-50',
      label: '10-50',
    },
    {
      value: '50-above',
      label: '50-above',
    },
  ],
  productTypeOptions: [
    {
      value: 'PMFBY',
      label: 'PMFBY',
    },
    {
      value: 'WBCIS',
      label: 'WBCIS',
    },
    {
      value: 'Micro Insurance',
      label: 'Micro Insurance',
    },
  ],
  productPriceRangeOptions: [
    {
      value: '0-1000',
      label: '0-1000',
    },
    {
      value: '1000-5000',
      label: '1000-5000',
    },
    {
      value: '5000-10000',
      label: '5000-10000',
    },
    {
      value: '10000-above',
      label: '10000-above',
    },
  ],
  procurementCenterOptions: [
    {
      value: '0-5',
      label: '0-5',
    },
    {
      value: '5-10',
      label: '5-10',
    },
    {
      value: '10-50',
      label: '10-50',
    },
    {
      value: '50-100',
      label: '50-100',
    },
    {
      value: '50-above',
      label: '50-above',
    },
  ],
  accountSetupStep2: {
    bank: 'Bank',
    farmer: 'Farmer',
    insurance: 'Insurance',
    seedCompany: 'Seed company',
    fertAndAgro: 'Fertiliser and Agrochemicals',
    farmEquipments: 'Farm Equipments',
    procurement: 'Procurement',
  },
  gpmLegends: [
    {
      color: '#F6FAFD',
      text: '0-10mm',
    },
    {
      color: '#E1ECF7',
      text: '10-20mm',
    },
    {
      color: '#CCDEEF',
      text: '20-30mm',
    },
    {
      color: '#ACCFE5',
      text: '30-50mm',
    },
    {
      color: '#85BBD8',
      text: '50-70mm',
    },
    {
      color: '#5CA2CC',
      text: '70-100mm',
    },
    {
      color: '#3986BC',
      text: '100-150mm',
    },
    {
      color: '#1F6AAD',
      text: '150-200mm',
    },
    {
      color: '#0E4F94',
      text: '200-250mm',
    },
    {
      color: '#0B3269',
      text: '250 or above',
    },
  ],
  ndviLegends: [
    {
      rgb: [254, 153, 41],
      val: -1,
    },
    {
      rgb: [254, 155, 42],
      value: -0.99,
    },
    {
      rgb: [254, 156, 43],
      value: -0.98,
    },
    {
      rgb: [254, 157, 45],
      value: -0.97,
    },
    {
      rgb: [254, 160, 47],
      value: -0.96,
    },
    {
      rgb: [254, 161, 48],
      value: -0.95,
    },
    {
      rgb: [254, 163, 49],
      value: -0.94,
    },
    {
      rgb: [254, 164, 51],
      value: -0.93,
    },
    {
      rgb: [254, 167, 53],
      value: -0.92,
    },
    {
      rgb: [254, 168, 54],
      value: -0.91,
    },
    {
      rgb: [254, 169, 55],
      value: -0.9,
    },
    {
      rgb: [254, 172, 58],
      value: -0.89,
    },
    {
      rgb: [254, 173, 59],
      value: -0.88,
    },
    {
      rgb: [254, 175, 60],
      value: -0.87,
    },
    {
      rgb: [254, 176, 61],
      value: -0.86,
    },
    {
      rgb: [254, 179, 64],
      value: -0.85,
    },
    {
      rgb: [254, 180, 65],
      value: -0.84,
    },
    {
      rgb: [254, 182, 66],
      value: -0.83,
    },
    {
      rgb: [254, 183, 67],
      value: -0.82,
    },
    {
      rgb: [254, 186, 70],
      value: -0.81,
    },
    {
      rgb: [254, 187, 71],
      value: -0.8,
    },
    {
      rgb: [254, 188, 72],
      value: -0.79,
    },
    {
      rgb: [254, 191, 74],
      value: -0.78,
    },
    {
      rgb: [254, 192, 76],
      value: -0.77,
    },
    {
      rgb: [254, 194, 77],
      value: -0.76,
    },
    {
      rgb: [254, 195, 78],
      value: -0.75,
    },
    {
      rgb: [254, 197, 82],
      value: -0.74,
    },
    {
      rgb: [254, 198, 84],
      value: -0.73,
    },
    {
      rgb: [254, 199, 86],
      value: -0.72,
    },
    {
      rgb: [254, 200, 88],
      value: -0.71,
    },
    {
      rgb: [254, 202, 92],
      value: -0.7,
    },
    {
      rgb: [254, 203, 94],
      value: -0.69,
    },
    {
      rgb: [254, 204, 96],
      value: -0.68,
    },
    {
      rgb: [254, 206, 101],
      value: -0.67,
    },
    {
      rgb: [254, 207, 103],
      value: -0.66,
    },
    {
      rgb: [254, 208, 105],
      value: -0.65,
    },
    {
      rgb: [254, 209, 107],
      value: -0.64,
    },
    {
      rgb: [254, 211, 111],
      value: -0.63,
    },
    {
      rgb: [254, 212, 113],
      value: -0.62,
    },
    {
      rgb: [254, 213, 115],
      value: -0.61,
    },
    {
      rgb: [254, 215, 119],
      value: -0.6,
    },
    {
      rgb: [254, 216, 121],
      value: -0.59,
    },
    {
      rgb: [254, 217, 123],
      value: -0.58,
    },
    {
      rgb: [254, 218, 125],
      value: -0.57,
    },
    {
      rgb: [254, 219, 129],
      value: -0.56,
    },
    {
      rgb: [254, 220, 132],
      value: -0.55,
    },
    {
      rgb: [254, 221, 134],
      value: -0.54,
    },
    {
      rgb: [254, 222, 136],
      value: -0.53,
    },
    {
      rgb: [254, 224, 140],
      value: -0.52,
    },
    {
      rgb: [254, 225, 142],
      value: -0.51,
    },
    {
      rgb: [254, 226, 144],
      value: -0.5,
    },
    {
      rgb: [254, 228, 147],
      value: -0.49,
    },
    {
      rgb: [254, 228, 148],
      value: -0.48,
    },
    {
      rgb: [254, 229, 150],
      value: -0.47,
    },
    {
      rgb: [254, 229, 151],
      value: -0.46,
    },
    {
      rgb: [254, 231, 154],
      value: -0.45,
    },
    {
      rgb: [254, 231, 155],
      value: -0.44,
    },
    {
      rgb: [254, 232, 156],
      value: -0.43,
    },
    {
      rgb: [254, 233, 158],
      value: -0.42,
    },
    {
      rgb: [254, 234, 160],
      value: -0.41,
    },
    {
      rgb: [254, 235, 162],
      value: -0.4,
    },
    {
      rgb: [254, 235, 163],
      value: -0.39,
    },
    {
      rgb: [254, 236, 166],
      value: -0.38,
    },
    {
      rgb: [254, 237, 167],
      value: -0.37,
    },
    {
      rgb: [254, 238, 168],
      value: -0.36,
    },
    {
      rgb: [254, 238, 170],
      value: -0.35,
    },
    {
      rgb: [254, 240, 172],
      value: -0.34,
    },
    {
      rgb: [254, 240, 174],
      value: -0.33,
    },
    {
      rgb: [254, 241, 175],
      value: -0.32,
    },
    {
      rgb: [254, 241, 177],
      value: -0.31,
    },
    {
      rgb: [254, 243, 179],
      value: -0.3,
    },
    {
      rgb: [254, 243, 181],
      value: -0.29,
    },
    {
      rgb: [254, 244, 182],
      value: -0.28,
    },
    {
      rgb: [254, 245, 185],
      value: -0.27,
    },
    {
      rgb: [254, 246, 186],
      value: -0.26,
    },
    {
      rgb: [254, 246, 187],
      value: -0.25,
    },
    {
      rgb: [255, 247, 189],
      value: -0.24,
    },
    {
      rgb: [255, 247, 191],
      value: -0.23,
    },
    {
      rgb: [255, 247, 192],
      value: -0.22,
    },
    {
      rgb: [255, 248, 194],
      value: -0.21,
    },
    {
      rgb: [255, 248, 196],
      value: -0.2,
    },
    {
      rgb: [255, 248, 198],
      value: -0.19,
    },
    {
      rgb: [255, 249, 199],
      value: -0.18,
    },
    {
      rgb: [255, 249, 200],
      value: -0.17,
    },
    {
      rgb: [255, 249, 203],
      value: -0.16,
    },
    {
      rgb: [255, 250, 204],
      value: -0.15,
    },
    {
      rgb: [255, 250, 205],
      value: -0.14,
    },
    {
      rgb: [255, 250, 207],
      value: -0.13,
    },
    {
      rgb: [255, 251, 209],
      value: -0.12,
    },
    {
      rgb: [255, 251, 210],
      value: -0.11,
    },
    {
      rgb: [255, 251, 212],
      value: -0.1,
    },
    {
      rgb: [255, 252, 214],
      value: -0.09,
    },
    {
      rgb: [255, 252, 216],
      value: -0.08,
    },
    {
      rgb: [255, 252, 217],
      value: -0.07,
    },
    {
      rgb: [255, 252, 218],
      value: -0.06,
    },
    {
      rgb: [255, 253, 221],
      value: -0.05,
    },
    {
      rgb: [255, 253, 222],
      value: -0.04,
    },
    {
      rgb: [255, 253, 223],
      value: -0.03,
    },
    {
      rgb: [255, 254, 225],
      value: -0.02,
    },
    {
      rgb: [255, 254, 227],
      value: -0.01,
    },
    {
      rgb: [255, 255, 229],
      value: 0,
    },
    {
      rgb: [255, 255, 229],
      value: 0.01,
    },
    {
      rgb: [254, 254, 226],
      value: 0.02,
    },
    {
      rgb: [254, 254, 224],
      value: 0.03,
    },
    {
      rgb: [253, 254, 223],
      value: 0.04,
    },
    {
      rgb: [253, 254, 222],
      value: 0.05,
    },
    {
      rgb: [253, 254, 219],
      value: 0.06,
    },
    {
      rgb: [252, 254, 217],
      value: 0.07,
    },
    {
      rgb: [252, 254, 216],
      value: 0.08,
    },
    {
      rgb: [252, 254, 215],
      value: 0.09,
    },
    {
      rgb: [251, 253, 212],
      value: 0.1,
    },
    {
      rgb: [251, 253, 211],
      value: 0.11,
    },
    {
      rgb: [251, 253, 209],
      value: 0.12,
    },
    {
      rgb: [250, 253, 206],
      value: 0.13,
    },
    {
      rgb: [250, 253, 205],
      value: 0.14,
    },
    {
      rgb: [250, 253, 204],
      value: 0.15,
    },
    {
      rgb: [250, 253, 202],
      value: 0.16,
    },
    {
      rgb: [249, 253, 200],
      value: 0.17,
    },
    {
      rgb: [249, 252, 198],
      value: 0.18,
    },
    {
      rgb: [249, 252, 197],
      value: 0.19,
    },
    {
      rgb: [248, 252, 194],
      value: 0.2,
    },
    {
      rgb: [248, 252, 193],
      value: 0.21,
    },
    {
      rgb: [248, 252, 191],
      value: 0.22,
    },
    {
      rgb: [247, 252, 190],
      value: 0.23,
    },
    {
      rgb: [247, 252, 187],
      value: 0.24,
    },
    {
      rgb: [247, 252, 186],
      value: 0.25,
    },
    {
      rgb: [246, 251, 184],
      value: 0.26,
    },
    {
      rgb: [245, 251, 184],
      value: 0.27,
    },
    {
      rgb: [244, 250, 182],
      value: 0.28,
    },
    {
      rgb: [243, 250, 182],
      value: 0.29,
    },
    {
      rgb: [242, 250, 181],
      value: 0.3,
    },
    {
      rgb: [240, 249, 180],
      value: 0.31,
    },
    {
      rgb: [239, 248, 179],
      value: 0.32,
    },
    {
      rgb: [238, 248, 178],
      value: 0.33,
    },
    {
      rgb: [237, 248, 178],
      value: 0.34,
    },
    {
      rgb: [235, 247, 176],
      value: 0.35,
    },
    {
      rgb: [234, 247, 175],
      value: 0.36,
    },
    {
      rgb: [233, 246, 175],
      value: 0.37,
    },
    {
      rgb: [232, 246, 174],
      value: 0.38,
    },
    {
      rgb: [230, 245, 173],
      value: 0.39,
    },
    {
      rgb: [229, 245, 172],
      value: 0.4,
    },
    {
      rgb: [229, 244, 171],
      value: 0.41,
    },
    {
      rgb: [227, 244, 170],
      value: 0.42,
    },
    {
      rgb: [226, 243, 169],
      value: 0.43,
    },
    {
      rgb: [225, 243, 169],
      value: 0.44,
    },
    {
      rgb: [224, 242, 168],
      value: 0.45,
    },
    {
      rgb: [222, 242, 166],
      value: 0.46,
    },
    {
      rgb: [221, 241, 166],
      value: 0.47,
    },
    {
      rgb: [220, 241, 165],
      value: 0.48,
    },
    {
      rgb: [219, 241, 164],
      value: 0.49,
    },
    {
      rgb: [217, 240, 163],
      value: 0.5,
    },
    {
      rgb: [216, 239, 162],
      value: 0.51,
    },
    {
      rgb: [215, 239, 162],
      value: 0.52,
    },
    {
      rgb: [212, 238, 160],
      value: 0.53,
    },
    {
      rgb: [211, 237, 160],
      value: 0.54,
    },
    {
      rgb: [209, 236, 159],
      value: 0.55,
    },
    {
      rgb: [208, 236, 158],
      value: 0.56,
    },
    {
      rgb: [205, 235, 157],
      value: 0.57,
    },
    {
      rgb: [204, 234, 156],
      value: 0.58,
    },
    {
      rgb: [202, 233, 156],
      value: 0.59,
    },
    {
      rgb: [200, 232, 154],
      value: 0.6,
    },
    {
      rgb: [198, 232, 154],
      value: 0.61,
    },
    {
      rgb: [197, 231, 153],
      value: 0.62,
    },
    {
      rgb: [195, 230, 152],
      value: 0.63,
    },
    {
      rgb: [193, 229, 151],
      value: 0.64,
    },
    {
      rgb: [191, 229, 150],
      value: 0.65,
    },
    {
      rgb: [190, 228, 150],
      value: 0.66,
    },
    {
      rgb: [189, 227, 149],
      value: 0.67,
    },
    {
      rgb: [186, 226, 148],
      value: 0.68,
    },
    {
      rgb: [184, 226, 147],
      value: 0.69,
    },
    {
      rgb: [183, 225, 147],
      value: 0.7,
    },
    {
      rgb: [180, 224, 145],
      value: 0.71,
    },
    {
      rgb: [179, 223, 145],
      value: 0.72,
    },
    {
      rgb: [178, 223, 144],
      value: 0.73,
    },
    {
      rgb: [176, 222, 143],
      value: 0.74,
    },
    {
      rgb: [173, 221, 142],
      value: 0.75,
    },
    {
      rgb: [172, 220, 141],
      value: 0.76,
    },
    {
      rgb: [170, 220, 141],
      value: 0.77,
    },
    {
      rgb: [169, 219, 140],
      value: 0.78,
    },
    {
      rgb: [165, 217, 139],
      value: 0.79,
    },
    {
      rgb: [164, 217, 138],
      value: 0.8,
    },
    {
      rgb: [162, 216, 137],
      value: 0.81,
    },
    {
      rgb: [159, 214, 136],
      value: 0.82,
    },
    {
      rgb: [157, 214, 135],
      value: 0.83,
    },
    {
      rgb: [155, 213, 135],
      value: 0.84,
    },
    {
      rgb: [154, 212, 134],
      value: 0.85,
    },
    {
      rgb: [150, 211, 133],
      value: 0.86,
    },
    {
      rgb: [149, 210, 132],
      value: 0.87,
    },
    {
      rgb: [147, 209, 131],
      value: 0.88,
    },
    {
      rgb: [145, 209, 131],
      value: 0.89,
    },
    {
      rgb: [142, 207, 129],
      value: 0.9,
    },
    {
      rgb: [140, 207, 129],
      value: 0.91,
    },
    {
      rgb: [139, 206, 128],
      value: 0.92,
    },
    {
      rgb: [135, 204, 127],
      value: 0.93,
    },
    {
      rgb: [134, 204, 126],
      value: 0.94,
    },
    {
      rgb: [132, 203, 125],
      value: 0.95,
    },
    {
      rgb: [130, 202, 125],
      value: 0.96,
    },
    {
      rgb: [127, 201, 123],
      value: 0.97,
    },
    {
      rgb: [125, 200, 123],
      value: 0.98,
    },
    {
      rgb: [124, 199, 122],
      value: 0.99,
    },
    {
      rgb: [120, 198, 121],
      value: 1,
    },
  ],
  spiLegends: [
    {
      color: '#972625',
      text: '-2.5 to -2',
    },
    {
      color: '#EC0000',
      text: '-2 to -1.5',
    },
    {
      color: '#FF7300',
      text: '-1.5 to -1',
    },
    {
      color: '#FEE100',
      text: '-1 to -0.5',
    },
    {
      color: '#81FF5E',
      text: '-0.5 to 0',
    },
    {
      color: '#00FFFF',
      text: '0 to 0.5',
    },
    {
      color: '#00BEFF',
      text: '0.5 to 1',
    },
    {
      color: '#006FFF',
      text: '1 to 1.5',
    },
    {
      color: '#3C00E4',
      text: '1.5 to 2',
    },
    {
      color: '#3716A3',
      text: '2 to 2.5',
    },
  ],
  tileServerAPI:
    'https://s9oktod2p7.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server',
  segmentsProps: [
    {
      layerId: 'ganganagarsegments',
      sourceId: 'ganganagarsegments',
      sourceLayer: 'ganganagarsegments2',
      tilesetURL: 'mapbox://farmguide.ganganagarsegments2',
      region: 'ganganagar',
    },
    {
      layerId: 'haryana-2019',
      sourceId: 'haryana-2019',
      sourceLayer: 'haryana-2019',
      tilesetURL: 'mapbox://farmguide.haryana-2019',
      year: 2019,
      region: 'haryana',
    },
    {
      layerId: 'haryana-2018',
      sourceId: 'haryana-2018',
      sourceLayer: 'haryana-2018',
      tilesetURL: 'mapbox://farmguide.haryana-2018',
      year: 2018,
      region: 'haryana',
    },
    {
      layerId: 'karnataka-2019',
      sourceId: 'karnataka-2019',
      sourceLayer: 'karnataka-2019',
      tilesetURL: 'mapbox://farmguide.karnataka-2019',
      region: 'karnataka',
    },
    {
      layerId: 'bihar-2019',
      sourceId: 'bihar-2019',
      sourceLayer: 'bihar-2019',
      tilesetURL: 'mapbox://farmguide.bihar-2019',
      region: 'bihar',
    },
  ],
  segmentCommonProps: {
    fillZoomExtent: [1, 20],
    lineZoomExtent: [15, 20],
    fillOpacity: [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      1,
      8,
      1,
      10,
      1,
      13,
      1,
      22,
      1,
    ],
    minZoomForEvents: 13,
    flyToOptionsOnClick: {
      zoom: 15.5,
      speed: 1,
    },
  },
};
