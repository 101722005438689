import React from 'react';

const loaderHead = {
  position: 'relative',
  // top: '170px',
  left: '0px',
  right: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '300px',
  background:
    'rgba(255,255,255, 0.9) url(https://d312obd2q0lyz0.cloudfront.net/public/gif/weat-animation-72.gif) no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '50',
  zIndex: '4000',
};

export default () => <div style={loaderHead} />;
