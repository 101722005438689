import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import {
  PieChart, Pie, Cell, Sector, Tooltip,
} from 'recharts';
import _ from 'lodash';

const COLORS = ['#dbecf8', '#2c82be', '#53a8e2', '#76ddfb'];

const TotalFarmer = 1000;
const TotalSms = 500;
const TotalDel = 250;
const TotalRes = 100;

const first = TotalFarmer - TotalSms;
const second = TotalSms - TotalDel;
const third = TotalDel - TotalRes;
const fourth = TotalRes;

const CustomTooltip = (props) => {
  const { activeData } = props;
  return (
    <React.Fragment>
      {!_.isEmpty(activeData) && (
        <div className="pie-tooltip">
          <p>
            {activeData.name}
            {' '}
:
            {`(${(activeData.percent * 100).toFixed(2)}%)`}
            {' '}
          </p>
          {/* <ul>
          <li>
            Stage 1
            {' '}
            <span>300</span>
          </li>
          <li>
            Stage 1
            {' '}
            <span>200</span>
          </li>
          <li>
            Stage 1
            {' '}
            <span>100</span>
          </li>
        </ul> */}
        </div>
      )}
    </React.Fragment>
  );
};

const data1 = [
  { name: 'Total Calls', value: first },
  { name: 'Received Calls', value: second },
  { name: 'Missed Calls', value: third },
  { name: 'Busy Calls', value: fourth },
];

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 15}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default class IvrsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReportAvailable: false,
      activeIndex: null,
    };
  }

  handleTabChange = () => {
    const { anotherTab } = this.props;
    anotherTab();
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      activeData: data,
    });
  };

  onPieLeave = () => {
    this.setState({
      activeIndex: null,
    });
  };

  render() {
    const { isReportAvailable, activeIndex, activeData } = this.state;
    return (
      <React.Fragment>
        <div className="ivrsReportMain">
          {!isReportAvailable ? (
            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                <div className="ivrsReportContent">
                  <div className="ivrsReportBody">
                    <img src="images/call.svg" alt="" title="" />
                    <h1>No IVRS available</h1>
                    <div className="btn">
                      <Button
                        className="btn-success"
                        onClick={this.handleTabChange}
                      >
                        Create new IVRS
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={0}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <aside>
                  <div className="asideContent">
                    <div className="asideHead">
                      <Grid container>
                        <Grid item lg={12} md={12} xs={12}>
                          <div className="asideHeadLeft">
                            <h1>IVRS Name</h1>
                          </div>
                          <div className="asideHeadRight">
                            <i
                              className="material-icons  asideIcon"
                              style={{ cursor: 'default' }}
                            >
                              search
                            </i>
                            <i
                              className="material-icons"
                              style={{ cursor: 'default' }}
                            >
                              more_vert
                            </i>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="asideBody">
                      <Grid container>
                        <Grid item lg={12} md={12} xs={12}>
                          <ul>
                            <li className="left active">
                              <span className="list-img pull-left">
                                <img
                                  alt="Call Icon"
                                  src="images/phone-call.svg"
                                />
                              </span>
                              <div className="list-body">
                                <div className="list-heading">
                                  <h1 className="pull-left">
                                    Mandi Response
                                    <span className="pull-right">
                                      <strong>Date:</strong>
                                      {' '}
16 Sep’19
                                    </span>
                                  </h1>
                                </div>
                                <div className="sub-heading">
                                  <h1 className="pull-left">
                                    Total Farmers: 15,000
                                    <span className="pull-right">10:27 AM</span>
                                  </h1>
                                  <ul>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="left active">
                              <span className="list-img pull-left">
                                <img
                                  alt="Call Icon"
                                  src="images/phone-call.svg"
                                />
                              </span>
                              <div className="list-body">
                                <div className="list-heading">
                                  <h1 className="pull-left">
                                    Mandi Response
                                    <span className="pull-right">
                                      <strong>Date:</strong>
                                      {' '}
16 Sep’19
                                    </span>
                                  </h1>
                                </div>
                                <div className="sub-heading">
                                  <h1 className="pull-left">
                                    Total Farmers: 15,000
                                    <span className="pull-right">10:27 AM</span>
                                  </h1>
                                  <ul>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="left active">
                              <span className="list-img pull-left">
                                <img
                                  alt="Call Icon"
                                  src="images/phone-call.svg"
                                />
                              </span>
                              <div className="list-body">
                                <div className="list-heading">
                                  <h1 className="pull-left">
                                    Mandi Response
                                    <span className="pull-right">
                                      <strong>Date:</strong>
                                      {' '}
16 Sep’19
                                    </span>
                                  </h1>
                                </div>
                                <div className="sub-heading">
                                  <h1 className="pull-left">
                                    Total Farmers: 15,000
                                    <span className="pull-right">10:27 AM</span>
                                  </h1>
                                  <ul>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="left active">
                              <span className="list-img pull-left">
                                <img
                                  alt="Call Icon"
                                  src="images/phone-call.svg"
                                />
                              </span>
                              <div className="list-body">
                                <div className="list-heading">
                                  <h1 className="pull-left">
                                    Mandi Response
                                    <span className="pull-right">
                                      <strong>Date:</strong>
                                      {' '}
16 Sep’19
                                    </span>
                                  </h1>
                                </div>
                                <div className="sub-heading">
                                  <h1 className="pull-left">
                                    Total Farmers: 15,000
                                    <span className="pull-right">10:27 AM</span>
                                  </h1>
                                  <ul>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                    <li>
                                      <i className="material-icons">
                                        local_offer
                                      </i>
                                      Tag 1
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </aside>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <section>
                  <div className="sectionContent posterWrap">
                    <div className="sectionHeader">
                      <Grid container>
                        <Grid item lg={12} md={12} xs={12}>
                          <div className="sectionHeaderLeft">
                            <h1>Details</h1>
                          </div>
                          <div className="sectionHeaderRight">
                            <i className="material-icons">get_app</i>
                            Download Report
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="sectionBody">
                      <Grid container>
                        <Grid item lg={12} md={12} xs={12}>
                          <h2>Crop Insurance</h2>
                          <p>
                            <span className="date">
                              <strong>Date:</strong>
                              {' '}
16 Sep’19
                            </span>
                            {' '}
                            |
                            {' '}
                            <span className="time"> 10:27 AM</span>
                          </p>
                          <h3>
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem Sed ut perspiciatis unde omnis iste natus
                            error sit voluptatem.
                          </h3>
                          <h4>
                            Tags:
                            <ul>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                              <li>
                                <i className="material-icons">local_offer</i>
                                Tag 1
                              </li>
                            </ul>
                          </h4>
                        </Grid>
                        <Grid container>
                          <Grid item lg={12} md={12} xs={12}>
                            <h5>Graph Representaion:</h5>
                          </Grid>
                          <Grid item lg={6} md={6} xs={12}>
                            <div className="graphContent">
                              <PieChart
                                width={600}
                                height={300}
                                style={{
                                  marginTop: '-70px',
                                  marginLeft: '-70px',
                                }}
                              >
                                <text
                                  x="300"
                                  y={200}
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                  className="circle-chart__subline"
                                  style={{ fontSize: 15 }}
                                >
                                  <tspan x="300" y="200">
                                    20
                                  </tspan>
                                  <tspan x="300" y="210" dy="1.0em">
                                    Responses
                                  </tspan>
                                </text>
                                <Pie
                                  activeIndex={activeIndex}
                                  activeShape={renderActiveShape}
                                  data={data1}
                                  dataKey="value"
                                  cx={300}
                                  cy={200}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  blendStroke
                                  onMouseEnter={this.onPieEnter}
                                  onMouseLeave={this.onPieLeave}
                                >
                                  {data1.map((entry, index) => (
                                    <Cell
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                                <Tooltip
                                  content={
                                    <CustomTooltip activeData={activeData} />
                                  }
                                />
                              </PieChart>
                            </div>
                          </Grid>
                          <Grid item lg={6} md={6} xs={12}>
                            <div className="graphContent">
                              <div className="graph-card">
                                <div className="graph-card__header">
                                  <p>Total Calls: 1500</p>
                                </div>
                                <div className="graph-card__body">
                                  <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                      <ul>
                                        <li>
                                          <i className="material-icons">
                                            fiber_manual_record
                                          </i>
                                          <span>
                                            <em>10%</em>
                                            Recieved Calls:
                                            <strong>View</strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="material-icons">
                                            fiber_manual_record
                                          </i>
                                          <span>
                                            <em>15%</em>
                                            Missed Calls:
                                            <strong>View</strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="material-icons">
                                            fiber_manual_record
                                          </i>
                                          <span>
                                            <em>20%</em>
                                            Busy Calls:
                                            <strong>View</strong>
                                          </span>
                                        </li>
                                        <li>
                                          <i className="material-icons">
                                            fiber_manual_record
                                          </i>
                                          <span>
                                            <em>55%</em>
                                            Not Reachable:
                                            <strong>View</strong>
                                            <ul>
                                              <li>
                                                <span>
                                                  <em>30%</em>
                                                  Stage 1
                                                  <strong>View</strong>
                                                </span>
                                              </li>
                                              <li>
                                                <span>
                                                  <em>15%</em>
                                                  Stage 2
                                                  <strong>View</strong>
                                                </span>
                                              </li>
                                              <li>
                                                <span>
                                                  <em>10%</em>
                                                  Stage 3
                                                  <strong>View</strong>
                                                </span>
                                              </li>
                                            </ul>
                                          </span>
                                        </li>
                                      </ul>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {/*          <Grid item lg={12} md={12} xs={12}>
                          <h5>Graph Representaion:</h5>
                          <div className="graphContent">
                              <PieChart width={600} height={300}>
                                <text
                                  x="300"
                                  y={200}
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                  className="circle-chart__subline"
                                  style={{fontSize:15}}
                                >
                                  <tspan x="300" y="200" >Your Responses</tspan>
                                  <tspan x="300" y="210" dy="1.0em">300</tspan>
                                </text>
                                <Pie
                                  activeIndex={this.state.activeIndex}
                                  activeShape={renderActiveShape}
                                  data={data}
                                  cx={300}
                                  cy={200}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  blendStroke
                                  onMouseEnter={this.onPieEnter}
                                  onMouseLeave={this.onPieLeave}
                                >
                                  {
                                    data.map((entry, index) =>
                                    <Cell fill={COLORS[index % COLORS.length]}/>)
                                  }
                                </Pie>

                              </PieChart>

                                 <svg
                              className="circle-chart"
                              viewBox="0 0 33.83098862 33.83098862"
                              width="200"
                              height="200"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                className="circle-chart__background"
                                stroke="#efefef"
                                strokeWidth="2"
                                fill="none"
                                cx="16.91549431"
                                cy="16.91549431"
                                r="15.91549431"
                              />
                              <circle
                                className="circle-chart__circle"
                                stroke="#00acc1"
                                strokeWidth="2"
                                strokeDasharray="30,100"
                                strokeLinecap="round"
                                fill="none"
                                cx="16.91549431"
                                cy="16.91549431"
                                r="15.91549431"
                              />
                              <g className="circle-chart__info">
                                <text
                                  className="circle-chart__percent"
                                  x="16.91549431"
                                  y="15.5"
                                  alignmentBaseline="central"
                                  textAnchor="middle"
                                  fontSize="8"
                                >
                                  30%
                                </text>
                                <text
                                  className="circle-chart__subline"
                                  x="16.91549431"
                                  y="20.5"
                                  alignmentBaseline="central"
                                  textAnchor="middle"
                                  fontSize="2"
                                >
                                  Yay 30% progress!
                                </text>
                              </g>
                            </svg>
                            <div className="graph-card">
                              <div className="graph-card__header">
                                <p>Total Calls: 1500</p>
                              </div>
                              <div className="graph-card__body">
                                <Grid container>
                                  <Grid item lg={12} md={12} xs={12}>
                                    <ul>
                                      <li>
                                        <i className="material-icons">
                                          fiber_manual_record
                                        </i>
                                        <span>
                                          Recieved Calls:
                                          <strong>600</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="material-icons">
                                          fiber_manual_record
                                        </i>
                                        <span>
                                          Missed Calls:
                                          <strong>600</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="material-icons">
                                          fiber_manual_record
                                        </i>
                                        <span>
                                          Busy Calls:
                                          <strong>600</strong>
                                        </span>
                                      </li>
                                      <li>
                                        <i className="material-icons">
                                          fiber_manual_record
                                        </i>
                                        <span>
                                          Not Reachable:
                                          <strong>600</strong>
                                        </span>
                                      </li>
                                    </ul>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </Grid> */}
                        <Grid item lg={12} md={12} xs={12}>
                          <div className="tips btn">
                            <h6>Healthy Tips:</h6>
                            <Button className="btn-success">Show me how</Button>
                            <p>
                              Convert your responses into customers by
                              connecting with dealers.
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          )}
        </div>
      </React.Fragment>
    );
  }
}
