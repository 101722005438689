import React from 'react';
import { Button, Dialog } from '@material-ui/core';
// import Modal from 'react-responsive-modal';

const AccountSetUpModal = (props) => {
  const {
    show,
    handleClose,
    handleSelectedFarm,
    displayContent,
    language,
  } = props;
  return (
    // <Modal
    //   open={show}
    //   showCloseIcon={false}
    //   center
    //   onClose={() => {
    //     handleClose();
    //   }}
    //   className="registerModal modalHead"
    // >
    <Dialog
      maxWidth="xs"
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={show}
      className="registerModal modalHead"
    >
      <div className="paper">
        <div className="modalSec">
          <div className="modalContent">
            {displayContent}
            <ul>
              <li className="darkgreenBtnHeader">
                <Button
                  variant="contained"
                  className="button darkgreenBtn"
                  onClick={() => {
                    handleSelectedFarm();
                  }}
                >
                  {(language.hindi && language.hindi.Yes) || 'Yes'}
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  className="button darkgreenBtn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {(language.hindi && language.hindi.No) || 'No'}
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AccountSetUpModal;
