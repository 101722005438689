import React from 'react';

const SearchControl = (props) => {
  const {
    addExpandClass,
    searchPlace,
    handlePlaceChange,
    handleClickSearch,
  } = props;

  return (
    <div
      className={addExpandClass ? 'searchPanel expandSearch' : 'searchPanel'}
    >
      <input
        type="text"
        id="autocomplete_search"
        value={searchPlace}
        onChange={e => handlePlaceChange(e)}
        placeholder="Enter search location"
      />
      <button type="button" className="searchBtn" onClick={handleClickSearch}>
        <i className="material-icons">search</i>
      </button>
    </div>
  );
};

export default SearchControl;
