import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { Grid } from '@material-ui/core';
import DashboardSidePanel from '../common/dashboardSidePannel';
import ScrollableTabPanel from '../common/ScrollableTabPanel';
import IVRS from '../ivrs/index';
import SMS from '../sms/index';
import Insights from './insights';
// import SelectPoster from './selectPoster';
// import Reports from '../scheduleReport/reports';
import TaggingTab from './taggingTab';
import '../../css/common.scss';
import '../../css/button.scss';
import '../../css/rightPanel.scss';

class Tagging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandPanel: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    window.location.hash = '';
    this.broadcastTabChange(1);
  }

  onExpandClick = () => {
    const { expandPanel } = this.state;
    this.setState({
      expandPanel: !expandPanel,
    });
  };

  anotherTab = (tab) => {
    if (tab === 'Tagging') {
      this.setState({
        activeTab: 1,
      });
    }
  };

  broadcastTabChange = (tab) => {
    console.log('tab', tab);
    // if (tab !== 1) {
    //   this.setState({
    //     activeTab: '',
    //   });
    // }
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { expandPanel, activeTab } = this.state;
    const { cookies } = this.props;
    const userdata = cookies.get('userData');
    let role = '';
    if (userdata) {
      const { data } = userdata;
      const { roles } = data;
      if (roles && roles.length > 0) {
        role = roles[0].roleName;
      }
      console.log('role', role);
    }
    console.log('activeTab', activeTab);
    const tabsData = [
      {
        tabName: 'Insights',
        // disabled: true,
        component:
          activeTab === 0 ? (
            <Insights anotherTab={this.anotherTab} activeTab={activeTab} />
          ) : null,
      },
      {
        tabName: 'Tagging Campaign',
        component: <TaggingTab />,
      },
      /* {
        tabName: 'Tagging1',
        component: <SelectPoster />,
      }, */
      {
        tabName: 'IVRS Campaign',
        // disabled: role === '',
        component: <IVRS />,
      },
      {
        tabName: 'SMS Campaign',
        // disabled: role === '',
        component: <SMS />,
      },
      /* {
        tabName: 'Reports1',
        disabled: true,
        component: <Reports />,
      }, */
    ];
    return (
      <div>
        <section
          className={
            !expandPanel
              ? 'root panel mainPanel miniPanel'
              : 'root panel mainPanel miniPanel expandPanel'
          }
        >
          <Grid container>
            <DashboardSidePanel />
            <Grid item xs={12} sm={7} className="mapPanel">
              <button
                type="button"
                className="app-nav-action"
                onClick={this.onExpandClick}
              >
                <span className="app-nav-action__item" />
              </button>
              <ScrollableTabPanel
                tabsData={tabsData}
                customStyle="taggingTabPanel"
                activeTab={activeTab}
                broadcastTabChange={this.broadcastTabChange}
              />
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    null,
  )(withCookies(Tagging)),
);
