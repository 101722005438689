import React from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Select,
  Button,
} from '@material-ui/core';
import MultiSelect from 'react-select';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import { PieChart } from '../../common/chart';
import BarChartComponent from '../barChart';
import '../../../css/agricultureIndices.scss';

function createData(name, calories, fat, carbs, protein, abs) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    abs,
  };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const piChartData = [
  { name: 'Group A', value: 400, color: '#0088FE' },
  { name: 'Group B', value: 200, color: '#36B37E' },
  { name: 'Group C', value: 100, color: '#FF8B00' },
];

const barchartData = [
  {
    years: '2017',
    early: 4000,
    mid: 2400,
    late: 3400,
  },
  {
    years: '2018',
    early: 3000,
    mid: 1400,
    late: 2400,
  },
  {
    years: '2019',
    early: 2000,
    mid: 400,
    late: 1400,
  },
];

const options = [
  { value: 'farm1', label: 'farm1' },
  { value: 'farm2', label: 'farm2' },
];

class FarmTagSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenerate: false,
      expandPanel: false,
    };
  }

  _generateData = () => {
    this.setState({
      isGenerate: true,
    });
  };

  render() {
    const { isGenerate, expandPanel } = this.state;
    const { broadcastLocationChange } = this.props;

    let yearHtml = [];
    yearHtml = [2016, 2017, 2018, 2019].map(item => (
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    ));

    let seasonHtml = [];
    seasonHtml = [
      { label: 'Kharif', value: 1 },
      { label: 'Rabi', value: 2 },
      // { label: 'Zaid', value: 3 },
    ].map(item => (
      <MenuItem value={item.value} key={item.label}>
        {item.label}
      </MenuItem>
    ));

    return (
      <div>
        {isGenerate ? (
          <div className="indicesMainPanel">
            <div
              className={
                expandPanel ? 'indicesList expandPanel' : 'indicesList'
              }
            >
              {/* <div className=""> */}
              <Grid container>
                <Grid item xs={3}>
                  <h4>Farm Tag</h4>
                </Grid>
                <Grid item xs={6} className="indicesList">
                  <ul>
                    <li>2018</li>
                    <li>Rabi</li>
                  </ul>
                </Grid>
                <Grid item xs={3}>
                  <h4>
                    <a href="/" onClick={this._resetLocation}>
                      Edit Selection
                    </a>
                  </h4>
                </Grid>
              </Grid>

              <ul>
                <li>Haryana</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
                <li>Fatehbad</li>
              </ul>
              <Grid
                container
                style={{
                  borderBottom: '1px solid #d7d7d7',
                  paddingBottom: '15px',
                }}
              >
                <Grid item xs={3}>
                  <h4>Farms</h4>
                </Grid>
                <Grid item xs={9} className="indicesList">
                  <ul>
                    <li>Farm1</li>
                    <li>Farm2</li>
                  </ul>
                </Grid>
              </Grid>
              <Grid
                container
                className="indicesList bg-green"
                style={{ borderBottom: 'solid 1px #d7d7d7' }}
              >
                <Grid item xs={9} className="indicesList bg-green">
                  <h4>Target farmers in the region through farmguide</h4>
                </Grid>
                <Grid item xs={3} className="indicesList bg-green">
                  <h4>
                    <a href="/">Lead Generator</a>
                  </h4>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="indicesList bg-gray">
                  <h4>Graphical Analysis</h4>
                </Grid>
              </Grid>
            </div>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="rechartsPanel">
                  <Grid container className="panelDivision">
                    <Grid item xs={7}>
                      <h4>Farm Analysis</h4>
                      <div className="pie-chart">
                        <PieChart data={piChartData} showLegend={false} />
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl className="formControl inputHead coloredForms inputFullWidth">
                        <Select
                          disableUnderline
                          name="dashboard-user"
                          className="selectEmpty modalDropdown"
                          inputProps={{ 'aria-label': 'user' }}
                          value="Maize"
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Maize">Maize</MenuItem>
                          <MenuItem value="Wheat">Wheet</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={7}>
                      <h4>Business Engagement</h4>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl className="formControl inputHead coloredForms multiSelect half-width">
                        <MultiSelect
                          isMulti
                          name="user"
                          options={options}
                          value={options.value}
                          className="selectInner"
                          classNamePrefix="select"
                          placeholder="Select"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="line-chart">
                        <BarChartComponent
                          data={barchartData}
                          yAxisLabel="Parameters"
                          dataKey="early"
                          dataKey1="mid"
                          dataKey2="late"
                          xAxisDataKey="dates"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <Grid container className="indicesList bg-gray">
              <Grid item xs={6} className="indicesList bg-gray">
                <h4>Lorem</h4>
              </Grid>
              <Grid item xs={6} className="indicesList bg-gray" />
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <h4>Account List</h4>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControl className="formControl inputHead coloredForms inputFullWidth">
                      <Select
                        disableUnderline
                        name="dashboard-user"
                        className="selectEmpty modalDropdown"
                        inputProps={{ 'aria-label': 'user' }}
                        value=""
                        displayEmpty
                        onChange={ev => this._handleSatelliteSource(ev)}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Maize">Maize</MenuItem>
                        <MenuItem value="Wheat">Wheet</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="formControl inputHead coloredForms inputFullWidth">
                      <Select
                        disableUnderline
                        name="dashboard-user"
                        className="selectEmpty modalDropdown"
                        inputProps={{ 'aria-label': 'user' }}
                        value=""
                        displayEmpty
                        onChange={ev => this._handleSatelliteSource(ev)}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Maize">Maize</MenuItem>
                        <MenuItem value="Wheat">Wheet</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="insightTable tableWrapper responsive-table">
                  <Table className="table" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell align="right">A/C Number</TableCell>
                        <TableCell align="right">Area</TableCell>
                        <TableCell align="right">Crop</TableCell>
                        <TableCell align="right">Call to Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.carbs}</TableCell>
                          <TableCell align="right">
                            {' '}
                            <FormControl
                              style={{ border: 'none' }}
                              className="formControl inputHead coloredForms"
                            >
                              <Select
                                disableUnderline
                                inputProps={{ 'aria-label': 'age' }}
                                className="selectEmpty modalDropdown"
                                value="1"
                                displayEmpty
                              >
                                {seasonHtml}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                {/* </div> */}
              </div>
            </div>
            <Grid container className="indicesList bg-gray">
              <Grid item xs={12} className="indicesList bg-gray">
                <h4>
                  Add Farms
                  <a href="/">Select On Map</a>
                </h4>
              </Grid>
            </Grid>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <Grid container>
                  <Grid item xs={4}>
                    <FormControl className="formControl inputHead coloredForms">
                      <Select
                        disableUnderline
                        inputProps={{ 'aria-label': 'age' }}
                        className="selectEmpty modalDropdown"
                        value="1"
                        displayEmpty
                      >
                        {seasonHtml}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl className="formControl inputHead coloredForms">
                      <Select
                        disableUnderline
                        inputProps={{ 'aria-label': 'age' }}
                        className="selectEmpty modalDropdown"
                        value="1"
                        displayEmpty
                      >
                        {seasonHtml}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl className="formControl inputHead coloredForms">
                      <Select
                        disableUnderline
                        inputProps={{ 'aria-label': 'age' }}
                        className="selectEmpty modalDropdown"
                        value="1"
                        displayEmpty
                      >
                        {seasonHtml}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl className="formControl inputHead coloredForms">
                      <Select
                        disableUnderline
                        inputProps={{ 'aria-label': 'age' }}
                        className="selectEmpty modalDropdown"
                        value="1"
                        displayEmpty
                      >
                        {seasonHtml}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      id="mobile"
                      label="Name"
                      className="textField inputHead customText-width small-textfield"
                      style={{ width: '75%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      id="mobile"
                      label="Name"
                      className="textField inputHead customText-width"
                      style={{ width: '75%' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid container className="indicesScrollableLink">
              <Grid item xs={12} className="darkgreenBtnHeader">
                <Button className="darkgreenBtn-lg">Add Farms</Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="agriIndices selectController">
            <h4 className="tabpanelHeading">Farm Tag</h4>
            <Grid item xs={12}>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value="2016"
                  displayEmpty
                >
                  {yearHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  inputProps={{ 'aria-label': 'age' }}
                  className="selectEmpty modalDropdown"
                  value="1"
                  displayEmpty
                >
                  {seasonHtml}
                </Select>
              </FormControl>
              <LocationHeirarchy
                type="selectEmpty modalDropdown"
                calledFrom="dashboard"
                broadcastLocationChange={broadcastLocationChange}
              />
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <h4 className="tabpanelHeading">Farms</h4>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <FormControl className="formControl inputHead coloredForms multiSelect full-width">
                  <MultiSelect
                    isMulti
                    name="user"
                    options={options}
                    value={options.value}
                    className="selectInner"
                    classNamePrefix="select"
                    placeholder="Select Multiple"
                  />
                </FormControl>
              </Grid>
              <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6} className="agriIndices btn">
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-success floatRight"
                    onClick={this._generateData}
                  >
                    Generate Data
                    <i className="material-icons">arrow_right_alt</i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default FarmTagSelectTab;
