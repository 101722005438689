import React from 'react';

const MapControl = props => (
  <div className="mapboxgl-ctrl-top-right">
    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
      <button
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
        type="button"
        title="Zoom in"
        aria-label="Zoom in"
        onClick={() => props.handleZoomChange('plus')}
      />
      <button
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
        type="button"
        title="Zoom out"
        aria-label="Zoom out"
        onClick={() => props.handleZoomChange('minus')}
      />
    </div>
    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
      <button
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate"
        type="button"
        aria-label="Geolocate"
        onClick={() => props.geolocateUser()}
      />
    </div>
  </div>
);

export default MapControl;
