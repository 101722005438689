const isName = (name) => {
  const nameRegex = /^[a-zA-Z ]+$/.test(name);
  return nameRegex;
};
const isNumber = (number) => {
  const numRegex = /^\d+$/.test(number);
  return numRegex;
};
const isPassword = (password) => {
  if (password.length < 8) return false;
  return true;
};
const isConfirmPassword = (password, confirmPassword) => {
  if (password !== confirmPassword) return false;
  return true;
};
const isDecimalUptofourDigit = (number) => {
  const decimalRegex = /^\d+(\.\d{1,4})?$/.test(number);
  return decimalRegex;
};
const isMobile = (mobile) => {
  if (mobile.length === 10) {
    const mobileRegex = /^[6-9]\d{9}$/.test(mobile);
    return mobileRegex;
  }
  return false;
};
const isEmail = (email) => {
  const emailRegex = /\S+@\S+\.\S+/.test(email);
  return emailRegex;
};
const isAadhar = (aadhar) => {
  if (aadhar.length === 12) {
    const UIDRegex = /^\d+$/.test(aadhar);
    return UIDRegex;
  }
  return false;
};
const isAddress = (address) => {
  if (address.length < 250) {
    const addRegex = /^[a-zA-Z0-9\s,'-]*$/.test(address);
    return addRegex;
  }
  return false;
};
const isAccountNumberWithMinMax = (accountNumber, min, max) => {
  if (accountNumber.length >= min && accountNumber.length <= max) {
    const accountNumberRegex = /^\d+$/.test(accountNumber);
    if (accountNumberRegex) {
      return true;
    }
    return false;
  }
  return false;
};
const isAmountWithMinMax = (amount, min, max) => {
  if (amount >= min && amount <= max) {
    const amountRegex = /^\d+(\.\d{1,2})?$/.test(amount);
    return amountRegex;
  }
  return false;
};

const isNaturalNumber = (n) => {
  const naturalNumber = n.toString(); // force the value incase it is not
  const n1 = Math.abs(naturalNumber);

  const n2 = parseInt(naturalNumber, 10);
  return typeof n1 === 'number' && n2 === n1 && n1.toString() === naturalNumber;
};

export {
  isName,
  isNumber,
  isPassword,
  isConfirmPassword,
  isDecimalUptofourDigit,
  isMobile,
  isEmail,
  isAadhar,
  isAddress,
  isAccountNumberWithMinMax,
  isAmountWithMinMax,
  isNaturalNumber,
};
