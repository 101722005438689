import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@material-ui/styles';
// import './index.scss';
import App from './App';
import theme from './theme';
import { store } from './store/configureStore';
// import registerServiceWorker from './registerServiceWorker';

// const store = configureStore(window.__REDUX_STATE__ || {});

// const AppBundle = (
//   <ReduxProvider store={store}>
//     <BrowserRouter>
//       <CookiesProvider>
//         <App />
//       </CookiesProvider>
//     </BrowserRouter>
//   </ReduxProvider>
// );

function AppBundle() {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </ReduxProvider>
    </ThemeProvider>
  );
}

window.onload = () => {
  Loadable.preloadReady().then(() => {
    const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
    renderMethod(<AppBundle />, document.getElementById('root'));
  });
};

// registerServiceWorker();
