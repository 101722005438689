import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Button,
  Grid,
  AppBar,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
// import _ from 'lodash';
import MediaFileUploader from './mediaFileUploader';
import MediaAudioRecorder from './mediaAudioRecorder';
import { getDuration } from './audioPlayerUtil';
import { addMedia } from '../../Actions/ivrsAction';
import MediaList from './mediaList';
import ModalHOC from '../common/Modal/index';
import '../../css/ivrs/ivrs.scss';
import '../../css/ivrs/tabView.scss';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

class AddAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioInputType: 'file',
      fileStaticMedia: undefined,
      recorderStaticMedia: undefined,
      staticListMedia: undefined,
      mediaTitle: '',
      mediaDesc: '',
      error: '',
      value: 0,
      mediaTitleError: '',
      mediaDescError: '',
      language: 'English',
      mediaError: '',
    };
  }

  _handleClose = () => {
    const { onClear } = this.props;
    onClear();
    this.setState({
      value: 0,
    });
  };

  updateBase64File = (fileBase64String) => {
    if (fileBase64String && fileBase64String.length > 0) {
      const id = new Date().getTime();
      const fileStaticMedia = {
        id: `SM_${id}`,
        type: 'SINGLE_MEDIA',
        name: 'File Media',
        language: 'EN',
        description: '',
        totalDuration: 0,
        isMetaAvailable: false,
        mediaSources: [
          {
            id,
            hasSrcUrl: false,
            base64: fileBase64String,
            isMetaAvailable: false,
          },
        ],
      };
      this.setState(
        {
          fileStaticMedia,
        },
        () => {
          this.fetchDuration(fileBase64String);
        },
      );
    } else {
      this.setState({ fileStaticMedia: undefined });
    }
  };

  updateBase64Recorder = (recorderBase64String) => {
    if (recorderBase64String && recorderBase64String.length > 0) {
      const id = new Date().getTime();
      const recorderStaticMedia = {
        id: `SM_${id}`,
        type: 'SINGLE_MEDIA',
        name: 'Recorded Media',
        language: 'EN',
        description: '',
        totalDuration: 0,
        formattedTotalDuration: '',
        isMetaAvailable: false,
        mediaSources: [
          {
            id,
            hasSrcUrl: false,
            base64: recorderBase64String,
            isMetaAvailable: false,
          },
        ],
      };
      this.setState(
        {
          recorderStaticMedia,
        },
        () => {
          this.fetchRecordDuration(recorderBase64String);
        },
      );
    } else {
      this.setState({ recorderStaticMedia: undefined });
    }
  };

  fetchRecordDuration = async (base64String) => {
    const { recorderStaticMedia } = this.state;
    if (base64String && base64String.length > 0) {
      const totalDuration = await getDuration(base64String);
      console.log('key>>>', recorderStaticMedia);
      const recorderStaticMediaCopy = { ...recorderStaticMedia };
      recorderStaticMediaCopy.totalDuration = totalDuration;
      this.setState(
        {
          recorderStaticMedia: recorderStaticMediaCopy,
        },
        () => {
          console.log(
            'recordstaticMedia',
            recorderStaticMediaCopy,
            recorderStaticMedia,
          );
        },
      );
    }
  };

  fetchDuration = async (base64String) => {
    const { fileStaticMedia } = this.state;
    if (base64String && base64String.length > 0) {
      const totalDuration = await getDuration(base64String);
      console.log('key>>>', fileStaticMedia);
      const fileStaticMediaCopy = { ...fileStaticMedia };
      fileStaticMediaCopy.totalDuration = totalDuration;
      this.setState(
        {
          fileStaticMedia: fileStaticMediaCopy,
        },
        () => {
          console.log('fileStatetic', fileStaticMediaCopy, fileStaticMedia);
        },
      );
    }
  };

  /*  broadcastMediaTabChange = (tab) => {
    console.log('tab', tab);
    if (tab === 0) {
      this.setState({
        audioInputType: 'file',
        mediaTitle: '',
        mediaDesc: '',
        error: '',
      });
    } else if (tab === 1) {
      this.setState({
        audioInputType: 'recorder',
        mediaTitle: '',
        mediaDesc: '',
        error: '',
      });
    } else {
      this.setState({
        audioInputType: 'list',
        error: '',
      });
    }
  }; */

  broadcastSelectedStaticMedia = () => {
    let mediaObj;
    let check = true;
    let error = '';
    let mediaTitleError = '';
    let mediaDescError = '';
    let mediaError = '';
    const {
      audioInputType,
      fileStaticMedia,
      recorderStaticMedia,
      staticListMedia,
      mediaTitle,
      mediaDesc,
    } = this.state;
    const { handleClose, activeNode, addMediaObj } = this.props;
    if (
      mediaTitle
      && mediaDesc
      && audioInputType === 'file'
      && fileStaticMedia === undefined
    ) {
      mediaError = 'Please choose media file';
      check = false;
    }
    // if (
    //   mediaTitle &&
    //   mediaDesc &&
    //   audioInputType === 'file' &&
    //   !_.isEmpty(fileStaticMedia) &&
    //   fileStaticMedia.totalDuration > 30.0
    // ) {
    //   mediaError = 'duration should be less than or equal to 30 sec';
    //   check = false;
    // }
    if (
      mediaTitle
      && mediaDesc
      && audioInputType === 'recorder'
      && recorderStaticMedia === undefined
    ) {
      mediaError = 'Please record media';
      check = false;
    }
    if (audioInputType === 'list' && staticListMedia === undefined) {
      error = 'Please select media';
      check = false;
    }
    if (
      mediaTitle
      && !mediaDesc
      && (audioInputType === 'file' || audioInputType === 'recorder')
    ) {
      mediaDescError = 'Please enter text';
      check = false;
    }
    if (
      mediaTitle
      && mediaDesc
      && mediaDesc.length > 280
      && (audioInputType === 'file' || audioInputType === 'recorder')
    ) {
      mediaDescError = 'max 280 characters allowed';
      check = false;
    }
    if (
      !mediaTitle
      && (audioInputType === 'file' || audioInputType === 'recorder')
    ) {
      mediaTitleError = 'Please enter title';
      check = false;
    }
    if (
      mediaTitle
      && mediaTitle.length > 15
      && (audioInputType === 'file' || audioInputType === 'recorder')
    ) {
      mediaTitleError = 'max 15 characters allowed';
      check = false;
    }
    if (!check) {
      this.setState({
        error,
        mediaTitleError,
        mediaDescError,
        mediaError,
      });
    } else {
      if (audioInputType === 'file') {
        mediaObj = {
          mediaFile: fileStaticMedia.mediaSources[0].base64.split(',').pop(),
          actionType: 'ADD',
          description: mediaDesc,
          name: mediaTitle,
          language: 'HINDI',
        };
        console.log('mediaObj', mediaObj);
        addMediaObj(mediaObj);
        handleClose(fileStaticMedia, activeNode);
        this.setState({
          fileStaticMedia: undefined,
          error: '',
          mediaTitleError: '',
          mediaDescError: '',
          mediaError: '',
        });
      }
      if (audioInputType === 'recorder') {
        mediaObj = {
          mediaFile: recorderStaticMedia.mediaSources[0].base64
            .split(',')
            .pop(),
          actionType: 'ADD',
          description: mediaDesc,
          name: mediaTitle,
          language: 'HINDI',
        };
        addMediaObj(mediaObj);
        handleClose(recorderStaticMedia, activeNode);
        this.setState({
          recorderStaticMedia: undefined,
          error: '',
          mediaTitleError: '',
          mediaDescError: '',
          mediaError: '',
        });
      }
      if (audioInputType === 'list') {
        handleClose(staticListMedia, activeNode);
        this.setState({
          staticListMedia: undefined,
          error: '',
          mediaTitleError: '',
          mediaDescError: '',
          mediaError: '',
        });
      }
    }
  };

  handleUpdate = (media) => {
    this.setState({
      staticListMedia: media,
    });
  };

  updateText = (value) => {
    this.setState({
      mediaTitle: value,
    });
  };

  updateDesc = (value) => {
    this.setState({
      mediaDesc: value,
    });
  };

  handleLanguage = (value) => {
    this.setState({
      language: value,
    });
  };

  handleChange = (event, newValue) => {
    this.setState(
      {
        value: newValue,
      },
      () => {
        if (newValue === 0) {
          this.setState({
            audioInputType: 'file',
            mediaTitle: '',
            mediaDesc: '',
            error: '',
            mediaTitleError: '',
            mediaDescError: '',
            mediaError: '',
          });
        } else if (newValue === 1) {
          this.setState({
            audioInputType: 'recorder',
            mediaTitle: '',
            mediaDesc: '',
            error: '',
            mediaTitleError: '',
            mediaDescError: '',
            mediaError: '',
          });
        } else {
          this.setState({
            audioInputType: 'list',
            error: '',
            mediaTitleError: '',
            mediaDescError: '',
            mediaError: '',
          });
        }
      },
    );
  };

  render() {
    const {
      fileStaticMedia,
      error,
      value,
      mediaTitleError,
      mediaDescError,
      language,
      mediaError,
    } = this.state;
    const { showAddMediaModal } = this.props;
    console.log('language', language);
    return (
      <React.Fragment>
        <ModalHOC
          isOpen={showAddMediaModal}
          onClose={this._handleClose}
          titleContent="Add Media"
          customContent="add-mediaModal"
          footerComponent={(
            <Grid item lg={12}>
              {error ? (
                <p className="errorMsg1">
                  {' '}
                  {error}
                  {' '}
                </p>
              ) : null}
              <Button
                className="upload-btn floatRight"
                onClick={this.broadcastSelectedStaticMedia}
              >
                Save
              </Button>
              <Button
                className="upload-btn floatRight"
                onClick={this._handleClose}
              >
                Discard
              </Button>
            </Grid>
)}
        >
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <div className="tabView">
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Upload File" />
                    <Tab label="Record Media" />
                    <Tab label="Media List" />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <MediaFileUploader
                    fileStaticMedia={fileStaticMedia}
                    updateBase64File={this.updateBase64File}
                    updateText={this.updateText}
                    updateDesc={this.updateDesc}
                    updateLanguage={this.handleLanguage}
                    mediaTitleError={mediaTitleError}
                    mediaDescError={mediaDescError}
                    mediaError={mediaError}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MediaAudioRecorder
                    updateBase64Recorder={this.updateBase64Recorder}
                    updateText={this.updateText}
                    updateDesc={this.updateDesc}
                    updateLanguage={this.handleLanguage}
                    mediaTitleError={mediaTitleError}
                    mediaDescError={mediaDescError}
                    mediaError={mediaError}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MediaList updateMedia={this.handleUpdate} />
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        </ModalHOC>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ ivrs }) => ({
      media: ivrs.media,
    }),
    dispatch => ({
      addMediaObj: data => dispatch(addMedia(data)),
    }),
  )(withCookies(AddAudio)),
);
