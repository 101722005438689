import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withCookies } from 'react-cookie';
import Loader from '../../../common/customLoader';
import ScrollableTabPanel from '../../../common/ScrollableTabPanel';
import { closePopupAction } from '../../../../Actions/surveyAction';

// eslint-disable-next-line no-unused-vars
const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

class CropHealthPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      closePopupFunc,
      experimentDetailLoading,
      experimentDetailError,
      experimentDetail,
      previewExperimentNumber,
    } = this.props;
    const { surveyDetails, mediaURL } = experimentDetail;
    let tabsData = [];
    if (!_.isEmpty(experimentDetail)) {
      if (!_.isEmpty(surveyDetails)) {
        tabsData = [
          {
            tabName: 'Part-1 health-overview',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Geographic Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            State
                            {' '}
                            <span>{surveyDetails.stateName || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            District
                            <span>
                              {surveyDetails.districtName || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            {surveyDetails.level4 || 'Level 4'}
                            <span>{surveyDetails.level4Name || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Season/Year
                            <span>{`${surveyDetails.season}/${surveyDetails.year}`}</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            {surveyDetails.level5 || 'Level 5'}
                            {' '}
                            <span>{surveyDetails.level5Name}</span>
                          </li>
                          <li>
                            {surveyDetails.level6 || 'Level 6'}
                            {' '}
                            <span>{surveyDetails.level6Name || 'xxxxxx'}</span>
                          </li>
                          <li>
                            {surveyDetails.level7 || 'Level 7'}
                            {' '}
                            <span>{surveyDetails.level7Name || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Survey Date
                            {' '}
                            <span>
                              {surveyDetails.inspectionDate
                                ? `${new Date(
                                  surveyDetails.inspectionDate,
                                ).getDate()} ${
                                  months[
                                    new Date(
                                      surveyDetails.inspectionDate,
                                    ).getMonth()
                                  ]
                                } ${new Date(
                                  surveyDetails.inspectionDate,
                                ).getFullYear()}`
                                : 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>
                        Observation Details
                        {' '}
                        <span>
                          Co ordinates:
                          {' '}
                          <strong>{`${surveyDetails.lat} , ${surveyDetails.lng}`}</strong>
                        </span>
                      </h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            IU Name
                            {' '}
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            IU Level
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Crop Name
                            <span>
                              {surveyDetails.primaryCropName || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Crop STAGE
                            {' '}
                            {/* <strong>Yes</strong> */}
                            <span>
                              {surveyDetails.cropStageOnSurvey || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Major Seed Type
                            {' '}
                            <span>
                              {surveyDetails.majorSeedVariety || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Maturity (days)
                            <span>
                              {surveyDetails.averageMaturityDays || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Crop Condition
                            <span>
                              {surveyDetails.cropCondition || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Any Loss?(Yes/No)
                            <span>
                              {surveyDetails.cropLossObservation ? 'Yes' : 'No'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.eastFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.cropPhotograph}`}
                          alt="02"
                        />
                        <p>Crop</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.northFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.southFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.westFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Part-2 crop-details',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Cropping Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Sowing Date
                            <span>{surveyDetails.sowingDate || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Harvesting Date
                            <span>
                              {surveyDetails.expectedHarvestedDate || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Irrigation Source
                            <span>
                              {surveyDetails.sourceOfIrrigation || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Estimated yield (
                            {surveyDetails.yieldUnit}
)
                            <span>
                              {surveyDetails.expectedYield || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Loss Percent
                            <span>
                              {surveyDetails.lossPercentage || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Farm Acreage (hect)
                            <span>
                              {surveyDetails.farmAcreage || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Cause of Loss
                            <span>
                              {surveyDetails.reasonForCropLoss || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Crop Height(ft)
                            <span>{surveyDetails.cropHeight || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Stress Details </h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Foilage Cover
                            <span>
                              {surveyDetails.foliageCover || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Soil Moisture
                            <span>
                              {surveyDetails.soilMoisture || 'xxxxxx'}
                            </span>
                          </li>
                          <li>
                            Moisture Stress(Yes/No)
                            <span>
                              {surveyDetails.moistureStress === 'true'
                                ? 'Yes'
                                : 'No'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Moisture Issue
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Other Remarks
                            <span>
                              {surveyDetails.otherRemarks || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.eastFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.cropPhotograph}`}
                          alt="02"
                        />
                        <p>Crop</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.northFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.southFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.westFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Part-3 yield-overview',
            disabled: true,
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Weighing Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Crop Variety
                            <span>{surveyDetails.cropVariety}</span>
                          </li>
                          <li>
                            Variety Source
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Dry/wet weight
                            <strong>(Unit)</strong>
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Moisture %
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Variety Name
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Trees/Fruits Count
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Production Status
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Driage Ratio
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Yield Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Avg Yield
                            <strong>(Unit)</strong>
                            {' '}
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Damage in (%)
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Stress Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Data Point C
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Produce Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Remarks
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Weather Status
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Data Point D
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.eastFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.cropPhotograph}`}
                          alt="02"
                        />
                        <p>Crop</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.northFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.southFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.westFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Supervision',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Supervisor Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Supervisor Designation
                            <span>
                              {surveyDetails.jointCommitteeMember1Designation
                                || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Supervisor Mobile No.
                            <span>
                              {surveyDetails.jointCommitteeMember1Mobile
                                || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Supervisor Name
                            <span>
                              {surveyDetails.jointCommitteeMember1Name
                                || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Remarks
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Submission Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Agent Name
                            {' '}
                            <span>{surveyDetails.agentName || 'xxxxxxx'}</span>
                          </li>
                          {/* <li>
                                                 Part 1 Date
                                                 <span>xxxxxxx</span>
                                                 </li> */}
                          <li>
                            Date of Allocation
                            <span>{surveyDetails.endDate || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Agent Mobile No.
                            <span>
                              {surveyDetails.agentMobile || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Final Submission Date
                            <span>
                              {surveyDetails.inspectionDate
                                ? `${new Date(
                                  surveyDetails.inspectionDate,
                                ).getDate()} ${
                                  months[
                                    new Date(
                                      surveyDetails.inspectionDate,
                                    ).getMonth()
                                  ]
                                } ${new Date(
                                  surveyDetails.inspectionDate,
                                ).getFullYear()}`
                                : 'xxxxxxx'}
                            </span>
                          </li>
                          {/* <li>
                                                 Smart Sampling
                                                 <span>xxxxxxx</span>
                                                 </li> */}
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.eastFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.cropPhotograph}`}
                          alt="02"
                        />
                        <p>Crop</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.northFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.southFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.westFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Satellite-verification',
            disabled: true,
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Land Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Land Shape File
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Identified Crop
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Harvesting Week
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Max NDVI
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Shape File Area
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Sowing Week
                            <span>{surveyDetails.sowingDate || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Irrigation Status
                            <span>
                              {surveyDetails.irrigationSource || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Avg NDVI
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Yield Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Health Status
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Field Relative Index
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Area Difference(Unit)
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Loss Percentage
                            <span>
                              {surveyDetails.lossPercentage || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Avg Yield
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            DB Value
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.eastFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.cropPhotograph}`}
                          alt="02"
                        />
                        <p>Crop</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.northFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.southFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.westFarmPhotograph}`}
                          alt="01"
                        />
                        <p>Farm</p>
                      </figure>
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Summary',
            disabled: true,
            component: <div>kjaedbe</div>,
          },
        ];
      }
    }
    return (
      <div className="survey-modal">
        {/* Condition for Loader.
            Used the same Popup Layout for Loader
         */}
        {experimentDetailLoading ? (
          <div>
            <div>
              <div className="survey-modal__content">
                <div className="survey-modal__header">
                  <h1>
                    Crop Health Inspection Report
                    {' '}
                    <i
                      role="button"
                      tabIndex="0"
                      onKeyDown={closePopupFunc}
                      className="material-icons"
                      onClick={closePopupFunc}
                    >
                      close
                    </i>
                  </h1>
                </div>
              </div>
              <div className="survey-modal__body">
                <Grid container className="survey-modal__body__head">
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="survey-modal__body__head--left"
                  >
                    ID:
                    {previewExperimentNumber}
                    {/*   <div class="survey-modal__body__head--left">
                         ID:677667
                     </div>
                     <div class="survey-modal__body__head--right">
                         Date
                     </div> */}
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="survey-modal__body__head--right"
                  >
                    Date
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ScrollableTabPanel
                      tabsData={[
                        {
                          tabName: 'Part 1 Field Overview',
                          component: (
                            <div className="survey-tab">
                              <div className="survey-tab__content">
                                <div className="survey-tab__body">
                                  {/*                                  <Grid
                                   container
                                   className="survey-tab__body__palette"
                                 >
                                   <h1>Loader</h1>
                                 </Grid> */}
                                  <Grid
                                    container
                                    className="survey-tab__body__details"
                                  >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Loader />
                                    </Grid>
                                  </Grid>
                                </div>
                                <Grid container className="survey-tab__footer">
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">Delete</button>
                                  </Grid>
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">Download</button>
                                  </Grid>
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">CTA</button>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      customStyle="surveyTabPanel"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* Condition For Empty Data or Error
              Used the same Popup Layout for Loader
             */}
            {_.isEmpty(experimentDetail) && experimentDetailError !== '' && (
              <div>
                <div className="survey-modal__content">
                  <div className="survey-modal__header">
                    <h1>
                      Crop Health Inspection Report
                      {' '}
                      <i
                        role="button"
                        tabIndex="0"
                        onKeyDown={closePopupFunc}
                        className="material-icons"
                        onClick={closePopupFunc}
                      >
                        close
                      </i>
                    </h1>
                  </div>
                </div>
                <div className="survey-modal__body">
                  <Grid container className="survey-modal__body__head">
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--left"
                    >
                      ID:
                      {previewExperimentNumber}
                      {/*   <div class="survey-modal__body__head--left">
                          ID:677667
                      </div>
                      <div class="survey-modal__body__head--right">
                          Date
                      </div> */}
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--right"
                    >
                      Date
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <ScrollableTabPanel
                        tabsData={[
                          {
                            tabName: 'Part 1 Field Overview',
                            component: (
                              <div className="survey-tab">
                                <div className="survey-tab__content">
                                  <div className="survey-tab__body">
                                    <Grid
                                      container
                                      className="survey-tab__body__palette"
                                    >
                                      <h1>Error</h1>
                                    </Grid>
                                    <Grid
                                      container
                                      className="survey-tab__body__details"
                                    >
                                      <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <h2>{experimentDetailError}</h2>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid
                                    container
                                    className="survey-tab__footer"
                                  >
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">Delete</button>
                                    </Grid>
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">Download</button>
                                    </Grid>
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">CTA</button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        customStyle="surveyTabPanel"
                      />
                    </Grid>
                  </Grid>
                </div>
                {/*        <h2>{description}</h2>
              <Button
                variant="contained"
                className="button btn-success startBtn"
                onClick={() => closePopupFunc()}
              >
                Close
              </Button> */}
                {/*        <Grid container className="root gis-main1" justify="center">
                <ScrollableTabPanel
                  tabsData={tabsData}
                  customStyle="surveyTabPanel"
                />
              </Grid> */}
              </div>
            )}
            {/* Condition of Data Success */}
            {!_.isEmpty(experimentDetail) && surveyDetails && (
              <div>
                <div className="survey-modal__content">
                  <div className="survey-modal__header">
                    <h1>
                      Crop Health Inspection Report
                      {' '}
                      <i
                        role="button"
                        tabIndex="0"
                        onKeyDown={closePopupFunc}
                        className="material-icons"
                        onClick={closePopupFunc}
                      >
                        close
                      </i>
                    </h1>
                  </div>
                </div>
                <div className="survey-modal__body">
                  <Grid container className="survey-modal__body__head">
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--left"
                    >
                      ID:
                      {surveyDetails.displayID}
                      {/*   <div class="survey-modal__body__head--left">
                            ID:677667
                        </div>
                        <div class="survey-modal__body__head--right">
                            Date
                        </div> */}
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--right"
                    >
                      Date:
                      {`${new Date(surveyDetails.inspectionDate).getDate()} ${
                        months[
                          new Date(surveyDetails.inspectionDate).getMonth()
                        ]
                      } ${new Date(
                        surveyDetails.inspectionDate,
                      ).getFullYear()}`}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <ScrollableTabPanel
                        tabsData={tabsData}
                        customStyle="surveyTabPanel"
                      />
                    </Grid>
                  </Grid>
                </div>
                {/*        <h2>{description}</h2>
                <Button
                  variant="contained"
                  className="button btn-success startBtn"
                  onClick={() => closePopupFunc()}
                >
                  Close
                </Button> */}
                {/*        <Grid container className="root gis-main1" justify="center">
                  <ScrollableTabPanel
                    tabsData={tabsData}
                    customStyle="surveyTabPanel"
                  />
                </Grid> */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ survey }) => ({
      showPopup: survey.showPopup,
      markerIndex: survey.markerIndex,
      surveyData: survey.surveyData,
      experimentDetailLoading: survey.experimentDetailLoading,
      experimentDetail: survey.experimentDetail,
      experimentDetailError: survey.experimentDetailError,
      previewExperimentNumber: survey.previewExperimentNumber,
    }),
    dispatch => ({
      closePopupFunc: () => dispatch(closePopupAction()),
    }),
  )(withCookies(CropHealthPopup)),
);
