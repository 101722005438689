import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
// import { Theme } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import '../../css/common.scss';
import '../../css/button.scss';
import '../../css/accountSetup.scss';
import '../../css/rightPanel.scss';
import AccountSetUpMap from './AccountSetUpMap';
import {
  addSelectedFarm,
  removeFarm,
  resetAccountSetUpDetails,
  getSelectedFarmCard,
  getLandData,
  resetAccountSetUpLandDetails,
} from '../../Actions/accountSetUpAction';
import FarmerCard from './farmerCard';
import Toastr from '../common/toastr';
import AccountSetUpModal from './accountSetUpModal';
import DashboardLeftPanel from '../dashboard/dashboardLeftPanel';
import LanguageContext from '../common/languageContext';

class AccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedFarmDetails: '',
      selectedFarmId: '',
      isRemoveModalOpen: false,
      removeFarmId: '',
      isFarmCardOpen: false,
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      isAlreadySelected: false,
      expandPanel: false,
    };
  }

  componentDidMount() {
    const { location, cookies, getFarmerLandData } = this.props;
    if (location.state) {
      window.location.hash = '';
    }
    const userData = cookies.get('userData');
    if (userData) getFarmerLandData(userData.token);
  }

  componentDidUpdate(prevProps) {
    const {
      isLandRegistered,
      farmDetails,
      farmTagError,
      removeFarmError,
      showLandSuccessMsg,
      showLandRemoveSuccessMsg,
      getFarmerLandData,
      cookies,
      isLandRemoved,
      resetLandDetails,
    } = this.props;
    const userData = cookies.get('userData');
    if (
      (farmTagError === ''
        && farmDetails.length > prevProps.farmDetails.length)
      || (isLandRemoved === true
        || farmDetails.length < prevProps.farmDetails.length)
    ) {
      if (isLandRemoved) {
        resetLandDetails();
      }
      getFarmerLandData(userData.token);
      this.onUpdate(
        'success',
        isLandRegistered,
        farmTagError,
        removeFarmError,
        showLandSuccessMsg,
        showLandRemoveSuccessMsg,
      );
    }
    if (
      (farmTagError !== '' || removeFarmError !== '')
      && farmDetails.length === prevProps.farmDetails.length
    ) {
      this.onUpdate(
        'error',
        isLandRegistered,
        farmTagError,
        removeFarmError,
        showLandSuccessMsg,
        showLandRemoveSuccessMsg,
      );
    }
  }

  onUpdate = (
    type,
    isLandRegistered,
    farmTagError,
    removeFarmError,
    showLandSuccessMsg,
    showLandRemoveSuccessMsg,
  ) => {
    const { resetDetails } = this.props;
    if (type === 'success') {
      this.setState(
        {
          istoastrOpen: true,
          toastrMessage:
            isLandRegistered === true
              ? showLandSuccessMsg
              : showLandRemoveSuccessMsg,
          toastrMessageType: 'success',
        },
        () => {
          resetDetails();
        },
      );
    } else {
      this.setState(
        {
          istoastrOpen: true,
          toastrMessage: farmTagError !== '' ? farmTagError : removeFarmError,
          toastrMessageType: 'error',
        },
        () => {
          resetDetails();
        },
      );
    }
  };

  handleRemoveModal = (farmid) => {
    this.setState({
      isRemoveModalOpen: true,
      removeFarmId: farmid,
    });
  };

  handleAddModal = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      isRemoveModalOpen: false,
    });
  };

  handleFarmTag = (farmId, searchPlace) => {
    const { landData } = this.props;
    let isAlreadyMap;
    if (landData) {
      isAlreadyMap = landData.filter(farm => farm.farmID === farmId);
    }
    if (isAlreadyMap.length > 0) {
      this.setState({
        isAlreadySelected: true,
        selectedFarmId: farmId,
      });
    } else {
      this.setState({
        selectedFarmDetails: searchPlace,
        selectedFarmId: farmId,
        isAlreadySelected: false,
      });
    }
  };

  handleDeSelectFarm = () => {
    this.setState({
      selectedFarmId: '',
    });
  };

  handleAddSelectedFarm = () => {
    const { selectedFarmId, selectedFarmDetails } = this.state;
    const { cookies, addFarmer } = this.props;
    const userData = cookies.get('userData');
    if (userData) {
      addFarmer(selectedFarmId, selectedFarmDetails, userData.token);
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'something is Wrong',
        toastrMessageType: 'error',
      });
    }
    this.setState({
      open: false,
    });
  };

  handleRemoveSelectedFarm = () => {
    const { removeFarmId } = this.state;
    const { deleteFarm, cookies } = this.props;
    const userData = cookies.get('userData');
    deleteFarm(removeFarmId, userData.token);
    this.setState({
      isRemoveModalOpen: false,
    });
  };

  handleFarmCard = () => {
    this.setState({
      isFarmCardOpen: true,
    });
    const { selectedFarmId } = this.state;
    const { cookies, getFarmCard } = this.props;
    const userData = cookies.get('userData');
    getFarmCard(selectedFarmId, userData.token);
  };

  closeFarmCard = () => {
    this.setState({
      isFarmCardOpen: false,
    });
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false,
    });
  };

  onExpandClick = () => {
    const { expandPanel } = this.state;
    this.setState({
      expandPanel: !expandPanel,
    });
  };

  _renderAfterFarmAdded = () => {
    const { farmDetails } = this.props;
    const { language } = this.context;
    return (
      <React.Fragment>
        <h4>
          {' '}
          {(language.hindi && language.hindi['Added Farm']) || 'Added Farm'}
        </h4>
        <ol>
          {farmDetails.map((item, index) => (
            <li key={Math.random()}>
              <h2>
                {(language.hindi && language.hindi.Farm) || 'Farm'}
                {' '}
:
                {index + 1}
              </h2>
              <Button
                variant="contained"
                className="button darkgreenBtn floatRight removeBtn"
                onClick={() => {
                  this.handleRemoveModal(item.farmID);
                }}
                onKeyDown={() => {
                  this.handleRemoveModal();
                }}
              >
                {(language.hindi && language.hindi.remove) || 'remove'}
              </Button>
              <h6>
                {(language.hindi && language.hindi.address) || 'Address'}
                {' '}
:
                <span>
                  {item.villageName}
,
                  {item.tehsilName}
,
                  {item.districtName}
,
                  {item.stateName}
                </span>
              </h6>
            </li>
          ))}
        </ol>
      </React.Fragment>
    );
  };

  render() {
    const {
      open,
      isRemoveModalOpen,
      selectedFarmId,
      isFarmCardOpen,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      isAlreadySelected,
      expandPanel,
    } = this.state;
    const { farmDetails, farmCard, landData } = this.props;
    const { language } = this.context;
    return (
      <div>
        <section
          className={
            !expandPanel
              ? 'root panel mainPanel miniPanel'
              : 'root panel mainPanel miniPanel expandPanel'
          }
        >
          <Grid container>
            <DashboardLeftPanel />
            <Grid item xs={12} sm={7} className="mapPanel">
              <button
                type="button"
                className="app-nav-action"
                onClick={this.onExpandClick}
              >
                <span className="app-nav-action__item" />
              </button>
              {/* <ScrollableTabPanel tabsData={tabsData} /> */}
              <div className="accountSetupHead">
                <Helmet>
                  <title>FarmEngine| Find Your Farm</title>
                </Helmet>
                <AccountSetUpMap
                  className="setupMap"
                  ref={(instance) => {
                    this.child = instance;
                  }}
                  landData={landData}
                  handleFarmTag={this.handleFarmTag}
                  handleDeSelectFarm={this.handleDeSelectFarm}
                />
                {selectedFarmId !== '' && (
                  <div className="setupFlow">
                    <ul>
                      <li className="setupActive">
                        <span>
                          {(language.hindi
                            && language.hindi['Find your farm'])
                            || 'Find your farm'}
                        </span>
                      </li>
                      <li>
                        <span>
                          {(language.hindi && language.hindi['Farmer Card'])
                            || 'Farmer Card'}
                        </span>
                      </li>
                    </ul>
                    <div className="darkgreenBtnHeader acccounDetailHead">
                      <h4>
                        {(language.hindi
                          && language.hindi['Details of selected farm'])
                          || 'Details of selected farm'}
                      </h4>
                      <h6>
                        {(language.hindi && language.hindi.address)
                          || 'Address'}
                        <span>
                          Ganganagar, Ganganagar, Ganganagar, Rajasthan
                        </span>
                      </h6>
                      {isAlreadySelected && selectedFarmId !== '' ? (
                        <Button
                          variant="contained"
                          className="button darkgreenBtn floatLeft addToFarmBtn"
                          onClick={() => {
                            this.handleRemoveModal(selectedFarmId);
                          }}
                          onKeyDown={() => {
                            this.handleRemoveModal();
                          }}
                        >
                          {(language.hindi && language.hindi.remove)
                            || 'remove'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="button darkgreenBtn floatLeft addToFarmBtn"
                          onClick={() => {
                            this.handleAddModal();
                          }}
                          onKeyDown={() => {
                            this.handleAddModal();
                          }}
                        >
                          {(language.hindi
                            && language.hindi['Add to my farm'])
                            || 'Add to my farm'}
                        </Button>
                      )}
                      <div className="addedFarmPanel">
                        {farmDetails
                          && farmDetails.length > 0
                          && this._renderAfterFarmAdded()}
                      </div>
                      {/* <div className="addedFarmPanel">
             {farmDetails && farmDetails.length > 0 && (
             <h4>
             {' '}
             {(language.hindi && language.hindi['Added Farm'])
             || 'Added Farm'}
             </h4>
             )}
             <ol>
             {farmDetails
             && farmDetails.length > 0
             && farmDetails.map((item, index) => (
             <li key={Math.random()}>
             <h2>
             {(language.hindi && language.hindi.Farm) || 'Farm'}
             {' '}
             :
             {index + 1}
             </h2>
             <Button
             variant="contained"
             className="button darkgreenBtn floatRight removeBtn"
             onClick={() => {
             this.handleRemoveModal(item.farmID);
             }}
             onKeyDown={() => {
             this.handleRemoveModal();
             }}
             >
             {(language.hindi && language.hindi.remove)
             || 'remove'}
             </Button>
             <h6>
             {(language.hindi && language.hindi.address)
             || 'Address'}
             {' '}
             :
             <span>
             {item.villageName}
             ,
             {item.tehsilName}
             ,
             {item.districtName}
             ,
             {item.stateName}
             </span>
             </h6>
             </li>
             ))}
             </ol>
             </div> */}
                      <Grid container spacing={5}>
                        <Grid item xs={2} />
                      </Grid>
                      {/* {farmDetails && farmDetails.length > 0 && ( */}
                      {((farmDetails && farmDetails.length > 0)
                        || isAlreadySelected) && (
                        <Button
                          variant="contained"
                          className="button darkgreenBtn floatRight"
                          onClick={() => {
                            this.handleFarmCard();
                          }}
                        >
                          {(language.hindi
                            && language.hindi['Generate your farm card'])
                            || 'Generate your farm card'}
                          <Icon className="rightIcon btnIcon">
                            arrow_forward
                          </Icon>
                        </Button>
                      )}
                      {/* )} */}
                      <AccountSetUpModal
                        show={open || isRemoveModalOpen}
                        handleClose={this.handleClose}
                        displayContent={
                          open
                            ? (language.hindi
                                && language.hindi[
                                  'This selected farm will be Added! Are you sure?'
                                ])
                              || 'This selected farm will be Added! Are you sure?'
                            : (language.hindi
                                && language.hindi[
                                  'This selected farm will be removed! Are you sure?'
                                ])
                              || 'This selected farm will be removed! Are you sure?'
                        }
                        handleSelectedFarm={
                          open
                            ? this.handleAddSelectedFarm
                            : this.handleRemoveSelectedFarm
                        }
                        language={language}
                      />
                      {/* <AccountSetUpModal
             show={isRemoveModalOpen}
             handleClose={this.handleClose}
             displayContent={
             (language.hindi
             && language.hindi[
             'This selected farm will be removed! Are you sure?'
             ])
             || 'This selected farm will be removed! Are you sure?'
             }
             handleSelectedFarm={this.handleRemoveSelectedFarm}
             language={language}
             /> */}
                      {/* <Modal
             aria-labelledby="simple-modal-title"
             aria-describedby="simple-modal-description"
             open={open}
             onClose={() => {
             this.handleClose();
             }}
             className="registerModal modalHead"
             >
             <div className="paper modalCarousel">
             <div className="modalSec">
             <div className="modalContent">
             {(language.hindi
             && language.hindi[
             'This selected farm will be Added! Are you sure?'
             ])
             || 'This selected farm will be Added! Are you sure?'}
             <ul>
             <li className="darkgreenBtnHeader">
             <Button
             variant="contained"
             className="button darkgreenBtn"
             onClick={() => {
             this.handleAddSelectedFarm();
             }}
             >
             Yes
             </Button>
             </li>
             <li>
             <Button
             variant="contained"
             className="button darkgreenBtn"
             onClick={() => {
             this.handleClose();
             }}
             >
             No
             </Button>
             </li>
             </ul>
             </div>
             </div>
             </div>
             </Modal> */}
                      {/* <Modal
             aria-labelledby="simple-modal-title"
             aria-describedby="simple-modal-description"
             open={isRemoveModalOpen}
             onClose={() => {
             this.handleClose();
             }}
             className="registerModal modalHead"
             >
             <div className="paper modalCarousel">
             <div className="modalSec">
             <div className="modalContent">
             {(language.hindi
             && language.hindi[
             'This selected farm will be removed! Are you sure?'
             ])
             || 'This selected farm will be removed! Are you sure?'}
             <ul>
             <li className="darkgreenBtnHeader">
             <Button
             variant="contained"
             className="button darkgreenBtn"
             onClick={() => {
             this.handleRemoveSelectedFarm();
             }}
             >
             {(language.hindi && language.hindi.Yes) || 'Yes'}
             </Button>
             </li>
             <li className="greyBtnHeader">
             <Button
             variant="contained"
             className="button darkgreenBtn"
             onClick={() => {
             this.handleClose();
             }}
             >
             {(language.hindi && language.hindi.No) || 'No'}
             </Button>
             </li>
             </ul>
             </div>
             </div>
             </div>
             </Modal> */}
                      {isFarmCardOpen && (
                        <FarmerCard
                          isFarmCardOpen={isFarmCardOpen}
                          closeFarmCard={this.closeFarmCard}
                          farmCardData={farmCard}
                          language={language}
                        />
                      )}
                    </div>
                  </div>
                )}
                <Toastr
                  message={toastrMessage}
                  show={istoastrOpen}
                  handleClose={this.handleToastrClose}
                  messageType={toastrMessageType}
                />
              </div>
            </Grid>
          </Grid>
        </section>
      </div>
      // <div>
      //   <section className="root panel mainPanel miniPannel">
      //     <Grid container>
      //       <DashboardLeftPanel />
      //       <Grid item xs={12} sm={7} className="mapPanel">
      //         <div className="accountSetupHead">
      //           <Helmet>
      //             <title>FarmEngine| Find Your Farm</title>
      //           </Helmet>
      //           <AccountSetUpMap
      //             className="setupMap"
      //             ref={(instance) => {
      //               this.child = instance;
      //             }}
      //             landData={landData}
      //             handleFarmTag={this.handleFarmTag}
      //             handleDeSelectFarm={this.handleDeSelectFarm}
      //           />
      //           {selectedFarmId !== '' && (
      //             <div className="setupFlow">
      //               <ul>
      //                 <li className="setupActive">
      //                   <span>
      //                     {(language.hindi
      //                       && language.hindi['Find your farm'])
      //                       || 'Find your farm'}
      //                   </span>
      //                 </li>
      //                 <li>
      //                   <span>
      //                     {(language.hindi && language.hindi['Farmer Card'])
      //                       || 'Farmer Card'}
      //                   </span>
      //                 </li>
      //               </ul>
      //               <div className="darkgreenBtnHeader acccounDetailHead">
      //                 <h4>
      //                   {(language.hindi
      //                     && language.hindi['Details of selected farm'])
      //                     || 'Details of selected farm'}
      //                 </h4>
      //                 <h6>
      //                   {(language.hindi && language.hindi.address)
      //                     || 'Address'}
      //                   <span>
      //                     Ganganagar, Ganganagar, Ganganagar, Rajasthan
      //                   </span>
      //                 </h6>
      //                 {isAlreadySelected && selectedFarmId !== '' ? (
      //                   <Button
      //                     variant="contained"
      //                     className="button darkgreenBtn floatLeft addToFarmBtn"
      //                     onClick={() => {
      //                       this.handleRemoveModal(selectedFarmId);
      //                     }}
      //                     onKeyDown={() => {
      //                       this.handleRemoveModal();
      //                     }}
      //                   >
      //                     {(language.hindi && language.hindi.remove)
      //                       || 'remove'}
      //                   </Button>
      //                 ) : (
      //                   <Button
      //                     variant="contained"
      //                     className="button darkgreenBtn floatLeft addToFarmBtn"
      //                     onClick={() => {
      //                       this.handleAddModal();
      //                     }}
      //                     onKeyDown={() => {
      //                       this.handleAddModal();
      //                     }}
      //                   >
      //                     {(language.hindi
      //                       && language.hindi['Add to my farm'])
      //                       || 'Add to my farm'}
      //                   </Button>
      //                 )}
      //                 <div className="addedFarmPanel">
      //                   {farmDetails
      //                     && farmDetails.length > 0
      //                     && this._renderAfterFarmAdded()}
      //                 </div>
      //                 {/* <div className="addedFarmPanel">
      //        {farmDetails && farmDetails.length > 0 && (
      //        <h4>
      //        {' '}
      //        {(language.hindi && language.hindi['Added Farm'])
      //        || 'Added Farm'}
      //        </h4>
      //        )}
      //        <ol>
      //        {farmDetails
      //        && farmDetails.length > 0
      //        && farmDetails.map((item, index) => (
      //        <li key={Math.random()}>
      //        <h2>
      //        {(language.hindi && language.hindi.Farm) || 'Farm'}
      //        {' '}
      //        :
      //        {index + 1}
      //        </h2>
      //        <Button
      //        variant="contained"
      //        className="button darkgreenBtn floatRight removeBtn"
      //        onClick={() => {
      //        this.handleRemoveModal(item.farmID);
      //        }}
      //        onKeyDown={() => {
      //        this.handleRemoveModal();
      //        }}
      //        >
      //        {(language.hindi && language.hindi.remove)
      //        || 'remove'}
      //        </Button>
      //        <h6>
      //        {(language.hindi && language.hindi.address)
      //        || 'Address'}
      //        {' '}
      //        :
      //        <span>
      //        {item.villageName}
      //        ,
      //        {item.tehsilName}
      //        ,
      //        {item.districtName}
      //        ,
      //        {item.stateName}
      //        </span>
      //        </h6>
      //        </li>
      //        ))}
      //        </ol>
      //        </div> */}
      //                 <Grid container spacing={5}>
      //                   <Grid item xs={2} />
      //                 </Grid>
      //                 {/* {farmDetails && farmDetails.length > 0 && ( */}
      //                 {((farmDetails && farmDetails.length > 0)
      //                   || isAlreadySelected) && (
      //                   <Button
      //                     variant="contained"
      //                     className="button darkgreenBtn floatRight"
      //                     onClick={() => {
      //                       this.handleFarmCard();
      //                     }}
      //                   >
      //                     {(language.hindi
      //                       && language.hindi['Generate your farm card'])
      //                       || 'Generate your farm card'}
      //                     <Icon className="rightIcon btnIcon">
      //                       arrow_forward
      //                     </Icon>
      //                   </Button>
      //                 )}
      //                 {/* )} */}
      //                 <AccountSetUpModal
      //                   show={open || isRemoveModalOpen}
      //                   handleClose={this.handleClose}
      //                   displayContent={
      //                     open
      //                       ? (language.hindi
      //                           && language.hindi[
      //                             'This selected farm will be Added! Are you sure?'
      //                           ])
      //                         || 'This selected farm will be Added! Are you sure?'
      //                       : (language.hindi
      //                           && language.hindi[
      //                             'This selected farm will be removed! Are you sure?'
      //                           ])
      //                         || 'This selected farm will be removed! Are you sure?'
      //                   }
      //                   handleSelectedFarm={
      //                     open
      //                       ? this.handleAddSelectedFarm
      //                       : this.handleRemoveSelectedFarm
      //                   }
      //                   language={language}
      //                 />
      //                 {/* <AccountSetUpModal
      //        show={isRemoveModalOpen}
      //        handleClose={this.handleClose}
      //        displayContent={
      //        (language.hindi
      //        && language.hindi[
      //        'This selected farm will be removed! Are you sure?'
      //        ])
      //        || 'This selected farm will be removed! Are you sure?'
      //        }
      //        handleSelectedFarm={this.handleRemoveSelectedFarm}
      //        language={language}
      //        /> */}
      //                 {/* <Modal
      //        aria-labelledby="simple-modal-title"
      //        aria-describedby="simple-modal-description"
      //        open={open}
      //        onClose={() => {
      //        this.handleClose();
      //        }}
      //        className="registerModal modalHead"
      //        >
      //        <div className="paper modalCarousel">
      //        <div className="modalSec">
      //        <div className="modalContent">
      //        {(language.hindi
      //        && language.hindi[
      //        'This selected farm will be Added! Are you sure?'
      //        ])
      //        || 'This selected farm will be Added! Are you sure?'}
      //        <ul>
      //        <li className="darkgreenBtnHeader">
      //        <Button
      //        variant="contained"
      //        className="button darkgreenBtn"
      //        onClick={() => {
      //        this.handleAddSelectedFarm();
      //        }}
      //        >
      //        Yes
      //        </Button>
      //        </li>
      //        <li>
      //        <Button
      //        variant="contained"
      //        className="button darkgreenBtn"
      //        onClick={() => {
      //        this.handleClose();
      //        }}
      //        >
      //        No
      //        </Button>
      //        </li>
      //        </ul>
      //        </div>
      //        </div>
      //        </div>
      //        </Modal> */}
      //                 {/* <Modal
      //        aria-labelledby="simple-modal-title"
      //        aria-describedby="simple-modal-description"
      //        open={isRemoveModalOpen}
      //        onClose={() => {
      //        this.handleClose();
      //        }}
      //        className="registerModal modalHead"
      //        >
      //        <div className="paper modalCarousel">
      //        <div className="modalSec">
      //        <div className="modalContent">
      //        {(language.hindi
      //        && language.hindi[
      //        'This selected farm will be removed! Are you sure?'
      //        ])
      //        || 'This selected farm will be removed! Are you sure?'}
      //        <ul>
      //        <li className="darkgreenBtnHeader">
      //        <Button
      //        variant="contained"
      //        className="button darkgreenBtn"
      //        onClick={() => {
      //        this.handleRemoveSelectedFarm();
      //        }}
      //        >
      //        {(language.hindi && language.hindi.Yes) || 'Yes'}
      //        </Button>
      //        </li>
      //        <li className="greyBtnHeader">
      //        <Button
      //        variant="contained"
      //        className="button darkgreenBtn"
      //        onClick={() => {
      //        this.handleClose();
      //        }}
      //        >
      //        {(language.hindi && language.hindi.No) || 'No'}
      //        </Button>
      //        </li>
      //        </ul>
      //        </div>
      //        </div>
      //        </div>
      //        </Modal> */}
      //                 {isFarmCardOpen && (
      //                   <FarmerCard
      //                     isFarmCardOpen={isFarmCardOpen}
      //                     closeFarmCard={this.closeFarmCard}
      //                     farmCardData={farmCard}
      //                     language={language}
      //                   />
      //                 )}
      //               </div>
      //             </div>
      //           )}
      //           <Toastr
      //             message={toastrMessage}
      //             show={istoastrOpen}
      //             handleClose={this.handleToastrClose}
      //             messageType={toastrMessageType}
      //           />
      //         </div>
      //       </Grid>
      //     </Grid>
      //   </section>
      // </div>
    );
  }
}

AccountSetup.contextType = LanguageContext;

export default withRouter(
  connect(
    ({ farmTag }) => ({
      isLandRegistered: farmTag.isLandRegistered,
      farmDetails: farmTag.farmDetails,
      farmTagError: farmTag.farmTagError,
      removeFarmError: farmTag.removeFarmError,
      farmCard: farmTag.farmCard,
      landData: farmTag.landData,
      showLandSuccessMsg: farmTag.showLandSuccessMsg,
      showLandRemoveSuccessMsg: farmTag.showLandRemoveSuccessMsg,
      isLandRemoved: farmTag.isLandRemoved,
    }),
    dispatch => ({
      addFarmer: (id, locationDetails, token) => {
        dispatch(addSelectedFarm(id, locationDetails, token));
      },
      deleteFarm: (farmid, token) => {
        dispatch(removeFarm(farmid, token));
      },
      resetDetails: () => dispatch(resetAccountSetUpDetails()),
      getFarmCard: (farmId, token) => {
        dispatch(getSelectedFarmCard(farmId, token));
      },
      getFarmerLandData: (token) => {
        dispatch(getLandData(token));
      },
      resetLandDetails: () => dispatch(resetAccountSetUpLandDetails()),
    }),
  )(withCookies(AccountSetup)),
);
