const initialState = {
  cropData: [],
  cropCoverage: [],
  isLoading: false,
  changeInCropCoverage: {},
  error: '',
  coverageReportData: [],
  compareCoverageData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COVERAGE_CROPS_SUCCESS':
      return {
        ...state,
        cropData: action.payload,
      };
    case 'FETCH_COVERAGE_CROPS_FAILURE':
      return {
        ...state,
        cropData: [],
      };
    case 'FETCH_CROP_COVERAGE_SUCCESS':
      return {
        ...state,
        cropCoverage: action.payload,
      };
    case 'FETCH_CROP_COVERAGE_FAILURE':
      return {
        ...state,
        cropCoverage: [],
      };

    case 'FETCH_CROP_COVERAGE_CHANGE_SUCCESS':
      return {
        ...state,
        changeInCropCoverage: action.payload,
      };
    case 'FETCH_CROP_COVERAGE_CHANGE_FAILURE':
      return {
        ...state,
        changeInCropCoverage: {},
      };

    case 'RESET_COVERAGE_DATA':
      return {
        ...state,
        ...initialState,
        cropData: state.cropData,
        coverageReportData: [],
        compareCoverageData: [],
      };

    case 'COVERAGE_REPORT_DATA_SUCCESS':
      return {
        ...state,
        coverageReportData: action.payload,
      };

    case 'COVERAGE_REPORT_DATA_FAILURE':
      return {
        ...state,
        coverageReportData: [],
      };

    case 'COMPARE_COVERAGE_REPORT_DATA_SUCCESS':
      return {
        ...state,
        compareCoverageData: action.payload,
      };

    case 'COMPARE_COVERAGE_REPORT_DATA_FAILURE':
      return {
        ...state,
        compareCoverageData: [],
      };

    default:
      return state;
  }
};
