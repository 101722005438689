import ajax from '../Functions/ajax';

export const getImdData = params => (dispatch) => {
  dispatch({
    type: 'IMD_DATA_FETCHING',
    payload: '',
  });
  ajax
    .get('/weather/weather/getImdData', {
      params,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'IMD_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'IMD_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'IMD_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getHistoricalImdData = params => (dispatch) => {
  dispatch({
    type: 'IMD_HISTORICAL_DATA_FETCHING',
    payload: '',
  });
  ajax
    .get('/weather/weather/getImdDataHistorical', {
      params,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'IMD_HISTORICAL_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'IMD_HISTORICAL_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'IMD_HISTORICAL_DATA_FAILURE',
        payload: error,
      });
    });
};

export const indicesSpi = data => (dispatch) => {
  ajax
    .post('/services/services/spi_index', {
      data: {
        level: data.level,
        month: data.month,
        stateID: data.stateID,
        districtID: data.districtID,
        avgSelector: data.avgSelector,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'INDICES_SPI_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'INDICES_SPI_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'INDICES_SPI_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getIndicesSmap = params => (dispatch) => {
  ajax
    .get('/services/services/smap_data', {
      params,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'SMAP_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SMAP_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'SMAP_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getHistoricalNoaaData = data => (dispatch) => {
  ajax
    .post('/services/services/noaa_data', {
      data: {
        level: data.level,
        stateID: data.stateID,
        districtID: data.districtID,
        avgSelector: data.avgSelector,
        startDate: data.startDate,
        endDate: data.endDate,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'NOAA_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'NOAA_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'NOAA_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getHistoricalChiprsData = data => (dispatch) => {
  ajax
    .post('/services/services/chirps_data', {
      data: {
        level: data.level,
        stateID: data.stateID,
        districtID: data.districtID,
        avgSelector: data.avgSelector,
        startDate: data.startDate,
        endDate: data.endDate,
        type: data.type,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CHIRPS_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CHIRPS_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CHIRPS_DATA_FAILURE',
        payload: error,
      });
    });
};

export const getHistoricalGpmData = data => (dispatch) => {
  ajax
    .post('/services/services/gpm_data', {
      data: {
        level: data.level,
        stateID: data.stateID,
        districtID: data.districtID,
        avgSelector: data.avgSelector,
        startDate: data.startDate,
        endDate: data.endDate,
        type: data.type,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'GPM_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GPM_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GPM_DATA_FAILURE',
        payload: error,
      });
    });
};

export const resetWeatherSourceDetails = clearParam => ({
  type: 'RESET_WEATHER_SOURCE_DETAILS',
  payload: clearParam,
});

export const indicesSpiHeatmap = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_HEATMAPS',
    payload: null,
  });
  ajax
    .post('/services/services/spi_heatmap', {
      data: params,
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'SPI_HEATMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SPI_HEATMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'SPI_HEATMAP_FAILURE',
        payload: error,
      });
    });
};

export const clearSpiHeatMap = () => ({
  type: 'CLEAR_SPI_HEATMAP',
  payload: null,
});

export const getIndicesSmapHeatMap = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_SMAP_HEATMAP',
    payload: null,
  });
  ajax
    .get('/services/services/smap_heatmap', {
      params,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'SMAP_HEATMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SMAP_HEATMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'SMAP_HEATMAP_FAILURE',
        payload: error,
      });
    });
};

export const clearIndicesSmapHeatMap = () => ({
  type: 'CLEAR_SMAP_HEATMAP',
  payload: null,
});

export const getChirpsHeatMap = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_HEATMAPS',
    payload: null,
  });
  ajax
    .post('/services/services/chirps_heatmap', {
      data: params,
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'CHIRPS_HEATMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CHIRPS_HEATMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CHIRPS_HEATMAP_FAILURE',
        payload: error,
      });
    });
};

export const clearChirpsHeatMap = () => ({
  type: 'CLEAR_CHIRPS_HEATMAP',
  payload: null,
});

export const getNoaaHeatMap = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_HEATMAPS',
    payload: null,
  });
  ajax
    .post('/services/services/noaa_heatmap', {
      data: params,
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'NOAA_HEATMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'NOAA_HEATMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'NOAA_HEATMAP_FAILURE',
        payload: error,
      });
    });
};

export const clearNoaaHeatMap = () => ({
  type: 'CLEAR_NOAA_HEATMAP',
  payload: null,
});

export const getGpmHeatMap = params => (dispatch) => {
  dispatch({
    type: 'CLEAR_HEATMAPS',
    payload: null,
  });
  ajax
    .post('/services/services/gpm_heatmap', {
      data: params,
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'GPM_HEATMAP_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GPM_HEATMAP_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'GPM_HEATMAP_FAILURE',
        payload: error,
      });
    });
};

export const clearGPMHeatMap = () => ({
  type: 'CLEAR_GPM_HEATMAP',
  payload: null,
});

export const getPDSIData = params => (dispatch) => {
  ajax
    .get('/indices/indices/pdsi', {
      params,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'PDSI_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'PDSI_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'PDSI_DATA_FAILURE',
        payload: error,
      });
    });
};
