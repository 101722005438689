import React, { Component } from 'react';
import { DragSource } from 'react-dnd-cjs';

const Types = {
  CARD: 'card',
};

const cardSource = {
  // Required. When the dragging starts
  beginDrag(props) {
    return { index: props.index, inventoryName: props.inventoryName };
  },
};

const collect = (connect, monitor) => ({
  // Call this function inside render()
  // to let React DnD handle the drag events:
  connectDragSource: connect.dragSource(),
  // You can ask the monitor about the current drag state:
  isDragging: monitor.isDragging(),
});

class InventoryChip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDrop = () => {
    console.log('Chip = ', this.props);
    const { onChipDrop, chip, inventoryName } = this.props;
    onChipDrop(chip, inventoryName);
  };

  render() {
    const { connectDragSource, inventoryName } = this.props;
    return connectDragSource(
      <div className="inventoryChip" onDragEnd={this.handleDrop}>
        <p>
          <i className="material-icons">add_circle</i>
          <span>{inventoryName}</span>
        </p>
      </div>,
    );
  }
}

export default DragSource(Types.CARD, cardSource, collect)(InventoryChip);
