import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  componentDidMount() {
    // const { showDefaultDate } = this.props;
    // this.setState({
    //   startDate: showDefaultDate ? moment().subtract(6, 'months') : null,
    //   endDate: showDefaultDate ? moment().subtract(4, 'months') : null,
    // });
  }

  handleDateChange = ({ startDate, endDate }) => {
    const {
      getDate,
      comparison,
      getDateComparison,
      clearDates,
      updateDates,
      secondTab,
    } = this.props;
    if (!startDate && !endDate) {
      if (!comparison) {
        clearDates();
      }
    }
    // let startDateFormatted;
    // let endDateFormatted;
    // debugger;
    // if (startDate) {
    //   startDateFormatted = startDate._d
    //     .toLocaleDateString()
    //     .split('/')
    //     .join('-');
    // }
    // if (endDate) {
    //   endDateFormatted = endDate._d
    //     .toLocaleDateString()
    //     .split('/')
    //     .join('-');
    // }
    // debugger;
    // this.setState({
    //   startDate,
    //   endDate,
    // });
    if (comparison) {
      // debugger;
      if (!startDate && !endDate) {
        updateDates(null, null, null, null, comparison);
      }
      if (startDate) {
        updateDates(null, null, startDate, null, comparison);
      }

      if (endDate) {
        updateDates(null, null, null, endDate, comparison);
      }
    } else {
      if (!startDate && !endDate) {
        updateDates(null, null, null, null, comparison);
      }
      if (startDate) {
        updateDates(startDate, null, null, null, comparison);
      }

      if (endDate) {
        updateDates(null, endDate, null, null, comparison);
      }
    }

    if (startDate && endDate && comparison) getDateComparison(startDate, endDate);
    else if (startDate && endDate) getDate(startDate, endDate, secondTab);
  };

  handleFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  isOutsideRange = () => false;

  render() {
    const { startDate, endDate } = this.props;
    const { focusedInput } = this.state;

    console.log('======>', startDate);
    return (
      <div className="datePickerPanel">
        <h5>Date Range</h5>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate"
          endDate={endDate}
          endDateId="endDate"
          focusedInput={focusedInput}
          isOutsideRange={this.isOutsideRange} // for showing previous dates
          onDatesChange={this.handleDateChange}
          onFocusChange={this.handleFocusChange}
          startDatePlaceholderText="Start Date"
          endDatePlaceholderText="End Date"
          readOnly
          showDefaultInputIcon // calendar icon
          showClearDates={!!(startDate && endDate)} // clear icon cross
          hideKeyboardShortcutsPanel // hide question icon
          inputIconPosition="after"
          displayFormat="DD/MM/YY"
          small
          withPortal
        />
      </div>
    );
  }
}

export default DateRange;
