import ajax from '../Functions/ajax';

export const fetchCropsAct = (params, token, history, cookies) => (dispatch) => {
  ajax
    .get('/master/pmfby/crops', {
      params,
      isLoader: false,
      headers: { token },
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_COVERAGE_CROPS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_COVERAGE_CROPS_FAILURE',
          payload: response.data.error,
        });
        if (response.status === 401) {
          cookies.remove('userData', null);
          cookies.remove('loggedInTimeStamp', null);
          history.push('/');
        }
      }
    })
    .catch((err) => {
      console.log('err ', err);
      dispatch({
        type: 'FETCH_COVERAGE_CROPS_FAILURE',
        payload: err,
      });
    });
};

export const fetchCropCoverageAct = (
  params,
  token,
  history,
  cookies,
) => (dispatch) => {
  ajax
    .get('/master/pmfby/districtWiseCovergae', {
      params,
      isLoader: false,
      headers: { token },
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_CROP_COVERAGE_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_CROP_COVERAGE_FAILURE',
          payload: [],
        });
        if (response.status === 401) {
          cookies.remove('userData', null);
          cookies.remove('loggedInTimeStamp', null);
          history.push('/');
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: 'FETCH_CROP_COVERAGE_FAILURE',
        payload: err,
      });
    });
};

export const fetchCropCoverageChangeAct = (
  params,
  token,
  history,
  cookies,
) => (dispatch) => {
  ajax
    .get('/master/pmfby/changeInAreaCoverage', {
      params,
      isLoader: false,
      headers: { token },
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_CROP_COVERAGE_CHANGE_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_CROP_COVERAGE_CHANGE_FAILURE',
          payload: response.data.error,
        });
        if (response.status === 401) {
          cookies.remove('userData', null);
          cookies.remove('loggedInTimeStamp', null);
          history.push('/');
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: 'FETCH_CROP_COVERAGE_CHANGE_FAILURE',
        payload: err,
      });
    });
};

export const fetchCoverageReportAct = data => (dispatch) => {
  ajax
    .post('/indices/indices/coverageReport', {
      data: {
        locationID: data.locationID,
        year: data.year,
        fetchLevel: data.fetchLevel,
        cropID: data.cropID,
      },
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'COVERAGE_REPORT_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'COVERAGE_REPORT_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'COVERAGE_REPORT_DATA_FAILURE',
        payload: error,
      });
    });
};

export const fetchCoverageReportCompareAct = data => (dispatch) => {
  ajax
    .post('/indices/indices/coverageReport', {
      data: {
        locationID: data.locationID,
        year: data.year,
        fetchLevel: data.fetchLevel,
        cropID: data.cropID,
      },
      isLoader: true,
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({
          type: 'COMPARE_COVERAGE_REPORT_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'COMPARE_COVERAGE_REPORT_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'COMPARE_COVERAGE_REPORT_DATA_FAILURE',
        payload: error,
      });
    });
};

export const resetCoverageDataAct = () => ({
  type: 'RESET_COVERAGE_DATA',
  payload: null,
});
