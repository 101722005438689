import React, { Component } from 'react';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import { formatDuration } from './audioPlayerUtil';
import '../../css/ivrs/audioRecorder.scss';

const RECORDER_STATUS_LIST = [
  {
    id: 0,
    name: '',
  },
  {
    id: 1,
    name: 'Recording',
  },
  {
    id: 2,
    name: 'Done',
  },
];

const DURATION_LIMIT = 30 * 1000;

// const hasGetUserMedia = !!(
//   navigator.getUserMedia
//   || navigator.webkitGetUserMedia
//   || navigator.mozGetUserMedia
//   || navigator.msGetUserMedia
// );
let Microm;

class MediaAudioRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // microm: {},
      recorderStatusId: RECORDER_STATUS_LIST[0].id,
      // mp3: undefined,
      startTime: 0,
      duration: 0,
      mediaTitle: '',
      mediaDesc: '',
      tags: ['Seed', 'Govt.'],
      language: 'English',
      selectedGrp: 'Feedback',
    };
  }

  componentDidMount() {
    /* eslint-disable global-require */
    Microm = require('microm');
    /* eslint-enable global-require */
    Microm = new Microm();
    // if (!hasGetUserMedia) {
    //   alert(
    //     'Your browser cannot stream from your webcam. Please switch to Chrome or Firefox.',
    //   );
    // }
  }

  startRecord = () => {
    const self = this;
    Microm.record()
      .then(() => {
        self.setState({ startTime: new Date().getTime() });
        self.setState({
          recorderStatusId: RECORDER_STATUS_LIST[1].id,
          recordTimerId: setInterval(() => {
            const currentTime = new Date().getTime();
            const duration = currentTime - self.state.startTime;
            self.setState({ duration });
            if (duration >= DURATION_LIMIT) {
              self.stopRecord();
            }
          }, 500),
        });
      })
      .catch(() => {
        console.error('error recording');
      });
  };

  deactivateTimer = () => {
    const { recordTimerId } = this.state;
    if (recordTimerId) clearInterval(recordTimerId);
    this.setState({ recordTimerId: undefined });
  };

  readRecordingAsBlob = () => {
    const self = this;
    Microm.getBase64().then((base64String) => {
      self.props.updateBase64Recorder(base64String);
    });
  };

  stopRecord = () => {
    const self = this;
    Microm.stop().then((result) => {
      self.deactivateTimer();
      self.readRecordingAsBlob();
      self.setState({
        mp3: result,
        recorderStatusId: RECORDER_STATUS_LIST[2].id,
      });
    });
  };

  /* pauseRecord = () => {
    const { startTime } = this.state;
    Microm.pause();
    console.log('startTime', startTime);
  }; */

  handleTitleChange = (e) => {
    const { updateText } = this.props;
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        mediaTitle: value,
      },
      () => {
        updateText(value);
      },
    );
  };

  handleMediaDesc = (e) => {
    const { updateDesc } = this.props;
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        mediaDesc: value,
      },
      () => {
        updateDesc(value);
      },
    );
  };

  addTags = (e) => {
    const { tags } = this.state;
    if (e.key === 'Enter' && e.target.value !== '') {
      this.setState({
        tags: [...tags, e.target.value],
      });
      e.target.value = '';
    }
  };

  removeTags = (index) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter(tag => tags.indexOf(tag) !== index),
    });
  };

  handleLangChange = (e) => {
    const { updateLanguage } = this.props;
    this.setState({
      language: e.target.value,
    });
    updateLanguage(e.target.value);
  };

  handleSelectGrp = (e) => {
    this.setState({
      selectedGrp: e.target.value,
    });
  };

  render() {
    const {
      recorderStatusId,
      duration,
      startTime,
      mediaTitle,
      mediaDesc,
      tags,
      language,
      selectedGrp,
    } = this.state;
    const { mediaTitleError, mediaDescError, mediaError } = this.props;
    const isRecording = recorderStatusId === RECORDER_STATUS_LIST[1].id;
    const max = DURATION_LIMIT;
    const currentTime = duration;
    const formattedDuration = formatDuration(duration / 1000);
    console.log('startTime', startTime);
    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={3} md={3} xs={12}>
            <TextField
              id="ivrsRecordTitle"
              label="Recording Title"
              helperText={mediaTitleError || ''}
              error={!!mediaTitleError}
              className="ivrsTitle"
              margin="normal"
              value={mediaTitle}
              onChange={this.handleTitleChange}
            />
            {/* <i className="material-icons">edit</i> */}
          </Grid>
          <Grid item lg={2} md={2} xs={12} />
          <Grid item lg={7} md={7} xs={12}>
            <TextField
              id="ivrsRecordDesc"
              label="Text of audio file"
              className="mediaDesc"
              helperText={mediaDescError || ''}
              error={!!mediaDescError}
              margin="normal"
              value={mediaDesc}
              onChange={this.handleMediaDesc}
            />
          </Grid>
          <Grid item lg={2} md={2} xs={12} />
          {/*  <ul>
            {tags.map((tag, index) => (
              <li key={Math.random()}>
                <span>{tag}</span>
                <i
                  className="material-icons"
                  onClick={() => this.removeTags(index)}
                  onKeyDown={() => this.removeTags(index)}
                  role="button"
                  tabIndex="0"
                >
                  close
                </i>
              </li>
            ))}
          </ul>
          <input
            type="text"
            onKeyUp={event => this.addTags(event)}
            placeholder="Press enter to add tags"
          /> */}
        </Grid>
        <div className="media-body">
          <Grid container spacing={2}>
            <div className="recordTime">
              {/* <i className="fas fa-circle" />
              <span>Recorded</span> */}
              <div className="timeSec">{formattedDuration}</div>
            </div>
            <div className="progress-container">
              <progress className="progress" max={max} value={currentTime} />
            </div>
            <Grid item lg={12} md={12} xs={12}>
              <span className="duration">Max Duration 30 sec</span>
            </Grid>
            <ul className="recordButtons">
              <li>
                <Button
                  className={`${
                    isRecording ? 'startBtn disableBtn' : 'startBtn'
                  }`}
                  disabled={isRecording}
                  onClick={this.startRecord}
                >
                  <i className="material-icons">play_arrow</i>
                </Button>
              </li>
              {/* <li>
              <Button onClick={this.pauseRecord}>
                <i className="material-icons">mic</i>
              </Button>
            </li> */}
              <li>
                <Button
                  className={`${
                    !isRecording ? 'stopBtn disableBtn' : 'stopBtn'
                  }`}
                  disabled={!isRecording}
                  onClick={this.stopRecord}
                >
                  <i className="material-icons">fiber_manual_record</i>
                </Button>
              </li>
            </ul>
            <Grid item lg={12} md={12}>
              <p className="errorText">{mediaError}</p>
            </Grid>
          </Grid>
          <Grid container className="tagsTitle selectController">
            <Grid item lg={6} md={6} xs={12}>
              <h4>Tags</h4>
              <div className="ivrsTags">
                <div className="addTags">
                  <TextField
                    //  label="Press enter to add tags"
                    onKeyUp={event => this.addTags(event)}
                  />
                </div>
                <ul>
                  {tags.map((tag, index) => (
                    <li key={Math.random()}>
                      <span>{tag}</span>
                      <i
                        className="material-icons"
                        onClick={() => this.removeTags(index)}
                        onKeyDown={() => this.removeTags(index)}
                        role="button"
                        tabIndex="0"
                      >
                        close
                      </i>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <h4>Group</h4>
              <div className="ivrsTags">
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    name="feedback"
                    className="selectEmpty modalDropdown"
                    displayEmpty
                    value={selectedGrp}
                    onChange={this.handleSelectGrp}
                  >
                    <MenuItem value="Feedback">Feedback</MenuItem>
                    <MenuItem value="Data">Data</MenuItem>
                    <MenuItem value="Market">Market</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <h4>Audio Language</h4>
              <div className="ivrsTags">
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    name="feedback"
                    className="selectEmpty modalDropdown"
                    displayEmpty
                    value={language}
                    onChange={this.handleLangChange}
                  >
                    <MenuItem value="Hindi">Hindi</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          {/*        <Grid container>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
            className="ivrsTags"
            style={{ marginLeft: '10px' }}
          >
            <ul>
              {tags.map((tag, index) => (
                <li key={Math.random()}>
                  <span>{tag}</span>
                  <i
                    className="material-icons"
                    onClick={() => this.removeTags(index)}
                    onKeyDown={() => this.removeTags(index)}
                    role="button"
                    tabIndex="0"
                  >
                    close
                  </i>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item lg={2} md={2} xs={12} className="ivrsTags">
             <input
              type="text"
              onKeyUp={event => this.addTags(event)}
              placeholder="Press enter to add tags"
            />
            <TextField
              //  label="Press enter to add tags"
              className="addTags"
              onKeyUp={event => this.addTags(event)}
            />
          </Grid>
          <Grid
            item
            lg={5}
            md={5}
            xs={12}
            className="ivrsTags"
            style={{ marginLeft: '20px' }}
          >
            <div className="addGroup">
              <h1>
                Select group or
                {' '}
                <Button>
                  <i className="material-icons">add</i>
                  Create Group
                </Button>
              </h1>
            </div>
          </Grid>
        </Grid> */}
        </div>
      </React.Fragment>
    );
  }
}

export default MediaAudioRecorder;
