import React from 'react';
import { MobileStepper, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class MobileStepperHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleStepChange = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  render() {
    const {
      autoPlaySteps,
      // autoSwipeAxis, // "x", "y" , "x-reverse", "y-reverse",
      children,
    } = this.props;
    // const theme = useTheme();
    const { activeStep } = this.state;
    return (
      <div>
        {autoPlaySteps && (
          <AutoPlaySwipeableViews
            axis="x"
            index={activeStep}
            onChangeIndex={this.handleStepChange}
          >
            {children.map((child, index) => {
              if (index === activeStep) {
                return child;
              }
              return <div key={Math.random()} />;
            })}
          </AutoPlaySwipeableViews>
        )}
        {!autoPlaySteps
          && children.map((child, index) => {
            if (index === activeStep) {
              return child;
            }
            return <div key={Math.random()} />;
          })}
        <MobileStepper
          steps={children.length}
          position="static"
          variant="progress"
          activeStep={activeStep}
          nextButton={(
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === children.length - 1}
            >
              <KeyboardArrowRight />
            </Button>
)}
          backButton={(
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
)}
        />
      </div>
    );
  }
}

export default MobileStepperHOC;
