import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { setMessage } from '../../Actions/agricultureIndicesAction';
import Loader from './Loader';
import Welcome from './welcomeGif';
import NotFound from './notFound';
import ErrorBoundary from './errorBoundary';
import EnsureLoggedInContainer from './ensureLoggedInContainer';
import { checkUserData } from '../../Actions/appAction';

import '../../css/modal.scss';
import '../../css/form.scss';
import '../../css/mapComponent.scss';
import '../../css/gis/gis.scss';

const loggedInRoutes = ['/dashboard', '/tagging', '/home'];

const AsyncFarmEngine = Loadable({
  loader: () => import(/* webpackChunkName: "farmengine" */ '../farmEngine/index'),
  loading: () => <Welcome />,
  modules: ['farmengine'],
});

const AsyncPricing = Loadable({
  loader: () => import(/* webpackChunkName: "farmengine" */ '../pricing/index'),
  loading: () => <Welcome />,
  modules: ['pricing'],
});

const AsyncChoosePlan = Loadable({
  loader: () => import(/* webpackChunkName: "farmengine" */ '../choosePlan/index'),
  loading: () => <Welcome />,
  modules: ['choosePlan'],
});

// const AsyncAccountSetup = Loadable({
//  loader: () => import(/* webpackChunkName: "accountSetup" */ '../accountSetup/index'),
//  loading: () => <Welcome />,
//  modules: ['accountSetup'],
// });
// const AsyncFarmerSetup = Loadable({
//   loader: () => import(/* webpackChunkName: "farmerSetup" */ '../farmerSetup/index'),
//   loading: () => <Welcome />,
//   modules: ['farmerSetup'],
// });
//
// const AsyncDashboard = Loadable({
//   loader: () => import(/* webpackChunkName: "dashboard" */ '../dashboard/index'),
//   loading: () => <Welcome />,
//   modules: ['dashboard'],
// });
// const AccountSetup = Loadable({
//   loader: () => import(/* webpackChunkName: "accountSetup" */ '../accountSetup/index'),
//   loading: () => <Welcome />,
//   modules: ['gis'],
// });

/* const AsyncTag = Loadable({
  loader: () => import(/!* webpackChunkName: "dashboard" *!/ '../tagging/index'),
  loading: () => <Welcome />,
  modules: ['dashboard'],
}); */

const AsyncMyResource = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ '../resources/index'),
  loading: () => <Welcome />,
  modules: ['dashboard'],
});

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { flag: true };
  }

  componentDidMount() {
    const {
      message, updateMessage, cookies, updateUserData,
    } = this.props;
    if (!message) {
      updateMessage("Hi, I'm from client!");
    }
    const userdata = cookies.get('userData');
    updateUserData(userdata);
  }

  componentDidUpdate(prevProps) {
    const {
      history, location, cookies, isLogin,
    } = this.props;
    const { flag } = this.state;
    const userdata = cookies.get('userData');
    const loggedInTimeStamp = cookies.get('loggedInTimeStamp');
    if (!isLogin) {
      if (userdata) {
        const userTokenExp = userdata.data.sessionTTL;
        if (userTokenExp * 1000 + loggedInTimeStamp < Date.now()) {
          cookies.remove('userData', null);
          cookies.remove('currentTimestamp', null);
          history.push('/');
        } else {
          const { data } = userdata;
          const { userRoleData } = data;
          // const { roles } = data;
          if (history.location.pathname === '/') {
            setTimeout(() => {
              this.handleRoute(userRoleData.roleName);
              /* if (roles && roles.length > 0) {
                this.handleRoute(roles[0].roleName);
              } else {
                this.handleRoute('SeedCompany');
              } */
            }, 200);
          }
        }
      } else if (flag) {
        // this.setState({
        //   flag: false,
        // });
        this.handleFlag(false);
        history.push('/');
      }
    }
    if (!userdata) {
      if (prevProps.location.pathname !== location.pathname && flag) {
        this.handleFlag(false);
        history.push('/');
      }
    }
    if (userdata) {
      if (
        loggedInRoutes.includes(prevProps.location.pathname)
        && !loggedInRoutes.includes(location.pathname)
      ) {
        history.push(prevProps.location.pathname);
      }
      /*   if (
        prevProps.location.pathname === '/myaccount'
        && location.pathname !== '/myaccount'
      ) {
        history.push('/myaccount');
      }
      if (
        prevProps.location.pathname === '/dashboard'
        && location.pathname !== '/dashboard'
      ) {
        history.push('/dashboard');
      }
      if (
        prevProps.location.pathname !== '/home'
        && location.pathname === '/home'
      ) {
        history.push('/home');
      } */
    }
  }

  handleFlag = (boolean) => {
    this.setState({
      flag: boolean,
    });
  };

  handleRoute = (roleName) => {
    const { history } = this.props;
    switch (roleName) {
      case 'farmer':
        history.push('/myaccount');
        break;
      case 'SeedCompany':
        history.push('/tagging');
        break;
      default:
        history.push('/dashboard');
        break;
    }
  };

  render() {
    const { fetching } = this.props;
    return (
      <React.Fragment>
        {fetching ? <Loader /> : null}
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact component={AsyncFarmEngine} />
            <Route path="/pricing" exact component={AsyncPricing} />
            <Route path="/choosePlan" exact component={AsyncChoosePlan} />
            <Route path="/myresources" exact component={AsyncMyResource} />
            {/* <Route path="/tagging" exact component={AsyncTag} /> */}
            {/* <Route path="/myaccount" exact component={AsyncAccountSetup} /> */}
            <Route render={EnsureLoggedInContainer} />
            {/* <Route path="/myaccount" exact component={AsyncFarmerSetup} />
            <Route path="/dashboard" exact component={AsyncDashboard} />

            <Route path="/setup" component={AccountSetup} /> */}
            <Route path="*" render={NotFound} />
          </Switch>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

// Just for example, later it will be removed
export default withRouter(
  connect(
    ({ app }) => ({
      fetching: app.fetching,
      isLogin: app.isLogin,
    }),
    dispatch => ({
      updateMessage: messageText => dispatch(setMessage(messageText)),
      updateUserData: userData => dispatch(checkUserData(userData)),
    }),
  )(withCookies(MainPage)),
);
