import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import moment from 'moment';
import {
  Select, FormControl, MenuItem, Grid, Button,
} from '@material-ui/core';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import DateRangeClone from '../dateRangeClone';
import {
  resetWeatherSourceDetails,
  getHistoricalNoaaData,
  getHistoricalChiprsData,
  getHistoricalGpmData,
  getChirpsHeatMap,
  getNoaaHeatMap,
  getGpmHeatMap,
} from '../../../Actions/weatherAction';
import { VerticalLineChart } from '../../common/chart';
// import '../../../css/commonTable.scss';

class WeatherHistoricalTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weatherSource: 'IMD AWS',
      // locationObject: {},
      error: '',
      isGenerate: false,
      timeSeries: 'daily',
      startDate: moment()
        .subtract(4, 'months')
        ._d.toLocaleDateString('en-IN')
        .split('/')
        .join('-'),
      endDate: moment()
        .subtract(2, 'months')
        ._d.toLocaleDateString('en-IN')
        .split('/')
        .join('-'),
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.selectedStateID !== prevProps.location.selectedStateID) {
      this.updateDataOnLocChange();
    }
    if (
      location.selectedDistrictID !== prevProps.location.selectedDistrictID
      && location.selectedDistrictID !== ''
    ) {
      this.updateDataOnLocChange();
    }
    if (
      location.selectedStateID === prevProps.location.selectedStateID
      && location.selectedDistrictID === ''
      && prevProps.location.selectedDistrictID !== ''
    ) {
      this.updateDataOnLocChange();
    }
  }

  _handleWeatherSource = (ev) => {
    const { broadcastWeatherSourceChange } = this.props;
    this.setState({
      weatherSource: ev.target.value,
    });
    broadcastWeatherSourceChange(ev.target.value);
  };

  _resetData = (ev) => {
    const { resetDetails } = this.props;
    ev.preventDefault();
    this.setState({
      isGenerate: false,
      error: '',
      timeSeries: 'daily',
      // locationObject: {},
    });
    resetDetails(1);
  };

  _getWeatherSource = (value) => {
    switch (value) {
      case 'IMD AWS':
        return 'IMD AWS';
      case 'Chirps':
        return 'CHIRPS';
      case 'NOAA':
        return 'NOAA';
      case 'GPM':
        return 'GPM';
      default:
        return null;
    }
  };

  _handledayChange = (ev) => {
    const { startDate, endDate, weatherSource } = this.state;
    const { resetDetails } = this.props;
    this.setState(
      {
        timeSeries: ev.target.value,
      },
      () => {
        if (startDate && endDate && weatherSource === 'IMD AWS') {
          this.getImdHistoricalData();
        }
        if (startDate && endDate && weatherSource === 'NOAA') {
          this.updateNoaaHistoricalData();
        }
        if (startDate && endDate && weatherSource === 'GPM') {
          this.updateGPMHistoricalData();
        }
        if (weatherSource === 'Chirps' && startDate && endDate) {
          this.updateChirpsHistoricalData();
        }
      },
    );
    resetDetails(1);
  };

  getDate = (startDate, endDate) => {
    const { weatherSource } = this.state;
    const { resetDetails } = this.props;
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        if (startDate && endDate && weatherSource === 'IMD AWS') {
          this.getImdHistoricalData();
        }
        if (weatherSource === 'NOAA' && startDate && endDate) {
          this.updateNoaaHistoricalData();
        }
        if (weatherSource === 'GPM' && startDate && endDate) {
          this.updateGPMHistoricalData();
        }
        if (weatherSource === 'Chirps' && startDate && endDate) {
          this.updateChirpsHistoricalData();
        }
      },
    );
    resetDetails(1);
  };

  clearDates = () => {
    console.log('no dates found!!');
    const { resetDetails } = this.props;
    this.setState({
      startDate: null,
      endDate: null,
    });
    resetDetails(1);
  };

  getImdHistoricalData = () => {
    const { startDate, endDate, timeSeries } = this.state;
    const { updateImdHistoricalData } = this.props;
    updateImdHistoricalData(startDate, endDate, timeSeries);
    // const data = {};
    // data.districtID = location.selectedDistrictID;
    // data.dateFrom = startDate
    //   .split('-')
    //   .reverse()
    //   .join('-');
    // data.dateTo = endDate
    //   .split('-')
    //   .reverse()
    //   .join('-');
    // data.timeSeries = timeSeries;
    // fetchImdHistoricalData(data);
  };

  updateNoaaHistoricalData = () => {
    const { startDate, endDate, timeSeries } = this.state;
    const { location, fetchNoaaHistoricalData, fetchNoaaHeatmap } = this.props;
    let avgSelector;
    let level;
    if (location.selectedStateID) level = 2;
    if (location.selectedDistrictID) level = 3;
    if (timeSeries === 'daily') {
      avgSelector = 1;
    } else if (timeSeries === 'weekly') {
      avgSelector = 7;
    } else {
      avgSelector = 14;
    }
    const data = {};
    data.level = level;
    data.avgSelector = avgSelector;
    // data.startDate = '2000-01-01';
    // data.endDate = '2000-02-06';
    data.startDate = startDate
      .split('-')
      .reverse()
      .join('-');
    data.endDate = endDate
      .split('-')
      .reverse()
      .join('-');
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    fetchNoaaHistoricalData(data);
    fetchNoaaHeatmap(data);
  };

  updateGPMHistoricalData = () => {
    const { startDate, endDate, timeSeries } = this.state;
    const { location, fetchGpmHistoricalData, fetchGpmHeatmap } = this.props;
    let avgSelector;
    let level;
    if (location.selectedStateID) level = 2;
    if (location.selectedDistrictID) level = 3;
    if (timeSeries === 'daily') {
      avgSelector = 2;
    } else if (timeSeries === 'weekly') {
      avgSelector = 7;
    } else {
      avgSelector = 14;
    }
    const data = {};
    data.level = level;
    data.avgSelector = avgSelector;
    data.startDate = startDate
      .split('-')
      .reverse()
      .join('-');
    data.endDate = endDate
      .split('-')
      .reverse()
      .join('-');
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    data.type = 2;
    fetchGpmHistoricalData(data);
    fetchGpmHeatmap(data);
  };

  updateChirpsHistoricalData = () => {
    const { startDate, endDate, timeSeries } = this.state;
    const {
      location,
      fetchChirpsHistoricalData,
      fetchChirpsHeatmap,
    } = this.props;
    let avgSelector;
    let level;
    let type;
    if (location.selectedStateID) level = 2;
    if (location.selectedDistrictID) level = 3;
    if (timeSeries === 'daily') {
      avgSelector = 1;
      type = 1;
    } else if (timeSeries === 'weekly') {
      avgSelector = 7;
      type = 1;
    } else if (timeSeries === 'total7Days') {
      avgSelector = 7;
      type = 2;
    } else if (timeSeries === 'total14Days') {
      avgSelector = 14;
      type = 2;
    } else {
      avgSelector = 14;
      type = 1;
    }
    const data = {};
    data.level = level;
    data.avgSelector = avgSelector;
    data.startDate = startDate
      .split('-')
      .reverse()
      .join('-');
    data.endDate = endDate
      .split('-')
      .reverse()
      .join('-');
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    data.type = type;
    fetchChirpsHistoricalData(data);
    fetchChirpsHeatmap(data);
  };

  _generateData = (ev) => {
    const { weatherSource } = this.state;
    const { locationObject } = this.props;
    ev.preventDefault();
    let error = '';
    let check = true;
    if (!weatherSource) {
      check = false;
      error = 'please select Weather Source from above';
    }
    if (_.isEmpty(locationObject)) {
      check = false;
      error = 'please select Location from above or from map';
    }
    if (!locationObject.state) {
      check = false;
      error = 'please select Location from above or from map';
    }
    // if (location.selectedStateID) {
    //   level = 1;
    // }
    // if (location.selectedDistrictID) {
    //   level = 2;
    // }
    if (check) {
      this.setState(
        {
          isGenerate: true,
          error: '',
        },
        () => {
          if (weatherSource === 'IMD AWS') {
            this.getImdHistoricalData();
          }
          if (weatherSource === 'NOAA') {
            this.updateNoaaHistoricalData();
          }
          if (weatherSource === 'GPM') {
            this.updateGPMHistoricalData();
          }
          if (weatherSource === 'Chirps') {
            this.updateChirpsHistoricalData();
          }
        },
      );
    } else {
      this.setState({
        isGenerate: false,
        error,
      });
    }
  };

  // updateLocationObject(type, value) {
  //   const {
  //     locationObject, startDate, endDate, isGenerate,
  //   } = this.state;
  //   const { location } = this.props;
  //   const locationObjectCopy = { ...locationObject };
  //   locationObjectCopy[type] = value;
  //   this.setState({
  //     locationObject: locationObjectCopy,
  //   });
  //   if (
  //     isGenerate
  //     && value
  //     && startDate
  //     && endDate
  //     && location.selectedDistrictID
  //   ) {
  //     this.updateImdHistoricalData();
  //   }
  // }

  updateDataOnLocChange() {
    const {
      startDate, endDate, isGenerate, weatherSource,
    } = this.state;
    const {
      location,
      locationObject,
      resetDetails,
      activeWeatherTab,
    } = this.props;
    if (
      isGenerate
      && weatherSource === 'IMD AWS'
      && startDate
      && endDate
      && location.selectedDistrictID
      && activeWeatherTab === 1
    ) {
      this.getImdHistoricalData();
    }
    if (
      isGenerate
      && location.selectedStateID
      && weatherSource === 'NOAA'
      && startDate
      && endDate
      && activeWeatherTab === 1
    ) {
      this.updateNoaaHistoricalData();
    }
    if (
      isGenerate
      && locationObject.district
      && location.selectedDistrictID
      && weatherSource === 'Chirps'
      && startDate
      && endDate
      && activeWeatherTab === 1
    ) {
      this.updateChirpsHistoricalData();
    }
    if (
      isGenerate
      && locationObject.district
      && location.selectedDistrictID
      && weatherSource === 'GPM'
      && startDate
      && endDate
      && activeWeatherTab === 1
    ) {
      this.updateGPMHistoricalData();
    }
    if (
      isGenerate
      && weatherSource === 'IMD AWS'
      && location.selectedDistrictID === ''
      && activeWeatherTab === 1
    ) {
      resetDetails(1);
      this.getImdHistoricalData();
    }
    if (
      isGenerate
      && weatherSource === 'Chirps'
      && location.selectedDistrictID === ''
      && activeWeatherTab === 1
    ) {
      resetDetails(1);
      this.updateChirpsHistoricalData();
    }
    if (
      isGenerate
      && weatherSource === 'NOAA'
      && location.selectedDistrictID === ''
      && activeWeatherTab === 1
    ) {
      resetDetails(1);
      this.updateNoaaHistoricalData();
    }
    if (
      isGenerate
      && weatherSource === 'GPM'
      && location.selectedDistrictID === ''
      && activeWeatherTab === 1
    ) {
      resetDetails(1);
      this.updateGPMHistoricalData();
    }
  }

  render() {
    const {
      weatherSource,
      error,
      isGenerate,
      timeSeries,
      startDate,
      endDate,
    } = this.state;
    const {
      broadcastLocationChange,
      location,
      imdHistoricalData,
      locationObject,
      noaaData,
      chirpsData,
      gpmData,
      dataError,
    } = this.props;
    console.log('startDate', startDate, 'endDate', endDate);
    console.log('noaaData', noaaData);
    console.log('chirpsData', chirpsData);
    console.log('dataError', dataError);
    const maxTempData = [];
    const minTempData = [];
    const rainfallImdData = [];
    let rainfallData = [];
    const chirpIndexData = [];
    const gpmIndexData = [];
    let gpmHisData = [];
    let tempData = [];
    let chirpData = [];
    const humidityAM = [];
    const humidityPM = [];
    let humidityData = [];
    if (
      !_.isEmpty(imdHistoricalData)
      && weatherSource === 'IMD AWS'
      && location.selectedDistrictID
      && startDate
      && endDate
    ) {
      const weatherData = imdHistoricalData.weather;
      weatherData.forEach((item) => {
        const maxTempObj = {
          category: item.date,
          value: item.maxTemp || 0,
        };
        maxTempData.push(maxTempObj);
        const minTempObj = {
          category: item.date,
          value: item.minTemp || 0,
        };
        minTempData.push(minTempObj);

        const humidity8AM = {
          category: item.date,
          value: item.humidity830 || 0,
        };
        humidityAM.push(humidity8AM);
        const humidity5PM = {
          category: item.date,
          value: item.humidity1730 || 0,
        };
        humidityPM.push(humidity5PM);

        const rainfallObj = {
          category: item.date,
          value: item.rainfall || 0,
        };
        rainfallImdData.push(rainfallObj);
      });
    }

    if (
      noaaData
      && noaaData.length > 0
      && weatherSource === 'NOAA'
      && startDate
      && endDate
    ) {
      console.log('noaaDataHis', noaaData);
      noaaData.forEach((item) => {
        const maxTempObj = {
          category: item.startDate,
          value: item.maxTemp || 0,
        };
        maxTempData.push(maxTempObj);
        const minTempObj = {
          category: item.startDate,
          value: item.minTemp || 0,
        };
        minTempData.push(minTempObj);
      });
    }

    if (
      chirpsData
      && chirpsData.length > 0
      && weatherSource === 'Chirps'
      && startDate
      && endDate
    ) {
      chirpsData.forEach((item) => {
        const chirpObj = {
          category: item.startDate,
          value: item.chirpsIndex || 0,
        };
        chirpIndexData.push(chirpObj);
      });
    }

    if (
      gpmData
      && gpmData.length > 0
      && weatherSource === 'GPM'
      && startDate
      && endDate
    ) {
      gpmData.forEach((item) => {
        const gpmObj = {
          category: item.startDate,
          value: item.gpmIndex || 0,
        };
        gpmIndexData.push(gpmObj);
      });
    }
    if (chirpIndexData.length > 0 && chirpIndexData.length > 0) {
      chirpData = [
        {
          name: 'Rainfall (mm)',
          color: '#09b466',
          data: chirpIndexData,
        },
      ];
    }
    if (gpmIndexData.length > 0 && gpmIndexData.length > 0) {
      gpmHisData = [
        {
          name: 'Rainfall (mm)',
          color: '#09b466',
          data: gpmIndexData,
        },
      ];
    }
    if (minTempData.length > 0 && maxTempData.length > 0) {
      tempData = [
        {
          name: 'Max Temp',
          color: '#09b466',
          data: maxTempData,
        },
        {
          name: 'Min Temp',
          color: '#0d21d8',
          data: minTempData,
        },
      ];
    }
    if (humidityAM.length > 0 && humidityPM.length > 0) {
      humidityData = [
        {
          name: 'at 8 am',
          color: '#09b466',
          data: humidityAM,
        },
        {
          name: 'at 17:30 pm',
          color: '#0d21d8',
          data: humidityPM,
        },
      ];
    }
    if (rainfallImdData.length > 0) {
      rainfallData = [
        {
          name: 'Rainfall',
          color: '#09b466',
          data: rainfallImdData,
        },
      ];
    }
    return (
      <React.Fragment>
        {weatherSource
        && !_.isEmpty(locationObject)
        && locationObject.state
        && isGenerate ? (
          <div className="indicesMainPanel">
            <div className="indicesList">
              <h4>
                Historical Report
                <a href="/" onClick={this._resetData}>
                  Edit Selection
                </a>
              </h4>
              <ul>
                {locationObject.state ? <li>{locationObject.state}</li> : null}
                {location.selectedDistrictID && locationObject.district ? (
                  <li>{locationObject.district}</li>
                ) : null}
              </ul>
              <ul>
                <li className="listHeading">Source</li>
                <li>{this._getWeatherSource(weatherSource)}</li>
              </ul>
              <ul className="bg-gray-heading">Graphical Analysis</ul>
            </div>
            <div className="dateRangePanel">
              <DateRangeClone
                getDate={this.getDate}
                clearDates={this.clearDates}
                showDefaultDate
              />
              <FormControl
                className="formControl inputHead coloredForms modeSec"
                style={{ float: 'right' }}
              >
                {weatherSource !== 'Chirps' ? (
                  <Select
                    name="dashboard-range"
                    inputProps={{ 'aria-label': 'age' }}
                    className="tableDropdown"
                    onChange={this._handledayChange}
                    value={timeSeries}
                  >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">7 Days Avg</MenuItem>
                    <MenuItem value="fortnight">15 Days Avg</MenuItem>
                  </Select>
                ) : (
                  <Select
                    name="dashboard-range"
                    inputProps={{ 'aria-label': 'age' }}
                    className="tableDropdown"
                    onChange={this._handledayChange}
                    value={timeSeries}
                  >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">7 Days Avg</MenuItem>
                    <MenuItem value="fortnight">15 Days Avg</MenuItem>
                    <MenuItem value="total7Days">7 Days Cum</MenuItem>
                    <MenuItem value="total14Days">15 Days Cum</MenuItem>
                  </Select>
                )}
              </FormControl>
            </div>
            {/* <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
              <table>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-column="First Name">Min/Max Temp(ºC)</td>
                    <td data-column="Last Name">Matman</td>
                    <td data-column="Job Title">Chief Sandwich Eater</td>
                    <td data-column="Twitter">@james</td>
                  </tr>
                  <tr>
                    <td data-column="First Name">40/28.30</td>
                    <td data-column="Last Name">Nagy</td>
                    <td data-column="Job Title">Designer</td>
                    <td data-column="Twitter">@andornagy</td>
                  </tr>
                  <tr>
                    <td data-column="First Name">Tamas</td>
                    <td data-column="Last Name">Biro</td>
                    <td data-column="Job Title">Game Tester</td>
                    <td data-column="Twitter">@tamas</td>
                  </tr>
                  <tr>
                    <td data-column="First Name">Zoli</td>
                    <td data-column="Last Name">Mastah</td>
                    <td data-column="Job Title">Developer</td>
                    <td data-column="Twitter">@zoli</td>
                  </tr>
                  <tr>
                    <td data-column="First Name">Szabi</td>
                    <td data-column="Last Name">Nagy</td>
                    <td data-column="Job Title">Chief Sandwich Eater</td>
                    <td data-column="Twitter">@szabi</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div> */}
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                {(weatherSource === 'NOAA' || weatherSource === 'IMD AWS')
                && dataError === '' ? (
                  <div className="panelDivision rechartsPanel">
                    <h4>Temperature</h4>
                    <VerticalLineChart
                      data={tempData}
                      yAxisLabel="Min/Max Temp(ºC)"
                      dataKey="category"
                      showToolTip
                    />
                    <ul className="legendMain">
                      <li>
                        <i style={{ backgroundColor: '#09b466' }} />
                        <span>Max Temp</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: '#0d21d8' }} />
                        <span>Min Temp</span>
                      </li>
                    </ul>
                  </div>
                  ) : null}
                {weatherSource === 'Chirps' && dataError === '' ? (
                  <div className="panelDivision rechartsPanel">
                    <h4>Rainfall (mm)</h4>
                    <VerticalLineChart
                      data={chirpData}
                      yAxisLabel="Rainfall (mm)"
                      dataKey="category"
                      showToolTip
                    />
                    <ul className="legendMain">
                      <li>
                        <i style={{ backgroundColor: '#09b466' }} />
                        <span>Rainfall (mm)</span>
                      </li>
                    </ul>
                  </div>
                ) : null}
                {weatherSource === 'GPM' && dataError === '' ? (
                  <div className="panelDivision rechartsPanel">
                    <h4>Rainfall (mm)</h4>
                    <VerticalLineChart
                      data={gpmHisData}
                      yAxisLabel="Rainfall (mm)"
                      dataKey="category"
                      showToolTip
                    />
                    <ul className="legendMain">
                      <li>
                        <i style={{ backgroundColor: '#09b466' }} />
                        <span>Rainfall (mm)</span>
                      </li>
                    </ul>
                  </div>
                ) : null}
                {weatherSource === 'IMD AWS' && dataError === '' ? (
                  <React.Fragment>
                    <div className="panelDivision rechartsPanel">
                      <h4>Humidity</h4>
                      <VerticalLineChart
                        data={humidityData}
                        yAxisLabel="Percentage"
                        dataKey="category"
                        showToolTip
                      />
                      <ul className="legendMain">
                        <li>
                          <i style={{ backgroundColor: '#09b466' }} />
                          <span>at 8 am</span>
                        </li>
                        <li>
                          <i style={{ backgroundColor: '#0d21d8' }} />
                          <span>at 17:30 pm</span>
                        </li>
                      </ul>
                    </div>
                    <div className="panelDivision rechartsPanel">
                      <h4>Rainfall</h4>
                      <VerticalLineChart
                        data={rainfallData}
                        yAxisLabel="Rainfall (mm)"
                        dataKey="category"
                        showToolTip
                      />
                      <ul className="legendMain">
                        <li>
                          <i style={{ backgroundColor: '#09b466' }} />
                          <span>Rainfall</span>
                        </li>
                      </ul>
                    </div>
                  </React.Fragment>
                ) : null}
                {dataError !== '' ? (
                  <div className="notfound">
                    <img
                      src="../images/notFound.svg"
                      alt="not-found"
                      title="not-found"
                    />
                    <h2>
                      Oops… Please try with different location hierarchy and
                      bounds
                    </h2>
                    <i className="material-icons">arrow_downward</i>
                    <a
                      href="/"
                      className="darkgreenBtn"
                      onClick={this._resetData}
                    >
                      Edit
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          ) : (
            <div className="agriIndices selectController btn">
              <h4 className="tabpanelHeading">Historical Report</h4>
              <Grid item xs={12}>
                <LocationHeirarchy
                  type="selectEmpty modalDropdown"
                  calledFrom="dashboard"
                  broadcastLocationChange={broadcastLocationChange}
                  hideTehsil
                  hideVillage
                />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <h4 className="tabpanelHeading">Source</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="formControl inputHead coloredForms inputFullWidth">
                    <Select
                      disableUnderline
                      name="weather-range"
                      className="selectEmpty modalDropdown"
                      inputProps={{ 'aria-label': 'user' }}
                      value={weatherSource}
                      displayEmpty
                      onChange={ev => this._handleWeatherSource(ev)}
                    >
                      <MenuItem value="IMD AWS">IMD AWS</MenuItem>
                      <MenuItem value="Chirps">CHIRPS</MenuItem>
                      <MenuItem value="NOAA">NOAA</MenuItem>
                      <MenuItem value="GPM">GPM</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-success floatRight"
                    onClick={this._generateData}
                  >
                  Generate Data
                    <i className="material-icons">arrow_right_alt</i>
                  </Button>
                  {error ? (
                    <p className="errorMsg">
                      {' '}
                      {error}
                      {' '}
                    </p>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ location, weather }) => ({
      location,
      imdHistoricalData: weather.imdHistoricalData,
      noaaData: weather.noaaData,
      chirpsData: weather.chirpsData,
      gpmData: weather.gpmData,
      dataError: weather.dataError,
    }),
    dispatch => ({
      // fetchImdHistoricalData: data => dispatch(getHistoricalImdData(data)),
      fetchNoaaHistoricalData: data => dispatch(getHistoricalNoaaData(data)),
      fetchNoaaHeatmap: data => dispatch(getNoaaHeatMap(data)),
      fetchChirpsHistoricalData: data => dispatch(getHistoricalChiprsData(data)),
      fetchChirpsHeatmap: data => dispatch(getChirpsHeatMap(data)),
      fetchGpmHistoricalData: data => dispatch(getHistoricalGpmData(data)),
      fetchGpmHeatmap: data => dispatch(getGpmHeatMap(data)),
      resetDetails: clearParam => dispatch(resetWeatherSourceDetails(clearParam)),
    }),
  )(withCookies(WeatherHistoricalTab)),
);
