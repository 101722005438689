import React from 'react';
import { Grid } from '@material-ui/core';
import ScrollableTabPanel from '../common/ScrollableTabPanel';
import SmsSetup from './smsSetup';
import SmsList from './smsList';
import Reports from '../scheduleReport/reports';
import '../../css/common.scss';
import '../../css/button.scss';

class SMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  broadcastTabChange = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleTabChange = () => {
    this.setState({
      activeTab: 0,
    });
  };

  handleCampaignTab = () => {
    this.setState({
      activeTab: 2,
    });
  };

  render() {
    const { activeTab } = this.state;
    // const { calledFrom } = this.props;
    const tabsData = [
      {
        tabName: 'SMS List',
        component: <SmsList anotherTab={this.handleCampaignTab} />,
      },
      {
        tabName: 'SMS Setup',
        component: <SmsSetup anotherTab={this.handleCampaignTab} />,
      },
      {
        tabName: 'SMS Report',
        component:
          activeTab === 2 ? (
            <Reports anotherTab={this.handleTabChange} type="sms" />
          ) : null,
      },
    ];
    return (
      <React.Fragment>
        <Grid container className="taggingMain removePadding" spacing={0}>
          <Grid item lg={12} md={12} sm={12} xs={12} className="ivrsWhiteBack">
            <ScrollableTabPanel
              tabsData={tabsData}
              customStyle="innerTabPanel"
              // customStyle="ivrsTabPanel"
              renderOnce
              activeTab={activeTab}
              broadcastTabChange={this.broadcastTabChange}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default SMS;
