import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox,
  TextField,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import { getIvrsList, playIvrs, resetDetails } from '../../Actions/ivrsAction';
import Schedule from './schedule';
import ModalHOC from '../common/Modal/index';
import Toastr from '../common/toastr';

const WAIT_INTERVAL = 1000;
const MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

function createData(name, description, createdAt, id) {
  return {
    name,
    description,
    createdAt,
    id,
  };
}

class IvrsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      mobile: '',
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      selectedId: 0,
      ivrsName: '',
      ivrsDesc: '',
      show: false,
      selectedIdForPlay: '',
      addExpandClass: false,
    };
  }

  componentDidMount() {
    this.fetchList();
    this.fetchList = _.debounce(this.fetchList, WAIT_INTERVAL);
  }

  componentDidUpdate(prevProps) {
    const { pingedIvrs } = this.props;
    if (pingedIvrs !== false && prevProps.pingedIvrs === false) {
      this.showMessageType(pingedIvrs);
    }
  }

  showMessageType = (status) => {
    const { resetIvrsDetails } = this.props;
    if (status === true) {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'IVRS Pinged Successfully',
        toastrMessageType: 'success',
        mobile: '',
      });
      resetIvrsDetails();
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'Something went wrong',
        toastrMessageType: 'error',
        mobile: '',
      });
      resetIvrsDetails();
    }
  };

  fetchList = () => {
    const { page, name } = this.state;
    const { fetchIvrsList } = this.props;
    const data = {
      filters: {
        advisoryType: 'IVRS',
        name: name || undefined,
      },
      offset: page * 10,
      limit: 10,
    };
    fetchIvrsList(data);
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => {
        this.fetchList();
      },
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  captureMobile = (e) => {
    const mobileRegex = /^[6-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(mobileRegex) && e.target.value !== '') {
      return;
    }
    this.setState({ mobile: e.target.value });
  };

  doAction = (e, ivrsId) => {
    this.setState({
      show: true,
      selectedIdForPlay: ivrsId,
    });
  };

  handleToastrClose = () => {
    this.setState({
      istoastrOpen: false,
    });
  };

  handleCheck = (id, name, desc) => {
    this.setState({
      selectedId: id,
      ivrsName: name,
      ivrsDesc: desc,
    });
  };

  handlePlay = () => {
    const { ivrsPlay } = this.props;
    const { mobile, selectedIdForPlay } = this.state;
    let check = true;
    let error = '';
    if (mobile.length < 10) {
      check = false;
      error = 'Please enter a valid mobile number';
    }
    if (!mobile) {
      check = false;
      error = 'Please enter a mobile number';
    }
    if (check) {
      const data = {
        ivrsId: selectedIdForPlay,
        mobile,
      };
      ivrsPlay(data);
      this.setState({
        error: '',
        show: false,
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handelChange = (e) => {
    this.setState(
      {
        name: e.target.value,
        page: 0,
      },
      () => {
        this.fetchList();
      },
    );
  };

  handelProceedClick = () => {
    const { ivrsName, ivrsDesc, selectedId } = this.state;
    const { proceedClick } = this.props;
    const data = {
      ivrsName,
      ivrsDesc,
      selectedId,
    };
    proceedClick(true, data, 'list');
  };

  handleClickSearch = () => {
    const { addExpandClass } = this.state;
    this.setState({
      addExpandClass: !addExpandClass,
      name: '',
    });
  };

  render() {
    const {
      rowsPerPage,
      page,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      mobile,
      selectedId,
      show,
      error,
      addExpandClass,
      ivrsName,
      ivrsDesc,
      name,
    } = this.state;
    const { ivrsList, handlePrevious, proceed } = this.props;
    const { rows, count } = ivrsList;
    const totalCount = Number(count);
    const tableRows = [];
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i += 1) {
        tableRows.push(
          createData(
            rows[i].advisoryName,
            rows[i].description,
            rows[i].createdAt,
            rows[i].id,
          ),
        );
      }
    }
    return (
      <React.Fragment>
        {proceed === false ? (
          <div className="ivrs-area">
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <h1>IVRS LIST</h1>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div
                  className={
                    addExpandClass
                      ? 'smallSearchPanel expandSearch'
                      : 'smallSearchPanel'
                  }
                >
                  <input
                    type="text"
                    id="autocomplete_search"
                    placeholder="Enter Search Name"
                    value={name}
                    onChange={this.handelChange}
                  />
                  <button
                    type="button"
                    className="smallSearchBtn"
                    onClick={this.handleClickSearch}
                  >
                    <i className="material-icons">search</i>
                  </button>
                </div>
                <button
                  type="button"
                  className="filter-btn floatRight"
                  onClick={this.toggleFilter}
                >
                  <i className="material-icons">filter_list</i>
                  {' '}
Filter
                </button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="insightTable tableWrapper">
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>CreatedAt</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows.map(row => (
                        <TableRow key={row.id} role="checkbox">
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={row.id === selectedId}
                              onClick={() => this.handleCheck(
                                row.id,
                                row.name,
                                row.description,
                              )
                              }
                            />
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>
                            {`${row.createdAt.split('T')[0].substring(8, 10)} ${
                              MONTHS[
                                row.createdAt.split('T')[0].substring(5, 7)
                              ]
                            } ${row.createdAt.split('T')[0].substring(0, 4)}`}
                          </TableCell>
                          <TableCell>
                            <Button
                              className="playBtn"
                              name="listen"
                              onClick={e => this.doAction(e, row.id)}
                            >
                              <i className="material-icons">
                                play_circle_filled
                              </i>
                              Play
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={totalCount || 10}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ivrsProceed"
                style={{ margin: '8px 0px 0px 0px' }}
              >
                <div className="darkgreenBtnHeader alignRight">
                  <Button
                    className={`${!selectedId ? 'disableBtn' : 'enableBtn'}`}
                    onClick={this.handelProceedClick}
                    disabled={!selectedId}
                  >
                    Select Farmers
                    <i className="material-icons">arrow_forward</i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Schedule
            title={ivrsName}
            desc={ivrsDesc}
            selectedId={selectedId}
            handlePrevious={handlePrevious}
          />
        )}
        <ModalHOC
          isOpen={show}
          onClose={this.handleClose}
          titleContent="Enter Mobile number"
          customContent="addMobileNo"
          footerComponent={(
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <Button
                  className="upload-btn-default"
                  onClick={this.handlePlay}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
)}
        >
          <TextField
            id="mobileNo"
            label="Mobile No."
            className="textField inputHead ivrscustomText-width"
            margin="normal"
            value={mobile}
            onChange={this.captureMobile}
            inputProps={{ maxLength: 10 }}
          />
          {error ? (
            <p className="errorMsg1">
              {' '}
              {error}
              {' '}
            </p>
          ) : null}
        </ModalHOC>
        <Toastr
          message={toastrMessage}
          show={istoastrOpen}
          handleClose={this.handleToastrClose}
          messageType={toastrMessageType}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ ivrs }) => ({
      ivrsList: ivrs.ivrsList,
      pingedIvrs: ivrs.pingedIvrs,
    }),
    dispatch => ({
      fetchIvrsList: data => dispatch(getIvrsList(data)),
      ivrsPlay: data => dispatch(playIvrs(data)),
      resetIvrsDetails: () => dispatch(resetDetails()),
    }),
  )(withCookies(IvrsList)),
);
