const initialState = {
  showDemoPopup: false,
  demoMarkerIndex: -1,
  surveyData: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-77.032, 38.913],
      },
      properties: {
        id: 0,
        title: 'Mapbox',
        description: 'Washington, D.C.',
        coordinates: [-77.032, 38.913],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-122.414, 37.776],
      },
      properties: {
        id: 1,
        title: 'Mapbox',
        description: 'San Francisco, California',
        coordinates: [-122.414, 37.776],
      },
    },
  ],
  level_2: [],
  level_3: [],
  ic: [],
  cluster: [],
  level_4: [],
  level_5: [],
  level_6: [],
  level_7: [],
  level_selected: '',
  selected_level_2: '',
  level2Name: '',
  selected_ic: '',
  ic_name: '',
  selected_cluster: '',
  cluster_name: '',
  selected_level_3: '',
  level3Name: '',
  selected_level_4: '',
  level4Name: '',
  selected_level_5: '',
  level5Name: '',
  selected_level_6: '',
  level6Name: '',
  selected_level_7: '',
  level7Name: '',
  surveyListData: [],
  productListData: [],
  surveyCropListData: [],
  agentListData: [],
  locationWiseCount: {
    chart: {},
    countDetails: {},
  },
  selectedSeason: '',
  selectedYear: '',
  selectedProductID: '',
  selectedProductName: '',
  selectedCropID: '',
  selectedCropName: '',
  selectedSurveyName: '',
  selectedSurveyID: '',
  surveyType: 'cce',
  downloadedData: [],
  uploadFileStatus: {},
  misDownloadReportData: {},
  experimentList: [],
  experimentListCount: 0,
  showSurveyPopup: false,
  surveyMarkerIndex: -1,
  experimentDetailLoading: false,
  experimentDetail: {},
  experimentDetailError: '',
  previewExperimentNumber: '',
  misReportListData: [],
  misReportSubmit: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_DEMO_SURVEY_POPUP':
      return {
        ...state,
        showDemoPopup: true,
        demoMarkerIndex: action.payload,
      };
    case 'CLOSE_DEMO_SURVEY_POPUP':
      return {
        ...state,
        showDemoPopup: false,
        demoMarkerIndex: -1,
      };
    case 'GET_SURVEY_LEVEL_2_SUCCESS':
      return {
        ...state,
        level_2: action.payload,
        level_3: [],
        cluster: [],
        selected_cluster: '',
        selected_level_2: '',
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_2_FAILURE':
      return {
        ...state,
        level_2: [],
        level_3: [],
        cluster: [],
        selected_level_2: '',
        selected_level_3: '',
        selected_cluster: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_2':
      return {
        ...state,
        level_selected: action.level,
        selected_level_2: action.payload,
        level2Name: action.levelName,
        selected_level_3: '',
        level_3: [],
      };
    case 'GET_SURVEY_IC_SUCCESS':
      return {
        ...state,
        ic: action.payload,
        selected_ic: '',
      };
    case 'GET_SURVEY_IC_FAILURE':
      return {
        ...state,
        ic: [],
        selected_ic: '',
        level_3: [],
        selected_level_3: '',
      };
    case 'SET_SURVEY_SELECTED_IC':
      return {
        ...state,
        selected_ic: action.payload,
        ic_name: action.levelName,
      };
    case 'GET_SURVEY_CLUSTER_SUCCESS':
      return {
        ...state,
        cluster: action.payload,
        selected_cluster: '',
        level_3: [],
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_CLUSTER_FAILURE':
      return {
        ...state,
        cluster: [],
        selected_cluster: '',
        level_3: [],
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_CLUSTER':
      return {
        ...state,
        level_selected: action.level,
        selected_cluster: action.payload,
        cluster_name: action.levelName,
      };
    case 'GET_SURVEY_CLUSTER_LEVEL_3_SUCCESS':
      return {
        ...state,
        level_3: action.payload,
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_CLUSTER_LEVEL_3_FAILURE':
      return {
        ...state,
        level_3: [],
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_3_SUCCESS':
      return {
        ...state,
        level_3: action.payload,
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_3_FAILURE':
      return {
        ...state,
        level_3: [],
        selected_level_3: '',
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_3':
      return {
        ...state,
        level_selected: action.level,
        selected_level_3: action.payload,
        level3Name: action.levelName,
        level_4: [],
        selected_level_4: '',
      };
    case 'GET_SURVEY_LEVEL_4_SUCCESS':
      return {
        ...state,
        level_4: action.payload,
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_4_FAILURE':
      return {
        ...state,
        level_4: [],
        selected_level_4: '',
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_4':
      return {
        ...state,
        level_selected: action.level,
        selected_level_4: action.payload,
        level4Name: action.levelName,
        level_5: [],
        selected_level_5: '',
      };
    case 'GET_SURVEY_LEVEL_5_SUCCESS':
      return {
        ...state,
        level_5: action.payload,
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_5_FAILURE':
      return {
        ...state,
        level_5: [],
        selected_level_5: '',
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_5':
      return {
        ...state,
        level_selected: action.level,
        selected_level_5: action.payload,
        level5Name: action.levelName,
        level_6: [],
        selected_level_6: '',
      };
    case 'GET_SURVEY_LEVEL_6_SUCCESS':
      return {
        ...state,
        level_6: action.payload,
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_6_FAILURE':
      return {
        ...state,
        level_selected: action.level,
        level_6: [],
        selected_level_6: '',
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_6':
      return {
        ...state,
        level_selected: action.level,
        selected_level_6: action.payload,
        level6Name: action.levelName,
        level_7: [],
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_7_SUCCESS':
      return {
        ...state,
        level_7: action.payload,
        selected_level_7: '',
      };
    case 'GET_SURVEY_LEVEL_7_FAILURE':
      return {
        ...state,
        level_7: [],
        selected_level_7: '',
      };
    case 'SET_SURVEY_SELECTED_LEVEL_7':
      return {
        ...state,
        level_selected: action.level,
        selected_level_7: action.payload,
        level7Name: action.levelName,
      };
    case 'SURVEY_LIST_SUCCESS':
      return {
        ...state,
        surveyListData: action.payload,
      };
    case 'SURVEY_LIST_FAILURE':
      return {
        ...state,
        surveyListData: [],
      };
    case 'PRODUCT_LIST_SUCCESS':
      return {
        ...state,
        productListData: action.payload,
      };
    case 'PRODUCT_LIST_FAILURE':
      return {
        ...state,
        productListData: [],
      };
    case 'SURVEY_CROP_LIST_SUCCESS':
      return {
        ...state,
        surveyCropListData: action.payload,
      };
    case 'SURVEY_CROP_LIST_FAILURE':
      return {
        ...state,
        surveyCropListData: [],
      };
    case 'AGENT_LIST_SUCCESS':
      return {
        ...state,
        agentListData: action.payload,
      };
    case 'AGENT_LIST_FAILURE':
      return {
        ...state,
        agentListData: [],
      };
    case 'SET_SURVEY_SEASON':
      return {
        ...state,
        selectedSeason: action.payload,
      };
    case 'SET_SURVEY_YEAR':
      return {
        ...state,
        selectedYear: action.payload,
      };
    case 'SET_SELECTED_SURVEY':
      return {
        ...state,
        selectedSurveyName: action.payload.surveyName,
        selectedSurveyID: action.payload.surveyID,
      };
    case 'SET_SURVEY_PRODUCT':
      return {
        ...state,
        selectedProductID: action.payload.productID,
        selectedProductName: action.payload.productName,
      };
    case 'SET_SURVEY_CROP':
      return {
        ...state,
        selectedCropID: action.payload.cropID,
        selectedCropName: action.payload.cropName,
      };
    case 'LOCATION_WISE_COUNT_SUCCESS':
      return {
        ...state,
        locationWiseCount: action.payload,
      };
    case 'LOCATION_WISE_COUNT_FAILURE':
      return {
        ...state,
        locationWiseCount: {
          chart: {},
          countDetails: {},
        },
      };
    case 'RESET_CCE_SURVEY_DATA':
      return {
        ...state,
        locationWiseCount: {
          chart: {},
          countDetails: {},
        },
        downloadedData: [],
        uploadFileStatus: {},
        misDownloadReportData: {},
        experimentList: [],
        experimentListCount: 0,
        previewExperimentNumber: '',
        experimentDetailLoading: false,
        experimentDetail: {},
        experimentDetailError: '',
        showSurveyPopup: false,
        surveyMarkerIndex: -1,
        misReportSubmit: {},
        misReportListData: [],
      };
    case 'FILE_CCE_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_CCE_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_CCE_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_CCE_SURVEY_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_CCE_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_CCE_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'SURVEY_EXPERIMENT_LIST_SUCCESS':
      return {
        ...state,
        experimentList: action.payload.data,
        experimentListCount: action.payload.count,
      };
    case 'CLEAR_SURVEY_EXPERIMENT_LIST':
    case 'SURVEY_EXPERIMENT_LIST_FAILURE':
      return {
        ...state,
        experimentList: [],
        experimentListCount: 0,
        showSurveyPopup: false,
        surveyMarkerIndex: -1,
      };
    case 'SHOW_SURVEY_POPUP':
      return {
        ...state,
        showSurveyPopup: true,
        surveyMarkerIndex: action.payload,
      };
    case 'CLOSE_SURVEY_POPUP':
      return {
        ...state,
        showSurveyPopup: false,
        surveyMarkerIndex: -1,
        experimentDetail: {},
        previewExperimentNumber: '',
      };
    case 'EXPERIMENT_DETAIL_FETCHING':
      return {
        ...state,
        previewExperimentNumber: action.payload,
        experimentDetailLoading: true,
        experimentDetail: {},
        experimentDetailError: '',
      };
    case 'EXPERIMENT_DETAIL_FETCH_SUCCESS':
      return {
        ...state,
        experimentDetailLoading: false,
        experimentDetail: action.payload,
        experimentDetailError: '',
      };
    case 'EXPERIMENT_DETAIL_FETCH_FAILURE':
      return {
        ...state,
        experimentDetailLoading: false,
        experimentDetail: {},
        experimentDetailError: action.payload,
      };
    case 'RESET_SURVEY_TAB':
      return {
        ...initialState,
      };
    case 'MIS_REPORT_LIST_SUCCESS':
      return {
        ...state,
        misReportListData: action.payload,
      };
    case 'MIS_REPORT_LIST_FAILURE':
      return {
        ...state,
        misReportListData: [],
      };
    case 'MIS_REPORT_SUBMIT_SUCCESS':
      return {
        ...state,
        misReportSubmit: action.payload,
      };
    case 'MIS_REPORT_SUBMIT_FAILURE':
      return {
        ...state,
        misReportSubmit: action.payload,
      };

    case 'FILE_CROP_VERIFICATION_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_CROP_VERIFICATION_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_CROP_VERIFICATION_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_CROP_VERIFICATION_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_CROP_VERIFICATION_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_CROP_VERIFICATION_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    case 'FILE_CROP_LOSS_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_CROP_LOSS_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_CROP_LOSS_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_CROP_LOSS_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_CROP_LOSS_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_CROP_LOSS_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    case 'FILE_SOURCING_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_SOURCING_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_SOURCING_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_SOURCING_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_SOURCING_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_SOURCING_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    case 'FILE_SOWING_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_SOWING_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_SOWING_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_SOWING_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_SOWING_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_SOWING_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    case 'FILE_WEATHER_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_WEATHER_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_WEATHER_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_WEATHER_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_WEATHER_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_WEATHER_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    case 'FILE_CROP_HEALTH_DOWNLOAD_SUCCESS':
      return {
        ...state,
        downloadedData: action.payload,
      };
    case 'FILE_CROP_HEALTH_DOWNLOAD_FAILURE':
      return {
        ...state,
        downloadedData: [],
      };
    case 'UPLOAD_CROP_HEALTH_SURVEY_FILE_SUCCESS':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'UPLOAD_CROP_HEALTH_FILE_FAILURE':
      return {
        ...state,
        uploadFileStatus: action.payload,
      };
    case 'MIS_CROP_HEALTH_REPORT_SUCCESS':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };
    case 'MIS_CROP_HEALTH_REPORT_FAILURE':
      return {
        ...state,
        misDownloadReportData: action.payload,
      };

    default:
      return state;
  }
};
