import FarmerSetup from '../farmerSetup/index';
import Dashboard from '../dashboard/index';
import Tagging from '../tagging/index';

const USER_ROLE = {
  FarmerUser: 'farmer',
  BankUser: 'Bank',
  InsuranceUser: 'Insurance',
  SeedCompanyUser: 'SeedCompany',
  ADMIN: 'ADMIN',
  USER: 'USER',
};

const FarmerUser = {
  Farmer: {
    ID: 'FARMER',
    COMPONENT: FarmerSetup,
    PATH: '/myaccount',
  },
};

const BankUser = {
  Bank: {
    ID: 'BANK',
    COMPONENT: Dashboard,
    PATH: '/dashboard',
  },
  Tagging: {
    ID: 'TAGGING',
    COMPONENT: Tagging,
    PATH: '/tagging',
  },
};

const InsuranceUser = {
  Insurance: {
    ID: 'INSURANCE',
    COMPONENT: Dashboard,
    PATH: '/dashboard',
  },
  Tagging: {
    ID: 'TAGGING',
    COMPONENT: Tagging,
    PATH: '/tagging',
  },
};

const ADMIN = {
  Insurance: {
    ID: 'INSURANCE',
    COMPONENT: Dashboard,
    PATH: '/dashboard',
  },
  Tagging: {
    ID: 'TAGGING',
    COMPONENT: Tagging,
    PATH: '/tagging',
  },
};

const USER = {
  Insurance: {
    ID: 'INSURANCE',
    COMPONENT: Dashboard,
    PATH: '/dashboard',
  },
  Tagging: {
    ID: 'TAGGING',
    COMPONENT: Tagging,
    PATH: '/tagging',
  },
};

const SeedCompanyUser = {
  SeedCompany: {
    ID: 'SEEDCOMPANY',
    COMPONENT: Tagging,
    PATH: '/tagging',
  },
};

const USER_ROLE_PAGE_ACCESS_MAPPING = {
  [USER_ROLE.FarmerUser]: [FarmerUser.Farmer],
  [USER_ROLE.BankUser]: [BankUser.Bank, BankUser.Tagging],
  [USER_ROLE.InsuranceUser]: [InsuranceUser.Insurance, InsuranceUser.Tagging],
  [USER_ROLE.SeedCompanyUser]: [SeedCompanyUser.SeedCompany],
  [USER_ROLE.ADMIN]: [ADMIN.Insurance, ADMIN.Tagging],
  [USER_ROLE.USER]: [USER.Insurance, USER.Tagging],
};

const getAllowedPagesBasedOnUserRole = userRole => USER_ROLE_PAGE_ACCESS_MAPPING[userRole];

export default getAllowedPagesBasedOnUserRole;
