import React from 'react';
import {
  compose, lifecycle, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import center from '@turf/center';
import bbox from '@turf/bbox';
import wkx from 'wkx';
import axios from 'axios';
import _, { debounce } from 'lodash';
import { config } from '../../config/index';
import {
  MapControl,
  StyleChangeControl,
  // NewStyleChangeControl,
  // ChatControl,
  SearchControl,
} from './controls';

import {
  getHierarchyDataAct,
  resetLocationAct,
  setLocationAct,
} from '../../Actions/locationHierarchyAction';
import {
  getLevelStatsAct,
  getCropsRegionWiseAct,
  getTopCropsAct,
  getNdviIndicesAct,
} from '../../Actions/landingPageAction';
import {
  updateBoundsAction,
  fetchIndicesImagesAct,
  clearIndicesImagesAct,
} from '../../Actions/agricultureIndicesAction';
import { addVectorLayer, findMissingBounds } from '../../Functions/helper';
import {
  CCEPopup,
  InsuredSurveyPopup,
  IndividualSurveyPopup,
  SowingSurveyPopup,
  WeatherStationPopup,
  ApplicationSurveyPopup,
  CropHealthPopup,
} from '../dashboard/survey/surveyPopups';
// import {} from '../../Actions/afterLoginHomeAction';

let Map;
let ReactMapboxGl;
let level1Hover;
let level0Hover;
let level2Hover;
let level3Hover;
let hoversegment;
let popup;
let segmentLayerId;
let PopUPTe;
// const adminLayers = [
//   'admin-0-boundary',
//   'admin-1-boundary',
//   'admin-0-boundary-disputed',
//   'admin-1-boundary-bg',
//   'admin-0-boundary-bg',
// ];

const MyMap = compose(
  withState('showMap', 'setMap', false),
  withProps({}),
  lifecycle({
    componentDidMount() {
      /* eslint-disable global-require */
      ReactMapboxGl = require('react-mapbox-gl');
      const { Popup: PopUPTest } = require('react-mapbox-gl');
      /* eslint-enable global-require */
      Map = ReactMapboxGl.Map({
        accessToken: config.mapboxConfig.publicToken,
        hash: true,
        minZoom: 1.5,
        maxZoom: 18,
        preserveDrawingBuffer: true,
        antialias: true,
      });
      this.setState({
        showMap: true,
      });
      PopUPTe = PopUPTest;
    },
  }),
)(props => (props.showMap ? (
  <Map
    attributionControl={false}
    className="mapController surveyMap"
    container="map"
    logoPosition="bottom-left"
    movingMethod="flyTo"
    onStyleLoad={props._onMapLoad}
    onClick={props._onMapClick}
    onDragEnd={props._onDragEnd}
    onZoomEnd={props._onZoomEnd}
    style={config.mapboxConfig.defaultStyle}
    center={props.center}
    zoom={props.zoom}
  >
    {/* For Demo */}
    {props.showDemoPopup && (
    <PopUPTe
      anchor="bottom"
      coordinates={
            props.surveyData[props.demoMarkerIndex].properties.coordinates
          }
          // offset={{
          //   'bottom-left': [12, -38],
          //   bottom: [0, -38],
          //   'bottom-right': [-12, -38],
          // }}
      style={{ backgroundColor: 'transparent' }}
    >
      {props.selectedSurveyName === 'CCE' && <CCEPopup />}
      {props.selectedSurveyName === 'Insured Crop Verification' && (
      <InsuredSurveyPopup />
      )}
      {props.selectedSurveyName === 'Individual Crop Loss Assessment' && (
      <IndividualSurveyPopup />
      )}
      {props.selectedSurveyName === 'Application Sourcing' && (
      <ApplicationSurveyPopup />
      )}
      {props.selectedSurveyName === 'Sowing Survey' && (
      <SowingSurveyPopup />
      )}
      {props.selectedSurveyName === 'Weather Station Inspection' && (
      <WeatherStationPopup />
      )}
      {props.selectedSurveyName === 'Crop Health Inspection' && (
      <CropHealthPopup />
      )}
    </PopUPTe>
    )}

    {/* Actual Data & Popup Mapping */}
    {props.showSurveyPopup && (
    <PopUPTe
      anchor="bottom"
      coordinates={[
        parseFloat(props.experimentList[props.surveyMarkerIndex].lng),
        parseFloat(props.experimentList[props.surveyMarkerIndex].lat),
      ]}
      style={{ backgroundColor: 'transparent' }}
    >
      {props.selectedSurveyName === 'CCE' && <CCEPopup />}
      {props.selectedSurveyName === 'Insured Crop Verification' && (
      <InsuredSurveyPopup />
      )}
      {props.selectedSurveyName === 'Individual Crop Loss Assessment' && (
      <IndividualSurveyPopup />
      )}
      {props.selectedSurveyName === 'Application Sourcing' && (
      <ApplicationSurveyPopup />
      )}
      {props.selectedSurveyName === 'Sowing Survey' && (
      <SowingSurveyPopup />
      )}
      {props.selectedSurveyName === 'Weather Station Inspection' && (
      <WeatherStationPopup />
      )}
      {props.selectedSurveyName === 'Crop Health Inspection' && (
      <CropHealthPopup />
      )}
    </PopUPTe>
    )}
  </Map>
) : (
  <div>Loading Map</div>
)));

class FarmEngineMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPlace: '',
      isCropMapShown: false,
      center: config.mapboxConfig.defaultCenter,
      zoom: [config.mapboxConfig.defaultZoom],
      segmentedView: true,
      currentStyle: config.mapboxConfig.defaultStyle,
    };
  }

  componentDidMount() {
    this.searchAddress = debounce(this.searchAddress, 200);
    console.log('test jenkins');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedYear,
      selectedSeason,
      // indicesImages,
      // selectedTimelineDate,
      // indexToView,
      // calledFrom,
      // selectedTab,
      // satellitesSource,
      // bounds,
    } = this.props;
    const { isCropMapShown, pixelValue, pixelCoord } = this.state;
    if (prevProps.selectedYear !== selectedYear) {
      const map = this.mapContainer;
      if (map) {
        config.segmentsProps.forEach((item) => {
          if (item.year) {
            if (item.year === prevProps.selectedYear) {
              if (map.getSource(item.sourceId)) {
                map.removeLayer(item.layerId);
                map.removeLayer(`${item.sourceId}Line`);
                map.removeSource(item.sourceId);
              }
            }
            if (item.year === selectedYear) {
              let fillColor;
              if (isCropMapShown) {
                fillColor = [
                  'match',
                  ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
                  [0],
                  config.crops['0'].cropColor,
                  [1],
                  config.crops['1'].cropColor,
                  [2],
                  config.crops['2'].cropColor,
                  [3],
                  config.crops['3'].cropColor,
                  [4],
                  config.crops['4'].cropColor,
                  [5],
                  config.crops['5'].cropColor,
                  [6],
                  config.crops['6'].cropColor,
                  [7],
                  config.crops['7'].cropColor,
                  [8],
                  config.crops['8'].cropColor,
                  '#7ec850',
                ];
              } else {
                fillColor = 'rgba(0,0,0,0)';
              }
              const segmentsParam = {
                mapRef: map,
                tileSetURL: item.tilesetURL,
                withLineLayer: true,
                sourceLayer: item.sourceLayer,
                sourceId: item.sourceId,
                fillLayerId: item.layerId,
                lineLayerId: `${item.sourceId}Line`,
                fillZoomExtent: config.segmentCommonProps.fillZoomExtent,
                fillLayout: {
                  visibility: 'visible',
                },
                fillPaint: {
                  'fill-color': fillColor,
                  'fill-opacity': 1,
                  'fill-outline-color': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    14.5,
                    'rgba(0,0,0,0)',
                    15,
                    isCropMapShown ? '#000' : '#ffff00',
                  ],
                },
                fillmouseMoveFunc: this.genericSegmentMouseMove,
                fillmouseLeaveFunc: this.genericSegmentMouseLeave,
                fillclickFunc: this.genericSegmentMouseClick,
                lineZoomExtent: config.segmentCommonProps.lineZoomExtent,
                linePaint: {
                  'line-color': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    isCropMapShown ? '#000' : '#ffff00',
                    isCropMapShown ? 'rgba(0,0,0,0)' : '#ffff00',
                  ],
                  'line-width': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    isCropMapShown ? 2 : 3.5,
                    isCropMapShown ? 0 : 2,
                  ],
                },
                lineLayout: {
                  visibility: 'visible',
                },
                beforeLayerId: 'level3Fill',
              };
              addVectorLayer(segmentsParam);
            }
          }
        });
      }
    }

    if (prevProps.selectedSeason !== selectedSeason) {
      // Update Fill Color Properties;
      let fillPaintProperty;
      if (isCropMapShown) {
        fillPaintProperty = [
          'match',
          ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
          [0],
          config.crops['0'].cropColor,
          [1],
          config.crops['1'].cropColor,
          [2],
          config.crops['2'].cropColor,
          [3],
          config.crops['3'].cropColor,
          [4],
          config.crops['4'].cropColor,
          [5],
          config.crops['5'].cropColor,
          [6],
          config.crops['6'].cropColor,
          [7],
          config.crops['7'].cropColor,
          [8],
          config.crops['8'].cropColor,
          '#7ec850',
        ];
      } else {
        fillPaintProperty = 'rgba(0,0,0,0)';
      }

      const map = this.mapContainer;
      if (map) {
        config.segmentsProps.forEach((item) => {
          if (item.layerId !== 'ganganagarsegments') {
            if (map.getSource(item.sourceId)) {
              map.setPaintProperty(
                item.layerId,
                'fill-color',
                fillPaintProperty,
              );
            }
          }
        });
      }
    }

    /* if (this.mapContainer.getLayer('indices-tiles')) {
     this.mapContainer.removeLayer('indices-tiles');
     this.mapContainer.removeSource('indices-tiles');
     this.mapContainer.addLayer({
     id: 'indices-tiles',
     type: 'raster',
     source: {
     type: 'raster',
     scheme: 'xyz',
     tiles: [
     `https://kr9naia1o3.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server/{z}/{x}/{y}?index=${indexToView}&cmap=green&min=-1&max=1&tilesize=256&scale=2&satellite=${satellitesSource}&date=${firstObj.date}`,
     ],
     bounds,
     },
     paint: {},
     });
     } else {
     this.mapContainer.addLayer({
     id: 'indices-tiles',
     type: 'raster',
     source: {
     type: 'raster',
     scheme: 'xyz',
     tiles: [
     `https://kr9naia1o3.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server/{z}/{x}/{y}?index=${indexToView}&cmap=green&min=-1&max=1&tilesize=256&scale=2&satellite=${satellitesSource}&date=${firstObj.date}`,
     ],
     bounds,
     },
     paint: {},
     }, 'level3Fill');
     } */
    if (
      prevState.pixelValue !== pixelValue
      && pixelValue !== undefined
      && pixelCoord
    ) {
      const map = this.mapContainer;
      popup
        .setLngLat(pixelCoord)
        .setHTML(`<h2>${pixelValue}</h2>`)
        .addTo(map);
    } else if (popup) {
      popup.remove();
    }
    if (prevState.pixelValue !== undefined && pixelValue === undefined) {
      if (popup) {
        popup.remove();
      }
    }
  }

  componentWillUnmount() {
    const { resetLocationDetails } = this.props;
    resetLocationDetails();
    window.location.hash = '';
  }

  _onMapLoad = (map, event) => {
    console.log('Event ', event);
    if (window.location.hash === '') {
      this.setState({
        center: undefined,
        zoom: undefined,
      });
      map.setZoom(config.mapboxConfig.defaultZoom);
      map.setCenter(config.mapboxConfig.defaultCenter);
    }

    const {
      styleParams,
      compare,
      broadcastMapLoad,
      calledFrom,
      selectedTab,
    } = this.props;
    this.mapContainer = map;
    const firstload = window.localStorage.getItem('firstload');
    // adminLayers.forEach((adminLayer) => {
    //   map.setFilter(adminLayer, [
    //     'match',
    //     ['get', 'worldview'],
    //     ['all', 'IN'],
    //     true,
    //     false,
    //   ]);
    // });

    if (!firstload) {
      if (window.navigator.geolocation) {
        // const self = this;
        window.navigator.geolocation.getCurrentPosition((position) => {
          console.log('Geolocation ', position);
          // self.props.showLevelWiseReports({
          //   longitude: position.coords.longitude,
          //   latitude: position.coords.latitude,
          // });
          // map.flyTo({
          //   center: [position.coords.longitude, position.coords.latitude],
          //   zoom: 17,
          //   bearing: 0,
          //   speed: 1, // make the flying slow
          //   curve: 1, // change the speed at which it zooms out
          //   easing(t) {
          //     return t;
          //   },
          // });
        });
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
      window.localStorage.setItem('firstload', true);
    }

    map.addLayer({
      id: 'google-satellite-layer',
      type: 'raster',
      source: {
        type: 'raster',
        scheme: 'xyz',
        tiles: [
          'https://mt0.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt1.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt2.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
          'https://mt3.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
        ],
      },
      paint: {
        'raster-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12,
          0,
          14,
          0.5,
          16,
          1,
          22,
          1,
        ],
        'raster-fade-duration': 300,
        'raster-saturation': -0.75,
        'raster-contrast': 0,
        'raster-brightness-min': 0,
        'raster-brightness-max': 1,
      },
    });

    /**
     * Add Admin Boundaries Here Level Wise
     * 1. Country
     * 2. States
     * 3. District
     * 4. Tehsils
     * 5. Villages
     * */

    this.addLevel0(map);

    this.addLevel1(map);

    this.addLevel2(map);

    this.addLevel3(map);

    // this.addLevel4(map);

    if (styleParams) {
      if (styleParams.segmentedView) {
        this.addGenericSegments(map);
      }
    } else {
      this.addGenericSegments(map);
    }

    /* WMS Layer Here */

    /* map.addLayer({
     'id': 'wms-test-layer',
     'type': 'raster',
     'source': {
     'type': 'raster',
     'scheme': 'xyz',
     'tiles': [
     'http://35.170.67.119:4000/api/v1/index/{z}/{x}/{y}?url=https://dwa0i5u31qk1r.cloudfront.net/sentinel2/43/R/EL/2019/2/4/0/&tilesize=256&scale=2&index=ndvi&cmap=green'
     // 'https://px7dducuh9.execute-api.us-east-1.amazonaws.com/dev/api/v1/tiles/18/186114/115406.png?url=https://s3.amazonaws.com/indshine-felix/10561/10562/DSM.tif&numband=1'
     ],
     'tileSize': 256,
     // "bounds":[75.56478764857289,21.012575026155417,75.60243183474189,21.032404942402227]
     },
     'paint': {}
     }); */

    map.addLayer({
      id: 'background',
      type: 'background',
      paint: {
        'background-color': '#000000',
        'background-opacity': 0,
      },
    });

    /* eslint-disable global-require */
    const mapboxgl = require('mapbox-gl');
    /* eslint-enable global-require */
    popup = new mapboxgl.Popup();

    map.on('style.load', () => {
      const waiting = () => {
        if (!map.isStyleLoaded()) {
          // console.log('Not Loaded');
          setTimeout(waiting, 200);
        } else {
          const { isCropMapShown, segmentedView, currentStyle } = this.state;
          if (
            !isCropMapShown
            && currentStyle === config.mapboxConfig.blankStyle
          ) {
            map.addLayer({
              id: 'wms-test-layer',
              type: 'raster',
              source: {
                type: 'raster',
                scheme: 'xyz',
                // tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
                tiles: [
                  'https://mt0.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
                  'https://mt1.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
                  'https://mt2.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
                  'https://mt3.google.com/vt/lyrs=s,h&hl=en&x={x}&y={y}&z={z}',
                ],
                // tiles: ['http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
                // tiles: ['http://imagico.de/map/osmim_tiles.php?z={z}&x={x}&y=-{y}'],
                // tiles: ['http://b.tiles.mapbox.com/v3/openstreetmap.map-4wvf9l0l/{z}/{x}/{y}.png'],
              },
              paint: {
                'raster-opacity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  12,
                  0,
                  14,
                  0.5,
                  16,
                  1,
                  22,
                  1,
                ],
                'raster-fade-duration': 300,
                'raster-saturation': -0.75,
                'raster-contrast': 0,
                'raster-brightness-min': 0,
                'raster-brightness-max': 1,
              },
            });

            /* map.addLayer({
              id: 'wms',
              type: 'raster',
              minzoom: 6,
              source: {
                type: 'raster',
                scheme: 'xyz',
                // tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
                tiles: [
                  'https://kr9naia1o3.execute-api.ap-south-1.amazonaws.com/dev/api/v1/tile-server/{z}/{x}/{y}?index=ndvi&tilesize=256&scale=2&satellite=s2&date=2019-02-02',
                ],
                // tiles: ['http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
                // tiles: ['http://imagico.de/map/osmim_tiles.php?z={z}&x={x}&y=-{y}'],
                // tiles: ['http://b.tiles.mapbox.com/v3/openstreetmap.map-4wvf9l0l/{z}/{x}/{y}.png'],
              },
              paint: {},
            }); */
          }
          this.addLevel0(map);

          this.addLevel1(map);

          this.addLevel2(map);

          this.addLevel3(map);

          // this.addLevel4(map);

          if (segmentedView) {
            this.addGenericSegments(map);
          }

          if (!map.getLayer('background')) {
            map.addLayer({
              id: 'background',
              type: 'background',
              paint: {
                'background-color': '#000000',
                'background-opacity': 0,
              },
            });
          }
        }
      };
      waiting();
    });

    if (styleParams) {
      this.changeMapStyle(styleParams);
    }

    if (calledFrom === 'dashboard' && selectedTab === 0 && !compare) {
      broadcastMapLoad(map);
    }

    /* map.addLayer({
     'id': 'test-indices',
     'type': 'raster',
     'source': {
     'type': 'raster',
     'scheme': 'xyz',
     'tiles': [
     'http://192.168.100.164:4000/api/v1/tile-server/{z}/{x}/{y}?index=ndvi&cmap=jet&min=-1&max=1&tilesize=256&scale=2&satellite=s2&date=2019-02-02',
     // 'https://px7dducuh9.execute-api.us-east-1.amazonaws.com/dev/api/v1/tiles/18/186114/115406.png?url=https://s3.amazonaws.com/indshine-felix/10561/10562/DSM.tif&numband=1'
     ],
     'tileSize': 256,
     // "bounds":[75.56478764857289,21.012575026155417,75.60243183474189,21.032404942402227]
     },
     'paint': {}
     }, 'level3Line'); */

    // if (compare && broadcastMapLoad) {
    //   broadcastMapLoad(map);
    // }
  };

  _onMapClick = (map, evt) => {
    const layers = [];
    let features = [];
    const {
      updateBounds,
      satellitesSource,
      indicesValue,
      startDate,
      endDate,
      bounds,
      // fetchIndicesImages,
      // selectedTab,
    } = this.props;
    config.segmentsProps.forEach((item) => {
      if (map.getLayer(item.layerId)) {
        layers.push(item.layerId);
      }
    });
    features = map.queryRenderedFeatures(evt.point, {
      layers,
    });
    if (features.length === 0) {
      map.setPaintProperty('background', 'background-opacity', 0);
      updateBounds(
        bounds,
        indicesValue,
        satellitesSource,
        startDate,
        endDate,
        '',
      );
      if (map.getLayer('clickedFarm')) {
        const { closeFarmCard, calledFrom } = this.props;
        map.removeLayer('clickedFarm');
        map.removeLayer('clickedFarmLine');
        map.removeSource('clickedFarm');
        if (calledFrom === 'farmEngine') closeFarmCard();
        // comingFrom === 'farmEngine' && closeFarmCard();
      }

      if (map.getLayer('overlay')) {
        map.removeLayer('overlay');
        map.removeSource('imageSource');
      }
    }
  };

  _onZoomEnd = () => {
    // console.log('Map Container ', map);
    // this.handleMapMovement(map);
  };

  _onDragEnd = (map) => {
    this.handleMapMovement(map);
  };

  handleZoomChange = (type) => {
    if (this.mapContainer) {
      const map = this.mapContainer;
      switch (type) {
        case 'plus':
          map.zoomIn();
          break;

        case 'minus':
          map.zoomOut();
          break;

        default:
          break;
      }
    }
  };

  geolocateUser = () => {
    // console.log('Window  ', window.navigator.geolocation);
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        if (this.mapContainer) {
          const map = this.mapContainer;
          map.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 17,
            bearing: 0,
            speed: 1, // make the flying slow
          });
        }
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  changeMapStyle = (styleParams) => {
    /**
     * styleParams : Type Object
     * styleParams = { styleURL: "", "cropMapView", "segmentedView"  }
     * */
    if (this.mapContainer) {
      const map = this.mapContainer;
      const { broadcastMapStyleChange } = this.props;
      if (map) {
        const { currentStyle, isCropMapShown, segmentedView } = this.state;
        const oldStyleParams = {
          styleURL: currentStyle,
          segmentedView,
          cropMapView: isCropMapShown,
        };
        if (!_.isEqual(styleParams, oldStyleParams)) {
          if (styleParams.segmentedView) {
            if (styleParams.cropMapView) {
              this.addGenericSegments(map, styleParams.cropMapView);
            } else {
              this.addGenericSegments(map);
            }
          } else {
            this.removeGenericSegments(map);
          }
          /* if (currentStyle === styleParams.styleURL) {
           if (styleParams.segmentedView) {
           if (styleParams.cropMapView) {
           this.addSegments(map, styleParams.cropMapView);
           } else {
           this.addSegments(map);
           }
           } else {
           this.removeSegments(map);
           }
           } else {
           map.setStyle(styleParams.styleURL, { diff: true });
           } */
          this.setState({
            isCropMapShown: styleParams.cropMapView,
            segmentedView: styleParams.segmentedView,
            currentStyle: styleParams.styleURL,
          });
          if (broadcastMapStyleChange) {
            broadcastMapStyleChange(styleParams);
          }
        }
      }
    }
  };

  handlePlaceChange = (e) => {
    const self = this;
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete_search'),
      {
        types: ['geocode'],
      },
    );

    /**
     * fire event when a suggetsed place is selected
     * */

    autocomplete.addListener('place_changed', () => {
      // const place = autocomplete.getPlace();
      // console.log('place', place);
      self.searchAddress(document.getElementById('autocomplete_search').value);
    });
    this.setState({
      searchPlace: e.target.value,
    });
  };

  searchAddress = async (address) => {
    if (address) {
      this.setState({
        searchPlace: address,
      });
    }
    const response = await axios(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBFKufGnFZrQZeCPLpt1ALrzUlVETWBI_I`,
    );
    // console.log('response>>>>', response);
    if (response.status) {
      const data = response.data.results[0].geometry.location;
      if (this.mapContainer) {
        const map = this.mapContainer;
        if (map) {
          map.flyTo({ center: [data.lng, data.lat], zoom: 12 });
          this.setState({
            searchPlace: '',
            addExpandClass: false,
          });
        }
      }
    }
  };

  handleClickSearch = () => {
    this.setState({
      addExpandClass: true,
    });
  };

  addLevel0 = (map) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.country.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
      sourceId: 'level0',
      fillLayerId: 'level0Fill',
      lineLayerId: 'level0Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.country.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc: this.level0MouseMoveHandler,
      fillmouseLeaveFunc: this.level0MouseLeaveHandler,
      fillclickFunc: this.level0ClickHandler,
      lineZoomExtent: config.mapboxConfig.adminLayers.country.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel1 = (map) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.state.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
      sourceId: 'level1',
      fillLayerId: 'level1Fill',
      lineLayerId: 'level1Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.state.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc: this.level1MouseMoveHandler,
      fillmouseLeaveFunc: this.level1MouseLeaveHandler,
      fillclickFunc: this.level1ClickHandler,
      lineZoomExtent: config.mapboxConfig.adminLayers.state.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel2 = (map) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.district.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
      sourceId: 'level2',
      fillLayerId: 'level2Fill',
      lineLayerId: 'level2Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.district.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc: this.level2MouseMoveHandler,
      fillmouseLeaveFunc: this.level2MouseLeaveHandler,
      fillclickFunc: this.level2ClickHandler,
      lineZoomExtent: config.mapboxConfig.adminLayers.district.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  addLevel3 = (map) => {
    const layerParams = {
      mapRef: map,
      tileSetURL: config.mapboxConfig.adminLayers.tehsil.url,
      withLineLayer: true,
      sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
      sourceId: 'level3',
      fillLayerId: 'level3Fill',
      lineLayerId: 'level3Line',
      fillZoomExtent: config.mapboxConfig.adminLayers.tehsil.zoomRange,
      fillLayout: {
        visibility: 'visible',
      },
      fillPaint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
      fillmouseMoveFunc: this.level3MouseMoveHandler,
      fillmouseLeaveFunc: this.level3MouseLeaveHandler,
      fillclickFunc: this.level3ClickHandler,
      lineZoomExtent: config.mapboxConfig.adminLayers.tehsil.zoomRange,
      linePaint: {
        'line-color': '#fff',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          1,
        ],
      },
      lineLayout: {
        visibility: 'visible',
      },
    };
    addVectorLayer(layerParams);
  };

  /* addLevel4 = (map) => {
   const {
   getLevelStats,
   selectedCropRankingParam,
   setLocation,
   getHierarchyData,
   } = this.props;
   map.addSource('level4', {
   type: 'vector',
   url: config.mapboxConfig.adminLayers.village.url,
   });

   map.addLayer({
   id: 'level4Fill',
   type: 'fill',
   source: 'level4',
   'source-layer': config.mapboxConfig.adminLayers.village.sourceLayer,
   minzoom: config.mapboxConfig.adminLayers.village.zoomRange[0],
   maxzoom: config.mapboxConfig.adminLayers.village.zoomRange[1],
   layout: {
   visibility: 'visible',
   },
   paint: {
   'fill-color': '#fff',
   'fill-opacity': 0,
   },
   });

   map.addLayer({
   id: 'level4Line',
   type: 'line',
   source: 'level4',
   'source-layer': config.mapboxConfig.adminLayers.village.sourceLayer,
   minzoom: config.mapboxConfig.adminLayers.village.zoomRange[0],
   maxzoom: config.mapboxConfig.adminLayers.village.zoomRange[1],
   layout: {
   visibility: 'visible',
   },
   paint: {
   'line-color': '#fff',
   'line-width': [
   'case',
   ['boolean', ['feature-state', 'hover'], false],
   2,
   1,
   ],
   },
   });

   let level4Hover;
   map.on('mousemove', 'level4Fill', (e) => {
   if (e.features.length) {
   this.setState({
   levelOnHover: e.features[0].properties.village_name,
   });

   if (level4Hover) {
   map.setFeatureState(
   {
   source: 'level4',
   sourceLayer: config.mapboxConfig.adminLayers.village.sourceLayer,
   id: level4Hover,
   },
   {
   hover: false,
   },
   );
   }
   level4Hover = e.features[0].id;
   // set the hover attribute to true with feature state
   map.setFeatureState(
   {
   source: 'level4',
   sourceLayer: config.mapboxConfig.adminLayers.village.sourceLayer,
   id: level4Hover,
   },
   {
   hover: true,
   },
   );
   }
   });

   map.on('mouseleave', 'level4Fill', () => {
   this.setState({
   levelOnHover: undefined,
   });
   if (level4Hover) {
   map.setFeatureState(
   {
   source: 'level4',
   sourceLayer: config.mapboxConfig.adminLayers.village.sourceLayer,
   id: level4Hover,
   },
   {
   hover: false,
   },
   );
   }
   });

   map.on('click', 'level4Fill', (e) => {
   // console.log('Village Properties >>>>>>>>', e.features[0].properties);
   const {
   selectedSeason,
   selectedYear,
   selectedStateID,
   selectedDistrictID,
   selectedTehsilID,
   } = this.props;
   const geom = e.features[0].geometry;
   const featureGeoJSON = {
   type: 'FeatureCollection',
   features: [
   {
   type: 'Feature',
   geometry: geom,
   properties: {},
   },
   ],
   };

   const bounds = bbox(featureGeoJSON);
   const boundsCenter = center(featureGeoJSON);
   map.fitBounds(bounds, { padding: 20 });
   getLevelStats({
   fetchLevel: 'VILLAGE',
   stateID: e.features[0].properties.state_id,
   districtID: e.features[0].properties.district_id,
   villageID: e.features[0].properties.village_id,
   year: selectedYear,
   season: selectedSeason,
   cropRankingParam: selectedCropRankingParam,
   });
   if (e.features[0].properties.state_id !== selectedStateID) {
   getHierarchyData('DISTRICT', e.features[0].properties.state_id);
   setLocation('STATE', e.features[0].properties.state_id);
   }
   if (e.features[0].properties.district_id !== selectedDistrictID) {
   getHierarchyData('TEHSIL', e.features[0].properties.district_id);
   setLocation('DISTRICT', e.features[0].properties.district_id);
   }
   if (e.features[0].properties.tehsil_id !== selectedTehsilID) {
   getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
   setLocation('TEHSIL', e.features[0].properties.tehsil_id);
   }
   setLocation('VILLAGE', e.features[0].properties.village_id);
   });
   }; */

  addGenericSegments = (map, cropMapView) => {
    const { selectedSeason, selectedYear } = this.props;
    let { isCropMapShown } = this.state;
    isCropMapShown = cropMapView;
    let fillColor = [];

    config.segmentsProps.forEach((item) => {
      if (item.layerId === 'ganganagarsegments') {
        if (isCropMapShown) {
          fillColor = [
            'match',
            ['get', 'MAJORITY'],
            [0],
            config.crops['0'].cropColor,
            [1],
            config.crops['1'].cropColor,
            [2],
            config.crops['2'].cropColor,
            [3],
            config.crops['3'].cropColor,
            [4],
            config.crops['4'].cropColor,
            [5],
            config.crops['5'].cropColor,
            [6],
            config.crops['6'].cropColor,
            [7],
            config.crops['7'].cropColor,
            [8],
            config.crops['8'].cropColor,
            '#7ec850',
          ];
        } else {
          fillColor = 'rgba(0,0,0,0)';
        }
      } else if (isCropMapShown) {
        fillColor = [
          'match',
          ['get', selectedSeason === 1 ? 'Kharif' : 'Rabi'],
          [0],
          config.crops['0'].cropColor,
          [1],
          config.crops['1'].cropColor,
          [2],
          config.crops['2'].cropColor,
          [3],
          config.crops['3'].cropColor,
          [4],
          config.crops['4'].cropColor,
          [5],
          config.crops['5'].cropColor,
          [6],
          config.crops['6'].cropColor,
          [7],
          config.crops['7'].cropColor,
          [8],
          config.crops['8'].cropColor,
          '#7ec850',
        ];
      } else {
        fillColor = 'rgba(0,0,0,0)';
      }

      if (item.year) {
        if (selectedYear === item.year) {
          const segmentParams = {
            mapRef: map,
            tileSetURL: item.tilesetURL,
            withLineLayer: true,
            sourceLayer: item.sourceLayer,
            sourceId: item.sourceId,
            fillLayerId: item.layerId,
            lineLayerId: `${item.layerId}Line`,
            fillZoomExtent: config.segmentCommonProps.fillZoomExtent,
            fillLayout: {
              visibility: 'visible',
            },
            fillPaint: {
              'fill-color': fillColor,
              'fill-opacity': 1,
              'fill-outline-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14.5,
                'rgba(0,0,0,0)',
                15,
                isCropMapShown ? '#000000' : '#ffff00',
              ],
            },
            fillmouseMoveFunc: this.genericSegmentMouseMove,
            fillmouseLeaveFunc: this.genericSegmentMouseLeave,
            fillclickFunc: this.genericSegmentMouseClick,
            lineZoomExtent: config.segmentCommonProps.lineZoomExtent,
            linePaint: {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                isCropMapShown ? '#000' : '#ffff00',
                isCropMapShown ? 'rgba(0,0,0,0)' : '#ffff00',
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                isCropMapShown ? 2 : 3.5,
                isCropMapShown ? 0 : 2,
              ],
            },
            lineLayout: {
              visibility: 'visible',
            },
          };
          addVectorLayer(segmentParams);
        }
      } else {
        const segmentParams = {
          mapRef: map,
          tileSetURL: item.tilesetURL,
          withLineLayer: true,
          sourceLayer: item.sourceLayer,
          sourceId: item.sourceId,
          fillLayerId: item.layerId,
          lineLayerId: `${item.layerId}Line`,
          fillZoomExtent: config.segmentCommonProps.fillZoomExtent,
          fillLayout: {
            visibility: 'visible',
          },
          fillPaint: {
            'fill-color': fillColor,
            'fill-opacity': 1,
            'fill-outline-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              14.5,
              'rgba(0,0,0,0)',
              15,
              isCropMapShown ? '#000000' : '#ffff00',
            ],
          },
          fillmouseMoveFunc: this.genericSegmentMouseMove,
          fillmouseLeaveFunc: this.genericSegmentMouseLeave,
          fillclickFunc: this.genericSegmentMouseClick,
          lineZoomExtent: config.segmentCommonProps.lineZoomExtent,
          linePaint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              isCropMapShown ? '#000' : '#ffff00',
              isCropMapShown ? 'rgba(0,0,0,0)' : '#ffff00',
            ],
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              isCropMapShown ? 2 : 3.5,
              isCropMapShown ? 0 : 2,
            ],
          },
          lineLayout: {
            visibility: 'visible',
          },
        };
        addVectorLayer(segmentParams);
      }
    });

    if (map.getLayer('background')) {
      map.moveLayer('background');
    }
  };

  removeGenericSegments = (map) => {
    config.segmentsProps.forEach((item) => {
      if (map.getSource(item.sourceId)) {
        map.removeLayer(`${item.sourceId}Line`);
        map.removeLayer(item.layerId);
        map.removeSource(item.sourceId);
      }
    });
  };

  handleMapMovement = (map) => {
    const {
      setLocation,
      getHierarchyData,
      states,
      districts,
      tehsils,
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
      getLevelStats,
      getCropsRegionWise,
      getTopCrops,
      selectedCropRankingParam,
      selectedSeason,
      selectedYear,
    } = this.props;
    if (this.mapContainer) {
      const mapCenter = map.getCenter();
      const point = map.project(mapCenter);
      const zoom = map.getZoom();
      let renderedFeatures = [];
      if (
        zoom >= config.mapboxConfig.adminLayers.country.zoomRange[0]
        && zoom < config.mapboxConfig.adminLayers.country.zoomRange[1]
      ) {
        // Search Country Features Here
        renderedFeatures = this.mapContainer.queryRenderedFeatures(point, {
          layers: ['level0Fill'],
        });
        if (renderedFeatures.length > 0) {
          // this.setState({
          //   level0Name: renderedFeatures[0].properties.country_name,
          //   level1Name: undefined,
          //   level2Name: undefined,
          // });
        }
      } else if (
        zoom >= config.mapboxConfig.adminLayers.state.zoomRange[0]
        && zoom < config.mapboxConfig.adminLayers.state.zoomRange[1]
      ) {
        // Search State Features Here
        renderedFeatures = this.mapContainer.queryRenderedFeatures(point, {
          layers: ['level1Fill'],
        });
        if (renderedFeatures.length > 0) {
          setLocation('STATE', renderedFeatures[0].properties.state_id);
          if (states.length === 0) {
            getHierarchyData('STATE', '');
          }
          if (renderedFeatures[0].properties.state_id !== selectedStateID) {
            getHierarchyData(
              'DISTRICT',
              renderedFeatures[0].properties.state_id,
            );
            const params = {
              fetchLevel: 'STATE',
              stateID: renderedFeatures[0].properties.state_id,
              year: selectedYear,
              season: selectedSeason,
              cropRankingParam: selectedCropRankingParam,
            };
            getLevelStats(params);
            getCropsRegionWise({
              stateID: renderedFeatures[0].properties.state_id,
              fetchLevel: 'STATE',
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              stateID: renderedFeatures[0].properties.state_id,
              fetchLevel: 'STATE',
              year: selectedYear,
              season: selectedSeason,
            });
          }
        }
      } else if (
        zoom >= config.mapboxConfig.adminLayers.district.zoomRange[0]
        && zoom < config.mapboxConfig.adminLayers.district.zoomRange[1]
      ) {
        // Search State Features Here
        renderedFeatures = this.mapContainer.queryRenderedFeatures(point, {
          layers: ['level2Fill'],
        });
        if (renderedFeatures.length > 0) {
          setLocation('STATE', renderedFeatures[0].properties.state_id);
          setLocation('DISTRICT', renderedFeatures[0].properties.district_id);
          if (states.length === 0) {
            getHierarchyData('STATE', '');
          } else if (districts.length === 0) {
            getHierarchyData(
              'DISTRICT',
              renderedFeatures[0].properties.state_id,
            );
          }
          const params = {
            fetchLevel: 'DISTRICT',
            stateID: renderedFeatures[0].properties.state_id,
            districtID: renderedFeatures[0].properties.district_id,
            year: selectedYear,
            season: selectedSeason,
            cropRankingParam: selectedCropRankingParam,
          };
          if (renderedFeatures[0].properties.state_id !== selectedStateID) {
            getHierarchyData(
              'DISTRICT',
              renderedFeatures[0].properties.state_id,
            );
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
            getLevelStats(params);
            getCropsRegionWise({
              fetchLevel: 'DISTRICT',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              fetchLevel: 'DISTRICT',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              year: selectedYear,
              season: selectedSeason,
            });
          } else if (
            renderedFeatures[0].properties.district_id !== selectedDistrictID
          ) {
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
            getLevelStats(params);
            getCropsRegionWise({
              fetchLevel: 'DISTRICT',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              fetchLevel: 'DISTRICT',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              year: selectedYear,
              season: selectedSeason,
            });
            // getHierarchyData(
            //   'VILLAGE',
            //   renderedFeatures[0].properties.tehsil_id,
            // );
          }
        }
      } else if (
        zoom >= config.mapboxConfig.adminLayers.tehsil.zoomRange[0]
        && zoom < config.mapboxConfig.adminLayers.tehsil.zoomRange[1]
      ) {
        // Search State Features Here
        renderedFeatures = this.mapContainer.queryRenderedFeatures(point, {
          layers: ['level3Fill'],
        });
        if (renderedFeatures.length > 0) {
          setLocation('STATE', renderedFeatures[0].properties.state_id);
          setLocation('DISTRICT', renderedFeatures[0].properties.district_id);
          setLocation(
            'TEHSIL',
            renderedFeatures[0].properties.tehsil_id
              === '42623d26-734e-4481-b595-43429e097d0f'
              ? '77962571-7d6f-4186-9310-b72baffc700d'
              : renderedFeatures[0].properties.tehsil_id,
          );
          if (states.length === 0) {
            getHierarchyData('STATE', '');
          } else if (districts.length === 0) {
            getHierarchyData(
              'DISTRICT',
              renderedFeatures[0].properties.state_id,
            );
          } else if (tehsils.length === 0) {
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
          }
          const params = {
            fetchLevel: 'TEHSIL',
            stateID: renderedFeatures[0].properties.state_id,
            districtID: renderedFeatures[0].properties.district_id,
            tehsilID:
              renderedFeatures[0].properties.tehsil_id
              === '42623d26-734e-4481-b595-43429e097d0f'
                ? '77962571-7d6f-4186-9310-b72baffc700d'
                : renderedFeatures[0].properties.tehsil_id,
            year: selectedYear,
            season: selectedSeason,
            cropRankingParam: selectedCropRankingParam,
          };
          if (renderedFeatures[0].properties.state_id !== selectedStateID) {
            getHierarchyData(
              'DISTRICT',
              renderedFeatures[0].properties.state_id,
            );
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
            getLevelStats(params);
            getCropsRegionWise({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
          } else if (
            renderedFeatures[0].properties.district_id !== selectedDistrictID
          ) {
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
            getLevelStats(params);
            getCropsRegionWise({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
          } else if (
            renderedFeatures[0].properties.tehsil_id !== selectedTehsilID
          ) {
            getHierarchyData(
              'TEHSIL',
              renderedFeatures[0].properties.district_id,
            );
            getLevelStats(params);
            getCropsRegionWise({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
            getTopCrops({
              fetchLevel: 'TEHSIL',
              stateID: renderedFeatures[0].properties.state_id,
              districtID: renderedFeatures[0].properties.district_id,
              tehsilID:
                renderedFeatures[0].properties.tehsil_id
                === '42623d26-734e-4481-b595-43429e097d0f'
                  ? '77962571-7d6f-4186-9310-b72baffc700d'
                  : renderedFeatures[0].properties.tehsil_id,
              year: selectedYear,
              season: selectedSeason,
            });
            // getHierarchyData(
            //   'VILLAGE',
            //   renderedFeatures[0].properties.tehsil_id,
            // );
          }
        }
      }
    }
  };

  getBounds = (bounds, currentFarm) => {
    const {
      updateBounds,
      satellitesSource,
      indicesValue,
      startDate,
      endDate,
      // fetchIndicesImages,
      selectedFarm,
      // selectedTab,
    } = this.props;
    console.log(
      'Bounds >>>>>>>>>>>>>>>>',
      bounds,
      indicesValue,
      satellitesSource,
      selectedFarm,
      startDate,
      endDate,
      currentFarm,
    );
    updateBounds(
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      currentFarm,
    );
    // if (currentFarm && selectedTab === 0) {
    //   fetchIndicesImages(
    //     bounds,
    //     indicesValue,
    //     satellitesSource,
    //     startDate,
    //     endDate,
    //     currentFarm,
    //   );
    // }
  };

  level0MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.mapContainer;
      this.setState({
        levelOnHover: e.features[0].properties.country_name,
      });

      if (level0Hover) {
        map.setFeatureState(
          {
            source: 'level0',
            sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
            id: level0Hover,
          },
          {
            hover: false,
          },
        );
      }
      level0Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level0MouseLeaveHandler = () => {
    const map = this.mapContainer;
    this.setState({
      levelOnHover: undefined,
    });
    if (level0Hover) {
      map.setFeatureState(
        {
          source: 'level0',
          sourceLayer: config.mapboxConfig.adminLayers.country.sourceLayer,
          id: level0Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level0ClickHandler = (e) => {
    if (e.features.length > 0) {
      const map = this.mapContainer;
      const {
        selectedYear,
        selectedSeason,
        selectedCropRankingParam,
        getLevelStats,
        // getCropsRegionWise,
      } = this.props;
      const geom = e.features[0].geometry;
      const featureGeoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: geom,
            properties: {},
          },
        ],
      };

      const bounds = bbox(featureGeoJSON);
      map.fitBounds(bounds, { padding: 20 });
      const params = {
        fetchLevel: 'INDIA',
        year: selectedYear,
        season: selectedSeason,
        cropRankingParam: selectedCropRankingParam,
      };
      getLevelStats(params);
      // getCropsRegionWise(params);
      this.getBounds(bounds);
    }
  };

  level1MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.mapContainer;
      this.setState({
        levelOnHover: `${e.features[0].properties.STATE_NAME},${e.features[0].properties.COUNTRYNAM}`,
      });
      if (level1Hover) {
        map.setFeatureState(
          {
            source: 'level1',
            sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
            id: level1Hover,
          },
          {
            hover: false,
          },
        );
      }
      level1Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level1MouseLeaveHandler = () => {
    const map = this.mapContainer;
    this.setState({
      levelOnHover: undefined,
    });
    if (level1Hover) {
      map.setFeatureState(
        {
          source: 'level1',
          sourceLayer: config.mapboxConfig.adminLayers.state.sourceLayer,
          id: level1Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level1ClickHandler = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.mapContainer;
      const {
        selectedCropRankingParam,
        selectedSeason,
        selectedYear,
        getCropsRegionWise,
        getTopCrops,
        getLevelStats,
        getHierarchyData,
        setLocation,
        calledFrom,
      } = this.props;
      const { bounds } = e.features[0].properties;
      const stateID = e.features[0].properties.state_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      setLocation('STATE', stateID);
      getHierarchyData('DISTRICT', stateID);
      if (calledFrom === 'farmEngine') {
        const params = {
          fetchLevel: 'STATE',
          stateID,
          year: selectedYear,
          season: selectedSeason,
          cropRankingParam: selectedCropRankingParam,
        };
        getLevelStats(params);
        getCropsRegionWise({
          fetchLevel: 'STATE',
          stateID,
          year: selectedYear,
          season: selectedSeason,
        });
        getTopCrops({
          fetchLevel: 'STATE',
          stateID,
          year: selectedYear,
          season: selectedSeason,
        });
      }
      this.getBounds(boundingBox);
    }
  };

  level2MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.mapContainer;
      this.setState({
        levelOnHover: `${e.features[0].properties.district_name}, ${e.features[0].properties.state_name}`,
      });
      if (level2Hover) {
        map.setFeatureState(
          {
            source: 'level2',
            sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
            id: level2Hover,
          },
          {
            hover: false,
          },
        );
      }
      level2Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: true,
        },
      );
    }
  };

  level2MouseLeaveHandler = () => {
    const map = this.mapContainer;
    this.setState({
      levelOnHover: undefined,
    });
    if (level2Hover) {
      map.setFeatureState(
        {
          source: 'level2',
          sourceLayer: config.mapboxConfig.adminLayers.district.sourceLayer,
          id: level2Hover,
        },
        {
          hover: false,
        },
      );
    }
  };

  level2ClickHandler = (e) => {
    if (e.features.length > 0) {
      // console.log('Properties >>>>>>>>', e.features[0].properties);
      const map = this.mapContainer;
      const {
        selectedCropRankingParam,
        selectedSeason,
        selectedYear,
        getCropsRegionWise,
        getTopCrops,
        selectedStateID,
        getHierarchyData,
        setLocation,
        getLevelStats,
        calledFrom,
      } = this.props;
      const { bounds } = e.features[0].properties;
      const stateID = e.features[0].properties.state_id;
      const districtID = e.features[0].properties.district_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      if (stateID !== selectedStateID) {
        getHierarchyData('DISTRICT', stateID);
        setLocation('STATE', stateID);
      }
      setLocation('DISTRICT', districtID);
      getHierarchyData('TEHSIL', districtID);
      if (calledFrom === 'farmEngine') {
        const params = {
          fetchLevel: 'STATE',
          stateID,
          year: selectedYear,
          season: selectedSeason,
          cropRankingParam: selectedCropRankingParam,
        };
        getLevelStats(params);
        getCropsRegionWise({
          fetchLevel: 'STATE',
          stateID,
          districtID,
          year: selectedYear,
          season: selectedSeason,
        });
        getTopCrops({
          fetchLevel: 'STATE',
          stateID,
          districtID,
          year: selectedYear,
          season: selectedSeason,
        });
      }
      this.getBounds(boundingBox);
    }
  };

  level3MouseMoveHandler = (e) => {
    if (e.features.length) {
      const map = this.mapContainer;
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      this.setState({
        levelOnHover: `${e.features[0].properties.tehsil_name}`,
      });
      if (level3Hover) {
        map.setFeatureState(
          {
            source: 'level3',
            sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
            id: level3Hover,
          },
          {
            hover: false,
          },
        );
      }
      level3Hover = e.features[0].id;
      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: true,
        },
      );
      // this.tileHover(e);
    }
  };

  level3MouseLeaveHandler = () => {
    const map = this.mapContainer;
    this.setState({
      levelOnHover: undefined,
    });
    if (level3Hover) {
      map.setFeatureState(
        {
          source: 'level3',
          sourceLayer: config.mapboxConfig.adminLayers.tehsil.sourceLayer,
          id: level3Hover,
        },
        {
          hover: false,
        },
      );
    }
    if (popup) {
      popup.remove();
    }
  };

  level3ClickHandler = (e) => {
    if (e.features.length > 0) {
      const map = this.mapContainer;
      const {
        selectedStateID,
        selectedDistrictID,
        selectedTehsilID,
        selectedYear,
        selectedSeason,
        selectedCropRankingParam,
        getCropsRegionWise,
        getTopCrops,
        getHierarchyData,
        setLocation,
        getLevelStats,
        calledFrom,
      } = this.props;
      const { bounds } = e.features[0].properties;
      const stateID = e.features[0].properties.state_id;
      const districtID = e.features[0].properties.district_id;
      const tehsilID = e.features[0].properties.tehsil_id;
      const geom = wkx.Geometry.parse(bounds).toGeoJSON();
      const boundingBox = bbox(geom);
      map.fitBounds(boundingBox, { padding: 20 });
      if (stateID !== selectedStateID) {
        getHierarchyData('DISTRICT', stateID);
        setLocation('STATE', stateID);
        getHierarchyData('TEHSIL', districtID);
        setLocation('DISTRICT', districtID);
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      } else if (districtID !== selectedDistrictID) {
        getHierarchyData('TEHSIL', districtID);
        setLocation('DISTRICT', districtID);
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      } else if (tehsilID !== selectedTehsilID) {
        setLocation(
          'TEHSIL',
          tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
            ? '77962571-7d6f-4186-9310-b72baffc700d'
            : tehsilID,
        );
        // getHierarchyData('VILLAGE', e.features[0].properties.tehsil_id);
      }
      if (calledFrom === 'farmEngine') {
        const params = {
          fetchLevel: 'TEHSIL',
          tehsilID:
            tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
              ? '77962571-7d6f-4186-9310-b72baffc700d'
              : tehsilID,
          districtID,
          stateID,
          year: selectedYear,
          season: selectedSeason,
          cropRankingParam: selectedCropRankingParam,
        };
        getLevelStats(params);
        getCropsRegionWise({
          fetchLevel: 'TEHSIL',
          tehsilID:
            tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
              ? '77962571-7d6f-4186-9310-b72baffc700d'
              : tehsilID,
          districtID,
          stateID,
          year: selectedYear,
          season: selectedSeason,
        });
        getTopCrops({
          fetchLevel: 'TEHSIL',
          tehsilID:
            tehsilID === '42623d26-734e-4481-b595-43429e097d0f'
              ? '77962571-7d6f-4186-9310-b72baffc700d'
              : tehsilID,
          districtID,
          stateID,
          year: selectedYear,
          season: selectedSeason,
        });
      }
      this.getBounds(boundingBox);

      // self.fetchLevelStats(2, e.features[0].properties.district_id);
      // map.setFilter('districtFill', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
      // map.setFilter('districtLine', [
      //   '==',
      //   'district_id',
      //   e.features[0].properties.district_id,
      // ]);
    }
  };

  genericSegmentMouseMove = (e) => {
    const map = this.mapContainer;
    const { setSegmentProperties, selectedSeason } = this.props;
    if (
      e.features.length > 0
      && map.getZoom() > config.segmentCommonProps.minZoomForEvents
    ) {
      // console.log("Featuress >> ",e.features[0]);
      const feature = e.features[0];
      const layerData = feature.layer;
      segmentLayerId = layerData.id;
      const addCursor = map.getCanvas();
      addCursor.style.cursor = 'pointer';
      if (!map.getLayer('clickedFarm')) {
        if (setSegmentProperties) {
          if (layerData.id === 'ganganagarsegments') {
            setSegmentProperties(e.features[0].properties);
          } else {
            setSegmentProperties({
              MAJORITY:
                selectedSeason === 1
                  ? feature.properties.Kharif
                  : feature.properties.Rabi,
              score: feature.properties.Rating,
              farm_area: feature.properties.farm_area,
            });
          }
        }
      }

      if (hoversegment) {
        // set the hover attribute to false with feature state
        map.setFeatureState(
          {
            source: layerData.source,
            sourceLayer: layerData['source-layer'],
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }

      hoversegment = e.features[0].id;

      // set the hover attribute to true with feature state
      map.setFeatureState(
        {
          source: layerData.source,
          sourceLayer: layerData['source-layer'],
          id: hoversegment,
        },
        {
          hover: true,
        },
      );
      // this.tileHover(e);
    }
  };

  genericSegmentMouseLeave = () => {
    const map = this.mapContainer;
    const { setSegmentProperties } = this.props;
    if (!map.getLayer('clickedFarm')) {
      if (setSegmentProperties) {
        setSegmentProperties();
      }
    }

    if (hoversegment) {
      const segment = config.segmentsProps.find(
        o => o.layerId === segmentLayerId,
      );
      if (segment) {
        map.setFeatureState(
          {
            source: segment.sourceId,
            sourceLayer: segment.sourceLayer,
            id: hoversegment,
          },
          {
            hover: false,
          },
        );
      }
    }
  };

  genericSegmentMouseClick = (e) => {
    const map = this.mapContainer;
    if (
      e.features.length > 0
      && map.getZoom() > config.segmentCommonProps.minZoomForEvents
    ) {
      const {
        calledFrom,
        getNdviIndices,
        openFarmCard,
        setSegmentProperties,
        selectedTab,
        selectedSeason,
      } = this.props;
      const feature = e.features[0];
      console.log('Features >>>>> ', feature);
      if (!(calledFrom === 'dashboard' && selectedTab === 5)) {
        if (setSegmentProperties) {
          if (feature.layer.id === 'ganganagarsegments') {
            setSegmentProperties(feature.properties);
          } else {
            setSegmentProperties({
              MAJORITY:
                selectedSeason === 1
                  ? e.features[0].properties.Kharif
                  : e.features[0].properties.Rabi,
              score: e.features[0].properties.Rating,
              farm_area: e.features[0].properties.farm_area,
            });
          }
        }
        const featureGeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: feature.geometry,
              properties: feature.properties,
            },
          ],
        };

        const boundingBox = bbox(featureGeoJSON);

        if (calledFrom === 'farmEngine') {
          const completeBounds = findMissingBounds(boundingBox, 0);
          const {
            x1, x2, x3, x4, y1, y2, y3, y4,
          } = completeBounds;
          const xBounds = `${x1},${x2},${x3},${x4}`;
          const yBounds = `${y1},${y2},${y3},${y4}`;
          // let x = completeBounds.slice(0,4);
          // x = x.join(',');
          // let y = completeBounds.slice(5);
          // y.join(',')
          let today = new Date();
          let priorDate = new Date().setDate(today.getDate() - 90);
          priorDate = new Date(priorDate);
          today = `${today.getDate()}-${today.getMonth()
            + 1}-${today.getFullYear()}`;
          priorDate = `${priorDate.getDate()}-${priorDate.getMonth()
            + 1}-${priorDate.getFullYear()}`;
          console.log(
            '======>',
            boundingBox,
            completeBounds,
            xBounds,
            yBounds,
            today,
            priorDate,
          );

          const ndviParams = {
            x: xBounds,
            y: yBounds,
            dateTo: today,
            dateFrom: priorDate,
            source: 's2',
          };
          getNdviIndices(ndviParams);
        }
        this.getBounds(boundingBox, feature.properties.farmid);
        const featureCenter = center(featureGeoJSON);
        map.flyTo({
          center: featureCenter.geometry.coordinates,
          zoom: config.segmentCommonProps.flyToOptionsOnClick.zoom,
          speed: config.segmentCommonProps.flyToOptionsOnClick.speed,
        });
        if (map.getLayer('clickedFarm')) {
          map.getSource('clickedFarm').setData(featureGeoJSON);
        } else {
          map.addSource('clickedFarm', {
            type: 'geojson',
            data: featureGeoJSON,
          });

          let cropKey = '';
          if (feature.layer.id === 'ganganagarsegments') {
            cropKey = 'MAJORITY';
          } else {
            cropKey = selectedSeason === 1 ? 'Kharif' : 'Rabi';
          }

          map.addLayer({
            id: 'clickedFarm',
            type: 'fill',
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-opacity': 1,
              'fill-color':
                calledFrom === 'farmEngine'
                || (calledFrom === 'dashboard' && selectedTab !== 0)
                  ? [
                    'match',
                    ['get', cropKey],
                    [0],
                    config.crops['0'].cropColor,
                    [1],
                    config.crops['1'].cropColor,
                    [2],
                    config.crops['2'].cropColor,
                    [3],
                    config.crops['3'].cropColor,
                    [4],
                    config.crops['4'].cropColor,
                    [5],
                    config.crops['5'].cropColor,
                    [6],
                    config.crops['6'].cropColor,
                    [7],
                    config.crops['7'].cropColor,
                    [8],
                    config.crops['8'].cropColor,
                    '#7ec850',
                  ]
                  : 'rgba(0,0,0,0)',
            },
          });

          map.addLayer({
            id: 'clickedFarmLine',
            type: 'line',
            source: 'clickedFarm',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color':
                calledFrom === 'farmEngine'
                || (calledFrom === 'dashboard' && selectedTab !== 0)
                  ? '#fff'
                  : '#fff',
              'line-width':
                calledFrom === 'farmEngine'
                || (calledFrom === 'dashboard' && selectedTab !== 0)
                  ? 2
                  : 4,
            },
          });
        }
        let distId;
        if (feature.properties.Dist_ID) {
          distId = feature.properties.Dist_ID.toLowerCase();
        }
        if (calledFrom === 'farmEngine') {
          openFarmCard(feature.properties.farmid, featureCenter, distId);
        }
        if (
          calledFrom === 'farmEngine'
          || (calledFrom === 'dashboard' && selectedTab !== 0)
        ) {
          map.setPaintProperty('background', 'background-opacity', 0.5);
        }
      }
    }
  };

  tileHover = (e) => {
    const map = this.mapContainer;
    const {
      selectedTimelineDate,
      indexToView,
      indicesTimeline,
      indicesLegends,
    } = this.props;
    if (!_.isEmpty(indicesTimeline)) {
      // if (indicesTimeline.data) {
      if (indexToView && selectedTimelineDate) {
        const canvas = map.getCanvas();
        const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
        if (gl) {
          const { point } = e;
          const { x, y } = point;
          const data = new Uint8Array(4);
          const canvasX = x - canvas.offsetLeft;
          const canvasY = canvas.height - y - canvas.offsetTop;
          gl.readPixels(
            canvasX,
            canvasY,
            1,
            1,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            data,
          );
          const [r, g, b] = data;
          if (indexToView !== '' && indicesLegends.length) {
            // const color = `rgba(${r}, ${g}, ${b})`;
            // console.log(`Color at (${x}, ${y}) = ${color}`);
            // const ndviValue = indicesLegends.find(o => _.isEqual(o.rgb, [r, g, b]));
            // console.log('Canvas >>> ', canvas.offsetLeft, canvas.offsetTop);
            // console.log(`Point${x}, ${y}, ${canvasX}, ${canvasY}, ${data}`);
            // console.log(`color ${color}`);
            let minRDiffSoFar = 11;
            let minGDiffSoFar = 11;
            let minBDiffSoFar = 11;
            let indexWithMinValue = -1;
            const { length } = indicesLegends;
            for (let i = 0; i < length; i += 1) {
              const item = indicesLegends[i];
              const { rgb } = item;
              const rDiff = Math.abs(rgb[0] - r);
              const gDiff = Math.abs(rgb[1] - g);
              const bDiff = Math.abs(rgb[2] - b);
              if (rDiff < minRDiffSoFar) {
                minRDiffSoFar = rDiff;
                if (gDiff < minGDiffSoFar) {
                  minGDiffSoFar = gDiff;
                  if (bDiff < minBDiffSoFar) {
                    minBDiffSoFar = bDiff;
                    indexWithMinValue = i;
                  }
                }
              }
            }
            if (indexWithMinValue !== -1) {
              const ndviValue = indicesLegends[indexWithMinValue];
              // console.log('ndviValue ', indicesLegends[indexWithMinValue]);
              // console.log('minRDiffSoFar ', minRDiffSoFar);
              // console.log('minGDiffSoFar ', minGDiffSoFar);
              // console.log('minBDiffSoFar ', minBDiffSoFar);
              // console.log('color ', color);
              // /* eslint-disable global-require */
              // const mapboxgl = require('mapbox-gl');
              // /* eslint-enable global-require */
              this.setState({
                pixelValue: ndviValue.value,
                pixelCoord: e.lngLat,
              });
              // if (popup) {
              //   popup
              //     .setLngLat(e.lngLat)
              //     .setHTML(`<h2>${ndviValue.value}</h2>`)
              //     .addTo(map);
              // } else {
              //   popup = new mapboxgl.Popup()
              //     .setLngLat(e.lngLat)
              //     .setHTML(`<h2>${ndviValue.value}</h2>`)
              //     .addTo(map);
              // }
            } else {
              // console.log('color ', color);
              this.setState({
                pixelValue: undefined,
                pixelCoord: undefined,
              });
              // popup.remove();
            }
          }
        }
      }
      // }
    }
  };

  render() {
    const { isCropMapShown, addExpandClass, searchPlace } = this.state;
    return (
      <div className="dashboardMapHead">
        {' '}
        {/* add class mapDivision for comaprison */}
        <MyMap
          _onMapLoad={this._onMapLoad}
          _onMapClick={this._onMapClick}
          _onZoomEnd={this._onZoomEnd}
          _onDragEnd={this._onDragEnd}
          {...this.state}
          {...this.props}
        />
        {/* Zoom/Current Location Buttons Start */}
        <MapControl
          handleZoomChange={this.handleZoomChange}
          geolocateUser={this.geolocateUser}
        />
        {/* Zoom/Current Location Buttons End */}
        {/* Satellite Control Start */}
        <StyleChangeControl
          isCropMapShown={isCropMapShown}
          changeMapStyle={this.changeMapStyle}
        />
        {/* Satellite Control End */}
        {/* Chat Control Start */}
        {/* <ChatControl /> */}
        {/* Chat Control End */}
        {/* Search Panel Start */}
        <SearchControl
          addExpandClass={addExpandClass}
          searchPlace={searchPlace}
          handleClickSearch={this.handleClickSearch}
          handlePlaceChange={this.handlePlaceChange}
        />
        {/* Search Panel End */}
        {/* Powered By Section Start  */}
        <aside className="poweredSec">
          Powered by
          <a
            href="https://farmguide.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./images/logo.png" alt="logo-img" title="logo-img" />
          </a>
        </aside>
        {/* Timeline Panel Start */}
        {/* Timeline Panel End */}
      </div>
    );
  }
}

export default connect(
  ({
    location, landing, agricultureIndices, survey,
  }) => ({
    states: location.state,
    districts: location.district,
    tehsils: location.tehsil,
    villages: location.village,
    levelStats: landing.levelStats,
    selectedCropRankingParam: landing.selectedCropRankingParam,
    selectedSeason: location.selectedSeason,
    selectedYear: location.selectedYear,
    selectedStateID: location.selectedStateID,
    selectedDistrictID: location.selectedDistrictID,
    selectedTehsilID: location.selectedTehsilID,
    selectedVillageID: location.selectedVillageID,
    indicesValue: agricultureIndices.indicesValue,
    satellitesSource: agricultureIndices.satellitesSource,
    startDate: agricultureIndices.startDate,
    endDate: agricultureIndices.endDate,
    indicesImages: agricultureIndices.indicesImages,
    selectedFarm: agricultureIndices.selectedFarm,
    bounds: agricultureIndices.bounds,
    indicesTimeline: agricultureIndices.indicesTimeline,
    indicesLegends: agricultureIndices.indicesLegends,
    showDemoPopup: survey.showDemoPopup,
    demoMarkerIndex: survey.demoMarkerIndex,
    surveyData: survey.surveyData,
    selectedSurveyName: survey.selectedSurveyName,
    experimentList: survey.experimentList,
    showSurveyPopup: survey.showSurveyPopup,
    surveyMarkerIndex: survey.surveyMarkerIndex,
  }),

  dispatch => ({
    getHierarchyData: (level, plID) => dispatch(getHierarchyDataAct(level, plID)),
    setLocation: (level, selectedLocation) => dispatch(setLocationAct(level, selectedLocation)),
    getLevelStats: params => dispatch(getLevelStatsAct(params)),
    getCropsRegionWise: data => dispatch(getCropsRegionWiseAct(data)),
    getTopCrops: data => dispatch(getTopCropsAct(data)),
    getNdviIndices: params => dispatch(getNdviIndicesAct(params)),
    updateBounds: (
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      farmid,
    ) => {
      dispatch(
        updateBoundsAction(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          farmid,
        ),
      );
    },
    fetchIndicesImages: (
      bounds,
      indicesValue,
      satellitesSource,
      startDate,
      endDate,
      selectedFarm,
    ) => {
      dispatch(
        fetchIndicesImagesAct(
          bounds,
          indicesValue,
          satellitesSource,
          startDate,
          endDate,
          selectedFarm,
        ),
      );
    },
    clearIndicesImages: () => dispatch(clearIndicesImagesAct()),
    resetLocationDetails: () => dispatch(resetLocationAct()),
  }),
  null,
  { withRef: true },
)(FarmEngineMap);
