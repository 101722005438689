// import moment from 'moment';
import ajax from '../Functions/ajax';
import { findMissingBounds } from '../Functions/helper';

export const setMessage = messageText => ({
  type: 'SET_MESSAGE',
  message: messageText,
});

export const resetNDVIAction = () => ({
  type: 'RESET_NDVI_ACTION',
  payload: true,
});

export const setAsyncMessage = messageText => dispatch => new Promise((resolve) => {
  setTimeout(() => resolve(), 2000);
}).then(() => dispatch(setMessage(messageText)));

export const updateBoundsAction = (
  bounds,
  indicesValue,
  satellitesSource,
  startDate,
  endDate,
  farmid,
) => (dispatch) => {
  const newStartDate = startDate;
  const newEndDate = endDate;

  if (startDate) {
    startDate = startDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .reverse()
      .join('-');
    console.log('startDate._d after ', startDate);
  }
  if (endDate) {
    endDate = endDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .reverse()
      .join('-');
  }
  const coordinates = findMissingBounds(bounds, 0);
  const xBounds = `${coordinates.x1},${coordinates.x2},${coordinates.x3},${coordinates.x4}`;
  const yBounds = `${coordinates.y1},${coordinates.y2},${coordinates.y3},${coordinates.y4}`;
  let indice = [];
  if (indicesValue && indicesValue.length) {
    indicesValue.forEach((item) => {
      indice.push(item.value);
    });
  }
  indice = indice.toString();
  if (
    indicesValue
    && indicesValue.length
    && satellitesSource
    && bounds.length
    && startDate
    && endDate
    && farmid
  ) {
    // const dateA = moment(startDate);
    // const dateB = moment(endDate);
    // const diffDays = dateB.diff(dateA, 'days');
    // const rem = diffDays % 4;
    // const div = (diffDays - rem) / 4;
    // let start = dateA.format('DD/MM/YYYY');
    // let end = dateA.add(4, 'days').format('DD/MM/YYYY');
    // const dates = [];
    // for (let i = 0; i < div; i += 1) {
    //   if (i !== 0) {
    //     start = end;
    //     end = dateA.add(4, 'days').format('DD/MM/YYYY');
    //   }
    //   dates.push({
    //     start: start.split('/').join('-'),
    //     end: end.split('/').join('-'),
    //   });
    // }
    // if (rem !== 0) {
    //   start = end;
    //   end = dateA.add(rem, 'days').format('DD/MM/YYYY');
    //   dates.push({
    //     start: start.split('/').join('-'),
    //     end: end.split('/').join('-'),
    //   });
    // }
    dispatch({
      type: 'SET_LEVEL_STATS_LOADER',
      payload: true,
    });
    /*
    // eslint-disable-next-line no-inner-declarations
    function makeRequestsFromArray(arr) {
      let index = 0;
      function request() {
        const item = arr[index];
        return ajax
          .get(
            `/indices/indices/timeline?x=${xBounds}&y
            =${yBounds}&source=${satellitesSource}&
            indice=${indice}&dateFrom=${item.start}&dateTo=${item.end}`,
            { isLoader: true },
          )
          .then((response) => {
            index += 1;
            if (response.data.status) {
              dispatch({
                type: 'SET_LEVEL_STATS_LOADER',
                payload: false,
              });
              dispatch({
                type: 'INDICES_TIMELINE_SUCCESS',
                payload: response.data.data,
                bounds,
                satellitesSource,
                indicesValue,
                startDate: newStartDate,
                endDate: newEndDate,
                selectedFarm: farmid,
              });
            } else {
              dispatch({
                type: 'SET_LEVEL_STATS_LOADER',
                payload: false,
              });
              dispatch({
                type: 'SKIP_TIMELINE',
                bounds,
                satellitesSource,
                indicesValue,
                startDate: newStartDate,
                endDate: newEndDate,
                selectedFarm: farmid,
              });
            }
            if (index >= arr.length) {
              return 'done';
            }
            return request();
          })
          .catch((error) => {
            index += 1;
            dispatch({
              type: 'SET_LEVEL_STATS_LOADER',
              payload: false,
            });
            dispatch({
              type: 'INDICES_TIMELINE_FAILURE',
              payload: error,
            });
          });
      }
      return request();
    }
    // eslint-enable-next-line no-inner-declarations
    makeRequestsFromArray(dates); */

    ajax
      .get(
        `/indices/indices/timeline?x=${xBounds}&y=${yBounds}&source=${satellitesSource}&indice=${indice}&dateFrom=${startDate}&dateTo=${endDate}`,
        {
          isLoader: true,
        },
      )
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: 'INDICES_TIMELINE_SUCCESS',
            payload: response.data.data,
            bounds,
            satellitesSource,
            indicesValue,
            startDate: newStartDate,
            endDate: newEndDate,
            selectedFarm: farmid,
          });
        } else {
          dispatch({
            type: 'INDICES_TIMELINE_FAILURE',
            payload: response.data.error,
            bounds,
            satellitesSource,
            indicesValue,
            startDate: newStartDate,
            endDate: newEndDate,
            selectedFarm: farmid,
          });
        }
        dispatch({
          type: 'SET_LEVEL_STATS_LOADER',
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'INDICES_TIMELINE_FAILURE',
          payload: error,
        });
        dispatch({
          type: 'SET_LEVEL_STATS_LOADER',
          payload: false,
        });
      });
  } else {
    dispatch({
      type: 'BOUNDS_UPDATE',
      bounds,
      indicesValue,
      satellitesSource,
      startDate: newStartDate,
      endDate: newEndDate,
      selectedFarm: farmid,
    });
  }
};
export const updateBoundsComparisionAction = (
  bounds,
  indicesValueComparision,
  satellitesSource,
  startDateComparision,
  endDateComparision,
  compareFarmid,
) => (dispatch) => {
  const newStartDate = startDateComparision;
  const newEndDate = endDateComparision;
  if (startDateComparision) {
    startDateComparision = startDateComparision._d
      .toLocaleDateString('en-IN')
      .split('/')
      .join('-');
  }
  if (endDateComparision) {
    endDateComparision = endDateComparision._d
      .toLocaleDateString('en-IN')
      .split('/')
      .join('-');
  }
  const coordinates = findMissingBounds(bounds, 0);
  const xBounds = `${coordinates.x1},${coordinates.x2},${coordinates.x3},${coordinates.x4}`;
  const yBounds = `${coordinates.y1},${coordinates.y2},${coordinates.y3},${coordinates.y4}`;
  let indice = [];
  if (indicesValueComparision && indicesValueComparision.length) {
    indicesValueComparision.forEach((item) => {
      indice.push(item.value);
    });
  }
  indice = indice.toString();
  if (
    indicesValueComparision
    && indicesValueComparision.length
    && satellitesSource
    && bounds.length
    && startDateComparision
    && endDateComparision
    && compareFarmid
  ) {
    ajax
      .get(
        `/indices/indices/timeline?x=${xBounds}&y=${yBounds}&source=${satellitesSource}&indice=${indice}&dateFrom=${startDateComparision}&dateTo=${endDateComparision}`,
        {},
      )
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: 'INDICES_TIMELINE_COMPARISON_SUCCESS',
            payload: response.data.data,
            indicesValueComparision,
            startDateComparision: newStartDate,
            endDateComparision: newEndDate,
            selectedCompareFarm: compareFarmid,
          });
        } else {
          dispatch({
            type: 'INDICES_TIMELINE_COMPARISON_FAILURE',
            payload: response.data.error,
            indicesValueComparision,
            startDateComparision: newStartDate,
            endDateComparision: newEndDate,
            selectedCompareFarm: compareFarmid,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'INDICES_TIMELINE_COMPARISON_FAILURE',
          payload: error,
        });
      });
  } else {
    dispatch({
      type: 'BOUNDS_UPDATE_COMPARISION',
      bounds,
      indicesValueComparision,
      startDateComparision: newStartDate,
      endDateComparision: newEndDate,
      selectedCompareFarm: compareFarmid,
    });
  }
};

export const getIndicesUpdateAction = (
  satellitesSource,
  indicesValue,
  bounds,
) => (dispatch) => {
  console.log(satellitesSource, indicesValue, bounds);
  const coordinates = findMissingBounds(bounds, 0);
  // const xBounds = `${coordinates.x1},${coordinates.x2},${coordinates.x3},${coordinates.x4}`;
  // const yBounds = `${coordinates.y1},${coordinates.y2},${coordinates.y3},${coordinates.y4}`;
  const indicesValueArray = [];
  indicesValue.forEach((item) => {
    indicesValueArray.push(item.value);
  });
  const data = {
    geoJson: {
      type: 'Polygon',
      coordinates: [
        [
          [coordinates.x1, coordinates.y1],
          [coordinates.x2, coordinates.y2],
          [coordinates.x3, coordinates.y3],
          [coordinates.x4, coordinates.y4],
        ],
      ],
    },
    source: satellitesSource,
    indice: indicesValueArray,
  };
  ajax
    .post('/indices/indices/update', {
      data,
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'INDICES_ALERT_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'INDICES_ALERT_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'INDICES_ALERT_FAILURE',
        payload: err,
      });
    });
};

export const getSourceAction = () => (dispatch) => {
  ajax
    .get('/indices/indices/satsources', {
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'SATSOURCE_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SATSOURCE_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SATSOURCE_FAILURE',
        payload: err,
      });
    });
};

export const fetchIndicesImagesAct = (
  bounds,
  indicesValue,
  satellitesSource,
  startDate,
  endDate,
  selectedFarm,
) => (dispatch) => {
  dispatch({
    type: 'INDICES_IMAGES_FETCHING',
    payload: selectedFarm,
  });
  if (startDate) {
    startDate = startDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .join('-');
  }
  if (endDate) {
    endDate = endDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .join('-');
  }
  const coordinates = findMissingBounds(bounds, 0);
  const xBounds = `${coordinates.x1},${coordinates.x2},${coordinates.x3},${coordinates.x4}`;
  const yBounds = `${coordinates.y1},${coordinates.y2},${coordinates.y3},${coordinates.y4}`;
  let indice = [];
  if (indicesValue && indicesValue.length) {
    indicesValue.forEach((item) => {
      indice.push(item.value);
    });
  }
  indice = indice.toString();
  if (
    indicesValue
    && indicesValue.length
    && satellitesSource
    && bounds.length
    && startDate
    && endDate
  ) {
    ajax
      .get(
        `/indices/indices/timelineImage?x=${xBounds}&y=${yBounds}&source=${satellitesSource}&indice=${indice}&dateFrom=${startDate}&dateTo=${endDate}&farmID=${selectedFarm}`,
        {
          isLoader: true,
        },
      )
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: 'INDICES_IMAGES_SUCCESS',
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: 'INDICES_IMAGES_FAILURE',
            payload: response.data.error,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'INDICES_IMAGES_FAILURE',
          payload: error,
        });
      });
  }
};

export const fetchCompareIndicesImagesAct = (
  compareBounds,
  indicesValue,
  satellitesSource,
  startDate,
  endDate,
  selectedCompareFarm,
) => (dispatch) => {
  dispatch({
    type: 'COMPARE_INDICES_IMAGES_FETCHING',
    payload: selectedCompareFarm,
  });
  const coordinates = findMissingBounds(compareBounds, 0);
  const xBounds = `${coordinates.x1},${coordinates.x2},${coordinates.x3},${coordinates.x4}`;
  const yBounds = `${coordinates.y1},${coordinates.y2},${coordinates.y3},${coordinates.y4}`;
  let indice = [];
  if (indicesValue && indicesValue.length) {
    indicesValue.forEach((item) => {
      indice.push(item.value);
    });
  }
  indice = indice.toString();
  if (
    indicesValue
    && indicesValue.length
    && satellitesSource
    && compareBounds.length
    && startDate
    && endDate
  ) {
    ajax
      .get(
        `/indices/indices/timelineImage?x=${xBounds}&y=${yBounds}&source=${satellitesSource}&indice=${indice}&dateFrom=${startDate}&dateTo=${endDate}`,
        {},
      )
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: 'COMPARE_INDICES_IMAGES_SUCCESS',
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: 'COMPARE_INDICES_IMAGES_FAILURE',
            payload: response.data.error,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'COMPARE_INDICES_IMAGES_FAILURE',
          payload: error,
        });
      });
  }
};

export const clearIndicesImagesAct = () => ({
  type: 'INDICES_IMAGES_FAILURE',
  payload: true,
});

export const clearCompareIndicesImagesAct = () => ({
  type: 'COMPARE_INDICES_IMAGES_FAILURE',
  payload: true,
});

export const getTimeSeriesDataAct = (data, flag, flag2) => (dispatch) => {
  if (data.startDate && (flag || flag2)) {
    data.startDate = data.startDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .reverse()
      .join('-');
  }
  if (data.endDate && (flag || flag2)) {
    data.endDate = data.endDate._d
      .toLocaleDateString('en-IN')
      .split('/')
      .reverse()
      .join('-');
  }
  ajax
    .post('/indices/indices/timeseries', {
      data,
      isLoader: true,
    })
    .then((response) => {
      // debugger;
      dispatch({ type: 'SET_LEVEL_STATS_LOADER', payload: false });
      if (flag) {
        if (response.data.status) {
          dispatch({
            type: 'TIMESERIES_DATA_WITHOUT_COMPARE_FETCHED',
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: 'TIMESERIES_DATA_WITHOUT_COMPARE_FAILED',
            payload: response.data.error,
          });
        }
      } else if (flag2) {
        if (response.data.status) {
          dispatch({
            type: 'TIMESERIES_DATA_WITH_COMPARE_FETCHED',
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: 'TIMESERIES_DATA_WITH_COMPARE_FAILED',
            payload: response.data.error,
          });
        }
      } else if (response.data.status) {
        dispatch({
          type: 'TIMESERIES_DATA_FETCHED',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'TIMESERIES_DATA_FETCHING_FAILED',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: 'SET_LEVEL_STATS_LOADER', payload: false });
      if (flag) {
        dispatch({
          type: 'TIMESERIES_DATA_WITHOUT_COMPARE_FAILED',
          payload: err,
        });
      } else if (flag2) {
        dispatch({
          type: 'TIMESERIES_DATA_WITH_COMPARE_FAILED',
          payload: err,
        });
      } else {
        dispatch({ type: 'TIMESERIES_DATA_FETCHING_FAILED', payload: err });
      }
    });
};

export const getTimeSeriesChangeDataAct = data => dispatch => ajax
  .post('/indices/indices/timeseries_change', {
    data,
    isLoader: true,
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'TIMESERIES_CHANGE_DATA_FETCHED',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'TIMESERIES_CHANGE_DATA_FETCHING_FAILED',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    // console.log('err>>>', err);
    dispatch({
      type: 'TIMESERIES_CHANGE_DATA_FETCHING_FAILED',
      payload: err,
    });
  });

export const getIndicesLegends = (indice, compare) => (dispatch) => {
  dispatch({
    type: compare ? 'CLEAR_COMPARE_INDICE_LEGENDS' : 'CLEAR_INDICE_LEGENDS',
    payload: null,
  });
  ajax
    .get('/indices/indices/indice_color_code', {
      params: {
        indice,
      },
      isLoader: true,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: compare
            ? 'COMPARE_INDICE_LEGENDS_SUCCESS'
            : 'INDICE_LEGENDS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: compare
            ? 'COMPARE_INDICE_LEGENDS_FAILURE'
            : 'INDICE_LEGENDS_FAILURE',
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: compare
          ? 'COMPARE_INDICE_LEGENDS_FAILURE'
          : 'INDICE_LEGENDS_FAILURE',
        payload: error,
      });
    });
};

export const clearIndicesLegends = compare => ({
  type: compare ? 'CLEAR_COMPARE_INDICE_LEGENDS' : 'CLEAR_INDICE_LEGENDS',
  payload: null,
});
