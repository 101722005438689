import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

const Pagination = (props) => {
  const {
    page,
    rowsPerPage,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  return (
    <TablePagination
      rowsPerPageOptions={[5]}
      component="div"
      count={totalCount || 10}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
