import React from 'react';
import { config } from '../../../config/index';

const StyleChangeControl = (props) => {
  const { isCropMapShown, changeMapStyle } = props;
  return (
    <div
      className="satelliteHead"
      role="button"
      tabIndex="0"
      onClick={() => changeMapStyle({
        styleURL: config.mapboxConfig.defaultStyle,
        cropMapView: !isCropMapShown,
        segmentedView: true,
      })
      }
      onKeyUp={() => changeMapStyle({
        styleURL: config.mapboxConfig.defaultStyle,
        cropMapView: !isCropMapShown,
        segmentedView: true,
      })
      }
    >
      {isCropMapShown ? (
        <img src="../images/setelliteview.png" alt="satellite-img" />
      ) : (
        <img src="../images/cropview.png" alt="satellite-img" />
      )}
    </div>
  );
};

export default StyleChangeControl;
