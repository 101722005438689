import React from 'react';

class Windy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        key: 'lVTsvGZI8qCTO9PvnDGUYPDuC4hU7HEX', // REPLACE WITH YOUR KEY !!!

        // Changing Windy parameters at start-up time
        // (recommended for faster start-up)
        lat: 26.684,
        lon: 73.878,
        zoom: 7,

        timestamp: Date.now() + 3 * 24 * 60 * 60 * 1000,

        hourFormat: '12h',
      },
    };
  }

  componentDidMount() {
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;

        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));

        document.head.append(script);
      });
    }

    loadScript('https://unpkg.com/leaflet@1.4.0/dist/leaflet.js').then(() => {
      loadScript('https://api4.windy.com/assets/libBoot.js').then(() => {
        const { options } = this.state;

        this.handleChange(options);
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { options } = this.state;
    const { windyLat, windyLng, windyZoom } = this.props;
    const newOptions = { ...options };
    // console.log('windyLat', windyLat, windyLng, windyZoom);
    // console.log('option', lat, lon, zoom);
    // this.handleChange(options);
    if (
      windyLat !== prevProps.windyLat
      || windyLng !== prevProps.windyLng
      || windyZoom !== prevProps.windyZoom
    ) {
      newOptions.lat = windyLat;
      newOptions.lon = windyLng;
      newOptions.zoom = windyZoom;
      this.handleUpdateOption(newOptions);
    }
  }

  handleUpdateOption = (options) => {
    this.setState(
      {
        options,
      },
      () => {
        setTimeout(() => {
          this.handleChange(options);
        }, 2000);
      },
    );
  };

  handleChange(options) {
    // let { options } = this.state;
    if (!window.copy_of_W) {
      window.copy_of_W = Object.assign({}, window.W);
    }
    if (window.W.windyBoot) {
      window.W = Object.assign({}, window.copy_of_W);
    }

    const links = document.querySelectorAll('head > link');
    const scripts = document.querySelectorAll('head > script');
    // console.log('====>', links, scripts);
    if (links.length && scripts.length) {
      // debugger;
      let linkInd = 0;
      links.forEach((item) => {
        if (item.href.indexOf('windy') > -1) {
          linkInd += 1;
          if (linkInd > 1) {
            item.remove();
          }
        }
      });
      let scriptInd = 0;

      scripts.forEach((item) => {
        if (item.src.indexOf('windy') > -1) {
          scriptInd += 1;
          if (scriptInd > 3) {
            item.remove();
          }
        }
      });
    }

    window.windyInit(options, (windyAPI) => {
      const { store } = windyAPI;
      // All the params are stored in windyAPI.store

      // const levels = store.getAllowed('availLevels');
      // levels = ['surface', '850h', ... ]
      // Getting all available values for given key

      // let i = 0;
      // setInterval(() => {
      //     i = i === levels.length - 1 ? 0 : i + 1;

      //     // Changing Windy params at runtime
      //     store.set('level', levels[i]);
      // }, 500);

      // Observing change of .store value
      store.on('level', (level) => {
        console.log(`Level was changed: ${level}`);
      });
    });
  }

  render() {
    return (
      <div
        id="windy"
        className="dashboardMapHead"
        style={{ height: 'calc(100vh - 71px)' }}
      />
    );
  }
}

export default Windy;
