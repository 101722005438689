import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts';

const LineChartComponent = (props) => {
  const {
    data,
    dataKey,
    yAxisLabel,
    showToolTip,
    showLegend,
    children,
  } = props;
  return (
    <ResponsiveContainer width="80%" aspect={5.0 / 2.5}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey} stroke="#444444" fontSize="80%" />
        <YAxis stroke="#444444" fontSize="80%">
          <Label
            value={yAxisLabel}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle', fontSize: '80%', fill: '#bababa' }}
          />
        </YAxis>
        {showToolTip && <Tooltip />}
        {showLegend && <Legend />}
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
