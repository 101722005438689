import React from 'react';
import { Bar } from 'recharts';
import { BarChart } from '../common/chart';

const BarChartComponent = (props) => {
  const {
    data,
    yAxisLabel,
    xAxisDataKey,
    dataKey,
    dataKey1,
    compareChart,
    compareBarChart,
    label,
    showLegend,
    unit,
    showToolTip,
    stackBarchart,
    isStackBarChart,
  } = props;
  console.log('data', data);
  console.log('compareBarChart', compareBarChart);
  const colorArray = [
    '#00b0ff',
    '#9013fe',
    '#7ed321',
    '#ff8161',
    '#00C49F',
    '#0d21d8',
    '#FFBB28',
    '#09b466',
  ];
  return (
    <BarChart
      data={data}
      showLegend={showLegend || false}
      showToolTip={showToolTip || false}
      dataKey={xAxisDataKey}
      yAxisLabel={yAxisLabel}
      label={label}
    >
      {isStackBarChart ? null : (
        <Bar dataKey={dataKey} fill="#4a90e2" unit={unit} />
      )}
      {compareChart && <Bar dataKey={dataKey1} fill="#09b466" />}
      {compareBarChart
        && compareBarChart.length
        && compareBarChart.map((item, index) => {
          if (index >= 0) {
            return (
              <Bar
                key={item.value}
                dataKey={item.value}
                fill={item.color ? item.color : colorArray[index]}
              />
            );
          }
          return null;
        })}
      {stackBarchart
        && stackBarchart.length
        && stackBarchart.map((item, index) => (
          <Bar
            key={item.value}
            dataKey={item.value}
            stackId="a"
            fill={item.color ? item.color : colorArray[index]}
          />
        ))}
    </BarChart>
  );
};

export default BarChartComponent;
