import React, { Component } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import '../../css/ivrs/mediaUpload.scss';

class MediaFileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaTitle: '',
      mediaDesc: '',
      tags: ['Seed', 'Govt.'],
      language: 'English',
      selectedGrp: 'Feedback',
    };
  }

  handleFileChange = (e) => {
    const { updateBase64File } = this.props;
    e.preventDefault();
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      const fileBase64String = fileReader.result;
      updateBase64File(fileBase64String);
    });
    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  handleTitleChange = (e) => {
    const { updateText } = this.props;
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        mediaTitle: value,
      },
      () => {
        updateText(value);
      },
    );
  };

  handleMediaDesc = (e) => {
    const { updateDesc } = this.props;
    const { target } = e;
    const { value } = target;
    this.setState(
      {
        mediaDesc: value,
      },
      () => {
        updateDesc(value);
      },
    );
  };

  addTags = (e) => {
    const { tags } = this.state;
    if (e.key === 'Enter' && e.target.value !== '') {
      this.setState({
        tags: [...tags, e.target.value],
      });
      e.target.value = '';
    }
  };

  removeTags = (index) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter(tag => tags.indexOf(tag) !== index),
    });
  };

  handleLangChange = (e) => {
    const { updateLanguage } = this.props;
    this.setState({
      language: e.target.value,
    });
    updateLanguage(e.target.value);
  };

  handleSelectGrp = (e) => {
    this.setState({
      selectedGrp: e.target.value,
    });
  };

  render() {
    const {
      mediaDesc, mediaTitle, tags, language, selectedGrp,
    } = this.state;
    const { mediaTitleError, mediaDescError, mediaError } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={3} md={3} xs={12}>
            <TextField
              id="ivrsMediatitle"
              label="Media Title"
              helperText={mediaTitleError || ''}
              error={!!mediaTitleError}
              className="ivrsTitle"
              margin="normal"
              value={mediaTitle}
              onChange={this.handleTitleChange}
            />
            {/* <i className="material-icons">edit</i> */}
          </Grid>
          <Grid item lg={2} md={2} xs={12} />
          <Grid item lg={7} md={7} xs={12}>
            <TextField
              id="ivrsMediaDesc"
              label="Text of audio file"
              helperText={mediaDescError || ''}
              error={!!mediaDescError}
              className="mediaDesc"
              margin="normal"
              value={mediaDesc}
              onChange={this.handleMediaDesc}
            />
          </Grid>
        </Grid>
        <div className="media-body">
          <Grid container spacing={2}>
            <Grid item lg={12} className="mediaUpload">
              <img src="/images/upload.svg" alt="Upload" />
              <h1>Upload File</h1>
              {/* <input type="file" id="file-upload" multiple required /> */}
              <h5>Supported Format MP3 & Max Duration 30 sec</h5>
              <input
                className="fileType"
                type="file"
                accept=".mp3,.wav"
                onChange={this.handleFileChange}
              />
              {/* <label htmlFor="file-upload">Browse</label> */}
            </Grid>
            <Grid item lg={12} md={12}>
              <p className="errorText">{mediaError}</p>
            </Grid>

            {/*       <Grid item lg={12} className="mediaUpload">
            <div className="uploadFile">
              <input
                className="fileType"
                type="file"
                accept=".mp3,.wav"
                onChange={this.handleFileChange}
              />
              <div className="customUploadFile">
                <i className="material-icons">cloud_upload</i>
                <span className="customUpload">Upload File</span>
                <span>Supported formats: mp3</span>
              </div>
            </div>
          </Grid> */}
          </Grid>
          <Grid container className="tagsTitle selectController">
            <Grid item lg={6} md={6} xs={12}>
              <h4>Tags</h4>
              <div className="ivrsTags">
                <div className="addTags">
                  <TextField
                    //  label="Press enter to add tags"
                    onKeyUp={event => this.addTags(event)}
                  />
                </div>
                <ul>
                  {tags.map((tag, index) => (
                    <li key={Math.random()}>
                      <span>{tag}</span>
                      <i
                        className="material-icons"
                        onClick={() => this.removeTags(index)}
                        onKeyDown={() => this.removeTags(index)}
                        role="button"
                        tabIndex="0"
                      >
                        close
                      </i>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <h4>Group</h4>
              <div className="ivrsTags">
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    name="feedback"
                    className="selectEmpty modalDropdown"
                    displayEmpty
                    value={selectedGrp}
                    onChange={this.handleSelectGrp}
                  >
                    <MenuItem value="Feedback">Feedback</MenuItem>
                    <MenuItem value="Data">Data</MenuItem>
                    <MenuItem value="Market">Market</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <h4>Audio Language</h4>
              <div className="ivrsTags">
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    name="feedback"
                    className="selectEmpty modalDropdown"
                    displayEmpty
                    value={language}
                    onChange={this.handleLangChange}
                  >
                    <MenuItem value="Hindi">Hindi</MenuItem>
                    <MenuItem value="English">English</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <Grid item lg={12} className="mediaUpload">
          <img src="/images/upload.svg" alt="Upload" />
          <h1>Upload File</h1>
          <input type="file" id="file-upload" multiple required />
          <h5>Supported Format MP3</h5>
          <label htmlFor="file-upload">Browse</label>
          <div className="file-name" id="file-upload-filename">
            File Name
          </div> */}
      </React.Fragment>
    );
  }
}

export default MediaFileUploader;
