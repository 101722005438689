import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LanguageContext from '../common/languageContext';

import {
  getHierarchyDataAct,
  setLocationAct,
} from '../../Actions/locationHierarchyAction';
import { languageConverter } from '../../Functions/helper';

class LocationHeirarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sFlag: false,
      dFlag: false,
      tFlag: false,
    };
  }

  componentDidMount() {
    const { getHierarchyData, states } = this.props;
    if (!states.length) {
      getHierarchyData('STATE', null);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      states,
      districts,
      tehsils,
      selectedStateID,
      selectedDistrictID,
      agriIndices,
      lFlag,
      mapLoaded,
    } = this.props;
    const { sFlag, dFlag, tFlag } = this.state;
    // console.log(this.props.location);

    if (agriIndices && !lFlag) {
      if (
        states.length > 0
        && districts.length === 0
        && tehsils.length === 0
        && !sFlag
        && mapLoaded
      ) {
        this.handleLocation(
          { target: { value: '5c77da4f-bc9b-4099-bed7-15e06a45f376' } },
          'STATE',
        );
        this.updateFlag('sFlag', true);
      }

      if (
        prevProps.districts.length === 0
        && districts.length > 0
        && selectedStateID === '5c77da4f-bc9b-4099-bed7-15e06a45f376'
        && tehsils.length === 0
        && !dFlag
        && mapLoaded
      ) {
        // console.log(districts);
        this.handleLocation(
          { target: { value: 'd14c2906-e134-422f-941c-ac25b0ecf3bd' } },
          'DISTRICT',
        );
        this.updateFlag('dFlag', true);
      }

      if (
        prevProps.tehsils.length === 0
        && states.length > 0
        && districts.length > 0
        && tehsils.length > 0
        && selectedStateID === '5c77da4f-bc9b-4099-bed7-15e06a45f376'
        && selectedDistrictID === 'd14c2906-e134-422f-941c-ac25b0ecf3bd'
        && !tFlag
        && mapLoaded
      ) {
        // setLocation('TEHSIL', "8c4233f7-8482-41c6-828c-910538243523");
        this.handleLocation(
          {
            target: {
              value: '76008ce3-bb1f-4ce1-a685-7b3060577e6c',
            },
          },
          'TEHSIL',
        );

        this.updateFlag('tFlag', true);
      }
    }
  }

  updateFlag = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleLocation(event, level) {
    const {
      setLocation,
      getHierarchyData,
      broadcastLocationChange,
      states,
      districts,
      tehsils,
      fitLevelBounds,
    } = this.props;
    let stateData;
    let districtData;
    let tehsilData;
    setLocation(level, event.target.value);
    if (broadcastLocationChange) {
      broadcastLocationChange(level, event.target.value);
    }
    switch (level) {
      case 'STATE': {
        getHierarchyData('DISTRICT', event.target.value);
        stateData = states.find(o => o.stateID === event.target.value);
        if (stateData && stateData.bounds && fitLevelBounds) {
          fitLevelBounds('STATE', stateData.bounds);
        }
        break;
      }

      case 'DISTRICT': {
        getHierarchyData('TEHSIL', event.target.value);
        districtData = districts.find(o => o.districtID === event.target.value);
        if (districtData && districtData.bounds && fitLevelBounds) {
          fitLevelBounds('DISTRICT', districtData.bounds);
        }
        break;
      }

      case 'TEHSIL': {
        getHierarchyData('VILLAGE', event.target.value);
        tehsilData = tehsils.find(o => o.tehsilID === event.target.value);
        if (tehsilData.bounds && fitLevelBounds) {
          fitLevelBounds('TEHSIL', tehsilData.bounds);
        }
        break;
      }
      case 'VILLAGE': {
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { language } = this.context;
    const {
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
      selectedVillageID,
      states,
      districts,
      tehsils,
      villages,
      type,
      calledFrom,
      hideVillage,
      hideTehsil,
      restrictedStates,
      restrictedDistricts,
    } = this.props;
    console.log('type', type, calledFrom);
    const statesHtml = [];
    const districtHtml = [];
    const tehsilsHtml = [];
    const villagesHtml = [];
    statesHtml.push(
      <MenuItem value="" key="Fgdfsafsfs" disabled>
        {languageConverter('State', language.hindi)}
      </MenuItem>,
    );

    if (states.length) {
      states.forEach((item) => {
        if (restrictedStates) {
          if (restrictedStates.indexOf(item.stateID) > -1) {
            statesHtml.push(
              <MenuItem key={item.stateID} value={item.stateID}>
                {item.stateName}
              </MenuItem>,
            );
          } else {
            statesHtml.push(
              <MenuItem key={item.stateID} value={item.stateID} disabled>
                {item.stateName}
              </MenuItem>,
            );
          }
        } else {
          statesHtml.push(
            <MenuItem key={item.stateID} value={item.stateID}>
              {item.stateName}
            </MenuItem>,
          );
        }
      });
    }

    districtHtml.push(
      <MenuItem value="" key="Fgderefs" disabled>
        {languageConverter('Districts', language.hindi)}
      </MenuItem>,
    );

    if (districts.length) {
      districts.forEach((item) => {
        if (restrictedDistricts) {
          if (restrictedDistricts.indexOf(item.districtID) > -1) {
            districtHtml.push(
              <MenuItem key={item.districtID} value={item.districtID}>
                {item.districtName}
              </MenuItem>,
            );
          } else {
            districtHtml.push(
              <MenuItem key={item.districtID} value={item.districtID} disabled>
                {item.districtName}
              </MenuItem>,
            );
          }
        } else {
          districtHtml.push(
            <MenuItem key={item.districtID} value={item.districtID}>
              {item.districtName}
            </MenuItem>,
          );
        }
      });
    }

    tehsilsHtml.push(
      <MenuItem value="" key="Fgdqwefs" disabled>
        {languageConverter('Tehsils', language.hindi)}
      </MenuItem>,
    );

    if (tehsils.length) {
      tehsils.forEach((item) => {
        if (item.tehsilID !== '42623d26-734e-4481-b595-43429e097d0f') {
          tehsilsHtml.push(
            <MenuItem key={item.tehsilID} value={item.tehsilID}>
              {item.tehsilName}
            </MenuItem>,
          );
        }
      });
    }

    villagesHtml.push(
      <MenuItem value="" key="Fgipo" disabled>
        {languageConverter('Villages', language.hindi)}
      </MenuItem>,
    );

    if (villages.length) {
      villages.map(item => villagesHtml.push(
        <MenuItem key={item.villageID} value={item.villageID}>
          {item.villageName}
        </MenuItem>,
      ));
    }
    return (
      <React.Fragment>
        <FormControl className="formControl inputHead coloredForms">
          <Select
            disableUnderline
            inputProps={{ 'aria-label': 'age' }}
            className={type || 'tableDropdown'}
            onChange={event => this.handleLocation(event, 'STATE')}
            value={selectedStateID}
            displayEmpty
          >
            {statesHtml}
          </Select>
        </FormControl>
        {calledFrom === 'dashboard' || districts.length ? (
          <React.Fragment>
            {calledFrom === 'dashboard'
              ? null
              : `${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                inputProps={{ 'aria-label': 'age' }}
                className={type || 'tableDropdown'}
                onChange={event => this.handleLocation(event, 'DISTRICT')}
                value={selectedDistrictID}
                displayEmpty
                disabled={!(districts.length > 0)}
              >
                {districtHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null}
        {(calledFrom === 'dashboard' || tehsils.length) && !hideTehsil ? (
          <React.Fragment>
            {calledFrom === 'dashboard'
              ? null
              : `${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                inputProps={{ 'aria-label': 'age' }}
                className={type || 'tableDropdown'}
                onChange={event => this.handleLocation(event, 'TEHSIL')}
                value={selectedTehsilID}
                displayEmpty
                disabled={!(tehsils.length > 0)}
              >
                {tehsilsHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null}
        {(calledFrom === 'dashboard' || villages.length) && !hideVillage ? (
          <React.Fragment>
            {calledFrom === 'dashboard'
              ? null
              : `${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                inputProps={{ 'aria-label': 'age' }}
                className={type || 'tableDropdown'}
                onChange={event => this.handleLocation(event, 'VILLAGE')}
                value={selectedVillageID}
                displayEmpty
                disabled={!(villages.length > 0)}
              >
                {villagesHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null}
        {/* districts.length ? (
          <React.Fragment>
            {`${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                name="State"
                inputProps={{ 'aria-label': 'age' }}
                className="tableDropdown"
                onChange={event => this.handleLocation(event, 'DISTRICT')}
                value={selectedDistrictID}
                displayEmpty
              >
                {districtHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null */}
        {/* tehsils.length ? (
          <React.Fragment>
            {`${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                name="State"
                inputProps={{ 'aria-label': 'age' }}
                className="tableDropdown"
                onChange={event => this.handleLocation(event, 'TEHSIL')}
                value={selectedTehsilID}
                displayEmpty
              >
                {tehsilsHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null */}
        {/* villages.length ? (
          <React.Fragment>
            {`${languageConverter('in', language.hindi)} `}
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                name="State"
                inputProps={{ 'aria-label': 'age' }}
                className="tableDropdown"
                onChange={event => this.handleLocation(event, 'VILLAGE')}
                value={selectedVillageID}
                displayEmpty
              >
                {villagesHtml}
              </Select>
            </FormControl>
          </React.Fragment>
        ) : null */}
      </React.Fragment>
    );
  }
}

LocationHeirarchy.contextType = LanguageContext;

export default withRouter(
  connect(
    ({ location }) => ({
      states: location.state,
      districts: location.district,
      tehsils: location.tehsil,
      villages: location.village,
      selectedStateID: location.selectedStateID,
      selectedDistrictID: location.selectedDistrictID,
      selectedTehsilID: location.selectedTehsilID,
      selectedVillageID: location.selectedVillageID,
      selectedLevel: location.selectedLevel,
    }),

    dispatch => ({
      getHierarchyData: (level, plID) => dispatch(getHierarchyDataAct(level, plID)),
      setLocation: (level, selectedLocation) => dispatch(setLocationAct(level, selectedLocation)),
    }),
  )(LocationHeirarchy),
);
