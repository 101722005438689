import moment from 'moment';

const getDuration = async (srcUrl = '') => {
  const promise = new Promise((resolve) => {
    const audioInst = new Audio(srcUrl);
    audioInst.onloadedmetadata = () => {
      resolve(audioInst.duration);
    };
  });
  return promise;
};

const getHoursMinSecFromDuration = (durationInSec) => {
  const durationInMillis = moment.duration({ seconds: durationInSec });
  return {
    hours: durationInMillis.hours(),
    minutes: durationInMillis.minutes(),
    seconds: durationInMillis.seconds(),
  };
};

const getPaddedDuration = (duration) => {
  if (duration <= 9) {
    return `0${duration}`;
  }
  return duration;
};

const formatDuration = (durationInSec = 0) => {
  durationInSec = Math.round(durationInSec);
  const { hours, minutes, seconds } = getHoursMinSecFromDuration(durationInSec);
  if (durationInSec < 3600) {
    return `${minutes}:${getPaddedDuration(seconds)}`;
  }
  return `${hours}:${getPaddedDuration(minutes)}:${getPaddedDuration(seconds)}`;
};

const getNewAudioInstance = (mediaSource, onEnded) => {
  let newAudioInstance;
  if (mediaSource) {
    if (mediaSource.hasSrcUrl) {
      newAudioInstance = new Audio(mediaSource.srcUrl);
    } else {
      newAudioInstance = new Audio(mediaSource.base64);
    }
    newAudioInstance.onended = onEnded;
  }
  return newAudioInstance;
};

export { getDuration, formatDuration, getNewAudioInstance };
