import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { convertWKBGeom } from '../../../Functions/helper';
import {
  resetWeatherSourceDetails,
  getImdData,
  getHistoricalImdData,
} from '../../../Actions/weatherAction';
import ScrollableTabPanel from '../../common/ScrollableTabPanel';
import WeatherSelectTab from './weatherSelectTab';
import WeatherHistoricalTab from './weatherHistoricalTab';
import '../../../css/tabPanel.scss';

// let popup;
class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationObject: {},
      activeWeatherTab: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { locationObject } = this.state;
    let stateNameSelected;
    let districtNameSelected;
    if (location.selectedStateID !== '' && location.selectedDistrictID === '') {
      location.state.forEach((item) => {
        const { stateID, stateName } = item;
        if (stateID === location.selectedStateID) stateNameSelected = stateName;
      });
      const locationObjectCopy = { ...locationObject };
      locationObjectCopy.state = stateNameSelected;
      this.setState({
        locationObject: locationObjectCopy,
      });
    }
    if (location.selectedDistrictID !== '') {
      location.state.forEach((item) => {
        const { stateID, stateName } = item;
        if (stateID === location.selectedStateID) stateNameSelected = stateName;
      });
      location.district.forEach((item) => {
        const { districtID, districtName } = item;
        if (districtID === location.selectedDistrictID) districtNameSelected = districtName;
      });
      const locationObjectCopy = { ...locationObject };
      locationObjectCopy.state = stateNameSelected;
      locationObjectCopy.district = districtNameSelected;
      this.setState({
        locationObject: locationObjectCopy,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      imdData,
      imdHistoricalData,
      getMapReference,
      smapHeatmap,
      noaaHeatmap,
      gpmHeatmap,
      chirpsHeatmap,
      selectedGPMDate,
      selectedNOAADate,
      selectedNOAADataFilter,
      selectedCHIRPSDate,
      spiHeatmap,
      selectedSPIYear,
    } = this.props;
    const { activeWeatherTab } = this.state;
    if (location.selectedStateID !== prevProps.location.selectedStateID) {
      let stateNameSelected;
      location.state.forEach((item) => {
        const { stateID, stateName } = item;
        if (stateID === location.selectedStateID) stateNameSelected = stateName;
      });
      this.updateLocationObject('state', stateNameSelected);
    }
    if (location.selectedDistrictID !== prevProps.location.selectedDistrictID) {
      let districtNameSelected;
      location.district.forEach((item) => {
        const { districtID, districtName } = item;
        if (districtID === location.selectedDistrictID) districtNameSelected = districtName;
      });
      this.updateLocationObject('district', districtNameSelected);
    }
    if (
      location.selectedStateID === prevProps.location.selectedStateID
      && location.selectedDistrictID === ''
      && prevProps.location.selectedDistrictID !== ''
    ) {
      let stateNameSelected;
      location.state.forEach((item) => {
        const { stateID, stateName } = item;
        if (stateID === location.selectedStateID) stateNameSelected = stateName;
      });
      this.updateLocationObject('state', stateNameSelected);
    }

    // if (location.selectedTehsilID !== prevProps.location.selectedTehsilID) {
    //   let tehsilNameSelected;
    //   location.tehsil.forEach((item) => {
    //     const { tehsilID, tehsilName } = item;
    //     if (tehsilID === location.selectedTehsilID) tehsilNameSelected = tehsilName;
    //   });
    //   this.updateLocationObject('tehsil', tehsilNameSelected);
    // }
    // if (location.selectedVillageID !== prevProps.location.selectedVillageID) {
    //   let villageNameSelected;
    //   location.village.forEach((item) => {
    //     const { villageID, villageName } = item;
    //     if (villageID === location.selectedVillageID) villageNameSelected = villageName;
    //   });
    //   this.updateLocationObject('village', villageNameSelected);
    // }

    if (imdData.station && !prevProps.imdData.station) {
      // Read the data of Weather Station
      const map = getMapReference();
      // console.log("Add IMD Data on Map");
      if (map) {
        const size = 200;
        const pulsingDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),

          onAdd() {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
          },

          render() {
            const duration = 1000;
            const t = (window.performance.now() % duration) / duration;

            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const { context } = this;

            // draw outer circle
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2,
            );
            context.fillStyle = `rgba(207, 255, 100,${1 - t})`;
            context.fill();

            // draw inner circle
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              radius,
              0,
              Math.PI * 2,
            );
            context.fillStyle = 'rgba(207, 255, 0, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // update this image's data with data from the canvas
            this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height,
            ).data;

            // keep the map repainting
            map.triggerRepaint();

            // return `true` to let the map know that the image was updated
            return true;
          },
        };

        map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

        map.addLayer({
          id: 'imdPoints',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: imdData.station.map((station, index) => ({
                type: 'Feature',
                id: index,
                geometry: {
                  type: 'Point',
                  coordinates: [
                    station.weatherStationLon,
                    station.weatherStationLat,
                  ],
                },
              })),
            },
          },
          layout: {
            'icon-image': 'pulsing-dot',
            visibility: activeWeatherTab === 0 ? 'visible' : 'none',
          },
        });
      }
    }

    if (!imdData.station && prevProps.imdData.station) {
      // Read the data of Weather Station
      // console.log("Clear IMD Data on Map");
      const map = getMapReference();
      if (map) {
        if (map.getLayer('imdPoints')) {
          map.removeLayer('imdPoints');
          map.removeImage('pulsing-dot');
          map.removeSource('imdPoints');
        }
      }
    }

    if (imdHistoricalData.station && !prevProps.imdHistoricalData.station) {
      // Read the data of Weather Station
      const map = getMapReference();
      // console.log("Add IMD Data on Map");
      if (map) {
        const size = 200;
        const pulsingHistoricalDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),

          onAdd() {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
          },

          render() {
            const duration = 1000;
            const t = (window.performance.now() % duration) / duration;

            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const { context } = this;

            // draw outer circle
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2,
            );
            context.fillStyle = `rgba(255, 69, 100,${1 - t})`;
            context.fill();

            // draw inner circle
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              radius,
              0,
              Math.PI * 2,
            );
            context.fillStyle = 'rgba(255, 69, 0, 1)';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // update this image's data with data from the canvas
            this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height,
            ).data;

            // keep the map repainting
            map.triggerRepaint();

            // return `true` to let the map know that the image was updated
            return true;
          },
        };

        map.addImage('pulsing-historical-dot', pulsingHistoricalDot, {
          pixelRatio: 2,
        });

        map.addLayer({
          id: 'imdPointsHistorical',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: imdHistoricalData.station.map((station, index) => ({
                type: 'Feature',
                id: index,
                geometry: {
                  type: 'Point',
                  coordinates: [
                    station.weatherStationLon,
                    station.weatherStationLat,
                  ],
                },
              })),
            },
          },
          layout: {
            'icon-image': 'pulsing-historical-dot',
            visibility: activeWeatherTab === 1 ? 'visible' : 'none',
          },
        });

        // map.on('mousemove', 'imdPoints', (e) => {
        //   console.log("Mouse Move On Point",e);
        // });
      }
    }

    if (!imdHistoricalData.station && prevProps.imdHistoricalData.station) {
      // Read the data of Weather Station
      // console.log("Clear IMD Data on Map");
      const map = getMapReference();
      if (map) {
        if (map.getLayer('imdPointsHistorical')) {
          map.removeLayer('imdPointsHistorical');
          map.removeImage('pulsing-historical-dot');
          map.removeSource('imdPointsHistorical');
        }
      }
    }

    if (_.isEmpty(prevProps.smapHeatmap) && !_.isEmpty(smapHeatmap)) {
      console.log('HEAT MAP DATA ', smapHeatmap);
      const map = getMapReference();
      const { heatMapData } = smapHeatmap;
      map.addSource('smapheatmap', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: heatMapData.map((item, index) => ({
            type: 'Feature',
            id: index,
            properties: {
              value: item.value,
              legend: item.legend,
            },
            geometry: {
              type: 'Point',
              coordinates: [item.lat, item.lon],
            },
          })),
        },
      });

      map.addLayer({
        id: 'smapLayer',
        type: 'heatmap',
        source: 'smapheatmap',
        maxzoom: 16,
        paint: {
          // Increase the heatmap weight based on frequency and property magnitude
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'value'],
            0,
            0,
            0.05,
            0.3,
            0.1,
            0.6,
            0.15,
            0.8,
            0.2,
            0.9,
            0.6,
            1,
          ],
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            1,
            9,
            3,
            12,
            5,
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.2,
            'rgb(103,169,207)',
            0.4,
            'rgb(209,229,240)',
            0.6,
            'rgb(253,219,199)',
            0.8,
            'rgb(239,138,98)',
            1,
            'rgb(178,24,43)',
          ],
          // Adjust the heatmap radius by zoom level
          'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            5,
            2,
            10,
            10,
            16,
            20,
          ],
          // Transition from heatmap to circle layer by zoom level
          // 'heatmap-opacity': [
          //   'interpolate',
          //   ['linear'],
          //   ['zoom'],
          //   7, 1,
          //   16, 0,
          // ],
        },
      });

      /* map.addLayer({
        id: 'smapPoints',
        type: 'circle',
        source: 'smapheatmap',
        minzoom: 7,
        paint: {
          // Size circle radius by earthquake magnitude and zoom level
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7, [
              'interpolate',
              ['linear'],
              ['get', 'value'],
              0, 1,
              0.1, 4,
              0.2, 8,
              0.6, 15,
            ],
            16, [
              'interpolate',
              ['linear'],
              ['get', 'value'],
              0, 5,
              0.1, 8,
              0.2, 12,
              0.6, 20,
            ],
          ],
          // Color circle by earthquake magnitude
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'value'],
            0, '#253494',
            0.05, '#2c7fb8',
            0.1, '#41b6c4',
            0.15, '#a1dab4',
            0.2, '#ffffcc',
            0.25, '#ffeda0',
            0.3, '#fed976',
            0.35, '#feb24c',
            0.4, '#fd8d3c',
            0.45, '#fc4e2a',
            0.5, '#e31a1c',
            0.55, '#bd0026',
            0.6, '#800026',
          ],
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          // Transition from heatmap to circle layer by zoom level
          'circle-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7, 0,
            8, 1,
          ],
        },
      }); */
    }

    if (!_.isEmpty(prevProps.smapHeatmap) && _.isEmpty(smapHeatmap)) {
      const map = getMapReference();
      if (map.getSource('smapheatmap')) {
        map.removeLayer('smapLayer');
        map.removeSource('smapheatmap');
      }
    }

    if (_.isEmpty(prevProps.noaaHeatmap) && !_.isEmpty(noaaHeatmap)) {
      // Add NoaaHeatmap
      const map = getMapReference();
      if (map) {
        // Add Data on map
        // Add the data of latest Date by Default
        const first = Object.values(noaaHeatmap)[0];
        /* Object.values(noaaHeatmap).length - 1 */
        console.log('Last Data ', first);
        const geoJSONData = {
          type: 'FeatureCollection',
          features: first.map((item, index) => {
            const geometry = convertWKBGeom(item.geom);
            return {
              type: 'Feature',
              id: index,
              properties: {
                mintemp: item.mintemp,
                maxtemp: item.maxtemp,
                tehsilID: item.tehsilID,
                tehsilName: item.tehsilName,
              },
              geometry,
            };
          }),
        };
        map.addSource('noaaHeatmap', {
          type: 'geojson',
          data: geoJSONData,
        });

        map.addLayer({
          id: 'noaaHeatmap',
          type: 'fill',
          source: 'noaaHeatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', selectedNOAADataFilter],
              20,
              '#2E6399',
              50,
              '#5B1821',
            ],
            'fill-opacity': 1,
          },
        });

        map.addLayer({
          id: 'noaaheatmapline',
          type: 'line',
          source: 'noaaHeatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color': '#000',
            'line-width': 1,
          },
        });
      }
    }

    if (!_.isEmpty(prevProps.noaaHeatmap) && _.isEmpty(noaaHeatmap)) {
      // Remove NoaaHeatmap
      const map = getMapReference();
      if (map) {
        if (map.getSource('noaaHeatmap')) {
          map.removeLayer('noaaHeatmap');
          map.removeLayer('noaaheatmapline');
          map.removeSource('noaaHeatmap');
        }
      }
    }

    if (
      prevProps.selectedNOAADate !== selectedNOAADate
      && selectedNOAADate !== ''
    ) {
      // NOAA Date Changed, Change the Data in Source Here
      const map = getMapReference();
      if (map) {
        const data = noaaHeatmap[selectedNOAADate];
        if (data) {
          const geoJSONData = {
            type: 'FeatureCollection',
            features: data.map((item, index) => {
              const geometry = convertWKBGeom(item.geom);
              return {
                type: 'Feature',
                id: index,
                properties: {
                  mintemp: item.mintemp,
                  maxtemp: item.maxtemp,
                },
                geometry,
              };
            }),
          };

          if (map.getSource('noaaHeatmap')) {
            map.getSource('noaaHeatmap').setData(geoJSONData);
          } else {
            map.addSource('noaaHeatmap', {
              type: 'geojson',
              data: geoJSONData,
            });

            map.addLayer({
              id: 'noaaHeatmap',
              type: 'fill',
              source: 'noaaHeatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-color': [
                  'interpolate',
                  ['linear'],
                  ['get', selectedNOAADataFilter],
                  20,
                  '#2E6399',
                  50,
                  '#ff0000',
                ],
                'fill-opacity': 1,
              },
            });

            map.addLayer({
              id: 'noaaheatmapline',
              type: 'line',
              source: 'noaaHeatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#000',
                'line-width': 2,
              },
            });
          }
        }
      }
    }

    if (
      prevProps.selectedNOAADataFilter !== selectedNOAADataFilter
      && selectedNOAADataFilter !== ''
    ) {
      // Data Filter Changed on Timeline Change the Style on Map
      const map = getMapReference();
      if (map) {
        if (map.getSource('noaaHeatmap')) {
          map.setPaintProperty('noaaHeatmap', 'fill-color', [
            'interpolate',
            ['linear'],
            ['get', selectedNOAADataFilter],
            20,
            '#2E6399',
            50,
            '#5B1821',
          ]);
        }
      }
    }

    if (_.isEmpty(prevProps.gpmHeatmap) && !_.isEmpty(gpmHeatmap)) {
      // Add GPM Choropleth in Map
      console.log('GPM Heatmap ', gpmHeatmap);
      const map = getMapReference();
      if (map) {
        const last = Object.values(gpmHeatmap)[0];
        /* Object.values(gpmHeatmap).length - 1 */
        console.log('Last Data ', last);
        const geoJSONData = {
          type: 'FeatureCollection',
          features: last.map((item, index) => {
            const geometry = convertWKBGeom(item.geom);
            return {
              type: 'Feature',
              id: index,
              properties: {
                gpmIndex: item.gpmIndex,
                tehsilID: item.tehsilID,
                tehsilName: item.tehsilName,
              },
              geometry,
            };
          }),
        };
        map.addSource('gpmheatmap', {
          type: 'geojson',
          data: geoJSONData,
        });

        map.addLayer({
          id: 'gpmheatmap',
          type: 'fill',
          source: 'gpmheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': [
              'step',
              ['get', 'gpmIndex'],
              '#F6FAFD',
              0,
              '#E1ECF7',
              10,
              '#CCDEEF',
              20,
              '#ACCFE5',
              30,
              '#85BBD8',
              50,
              '#5CA2CC',
              70,
              '#3986BC',
              100,
              '#1F6AAD',
              150,
              '#0E4F94',
              200,
              '#0B3269',
              250,
              '#0000ff',
            ],
            'fill-opacity': 1,
          },
        });

        map.addLayer({
          id: 'gpmheatmapline',
          type: 'line',
          source: 'gpmheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color': '#000',
            'line-width': 1,
          },
        });

        /* map.on('mousemove', 'gpmheatmap', (e) => {
          const feature = e.features[0];
          /!* eslint-disable global-require *!/
          const mapboxgl = require('mapbox-gl');
          /!* eslint-enable global-require *!/
          if (popup) {
            popup
              .setLngLat(e.lngLat)
              .setHTML(`<h2>${feature.properties.gpmIndex}</h2>`)
              .addTo(map);
          } else {
            popup = new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`<h2>${feature.properties.gpmIndex}</h2>`)
              .addTo(map);
          }
        });

        map.on('mouseleave', 'gpmheatmap', () => {
          if (popup) {
            popup.remove();
          }
        }); */
      }
    }

    if (!_.isEmpty(prevProps.gpmHeatmap) && _.isEmpty(gpmHeatmap)) {
      // Remove GPM Choropleth in Map
      const map = getMapReference();
      if (map) {
        if (map.getSource('gpmheatmap')) {
          map.removeLayer('gpmheatmap');
          map.removeLayer('gpmheatmapline');
          map.removeSource('gpmheatmap');
        }
      }
    }

    if (
      prevProps.selectedGPMDate !== selectedGPMDate
      && selectedGPMDate !== ''
    ) {
      // Date is Changed for GPM
      const map = getMapReference();
      if (map) {
        const data = gpmHeatmap[selectedGPMDate];
        if (data) {
          const geoJSONData = {
            type: 'FeatureCollection',
            features: data.map((item, index) => {
              const geometry = convertWKBGeom(item.geom);
              return {
                type: 'Feature',
                id: index,
                properties: {
                  gpmIndex: item.gpmIndex,
                  tehsilID: item.tehsilID,
                  tehsilName: item.tehsilName,
                },
                geometry,
              };
            }),
          };

          if (map.getSource('gpmheatmap')) {
            map.getSource('gpmheatmap').setData(geoJSONData);
          } else {
            map.addSource('gpmheatmap', {
              type: 'geojson',
              data: geoJSONData,
            });

            map.addLayer({
              id: 'gpmheatmap',
              type: 'fill',
              source: 'gpmheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-color': [
                  'step',
                  ['get', 'gpmIndex'],
                  '#F6FAFD',
                  0,
                  '#E1ECF7',
                  10,
                  '#CCDEEF',
                  20,
                  '#ACCFE5',
                  30,
                  '#85BBD8',
                  50,
                  '#5CA2CC',
                  70,
                  '#3986BC',
                  100,
                  '#1F6AAD',
                  150,
                  '#0E4F94',
                  200,
                  '#0B3269',
                  250,
                  '#0000ff',
                ],
                'fill-opacity': 1,
              },
            });

            map.addLayer({
              id: 'gpmheatmapline',
              type: 'line',
              source: 'gpmheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#000',
                'line-width': 1,
              },
            });
          }
        }
      }
    }

    if (_.isEmpty(prevProps.chirpsHeatmap) && !_.isEmpty(chirpsHeatmap)) {
      // Add GPM Choropleth in Map
      console.log('chirps Heatmap ', chirpsHeatmap);
      const map = getMapReference();
      if (map) {
        const last = Object.values(chirpsHeatmap)[0];
        /* Object.values(chirpsheatmap).length - 1 */
        console.log('Last Data ', last);
        const geoJSONData = {
          type: 'FeatureCollection',
          features: last.map((item, index) => {
            const geometry = convertWKBGeom(item.geom);
            return {
              type: 'Feature',
              id: index,
              properties: {
                gpmIndex: item.gpmIndex,
                tehsilID: item.tehsilID,
                tehsilName: item.tehsilName,
              },
              geometry,
            };
          }),
        };
        map.addSource('chirpsheatmap', {
          type: 'geojson',
          data: geoJSONData,
        });

        map.addLayer({
          id: 'chirpsheatmap',
          type: 'fill',
          source: 'chirpsheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'chirpsIndex'],
              0,
              '#F6FAFD',
              10,
              '#E1ECF7',
              20,
              '#CCDEEF',
              30,
              '#ACCFE5',
              50,
              '#85BBD8',
              70,
              '#5CA2CC',
              100,
              '#3986BC',
              150,
              '#1F6AAD',
              200,
              '#0E4F94',
              250,
              '#0B3269',
              300,
              '#0000ff',
            ],
            'fill-opacity': 1,
          },
        });

        map.addLayer({
          id: 'chirpsheatmapline',
          type: 'line',
          source: 'chirpsheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color': '#000',
            'line-width': 1,
          },
        });
      }
    }

    if (!_.isEmpty(prevProps.chirpsHeatmap) && _.isEmpty(chirpsHeatmap)) {
      // Remove GPM Choropleth in Map
      const map = getMapReference();
      if (map) {
        if (map.getSource('chirpsheatmap')) {
          map.removeLayer('chirpsheatmap');
          map.removeLayer('chirpsheatmapline');
          map.removeSource('chirpsheatmap');
        }
      }
    }

    if (
      prevProps.selectedCHIRPSDate !== selectedCHIRPSDate
      && selectedCHIRPSDate !== ''
    ) {
      // Date is Changed for CHIRPS
      const map = getMapReference();
      if (map) {
        const data = chirpsHeatmap[selectedCHIRPSDate];
        if (data) {
          const geoJSONData = {
            type: 'FeatureCollection',
            features: data.map((item, index) => {
              const geometry = convertWKBGeom(item.geom);
              return {
                type: 'Feature',
                id: index,
                properties: {
                  chirpsIndex: item.chirpsIndex,
                },
                geometry,
              };
            }),
          };

          if (map.getSource('chirpsheatmap')) {
            map.getSource('chirpsheatmap').setData(geoJSONData);
          } else {
            map.addSource('chirpsheatmap', {
              type: 'geojson',
              data: geoJSONData,
            });

            map.addLayer({
              id: 'chirpsheatmap',
              type: 'fill',
              source: 'chirpsheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-color': [
                  'interpolate',
                  ['linear'],
                  ['get', 'chirpsIndex'],
                  0,
                  '#F6FAFD',
                  10,
                  '#E1ECF7',
                  20,
                  '#CCDEEF',
                  30,
                  '#ACCFE5',
                  50,
                  '#85BBD8',
                  70,
                  '#5CA2CC',
                  100,
                  '#3986BC',
                  150,
                  '#1F6AAD',
                  200,
                  '#0E4F94',
                  250,
                  '#0B3269',
                  300,
                  '#0B3269',
                ],
                'fill-opacity': 1,
              },
            });

            map.addLayer({
              id: 'chirpsheatmapline',
              type: 'line',
              source: 'chirpsheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#000',
                'line-width': 1,
              },
            });
          }
        }
      }
    }

    if (_.isEmpty(prevProps.spiHeatmap) && !_.isEmpty(spiHeatmap)) {
      // Add SPI Choropleth in Map
      console.log('SPI Heatmap ', spiHeatmap);
      const map = getMapReference();
      if (map) {
        const first = Object.values(spiHeatmap)[0];
        console.log('First Data ', first);
        const geoJSONData = {
          type: 'FeatureCollection',
          features: first.SPI.map((item, index) => {
            const geometry = convertWKBGeom(item.geom);
            return {
              type: 'Feature',
              id: index,
              properties: {
                spiIndex: item.spiIndex,
                name: item.tehsilName,
              },
              geometry,
            };
          }),
        };
        map.addSource('spiheatmap', {
          type: 'geojson',
          data: geoJSONData,
        });

        map.addLayer({
          id: 'spiheatmap',
          type: 'fill',
          source: 'spiheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': [
              'step',
              ['get', 'spiIndex'],
              '#972625',
              -2.5,
              '#EC0000',
              -2.0,
              '#FF7300',
              -1.5,
              '#FEE100',
              -0.5,
              '#81FF5E',
              0.0,
              '#00FFFF',
              0.5,
              '#00BEFF',
              1,
              '#006FFF',
              1.5,
              '#3C00E4',
              2.0,
              '#3716A3',
            ],
            /* 'fill-color': [
              'step',
              ['get', 'spiIndex'],
              '#972625',
              -2.5,
              '#EC0000',
              -2,
              '#FF7300',
              -1.5,
              '#FEE100',
              -1,
              '#81FF5E',
              -0.5,
              '#00FFFF',
              0,
              '#00BEFF',
              0.5,
              '#006FFF',
              1,
              '#3C00E4',
              1.5,
              '#3716A3',
              2,
              '#3716A3',
            ], */
            'fill-opacity': 1,
          },
        });

        map.addLayer({
          id: 'spiheatmapline',
          type: 'line',
          source: 'spiheatmap',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'line-color': '#000',
            'line-width': 1,
          },
        });

        /* map.on('mousemove', 'spiheatmap', (e) => {
          const feature = e.features[0];
          /!* eslint-disable global-require *!/
          const mapboxgl = require('mapbox-gl');
          /!* eslint-enable global-require *!/
          if (popup) {
            popup
              .setLngLat(e.lngLat)
              .setHTML(`<h2>
                        ${feature.properties.name}
                        :${feature.properties.spiIndex}
                        </h2>`)
              .addTo(map);
          } else {
            popup = new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`<h2>${feature.properties.spiIndex}</h2>`)
              .addTo(map);
          }
        });

        map.on('mouseleave', 'spiheatmap', () => {
          if (popup) {
            popup.remove();
          }
        }); */
      }
    }

    if (!_.isEmpty(prevProps.spiHeatmap) && _.isEmpty(spiHeatmap)) {
      // Remove SPI Choropleth in Map
      const map = getMapReference();
      if (map) {
        if (map.getSource('spiheatmap')) {
          map.removeLayer('spiheatmap');
          map.removeLayer('spiheatmapline');
          map.removeSource('spiheatmap');
        }
      }
    }

    if (
      prevProps.selectedSPIYear !== selectedSPIYear
      && selectedSPIYear !== ''
    ) {
      // Date is Changed for SPI
      const map = getMapReference();
      if (map) {
        const data = Object.values(spiHeatmap).find(
          o => o.year === selectedSPIYear,
        );
        if (data) {
          const geoJSONData = {
            type: 'FeatureCollection',
            features: data.SPI.map((item, index) => {
              const geometry = convertWKBGeom(item.geom);
              return {
                type: 'Feature',
                id: index,
                properties: {
                  spiIndex: item.spiIndex,
                  name: item.tehsilName,
                },
                geometry,
              };
            }),
          };

          if (map.getSource('spiheatmap')) {
            map.getSource('spiheatmap').setData(geoJSONData);
          } else {
            map.addSource('spiheatmap', {
              type: 'geojson',
              data: geoJSONData,
            });

            map.addLayer({
              id: 'spiheatmap',
              type: 'fill',
              source: 'spiheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'fill-color': [
                  'step',
                  ['get', 'spiIndex'],
                  '#972625',
                  -2.5,
                  '#EC0000',
                  -2.0,
                  '#FF7300',
                  -1.5,
                  '#FEE100',
                  -0.5,
                  '#81FF5E',
                  0.0,
                  '#00FFFF',
                  0.5,
                  '#00BEFF',
                  1,
                  '#006FFF',
                  1.5,
                  '#3C00E4',
                  2.0,
                  '#3716A3',
                ],
                'fill-opacity': 1,
              },
            });

            map.addLayer({
              id: 'spiheatmapline',
              type: 'line',
              source: 'spiheatmap',
              layout: {
                visibility: 'visible',
              },
              paint: {
                'line-color': '#000',
                'line-width': 1,
              },
            });
          }
        }
      }
    }
  }

  updateLocationObject = (type, value) => {
    const { locationObject } = this.state;
    const locationObjectCopy = { ...locationObject };
    locationObjectCopy[type] = value;
    this.setState({
      locationObject: locationObjectCopy,
    });
  };

  broadcastWeatherTabChange = (tab) => {
    const { getMapReference, broadcastWeatherTabChangeInParent } = this.props;
    // resetDetails();
    const map = getMapReference();
    switch (tab) {
      case 0:
        // Current Tab
        if (map.getLayer('imdPoints')) {
          map.setLayoutProperty('imdPoints', 'visibility', 'visible');
        }
        if (map.getLayer('imdPointsHistorical')) {
          map.setLayoutProperty('imdPointsHistorical', 'visibility', 'none');
        }
        if (map.getSource('smapheatmap')) {
          map.setLayoutProperty('smapLayer', 'visibility', 'visible');
        }
        if (map.getSource('chirpsheatmap')) {
          map.setLayoutProperty('chirpsheatmap', 'visibility', 'none');
          map.setLayoutProperty('chirpsheatmapline', 'visibility', 'none');
        }
        if (map.getSource('noaaHeatmap')) {
          map.setLayoutProperty('noaaHeatmap', 'visibility', 'none');
          map.setLayoutProperty('noaaheatmapline', 'visibility', 'none');
        }
        if (map.getSource('gpmheatmap')) {
          map.setLayoutProperty('gpmheatmap', 'visibility', 'none');
          map.setLayoutProperty('gpmheatmapline', 'visibility', 'none');
        }
        if (map.getSource('spiheatmap')) {
          map.setLayoutProperty('spiheatmap', 'visibility', 'visible');
          map.setLayoutProperty('spiheatmapline', 'visibility', 'visible');
        }
        break;

      case 1:
        // Historical Tab
        if (map.getLayer('imdPoints')) {
          map.setLayoutProperty('imdPoints', 'visibility', 'none');
        }
        if (map.getLayer('imdPointsHistorical')) {
          map.setLayoutProperty('imdPointsHistorical', 'visibility', 'visible');
        }
        if (map.getSource('smapheatmap')) {
          map.setLayoutProperty('smapLayer', 'visibility', 'none');
        }
        if (map.getSource('chirpsheatmap')) {
          map.setLayoutProperty('chirpsheatmap', 'visibility', 'visible');
          map.setLayoutProperty('chirpsheatmapline', 'visibility', 'visible');
        }
        if (map.getSource('noaaHeatmap')) {
          map.setLayoutProperty('noaaHeatmap', 'visibility', 'visible');
          map.setLayoutProperty('noaaheatmapline', 'visibility', 'visible');
        }
        if (map.getSource('gpmheatmap')) {
          map.setLayoutProperty('gpmheatmap', 'visibility', 'visible');
          map.setLayoutProperty('gpmheatmapline', 'visibility', 'visible');
        }
        if (map.getSource('spiheatmap')) {
          map.setLayoutProperty('spiheatmap', 'visibility', 'none');
          map.setLayoutProperty('spiheatmapline', 'visibility', 'none');
        }
        break;

      default:
        break;
    }
    this.setState({
      activeWeatherTab: tab,
    });
    if (broadcastWeatherTabChangeInParent) {
      broadcastWeatherTabChangeInParent(tab);
    }
  };

  updateImdData = () => {
    const { location, fetchImdData, resetDetails } = this.props;

    resetDetails(0);
    const data = {};
    data.districtID = location.selectedDistrictID;
    fetchImdData(data);
  };

  updateImdHistoricalData = (startDate, endDate, timeSeries) => {
    console.log('imdHistorical', startDate, endDate, timeSeries);
    const { fetchImdHistoricalData, location, resetDetails } = this.props;
    resetDetails(1);
    const data = {};
    data.districtID = location.selectedDistrictID;
    data.dateFrom = startDate
      .split('-')
      .reverse()
      .join('-');
    data.dateTo = endDate
      .split('-')
      .reverse()
      .join('-');
    data.timeSeries = timeSeries;
    fetchImdHistoricalData(data);
  };

  render() {
    const { locationObject, activeWeatherTab } = this.state;
    const tabsData = [
      {
        tabName: 'Currrent & Forecast',
        component: (
          <WeatherSelectTab
            {...this.props}
            locationObject={locationObject}
            updateImdData={this.updateImdData}
            activeWeatherTab={activeWeatherTab}
          />
        ),
      },
      {
        tabName: 'Historical',
        component: (
          <WeatherHistoricalTab
            {...this.props}
            locationObject={locationObject}
            updateImdHistoricalData={this.updateImdHistoricalData}
            activeWeatherTab={activeWeatherTab}
          />
        ),
      },
    ];
    return (
      <ScrollableTabPanel
        tabsData={tabsData}
        customStyle="innerTabPanel"
        renderOnce
        broadcastTabChange={this.broadcastWeatherTabChange}
      />
    );
  }
}

export default withRouter(
  connect(
    ({ location, weather }) => ({
      location,
      imdData: weather.imdData,
      imdHistoricalData: weather.imdHistoricalData,
      smapHeatmap: weather.smapHeatmap,
      noaaHeatmap: weather.noaaHeatmap,
      gpmHeatmap: weather.gpmHeatmap,
      chirpsHeatmap: weather.chirpsHeatmap,
      spiHeatmap: weather.spiHeatmap,
    }),
    dispatch => ({
      resetDetails: () => dispatch(resetWeatherSourceDetails()),
      fetchImdData: data => dispatch(getImdData(data)),
      fetchImdHistoricalData: data => dispatch(getHistoricalImdData(data)),
    }),
  )(withCookies(Weather)),
);
