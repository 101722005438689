import React from 'react';
// import {
//   Typography,
//   Container,
//   Grid,
//   TextField,
//   Button,
// } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import GiSAccountHome from './gisAccountHome';
import GisAccountMission from './gisAccountMission';
import GisWelcomeOnBoard from './gisWelcomeOnBoard';
// import '../../css/button.scss';
// import '../../css/leftPanel.scss';

export default class AccountSetup extends React.Component {
  _updateStep = () => {};

  _renderStep = () => (
    <Switch>
      <Route
        path="/setup/step1"
        render={() => <GiSAccountHome updateStep={this._updateStep} />}
      />
      <Route
        path="/setup/step2"
        render={() => <GisAccountMission updateStep={this._updateStep} />}
      />
      <Route
        path="/setup/step3"
        render={() => <GisWelcomeOnBoard updateStep={this._updateStep} />}
      />
    </Switch>
  );

  render() {
    return <React.Fragment>{this._renderStep()}</React.Fragment>;
  }
}
