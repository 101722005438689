/* eslint-disable max-len */
import React from 'react';
import {
  FormControl, Grid, MenuItem, Select, Button,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import '../../../css/agricultureIndices.scss';
import '../../../css/survey.scss';
import CCESurveyReport from './CCESurveyReport';
import IndividualClaimsReport from './IndividualClaimsReport';
import InsuredCropTabReport from './InsuredCropTabReport';
import SowingSurveyReport from './SowingSurveyReport';
import WeatherStationReport from './WeatherStationReport';
import ApplicationSurveyReport from './ApplicationSurveyReport';
import CropHealthInspection from './CropHealthInspection';

import {
  getSurveyListAct,
  getProductListAct,
  getSurveyCropListAct,
  getAgentListAct,
  getSurveyHierarchyDataAct,
  setSurveyLocationAct,
  getLocationWiseCCECountAct,
  getLocationWiseCropVerificationCountAct,
  getLocationWiseCropLossCountAct,
  getLocationWiseApplicationSourcingCountAct,
  getLocationWiseSowingSurveyCountAct,
  getLocationWiseWeatherSurveyCountAct,
  resetCCEDataAct,
  setSelectedSurveyAct,
  setSurveyYearAct,
  setSurveySeasonAct,
  setSurveyProductAct,
  setSurveyCropAct,
  downloadCCEFileAct,
  uploadCCESurveyFileAct,
  getCCEMISReportAct,
  getCCEExperimentListAct,
  getInsuredCropExperimentListAct,
  getCropLossExperimentListAct,
  getApplicationSourcingExperimentListAct,
  getSowingSurveyExperimentListAct,
  getWeatherStationExperimentListAct,
  showPopupAction,
  getCCEExperimentDetails,
  misCCEReportlistAct,
  misCCEReportSubmitAct,
  uploadCropVerificationSurveyFileAct,
  downloadCropVerificationFileAct,
  getCropVerificationMISReportAct,
  uploadCropLossSurveyFileAct,
  downloadCropLossFileAct,
  getCropLossMISReportAct,
  uploadAppSourcingSurveyFileAct,
  downloadAppSourcingFileAct,
  getAppSourcingMISReportAct,
  uploadSowingSurveyFileAct,
  downloadSowingFileAct,
  getSowingMISReportAct,
  uploadWeatherSurveyFileAct,
  downloadWeatherFileAct,
  getWeatherMISReportAct,
  getApplicationSourcingExperimentDetailsAct,
  getCropLossExperimentDetailsAct,
  getInsuredCropExperimentDetailsAct,
  getSowingSurveyExperimentDetailsAct,
  getWeatherSurveyExperimentDetailsAct,
  uploadCropHealthSurveyFileAct,
  downloadCropHealthFileAct,
  getLocationWiseCropHealthSurveyCountAct,
  getCropHealthMISReportAct,
  getCropHealthExperimentListAct,
  getCropHealthSurveyExperimentDetailsAct,
} from '../../../Actions/surveyAction';
import { objectToCsv, download } from '../../../Functions/helper';

function createData(name, description, createdAt, id) {
  return {
    name,
    description,
    createdAt,
    id,
  };
}

class SurveySelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      isGenerate: false,
      expandPanel: false,
      selectedAgent: '',
      error: '',
      selectedFile: null,
      misReportType: '',
      experimentPage: 0,
      expRowsPerPage: 5,
      /* rowsPerPage: 10, */
    };
  }

  componentDidMount() {
    const { getSurveyList, getProductList, getSurveyHierarchy } = this.props;
    getSurveyHierarchy('level_2', 'level_2');
    getSurveyHierarchy('ic', 'level_2');
    getSurveyList();
    getProductList();
  }

  componentDidUpdate(prevProps) {
    const { experimentList } = this.props;
    if (prevProps.experimentList.length === 0 && experimentList.length > 0) {
      console.log('PrevProps .>>>> ', prevProps, this.props);
      const { getMapReference } = this.props;
      const map = getMapReference();
      // console.log("Map >> ", map);
      const size = 100;
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        onAdd() {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

        render() {
          const duration = 1000;
          const t = (window.performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const { context } = this;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2,
          );
          context.fillStyle = `rgba(207, 255, 100,${1 - t})`;
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = 'rgba(207, 255, 0, 1)';
          context.strokeStyle = 'white';
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // keep the map repainting
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        },
      };

      map.addImage('pulsing-dot-survey', pulsingDot, { pixelRatio: 2 });
      const geojson = {
        type: 'FeatureCollection',
        features: experimentList.map((item) => {
          if (item.lat && item.lng) {
            let { lat, lng } = item;
            lat = typeof lat === 'string' ? parseFloat(lat) : lat;
            lng = typeof lng === 'string' ? parseFloat(lng) : lng;
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
              properties: {
                ...item,
              },
            };
          }
          return null;
        }),
      };
      map.addLayer(
        {
          id: 'surveyExperimentsPoints',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: geojson,
          },
          layout: {
            'icon-image': 'pulsing-dot-survey',
            visibility: 'visible',
          },
        },
        'level3Fill',
      );
      console.log(
        'Map >>  layer Added',
        map.getLayer('surveyExperimentsPoints'),
      );
      map.on('click', 'surveyExperimentsPoints', this.handlePopupMarkerClick);
    }
    if (prevProps.experimentList.length > 0 && experimentList.length === 0) {
      const { getMapReference } = this.props;
      const map = getMapReference();
      if (map) {
        if (map.getLayer('surveyExperimentsPoints')) {
          map.off(
            'click',
            'surveyExperimentsPoints',
            this.handlePopupMarkerClick,
          );
          map.removeImage('pulsing-dot-survey');
          map.removeLayer('surveyExperimentsPoints');
          map.removeSource('surveyExperimentsPoints');
        }
      }
    }
  }

  handlePopupMarkerClick = (e) => {
    console.log('Point Clicked >>', e.features[0]);
    // showPopupFunc(e.features[0].properties.id);
    const {
      experimentList,
      getMapReference,
      showPopup,
      getCCEPopupDetails,
      getApplicationSourcingExperimentDetails,
      getCropLossExperimentDetails,
      getInsuredCropExperimentDetails,
      getSowingSurveyExperimentDetails,
      getWeatherSurveyExperimentDetails,
      getCropHealthSurveyExperimentDetails,
      selectedSurveyName,
    } = this.props;
    const experimentIndex = experimentList.findIndex(
      o => o.experimentNumber === e.features[0].properties.experimentNumber,
    );
    const applicationIndex = experimentList.findIndex(
      o => o.applicationNo === e.features[0].properties.applicationNo,
    );
    if (experimentIndex !== -1 || applicationIndex !== -1) {
      const map = getMapReference();
      let { lat, lng } = e.features[0].properties;
      if (lat && lng) {
        lat = typeof lat === 'string' ? parseFloat(lat) : lat;
        lng = typeof lng === 'string' ? parseFloat(lng) : lng;
        map.flyTo({
          center: [lng, lat + 0.025],
          zoom: 12,
          bearing: 0,
          speed: 1, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing(t) {
            return t;
          },
        });
        switch (selectedSurveyName) {
          case 'CCE':
            getCCEPopupDetails(e.features[0].properties.experimentNumber);
            showPopup(experimentIndex);
            break;
          case 'Application Sourcing':
            getApplicationSourcingExperimentDetails(
              e.features[0].properties.applicationNo,
            );
            showPopup(experimentIndex);
            break;
          case 'Individual Crop Loss Assessment':
            getCropLossExperimentDetails(
              e.features[0].properties.applicationNo,
            );
            showPopup(applicationIndex);
            break;
          case 'Insured Crop Verification':
            getInsuredCropExperimentDetails(
              e.features[0].properties.applicationNo,
            );
            showPopup(applicationIndex);
            break;
          case 'Sowing Survey':
            getSowingSurveyExperimentDetails(
              e.features[0].properties.experimentNumber,
            );
            showPopup(experimentIndex);
            break;
          case 'Weather Station Inspection':
            getWeatherSurveyExperimentDetails(
              e.features[0].properties.applicationNo,
            );
            showPopup(applicationIndex);
            break;
          case 'Crop Health Inspection':
            getCropHealthSurveyExperimentDetails(
              e.features[0].properties.applicationNo,
            );
            showPopup(applicationIndex);
            break;
          default:
            break;
        }
      }
    }
  };

  downloadFileType = (levelID, type) => {
    const {
      downloadFile,
      levelSelected,
      downloadCropVerificationFile,
      downloadSourcingFile,
      downloadSowingFile,
      downloadWeatherFile,
      downloadCropLossFile,
      downloadCropHealthFile,
    } = this.props;
    let nextLevel = '';
    if (levelSelected === 'level_2') {
      nextLevel = 'level_3';
    }
    if (levelSelected === 'level_3') {
      nextLevel = 'level_4';
    }
    if (levelSelected === 'level_4') {
      nextLevel = 'level_5';
    }
    if (levelSelected === 'level_5') {
      nextLevel = 'level_6';
    }
    if (levelSelected === 'level_6') {
      nextLevel = 'level_7';
    }
    if (type === 'CCE') {
      const downloadData = {
        parentLevelID: levelID,
        cce_level: nextLevel,
      };
      downloadFile(downloadData);
    }
    if (type === 'Crop Health Inspection') {
      const downloadCropData = {
        parentLevelID: levelID,
        level: nextLevel,
      };
      downloadCropHealthFile(downloadCropData);
    }
    const data = {
      levelID,
      level: levelSelected,
    };
    if (type === 'Insured Crop Verification') {
      downloadCropVerificationFile(data);
    }
    if (type === 'Individual Crop Loss Assessment') {
      downloadCropLossFile(data);
    }
    if (type === 'Application Sourcing') {
      const downloadData = {
        parentLevelID: levelID,
        level: nextLevel,
      };
      downloadSourcingFile(downloadData);
    }
    if (type === 'Sowing Survey') {
      const downloadData = {
        parentLevelID: levelID,
        level: nextLevel,
      };
      downloadSowingFile(downloadData);
    }
    if (type === 'Weather Station Inspection') {
      downloadWeatherFile(data);
    }
  };

  fetchExperimentList = () => {
    const { experimentPage } = this.state;
    const {
      getCCEExperimentList,
      getInsuredCropExperimentList,
      getCropLossExperimentList,
      getApplicationSourcingExperimentList,
      getSowingSurveyExperimentList,
      getWeatherStationExperimentList,
      getCropHealthExperimentList,
      levelSelected,
      selectedYear,
      selectedSeason,
      selectedSurveyName,
    } = this.props;
    const levelKey = `selected${_.capitalize(
      levelSelected.split('_').join(''),
    )}`;
    // eslint-disable-next-line react/destructuring-assignment
    const levelID = this.props[levelKey];
    console.log('levelID', levelID); // remove hard code levelId once clear from backend
    const params = {
      levelID, // 7F0257BB-FD4D-456C-85F0-E5A9859F3BF6
      level: levelSelected,
      offset: experimentPage * 5,
      limit: 5,
      year: selectedYear,
      season: selectedSeason === 'Rabi' ? 2 : 1,
    };
    if (selectedSurveyName === 'CCE') {
      getCCEExperimentList({
        ...params,
        // levelID: '7F0257BB-FD4D-456C-85F0-E5A9859F3BF6',
        cce_stage: 'stage_2',
      });
    }
    if (selectedSurveyName === 'Insured Crop Verification') {
      getInsuredCropExperimentList(params);
    }
    if (selectedSurveyName === 'Individual Crop Loss Assessment') {
      getCropLossExperimentList(params);
    }
    if (selectedSurveyName === 'Application Sourcing') {
      getApplicationSourcingExperimentList(params);
    }
    if (selectedSurveyName === 'Sowing Survey') {
      getSowingSurveyExperimentList(params);
    }
    if (selectedSurveyName === 'Weather Station Inspection') {
      getWeatherStationExperimentList(params);
    }
    if (selectedSurveyName === 'Crop Health Inspection') {
      getCropHealthExperimentList(params);
    }
  };

  _generateData = (ev) => {
    ev.preventDefault();
    const {
      levelSelected,
      selectedLevel2,
      getLocationWiseCCECount,
      getLocationWiseCropVerificationCount,
      getLocationWiseCropLossCount,
      getLocationWiseApplicationSourcingCount,
      getLocationWiseSowingSurveyCount,
      getLocationWiseWeatherSurveyCount,
      getLocationWiseCropHealthSurveyCount,
      selectedYear,
      selectedSeason,
      selectedSurveyID,
      selectedSurveyName,
      getMisReportList,
    } = this.props;
    let error = '';
    let check = true;
    if (!selectedLevel2) {
      check = false;
      error = 'please select state';
    }
    if (!selectedSeason) {
      check = false;
      error = 'please select season';
    }
    if (!selectedYear) {
      check = false;
      error = 'please select year';
    }
    if (!selectedSurveyID) {
      check = false;
      error = 'Please select survey';
    }
    if (check) {
      const levelKey = `selected${_.capitalize(
        levelSelected.split('_').join(''),
      )}`;
      // eslint-disable-next-line react/destructuring-assignment
      const levelID = this.props[levelKey];
      const params = {
        levelID,
        level: levelSelected,
        year: selectedYear,
        season: selectedSeason === 'Rabi' ? 2 : 1,
      };
      const misData = {
        survey_id: selectedSurveyID,
      };
      if (selectedSurveyName === 'CCE') {
        getLocationWiseCCECount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'CCE');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Insured Crop Verification') {
        getLocationWiseCropVerificationCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Insured Crop Verification');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Individual Crop Loss Assessment') {
        getLocationWiseCropLossCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Individual Crop Loss Assessment');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Application Sourcing') {
        getLocationWiseApplicationSourcingCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Application Sourcing');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Sowing Survey') {
        getLocationWiseSowingSurveyCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Sowing Survey');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Weather Station Inspection') {
        getLocationWiseWeatherSurveyCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Weather Station Inspection');
        getMisReportList(misData);
      }
      if (selectedSurveyName === 'Crop Health Inspection') {
        getLocationWiseCropHealthSurveyCount(params);
        this.fetchExperimentList();
        this.downloadFileType(levelID, 'Crop Health Inspection');
        getMisReportList(misData);
      }
      this.setState({
        isGenerate: true,
        error: '',
      });
    } else {
      this.setState({
        isGenerate: false,
        error,
      });
    }
  };

  _resetLocation = (ev) => {
    ev.preventDefault();
    const { resetCCEData } = this.props;
    resetCCEData();
    this.setState({
      isGenerate: false,
      selectedFile: null,
      misReportType: '',
      experimentPage: 0,
      page: 0,
      expRowsPerPage: 5,
      rowsPerPage: 5,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleChangeExperimentPage = (event, newPage) => {
    this.setState(
      {
        experimentPage: newPage,
      },
      () => {
        this.fetchExperimentList();
      },
    );
  };

  handleChangeExpRowsPerPage = (event) => {
    this.setState({
      expRowsPerPage: parseInt(event.target.value, 10),
      experimentPage: 0,
    });
  };

  handleSurveySelection = (ev) => {
    const { setSelectedSurvey, surveyListData } = this.props;
    const surveyID = ev.target.value;
    const survey = surveyListData.find(o => o.surveyID === ev.target.value);
    setSelectedSurvey(surveyID, survey ? survey.surveyName : '');
  };

  handleYearSelection = (ev) => {
    const { setSurveyYear } = this.props;
    setSurveyYear(ev.target.value);
  };

  handleSeasonSelection = (ev) => {
    const { setSurveySeason } = this.props;
    setSurveySeason(ev.target.value);
  };

  fetchAgentListByLevel = (levelID, level) => {
    const { getAgentList } = this.props;
    const params = {
      levelID,
      level,
    };
    getAgentList(params);
  };

  handleProductSelection = (ev) => {
    const { setSurveyProduct, productListData } = this.props;
    const productID = ev.target.value;
    const product = productListData.find(o => o.productID === ev.target.value);
    setSurveyProduct(productID, product ? product.productName : '');
  };

  handleCropSelection = (ev) => {
    const { setSurveyCrop, surveyCropListData } = this.props;
    const cropID = ev.target.value;
    const crop = surveyCropListData.find(o => o.cropID === ev.target.value);
    setSurveyCrop(cropID, crop ? crop.cropName : '');
  };

  handleAgentSelection = (ev) => {
    this.setState({
      selectedAgent: ev.target.value,
    });
  };

  handleFileSelect = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  handleDownload = () => {
    const { downloadedData } = this.props;
    const csvData = downloadedData.length > 0 && objectToCsv(downloadedData);
    download(csvData);
  };

  handleUpload = () => {
    const {
      uploadSurveyFile,
      selectedSurveyName,
      uploadCropVerificationFile,
      uploadCropLossFile,
      uploadSowingFile,
      uploadWeatherFile,
      uploadSourcingFile,
      uploadCropHealthSurveyFile,
    } = this.props;
    const { selectedFile } = this.state;
    const formData = new FormData();
    formData.append('file', selectedFile);
    if (selectedFile && selectedSurveyName === 'CCE') {
      uploadSurveyFile(formData);
    }
    if (selectedFile && selectedSurveyName === 'Insured Crop Verification') {
      uploadCropVerificationFile(formData);
    }
    if (
      selectedFile
      && selectedSurveyName === 'Individual Crop Loss Assessment'
    ) {
      uploadCropLossFile(formData);
    }
    if (selectedFile && selectedSurveyName === 'Application Sourcing') {
      uploadSourcingFile(formData);
    }
    if (selectedFile && selectedSurveyName === 'Sowing Survey') {
      uploadSowingFile(formData);
    }
    if (selectedFile && selectedSurveyName === 'Weather Station Inspection') {
      uploadWeatherFile(formData);
    }
    if (selectedFile && selectedSurveyName === 'Crop Health Inspection') {
      uploadCropHealthSurveyFile(formData);
    }
  };

  handleMISReport = (ev) => {
    this.setState({
      misReportType: ev.target.value,
    });
  };

  onReportDownload = (item) => {
    const {
      getMISREPORT,
      selectedYear,
      selectedSeason,
      getCropVerificationMisReport,
      selectedSurveyName,
      getCropLossMisReport,
      getSowingMisReport,
      getSourcingMisReport,
      getWeatherMisReport,
      getCropHealthMisReport,
    } = this.props;
    const data = {
      levelID: item.levelID,
      level: item.level,
      reportType: item.reportType,
      year: selectedYear,
      season: selectedSeason === 'Rabi' ? 2 : 1,
      surveyType: item.surveyType,
      startDate: item.startDate,
      endDate: item.endDate,
    };
    if (selectedSurveyName === 'CCE') {
      getMISREPORT(data);
    }
    if (selectedSurveyName === 'Insured Crop Verification') {
      getCropVerificationMisReport(data);
    }
    if (selectedSurveyName === 'Individual Crop Loss Assessment') {
      getCropLossMisReport(data);
    }
    if (selectedSurveyName === 'Application Sourcing') {
      getSourcingMisReport(data);
    }
    if (selectedSurveyName === 'Sowing Survey') {
      getSowingMisReport(data);
    }
    if (selectedSurveyName === 'Weather Station Inspection') {
      getWeatherMisReport(data);
    }
    if (selectedSurveyName === 'Crop Health Inspection') {
      getCropHealthMisReport(data);
    }
  };

  handleSubmitMisReport = () => {
    const {
      submitMisReport,
      selectedSurveyID,
      selectedSeason,
      selectedYear,
      levelSelected,
      level3Name,
      level4Name,
      level2Name,
      selectedLevel2,
    } = this.props;
    const { misReportType } = this.state;
    const levelKey = `selected${_.capitalize(
      levelSelected.split('_').join(''),
    )}`;
    // eslint-disable-next-line react/destructuring-assignment
    const levelID = this.props[levelKey];
    if (misReportType !== 'bulk') {
      const data = {
        survey_id: selectedSurveyID,
        season: selectedSeason === 'Rabi' ? 2 : 1,
        year: selectedYear,
        level: levelSelected,
        levelName: levelSelected === 'level_3' ? level3Name : level4Name,
        levelTitle: levelSelected === 'level_3' ? 'District' : 'Tehsil',
        levelID,
        reportType: misReportType,
      };
      submitMisReport(data);
    }
    if (misReportType === 'bulk') {
      const data = {
        survey_id: selectedSurveyID,
        season: selectedSeason === 'Rabi' ? 2 : 1,
        year: selectedYear,
        level: 'level_2',
        levelName: level2Name,
        levelTitle: 'State',
        levelID: selectedLevel2,
        reportType: misReportType,
      };
      submitMisReport(data);
    }
  };

  handleLocation = (event, level) => {
    const {
      setSurveyLocation,
      getSurveyHierarchy,
      selectedIc,
      selectedLevel2,
      selectedCluster,
      getMapReference,
      level2,
      ic,
      cluster,
      level3,
      level4,
      level5,
      level6,
      level7,
      getCropList,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    let levelObj;
    switch (level) {
      case 'level_2': {
        levelObj = level2.find(o => o.stateID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.stateName : '',
        );
        this.fetchAgentListByLevel(event.target.value, 'level_2');
        if (selectedIc) {
          if (selectedCluster) {
            getSurveyHierarchy('level_3', 'level_2', event.target.value);
          } else {
            getSurveyHierarchy(
              'cluster',
              'level_2',
              event.target.value,
              selectedIc,
            );
          }
        } else {
          getSurveyHierarchy('level_3', 'level_2', event.target.value);
        }
        break;
      }
      case 'ic':
        levelObj = ic.find(o => o.insuranceCompanyID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.shortName : '',
        );
        if (selectedLevel2) {
          if (event.target.value !== '') {
            getSurveyHierarchy(
              'cluster',
              'level_2',
              selectedLevel2,
              event.target.value,
            );
          } else {
            getSurveyHierarchy('level_3', 'level_2', selectedLevel2);
          }
        }
        break;
      case 'cluster':
        levelObj = cluster.find(o => o.clusterID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.clusterName : '',
        );
        if (selectedIc) {
          getSurveyHierarchy(
            'cluster_level_3',
            'level_2',
            event.target.value,
            selectedIc,
          );
        }
        break;
      case 'level_3':
        levelObj = level3.find(o => o.districtID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.districtName : '',
        );
        getSurveyHierarchy('level_4', 'level_3', event.target.value);
        this.fetchAgentListByLevel(event.target.value, 'level_3');
        // eslint-disable-next-line no-case-declarations
        const map = getMapReference();
        // eslint-disable-next-line no-case-declarations
        const params = {
          districtID: levelObj.districtID,
        };
        getCropList(params);
        if (
          map
          && levelObj.districtCentroidLatitude
          && levelObj.districtCentroidLongitude
        ) {
          map.flyTo({
            center: [
              parseFloat(levelObj.districtCentroidLongitude),
              parseFloat(levelObj.districtCentroidLatitude),
            ],
            zoom: 9,
            bearing: 0,
            speed: 1, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing(t) {
              return t;
            },
          });
        }
        break;

      case 'level_4':
        levelObj = level4.find(o => o.tehsilID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.tehsilName : '',
        );
        getSurveyHierarchy('level_5', 'level_4', event.target.value);
        this.fetchAgentListByLevel(event.target.value, 'level_4');
        break;
      case 'level_5':
        levelObj = level5.find(o => o.level5ID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.level5Name : '',
        );
        getSurveyHierarchy('level_6', 'level_5', event.target.value);
        this.fetchAgentListByLevel(event.target.value, 'level_5');
        break;
      case 'level_6':
        levelObj = level6.find(o => o.level6ID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.level6Name : '',
        );
        getSurveyHierarchy('level_7', 'level_6', event.target.value);
        this.fetchAgentListByLevel(event.target.value, 'level_6');
        break;
      case 'level_7':
        levelObj = level7.find(o => o.level7ID === event.target.value);
        setSurveyLocation(
          level,
          event.target.value,
          levelObj ? levelObj.level7Name : '',
        );
        this.fetchAgentListByLevel(event.target.value, 'level_7');
        break;
      default:
        break;
    }
  };

  handlePreviewClick = (experimentData, experimentIndex) => {
    const {
      getMapReference,
      showPopup,
      getCCEPopupDetails,
      getApplicationSourcingExperimentDetails,
      getCropLossExperimentDetails,
      getInsuredCropExperimentDetails,
      getSowingSurveyExperimentDetails,
      getWeatherSurveyExperimentDetails,
      getCropHealthSurveyExperimentDetails,
      selectedSurveyName,
    } = this.props;
    let { lat, lng } = experimentData;
    const map = getMapReference();
    if (map) {
      if (lat && lng) {
        lat = typeof lat === 'string' ? parseFloat(lat) : lat;
        lng = typeof lng === 'string' ? parseFloat(lng) : lng;
        map.flyTo({
          center: [lng, lat + 0.025],
          zoom: 12,
          bearing: 0,
          speed: 1, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing(t) {
            return t;
          },
        });
        switch (selectedSurveyName) {
          case 'CCE':
            getCCEPopupDetails(experimentData.experimentNumber);
            break;
          case 'Application Sourcing':
            getApplicationSourcingExperimentDetails(
              experimentData.applicationNo,
            );
            break;
          case 'Individual Crop Loss Assessment':
            getCropLossExperimentDetails(experimentData.applicationNo);
            break;
          case 'Insured Crop Verification':
            getInsuredCropExperimentDetails(experimentData.applicationNo);
            break;
          case 'Sowing Survey':
            getSowingSurveyExperimentDetails(experimentData.experimentNumber);
            break;
          case 'Weather Station Inspection':
            getWeatherSurveyExperimentDetails(experimentData.experimentNumber);
            break;
          case 'Crop Health Inspection':
            getCropHealthSurveyExperimentDetails(
              experimentData.experimentNumber,
            );
            break;
          default:
            break;
        }
        showPopup(experimentIndex);
      }
    }
  };

  render() {
    const {
      isGenerate,
      expandPanel,
      rowsPerPage,
      page,
      selectedAgent,
      error,
      misReportType,
      experimentPage,
      expRowsPerPage,
      selectedFile,
    } = this.state;
    const {
      level2,
      selectedLevel2,
      ic,
      selectedIc,
      icName,
      cluster,
      clusterName,
      selectedCluster,
      level3,
      selectedLevel3,
      level4,
      selectedLevel4,
      level5,
      selectedLevel5,
      level6,
      selectedLevel6,
      level7,
      selectedLevel7,
      level2Name,
      level3Name,
      level4Name,
      level5Name,
      level6Name,
      level7Name,
      surveyListData,
      productListData,
      surveyCropListData,
      agentListData,
      locationWiseCount,
      selectedSeason,
      selectedYear,
      selectedProductID,
      selectedProductName,
      selectedCropID,
      selectedCropName,
      selectedSurveyName,
      selectedSurveyID,
      uploadFileStatus,
      experimentList,
      misReportListData,
      misReportSubmit,
      misDownloadReportData,
      experimentListCount,
    } = this.props;

    /* let yearHtml = [];
       yearHtml = [2016, 2017, 2018, 2019].map(item => (
       <MenuItem value={item} key={item}>
       {item}
       </MenuItem>
       )); */

    /*   let seasonHtml = [];
       seasonHtml = [
       { label: 'Kharif', value: 1 },
       { label: 'Rabi', value: 2 },
       // { label: 'Zaid', value: 3 },
       ].map(item => (
       <MenuItem value={item.value} key={item.label}>
       {item.label}
       </MenuItem>
       )); */
    const { rows, count } = [];
    const totalCount = Number(count);
    const tableRows = [];
    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i += 1) {
        tableRows.push(
          createData(
            rows[i].advisoryName,
            rows[i].description,
            rows[i].createdAt,
            rows[i].id,
          ),
        );
      }
    }
    const surveyListHtml = [];
    if (surveyListData && surveyListData.length > 0) {
      surveyListData.forEach((item) => {
        surveyListHtml.push(
          <MenuItem key={item.surveyID} value={item.surveyID}>
            {item.surveyName}
          </MenuItem>,
        );
      });
    }
    const statesHtml = [];
    if (level2 && level2.length) {
      level2.forEach((item) => {
        statesHtml.push(
          <MenuItem key={item.stateID} value={item.stateID}>
            {item.stateName}
          </MenuItem>,
        );
      });
    }
    const icHTML = [];
    if (ic && ic.length) {
      ic.forEach((item) => {
        icHTML.push(
          <MenuItem
            key={item.insuranceCompanyID}
            value={item.insuranceCompanyID}
          >
            {item.insuranceCompanyName}
          </MenuItem>,
        );
      });
    }
    const clusterHTML = [];
    if (cluster && cluster.length) {
      cluster.forEach((item) => {
        clusterHTML.push(
          <MenuItem key={item.clusterID} value={item.clusterID}>
            {item.clusterName}
          </MenuItem>,
        );
      });
    }
    const level3HTML = [];
    if (level3 && level3.length) {
      level3.forEach((item) => {
        level3HTML.push(
          <MenuItem key={item.districtID} value={item.districtID}>
            {item.districtName}
          </MenuItem>,
        );
      });
    }
    const level4HTML = [];
    if (level4 && level4.length) {
      level4.forEach((item) => {
        level4HTML.push(
          <MenuItem key={item.tehsilID} value={item.tehsilID}>
            {item.tehsilName}
          </MenuItem>,
        );
      });
    }
    const level5HTML = [];
    if (level5 && level5.length) {
      level5.forEach((item) => {
        level5HTML.push(
          <MenuItem key={item.level5ID} value={item.level5ID}>
            {item.level5Name}
          </MenuItem>,
        );
      });
    }
    const level6HTML = [];
    if (level6 && level6.length) {
      level6.forEach((item) => {
        level6HTML.push(
          <MenuItem key={item.level6ID} value={item.level6ID}>
            {item.level6Name}
          </MenuItem>,
        );
      });
    }
    const level7HTML = [];
    if (level7 && level7.length) {
      level7.forEach((item) => {
        level7HTML.push(
          <MenuItem key={item.level7ID} value={item.level7ID}>
            {item.level7Name}
          </MenuItem>,
        );
      });
    }
    const productListHTML = [];
    if (productListData && productListData.length) {
      productListData.forEach((item) => {
        productListHTML.push(
          <MenuItem key={item.productID} value={item.productID}>
            {item.productName}
          </MenuItem>,
        );
      });
    }
    const cropListHTML = [];
    if (selectedLevel3 && surveyCropListData && surveyCropListData.length) {
      surveyCropListData.forEach((item) => {
        cropListHTML.push(
          <MenuItem key={item.cropID} value={item.cropID}>
            {item.cropName}
          </MenuItem>,
        );
      });
    }
    const agentHTML = [];
    if (agentListData && agentListData.length > 0) {
      agentListData.forEach((item) => {
        agentHTML.push(
          <MenuItem key={item.userID} value={item.userID}>
            {item.userName}
          </MenuItem>,
        );
      });
    }
    const { chart, countDetails } = locationWiseCount;
    return (
      <div>
        {isGenerate ? (
          <div className="indicesMainPanel">
            <div className={expandPanel ? 'indicesList expandPanel' : 'survey'}>
              <Grid container className="survey__head">
                <Grid item xs={2}>
                  <h4 className="survey__head__left">Survey</h4>
                </Grid>
                <Grid item xs={7} className="survey__head__list">
                  <ul>
                    <li>{selectedSurveyName}</li>
                    <li>{selectedYear}</li>
                    <li>{selectedSeason}</li>
                  </ul>
                </Grid>
                <Grid item xs={3} className="survey__head__right">
                  <a href="/" onClick={this._resetLocation}>
                    Edit Selection
                  </a>
                </Grid>
              </Grid>
              <Grid container className="survey__datalist">
                <ul>
                  <li>{icName}</li>
                  <li>{level2Name}</li>
                  <li>{clusterName}</li>
                  <li>{level3Name}</li>
                  <li>{level4Name || 'All Level 4'}</li>
                  <li>{level5Name || 'All Level 5'}</li>
                  <li>{level6Name || 'All Level 6'}</li>
                  <li>{level7Name || 'All Level 7'}</li>
                  <li>{selectedProductName || 'All Products'}</li>
                  <li>{selectedCropName || 'All Crops'}</li>
                  <li>All Agents</li>
                </ul>
              </Grid>
              <Grid container className="survey__Palette">
                <Grid item xs={12} className="survey__Palette--sky">
                  <h4>
                    Target farmers in the region through farmguide
                    {' '}
                    <a
                      href="/"
                      style={{ pointerEvents: 'none', cursor: 'default' }}
                    >
                      Lead Generator
                    </a>
                  </h4>
                </Grid>
                <Grid item xs={12} className="survey__Palette--grey">
                  <h4>
                    <span>Graphical Analysis</span>
                  </h4>
                </Grid>
              </Grid>
              {selectedSurveyName === 'CCE' && (
                <CCESurveyReport
                  chart={chart}
                  countDetails={countDetails}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  handleUpload={this.handleUpload}
                  handleFileSelected={this.handleFileSelect}
                  uploadFileStatus={uploadFileStatus}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  onReportDownload={this.onReportDownload}
                  experimentList={experimentList}
                  handlePreviewClick={this.handlePreviewClick}
                  misReportListData={misReportListData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportSubmit={misReportSubmit}
                  misDownloadReportData={misDownloadReportData}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  experimentListCount={experimentListCount}
                  selectedFile={selectedFile}
                />
              )}
              {selectedSurveyName === 'Application Sourcing' && (
                <ApplicationSurveyReport
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
              {selectedSurveyName === 'Crop Health Inspection' && (
                <CropHealthInspection
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
              {selectedSurveyName === 'Individual Crop Loss Assessment' && (
                <IndividualClaimsReport
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
              {selectedSurveyName === 'Insured Crop Verification' && (
                <InsuredCropTabReport
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
              {selectedSurveyName === 'Sowing Survey' && (
                <SowingSurveyReport
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
              {selectedSurveyName === 'Weather Station Inspection' && (
                <WeatherStationReport
                  chart={chart}
                  countDetails={countDetails}
                  totalCount={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleDownload={this.handleDownload}
                  misReportListData={misReportListData}
                  onReportDownload={this.onReportDownload}
                  misDownloadReportData={misDownloadReportData}
                  handleSubmitMisReport={this.handleSubmitMisReport}
                  misReportType={misReportType}
                  handleMISReport={this.handleMISReport}
                  misReportSubmit={misReportSubmit}
                  handleFileSelected={this.handleFileSelect}
                  selectedFile={selectedFile}
                  handleUpload={this.handleUpload}
                  uploadFileStatus={uploadFileStatus}
                  experimentPage={experimentPage}
                  expRowsPerPage={expRowsPerPage}
                  experimentList={experimentList}
                  experimentListCount={experimentListCount}
                  handleChangeExperimentPage={this.handleChangeExperimentPage}
                  handleChangeExpRowsPerPage={this.handleChangeExpRowsPerPage}
                  handlePreviewClick={this.handlePreviewClick}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="agriIndices selectController">
            <h4 className="tabpanelHeading">Survey</h4>
            <Grid item xs={12}>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  /* inputProps={{ 'aria-label': 'age' }} */
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleSurveySelection(ev)}
                  value={selectedSurveyID}
                  displayEmpty
                  disabled={!(surveyListData.length > 0)}
                >
                  <MenuItem value="">Select Survey</MenuItem>
                  {surveyListHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleYearSelection(ev)}
                  value={selectedYear}
                  displayEmpty
                >
                  <MenuItem value="">Year</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleSeasonSelection(ev)}
                  value={selectedSeason}
                  displayEmpty
                >
                  <MenuItem value="">Season</MenuItem>
                  <MenuItem value="Rabi">Rabi</MenuItem>
                  <MenuItem value="Kharif">Kharif</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  /* inputProps={{ 'aria-label': 'age' }} */
                  className="selectEmpty modalDropdown"
                  onChange={event => this.handleLocation(event, 'ic')}
                  value={selectedIc}
                  displayEmpty
                  disabled={!(ic.length > 0)}
                >
                  <MenuItem value="">Insurance Co.</MenuItem>
                  {icHTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={event => this.handleLocation(event, 'level_2')}
                  value={selectedLevel2}
                  displayEmpty
                  disabled={!(level2.length > 0)}
                >
                  <MenuItem value="">State</MenuItem>
                  {statesHtml}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  displayEmpty
                  onChange={event => this.handleLocation(event, 'cluster')}
                  value={selectedCluster}
                  disabled={!(cluster.length > 0)}
                >
                  <MenuItem value="">Cluster</MenuItem>
                  {clusterHTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={event => this.handleLocation(event, 'level_3')}
                  value={selectedLevel3}
                  displayEmpty
                  disabled={!(level3.length > 0)}
                >
                  <MenuItem value="">District</MenuItem>
                  {level3HTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  displayEmpty
                  value={selectedLevel4}
                  onChange={event => this.handleLocation(event, 'level_4')}
                  disabled={!(level4.length > 0)}
                >
                  <MenuItem value="">Level 4 name </MenuItem>
                  {level4HTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  value={selectedLevel5}
                  displayEmpty
                  onChange={event => this.handleLocation(event, 'level_5')}
                  disabled={!(level5.length > 0)}
                >
                  <MenuItem value="">Level 5 name </MenuItem>
                  {level5HTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  value={selectedLevel6}
                  displayEmpty
                  onChange={event => this.handleLocation(event, 'level_6')}
                  disabled={!(level6.length > 0)}
                >
                  <MenuItem value="">Level 6 name </MenuItem>
                  {level6HTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  value={selectedLevel7}
                  displayEmpty
                  onChange={event => this.handleLocation(event, 'level_7')}
                  disabled={!(level7.length > 0)}
                >
                  <MenuItem value="">Level 7 name </MenuItem>
                  {level7HTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleProductSelection(ev)}
                  value={selectedProductID}
                  displayEmpty
                  disabled={!(productListData.length > 0)}
                >
                  <MenuItem value="">All Products</MenuItem>
                  {productListHTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleCropSelection(ev)}
                  value={selectedCropID}
                  displayEmpty
                  disabled={!(surveyCropListData.length > 0)}
                >
                  <MenuItem value="">All Crops</MenuItem>
                  {cropListHTML}
                </Select>
              </FormControl>
              <FormControl className="formControl inputHead coloredForms">
                <Select
                  disableUnderline
                  className="selectEmpty modalDropdown"
                  onChange={ev => this.handleAgentSelection(ev)}
                  value={selectedAgent}
                  displayEmpty
                  disabled={!(agentListData.length > 0)}
                >
                  <MenuItem value="">All Agents</MenuItem>
                  {agentHTML}
                </Select>
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6} className="agriIndices btn">
                <Button
                  type="submit"
                  variant="contained"
                  className="btn-success floatRight"
                  onClick={this._generateData}
                >
                  Generate Data
                  <i className="material-icons">arrow_right_alt</i>
                </Button>
                {error ? (
                  <p className="errorMsg">
                    {' '}
                    {error}
                    {' '}
                  </p>
                ) : null}
              </Grid>
            </Grid>
          </div>
        )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ survey }) => ({
      surveyListData: survey.surveyListData,
      productListData: survey.productListData,
      surveyCropListData: survey.surveyCropListData,
      agentListData: survey.agentListData,
      levelSelected: survey.level_selected,
      level2: survey.level_2,
      level3: survey.level_3,
      selectedLevel2: survey.selected_level_2,
      level2Name: survey.level2Name,
      selectedLevel3: survey.selected_level_3,
      level3Name: survey.level3Name,
      ic: survey.ic,
      icName: survey.ic_name,
      selectedIc: survey.selected_ic,
      cluster: survey.cluster,
      clusterName: survey.cluster_name,
      selectedCluster: survey.selected_cluster,
      level4: survey.level_4,
      selectedLevel4: survey.selected_level_4,
      level4Name: survey.level4Name,
      level5: survey.level_5,
      selectedLevel5: survey.selected_level_5,
      level5Name: survey.level5Name,
      level6: survey.level_6,
      selectedLevel6: survey.selected_level_6,
      level6Name: survey.level6Name,
      level7: survey.level_7,
      selectedLevel7: survey.selected_level_7,
      level7Name: survey.level7Name,
      locationWiseCount: survey.locationWiseCount,
      selectedSeason: survey.selectedSeason,
      selectedYear: survey.selectedYear,
      selectedProductID: survey.selectedProductID,
      selectedProductName: survey.selectedProductName,
      selectedCropID: survey.selectedCropID,
      selectedCropName: survey.selectedCropName,
      selectedSurveyName: survey.selectedSurveyName,
      selectedSurveyID: survey.selectedSurveyID,
      downloadedData: survey.downloadedData,
      uploadFileStatus: survey.uploadFileStatus,
      misDownloadReportData: survey.misDownloadReportData,
      experimentList: survey.experimentList,
      misReportListData: survey.misReportListData,
      misReportSubmit: survey.misReportSubmit,
      experimentListCount: survey.experimentListCount,
    }),
    dispatch => ({
      getSurveyList: () => dispatch(getSurveyListAct()),
      getProductList: () => dispatch(getProductListAct()),
      getCropList: params => dispatch(getSurveyCropListAct(params)),
      getAgentList: params => dispatch(getAgentListAct(params)),
      getSurveyHierarchy: (fetchLevel, parentLevel, parentLevelID, icID) => dispatch(
        getSurveyHierarchyDataAct(
          fetchLevel,
          parentLevel,
          parentLevelID,
          icID,
        ),
      ),
      // eslint-disable-next-line max-len
      setSurveyLocation: (level, selectedLocation, levelName) => dispatch(setSurveyLocationAct(level, selectedLocation, levelName)),
      getLocationWiseCCECount: params => dispatch(getLocationWiseCCECountAct(params)),
      getLocationWiseCropVerificationCount: params => dispatch(getLocationWiseCropVerificationCountAct(params)),
      getLocationWiseCropLossCount: params => dispatch(getLocationWiseCropLossCountAct(params)),
      getLocationWiseApplicationSourcingCount: params => dispatch(getLocationWiseApplicationSourcingCountAct(params)),
      getLocationWiseSowingSurveyCount: params => dispatch(getLocationWiseSowingSurveyCountAct(params)),
      getLocationWiseWeatherSurveyCount: params => dispatch(getLocationWiseWeatherSurveyCountAct(params)),
      resetCCEData: () => dispatch(resetCCEDataAct()),
      setSelectedSurvey: (surveyID, surveyName) => dispatch(setSelectedSurveyAct(surveyID, surveyName)),
      setSurveyYear: year => dispatch(setSurveyYearAct(year)),
      setSurveySeason: season => dispatch(setSurveySeasonAct(season)),
      setSurveyProduct: (productID, productName) => dispatch(setSurveyProductAct(productID, productName)),
      setSurveyCrop: (cropID, cropName) => dispatch(setSurveyCropAct(cropID, cropName)),
      downloadFile: params => dispatch(downloadCCEFileAct(params)),
      uploadSurveyFile: data => dispatch(uploadCCESurveyFileAct(data)),
      getMISREPORT: data => dispatch(getCCEMISReportAct(data)),
      getCCEExperimentList: params => dispatch(getCCEExperimentListAct(params)),
      getInsuredCropExperimentList: params => dispatch(getInsuredCropExperimentListAct(params)),
      getCropLossExperimentList: params => dispatch(getCropLossExperimentListAct(params)),
      getApplicationSourcingExperimentList: params => dispatch(getApplicationSourcingExperimentListAct(params)),
      getSowingSurveyExperimentList: params => dispatch(getSowingSurveyExperimentListAct(params)),
      getWeatherStationExperimentList: params => dispatch(getWeatherStationExperimentListAct(params)),
      showPopup: markerIndex => dispatch(showPopupAction(markerIndex)),
      getCCEPopupDetails: experimentNumber => dispatch(getCCEExperimentDetails(experimentNumber)),
      getApplicationSourcingExperimentDetails: applicationNo => dispatch(getApplicationSourcingExperimentDetailsAct(applicationNo)),
      getCropLossExperimentDetails: applicationNo => dispatch(getCropLossExperimentDetailsAct(applicationNo)),
      getInsuredCropExperimentDetails: applicationNo => dispatch(getInsuredCropExperimentDetailsAct(applicationNo)),
      getSowingSurveyExperimentDetails: applicationNo => dispatch(getSowingSurveyExperimentDetailsAct(applicationNo)),
      getWeatherSurveyExperimentDetails: applicationNo => dispatch(getWeatherSurveyExperimentDetailsAct(applicationNo)),
      getMisReportList: params => dispatch(misCCEReportlistAct(params)),
      submitMisReport: data => dispatch(misCCEReportSubmitAct(data)),
      uploadCropVerificationFile: data => dispatch(uploadCropVerificationSurveyFileAct(data)),
      downloadCropVerificationFile: params => dispatch(downloadCropVerificationFileAct(params)),
      getCropVerificationMisReport: data => dispatch(getCropVerificationMISReportAct(data)),
      uploadCropLossFile: data => dispatch(uploadCropLossSurveyFileAct(data)),
      downloadCropLossFile: params => dispatch(downloadCropLossFileAct(params)),
      getCropLossMisReport: data => dispatch(getCropLossMISReportAct(data)),
      uploadSourcingFile: data => dispatch(uploadAppSourcingSurveyFileAct(data)),
      downloadSourcingFile: params => dispatch(downloadAppSourcingFileAct(params)),
      getSourcingMisReport: data => dispatch(getAppSourcingMISReportAct(data)),
      uploadSowingFile: data => dispatch(uploadSowingSurveyFileAct(data)),
      downloadSowingFile: params => dispatch(downloadSowingFileAct(params)),
      getSowingMisReport: data => dispatch(getSowingMISReportAct(data)),
      uploadWeatherFile: data => dispatch(uploadWeatherSurveyFileAct(data)),
      downloadWeatherFile: params => dispatch(downloadWeatherFileAct(params)),
      getWeatherMisReport: data => dispatch(getWeatherMISReportAct(data)),
      uploadCropHealthSurveyFile: data => dispatch(uploadCropHealthSurveyFileAct(data)),
      downloadCropHealthFile: params => dispatch(downloadCropHealthFileAct(params)),
      getLocationWiseCropHealthSurveyCount: data => dispatch(getLocationWiseCropHealthSurveyCountAct(data)),
      getCropHealthMisReport: data => dispatch(getCropHealthMISReportAct(data)),
      getCropHealthExperimentList: params => dispatch(getCropHealthExperimentListAct(params)),
      getCropHealthSurveyExperimentDetails: applicationNo => dispatch(getCropHealthSurveyExperimentDetailsAct(applicationNo)),
    }),
  )(withCookies(SurveySelectTab)),
);
