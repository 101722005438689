import React from 'react';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import '../../../css/common/commonModal.scss';

const ModalHOC = (props) => {
  const {
    isOpen,
    onClose,
    titleContent,
    children,
    customClass,
    footerComponent,
    customContent,
    disablePortal,
  } = props;
  const customStyle = customClass || '';
  return (
    <Modal
      //  aria-labelledby="transition-modal-title"
      //  aria-describedby="transition-modal-description"
      disablePortal={disablePortal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={`modal-dialog ${customStyle}`}
    >
      <Fade in={isOpen}>
        <div className={`modal-content ${customContent}`}>
          <div className="modal-header">
            <h4 className="modal-title">{titleContent}</h4>
            <i
              role="button"
              tabIndex="0"
              onKeyDown={() => onClose()}
              className="material-icons"
              onClick={() => onClose()}
            >
              close
            </i>
          </div>
          <div className="modal-body">{children}</div>
          {footerComponent ? (
            <div className="modal-footer">{footerComponent}</div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalHOC;
