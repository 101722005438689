/* eslint-disable prefer-destructuring,no-nested-ternary */
import React from 'react';
import {
  Button,
  Grid,
  FormControl,
  Select as MaterialSelect,
  MenuItem,
} from '@material-ui/core';
import Select from 'react-select';
import bbox from '@turf/bbox';
import center from '@turf/center';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import '../../../css/agricultureIndices.scss';
import {
  getAccountsState,
  setAccountsLocationAct,
  getAccountsHierarchyAct,
  fetchMurabbaListAct,
  fetchKhasraKhataAct,
  fetchFarmOwnerAct,
  fetchFarmBoundaryAct,
  resetFarmAccountsAct,
  fetchFarmDetailsAct,
} from '../../../Actions/farmAccountsAction';
import LanguageContext from '../../common/languageContext';
import Loader from '../../common/customLoader';
import { languageConverter, fitLevelBounds } from '../../../Functions/helper';

let popup;
class MyAccountSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenerate: false,
      expandPanel: false,
      enabledStates: ['5c77da4f-bc9b-4099-bed7-15e06a45f376'],
      khataKhasraNo: '',
      murabbaNo: '',
      error: '',
    };
  }

  componentDidMount() {
    const { getStates, states } = this.props;
    if (states.length === 0) {
      getStates();
    } else {
      this.handleLocation(
        {
          target: '5c77da4f-bc9b-4099-bed7-15e06a45f376',
        },
        'STATE',
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      states,
      districts,
      tehsils,
      villages,
      farmBoundary,
      getMapReference,
      farmOwner,
      farmData,
      stateSelected,
      districtSelected,
      tehsilSelected,
      villageSelected,
    } = this.props;
    const { khataKhasraNo, murabbaNo } = this.state;
    if (_.isEmpty(prevProps.states) && !_.isEmpty(states)) {
      // Set Haryana State
      this.handleLocation(
        {
          target: {
            value: '5c77da4f-bc9b-4099-bed7-15e06a45f376',
          },
        },
        'STATE',
      );
    }

    if (_.isEmpty(prevProps.farmBoundary) && !_.isEmpty(farmBoundary)) {
      // Set FarmBoundary in Map
      const map = getMapReference();
      if (map) {
        console.log('Farm Boundary', farmBoundary);
        const geoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [farmBoundary],
              },
              properties: {
                farmOwner,
              },
            },
          ],
        };

        if (!map.getSource('farmaccountBoundary')) {
          const bounds = bbox(geoJSON);
          map.fitBounds(bounds, { padding: 20 });
          map.addSource('farmaccountBoundary', {
            type: 'geojson',
            data: geoJSON,
          });
          map.addLayer({
            id: 'farmaccountBoundary',
            type: 'fill',
            minzoom: 15,
            source: 'farmaccountBoundary',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': 'rgba(0,0,0,0)',
              'fill-opacity': 1,
              'fill-outline-color': '#f4df6e',
            },
          });
          map.addLayer({
            id: 'farmaccountBoundaryLine',
            type: 'line',
            source: 'farmaccountBoundary',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#f4df6e',
              'line-width': 4.5,
            },
          });

          map.off('click', 'farmaccountBoundary', this.farmClickFunction);
          map.on('click', 'farmaccountBoundary', this.farmClickFunction);
        }
      }
    }

    if (!_.isEmpty(prevProps.farmBoundary) && _.isEmpty(farmBoundary)) {
      // Reset FarmBoundary in Map
      const map = getMapReference();
      if (map) {
        if (map.getLayer('farmaccountBoundary')) {
          map.off('click', 'farmaccountBoundary', this.farmClickFunction);
          map.removeLayer('farmaccountBoundary');
          map.removeLayer('farmaccountBoundaryLine');
          map.removeSource('farmaccountBoundary');
        }
      }
    }

    if (_.isEmpty(prevProps.farmData) && !_.isEmpty(farmData)) {
      // Use Pop Here
      console.log('Farm Data ', farmData, farmBoundary);
      const map = getMapReference();
      if (map) {
        if (!_.isEmpty(farmBoundary)) {
          const geoJSON = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [farmBoundary],
                },
              },
            ],
          };
          const centerCoords = center(geoJSON);
          if (!_.isEmpty(centerCoords)) {
            const lngLat = centerCoords.geometry.coordinates;
            /* eslint-disable global-require */
            const mapboxgl = require('mapbox-gl');
            /* eslint-enable global-require */
            let stateName = '';
            let districtName = '';
            let tehsilName = '';
            let villageName = '';
            const stateObj = states.find(o => o.stateID === stateSelected);
            if (stateObj) {
              stateName = stateObj.stateName;
            }
            const districtObj = districts.find(
              o => o.districtCode === districtSelected,
            );
            if (districtObj) {
              districtName = districtObj.districtName;
            }
            const tehsilObj = tehsils.find(
              o => o.tehsilCode === tehsilSelected,
            );
            if (tehsilObj) {
              tehsilName = tehsilObj.tehsilName;
            }
            const villageObj = villages.find(
              o => o.villageCode === villageSelected,
            );
            if (villageObj) {
              villageName = villageObj.villageName;
            }
            let str = '';
            farmData.cropData.forEach((item, index) => {
              str += `<div className="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-3 MuiGrid-grid-xs-3">
                  <span>Segment ${index + 1}</span>
                  <ul>
                    <li>Year: ${item.year}</li>
                    <li>Season: ${
  item.Rabi !== -1
    ? 'Rabi'
    : item.Kharif !== -1
      ? 'Kharif'
      : 'NA'
}</li>
                    <li>Crop: ${
  item.Rabi !== -1
    ? item.rabiCrop
    : item.Kharif !== -1
      ? item.kharifCrop
      : 'NA'
}</li>
                    <li>Segment Area: ${(item.area * 0.0001).toFixed(3)}</li>
                    <li>Area Contained: ${(item.area * 0.0001).toFixed(3)}</li>
                    <li>Rating: ${item.Rating}</li>
                  </ul>
                </div>`;
            });
            console.log('Render String ', str);
            popup = new mapboxgl.Popup({ anchor: 'left' })
              .setLngLat({ lng: lngLat[0], lat: lngLat[1] })
              .setHTML(
                `<div class="farm-detail-card">
                   <div class="farm-detail-card-body">
                     <h5 class="farm-detail-card-title">Farm Details</h5>
                     <div class="MuiGrid-root MuiGrid-container">
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Khata/Khasra No.</h6>
                         <p>${khataKhasraNo}</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Murrabba No.</h6>
                         <p>${murabbaNo}</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Land Size</h6>
                         <p>${(farmData.area * 0.0001).toFixed(3)} Ha</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>State</h6>
                         <p>${stateName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>District</h6>
                         <p>${districtName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Tehsil</h6>
                         <p>${tehsilName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Village</h6>
                         <p>${villageName}</p>
                       </div>  
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>No of segment</h6>
                         <p>${farmData.cropData.length}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4"></div>
                     </div>
                     <div class=" MuiGrid-root MuiGrid-container card-m-t">
                         ${str}
                     </div>
                   </div>
                </div>`,
              )
              .addTo(map);
          }
          /* Farm Card HTML */
        }
      }
    }

    if (!_.isEmpty(prevProps.farmData) && _.isEmpty(farmData)) {
      // Remove Popup Here if already Present
      if (popup) {
        popup.remove();
      }
    }
  }

  farmClickFunction = () => {
    const {
      states,
      districts,
      tehsils,
      villages,
      stateSelected,
      districtSelected,
      tehsilSelected,
      villageSelected,
      fetchFarmDetails,
      farmBoundary,
      farmData,
      getMapReference,
    } = this.props;
    const { murabbaNo, khataKhasraNo } = this.state;
    if (!_.isEmpty(farmBoundary)) {
      if (_.isEmpty(farmData)) {
        const districtObj = districts.find(
          o => o.districtCode === districtSelected,
        );
        if (districtObj) {
          fetchFarmDetails({
            districtName: districtObj.districtName,
            coordinates: farmBoundary,
          });
        }
      } else {
        // Add Popup Here Only
        const map = getMapReference();
        if (map) {
          const geoJSON = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [farmBoundary],
                },
              },
            ],
          };
          const centerCoords = center(geoJSON);
          if (!_.isEmpty(centerCoords)) {
            const lngLat = centerCoords.geometry.coordinates;
            /* eslint-disable global-require */
            const mapboxgl = require('mapbox-gl');
            /* eslint-enable global-require */
            let stateName = '';
            let districtName = '';
            let tehsilName = '';
            let villageName = '';
            const stateObj = states.find(o => o.stateID === stateSelected);
            if (stateObj) {
              stateName = stateObj.stateName;
            }
            const districtObj = districts.find(
              o => o.districtCode === districtSelected,
            );
            if (districtObj) {
              districtName = districtObj.districtName;
            }
            const tehsilObj = tehsils.find(
              o => o.tehsilCode === tehsilSelected,
            );
            if (tehsilObj) {
              tehsilName = tehsilObj.tehsilName;
            }
            const villageObj = villages.find(
              o => o.villageCode === villageSelected,
            );
            if (villageObj) {
              villageName = villageObj.villageName;
            }
            let str = '';
            farmData.cropData.forEach((item, index) => {
              str += `<div className="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-6 MuiGrid-grid-xs-6">
                  <span>Segment ${index + 1}</span>
                  <ul>
                    <li>Year: ${item.year}</li>
                    <li>Season: ${
                      item.Rabi !== -1
                        ? 'Rabi'
                        : item.Kharif !== -1
                          ? 'Kharif'
                          : 'NA'
                    }</li>
                    <li>Crop: ${
                      item.Rabi !== -1
                        ? item.rabiCrop
                        : item.Kharif !== -1
                          ? item.kharifCrop
                          : 'NA'
                    }</li>
                    <li>Segment Area: ${(item.area * 0.0001).toFixed(3)}</li>
                    <li>Area Contained: ${(item.area * 0.0001).toFixed(3)}</li>
                    <li>Rating: ${item.Rating}</li>
                  </ul>
                </div>`;
            });
            popup = new mapboxgl.Popup({ anchor: 'left' })
              .setLngLat({ lng: lngLat[0], lat: lngLat[1] })
              .setHTML(
                `<div class="farm-detail-card">
                   <div class="farm-detail-card-body">
                     <h5 class="farm-detail-card-title">Farm Details</h5>
                     <div class="MuiGrid-root MuiGrid-container">
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Khata/Khasra No.</h6>
                         <p>${khataKhasraNo}</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Murrabba No.</h6>
                         <p>${murabbaNo}</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Land Size</h6>
                         <p>${(farmData.area * 0.0001).toFixed(3)} Ha</p>
                       </div>
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>State</h6>
                         <p>${stateName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>District</h6>
                         <p>${districtName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Tehsil</h6>
                         <p>${tehsilName}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>Village</h6>
                         <p>${villageName}</p>
                       </div>  
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4">
                         <h6>No of segment</h6>
                         <p>${farmData.cropData.length}</p>
                       </div> 
                       <div class ="MuiGrid-root MuiGrid-item MuiGrid-grid-lg-4 MuiGrid-grid-xs-4"></div>
                     </div>
                     <div class=" MuiGrid-root MuiGrid-container card-m-t">
                         ${str}
                     </div>
                   </div>
                </div>`,
              )
              .addTo(map);
          }
        }
      }
    }
  };

  _generateData = () => {
    const {
      fetchFarmOwner,
      fetchFarmBoundary,
      stateSelected,
      districtSelected,
      tehsilSelected,
      villageSelected,
    } = this.props;
    const { khataKhasraNo, murabbaNo } = this.state;

    let error = '';
    let check = true;
    if (!khataKhasraNo) {
      check = false;
      error = 'Please Select KhataKhasra Number';
    }
    if (!murabbaNo) {
      check = false;
      error = 'Please Select Muraba Number';
    }
    if (!villageSelected) {
      check = false;
      error = 'Please Select Village';
    }
    if (!tehsilSelected) {
      check = false;
      error = 'Please Select Tehsil';
    }
    if (!districtSelected) {
      check = false;
      error = 'Please Select District';
    }
    if (!stateSelected) {
      check = false;
      error = 'Please Select State';
    }
    if (check) {
      this.setState(
        {
          isGenerate: true,
          error: '',
        },
        () => {
          fetchFarmOwner({
            districtCode: districtSelected,
            tehsilCode: tehsilSelected,
            villageCode: villageSelected,
            murrabbaNo: murabbaNo,
            khasraNo: khataKhasraNo,
          });
          fetchFarmBoundary({
            districtCode: districtSelected,
            tehsilCode: tehsilSelected,
            villageCode: villageSelected,
            murrabbaNo: murabbaNo,
            khasraNo: khataKhasraNo,
          });
        },
      );
    } else {
      this.setState({
        isGenerate: false,
        error,
      });
    }
  };

  _resetData = (e) => {
    e.preventDefault();
    this.setState({
      isGenerate: false,
      expandPanel: false,
      enabledStates: ['5c77da4f-bc9b-4099-bed7-15e06a45f376'],
      khataKhasraNo: '',
      murabbaNo: '',
    });
    const { resetFarmAccounts, getMapReference } = this.props;
    const map = getMapReference();
    resetFarmAccounts();
    if (map) {
      if (map.getLayer('farmaccountBoundary')) {
        map.removeLayer('farmaccountBoundary');
        map.removeLayer('farmaccountBoundaryLine');
        map.removeSource('farmaccountBoundary');
      }
    }
    this.handleLocation(
      {
        target: {
          value: '5c77da4f-bc9b-4099-bed7-15e06a45f376',
        },
      },
      'STATE',
    );
  };

  handleLocation = (event, level) => {
    const {
      setAccountsLocation,
      getAccountsHierarchy,
      states,
      stateSelected,
      districtSelected,
      tehsilSelected,
      getMapReference,
      fetchMurabbaList,
    } = this.props;
    setAccountsLocation(level, event.target.value);
    let stateData;
    switch (level) {
      case 'STATE':
        getAccountsHierarchy({
          stateId: event.target.value,
          districtCode: 'NA',
          tehsilCode: 'NA',
          level: 3,
        });
        stateData = states.find(o => o.stateID === event.target.value);
        if (stateData && stateData.bounds && fitLevelBounds) {
          const map = getMapReference();
          if (map) {
            fitLevelBounds(map, 'STATE', stateData.bounds);
          }
        }
        break;

      case 'DISTRICT':
        getAccountsHierarchy({
          stateId: stateSelected,
          districtCode: event.target.value,
          tehsilCode: 'NA',
          level: 4,
        });
        this.setState({
          error: '',
        });
        break;

      case 'TEHSIL':
        getAccountsHierarchy({
          stateId: stateSelected,
          districtCode: districtSelected,
          tehsilCode: event.target.value,
          level: 6,
        });
        this.setState({
          error: '',
        });
        break;

      case 'VILLAGE':
        fetchMurabbaList({
          districtCode: districtSelected,
          tehsilCode: tehsilSelected,
          villageCode: event.target.value,
        });
        this.setState({
          error: '',
        });
        break;
      default:
        break;
    }
  };

  handleMurabbaChange = (item) => {
    if (item) {
      const {
        fetchKhasraKhata,
        districtSelected,
        tehsilSelected,
        villageSelected,
      } = this.props;
      this.setState({
        murabbaNo: item.value,
      });
      fetchKhasraKhata({
        districtCode: districtSelected,
        tehsilCode: tehsilSelected,
        villageCode: villageSelected,
        murrabbaNo: item.value,
      });
    } else {
      this.setState({
        murabbaNo: '',
      });
    }
  };

  handleKhataKhasraChange = (item) => {
    if (item) {
      this.setState({
        khataKhasraNo: item.value,
      });
    } else {
      this.setState({
        khataKhasraNo: '',
      });
    }
  };

  _zoomToFarm = (ev) => {
    ev.preventDefault();
    const { farmBoundary, getMapReference } = this.props;
    if (!_.isEmpty(farmBoundary)) {
      const geoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [farmBoundary],
            },
          },
        ],
      };
      const map = getMapReference();
      if (map) {
        // const bounds = bbox(geoJSON);
        const centerCoords = center(geoJSON);
        if (!_.isEmpty(centerCoords)) {
          const lngLat = centerCoords.geometry.coordinates;
          map.flyTo({
            center: lngLat,
            zoom: 18,
            bearing: 0,
            speed: 1.5, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing(t) {
              return t;
            },
          });
        }
        // map.fitBounds(bounds, { padding: 20 });
      }
    }
  };

  render() {
    const { language } = this.context;
    const {
      isGenerate,
      expandPanel,
      enabledStates,
      khataKhasraNo,
      murabbaNo,
      error,
    } = this.state;
    const {
      states,
      districts,
      tehsils,
      villages,
      stateSelected,
      districtSelected,
      tehsilSelected,
      villageSelected,
      murabbaList,
      khasraKhataList,
      farmOwner,
      isLoading,
      farmBoundary,
    } = this.props;
    const statesHtml = [];
    const districtHtml = [];
    const tehsilsHtml = [];
    const villagesHtml = [];
    const murabbaListHtml = [];
    const khasraKhataListHtml = [];
    statesHtml.push(
      <MenuItem value="" key="Fgdfsafsfs" disabled>
        {languageConverter('State', language.hindi)}
      </MenuItem>,
    );

    districtHtml.push(
      <MenuItem value="" key="adad" disabled>
        {languageConverter('District', language.hindi)}
      </MenuItem>,
    );

    tehsilsHtml.push(
      <MenuItem value="" key="adadssf" disabled>
        {languageConverter('Tehsil', language.hindi)}
      </MenuItem>,
    );

    villagesHtml.push(
      <MenuItem value="" key="ewrwew" disabled>
        {languageConverter('Village', language.hindi)}
      </MenuItem>,
    );

    murabbaListHtml.push(
      <MenuItem value="" key={Math.random()} disabled>
        {'Murabba No.'}
      </MenuItem>,
    );

    khasraKhataListHtml.push(
      <MenuItem value="" key={Math.random()} disabled>
        {'Khata Khasra No.'}
      </MenuItem>,
    );

    if (states.length) {
      states.forEach((item) => {
        if (enabledStates) {
          if (enabledStates.indexOf(item.stateID) > -1) {
            statesHtml.push(
              <MenuItem key={item.stateID} value={item.stateID}>
                {item.stateName}
              </MenuItem>,
            );
          } else {
            statesHtml.push(
              <MenuItem key={item.stateID} value={item.stateID} disabled>
                {item.stateName}
              </MenuItem>,
            );
          }
        } else {
          statesHtml.push(
            <MenuItem key={item.stateID} value={item.stateID}>
              {item.stateName}
            </MenuItem>,
          );
        }
      });
    }

    if (districts.length) {
      districts.forEach((item) => {
        districtHtml.push(
          <MenuItem key={Math.random()} value={item.districtCode}>
            {item.districtName}
          </MenuItem>,
        );
      });
    }

    if (tehsils.length) {
      tehsils.forEach((item) => {
        tehsilsHtml.push(
          <MenuItem key={Math.random()} value={item.tehsilCode}>
            {item.tehsilName}
          </MenuItem>,
        );
      });
    }

    if (villages.length) {
      villages.forEach((item) => {
        villagesHtml.push(
          <MenuItem key={Math.random()} value={item.villageCode}>
            {item.villageName}
          </MenuItem>,
        );
      });
    }

    if (murabbaList.length) {
      murabbaList.forEach((item) => {
        murabbaListHtml.push(
          <MenuItem key={Math.random()} value={item}>
            {item}
          </MenuItem>,
        );
      });
    }

    if (khasraKhataList.length) {
      khasraKhataList.forEach((item) => {
        khasraKhataListHtml.push(
          <MenuItem key={Math.random()} value={item}>
            {item}
          </MenuItem>,
        );
      });
    }
    let stateName;
    let districtName;
    let tehsilName;
    let villageName;
    if (isGenerate) {
      const stateObj = states.find(o => o.stateID === stateSelected);
      const { stateName: stateNameVar } = stateObj;
      stateName = stateNameVar;

      const villageObj = villages.find(o => o.villageCode === villageSelected);
      const {
        districtName: districtNameVar,
        tehsilName: tehsilNameVar,
        villageName: villageNameVar,
      } = villageObj;
      districtName = districtNameVar;
      tehsilName = tehsilNameVar;
      villageName = villageNameVar;
    }

    return (
      <div>
        {isGenerate ? (
          <div className="indicesMainPanel">
            <div
              className={
                expandPanel ? 'indicesList expandPanel' : 'indicesList'
              }
            >
              {/* <div className=""> */}
              <Grid container>
                <Grid item xs={3}>
                  <h4>Location</h4>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <h4>
                    <a href="/" onClick={this._resetData}>
                      Edit Selection
                    </a>
                  </h4>
                </Grid>
              </Grid>

              <ul>
                <li>{stateName}</li>
                <li>{districtName}</li>
                <li>{tehsilName}</li>
                <li>{villageName}</li>
              </ul>
              <Grid
                container
                style={{
                  borderBottom: '1px solid #d7d7d7',
                  paddingBottom: '15px',
                }}
              >
                <Grid item xs={3}>
                  <h4>Input</h4>
                </Grid>
                <Grid item xs={9} className="indicesList">
                  <ul>
                    <li>
                      Murabba No.
                      {murabbaNo}
                    </li>
                    <li>
                      Khata Khasra No.
                      {khataKhasraNo}
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
            <div className="indicesScrollableLink">
              <div className="indicesScrollBlock selectController">
                <div className="comparisonPanel darkgreenBtnHeader ">
                  <h4 className="floatLeft">
                    Farmer Detail
                    {!_.isEmpty(farmBoundary) && (
                      <Button
                        style={{ width: 'auto', height: 'auto' }}
                        variant="contained"
                        className="darkgreenBtn floatRight"
                        onClick={e => this._zoomToFarm(e)}
                      >
                        Zoom to Farm
                      </Button>
                    )}
                  </h4>
                  <p>{typeof farmOwner === 'string' && farmOwner}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="agriIndices selectController">
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <h4 className="tabpanelHeading">Location</h4>
                <Grid item xs={12}>
                  <FormControl className="formControl inputHead coloredForms">
                    <MaterialSelect
                      disableUnderline
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={event => this.handleLocation(event, 'STATE')}
                      value={stateSelected}
                      displayEmpty
                    >
                      {statesHtml}
                    </MaterialSelect>
                  </FormControl>
                  <FormControl className="formControl inputHead coloredForms">
                    <MaterialSelect
                      disableUnderline
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={event => this.handleLocation(event, 'DISTRICT')}
                      value={districtSelected}
                      displayEmpty
                    >
                      {districtHtml}
                    </MaterialSelect>
                  </FormControl>
                  <FormControl className="formControl inputHead coloredForms">
                    <MaterialSelect
                      disableUnderline
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={event => this.handleLocation(event, 'TEHSIL')}
                      value={tehsilSelected}
                      displayEmpty
                    >
                      {tehsilsHtml}
                    </MaterialSelect>
                  </FormControl>
                  <FormControl className="formControl inputHead coloredForms">
                    <MaterialSelect
                      disableUnderline
                      inputProps={{ 'aria-label': 'age' }}
                      className="tableDropdown"
                      onChange={event => this.handleLocation(event, 'VILLAGE')}
                      value={villageSelected}
                      displayEmpty
                    >
                      {villagesHtml}
                    </MaterialSelect>
                  </FormControl>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <h4 className="tabpanelHeading">Murrabba List</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <h4 className="tabpanelHeading">Khasra/Khata List</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="formControl">
                      <Select
                        defaultValue={{
                          label: murabbaNo,
                          value: murabbaNo,
                        }}
                        placeholder="Select Murraba no."
                        className="inputSelect"
                        isClearable
                        isSearchable
                        name="murabbaList"
                        options={murabbaList.map(item => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={item => this.handleMurabbaChange(item)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="formControl">
                      <Select
                        defaultValue={{
                          label: khataKhasraNo,
                          value: khataKhasraNo,
                        }}
                        className="inputSelect"
                        isClearable
                        isSearchable
                        name="khataKhasra"
                        options={khasraKhataList.map(item => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={item => this.handleKhataKhasraChange(item)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6} className="agriIndices btn">
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-success floatRight"
                        onClick={this._generateData}
                      >
                        Generate Data
                        <i className="material-icons">arrow_right_alt</i>
                      </Button>
                      {error ? (
                        <p className="errorMsg">
                          {' '}
                          {error}
                          {' '}
                        </p>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

MyAccountSelectTab.contextType = LanguageContext;

export default withRouter(
  connect(
    ({ farmaccounts }) => ({
      states: farmaccounts.states,
      districts: farmaccounts.districts,
      tehsils: farmaccounts.tehsils,
      villages: farmaccounts.villages,
      stateSelected: farmaccounts.stateSelected,
      districtSelected: farmaccounts.districtSelected,
      tehsilSelected: farmaccounts.tehsilSelected,
      villageSelected: farmaccounts.villageSelected,
      murabbaList: farmaccounts.murabbaList,
      khasraKhataList: farmaccounts.khasraKhataList,
      farmOwner: farmaccounts.farmOwner,
      farmBoundary: farmaccounts.farmBoundary,
      farmData: farmaccounts.farmData,
      isLoading: farmaccounts.isLoading,
    }),
    dispatch => ({
      getStates: () => dispatch(getAccountsState()),
      setAccountsLocation: (level, value) => dispatch(setAccountsLocationAct(level, value)),
      getAccountsHierarchy: params => dispatch(getAccountsHierarchyAct(params)),
      fetchMurabbaList: params => dispatch(fetchMurabbaListAct(params)),
      fetchKhasraKhata: params => dispatch(fetchKhasraKhataAct(params)),
      fetchFarmOwner: params => dispatch(fetchFarmOwnerAct(params)),
      fetchFarmBoundary: params => dispatch(fetchFarmBoundaryAct(params)),
      resetFarmAccounts: () => dispatch(resetFarmAccountsAct()),
      fetchFarmDetails: params => dispatch(fetchFarmDetailsAct(params)),
    }),
  )(MyAccountSelectTab),
);
