import React, { Component } from 'react';
// import { getNewAudioInstance } from './audioPlayerUtil';
import { Tooltip } from '@material-ui/core';
import MiniPlayer from './miniPlayer';

export default class GraphCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardDisplay: 'block',
      cardName: '',
      editable: false,
      playMediaRowId: undefined,
      audioInstance: undefined,
      //  play: false,
    };
  }

  componentDidMount() {
    const { card } = this.props;
    this.setState({
      cardName: card.name,
      //   myAudioInstance: undefined,
    });
  }

  handleClick = () => {
    const { onConnection, card } = this.props;
    onConnection(true, card);
  };

  handleMinimise = () => {
    const { cardDisplay } = this.state;
    const { reDraw, card } = this.props;
    if (cardDisplay === 'block') {
      this.setState({ cardDisplay: 'none' });
    } else {
      this.setState({ cardDisplay: 'block' });
    }
    reDraw(card.id);
  };

  handleAdd = () => {
    const { onClickPlus, card } = this.props;
    onClickPlus(true, card.id);
  };

  handleDeleteCard = () => {
    const { onDeleteCard, card } = this.props;
    onDeleteCard(card.id);
  };

  handleSettings = () => {
    const { onOpenSettings, card } = this.props;
    onOpenSettings(true, card);
  };

  getStaticMediaFromMedia = (media) => {
    const mediaObj = {
      id: media.id,
      type: 'SINGLE_MEDIA',
      name: media.name,
      language: 'HINDI',
      description: 'desc',
      totalDuration: 0,
      formattedTotalDuration: '',
      isMetaAvailable: true,
      mediaSources: [
        {
          id: media.id,
          hasSrcUrl: true,
          srcUrl: media.mediaUrl !== undefined ? media.mediaUrl : media.fileUrl,
          isMetaAvailable: true,
        },
      ],
    };
    return mediaObj;
  };

  // handleTogglePlay = (media) => {
  //   const { play, myAudioInstance } = this.state;
  //   this.setState(
  //     {
  //       play: !play,
  //     },
  //     () => {
  //       console.log('play', play);
  //     },
  //   );
  //   let getmyAudioInstance;
  //   const mediaObj = {
  //     id: media.id,
  //     type: 'SINGLE_MEDIA',
  //     name: media.name,
  //     language: 'HINDI',
  //     description: 'desc',
  //     totalDuration: 0,
  //     formattedTotalDuration: '',
  //     isMetaAvailable: true,
  //     mediaSources: [
  //       {
  //         id: media.id,
  //         hasSrcUrl: true,
  //         srcUrl: media.mediaUrl !== undefined ? media.mediaUrl : media.fileUrl,
  //         isMetaAvailable: true,
  //       },
  //     ],
  //   };
  //   console.log('media11', mediaObj);
  //   if (play === false) {
  //     getmyAudioInstance = getNewAudioInstance(mediaObj.mediaSources[0]);
  //     if (getmyAudioInstance) {
  //       getmyAudioInstance.play();
  //       console.log('myAudioInstance', getmyAudioInstance);
  //       this.setState({
  //         myAudioInstance: getmyAudioInstance,
  //       });
  //     }
  //   } else {
  //     myAudioInstance.pause();
  //     this.setState({
  //       myAudioInstance: undefined,
  //     });
  //   }
  // };

  handleDeleteMedia = (index, cardId) => {
    const { onDelete } = this.props;
    onDelete(index, cardId);
  };

  handleChange = (e) => {
    this.setState({ cardName: e.target.value });
  };

  handleKeyPress = (e) => {
    const { cardName } = this.state;
    const { card, updateCardName } = this.props;
    if (e.key === 'Enter') {
      if (cardName === '') {
        this.setState({
          cardName: card.name,
          editable: false,
        });
      }
      if (cardName !== '') {
        this.setState(
          {
            cardName,
            editable: false,
          },
          () => {
            updateCardName(card.id, cardName);
          },
        );
      }
    }
  };

  _editCardName = () => {
    this.setState({ editable: true }, () => {
      const inp = document.getElementById('editField');
      inp.focus();
    });
  };

  broadcastPlay = (rowId, audioInstanceParam) => {
    const { audioInstance } = this.state;
    if (audioInstance) {
      audioInstance.pause();
    }
    this.setState({
      playMediaRowId: rowId,
      audioInstance: audioInstanceParam,
    });
  };

  render() {
    const { card } = this.props;
    const {
      cardName, cardDisplay, editable, playMediaRowId,
    } = this.state;
    return (
      <div style={{ position: 'absolute' }} className="ivrsSetup">
        <div className="ivrsHeader">
          <span
            onClick={this.handleMinimise}
            onKeyDown={this.handleMinimise}
            role="button"
            tabIndex="0"
            className="minimize"
          >
            <i className="material-icons">remove</i>
          </span>
          {/* <Link to="/" onClick={this.handleMinimise}>
           <i className="fas fa-circle" />
           </Link> */}
          <Tooltip title={cardName} placement="top">
            <h5 onDoubleClick={this._editCardName}>
              {editable ? (
                <input
                  type="text"
                  id="editField"
                  style={{
                    backgroundColor: '#0e81f9',
                    border: '1px solid #0e81f9',
                  }}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              ) : (
                cardName
              )}
            </h5>
          </Tooltip>
          <span
            onClick={this.handleSettings}
            onKeyDown={this.handleSettings}
            role="button"
            tabIndex="0"
          >
            <i className="material-icons">settings_applications</i>
          </span>
          {/* <Link to="/" onClick={() => console.log('settings')}>
           <i className="fas fa-cog downArrow" />
           </Link>
           <Link to="/" onClick={this.handleDeleteCard} className="closeIcon">
           <i className="fas fa-times" />
           </Link> */}
          <span
            onClick={this.handleDeleteCard}
            onKeyDown={this.handleDeleteCard}
            role="button"
            tabIndex="0"
            className="closeIcon"
          >
            <i className="material-icons customDelete">close</i>
          </span>
        </div>
        <div style={{ display: cardDisplay }} className="ivrsBody">
          <ul>
            {card.medias.length > 0
              ? card.medias.map((media, index) => (
                <li key={Math.random()}>
                  <MiniPlayer
                    staticMedia={this.getStaticMediaFromMedia(media)}
                    isMediaPlaying={playMediaRowId === media.id}
                    broadcastPlay={this.broadcastPlay}
                  />
                  {/*   <span
                    onClick={() => this.handleTogglePlay(media)}
                    onKeyDown={() => this.handleTogglePlay(media)}
                    role="button"
                    tabIndex="0"
                  >
                    {play ? (
                      <i className="material-icons customPlayIcon">
                          pause_circle_outline
                      </i>
                    ) : (
                      <i className="material-icons customPlayIcon">
                          play_circle_outline
                      </i>
                    )}
                  </span> */}
                  <h5>{media.name}</h5>
                  <span
                    onClick={() => this.handleDeleteMedia(index, card.id)}
                    onKeyDown={() => this.handleDeleteMedia(index, card.id)}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="material-icons crossButton">close</i>
                  </span>
                </li>
              ))
              : null}
            <li className="addMore">
              <span
                onClick={this.handleAdd}
                onKeyDown={this.handleAdd}
                role="button"
                tabIndex="0"
              >
                <i className="material-icons">add_circle</i>
              </span>
            </li>
          </ul>
        </div>
        <div
          style={{ backgroundColor: 'white', display: cardDisplay }}
          className="ivrsFooter"
        >
          <ol>
            {card.outs.map(out => (
              <li key={Math.random()}>
                <span>{out[0]}</span>
              </li>
            ))}
            <li style={{ backgroundColor: '#009999' }}>
              <span
                onClick={this.handleClick}
                onKeyDown={this.handleClick}
                role="button"
                tabIndex="0"
              >
                <i className="material-icons customAddIvrs">add</i>
              </span>
              {/* <Link to="/" onClick={this.handleClick}>
               <i className="fas fa-plus" />
               </Link> */}
            </li>
          </ol>
        </div>
      </div>
    );
  }
}
