import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
} from '@material-ui/core';
import Loader from '../common/customLoader';
import {
  getCampaignDataPoints,
  resetTaggingDetails,
  uploadPoster,
} from '../../Actions/taggingAction';
import TaggingFixedPanel from './common/taggingFixedPanel';
import SelectFarmer from './SelectFarmer';
import '../../css/common.scss';
import '../../css/common/responsive.scss';
import '../../css/tagging/main.scss';
import '../../css/tagging/insights.scss';
import '../../css/tagging/posterInput.scss';

class PosterInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPoints: [],
      selectFarmer: false,
      selectedFile: null,
      checkedDataPoints: [],
      msgDesc: '',
      error: '',
      uploadPicUrl: '',
      isEditing: false,
      posterTitle: 'Campaign 1',
      posterDescription: `from a stunning collection of poster templates
                          guaranteed to turn heads. Free to customize to suit 
                          every event or occasion.`,
      isDescEdit: false,
    };
  }

  componentDidMount() {
    const { fetchCampaignDataPoints, resetDetails } = this.props;
    resetDetails();
    fetchCampaignDataPoints();
  }

  componentDidUpdate(prevProps) {
    const { campaignDataPoints, uploadPosterUrl } = this.props;
    if (campaignDataPoints.length > prevProps.campaignDataPoints.length) {
      this.update(campaignDataPoints);
    }
    if (!_.isEmpty(uploadPosterUrl) && _.isEmpty(prevProps.uploadPosterUrl)) {
      this.updatePosterUrl();
    }
  }

  update = (dataPoints) => {
    const newDataPoints = dataPoints.map(item => ({
      ...item,
      isChecked: false,
    }));
    this.setState({
      dataPoints: newDataPoints,
    });
  };

  updatePosterUrl = () => {
    const { uploadPosterUrl } = this.props;
    const { path } = uploadPosterUrl;
    this.setState({
      uploadPicUrl: path,
    });
  };

  handleMsgChange = (e) => {
    if (e.target.value.length > 250) {
      return;
    }
    this.setState({
      msgDesc: e.target.value,
    });
  };

  handleFileSelected = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  fileUploadHandler = () => {
    const { selectedFile } = this.state;
    const { uploadFile } = this.props;
    const formData = new FormData();
    formData.append('photo', selectedFile);
    uploadFile(formData);
  };

  handleCheckChild = (e) => {
    const { dataPoints } = this.state;
    dataPoints.forEach((item) => {
      if (item.dataPointKey === e.target.value) {
        item.isChecked = e.target.checked;
      }
    });
    this.setState({ dataPoints }, () => {
      const checkedDataPoints = dataPoints.filter(
        item => item.isChecked && item,
      );
      this.setState({
        checkedDataPoints,
      });
    });
  };

  handelClick = () => {
    const { msgDesc, checkedDataPoints } = this.state;
    let error = '';
    let check = true;
    if (checkedDataPoints.length === 0) {
      // check = false;
      // error = 'Please select data points';
    }
    if (!msgDesc) {
      check = false;
      error = 'Please enter the message';
    }
    if (check) {
      this.setState({
        selectFarmer: true,
        error: '',
      });
    } else {
      // // data Points and msg  not mandatory
      this.setState({
        error,
        selectFarmer: false,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      posterTitle: e.target.value,
    });
  };

  handleKeyPress = (e) => {
    const { posterTitle } = this.state;
    if (e.key === 'Enter') {
      if (posterTitle === '') {
        this.setState({ posterTitle: 'Poster 1' });
      }
      this.setState({ isEditing: false });
    }
  };

  _editTitle = () => {
    const { isEditing } = this.state;
    this.setState(
      {
        isEditing: !isEditing,
      },
      () => {
        const inp = document.getElementById('editField');
        inp.focus();
      },
    );
  };

  handleDescChange = (e) => {
    this.setState({
      posterDescription: e.target.value,
    });
  };

  handleDescKeyPress = (e) => {
    const { posterDescription } = this.state;
    if (e.key === 'Enter') {
      if (posterDescription === '') {
        this.setState({ posterDescription: 'poster description 1' });
      }
      this.setState({ isDescEdit: false });
    }
  };

  _editDesc = () => {
    const { isDescEdit } = this.state;
    this.setState(
      {
        isDescEdit: !isDescEdit,
      },
      () => {
        const inp = document.getElementById('editDescField');
        inp.focus();
      },
    );
  };

  _saveDesc = () => {
    const { posterDescription } = this.state;
    this.setState({
      posterDescription,
      isDescEdit: false,
    });
  };

  _saveTitle = () => {
    const { posterTitle } = this.state;
    this.setState({
      posterTitle,
      isEditing: false,
    });
  };

  handlePrevious = () => {
    this.setState({
      selectFarmer: false,
    });
  };

  render() {
    const {
      dataPoints,
      selectFarmer,
      checkedDataPoints,
      msgDesc,
      error,
      uploadPicUrl,
      isEditing,
      posterTitle,
      posterDescription,
      isDescEdit,
    } = this.state;
    const {
      selectedPoster,
      handleBack,
      isLoading,
      calledFrom,
      posterListData,
      anotherTab,
    } = this.props;
    console.log('msgDesc', msgDesc);
    return (
      <React.Fragment>
        {selectFarmer === false ? (
          <Grid container className="taggingMain" spacing={2}>
            <Grid item lg={9} sm={9} xs={12} className="insightLeftPanel">
              <h5>Tagging</h5>
              <ul className="breadCrumb">
                <li className="backLink">
                  <Button
                    variant="contained"
                    style={{
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => handleBack()}
                  >
                    <i className="material-icons">keyboard_backspace</i>
                    Back
                  </Button>
                  {' '}
                  <i className="material-icons line">remove</i>
                </li>
              </ul>
              {calledFrom ? (
                <ul className="cardHolder">
                  <li>
                    <i className="cardCount">15</i>
                  </li>
                  <li>
                    <h5>Send pesonalize posters to your farmers</h5>
                    <p className="labelList">
                      <span className="greenTxt">Recomended</span>
                    </p>
                  </li>
                </ul>
              ) : null}
              <Grid container spacing={0} className="posterWrap cardDetails">
                <Grid item lg={2} sm={2} md={2} xs={12}>
                  <div className="posterWrapImg">
                    {/* selectedPoster ? (
                        <ul>
                          <li>
                            <img
                              src={selectedPoster.mediaLocation}
                              alt={selectedPoster.posterImageID}
                              className="img-responsive"
                            />
                          </li>
                        </ul>
                      ) : null */}
                    <ul>
                      {posterListData.map(list => (
                        <li key={list.posterImageID}>
                          <img
                            src={list.mediaLocation}
                            alt={list.posterImageID}
                            className="img-responsive"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </Grid>
                <Grid item lg={5} sm={5} md={5} xs={12}>
                  <div className="posterSection">
                    {/* <h1>Below image is your selected poster</h1> */}
                    {!uploadPicUrl ? (
                      <img
                        src={
                          selectedPoster
                            ? selectedPoster.mediaLocation
                            : 'images/defaultPoster.png'
                        }
                        alt="default poster"
                        className="img-responsive"
                      />
                    ) : (
                      <img
                        src={uploadPicUrl}
                        alt="upload-pic"
                        className="img-responsive"
                      />
                    )}
                    {/* {selectedPoster ? (
                        <img
                          src={selectedPoster.mediaLocation}
                          alt={selectedPoster.posterImageID}
                          className="img-responsive"
                        />
                      ) : null}
                      {!uploadPicUrl ? null : (
                        <img
                          src={uploadPicUrl}
                          alt="upload-pic"
                          className="img-responsive"
                        />
                      )} */}
                    {/* <span>It will have data points screen*</span> */}
                  </div>
                  <div className="uploadPoster">
                    <h2>Upload you own version of poster</h2>
                    <Grid container>
                      <Grid item lg={6} sm={6} md={6} xs={12}>
                        <input
                          type="file"
                          onChange={this.handleFileSelected}
                          className="custom-file-upload"
                          // style={{ pointerEvents: 'none', cursor: 'default' }}
                        />
                      </Grid>
                      <Grid item lg={6} sm={6} md={6} xs={12}>
                        <div className="btn ml">
                          <Button
                            variant="contained"
                            className="btn-enable"
                            onClick={this.fileUploadHandler}
                          >
                            Upload
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg={5} sm={5} md={5} xs={12}>
                  <div className="posterformInput">
                    <div className="recomondationText">
                      <h3>
                        {isEditing ? (
                          <input
                            type="text"
                            id="editField"
                            className="editTextField"
                            onChange={this.handleChange}
                            onKeyPress={e => this.handleKeyPress(e)}
                            style={{ outline: 'none' }}
                          />
                        ) : (
                          <span>{posterTitle}</span>
                        )}
                      </h3>
                      {!isEditing ? (
                        <Button
                          to="edit"
                          className="linkBtn greenTxt"
                          onClick={this._editTitle}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          to="edit"
                          className="linkBtn greenTxt"
                          onClick={this._saveTitle}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                    <div className="editDetail">
                      <div className="editDetail__head">
                        <h5>Campaign Details:</h5>
                        {!isDescEdit ? (
                          <Button
                            to="edit"
                            className="linkBtn greenTxt"
                            onClick={this._editDesc}
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            to="edit"
                            className="linkBtn greenTxt"
                            onClick={this._saveDesc}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                      <p>
                        {isDescEdit ? (
                          <textarea
                            type="text"
                            id="editDescField"
                            className="editTextField"
                            onChange={this.handleDescChange}
                            onKeyPress={e => this.handleDescKeyPress(e)}
                          />
                        ) : (
                          <span>{posterDescription}</span>
                        )}
                        {/*                       {!isDescEdit ? (
                          <Button
                            to="edit"
                            className="linkBtn greenTxt"
                            onClick={this._editDesc}
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            to="edit"
                            className="linkBtn greenTxt"
                            onClick={this._saveDesc}
                          >
                            Save
                          </Button>
                        )} */}
                      </p>
                    </div>
                    <div className="recomondationText">
                      <p>
                        <span>Recommendation: </span>
                        Choose from a stunning collection of poster templates
                        guaranteed to turn heads. Free to customize to suit
                        every event or occasion.
                      </p>
                    </div>
                    <div className="capturePoint">
                      <ul>
                        <li>Select data points to capture</li>
                      </ul>
                    </div>
                    <div className="capturePointName">
                      {isLoading && <Loader />}
                      <FormControl component="fieldset">
                        <FormGroup className="checkBox">
                          {!isLoading
                            && dataPoints
                            && dataPoints.map(item => (
                              <FormControlLabel
                                key={item.dataPointKey}
                                className="points-label"
                                control={(
                                  <Checkbox
                                    checked={item.isChecked}
                                    onClick={this.handleCheckChild}
                                    value={item.dataPointKey}
                                  />
)}
                                label={item.dataPointTextDesc}
                                style={{
                                  width: '50%',
                                  display: 'inline-flex',
                                  margin: '0px',
                                }}
                              />
                            ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                    <div className="capturePoint">
                      <ul>
                        <li>Compose message text</li>
                        {/* <li>
                            <span>
                              Hindi
                              <img
                                src="/images/translate.svg"
                                alt="translate"
                              />
                              <FormControl
                              className="formControl inputHead coloredForms modeSec">
                              <Select
                                name="dashboard-range"
                                className="tableDropdown"
                              >
                                <MenuItem value="mean">Mean</MenuItem>
                                <MenuItem value="mode">Mode</MenuItem>
                                <MenuItem value="median">Median</MenuItem>
                                <MenuItem value="max">Max</MenuItem>
                                <MenuItem value="min">Min</MenuItem>
                              </Select>
                            </FormControl>
                            </span>
                          </li> */}
                      </ul>
                    </div>
                    <div className="textArea">
                      <TextField
                        // error={!!mobileError}
                        placeholder="Type Your message here, maximum 250 characters allowed"
                        value={msgDesc}
                        multiline
                        className="textField inputHead customText-width"
                        onChange={this.handleMsgChange}
                      />
                    </div>
                    <div className="darkgreenBtnHeader thankuBtn">
                      <Button
                        variant="contained"
                        className="button darkgreenBtn"
                        onClick={this.handelClick}
                      >
                        Select Farmer
                      </Button>
                      {error ? (
                        <p className="errorMsg1">
                          {' '}
                          {error}
                          {' '}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <TaggingFixedPanel />
          </Grid>
        ) : (
          <SelectFarmer
            selectedPoster={!uploadPicUrl ? selectedPoster : uploadPicUrl}
            dataPoints1={checkedDataPoints}
            textDesc={msgDesc}
            handlePrevious={this.handlePrevious}
            posterTitle={posterTitle}
            posterDescription={posterDescription}
            calledFrom={calledFrom}
            anotherTab={anotherTab}
          />
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(
  connect(
    ({ tagging }) => ({
      campaignDataPoints: tagging.campaignDataPoints,
      uploadPosterUrl: tagging.uploadPosterUrl,
      isLoading: tagging.isLoading,
      posterListData: tagging.posterListData,
    }),
    dispatch => ({
      fetchCampaignDataPoints: () => dispatch(getCampaignDataPoints()),
      uploadFile: data => dispatch(uploadPoster(data)),
      resetDetails: () => dispatch(resetTaggingDetails()),
    }),
  )(withCookies(PosterInput)),
);
