import React from 'react';
import { Grid } from '@material-ui/core';
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import ScrollableTabPanel from '../common/ScrollableTabPanel';
import IvrsSetup from './ivrsSetup';
import IvrsList from './ivrsList';
// import Schedule from './schedule';
import IvrsReport from './ivrsReport';
import '../../css/common.scss';
import '../../css/button.scss';
import '../../css/ivrs/ivrsInsights.scss';

class IVRS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // activePanel: '',
      proceed: false,
      // title: '',
      // desc: '',
      // selectedId: '',
      activeTab: 0,
    };
  }

  handleProceed = (value, data, type) => {
    // const { ivrsName, ivrsDesc, selectedId } = data;
    if (type === 'list') {
      this.setState({
        proceed: value,
        // title: ivrsName,
        // desc: ivrsDesc,
        // selectedId,
      });
    } else {
      this.setState({
        proceed: value,
        // title: ivrsName,
        // desc: ivrsDesc,
        // selectedId: '',
      });
    }
  };

  handlePrevious = () => {
    this.setState({
      proceed: false,
    });
  };

  broadcastTabChange = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleTab = () => {
    this.setState({
      activeTab: 1,
    });
  };

  render() {
    const { proceed, activeTab } = this.state;
    const tabsData = [
      {
        tabName: 'IVRS REPORT',
        component: <IvrsReport anotherTab={this.handleTab} />,
      },
      {
        tabName: 'Ivrs Setup',
        component:
          activeTab === 1 ? (
            <IvrsSetup
              proceedClick={this.handleProceed}
              proceed={proceed}
              handlePrevious={this.handlePrevious}
            />
          ) : null,
      },
      {
        tabName: 'Ivrs List',
        component:
          activeTab === 2 ? (
            <IvrsList
              proceedClick={this.handleProceed}
              proceed={proceed}
              handlePrevious={this.handlePrevious}
            />
          ) : null,
      },
    ];
    return (
      <React.Fragment>
        <DndProvider backend={HTML5Backend}>
          <Grid container className="taggingMain removePadding" spacing={0}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="ivrsWhiteBack"
            >
              <ScrollableTabPanel
                tabsData={tabsData}
                customStyle="innerTabPanel"
                // customStyle="ivrsTabPanel"
                // renderOnce
                activeTab={activeTab}
                broadcastTabChange={this.broadcastTabChange}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </React.Fragment>
    );
  }
}
export default IVRS;
