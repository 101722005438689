import React from 'react';
import {
  Grid, Button, FormControl, Select, MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import LocationHeirarchy from '../../farmEngine/locationHeirarchy';
import '../../../css/agricultureIndices.scss';
import {
  setSelectedYearAct,
  setSelectedSeasonAct,
} from '../../../Actions/locationHierarchyAction';
import {
  getCropsInCropMapsAct,
  getCropMapParameter,
  getCropMap,
  fetchCropMapGraphAct,
  emptyCropMapGraphDataAct,
} from '../../../Actions/cropAction';

class CropSelectTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cropName: '',
      cropParameterValue: '',
      cropParameterName: '',
      cropParameterKey: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { location, emptyCropMapGraphData } = this.props;
    const { cropName, cropParameterKey } = this.state;
    if (
      location.selectedYear !== prevProps.location.selectedYear
      && location.selectedSeason
      && location.selectedStateID
      && location.selectedDistrictID
    ) {
      this.getCrops();
      emptyCropMapGraphData();
      if (cropName && cropParameterKey) {
        this.getCropMap();
      }
    }

    if (
      location.selectedSeason !== prevProps.location.selectedSeason
      && location.selectedYear
      && location.selectedStateID
      && location.selectedDistrictID
    ) {
      this.getCrops();
      emptyCropMapGraphData();
      if (cropName && cropParameterKey) {
        this.getCropMap();
      }
    }

    if (
      location.selectedStateID !== prevProps.location.selectedStateID
      && location.selectedStateID
    ) {
      // let stateNameSelected;
      // location.state.forEach((item) => {
      //   const { stateID, stateName } = item;
      //   if (stateID === location.selectedStateID) stateNameSelected = stateName;
      // });
      // this.updateLocationObject('state', stateNameSelected);
      emptyCropMapGraphData();
    }
    if (
      location.selectedDistrictID !== prevProps.location.selectedDistrictID
      && location.selectedDistrictID
    ) {
      // let districtNameSelected;
      // location.district.forEach((item) => {
      //   const { districtID, districtName } = item;
      //   if (districtID === location.selectedDistrictID) districtNameSelected = districtName;
      // });
      // this.updateLocationObject('district', districtNameSelected);

      this.getCrops();
      if (
        cropName
        && cropParameterKey
        && location.selectedSeason
        && location.selectedYear
      ) {
        this.getCropMap();
      }
    }
  }

  getCrops = () => {
    const { location, getCropsInCropMaps, fetchCropParameter } = this.props;
    const data = {};
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    data.tehsilID = location.selectedTehsilID;
    data.year = location.selectedYear;
    data.seasonCode = location.selectedSeason;
    if (
      location.selectedStateID
      && location.selectedDistrictID
      && location.selectedYear
      && location.selectedSeason
    ) {
      getCropsInCropMaps(data);
      fetchCropParameter();
    }
  };

  _handleCropSource = (ev) => {
    this.setState(
      {
        cropName: ev.target.value,
      },
      () => {
        this.getCropMap();
      },
    );
  };

  _handleCropParameter = (ev) => {
    this.setState(
      {
        cropParameterKey: ev.target.value.split('//')[0],
        cropParameterValue: ev.target.value,
        cropParameterName: ev.target.value.split('//')[1],
      },
      () => {
        this.getCropMap();
      },
    );
  };

  getCropMap = () => {
    const { location, fetchCropMap, fetchCropMapGraph } = this.props;
    const { cropName, cropParameterKey } = this.state;
    const data = {};
    data.stateID = location.selectedStateID;
    data.districtID = location.selectedDistrictID;
    data.tehsilID = '';
    data.year = location.selectedYear;
    data.seasonCode = location.selectedSeason;
    data.cropID = cropName;
    data.parameterName = cropParameterKey;
    const newData = JSON.parse(JSON.stringify(data));
    newData.aggregatedLevel = 'district';
    if (cropName && cropParameterKey) {
      fetchCropMap(data);
      fetchCropMapGraph(newData);
    }
  };

  handleYear(year) {
    const { setSelectedYear } = this.props;

    setSelectedYear(year);
  }

  handleSeason(season) {
    const { setSelectedSeason } = this.props;

    setSelectedSeason(season);
  }

  updateLocationObject(type, value) {
    const { locationObject } = this.state;
    const locationObjectCopy = { ...locationObject };
    locationObjectCopy[type] = value;
    this.setState({
      locationObject: locationObjectCopy,
    });
  }

  render() {
    const {
      broadcastLocationChange,
      selectedSeason,
      selectedYear,
      cropsData,
      cropParameter,
      // cropMap,
      cropGraphData,
    } = this.props;
    const {
      cropName,
      cropParameterName,
      cropParameterValue,
      cropParameterKey,
    } = this.state;
    let yearHtml = [];
    yearHtml = [2018, 2019].map(item => (
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    ));

    let seasonHtml = [];
    seasonHtml = [
      { label: 'Kharif', value: 1 },
      { label: 'Rabi', value: 2 },
      // { label: 'Zaid', value: 3 },
    ].map(item => (
      <MenuItem value={item.value} key={item.label}>
        {item.label}
      </MenuItem>
    ));
    return (
      <React.Fragment>
        <div className="agriIndices selectController">
          <h4 className="tabpanelHeading">Crop Report</h4>
          <Grid item xs={12}>
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                inputProps={{ 'aria-label': 'age' }}
                className="selectEmpty modalDropdown"
                onChange={event => this.handleYear(event.target.value)}
                value={selectedYear}
                displayEmpty
              >
                {yearHtml}
              </Select>
            </FormControl>
            <FormControl className="formControl inputHead coloredForms">
              <Select
                disableUnderline
                inputProps={{ 'aria-label': 'age' }}
                className="selectEmpty modalDropdown"
                onChange={event => this.handleSeason(event.target.value)}
                value={selectedSeason}
                displayEmpty
              >
                {seasonHtml}
              </Select>
            </FormControl>
            <LocationHeirarchy
              type="selectEmpty modalDropdown"
              calledFrom="dashboard"
              broadcastLocationChange={broadcastLocationChange}
              hideVillage
              hideTehsil
            />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h4 className="tabpanelHeading">CROP</h4>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="formControl inputHead coloredForms inputFullWidth">
                <Select
                  disableUnderline
                  name="dashboard-user"
                  className="selectEmpty modalDropdown"
                  inputProps={{ 'aria-label': 'user' }}
                  value={cropName}
                  displayEmpty
                  onChange={ev => this._handleCropSource(ev)}
                  disabled={!cropsData.length}
                >
                  <MenuItem value="" disabled>
                    Select Crop
                  </MenuItem>
                  {cropsData.map(item => (
                    <MenuItem value={item.cropID} key={item.cropID}>
                      {item.cropName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className="formControl inputHead coloredForms inputFullWidth">
                <Select
                  disableUnderline
                  name="dashboard-user"
                  className="selectEmpty modalDropdown"
                  inputProps={{ 'aria-label': 'user' }}
                  value={cropParameterValue}
                  displayEmpty
                  onChange={ev => this._handleCropParameter(ev)}
                  disabled={!cropParameter.length}
                >
                  <MenuItem value="" disabled>
                    Parameter
                  </MenuItem>
                  {cropParameter.map(item => (
                    <MenuItem
                      value={`${item.parameterName}//${item.label}`}
                      key={item.parameterID}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {cropGraphData.length ? (
              <ResponsiveContainer width="100%" aspect={4.0 / 2.5}>
                <BarChart data={cropGraphData} barGap={0}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    stroke="#444444"
                    fontSize="80%"
                    tickLine={false}
                    tick
                  >
                    <Label
                      value={cropParameterName}
                      angle={0}
                      position="insideBottom"
                      style={{
                        textAnchor: 'middle',
                        fontSize: '50%',
                        fill: '#bababa',
                      }}
                    />
                  </XAxis>
                  <YAxis stroke="#444444" fontSize="80%" tickLine={false}>
                    <Label
                      value="Acreage Sum"
                      angle={-90}
                      position="insideLeft"
                      style={{
                        textAnchor: 'middle',
                        fontSize: '80%',
                        fill: '#bababa',
                      }}
                    />
                  </YAxis>

                  <Tooltip />
                  <Legend
                    margin={{ top: 50 }}
                    verticalAlign="bottom"
                    align="center"
                  />
                  {cropParameterKey === 'crop_health' ? (
                    <Bar dataKey="Below normal" fill="#8884d8" stackId="a" />
                  ) : null}
                  {cropParameterKey === 'crop_health' ? (
                    <Bar dataKey="Normal" fill="#FF7F50" stackId="a" />
                  ) : null}
                  {cropParameterKey === 'crop_health' ? (
                    <Bar
                      dataKey="Slightly below normal"
                      fill="#FFD700"
                      stackId="a"
                    />
                  ) : null}
                  {cropParameterKey === 'avg_ndvi_peak' ? (
                    <Bar dataKey="0.3-0.5" fill="#FF7F50" stackId="a" />
                  ) : null}
                  {cropParameterKey === 'avg_ndvi_peak' ? (
                    <Bar dataKey="0.5-0.6" fill="#FFD700" stackId="a" />
                  ) : null}
                </BarChart>
              </ResponsiveContainer>
            ) : null}
          </Grid>
        </div>
        <div className="fixedControls">
          <ul>
            <li>
              <Button>
                <i className="material-icons">save_alt</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">save</i>
              </Button>
            </li>
            <li>
              <Button>
                <i className="material-icons">print</i>
              </Button>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ location, crop }) => ({
      selectedYear: location.selectedYear,
      selectedSeason: location.selectedSeason,
      location,
      cropsData: crop.cropInCropMaps,
      cropParameter: crop.cropParameter,
      cropMap: crop.cropMap,
      cropGraphData: crop.cropGraphData,
    }),
    dispatch => ({
      setSelectedYear: year => dispatch(setSelectedYearAct(year)),
      setSelectedSeason: season => dispatch(setSelectedSeasonAct(season)),
      getCropsInCropMaps: data => dispatch(getCropsInCropMapsAct(data)),
      fetchCropParameter: () => dispatch(getCropMapParameter()),
      fetchCropMap: data => dispatch(getCropMap(data)),
      fetchCropMapGraph: data => dispatch(fetchCropMapGraphAct(data)),
      emptyCropMapGraphData: () => dispatch(emptyCropMapGraphDataAct()),
    }),
  )(withCookies(CropSelectTab)),
);
