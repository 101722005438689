import React from 'react';
import {
  AppBar, Tabs, Tab, Typography, Box,
} from '@material-ui/core';
import '../../css/tabPanel.scss';

/**
 * Sample TabsData
 *
 * const tabsData = [
  {
    tabName: 'Agriculture Indices',
    component: (<NewTabpanel/>)
  },
  {
    tabName: 'Weather',
    component: null
  },
  {
    tabName: 'Crop Maps',
    component: null
  },
  {
    tabName: 'Yield Processing',
    component: ('Item 4')
  }
];
 *  */

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

export default class ScrollableTabPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    const { initialSelectedTab } = this.props;
    if (initialSelectedTab) {
      this.setState({
        value: initialSelectedTab,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;
    if (prevProps.activeTab !== activeTab) {
      this.handleChange(null, activeTab);
    }
  }

  handleChange = (evt, value) => {
    if (evt) {
      evt.preventDefault();
    }
    const { broadcastTabChange } = this.props;
    this.setState({
      value,
    });

    if (broadcastTabChange) {
      broadcastTabChange(value);
    }
  };

  render() {
    const { value } = this.state;
    const { tabsData, renderOnce, customStyle } = this.props;
    const tabsHTML = [];
    const tabPanelHTML = [];
    tabsData.forEach((tabItem, tabIndex) => {
      tabsHTML.push(
        <Tab
          key={tabItem.tabName}
          id={`scrollable-force-tab-${tabIndex}`}
          aria-controls={`scrollable-force-tabpanel-${tabIndex}`}
          label={tabItem.tabName}
          disabled={tabItem.disabled}
        />,
      );
      if (renderOnce) {
        tabPanelHTML.push(
          <TabPanel key={tabItem.tabName} value={value} index={tabIndex}>
            {tabItem.component}
          </TabPanel>,
        );
      } else if (value === tabIndex) {
        tabPanelHTML.push(
          <TabPanel key={tabItem.tabName} value={value} index={tabIndex}>
            {tabItem.component}
          </TabPanel>,
        );
      }
    });
    const classes = customStyle || '';
    return (
      <div className={classes}>
        <div className="tabPanelMain">
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              {tabsHTML}
            </Tabs>
          </AppBar>
          {tabPanelHTML}
        </div>
      </div>
    );
  }
}
