import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Loadable from 'react-loadable';
import Welcome from './welcomeGif';
import getAllowedPagesBasedOnUserRole from './loggedinAccesPages';
import NotFound from './notFound';
import AccountSetup from '../accountSetup/index';

const AsyncFarmEngine = Loadable({
  loader: () => import(/* webpackChunkName: "farmengine" */ '../farmEngine/index'),
  loading: () => <Welcome />,
  modules: ['farmengine'],
});

class EnsureLoggedInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkedLoggedIn();
  }

  checkedLoggedIn() {
    const { cookies, history, isLogin } = this.props;
    const userdata = cookies.get('userData');
    const loggedInTimeStamp = cookies.get('loggedInTimeStamp');

    if (!isLogin) {
      if (userdata) {
        const userTokenExp = userdata.data.sessionTTL;
        if (userTokenExp * 1000 + loggedInTimeStamp < Date.now()) {
          cookies.remove('userData', null);
          cookies.remove('loggedInTimeStamp', null);
          history.push('/');
        }
      } else {
        history.push('/');
      }
    }
  }

  render() {
    const { cookies } = this.props;
    const userdata = cookies.get('userData');
    const routePages = userdata
      ? getAllowedPagesBasedOnUserRole(userdata.data.userRoleData.roleName)
      : null;
    /* const routePages = userdata
      ? getAllowedPagesBasedOnUserRole(
        userdata.data.roles ? userdata.data.roles[0].roleName : 'SeedCompany',
      )
      : null; */
    return (
      <div>
        {routePages ? (
          <Switch>
            {routePages
              && routePages.length > 0
              && routePages.map(item => (
                <Route
                  key={item.ID}
                  exact
                  path={item.PATH}
                  component={item.COMPONENT}
                />
              ))}
            <Route path="/setup" component={AccountSetup} />
            <Route path="/home" component={AsyncFarmEngine} />
            {/* <Route path="/myaccount" exact component={FarmerSetup} /> */}
            {/* <Route path="/dashboard" exact component={Dashboard} /> */}
            <Route path="*" render={NotFound} />
          </Switch>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(({ app }) => ({
    isLogin: app.isLogin,
  }))(withCookies(EnsureLoggedInContainer)),
);
