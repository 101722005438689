import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const AreaChartComponent = (props) => {
  const {
    data, dataKey, showToolTip, showLegend, children, color,
  } = props;
  return (
    <ResponsiveContainer width="25%" aspect={4.0 / 3.4}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.0} />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis hide dataKey={dataKey} stroke="#444444" fontSize="80%" />
        <YAxis hide />
        {showToolTip && <Tooltip />}
        {showLegend && <Legend />}
        {children}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
