const initialState = {
  states: [],
  districts: [],
  tehsils: [],
  villages: [],
  stateSelected: '',
  districtSelected: '',
  tehsilSelected: '',
  villageSelected: '',
  murabbaList: [],
  khasraKhataList: [],
  farmOwner: '',
  farmBoundary: [],
  isLoading: false,
  farmData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ACCOUNTS_STATE_SUCCESS':
      return {
        ...state,
        states: action.payload,
        districts: [],
        tehsils: [],
        villages: [],
        districtSelected: '',
        tehsilSelected: '',
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_STATE_FAILURE':
      return {
        ...state,
        ...initialState,
        isLoading: false,
      };

    case 'GET_ACCOUNTS_DISTRICT_SUCCESS':
      return {
        ...state,
        districts: action.payload,
        tehsils: [],
        villages: [],
        districtSelected: '',
        tehsilSelected: '',
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_DISTRICT_FAILURE':
      return {
        ...state,
        districts: [],
        tehsils: [],
        villages: [],
        districtSelected: '',
        tehsilSelected: '',
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_TEHSIL_SUCCESS':
      return {
        ...state,
        tehsils: action.payload,
        villages: [],
        tehsilSelected: '',
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_TEHSIL_FAILURE':
      return {
        ...state,
        tehsils: [],
        villages: [],
        tehsilSelected: '',
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_VILLAGE_SUCCESS':
      return {
        ...state,
        villages: action.payload,
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'GET_ACCOUNTS_VILLAGE_FAILURE':
      return {
        ...state,
        villages: [],
        villageSelected: '',
        isLoading: false,
        murabbaList: [],
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'SET_ACCOUNTS_STATE':
      return {
        ...state,
        stateSelected: action.payload,
        districtSelected: '',
        tehsilSelected: '',
        villageSelected: '',
        districts: [],
        tehsils: [],
        villages: [],
        isLoading: false,
      };

    case 'SET_ACCOUNTS_DISTRICT':
      return {
        ...state,
        districtSelected: action.payload,
        tehsilSelected: '',
        villageSelected: '',
        tehsils: [],
        villages: [],
        isLoading: false,
      };

    case 'SET_ACCOUNTS_TEHSIL':
      return {
        ...state,
        tehsilSelected: action.payload,
        villageSelected: '',
        villages: [],
        isLoading: false,
      };

    case 'SET_ACCOUNTS_VILLAGE':
      return {
        ...state,
        villageSelected: action.payload,
        isLoading: false,
      };

    case 'FETCH_MURABBA_LIST_SUCCESS':
      return {
        ...state,
        murabbaList: action.payload,
        isLoading: false,
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'FETCH_MURABBA_LIST_FAILURE':
      return {
        ...state,
        murabbaList: [],
        isLoading: false,
        khasraKhataList: [],
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'FETCH_KHASRA_KHATA_LIST_SUCCESS':
      return {
        ...state,
        khasraKhataList: action.payload,
        isLoading: false,
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'FETCH_KHASRA_KHATA_LIST_FAILURE':
      return {
        ...state,
        khasraKhataList: [],
        isLoading: false,
        farmOwner: '',
        farmBoundary: [],
        farmData: [],
      };

    case 'FETCH_FARM_OWNER_SUCCESS':
      return {
        ...state,
        farmOwner: action.payload,
        isLoading: false,
      };

    case 'FETCH_FARM_OWNER_FAILURE':
      return {
        ...state,
        farmOwner: '',
        isLoading: false,
      };

    case 'FETCH_FARM_BOUNDARY_SUCCESS':
      return {
        ...state,
        farmBoundary: action.payload,
        isLoading: false,
      };

    case 'FETCH_FARM_BOUNDARY_FAILURE':
      return {
        ...state,
        farmBoundary: '',
        isLoading: false,
      };

    case 'FARM_ACCOUNTS_LOADING':
      return {
        ...state,
        isLoading: true,
        farmBoundary:
          action.payload === 'farmboundary' ? [] : state.farmBoundary,
        farmOwner: action.payload === 'farmowner' ? '' : state.farmOwner,
      };

    case 'RESET_FARM_ACCOUNTS':
      return {
        ...state,
        ...initialState,
        states: state.states,
      };

    case 'FETCH_FARM_DETAILS_SUCCESS':
      return {
        ...state,
        farmData: action.payload,
        isLoading: false,
      };

    case 'FETCH_FARM_DETAILS_FAILURE':
      return {
        ...state,
        farmData: [],
        isLoading: false,
      };

    default:
      return state;
  }
};
