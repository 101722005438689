import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withCookies } from 'react-cookie';
import Loader from '../../../common/customLoader';
import ScrollableTabPanel from '../../../common/ScrollableTabPanel';
import { closePopupAction } from '../../../../Actions/surveyAction';

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

class ApplicationSurveyPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      closePopupFunc,
      experimentDetailLoading,
      experimentDetailError,
      experimentDetail,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { surveyDetails, mediaURL } = experimentDetail;
    let tabsData = [];
    if (!_.isEmpty(experimentDetail)) {
      if (!_.isEmpty(surveyDetails)) {
        tabsData = [
          {
            tabName: 'Part-1 Applicant Location',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Geographic Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            State
                            {' '}
                            <span>{surveyDetails.stateName || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            District
                            <span>
                              {surveyDetails.districtName || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            {surveyDetails.level4 || 'Level 4'}
                            <span>{surveyDetails.level4Name || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Season/Year
                            <span>{`${surveyDetails.season}/${surveyDetails.year}`}</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            {surveyDetails.level5 || 'Level 5'}
                            {' '}
                            <span>{surveyDetails.level5Name}</span>
                          </li>
                          <li>
                            {surveyDetails.level6 || 'Level 6'}
                            {' '}
                            <span>{surveyDetails.level6Name || 'xxxxxx'}</span>
                          </li>
                          <li>
                            {surveyDetails.level7 || 'Level 7'}
                            {' '}
                            <span>{surveyDetails.level7Name || 'xxxxxx'}</span>
                          </li>
                          <li>
                            Crop Name
                            {' '}
                            <span>{surveyDetails.cropName || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>
                        Field Details
                        {' '}
                        <span>
                          Co ordinates:
                          {' '}
                          <strong>{`${surveyDetails.lat} , ${surveyDetails.lng}`}</strong>
                        </span>
                      </h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Total Area(Unit)
                            {' '}
                            <span>{surveyDetails.landArea || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Relative Name
                            <span>
                              {surveyDetails.relativeName || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Survey/Subdivision
                            <span>{`${surveyDetails.surveyNumber}/${surveyDetails.subDivisionNumber}`}</span>
                          </li>
                          <li>
                            Relation Type
                            <span>{surveyDetails.relation || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Farmer Name
                            {' '}
                            <span>{surveyDetails.farmerName || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Farmer Mobile
                            <span>{surveyDetails.mobile || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Pincode
                            <span>{surveyDetails.resPincode || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Sowing Date
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.passbookPhoto}`}
                          alt="01"
                        />
                        <p>Passbook</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.landRecordPhoto}`}
                          alt="02"
                        />
                        <p>Land Record</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.tenantCertificatePhoto}`}
                          alt="03"
                        />
                        <p>Tenant Certificate</p>
                      </figure>
                      {/* <figure>
                        <img src="../images/cropview.png" alt="04" />
                        <p>Caption</p>
                      </figure>
                      <figure>
                        <img src="../images/cropview.png" alt="05" />
                        <p>Caption</p>
                      </figure> */}
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Part-2 crop-details',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Cropping Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Crops Per Year
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Seed Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Cultivation System
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Mixed Cropping
                            <span>{surveyDetails.isMix || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Land Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Input Cost
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Mechanisation
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Crops Per Season
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>
                        Harvest Details
                        {' '}
                        <span>
                          Co ordinates:
                          {' '}
                          <strong>{`${surveyDetails.lat} , ${surveyDetails.lng}`}</strong>
                        </span>
                      </h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Manure Usage
                            <strong>(Unit)</strong>
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Irrigation Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Post Processing Type
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Total Production
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Fertilizer Usage
                            <strong>(Unit)</strong>
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Irrigation Source
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Harvest Buyer
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Warehouse Name
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.passbookPhoto}`}
                          alt="01"
                        />
                        <p>Passbook</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.landRecordPhoto}`}
                          alt="02"
                        />
                        <p>Land Record</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.tenantCertificatePhoto}`}
                          alt="03"
                        />
                        <p>Tenant Certificate</p>
                      </figure>
                      {/* <figure>
                        <img src="../images/cropview.png" alt="04" />
                        <p>Caption</p>
                      </figure>
                      <figure>
                        <img src="../images/cropview.png" alt="05" />
                        <p>Caption</p>
                      </figure> */}
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Part-3 Bank Details',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Bank Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Existing Loan
                            <span>
                              {surveyDetails.isLoan ? 'Yes' : 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Branch Name
                            <span>{surveyDetails.branchName || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Account Number
                            <strong>(Unit)</strong>
                            <span>
                              {surveyDetails.accountNumber || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Farmer Category
                            <span>
                              {surveyDetails.farmerCategory || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Bank Name
                            <span>{surveyDetails.bankName || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Branch Code
                            <span>{surveyDetails.ifscCode || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Account Type
                            <span>
                              {surveyDetails.accountType || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Farmer Type
                            <span>{surveyDetails.farmerType || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Bureau Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Pancard
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Credit Score
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Cost of Production
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Other Income
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Farmer ID
                            <span>{surveyDetails.farmerID || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Monthly Income
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Family Size
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Monthly EMI
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.passbookPhoto}`}
                          alt="01"
                        />
                        <p>Passbook</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.landRecordPhoto}`}
                          alt="02"
                        />
                        <p>Land Record</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.tenantCertificatePhoto}`}
                          alt="03"
                        />
                        <p>Tenant Certificate</p>
                      </figure>
                      {/* <figure>
                        <img src="../images/cropview.png" alt="04" />
                        <p>Caption</p>
                      </figure>
                      <figure>
                        <img src="../images/cropview.png" alt="05" />
                        <p>Caption</p>
                      </figure> */}
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Product Details',
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Product Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Scheme
                            <span>{surveyDetails.scheme || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Farmer Share
                            <span>
                              {surveyDetails.farmerShare || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            GOI Share
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Farmer Share
                            <span>
                              {surveyDetails.farmerShare || 'xxxxxxx'}
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Total Premium
                            <span>
                              {surveyDetails.totalPremium || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            State Share
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Sum Insured
                            <span>{surveyDetails.sumInsured || 'xxxxxxx'}</span>
                          </li>
                          <li>
                            Cutoff Date
                            <span>{surveyDetails.cutOfDate || 'xxxxxxx'}</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Submission Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Agent Name
                            {' '}
                            <span>{surveyDetails.agentName || 'xxxxxxx'}</span>
                          </li>
                          {/* <li>
                                                 Part 1 Date
                                                 <span>xxxxxxx</span>
                                                 </li> */}
                          <li>
                            Premium Collection
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Transaction Type
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Agent Mobile No.
                            <span>
                              {surveyDetails.agentMobile || 'xxxxxxx'}
                            </span>
                          </li>
                          <li>
                            Final Submission Date
                            <span>xxxxxxx</span>
                          </li>
                          {/* <li>
                                                 Smart Sampling
                                                 <span>xxxxxxx</span>
                                                 </li> */}
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.passbookPhoto}`}
                          alt="01"
                        />
                        <p>Passbook</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.landRecordPhoto}`}
                          alt="02"
                        />
                        <p>Land Record</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.tenantCertificatePhoto}`}
                          alt="03"
                        />
                        <p>Tenant Certificate</p>
                      </figure>
                      {/* <figure>
                        <img src="../images/cropview.png" alt="04" />
                        <p>Caption</p>
                      </figure>
                      <figure>
                        <img src="../images/cropview.png" alt="05" />
                        <p>Caption</p>
                      </figure> */}
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Satellite Verification',
            disabled: true,
            component: (
              <div className="survey-tab">
                <div className="survey-tab__content">
                  <div className="survey-tab__body">
                    <Grid container className="survey-tab__body__palette">
                      <h1>Land Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Land Shape File
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Identified Crop
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Harvesting Week
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Max NDVI
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Shape File Area
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Sowing Week
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Irrigation Status
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Avg NDVI
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container class="survey-tab__body__palette">
                      <h1>Yield Details</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__details">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul>
                          <li>
                            Health Status
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Field Relative Index
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Estimated Yield
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <ul className="survey-tab__body__details--right">
                          <li>
                            Loss Percentage
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            Avg Yield
                            <span>xxxxxxx</span>
                          </li>
                          <li>
                            DB Value
                            <span>xxxxxxx</span>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid container className="survey-tab__body__palette">
                      <h1>Sub Section C</h1>
                    </Grid>
                    <Grid container className="survey-tab__body__img">
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.passbookPhoto}`}
                          alt="01"
                        />
                        <p>Passbook</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.landRecordPhoto}`}
                          alt="02"
                        />
                        <p>Land Record</p>
                      </figure>
                      <figure>
                        <img
                          src={`${mediaURL}${surveyDetails.tenantCertificatePhoto}`}
                          alt="03"
                        />
                        <p>Tenant Certificate</p>
                      </figure>
                      {/* <figure>
                        <img src="../images/cropview.png" alt="04" />
                        <p>Caption</p>
                      </figure>
                      <figure>
                        <img src="../images/cropview.png" alt="05" />
                        <p>Caption</p>
                      </figure> */}
                    </Grid>
                  </div>
                  <Grid container className="survey-tab__footer">
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Delete</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">Download</button>
                    </Grid>
                    <Grid
                      item
                      className="survey-tab__footer--btn"
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <button type="button">CTA</button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          },
          {
            tabName: 'Summary',
            component: <div />,
          },
        ];
      }
    }
    return (
      <div className="survey-modal">
        {/* Condition for Loader.
            Used the same Popup Layout for Loader
         */}
        {experimentDetailLoading ? (
          <div>
            <div>
              <div className="survey-modal__content">
                <div className="survey-modal__header">
                  <h1>
                    Application Details
                    {' '}
                    <i
                      role="button"
                      tabIndex="0"
                      onKeyDown={closePopupFunc}
                      className="material-icons"
                      onClick={closePopupFunc}
                    >
                      close
                    </i>
                  </h1>
                </div>
              </div>
              <div className="survey-modal__body">
                <Grid container className="survey-modal__body__head">
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="survey-modal__body__head--left"
                  >
                    ID:
                    {/*   <div class="survey-modal__body__head--left">
                       ID:677667
                   </div>
                   <div class="survey-modal__body__head--right">
                       Date
                   </div> */}
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="survey-modal__body__head--right"
                  >
                    Date
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ScrollableTabPanel
                      tabsData={[
                        {
                          tabName: 'Part 1 Field Overview',
                          component: (
                            <div className="survey-tab">
                              <div className="survey-tab__content">
                                <div className="survey-tab__body">
                                  {/*                                  <Grid
                                 container
                                 className="survey-tab__body__palette"
                               >
                                 <h1>Loader</h1>
                               </Grid> */}
                                  <Grid
                                    container
                                    className="survey-tab__body__details"
                                  >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Loader />
                                    </Grid>
                                  </Grid>
                                </div>
                                <Grid container className="survey-tab__footer">
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">Delete</button>
                                  </Grid>
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">Download</button>
                                  </Grid>
                                  <Grid
                                    item
                                    className="survey-tab__footer--btn"
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={12}
                                  >
                                    <button type="button">CTA</button>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      customStyle="surveyTabPanel"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* Condition For Empty Data or Error
              Used the same Popup Layout for Loader
             */}
            {_.isEmpty(experimentDetail) && experimentDetailError !== '' && (
              <div>
                <div className="survey-modal__content">
                  <div className="survey-modal__header">
                    <h1>
                      Application Details
                      {' '}
                      <i
                        role="button"
                        tabIndex="0"
                        onKeyDown={closePopupFunc}
                        className="material-icons"
                        onClick={closePopupFunc}
                      >
                        close
                      </i>
                    </h1>
                  </div>
                </div>
                <div className="survey-modal__body">
                  <Grid container className="survey-modal__body__head">
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--left"
                    >
                      ID:
                      {/*   <div class="survey-modal__body__head--left">
                         ID:677667
                     </div>
                     <div class="survey-modal__body__head--right">
                         Date
                     </div> */}
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--right"
                    >
                      Date
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <ScrollableTabPanel
                        tabsData={[
                          {
                            tabName: 'Part 1 Field Overview',
                            component: (
                              <div className="survey-tab">
                                <div className="survey-tab__content">
                                  <div className="survey-tab__body">
                                    <Grid
                                      container
                                      className="survey-tab__body__palette"
                                    >
                                      <h1>Error</h1>
                                    </Grid>
                                    <Grid
                                      container
                                      className="survey-tab__body__details"
                                    >
                                      <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <h2>{experimentDetailError}</h2>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid
                                    container
                                    className="survey-tab__footer"
                                  >
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">Delete</button>
                                    </Grid>
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">Download</button>
                                    </Grid>
                                    <Grid
                                      item
                                      className="survey-tab__footer--btn"
                                      lg={4}
                                      md={4}
                                      sm={4}
                                      xs={12}
                                    >
                                      <button type="button">CTA</button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        customStyle="surveyTabPanel"
                      />
                    </Grid>
                  </Grid>
                </div>
                {/*        <h2>{description}</h2>
             <Button
               variant="contained"
               className="button btn-success startBtn"
               onClick={() => closePopupFunc()}
             >
               Close
             </Button> */}
                {/*        <Grid container className="root gis-main1" justify="center">
               <ScrollableTabPanel
                 tabsData={tabsData}
                 customStyle="surveyTabPanel"
               />
             </Grid> */}
              </div>
            )}
            {/* Condition of Data Success */}
            {!_.isEmpty(experimentDetail) && surveyDetails && (
              <div>
                <div className="survey-modal__content">
                  <div className="survey-modal__header">
                    <h1>
                      Application Details
                      {' '}
                      <i
                        role="button"
                        tabIndex="0"
                        onKeyDown={closePopupFunc}
                        className="material-icons"
                        onClick={closePopupFunc}
                      >
                        close
                      </i>
                    </h1>
                  </div>
                </div>
                <div className="survey-modal__body">
                  <Grid container class="survey-modal__body__head">
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--left"
                    >
                      ID:
                      {surveyDetails.displayID}
                      {/*   <div class="survey-modal__body__head--left">
                             ID:677667
                             </div>
                             <div class="survey-modal__body__head--right">
                             Date
                             </div> */}
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="survey-modal__body__head--right"
                    >
                      Date :
                      {`${new Date(surveyDetails.inspectionDate).getDate()} ${
                        months[
                          new Date(surveyDetails.inspectionDate).getMonth()
                        ]
                      } ${new Date(
                        surveyDetails.inspectionDate,
                      ).getFullYear()}`}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <ScrollableTabPanel
                        tabsData={tabsData}
                        customStyle="surveyTabPanel"
                      />
                    </Grid>
                  </Grid>
                </div>
                {/*        <h2>{description}</h2>
                 <Button
                 variant="contained"
                 className="button btn-success startBtn"
                 onClick={() => closePopupFunc()}
                 >
                 Close
                 </Button> */}
                {/*        <Grid container className="root gis-main1" justify="center">
                 <ScrollableTabPanel
                 tabsData={tabsData}
                 customStyle="surveyTabPanel"
                 />
                 </Grid> */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ survey }) => ({
      showPopup: survey.showPopup,
      markerIndex: survey.markerIndex,
      surveyData: survey.surveyData,
      experimentDetailLoading: survey.experimentDetailLoading,
      experimentDetail: survey.experimentDetail,
      experimentDetailError: survey.experimentDetailError,
      previewExperimentNumber: survey.previewExperimentNumber,
    }),
    dispatch => ({
      closePopupFunc: () => dispatch(closePopupAction()),
    }),
  )(withCookies(ApplicationSurveyPopup)),
);
