import ajax from '../Functions/ajax';

export const getChipsApi = () => (dispatch) => {
  ajax
    .get('https://info.labs.farmguide.in/webapi/ivrs/getChips')
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'CHIPS_DATA_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CHIPS_DATA_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'CHIPS_DATA_FAILURE',
        payload: err,
      });
    });
};

export const addMedia = data => (dispatch) => {
  ajax
    .post('https://info.labs.farmguide.in/webapi/media/addMedia', {
      data: {
        data: {
          actionType: data.actionType,
          description: data.description,
          language: data.language,
          mediaFile: data.mediaFile,
          name: data.name,
        },
      },
    })
    .then((response) => {
      if (response.status) {
        console.log('Hiiiiiiiiiiiiiii', response.data);
        dispatch({
          type: 'ADD_MEDIA_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'ADD_MEDIA_FAILURE',
          payload: response.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'ADD_MEDIA_FAILURE',
        payload: error,
      });
    });
};

export const createIvrs = data => (dispatch) => {
  ajax
    .post('https://info.labs.farmguide.in/webapi/ivrs/createIvrs', {
      data,
    })
    .then((response) => {
      console.log('API RESPONSE ====== ', response);
      if (response.data.status) {
        dispatch({
          type: 'CREATE_IVRS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'CREATE_IVRS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'CREATE_IVRS_FAILURE',
        payload: error,
      });
    });
};

export const playIvrs = data => (dispatch) => {
  ajax
    .post('https://info.labs.farmguide.in/webapi/ivrs/playIvrs', {
      data,
    })
    .then((response) => {
      console.log('API RESPONSE ====== ', response);
      if (response.data.status) {
        dispatch({
          type: 'PLAY_IVRS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'PLAY_IVRS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'PLAY_IVRS_FAILURE',
        payload: error,
      });
    });
};

export const getMediaList = params => (dispatch) => {
  ajax
    .get('https://info.labs.farmguide.in/webapi/media', {
      params,
    })
    .then((response) => {
      console.log('API RESPONSE ====== ', response);
      if (response.data.status) {
        dispatch({
          type: 'MEDIA_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'MEDIA_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'MEDIA_LIST_FAILURE',
        payload: err,
      });
    });
};

export const scheduleIvrs = data => (dispatch) => {
  ajax
    .post('https://info.labs.farmguide.in/webapi/calendar/addCalendarDemo', {
      data,
    })
    .then((response) => {
      console.log('API RESPONSE ====== ', response);
      if (response.data.status) {
        dispatch({
          type: 'SCHEDULE_IVRS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'SCHEDULE_IVRS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'SCHEDULE_IVRS_FAILURE',
        payload: error,
      });
    });
};

export const getIvrsList = data => (dispatch) => {
  ajax
    .post('https://info.labs.farmguide.in/webapi/advisory/list', {
      data,
    })
    .then((response) => {
      console.log('API RESPONSE ====== ', response);
      if (response.data.status) {
        dispatch({
          type: 'IVRS_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'IVRS_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'IVRS_LIST_FAILURE',
        payload: error,
      });
    });
};

export const resetDetails = () => ({
  type: 'RESET_DETAILS',
});
