/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper
} from '@material-ui/core';
import Datetime from 'react-datetime';
import moment from 'moment';
// import _ from 'lodash';
import TaggingFixedPanel from '../tagging/common/taggingFixedPanel';
import ListModal from '../tagging/listModal';
import UploadModal from '../tagging/uploadModal';
import FarmerListModal from '../tagging/farmerListModal';
import FgFilterModal from '../tagging/fgFilterModal';
import UploadViewFormatModal from '../ivrs/uploadViewFormatModal';

import Toastr from '../common/toastr';
import { playIvrs, scheduleIvrs, resetDetails } from '../../Actions/ivrsAction';
import {
  farmeList,
  resetTaggingFarmerDetails,
  resetTaggedFarmerList,
  resetUploadFarmerDetails,
  uploadFarmers
} from '../../Actions/taggingAction';
import { resetLocationAct } from '../../Actions/locationHierarchyAction';
import '../../css/common.scss';
import '../../css/form.scss';
import '../../css/table.scss';
import '../../css/tagging/main.scss';
import '../../css/tagging/schedule.scss';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTime: '',
      checked: false,
      mobileNo: '',
      // selected: [],
      istoastrOpen: false,
      toastrMessage: '',
      toastrMessageType: '',
      error: '',
      showModal: false,
      selectedFarmerList: [],
      confirmUpload: false,
      isFarmerTagged: false,
      showUploadModal: false,
      showModalOpen: false,
      uploadCheckbox: false,
      isFgModalOpen: false,
      isFormatModalOpen: false,
      uploadTaggedFarmerList: [],
      uploadError: ''
    };
  }

  componentDidMount() {
    const { resetLocationDetails, resetTaggedList } = this.props;
    resetLocationDetails();
    resetTaggedList();
  }

  componentDidUpdate(prevProps) {
    const { ivrsSchedule } = this.props;
    if (ivrsSchedule !== false && prevProps.ivrsSchedule === false) {
      this.showMessageType(ivrsSchedule);
    }
    // if (playIvrs !== false && prevProps.playIvrs === false) {
    //   this.showMessageType(playIvrs);
    // }
  }

  showMessageType = status => {
    const { resetIvrsDetails } = this.props;
    if (status === true) {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'IVRS Schedule Successfully',
        toastrMessageType: 'success'
      });
      resetIvrsDetails();
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'IVRS Not Schedule',
        toastrMessageType: 'error'
      });
      resetIvrsDetails();
    }
  };

  handleChange = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
  };

  handleDateTime = momt => {
    this.setState({
      dateTime: moment(momt._d)
        .format()
        .slice(0, 16)
    });
  };

  valid = current => {
    const yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  handleMobileChange = e => {
    const mobileRegex = /^[6-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(mobileRegex) && e.target.value !== '') {
      return;
    }
    this.setState({
      mobileNo: e.target.value
    });
  };

  handleTest = () => {
    const { mobileNo } = this.state;
    const { ivrsData, ivrsPlay } = this.props;
    let check = true;
    let error = '';
    if (!mobileNo) {
      check = false;
      error = 'Please enter the mobile no.';
    }
    if (check) {
      const data = {
        ivrsId: ivrsData.advisoryIvrsId,
        mobile: mobileNo
      };
      ivrsPlay(data);
      this.setState({
        error: ''
      });
    } else {
      this.setState({
        error
      });
    }
  };

  /*  isSelected = (id) => {
    const { selected } = this.state;
    return selected.indexOf(id) !== -1;
  };

  handleCheck = (farmerId) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(farmerId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, farmerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState(
      {
        selected: newSelected,
      },
      () => {
        console.log('selected>>', selected);
      },
    );
  }; */

  handleSchedule = () => {
    const { dateTime, checked, uploadTaggedFarmerList } = this.state;
    const { ivrsData, scheduleCall, selectedId } = this.props;
    let check = true;
    let error = '';
    if (!dateTime) {
      error = 'Please select date and time';
      check = false;
    }
    const uploadFilterList = uploadTaggedFarmerList.map(obj => ({
      name: obj.name,
      farmerID: obj.farmerID,
      mobile: obj.mobile,
      sms: true,
      ivrs: false
    }));
    console.log('uploaded', uploadFilterList);
    // if (taggedFarmerList.length === 0) {
    //   error = 'Please Add Farmer to the List';
    //   check = false;
    // }
    // if (selected.length === 0) {
    //   error = 'Please select the farmer';
    //   check = false;
    // }
    if (!checked) {
      error = 'Please Acknowledge';
      check = false;
    }
    if (check) {
      const data = {
        calendarType: 'IVRS',
        // criteria: taggedFarmerList,
        calendarEvents: [
          {
            advisoryIvrsId: selectedId || ivrsData.advisoryIvrsId,
            date: dateTime
          }
        ]
      };
      scheduleCall(data);
      this.setState({
        error: ''
      });
    } else {
      this.setState({
        error
      });
    }
  };

  handleModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  /*  addToList = () => {
    const { selected, selectedFarmerList } = this.state;
    const { farmersList } = this.props;
    const { farmers } = farmersList;
    const newFarmerList = farmers.filter(item => selected.includes(item.orgFarmerID));
    const newList = _.unionBy(
      selectedFarmerList,
      newFarmerList,
      item => item.orgFarmerID,
    );
    const updatedList = newList.filter(item => selected.includes(item.orgFarmerID));
    if (newFarmerList && newFarmerList.length > 0) {
      this.setState({
        selectedFarmerList: updatedList,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
      });
    }
  }; */

  handleUploadCheckbox = () => {
    const { uploadCheckbox } = this.state;
    this.setState({
      uploadCheckbox: !uploadCheckbox
    });
  };

  handleFileSelected = e => {
    this.setState({
      selectedFile: e.target.files[0]
    });
  };

  fileUploadHandler = () => {
    const { selectedFile, uploadCheckbox } = this.state;
    const { uploadFile, cookies } = this.props;
    const userData = cookies.get('userData');
    let check = true;
    let uploadError = '';
    if (!uploadCheckbox) {
      check = false;
      uploadError = 'Please Acknowledge';
    }
    if (selectedFile === null) {
      check = false;
      uploadError = 'Please select the file';
    }
    if (check) {
      if (selectedFile && uploadCheckbox) {
        const formData = new FormData();
        formData.append('farmer', selectedFile);
        if (userData) {
          uploadFile(formData, userData.token);
        }
        // uploadFile(formData);
        this.setState({
          uploaded: true,
          uploadError: ''
        });
      }
    } else {
      this.setState({
        uploadError
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      showModalOpen: false
    });
  };

  handleConfirmUpload = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      confirmUpload: true,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  uploadAgain = () => {
    const { resetUploadDetails } = this.props;
    this.setState({
      uploaded: false,
      uploadCheckbox: false
    });
    resetUploadDetails();
  };

  uploadModal = () => {
    this.setState({
      showUploadModal: true
    });
  };

  handleCloseUploadModal = () => {
    this.setState({
      showUploadModal: false,
      uploadCheckbox: false
    });
  };

  handleViewTaggedList = () => {
    this.setState({
      showModalOpen: true
    });
  };

  handleUpdateTaggedList = list => {
    if (list.length > 0) {
      this.setState({
        // taggedFarmerList: list,
        uploadTaggedFarmerList: list,
        istoastrOpen: true,
        toastrMessage: 'Farmers Added Successfully',
        toastrMessageType: 'success',
        isFarmerTagged: true
      });
    } else {
      this.setState({
        istoastrOpen: true,
        toastrMessage: 'No Farmer Selected',
        toastrMessageType: 'error',
        isFarmerTagged: false
      });
    }
  };

  handleOpenFgModal = () => {
    this.setState({
      isFgModalOpen: true
    });
  };

  handleViewFormatModal = () => {
    this.setState({
      isFormatModalOpen: true
    });
  };

  handleCloseFgModal = () => {
    this.setState({
      isFgModalOpen: false
    });
  };

  handleViewFormatClose = () => {
    this.setState({
      isFormatModalOpen: false
    });
  };

  render() {
    const {
      checked,
      mobileNo,
      dateTime,
      toastrMessage,
      toastrMessageType,
      istoastrOpen,
      error,
      selectedFarmerList,
      showModal,
      confirmUpload,
      selectedFile,
      isFarmerTagged,
      showUploadModal,
      showModalOpen,
      uploadCheckbox,
      uploaded,
      isFgModalOpen,
      uploadTaggedFarmerList,
      uploadError,
      isFormatModalOpen
    } = this.state;
    const {
      //  farmersList,
      title,
      desc,
      handlePrevious,
      // broadcastLocationChange,
      //  farmerListError,
      farmersUploaded,
      farmersUploadError,
      taggedListFarmer
    } = this.props;
    const { totalCount } = taggedListFarmer;
    let count = 0;
    if (totalCount) {
      count += parseInt(totalCount);
    }
    if (uploadTaggedFarmerList.length && uploadTaggedFarmerList.length > 0) {
      count += uploadTaggedFarmerList.length;
    }
    const splitDate = dateTime.split('T');
    return (
      <div className="root">
        <Grid container className="taggingMain" spacing={2}>
          <Grid item xs={9} className="insightLeftPanel">
            <h5>Ivrs</h5>
            <ul className="breadCrumb">
              <li className="backLink">
                <Button
                  variant="contained"
                  style={{
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                  }}
                  onClick={() => handlePrevious()}
                >
                  <i className="material-icons">keyboard_backspace</i>
                  Back
                </Button>
                <i className="material-icons line">remove</i>
              </li>
            </ul>
            <div className="farmerSelectionPanel">
              <Grid container spacing={2} className="addFarmerWithOutput">
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className="selectAddFarmers darkgreenBtnHeader"
                >
                  <h4>Select Farmers </h4>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            totalCount !== 0 && totalCount !== undefined
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {totalCount !== 0 && totalCount !== undefined
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6}>
                        <h6>Add Farmers</h6>
                        <p>
                          Total farmers :<span> {totalCount || 0} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <Button
                          className="upload-btn textCenter"
                          onClick={this.fileUploadHandler}
                          disabled
                        >
                          View List
                        </Button>
                      </Grid>
                      <Grid item lg={2} />
                      <Grid item lg={10} md={12} xs={12} className="btn-panel">
                        <Button
                          className="upload-enable textCenter mr"
                          onClick={this.handleOpenFgModal}
                          // disabled
                        >
                          FG Database
                        </Button>
                        <Button
                          className="upload-btn-disable textCenter"
                          disabled
                        >
                          My Resources
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            uploadTaggedFarmerList &&
                            uploadTaggedFarmerList.length > 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {uploadTaggedFarmerList &&
                          uploadTaggedFarmerList.length > 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={7}>
                        <h6>Upload your farmers</h6>
                        <p>Upload your farmer’s database</p>
                        <p>
                          Total farmers :
                          <span>{uploadTaggedFarmerList.length} </span>
                        </p>
                      </Grid>
                      <Grid item lg={3}>
                        <Button
                          className={`${
                            !confirmUpload ? 'upload-btn' : 'upload-enable'
                          } textCenter`}
                          disabled={!confirmUpload}
                          onClick={this.handleModal}
                        >
                          View List
                        </Button>
                      </Grid>
                      <Grid item lg={2} />
                      <Grid item lg={3} className="btn-panel">
                        <Button
                          className="upload-btn-disable textCenter"
                          onClick={this.uploadModal}
                          disabled
                        >
                          Upload
                        </Button>
                      </Grid>
                      <Grid item lg={7}>
                        <h5 className="upload-view">
                          <Button
                            onClick={this.handleViewFormatModal}
                            style={{ pointerEvents: 'none', cursor: 'default' }}
                          >
                            View Format
                          </Button>
                        </h5>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12}>
                        <div className="upload-box-footer">
                          {!confirmUpload ? (
                            <p className="footer-content">No file uploaded</p>
                          ) : (
                            <p className="footer-content">
                              Uploaded file: <span>{selectedFile.name}</span>
                              <i className="material-icons">clear</i>
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="upload-box">
                    <Grid container className="upload-content">
                      <Grid item lg={2}>
                        <i
                          className={`${
                            count !== 0
                              ? 'material-icons tickIcon active'
                              : 'material-icons tickIcon'
                          }
                            `}
                        >
                          {count !== 0
                            ? 'check_circle'
                            : 'check_circle_outline'}
                        </i>
                      </Grid>
                      <Grid item lg={6}>
                        <h6>To Be Tagged Farmers</h6>
                        {/* <p>Upload your farmer’s database</p> */}
                        <p>
                          Total farmers :<span> {count} </span>
                        </p>
                      </Grid>
                      <Grid item lg={4}>
                        <Button
                          className={`${
                            !isFarmerTagged ? 'upload-btn' : 'upload-enable'
                          } textCenter`}
                          disabled={!isFarmerTagged}
                          onClick={this.handleViewTaggedList}
                        >
                          View List
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={6} className="finalOutput">
                  <h4 className="blackBorder">Final Output</h4>
                  <div className="finalOutputImg blackBorder">
                    <div className="finalOutputDetail">
                      <h5>
                        Ivrs Title:
                        {title}
                      </h5>
                      <p>
                        <span>Ivrs Description:</span>
                        {desc}
                      </p>
                    </div>
                  </div>
                  <div className="scheduleTime blackBorder">
                    <h4>Schedule date & time</h4>
                    <div className="dateTimePicker">
                      <i className="material-icons calender-icon">date_range</i>
                      <Datetime
                        isValidDate={this.valid}
                        onChange={this.handleDateTime}
                        inputProps={{ placeholder: 'Select date and time' }}
                      />
                    </div>
                    <ul className="dateTimeValue">
                      <li>
                        Date:
                        <span>{splitDate[0]}</span>
                      </li>
                      <li>
                        Time:
                        <span>{splitDate[1]}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="scheduleSms darkgreenBtnHeader">
                    <div className="enterNumber">
                      <TextField
                        id="mobileN"
                        placeholder="Enter Mobile Number"
                        className="textField inputHead customText-width"
                        value={mobileNo}
                        onChange={this.handleMobileChange}
                        inputProps={{ maxLength: 10 }}
                      />
                      <Button
                        className="darkgreenBtn"
                        onClick={this.handleTest}
                      >
                        Test Ivrs
                      </Button>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={this.handleChange}
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="I hereby acknowledge that this message doesn’t carry any unwanted or abusive words  "
                      className="acknowledgeCheckbox"
                    />
                    <Paper className="scheduleSmsBtn">
                      <Button
                        className="darkgreenBtn"
                        onClick={this.handleSchedule}
                      >
                        Schedule Ivrs
                      </Button>
                      {error ? <p className="errorMsg1"> {error} </p> : null}
                    </Paper>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <TaggingFixedPanel />
          {showModal && (
            <ListModal
              show={showModal}
              onClose={this.handleCloseModal}
              farmerList={selectedFarmerList}
              updateTaggedList={this.handleUpdateTaggedList}
            />
          )}
          <UploadModal
            show={showUploadModal}
            onClose={this.handleCloseUploadModal}
            handleChange={this.handleFileSelected}
            uploadHandler={this.fileUploadHandler}
            uploaded={uploaded}
            uploadAgain={this.uploadAgain}
            farmersUploaded={farmersUploaded}
            uploadCheckbox={uploadCheckbox}
            handleUploadCheckbox={this.handleUploadCheckbox}
            confirmUpload={this.handleConfirmUpload}
            uploadError={uploadError}
            farmersUploadError={farmersUploadError}
          />
          {isFgModalOpen && (
            <FgFilterModal
              show={isFgModalOpen}
              onClose={this.handleCloseFgModal}
            />
          )}
          <FarmerListModal
            show={showModalOpen}
            onClose={this.handleModalClose}
            taggedList={uploadTaggedFarmerList}
          />
          <UploadViewFormatModal
            showModal={isFormatModalOpen}
            onClose={this.handleViewFormatClose}
          />
          <Toastr
            message={toastrMessage}
            show={istoastrOpen}
            handleClose={this.handleToastrClose}
            messageType={toastrMessageType}
          />
        </Grid>
      </div>
    );
  }
}
export default withRouter(
  connect(
    ({ ivrs, tagging, location }) => ({
      ivrsData: ivrs.ivrsData,
      pingedIvrs: ivrs.pingedIvrs,
      farmersList: tagging.farmersList,
      ivrsSchedule: ivrs.ivrsSchedule,
      farmerListError: tagging.farmerListError,
      taggedListFarmer: tagging.taggedListFarmer,
      farmersUploadError: tagging.farmersUploadError,
      farmersUploaded: tagging.farmersUploaded,
      location
    }),
    dispatch => ({
      ivrsPlay: data => dispatch(playIvrs(data)),
      getFarmerList: data => dispatch(farmeList(data)),
      scheduleCall: data => dispatch(scheduleIvrs(data)),
      resetIvrsDetails: () => dispatch(resetDetails()),
      resetFarmerDetails: () => dispatch(resetTaggingFarmerDetails()),
      resetLocationDetails: () => dispatch(resetLocationAct()),
      resetTaggedList: () => dispatch(resetTaggedFarmerList()),
      resetUploadDetails: () => dispatch(resetUploadFarmerDetails()),
      uploadFile: (data, token) => dispatch(uploadFarmers(data, token))
    })
  )(withCookies(Schedule))
);
