import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
} from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartComponent = (props) => {
  const {
    data, dataKey, showLegend, outerRadius,
  } = props;
  return (
    <ResponsiveContainer width="100%" height={270}>
      <PieChart>
        <Pie
          dataKey={dataKey}
          className="surveyPieChart"
          data={data}
          cx={100}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={outerRadius || 80}
          fill="#8884d8"
        >
          {data.map(entry => (
            <Cell key={entry.name} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        {showLegend && (
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
