/* eslint-disable */
import React from 'react';
import { Grid, Link, Paper, Button } from '@material-ui/core';
import '../../css/tagging/campaginList.scss';

class CampaginList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = id => {
    const { campaignSelected } = this.props;
    campaignSelected(id);
  };

  render() {
    const { campaignList, selectedCampaignId, type } = this.props;
    console.log(campaignList);
    return (
      <React.Fragment>
        <div className="reportLeft">
          {/*   <div className="insightLeftPanel">
            <h5>
              Reports
              <Link to="/">edit setting</Link>
            </h5>
          </div>*/}
          <Grid container className="leftAside">
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <div className="basePanel">
                <div className="member_listHead">
                  <i
                    className="material-icons deleteIcons"
                    style={{ cursor: 'default' }}
                  >
                    delete
                  </i>
                  <i className="material-icons" style={{ cursor: 'default' }}>
                    access_time
                  </i>
                </div>
                <div className="member_list">
                  <ul>
                    {campaignList &&
                      campaignList.length > 0 &&
                      campaignList.map(item => (
                        <li
                          className={`${
                            selectedCampaignId === item.campaignID
                              ? 'left active'
                              : 'left'
                          }`}
                          key={item.campaignID}
                          onClick={() => this.handleClick(item.campaignID)}
                        >
                          <span className="list-img pull-left">
                            {type === 'tagging' ? (
                              <img
                                src={
                                  item.mediaLocation ||
                                  'images/defaultPoster.png'
                                }
                                alt="img"
                                className="img-circle"
                              />
                            ) : (
                              <img
                                src="images/phone-call.svg"
                                alt="User Avatar"
                                className="img-circle img-sms"
                              />
                            )}
                          </span>
                          <div className="list-body">
                            <div className="list-heading">
                              <h1>
                                {' '}
                                {item.campaignTitle} <span>SMS</span>
                              </h1>
                              <h2>
                                Total Farmers:
                                {item.totalFarmers}
                              </h2>
                            </div>
                            <div className="sub-heading">
                              <h1 className="pull-left">
                                Date:{' '}
                                <strong>
                                  {`${item.createdAt.split(' ')[0]}
                                  `}
                                </strong>{' '}
                                <span className="pull-right">
                                  {item.createdAt.split(' ')[1].substring(0, 5)}
                                </span>
                              </h1>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <Paper className="withBorder">
                  <div className="initiateCampagin">
                    Happy with your campaigns? Initiate more!
                  </div>
                  <div className="addMore">
                    Add more farmers and campaigns in your portfolio
                  </div>
                  <Grid container className="btn">
                    <Grid item lg={5} sm={5} md={5} xs={12}>
                      <Button
                        variant="contained"
                        className="btnAlign"
                        style={{ cursor: 'default' }}
                      >
                        Farmers Tagging
                      </Button>
                    </Grid>
                    <Grid item lg={2} sm={2} md={2} xs={12}></Grid>
                    <Grid item lg={5} sm={5} md={5} xs={12}>
                      <Button
                        variant="contained"
                        className="btnAlign btn-sm"
                        style={{ cursor: 'default' }}
                      >
                        IVRS
                      </Button>
                    </Grid>
                  </Grid>
                  {/*                  <div className="btn">
                    <Button
                      variant="contained"
                      className="btnAlign"
                      style={{ cursor: 'default' }}
                    >
                      Farmers Tagging
                    </Button>
                    <Button
                      variant="contained"
                      className="btnAlign btn-sm"
                      style={{ cursor: 'default' }}
                    >
                      IVRS
                    </Button>
                  </div>*/}
                </Paper>
              </div>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              {/*              <Paper className="withBorder">
                <div className="initiateCampagin">
                  Happy with your campaigns? Initiate more!
                </div>
                <div className="addMore">
                  Add more farmers and campaigns in your portfolio
                </div>
                <div className="btn">
                  <Button
                    variant="contained"
                    className="btnAlign"
                    style={{ cursor: 'default' }}
                  >
                    Farmers Tagging
                  </Button>
                  <Button
                    variant="contained"
                    className="btnAlign"
                    style={{ cursor: 'default' }}
                  >
                    IVRS
                  </Button>
                </div>
              </Paper>*/}
            </Grid>
          </Grid>
          {/*     <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="insightLeftPanel"
          >
            <h5>
              Reports
              <Link to="/">edit setting</Link>
            </h5>

            <Paper className="campaginDetials">
              <Typography>
                <i className="material-icons deleteIcons">delete</i>
                <i className="material-icons">access_time</i>
              </Typography>
            </Paper>
            {campaignList
              && campaignList.length > 0
              && campaignList.map(item => (
                <Paper className="customPadding" key={Math.random()}>
                  <div className="commonLayout">
                    <img
                      src="/images/posterImg.png"
                      alt="campagin-list-img"
                      className="img-responsive"
                    />
                  </div>
                  <div className="content">
                    <div className="campaginName">
                      {item.campaignTitle}
                      <div className="count">
                        Total Farmers:
                        {' '}
                        <span>{item.totalFarmers}</span>
                      </div>
                      <div className="count">
                        Date:
                        {' '}
                        <span>
                          {`${item.createdAt.split('T')[0].substring(8, 10)}
                          ${item.createdAt.split('T')[0].substring(4, 8)}
                          ${item.createdAt.split('T')[0].substring(0, 4)}
                         `}
                        </span>
                        <span className="rightAlign">
                          {item.createdAt.split('T')[1].substring(0, 5)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>
              ))}
            <Paper className="withBorder">
              <div className="initiateCampagin">
                Happy with your campaigns? Initiate more!
              </div>
              <div className="addMore">
                Add more farmers and campaigns in your portfolio
              </div>
              <div className="btn">
                <Button variant="contained" className="btnAlign">
                  Farmers Tagging
                </Button>
                <Button variant="contained" className="btnAlign">
                  IVRS
                </Button>
              </div>
            </Paper>
          </Grid> */}
        </div>
      </React.Fragment>
    );
  }
}

export default CampaginList;
