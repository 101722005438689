import ajax from '../Functions/ajax';

export const setLocationAct = (level, selectedLocation) => (dispatch) => {
  switch (level) {
    case 'STATE':
      dispatch({
        type: 'SET_SELECTED_STATE',
        payload: selectedLocation,
        level,
      });
      break;
    case 'DISTRICT':
      dispatch({
        type: 'SET_SELECTED_DISTRICT',
        payload: selectedLocation,
        level,
      });
      break;
    case 'TEHSIL':
      dispatch({
        type: 'SET_SELECTED_TEHSIL',
        payload: selectedLocation,
        level,
      });
      break;
    case 'VILLAGE':
      dispatch({
        type: 'SET_SELECTED_VILLAGE',
        payload: selectedLocation,
        level,
      });
      break;
    default:
      break;
  }
};

// export const getFarmProfileData = (data) => dispatch => ajax
// .get('http://localhost:3009/v1/farms/farms/generalStatsPanel', {
//   params: data,
// })
// .then((response) => {
//   if (response.data.status) {
//     dispatch({
//           type: 'FARM_PROFILE_DATA_SUCCESS',
//           payload: response.data.data,
//     });
//   } else {
//     dispatch({
//           type: 'FARM_PROFILE_DATA_FAILURE',
//           payload: response.data.error,
//     });
//   }
// })
// .catch((error) => {
//   dispatch({
//       type: 'FARM_PROFILE_DATA_FAILURE',
//       payload:error,
//   });
// })

export const getHierarchyDataAct = (level, levelID) => dispatch => ajax
  .get('/maps/maps/locationHeirarchy', {
    params: {
      fetchLevel: level,
      parentLevelID: levelID,
    },
    isLoader: 'false',
  })
  .then((response) => {
    if (response.data.status) {
      switch (level) {
        case 'STATE':
          dispatch({
            type: 'GET_STATE_SUCCESS',
            payload: response.data.data,
          });
          break;
        case 'DISTRICT':
          dispatch({
            type: 'GET_DISTRICT_SUCCESS',
            payload: response.data.data,
          });
          break;
        case 'TEHSIL':
          dispatch({
            type: 'GET_TEHSIL_SUCCESS',
            payload: response.data.data,
          });
          break;
        case 'VILLAGE':
          dispatch({
            type: 'GET_VILLAGE_SUCCESS',
            payload: response.data.data,
          });
          break;
        default:
          break;
      }
    } else {
      switch (level) {
        case 'STATE':
          dispatch({
            type: 'GET_STATE_FAILURE',
            payload: response.data.error,
          });
          break;
        case 'DISTRICT':
          dispatch({
            type: 'GET_DISTRICT_FAILURE',
            payload: response.data.error,
          });
          break;
        case 'TEHSIL':
          dispatch({
            type: 'GET_TEHSIL_FAILURE',
            payload: response.data.error,
          });
          break;
        case 'VILLAGE':
          dispatch({
            type: 'GET_VILLAGE_FAILURE',
            payload: response.data.error,
          });
          break;
        default:
          break;
      }
    }
  })
  .catch((error) => {
    switch (level) {
      case 'STATE':
        dispatch({ type: 'GET_STATE_FAILURE', payload: error });
        break;
      case 'DISTRICT':
        dispatch({ type: 'GET_DISTRICT_FAILURE', payload: error });
        break;
      case 'TEHSIL':
        dispatch({ type: 'GET_TEHSIL_FAILURE', payload: error });
        break;
      case 'VILLAGE':
        dispatch({ type: 'GET_VILLAGE_FAILURE', payload: error });
        break;
      default:
        break;
    }
  });

export const setSelectedYearAct = year => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_YEAR',
    payload: year,
  });
};

export const setSelectedSeasonAct = season => (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_SEASON',
    payload: season,
  });
};

export const resetLocationAct = () => ({
  type: 'RESET_LOCATION_DETAILS',
});
