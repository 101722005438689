import ajax from '../Functions/ajax';

export const setAccountsLocationAct = (level, selectedLocation) => (dispatch) => {
  switch (level) {
    case 'STATE':
      dispatch({
        type: 'SET_ACCOUNTS_STATE',
        payload: selectedLocation,
        level,
      });
      break;
    case 'DISTRICT':
      dispatch({
        type: 'SET_ACCOUNTS_DISTRICT',
        payload: selectedLocation,
        level,
      });
      break;
    case 'TEHSIL':
      dispatch({
        type: 'SET_ACCOUNTS_TEHSIL',
        payload: selectedLocation,
        level,
      });
      break;
    case 'VILLAGE':
      dispatch({
        type: 'SET_ACCOUNTS_VILLAGE',
        payload: selectedLocation,
        level,
      });
      break;
    default:
      break;
  }
};

export const getAccountsState = () => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: null,
  // });
  ajax
    .get('/maps/maps/locationHeirarchy', {
      params: {
        fetchLevel: 'STATE',
      },
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'GET_ACCOUNTS_STATE_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'GET_ACCOUNTS_STATE_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: 'GET_ACCOUNTS_STATE_FAILURE', payload: error });
    });
};

export const getAccountsHierarchyAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: null,
  // });
  ajax
    .post('/services/hr_land_records/locationHierarchy', {
      data: {
        stateId: params.stateId,
        districtCode: params.districtCode,
        tehsilCode: params.tehsilCode,
        level: params.level,
      },
    })
    .then((response) => {
      if (response.data.status) {
        switch (params.level) {
          case 3:
            dispatch({
              type: 'GET_ACCOUNTS_DISTRICT_SUCCESS',
              payload: response.data.data,
            });
            break;
          case 4:
            dispatch({
              type: 'GET_ACCOUNTS_TEHSIL_SUCCESS',
              payload: response.data.data,
            });
            break;
          case 6:
            dispatch({
              type: 'GET_ACCOUNTS_VILLAGE_SUCCESS',
              payload: response.data.data,
            });
            break;
          default:
            break;
        }
      } else {
        switch (params.level) {
          case 3:
            dispatch({
              type: 'GET_ACCOUNTS_DISTRICT_FAILURE',
              payload: response.data.error,
            });
            break;
          case 4:
            dispatch({
              type: 'GET_ACCOUNTS_TEHSIL_FAILURE',
              payload: response.data.error,
            });
            break;
          case 6:
            dispatch({
              type: 'GET_ACCOUNTS_VILLAGE_FAILURE',
              payload: response.data.error,
            });
            break;
          default:
            break;
        }
      }
    })
    .catch((error) => {
      switch (params.level) {
        case 3:
          dispatch({ type: 'GET_ACCOUNTS_DISTRICT_FAILURE', payload: error });
          break;
        case 4:
          dispatch({ type: 'GET_ACCOUNTS_TEHSIL_FAILURE', payload: error });
          break;
        case 6:
          dispatch({ type: 'GET_ACCOUNTS_VILLAGE_FAILURE', payload: error });
          break;
        default:
          break;
      }
    });
};

export const fetchMurabbaListAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: null,
  // });
  ajax
    .post('/services/hr_land_records/murabba_list', {
      data: {
        districtCode: params.districtCode,
        tehsilCode: params.tehsilCode,
        villageCode: params.villageCode,
      },
      // isLoader: 'true',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_MURABBA_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_MURABBA_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_MURABBA_LIST_FAILURE',
        payload: error,
      });
    });
};

export const fetchKhasraKhataAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: null,
  // });
  ajax
    .post('/services/hr_land_records/khasra_khata_list', {
      data: {
        districtCode: params.districtCode,
        tehsilCode: params.tehsilCode,
        villageCode: params.villageCode,
        murrabbaNo: params.murrabbaNo,
      },
      // isLoader: 'true',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_KHASRA_KHATA_LIST_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_KHASRA_KHATA_LIST_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_KHASRA_KHATA_LIST_FAILURE',
        payload: error,
      });
    });
};

export const fetchFarmOwnerAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: 'farmowner',
  // });
  ajax
    .post('/services/hr_land_records/farm_owner', {
      data: {
        districtCode: params.districtCode,
        tehsilCode: params.tehsilCode,
        villageCode: params.villageCode,
        murrabbaNo: params.murrabbaNo,
        khasraNo: params.khasraNo,
      },
      // isLoader: 'true',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_FARM_OWNER_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_FARM_OWNER_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_FARM_OWNER_FAILURE',
        payload: error,
      });
    });
};

export const fetchFarmBoundaryAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: 'farmboundary',
  // });
  ajax
    .post('/services/hr_land_records/farm_boundaries', {
      data: {
        districtCode: params.districtCode,
        tehsilCode: params.tehsilCode,
        villageCode: params.villageCode,
        murrabbaNo: params.murrabbaNo,
        khasraNo: params.khasraNo,
      },
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_FARM_BOUNDARY_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_FARM_BOUNDARY_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_FARM_BOUNDARY_FAILURE',
        payload: error,
      });
    });
};

export const resetFarmAccountsAct = value => (dispatch) => {
  dispatch({
    type: 'RESET_FARM_ACCOUNTS',
    payload: value,
  });
};

export const fetchFarmDetailsAct = params => (dispatch) => {
  // dispatch({
  //   type: 'FARM_ACCOUNTS_LOADING',
  //   payload: null,
  // });
  ajax
    .post('/services/hr_land_records/farm_crops', {
      data: {
        districtName: params.districtName,
        coordinates: params.coordinates,
      },
      // isLoader: 'true',
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'FETCH_FARM_DETAILS_SUCCESS',
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: 'FETCH_FARM_DETAILS_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_FARM_DETAILS_FAILURE',
        payload: error,
      });
    });
};
