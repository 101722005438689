import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import {
  Grid, FormControl, MenuItem, Select, Button,
} from '@material-ui/core';
import ModalHOC from '../common/Modal/index';
import {
  getTaggedFarmerList,
  getCropByLocation,
} from '../../Actions/taggingAction';
import LocationHeirarchy from '../farmEngine/locationHeirarchy';

class FgFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pincode: '',
      ownership: '',
      crop: '',
      landSize: '',
      districtNameCrop: '',
      error: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.selectedDistrictID !== '') {
      this.updateCropData(location.selectedDistrictID);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.selectedDistrictID !== prevProps.location.selectedDistrictID) {
      this.updateCropData(location.selectedDistrictID);
    }
  }

  updateCropData = (districId) => {
    const { getCrops, location } = this.props;
    const { district } = location;
    const districtObj = district.find(o => o.districtID === districId);
    if (districtObj) {
      const { districtName } = districtObj;
      this.setState(
        {
          districtNameCrop: districtName,
        },
        () => {
          const data = {
            districtID: districtName,
          };
          getCrops(data);
        },
      );
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handlePincodeChange = (e) => {
    const pincodeRegex = /^[1-9]+[0-9]{0,9}$/i;
    if (!e.target.value.match(pincodeRegex) && e.target.value !== '') {
      return;
    }
    this.setState({
      pincode: e.target.value,
    });
  };

  _handleOwnershipChange = (ev) => {
    this.setState({
      ownership: ev.target.value,
    });
  };

  _handleLandChange = (ev) => {
    this.setState({
      landSize: ev.target.value,
    });
  };

  _handleCropChange = (ev) => {
    this.setState({
      crop: ev.target.value,
    });
  };

  _handleFilter = () => {
    const {
      pincode, ownership, crop, landSize,
    } = this.state;
    const { location, getFarmerList } = this.props;
    const {
      state,
      district,
      tehsil,
      selectedStateID,
      selectedDistrictID,
      selectedTehsilID,
    } = location;
    let check = true;
    let error = '';
    let statename = '';
    let districtname = '';
    let tehsilname = '';
    const stateObj = state.find(o => o.stateID === selectedStateID);
    if (stateObj) {
      const { stateName } = stateObj;
      statename = stateName;
    }
    const districtObj = district.find(o => o.districtID === selectedDistrictID);
    if (districtObj) {
      const { districtName } = districtObj;
      districtname = districtName;
    }
    const tehsilObj = tehsil.find(o => o.tehsilID === selectedTehsilID);
    if (tehsilObj) {
      const { tehsilName } = tehsilObj;
      tehsilname = tehsilName;
    }

    if (
      pincode === ''
      && landSize === ''
      && ownership === ''
      && statename === ''
    ) {
      error = 'Please select the filter';
      check = false;
    }
    const filterData = [
      {
        filterType: 'STATEID',
        filterValue: statename,
      },
      {
        filterType: 'DISTRICTID',
        filterValue: districtname,
      },
      {
        filterType: 'SUBDISTRICTID',
        filterValue: tehsilname,
      },
      {
        filterType: 'AREA',
        filterValue: landSize,
      },
      {
        filterType: 'CROPID',
        filterValue: crop,
      },
      {
        filterType: 'farmerCategory',
        filterValue: ownership,
      },
    ];
    const filterJson = JSON.stringify(filterData);
    if (check) {
      const data = {
        offset: 0,
        limit: 50000,
        filters: filterJson,
        search: { searchType: 'PINCODE', searchValue: pincode },
      };
      getFarmerList(data);
      this.setState({
        error: '',
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  render() {
    const {
      pincode,
      ownership,
      landSize,
      crop,
      districtNameCrop,
      error,
    } = this.state;
    const {
      show,
      broadcastLocationChange,
      cropListByLoc,
      taggedListFarmer,
    } = this.props;
    const { totalCount } = taggedListFarmer;
    return (
      <div>
        <ModalHOC
          isOpen={show}
          customContent="farmer-filterModal"
          onClose={this.handleClose}
          titleContent="Filter farmer below to send SMS"
          footerComponent={<Button onClick={this.handleClose}>Proceed</Button>}
        >
          <div className="filter filterController">
            <Grid container>
              <Grid item xs={12}>
                <LocationHeirarchy
                  type="selectEmpty modalDropdown"
                  calledFrom="dashboard"
                  broadcastLocationChange={broadcastLocationChange}
                  hideVillage
                  restrictedStates={[
                    'a3e1e1ad-a0f1-41f1-b557-741769808b77',
                    '6693a237-25bd-4446-91e3-f7f05f3b2b21',
                  ]}
                />
                <input
                  placeholder="Pincode"
                  className="textField inputHead customText-width small-textfield"
                  maxLength={6}
                  onChange={this.handlePincodeChange}
                  value={pincode}
                />
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value={crop}
                    displayEmpty
                    disabled={districtNameCrop === ''}
                    onChange={ev => this._handleCropChange(ev)}
                  >
                    <MenuItem value="">Crop Name</MenuItem>
                    {cropListByLoc
                      && cropListByLoc.length > 0
                      && cropListByLoc.map(item => (
                        <MenuItem key={Math.random()} value={item.cropName}>
                          {item.cropName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value={ownership}
                    displayEmpty
                    onChange={ev => this._handleOwnershipChange(ev)}
                  >
                    <MenuItem value="">Ownership</MenuItem>
                    <MenuItem value="1">Owner</MenuItem>
                    <MenuItem value="2">ShareCropper</MenuItem>
                    <MenuItem value="3">Tenant</MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value=""
                    displayEmpty
                    disabled
                    // onChange={ev => this._handleSatelliteSource(ev)}
                  >
                    <MenuItem value="">Sowing Month</MenuItem>
                  </Select>
                </FormControl> */}
                {' '}
                <FormControl className="formControl inputHead coloredForms inputFullWidth">
                  <Select
                    disableUnderline
                    name="dashboard-user"
                    className="selectEmpty modalDropdown"
                    inputProps={{ 'aria-label': 'user' }}
                    value={landSize}
                    displayEmpty
                    onChange={ev => this._handleLandChange(ev)}
                  >
                    <MenuItem value="">Land Size</MenuItem>
                    <MenuItem value="1">0 to 1 Ha.</MenuItem>
                    <MenuItem value="2">1 to 2 Ha.</MenuItem>
                    <MenuItem value="3">2 to 4 Ha.</MenuItem>
                    <MenuItem value="4">4 to 10 Ha.</MenuItem>
                    <MenuItem value="5"> More than 10 Ha.</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={() => this._handleFilter()}>Ok</Button>
                {error ? (
                  <p
                    className="errorMsg1"
                    style={{ marginLeft: '180px', marginTop: '-10px' }}
                  >
                    {' '}
                    {error}
                    {' '}
                  </p>
                ) : null}
                <div className="farmerCount" style={{ marginTop: '20px' }}>
                  <h1>
Total Farmers :
                    {totalCount || 0}
                  </h1>
                </div>
              </Grid>
            </Grid>
          </div>
        </ModalHOC>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({ tagging, location }) => ({
      cropListByLoc: tagging.cropListByLoc,
      taggedListFarmer: tagging.taggedListFarmer,
      location,
    }),
    dispatch => ({
      getFarmerList: data => dispatch(getTaggedFarmerList(data)),
      getCrops: data => dispatch(getCropByLocation(data)),
    }),
  )(withCookies(FgFilterModal)),
);
