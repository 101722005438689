import React from 'react';
import { Line } from 'recharts';
import { LineChart } from '../common/chart';

const LineChartComponent = (props) => {
  const {
    data,
    xAxisDataKey,
    dataKey,
    yAxisLabel,
    compareChart,
    range,
  } = props;
  const colorArray = [
    '#09b466',
    '#0d21d8',
    '#ffc658',
    '#8884d8',
    '#00C49F',
    '#0088FE',
    '#FFBB28',
    '#FF8042',
  ];
  return (
    <LineChart
      data={data}
      dataKey={xAxisDataKey}
      yAxisLabel={yAxisLabel}
      showToolTip
      showLegend={compareChart}
    >
      <Line
        type="monotone"
        dataKey={dataKey}
        stroke="#09b466"
        dot={false}
        strokeWidth={2}
      />
      {compareChart
        && compareChart.length
        && compareChart.map((item, index) => {
          if (index > 0) {
            return (
              <Line
                key={item}
                type="monotone"
                dataKey={`${item}-${range}`}
                stroke={colorArray[index]}
                dot={false}
                strokeWidth={2}
              />
            );
          }
          return null;
        })}
    </LineChart>
  );
};

export default LineChartComponent;
