import ajax from '../Functions/ajax';

// let baseURL = 'http://localhost:3009/v1';
//
// if (
//   typeof window !== 'undefined'
//   && window.location.href.indexOf('localhost') === -1
// ) {
//   baseURL = '//13.235.105.20/v1';
// }

export const getOtp = (mobile, otpType, device) => dispatch => ajax
  .post('/services/services/sendOtp', {
    data: {
      mobile,
      otpType,
      otpVia: device,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'GET_OTP_SUCCESS', payload: response.data });
    } else {
      dispatch({ type: 'GET_OTP_FAILURE', payload: response.data.error });
    }
  })
  .catch((error) => {
    dispatch({ type: 'GET_OTP_FAILURE', payload: error });
  });

export const sendOtp = (mobile, otpType, device) => dispatch => ajax
  .post('/admin/auth/send_otp', {
    data: {
      mobile,
      otpType,
      otpVia: device,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'GET_OTP_SUCCESS', payload: response.data });
    } else {
      dispatch({ type: 'GET_OTP_FAILURE', payload: response.data.error });
    }
  })
  .catch((error) => {
    dispatch({ type: 'GET_OTP_FAILURE', payload: error });
  });

export const verifyOtpAction = (mobileOtp, mobileNumber, otpType) => dispatch => ajax
  .post('/services/services/verifyOTP', {
    data: {
      otp: Number(mobileOtp),
      mobile: mobileNumber,
      otpType,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'MOBILE_VERIFY_SUCCESS',
        payload: response.data,
        mobileNumber,
      });
    } else {
      dispatch({
        type: 'MOBILE_VERIFY_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((error) => {
    dispatch({ type: 'MOBILE_VERIFY_FAILURE', payload: error });
  });

export const verifyMobileOtp = (mobileOtp, mobileNumber, otpType) => dispatch => ajax
  .post('/admin/auth/verify_otp', {
    data: {
      otp: Number(mobileOtp),
      mobile: mobileNumber,
      otpType,
    },
  })
  .then((response) => {
    if (response.data.status) {
      dispatch({
        type: 'MOBILE_VERIFY_SUCCESS',
        payload: response.data,
        mobileNumber,
      });
    } else {
      dispatch({
        type: 'MOBILE_VERIFY_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((error) => {
    dispatch({ type: 'MOBILE_VERIFY_FAILURE', payload: error });
  });

export const registerFarmerAction = (
  farmerDetails,
  verifiedMobile,
) => dispatch => ajax
  .post('/farmers/farmers/registration', {
    data: {
      demographicesDetails: {
        farmerName: `${farmerDetails.firstName} ${farmerDetails.lastName}`,
        mobile: verifiedMobile,
        emailID: farmerDetails.emailId,
        source: 0,
        password: farmerDetails.password,
      },
    },
  })
  .then((response) => {
    console.log('response---', response);
    if (response.data.status) {
      dispatch({ type: 'REGISTRATION_SUCCESS', payload: response.data });
    } else {
      dispatch({
        type: 'REGISTRATION_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'REGISTRATION_FAILURE', payload: err });
  });

export const registerOrgsAction = orgsDetails => dispatch => ajax
  .post('/users/users/newRegistration', {
    data: orgsDetails,
  })
  .then((response) => {
    console.log('response---', response);
    if (response.data.status) {
      dispatch({ type: 'REGISTRATION_SUCCESS', payload: response.data });
    } else {
      dispatch({
        type: 'REGISTRATION_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'REGISTRATION_FAILURE', payload: err });
  });

export const registerFarmconnectAction = orgsDetails => dispatch => ajax
  .post('/admin/create_user', {
    data: orgsDetails,
  })
  .then((response) => {
    console.log('response---', response, orgsDetails);
    if (response.data.status) {
      dispatch({ type: 'REGISTRATION_SUCCESS', payload: response.data });
    } else {
      dispatch({
        type: 'REGISTRATION_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'REGISTRATION_FAILURE', payload: err });
  });

export const registerOrgsSocialAction = orgsDetails => dispatch => ajax
  .post('/users/users/socaialRegistration', {
    data: orgsDetails,
  })
  .then((response) => {
    console.log('response---', response);
    if (response.data.status) {
      dispatch({ type: 'REGISTRATION_SUCCESS', payload: response.data });
    } else {
      dispatch({
        type: 'REGISTRATION_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'REGISTRATION_FAILURE', payload: err });
  });

export const resetRegistrationAction = () => ({
  type: 'RESET_REGISTER',
});

export const submitOrgsLoginAction = (method, password, type) => {
  const data = {};
  if (type === 'email') {
    // data.source = 3;
    data.emailID = method;
  } else {
    // data.source = 0;
    data.mobile = method;
  }
  data.password = password;
  return dispatch => ajax
    .post('/users/users/userLogin', {
      data,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      } else {
        dispatch({ type: 'LOGIN_FAILURE', payload: response.data.error });
      }
    })
    .catch((err) => {
      dispatch({ type: 'LOGIN_FAILURE', payload: err });
    });
};

export const submitFarmconnectLoginAction = (mobile, password, type) => {
  const data = {};
  data.mobile = mobile;
  data.password = password;
  data.deviceType = type;
  return dispatch => ajax
    .post('/users/login', {
      data,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      } else {
        dispatch({ type: 'LOGIN_FAILURE', payload: response.data.error });
      }
    })
    .catch((err) => {
      dispatch({ type: 'LOGIN_FAILURE', payload: err });
    });
};

export const submitLoginAction = (method, password, type) => {
  const data = {};
  if (type === 'email') {
    data.source = 3;
    data.emailID = method;
  } else {
    data.source = 0;
    data.mobile = method;
  }
  data.password = password;
  return dispatch => ajax
    .post('/farmers/farmers/login', {
      data,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      } else {
        dispatch({ type: 'LOGIN_FAILURE', payload: response.data.error });
      }
    })
    .catch((err) => {
      dispatch({ type: 'LOGIN_FAILURE', payload: err });
    });
};

export const registerFarmerSocialAction = (email, name, userID, source) => {
  const demographicesDetails = {
    farmerName: name,
    emailID: email,
    source,
  };
  if (source === 1) {
    demographicesDetails.googleId = userID;
  } else {
    demographicesDetails.userID = userID;
  }
  return dispatch => ajax
    .post('/farmers/farmers/registration', {
      data: { demographicesDetails },
    })
    .then((response) => {
      console.log('response---', response);
      if (response.data.status) {
        dispatch({ type: 'REGISTRATION_SUCCESS', payload: response.data });
      } else {
        dispatch({
          type: 'REGISTRATION_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      console.log('err---', err);
      dispatch({ type: 'REGISTRATION_FAILURE', payload: err });
    });
};

export const openRegisterModalAction = bool => ({
  type: 'OPEN_REGISTER_MODAL',
  payload: bool,
});

export const resetOtpAction = () => ({
  type: 'RESET_OTP_SUCCESS',
  payload: false,
});

export const getUserTypeAction = () => dispatch => ajax
  .get('/users/users/organisationTypeList')
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'USER_TYPE_SUCCESS', payload: response.data });
    } else {
      dispatch({ type: 'USER_TYPE_FAILURE', payload: response.data });
    }
  })
  .catch((err) => {
    dispatch({ type: 'USER_TYPE_FAILURE', payload: err });
  });

export const getFarmConnectUserTypeAction = () => dispatch => ajax
  .get('/admin/stakeholders')
  .then((response) => {
    if (response.data.status) {
      dispatch({ type: 'USER_TYPE_SUCCESS', payload: response.data });
    } else {
      dispatch({ type: 'USER_TYPE_FAILURE', payload: response.data });
    }
  })
  .catch((err) => {
    dispatch({ type: 'USER_TYPE_FAILURE', payload: err });
  });

export const checkUserData = userData => (dispatch) => {
  if (userData) {
    dispatch({ type: 'UPDATE_USERDATA_SUCCESS', payload: userData });
  } else {
    dispatch({ type: 'UPDATE_USERDATA_FAILURE', payload: null });
  }
};

export const handleLoginModalAction = bool => ({
  type: 'HANDLE_LOGIN_MODAL',
  payload: bool,
});

export const submitLoginAsSeedCompAction = (mobile, password) => {
  const data = {};
  data.userName = mobile;
  data.password = password;
  return dispatch => ajax
    .post('/users/users/authtoken', {
      data,
    })
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'LOGIN_AS_SEED_COMPANY_SUCCESS',
          payload: response.data,
        });
      } else {
        dispatch({
          type: 'LOGIN_AS_SEED_COMPANY_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: 'LOGIN_AS_SEED_COMPANY_FAILURE', payload: err });
    });
};

export const getStakeHoldersOrgsAction = params => dispatch => ajax
  .get('/admin/organisations', {
    params: {
      stakeHolderID: params.stakeHolderID,
    },
  })
  .then((response) => {
    console.log('response---', response);
    if (response.data.status) {
      dispatch({
        type: 'STAKE_HOLDERS_SUCCESS',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'STAKE_HOLDERS_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'STAKE_HOLDERS_FAILURE', payload: err });
  });

export const getRolesOrgsAction = params => dispatch => ajax
  .get('/admin/roles', {
    params: {
      rolePrefix: params.rolePrefix,
    },
  })
  .then((response) => {
    console.log('response---', response);
    if (response.data.status) {
      dispatch({ type: 'USER_ROLE_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'USER_ROLE_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    console.log('err---', err);
    dispatch({ type: 'USER_ROLE_FAILURE', payload: err });
  });
