import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {
  Grid,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { getMediaList } from '../../Actions/ivrsAction';
import MiniPlayer from './miniPlayer';
import Pagination from '../common/pagination';

const WAIT_INTERVAL = 1000;

function createData(name, description, id, fileUrl, language) {
  return {
    name,
    description,
    id,
    fileUrl,
    language,
  };
}

class MediaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      selectedMediaId: '',
      name: '',
      playMediaRowId: undefined,
      audioInstance: undefined,
    };
  }

  componentDidMount() {
    this.fetchMedias();
    this.fetchMedias = _.debounce(this.fetchMedias, WAIT_INTERVAL);
  }

  fetchMedias = () => {
    const { page, name } = this.state;
    const { fetchMediaList } = this.props;
    const data = {
      offset: page * 10,
      limit: 10,
      name: name || undefined,
    };
    fetchMediaList(data);
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => {
        this.fetchMedias();
      },
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleCheck = (e, media) => {
    const { updateMedia } = this.props;
    this.setState({
      selectedMediaId: media.id,
    });
    updateMedia(media);
  };

  getStaticMediaFromMedia = (media) => {
    const mediaObj = {
      id: media.id,
      type: 'SINGLE_MEDIA',
      name: media.name,
      language: media.language,
      description: media.description,
      totalDuration: 0,
      formattedTotalDuration: '',
      isMetaAvailable: true,
      mediaSources: [
        {
          id: media.id,
          hasSrcUrl: true,
          srcUrl: media.fileUrl,
          isMetaAvailable: true,
        },
      ],
    };
    return mediaObj;
  };

  handelChange = (e) => {
    this.setState(
      {
        name: e.target.value,
        page: 0,
      },
      () => {
        this.fetchMedias();
      },
    );
  };

  handleClickSearch = () => {
    const { addExpandClass } = this.state;
    this.setState({
      addExpandClass: !addExpandClass,
      name: '',
    });
  };

  broadcastPlay = (rowId, audioInstanceParam) => {
    const { audioInstance } = this.state;
    if (audioInstance) {
      audioInstance.pause();
    }
    this.setState({
      playMediaRowId: rowId,
      audioInstance: audioInstanceParam,
    });
  };

  render() {
    const {
      rowsPerPage,
      page,
      selectedMediaId,
      addExpandClass,
      name,
      playMediaRowId,
    } = this.state;
    const { mediaList } = this.props;
    const tableRows = [];
    let countTotal;
    if (!_.isEmpty(mediaList)) {
      const { count, rows } = mediaList;
      countTotal = count;
      if (rows.length > 0) {
        for (let i = 0; i < rows.length; i += 1) {
          tableRows.push(
            createData(
              rows[i].name,
              rows[i].description,
              rows[i].id,
              rows[i].fileUrl,
              rows[i].language,
            ),
          );
        }
      }
    }
    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12} className="listTitle">
            <h4>List</h4>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div
              style={{ top: '73px', left: '80px', right: 'auto' }}
              className={
                addExpandClass
                  ? 'smallSearchPanel expandSearch'
                  : 'smallSearchPanel'
              }
            >
              <input
                type="text"
                id="autocomplete_search"
                placeholder="Enter Search Name"
                value={name}
                onChange={this.handelChange}
              />
              <button
                type="button"
                className="smallSearchBtn"
                onClick={this.handleClickSearch}
              >
                <i className="material-icons">search</i>
              </button>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="insightTable tableWrapper">
              <div className="scrollPanel">
                <Table className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Media</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map(row => (
                      <TableRow hover key={Math.random()}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={row.id === selectedMediaId}
                            onClick={e => this.handleCheck(e, row)}
                          />
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <MiniPlayer
                            staticMedia={this.getStaticMediaFromMedia(row)}
                            // rowId={row.id}
                            isMediaPlaying={playMediaRowId === row.id}
                            broadcastPlay={this.broadcastPlay}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Pagination
                rowsPerPage={rowsPerPage}
                page={page}
                totalCount={countTotal}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    ({ ivrs }) => ({
      mediaList: ivrs.mediaList,
    }),
    dispatch => ({
      fetchMediaList: data => dispatch(getMediaList(data)),
    }),
  )(withCookies(MediaList)),
);
