import ajax from '../Functions/ajax';

export const addSelectedFarm = (id, locationDetails, token) => {
  console.log('token---', token);
  return dispatch => ajax
    .post('/farmers/farmers/addFarmerLand', {
      data: {
        farmId: id,
      },
      headers: {
        token,
      },
    })
    .then((response) => {
      console.log('response', response);
      if (response.data.status) {
        dispatch({ type: 'LAND_REGISTERED_SUCCESS', payload: response.data });
      } else {
        dispatch({
          type: 'LAND_REGISTERED_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      console.log('err>>>', err);
      dispatch({
        type: 'LAND_REGISTERED_FAILURE',
        payload: err,
      });
    });
};

export const getSelectedFarmCard = (id, token) => dispatch => ajax
  .get(`/farms/farms/farmerCard?farmID=${id}`, {
    headers: {
      token,
      'Content-Type': 'application/json',
    },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({ type: 'FARM_CARD_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'FARM_CARD_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'FARM_CARD_FAILURE',
      payload: err,
    });
  });

export const removeFarm = (farmid, token) => (dispatch) => {
  const requestConfig = {
    data: {
      farmId: farmid,
    },
    headers: {
      token,
    },
  };
  ajax
    .delete('/farmers/farmers/deleteFarmerLand', requestConfig)
    .then((response) => {
      if (response.data.status) {
        dispatch({
          type: 'LAND_REMOVED_SUCCESS',
          payload: response.data,
          farmId: farmid,
        });
      } else {
        dispatch({
          type: 'LAND_REMOVED_FAILURE',
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      console.log('error ', err);
      dispatch({
        type: 'LAND_REMOVED_FAILURE',
        payload: err,
      });
    });
};

export const resetAccountSetUpDetails = () => ({
  type: 'RESET_ACCOUNT_SETUP_DETAILS',
});

export const getLandData = token => dispatch => ajax
  .get('/farmers/farmers/getFarmerLand', {
    headers: {
      token,
    },
  })
  .then((response) => {
    console.log('response', response);
    if (response.data.status) {
      dispatch({ type: 'LAND_DATA_SUCCESS', payload: response.data.data });
    } else {
      dispatch({
        type: 'LAND_DATA_FAILURE',
        payload: response.data.error,
      });
    }
  })
  .catch((err) => {
    dispatch({
      type: 'LAND_DATA_FAILURE',
      payload: err,
    });
  });

export const resetAccountSetUpLandDetails = () => ({
  type: 'RESET_LAND_REMOVE_DETAILS',
});
