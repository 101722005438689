import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../css/gisLeftPanel.scss';

class DashboardSidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLogout = () => {
    const { cookies, history } = this.props;
    cookies.remove('userData', null);
    cookies.remove('loggedInTimeStamp', null);
    history.push('/');
  };

  // goToHome = () => {
  //   const { history } = this.props;
  //   history.push('/home');
  // };

  handleDisable = (ev) => {
    ev.preventDefault();
  };

  render() {
    const { location } = this.props;
    const pathName = location.pathname;
    /* const userdata = cookies.get('userData');
    let isdisabled = false;

    if (userdata) {
      if (userdata.data.roles) {
        isdisabled = false;
      } else {
        isdisabled = true;
      }
    } */

    return (
      <Grid item xs={12} sm={3} className="leftPanelMini">
        <a href="/setup/step3" className="logoHead">
          <img
            src="images/logoCircle.svg"
            alt="logo"
            title="logo"
            img="responsive"
          />
          <img
            src="images/logoName.svg"
            alt="logo"
            title="logo"
            img="responsive"
            className="logoName"
          />
        </a>
        <ul>
          <li className={pathName === '/dashboard' ? 'menu-active' : ''}>
            <Link to="/dashboard">
              <img src="images/leftPanelIcons/gis.svg" alt="gis" title="gis" />
              <span>Farm Monitoring</span>
            </Link>
            {/* {isdisabled === false ? (
              <Link to="/dashboard">
                <img
                  src="images/leftPanelIcons/gis.svg"
                  alt="gis"
                  title="gis"
                />
                <span>Farm Monitoring</span>
              </Link>
            ) : (
              <a href="/" onClick={this.handleDisable}>
                <img
                  src="images/leftPanelIcons/gis.svg"
                  alt="gis"
                  title="gis"
                />
                <span>Farm Monitoring</span>
              </a>
            )} */}
            {/* <button type="button" onClick={() => this.goToHome()}>
                         <img src="images/leftPanelIcons/gis.svg" alt="gis" title="gis" />
                         <span>GIS</span>
                       </button> */}
          </li>
          <li className={pathName === '/tagging' ? 'menu-active' : ''}>
            <Link to="/tagging">
              <img
                src="images/leftPanelIcons/farmer-registration.svg"
                alt="farmer-registration"
                title="farmer-registration"
              />
              <span>Farmer Marketing</span>
            </Link>
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/analytics.svg"
                alt="analytics"
                title="analytics"
              />
              <span>Analytics & API’s</span>
            </a>
          </li>
          {/* <li
          className={pathName === '/tagging' ? 'menu-active' : ''}
          onClick={this.handleDisable}>
            <Link to="/tagging">
              <img
                src="images/leftPanelIcons/farmer-registration.svg"
                alt="farmer-registration"
                title="farmer-registration"
              />
              <span>Tagging</span>
            </Link>
          </li> */}
          {/*  <li
          className={pathName === '/home' ? 'menu-active' : ''}
           onClick={this.handleDisable}>
            <Link to="/home">
              <img
                src="images/leftPanelIcons/analytics.svg"
                alt="analytics"
                title="analytics"
              />
              <span>Home</span>
               <span>Analytics & API’s</span>
            </Link>
          </li> */}
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/ivrs.svg"
                alt="ivrs"
                title="ivrs"
              />
              <span>IVRS</span>
            </a>
          </li>
          <li>
            <a href="/" onClick={this.handleDisable}>
              <img
                src="images/leftPanelIcons/pricing.svg"
                alt="pricing"
                title="pricing"
              />
              <span>Pricing</span>
            </a>
          </li>
          {/* <li>
            <a href="/">
              <img
                src="images/leftPanelIcons/target.svg"
                alt="target"
                title="target"
              />
              <span>Target</span>
            </a>
          </li> */}
        </ul>
        <a href="/" className="logout" onClick={this.handleLogout}>
          <img
            src="images/leftPanelIcons/logout.svg"
            alt="logout"
            title="logout"
          />
          <span>User</span>
        </a>
      </Grid>
    );
  }
}

export default withRouter(
  connect(
    null,
    null,
  )(withCookies(DashboardSidePanel)),
);
