const initialState = {
  state: [],
  district: [],
  tehsil: [],
  village: [],
  selectedStateID: '',
  selectedDistrictID: '',
  selectedTehsilID: '',
  selectedVillageID: '',
  selectedLevel: '',
  selectedYear: 2019,
  selectedSeason: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STATE_SUCCESS':
      return {
        ...state,
        state: action.payload,
        district: [],
        tehsil: [],
        village: [],
      };
    case 'GET_STATE_FAILURE':
      return {
        ...state,
        state: [],
        district: [],
        tehsil: [],
        village: [],
        selectedStateID: '',
        selectedDistrictID: '',
        selectedTehsilID: '',
        selectedVillageID: '',
      };
    case 'GET_DISTRICT_SUCCESS':
      return {
        ...state,
        district: action.payload,
        tehsil: [],
        village: [],
      };
    case 'GET_DISTRICT_FAILURE':
      return {
        ...state,
        district: [],
        tehsil: [],
        village: [],
      };
    case 'GET_TEHSIL_SUCCESS':
      return {
        ...state,
        tehsil: action.payload,
        village: [],
      };
    case 'GET_TEHSIL_FAILURE':
      return {
        ...state,
        tehsil: [],
        village: [],
      };

    case 'GET_VILLAGE_SUCCESS':
      return {
        ...state,
        village: action.payload,
      };
    case 'GET_VILLAGE_FAILURE':
      return {
        ...state,
        village: [],
      };

    case 'GET_OTP_SUCCESS':
      return {
        ...state,
        otp: action.payload.data.otp,
      };
    case 'SET_SELECTED_STATE':
      return {
        ...state,
        selectedStateID: action.payload,
        selectedDistrictID: '',
        selectedTehsilID: '',
        selectedVillageID: '',
        selectedLevel: action.level,
        district: [],
        tehsil: [],
      };

    case 'SET_SELECTED_DISTRICT':
      return {
        ...state,
        selectedDistrictID: action.payload,
        selectedTehsilID: '',
        selectedVillageID: '',
        selectedLevel: action.level,
        tehsil: [],
      };

    case 'SET_SELECTED_TEHSIL':
      return {
        ...state,
        selectedTehsilID: action.payload,
        selectedVillageID: '',
        selectedLevel: action.level,
        village: [],
      };

    case 'SET_SELECTED_VILLAGE':
      return {
        ...state,
        selectedVillageID: action.payload,
        selectedLevel: action.level,
      };

    case 'SET_SELECTED_YEAR':
      return {
        ...state,
        selectedYear: action.payload,
      };

    case 'SET_SELECTED_SEASON':
      return {
        ...state,
        selectedSeason: action.payload,
      };
    case 'RESET_LOCATION_DETAILS':
      return {
        ...state,
        state: [],
        district: [],
        tehsil: [],
        village: [],
        selectedStateID: '',
        selectedDistrictID: '',
        selectedTehsilID: '',
        selectedVillageID: '',
      };

    default:
      return state;
  }
};
