import React from 'react';
import {
  Grid,
  Button,
  Paper,
  //  Checkbox,
  FormControl,
  //  FormGroup,
  //  FormControlLabel,
} from '@material-ui/core';
import {
  PieChart, Pie, Cell, Tooltip, Sector,
} from 'recharts';
import _ from 'lodash';
import '../../css/tagging/campaginResult.scss';

const COLORS = ['#dbecf8', '#2c82be', '#53a8e2', '#76ddfb'];

const CustomTooltip = (props) => {
  const { activeData } = props;
  return (
    <React.Fragment>
      {!_.isEmpty(activeData) && (
        <div className="pie-tooltip">
          <p>
            {activeData.name}
            {' '}
            <strong>{`${(activeData.percent * 100).toFixed(2)}%`}</strong>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 15}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

class CampaginResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleTargetFarmer = () => {
    const { handleTabChange } = this.props;
    handleTabChange();
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      activeData: data,
    });
  };

  onPieLeave = () => {
    this.setState({
      activeIndex: null,
    });
  };

  render() {
    const { campaignReport, type } = this.props;
    const { activeIndex, activeData } = this.state;
    const graphData = [
      {
        name: 'Total Farmers',
        value: campaignReport.totalFarmers - campaignReport.campaignSMSSent,
      },
      {
        name: 'Total Sms  Sent',
        value: campaignReport.campaignSMSSent - campaignReport.linkOpentCount,
      },
      {
        name: 'Total Sms Delivered',
        value:
          campaignReport.linkOpentCount - campaignReport.campaignDataResponse,
      },
      { name: 'Total Response', value: campaignReport.campaignDataResponse },
    ];
    // const progressData = (campaignReport.campaignSMSSent / campaignReport.totalFarmers) * 100;
    // const progressCalc = Number.isNaN(progressData) ? 0 : progressData;
    return (
      <React.Fragment>
        <div className="root rightSide">
          <div className="insightRightPanel">
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className="campaginName">
                  <div className="pushLeft">
                    <div className="item">{campaignReport.campaignTitle}</div>
                    <div className="push-right" style={{ cursor: 'default' }}>
                      <i className="material-icons">reply</i>
                      Share
                    </div>
                    <div className="push-right" style={{ cursor: 'default' }}>
                      <i className="material-icons">arrow_downward</i>
                      Download
                    </div>
                  </div>
                </Paper>
                <div className="campaginBody">
                  {type === 'tagging' ? (
                    <div className="iconbb">
                      <div className="posterSection">
                        <img
                          src={
                            campaignReport.mediaLocation
                            || 'images/defaultPoster.png'
                          }
                          alt="img"
                          className="img-responsive"
                        />
                      </div>
                      {/*  <div className="form">
                        <div className="captureDataPoint">
                          <Grid container>
                            <Grid item lg={6} md={6} xs={12}>
                              <div className="campaginDataPoint">
                                {campaignReport.dataPoints
                                && campaignReport.dataPoints.length
                                  ? campaignReport.dataPoints.map(item => (
                                    <span key={item.dataPointKey}>
                                      <i className="material-icons">done</i>
                                      {item.dataPointTextDesc}
                                    </span>
                                  ))
                                  : null}
                              </div>
                            </Grid>
                          </Grid>
                          <FormControl component="fieldset">
                             <FormGroup className="checkBox">
                          {campaignReport.dataPoints
                            && campaignReport.dataPoints.length
                            && campaignReport.dataPoints.map(item => (
                              <FormControlLabel
                                key={item.dataPointKey}
                                control={<Checkbox checked />}
                                label={item.dataPointTextDesc}
                              />
                            ))}
                        </FormGroup>
                          </FormControl>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  <div className="textbb">
                    <h1>
                      SMS Text Description:
                      {' '}
                      {campaignReport.smsTextDescription}
                    </h1>
                    {type === 'sms' && (
                      <h1>
                        SMS Link:
                        {campaignReport.weblinkUrl || 'NA'}
                      </h1>
                    )}
                    <p>
                      {' '}
                      Campaign Details :
                      <small>{campaignReport.campaignDescription}</small>
                    </p>
                    <div className="graphRepresentation">
                      <span>Graph Representaion:</span>
                      <div className="graph">
                        <Grid container>
                          <Grid item lg={7} sm={7} md={7} xs={12}>
                            <PieChart
                              width={600}
                              height={300}
                              style={{
                                marginTop: '-100px',
                                marginLeft: '-218px',
                              }}
                            >
                              <text
                                x="300"
                                y={200}
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className="circle-chart__subline"
                                style={{ fontSize: 15 }}
                              >
                                <tspan x="300" y="200">
                                  {campaignReport.campaignDataResponse}
                                </tspan>
                                <tspan x="300" y="210" dy="1.0em">
                                  Responses
                                </tspan>
                              </text>
                              <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={graphData}
                                dataKey="value"
                                cx={300}
                                cy={200}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                blendStroke
                                onMouseEnter={this.onPieEnter}
                                onMouseLeave={this.onPieLeave}
                              >
                                {graphData.map((entry, index) => (
                                  <Cell
                                    key={Math.random()}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip
                                content={
                                  <CustomTooltip activeData={activeData} />
                                }
                              />
                            </PieChart>
                          </Grid>
                          <Grid item lg={5} sm={5} md={5} xs={12}>
                            <div className="totalStats">
                              <ul>
                                <li>
                                  Total Sent:
                                  <span>{campaignReport.campaignSMSSent}</span>
                                </li>
                                <li>
                                  Total Links Opened:
                                  <span>{campaignReport.linkOpentCount}</span>
                                </li>
                                <li>
                                  Total Response:
                                  <span>
                                    {campaignReport.campaignDataResponse}
                                  </span>
                                </li>
                                <li>
                                  Picture Received:
                                  <span>0</span>
                                </li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                        {/*                        <PieChart
             width={600}
             height={300}
             style={{ marginTop: '-70px', marginLeft: '-70px' }}>
                          <text
                            x="300"
                            y={200}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            className="circle-chart__subline"
                            style={{ fontSize: 15 }}
                          >
                            <tspan x="300" y="200">Your Responses</tspan>
                            <tspan x="300" y="210" dy="1.0em">300</tspan>
                          </text>
                          <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx={300}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            blendStroke
                            onMouseEnter={this.onPieEnter}
                            onMouseLeave={this.onPieLeave}
                          >
                            {
                              // eslint-disable-next-line max-len
                              data.map((entry, index) =>
                              <Cell fill={COLORS[index % COLORS.length]} />)
                            }
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart> */}
                        {/*                        <ResponsiveContainer width="40%" height={250}>
                          <PieChart>
                            <text
                              x="50%"
                              y={125}
                              textAnchor="middle"
                              dominantBaseline="middle"
                              className="circle-chart__subline"
                            >
                              Yay
                              {' '}
                              {progressCalc}
% progress!
                            </text>
                            <Pie
                              data={[
                                {
                                  id: '1',
                                  name: 'L1',
                                  value: 100 - progressCalc,
                                },
                                { id: '2', name: 'L2', value: progressCalc },
                              ]}
                              dataKey="value"
                              innerRadius="80%"
                              outerRadius="100%"
                              fill="#00acc1"
                              startAngle={90}
                              endAngle={-270}
                              paddingAngle={0}
                              blendStroke
                              isAnimationActive={false}
                            >
                              <Cell key="test" fill="#CCC" />
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer> */}
                        {/* <svg
                        className="circle-chart"
                        viewBox="0 0 33.83098862 33.83098862"
                        width="200"
                        height="200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="circle-chart__background"
                          stroke="#efefef"
                          strokeWidth="2"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <circle
                          className="circle-chart__circle"
                          stroke="#00acc1"
                          strokeWidth="2"
                          strokeDasharray="30,100"
                          strokeLinecap="round"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <g className="circle-chart__info">
                          <text
                            className="circle-chart__percent"
                            x="16.91549431"
                            y="15.5"
                            alignmentBaseline="central"
                            textAnchor="middle"
                            fontSize="8"
                          >
                            30%
                          </text>
                          <text
                            className="circle-chart__subline"
                            x="16.91549431"
                            y="20.5"
                            alignmentBaseline="central"
                            textAnchor="middle"
                            fontSize="2"
                          >
                            Yay 30% progress!
                          </text>
                        </g>
                      </svg> */}
                        {/*   <div className="totalStats">
                          <ul>
                            <li>
                              Total Sent:
                              <span>{campaignReport.campaignSMSSent}</span>
                            </li>
                            <li>
                              Total Links Opened:
                              <span>{campaignReport.linkOpentCount}</span>
                            </li>
                            <li>
                              Total Response:
                              <span>{campaignReport.campaignDataResponse}</span>
                            </li>
                            <li>
                              Picture Receive:
                              <span>0</span>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form">
                  <div className="captureDataPoint">
                    <Grid container>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <div className="campaginDataPoint">
                          {campaignReport.dataPoints
                          && campaignReport.dataPoints.length
                            ? campaignReport.dataPoints.map(item => (
                              <span key={item.dataPointKey}>
                                <i className="material-icons">done</i>
                                {item.dataPointTextDesc}
                              </span>
                            ))
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                    <FormControl component="fieldset">
                      {/* <FormGroup className="checkBox">
                         {campaignReport.dataPoints
                         && campaignReport.dataPoints.length
                         && campaignReport.dataPoints.map(item => (
                         <FormControlLabel
                         key={item.dataPointKey}
                         control={<Checkbox checked />}
                         label={item.dataPointTextDesc}
                         />
                         ))}
                         </FormGroup> */}
                    </FormControl>
                  </div>
                </div>
                <Grid container>
                  <div className="campaginFoot btn">
                    <Grid container className="btn">
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <p>View Leads</p>
                        {/* <Button
                          variant="contained"
                          className="button mt-20 darkgreenBtn floatLeft btn-disable"
                          disabled
                        >
                          View Leads
                        </Button> */}
                        <Button
                          variant="contained"
                          className="button mt-20 floatLeft btn-outline-enable"
                        >
                          View Leads
                        </Button>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <p>View your responses on map with farm cards</p>
                        {/* <Button
                          variant="contained"
                          // className="button darkgreenBtn floatLeft addToFarmBtn disable"
                          className="button floatLeft btn-disable"
                          disabled
                        >
                          View on map
                        </Button> */}
                        <Button
                          variant="contained"
                          className="button floatLeft btn-outline-enable"
                        >
                          View on map
                        </Button>
                      </Grid>
                      <Grid item lg={5} md={5} sm={5} xs={12}>
                        <p>
                          Reschedule and edit this campaign again with existing
                          farmers
                        </p>
                        {/* <Button
                          variant="contained"
                          className="button floatLeft btn-disable floatLeft"
                          // className="button btn-enable-border floatLeft "
                          disabled
                          onClick={this.handleTargetFarmer}
                        >
                          Target Again
                        </Button> */}
                        <Button
                          variant="contained"
                          className="button floatLeft btn-outline-enable"
                        >
                          Target Again
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {/* <div className="campaginFootTitle">
                  <div className="tips-section">
                    <div className="leftAlign">
                      <h4>Healthy Tips:</h4>
                      <p>
                        Convert your responses into customers by connecting with
                        dealers.
                      </p>
                    </div>
                    <div className="rightAlign btn">
                      <Button
                        className="btn-disable"
                        style={{ cursor: 'default' }}
                      >
                        Show me how
                      </Button>
                    </div>
                  </div>
                </div> */}
              </Grid>
            </Grid>
          </div>
          {/*
                    <Grid container className="insightRightPanel" spacing={0}>
            <Grid item lg={12} xs={12}>
              <Paper className="campaginName">
                <div className="pushLeft">
                  <div className="item">{campaignReport.campaignTitle}</div>
                  <div className="push-right">
                    <i className="material-icons">reply</i>
                    Share
                  </div>
                  <div className="push-right">
                    <i className="material-icons">arrow_downward</i>
                    Download
                  </div>
                </div>
              </Paper>
              <div className="campaginBody">
                <div className="iconbb">
                  <img
                    src="images/defaultPoster.png"
                    alt="img"
                    className="img-responsive"
                  />
                  <div className="form">
                    <div className="capturePointName">
                      <Grid container>
                        <Grid item lg={6} md={6} xs={12}>
                          <div>
                            {campaignReport.dataPoints
                           && campaignReport.dataPoints.length
                           && campaignReport.dataPoints.map(item => (
                             <span key={item.dataPointKey}>{item.dataPointTextDesc}</span>
                           ))}
                          </div>
                        </Grid>
                      </Grid>
                      <FormControl component="fieldset">
                         <FormGroup className="checkBox">
                          {campaignReport.dataPoints
                            && campaignReport.dataPoints.length
                            && campaignReport.dataPoints.map(item => (
                              <FormControlLabel
                                key={item.dataPointKey}
                                control={<Checkbox checked />}
                                label={item.dataPointTextDesc}
                              />
                            ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="textbb">
                  {campaignReport.campaignTitle}
                  <p>
                    Campaign Details:
                    <small>
                      from a stunning collection of poster templates guaranteed
                      to turn heads. Free to customize to suit every event or
                      occasion.
                    </small>
                  </p>
                  <div className="graphRepresentation">
                    <span>Graph Representaion:</span>
                    <div className="graph">
                      <ResponsiveContainer width="40%" height={250}>
                        <PieChart>
                          <text
                            x="50%"
                            y={125}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            className="circle-chart__subline"
                          >
                            Yay
                            {' '}
                            {progressCalc}
% progress!
                          </text>
                          <Pie
                            data={[
                              {
                                id: '1',
                                name: 'L1',
                                value: 100 - progressCalc,
                              },
                              { id: '2', name: 'L2', value: progressCalc },
                            ]}
                            dataKey="value"
                            innerRadius="80%"
                            outerRadius="100%"
                            fill="#00acc1"
                            startAngle={90}
                            endAngle={-270}
                            paddingAngle={0}
                            blendStroke
                          >
                            <Cell key="test" fill="#CCC" />
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                       <svg
                        className="circle-chart"
                        viewBox="0 0 33.83098862 33.83098862"
                        width="200"
                        height="200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="circle-chart__background"
                          stroke="#efefef"
                          strokeWidth="2"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <circle
                          className="circle-chart__circle"
                          stroke="#00acc1"
                          strokeWidth="2"
                          strokeDasharray="30,100"
                          strokeLinecap="round"
                          fill="none"
                          cx="16.91549431"
                          cy="16.91549431"
                          r="15.91549431"
                        />
                        <g className="circle-chart__info">
                          <text
                            className="circle-chart__percent"
                            x="16.91549431"
                            y="15.5"
                            alignmentBaseline="central"
                            textAnchor="middle"
                            fontSize="8"
                          >
                            30%
                          </text>
                          <text
                            className="circle-chart__subline"
                            x="16.91549431"
                            y="20.5"
                            alignmentBaseline="central"
                            textAnchor="middle"
                            fontSize="2"
                          >
                            Yay 30% progress!
                          </text>
                        </g>
                      </svg>
                      <div className="totalStats">
                        <ul>
                          <li>
                            Total Sent:
                            <span>{campaignReport.campaignSMSSent}</span>
                          </li>
                          <li>
                            Total Links Opened:
                            <span>{campaignReport.linkOpentCount}</span>
                          </li>
                          <li>
                            Total Response:
                            <span>{campaignReport.campaignDataResponse}</span>
                          </li>
                          <li>
                            Picture Receive:
                            <span>1500/-</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <Grid item lg={12} md={12} className="leadsBackground">
                  <div className="viewLeads">
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <p>View Leads</p>
                      <Button
                        variant="contained"
                        className="button darkgreenBtn floatLeft btn-disable"
                      >
                        View Leads
                      </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <p>View your responses on map with farm cards</p>
                      <Button
                        variant="contained"
                       // className="button darkgreenBtn floatLeft addToFarmBtn disable"
                        className="button darkgreenBtn floatLeft btn-disable"
                      >
                        View on map
                      </Button>
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                      <p>
                        Reschedule and edit this campaign again with existing
                        farmers
                      </p>
                      <Button
                        variant="contained"
                        className="button darkgreenBtn floatLeft btn-disable"
                      >
                        Target Again
                      </Button>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="tips-section">
                    <div className="leftAlign">
                      <h4>Healthy Tips:</h4>
                      <p>
                        Convert your responses into customers by connecting with
                        dealers.
                      </p>
                    </div>
                    <div className="rightAlign btn">
                        <Button className="btn-disable">Show me how</Button>
                    </div>
                  </div>
                   <div className="pictureResponse">
                    picrure response:
                    <div className="pictureCount">counting imgs</div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid> */}
        </div>
      </React.Fragment>
    );
  }
}

export default CampaginResult;
