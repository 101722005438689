import React from 'react';
import { Button, TextField, Grid } from '@material-ui/core';
import SmsSelectFarmer from './smsSelectFarmer';
import '../../css/sms/smsSetup.scss';
import '../../css/common/responsive.scss';

class SmsSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFarmer: false,
      smsTitle: 'Campaign 1',
      isEditing: false,
      createSms: '',
      linkText: '',
      error: '',
      smsDetail: `from a stunning collection of poster templates
                          guaranteed to turn heads. Free to customize to suit 
                          every event or occasion.`,
      isSmsDetailEdit: false,
    };
  }

  handelClick = () => {
    const { createSms, smsTitle, smsDetail } = this.state;
    let error = '';
    let check = true;
    if (!smsDetail) {
      check = false;
      error = 'Please enter sms detail';
    }
    if (!smsTitle) {
      check = false;
      error = 'Please enter sms title';
    }
    // if (!linkText) {
    //   check = false;
    //   error = 'Please enter link';
    // }
    if (!createSms) {
      check = false;
      error = 'Please create sms';
    }
    if (check) {
      this.setState({
        selectFarmer: true,
        error: '',
      });
    } else {
      this.setState({
        selectFarmer: false,
        error,
      });
    }
  };

  handlePrevious = () => {
    this.setState({
      selectFarmer: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      smsTitle: e.target.value,
    });
  };

  handleKeyPress = (e) => {
    const { smsTitle } = this.state;
    if (e.key === 'Enter') {
      if (smsTitle === '') {
        this.setState({ smsTitle: 'Campaign 1' });
      }
      this.setState({ isEditing: false });
    }
  };

  _editTitle = () => {
    const { isEditing } = this.state;
    this.setState(
      {
        isEditing: !isEditing,
      },
      () => {
        const inp = document.getElementById('editSmsField');
        inp.focus();
      },
    );
  };

  _saveTitle = () => {
    const { smsTitle } = this.state;
    this.setState({
      smsTitle,
      isEditing: false,
    });
  };

  handleCreateSmsChange = (e) => {
    if (e.target.value.length > 250) {
      return;
    }
    this.setState({
      createSms: e.target.value,
      error: '',
    });
  };

  handleLinkChange = (e) => {
    this.setState({
      linkText: e.target.value,
      error: '',
    });
  };

  _editSmsDesc = () => {
    const { isSmsDetailEdit } = this.state;
    this.setState(
      {
        isSmsDetailEdit: !isSmsDetailEdit,
      },
      () => {
        const inp = document.getElementById('editSmsDescField');
        inp.focus();
      },
    );
  };

  _saveSmsDesc = () => {
    const { smsDetail } = this.state;
    this.setState({
      smsDetail,
      isSmsDetailEdit: false,
    });
  };

  handleSmsDescChange = (e) => {
    this.setState({
      smsDetail: e.target.value,
    });
  };

  handleSmsDescKeyPress = (e) => {
    const { smsDetail } = this.state;
    if (e.key === 'Enter') {
      if (smsDetail === '') {
        this.setState({ smsDetail: 'Sms description ' });
      }
      this.setState({ isSmsDetailEdit: false });
    }
  };

  render() {
    const {
      selectFarmer,
      smsTitle,
      isEditing,
      createSms,
      linkText,
      error,
      isSmsDetailEdit,
      smsDetail,
    } = this.state;
    const { anotherTab } = this.props;
    return (
      <React.Fragment>
        {selectFarmer === false ? (
          <div className="sms">
            <div className="posterWrap1">
              <div className="posterformInput">
                <div className="recomondationText">
                  <h3 style={{ display: 'inline-block' }}>
                    {isEditing ? (
                      <input
                        type="text"
                        id="editSmsField"
                        className="editTextField"
                        onChange={this.handleChange}
                        onKeyPress={e => this.handleKeyPress(e)}
                        style={{ outline: 'none' }}
                      />
                    ) : (
                      <span>{smsTitle}</span>
                    )}
                  </h3>
                  {!isEditing ? (
                    <Button
                      to="edit"
                      className="linkBtn greenTxt"
                      onClick={this._editTitle}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      to="edit"
                      className="linkBtn greenTxt"
                      onClick={this._saveTitle}
                    >
                      Save
                    </Button>
                  )}
                </div>
                <p>
                  <span>Campaign Details: </span>
                  {isSmsDetailEdit ? (
                    <textarea
                      id="editSmsDescField"
                      className="sms__create--textarea"
                      onChange={this.handleSmsDescChange}
                      onKeyPress={e => this.handleSmsDescKeyPress(e)}
                    />
                  ) : (
                    <span>{smsDetail}</span>
                  )}
                  {!isSmsDetailEdit ? (
                    <Button
                      to="edit"
                      className="linkBtn greenTxt"
                      onClick={this._editSmsDesc}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      to="edit"
                      className="linkBtn1 greenTxt"
                      onClick={this._saveSmsDesc}
                    >
                      Save
                    </Button>
                  )}
                </p>
                <div className="sms__create">
                  <h1 className="sms__create--head">Create SMS</h1>
                  <textarea
                    placeholder="Type Your message here, maximum 250 characters allowed"
                    className="sms__create--textarea"
                    onChange={this.handleCreateSmsChange}
                    value={createSms}
                  />
                  <h1 className="sms__create--head">Link</h1>
                  <TextField
                    type="url"
                    placeholder="Please enter URL"
                    className="textField inputHead"
                    value={linkText}
                    onChange={this.handleLinkChange}
                  />
                  <div />
                </div>
                {/*  <div className="sms__create">
                  <h1 className="sms__create--head">Link</h1>
                  <TextField
                    type="url"
                    placeholder="Please enter URL"
                    className="textField inputHead"
                    value={linkText}
                    onChange={this.handleLinkChange}
                  />
                </div> */}
                <div className="sms__footer">
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="btn centerAlign">
                        <Button
                          className="btn-success"
                          onClick={this.handelClick}
                        >
                          Select Farmer
                        </Button>
                        {error ? (
                          <p className="errorMsg1">
                            {' '}
                            {error}
                            {' '}
                          </p>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SmsSelectFarmer
            handlePrevious={this.handlePrevious}
            smsText={createSms}
            smsTitle={smsTitle}
            smsDesc={smsDetail}
            smsLink={linkText}
            anotherTab={anotherTab}
          />
        )}
      </React.Fragment>
    );
  }
}
export default SmsSetup;
