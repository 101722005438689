import React from 'react';
import Main from './components/common/main';
import LanguageContext from './components/common/languageContext';
import languages from './translate';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      language: 'eng',
      changeLanguage: this.changeLanguage,
    };
  }

  changeLanguage = (ev) => {
    console.log('afsfsa');
    this.setState({
      language: ev === 'eng' ? 'eng' : languages.hindi,
    });
  };

  render() {
    const { changeLanguage, language } = this.state;
    // The entire state is passed to the provider
    return (
      <LanguageContext.Provider
        value={{ language, changeLanguage }}
        changeLanguage={changeLanguage}
      >
        <Main />
      </LanguageContext.Provider>
    );
  }
}
