import React, { Component } from 'react';
import { DropTarget } from 'react-dnd-cjs';
import Graph from 'react-graph-vis';
import GraphCard from './graphCard';
import '../../css/ivrs/ivrsSetup.scss';

const Types = {
  CARD: 'card',
};

const cardTarget = {
  drop(props, monitor) {
    console.log('props', props);
    const item = monitor.getItem();
    console.log('item>>>', item);
  },
};

const collect = (connect, monitor) => ({
  // Call this function inside render()
  // to let React DnD handle the drag events:
  connectDropTarget: connect.dropTarget(),
  // You can ask the monitor about the current drag state:
  isOver: monitor.isOver(),
});

class GraphCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  initNetworkInstance = (networkInstance) => {
    console.log('networkInstance', networkInstance);
    this.network = networkInstance;
  };

  select = (event) => {
    const { edges } = event;
    const { graph, cards } = this.props;
    // const notSelectedEdges = graph.edges.filter((edge) => {
    //   if (edge.id !== edges[0]) {
    //     return graph.edges;
    //   }
    // });
    const notSelectedEdges = graph.edges.filter(edge => edge.id !== edges[0]);
    console.log('notSelectedEdges', notSelectedEdges);
    const selectedEdge = graph.edges.filter(edge => edge.id === edges[0]);
    if (selectedEdge.length > 0) {
      const selectedEdgeId = selectedEdge[0].from;
      const selectedEdgeLabel = selectedEdge[0].label;
      const selectedCardEdge = cards.find(o => o.id === selectedEdgeId);
      console.log(
        'selectedCardEdgeCopy',
        selectedCardEdge,
        notSelectedEdges,
        selectedEdgeLabel,
      );
      // handleDeleteEdge(notSelectedEdges, selectedCardEdge, selectedEdgeLabel);
    }
  };

  zoom = () => {
    if (this.network.getScale() !== 1.2) this.network.moveTo({ scale: 1.2 });
  };

  afterDrawing = () => {
    const { graph } = this.props;
    const { nodes } = graph;
    this.placeOverlay('network', nodes);
    // console.log('ctx',ctx);
    // let dataURL = ctx.canvas.toDataURL();
    // console.log('dataUrl',dataURL);
  };

  placeOverlay = (elementId, nodes) => {
    console.log('plaaaa', elementId, nodes);
    const nodeIds = [];
    console.log('nodes', nodes);
    for (let i = 0; i < nodes.length; i += 1) {
      const temp = nodes[i].id;
      nodeIds.push(temp);
    }
    const pos = this.network.getPositions(nodeIds); // return x,y position in canvas space
    console.log('pos', pos);
    const domCoordsList = [];
    // const nodeId = Object.keys(pos);
    // for (let i = 0; i < nodeId.length; i += 1) {
    //   domCoordsList.push(
    //     this.network.canvasToDOM({ x: pos[i].x, y: pos[i].y }),
    //   );
    // }
    Object.keys(pos).forEach((key) => {
      domCoordsList.push(
        this.network.canvasToDOM({ x: pos[key].x, y: pos[key].y }),
      );
    });
    // for (const nodeId in pos) {
    //   console.log('nodeId',nodeId)
    //   if (pos.hasOwnProperty(nodeId)) {
    //     domCoordsList.push(
    //       this.network.canvasToDOM({ x: pos[nodeId].x, y: pos[nodeId].y }),
    //     );
    //   }
    // }
    console.log('domCoordsList', domCoordsList);
    console.log('edddddddddd', this.network.getSelectedEdges());
    // let selectedEdges;
    // selectedEdges = this.network.getSelectedEdges()
    // console.log('seeee',selectedEdges)
    // to handle graph card inner design
    const element = document.getElementById(elementId);
    const elements = [];
    for (let i = 1; i < element.children.length; i += 1) {
      elements.push(element.children[i]);
    }
    for (let i = 0; i < elements.length; i += 1) {
      // for undo
      if (domCoordsList[i]) {
        elements[i].style.left = `${domCoordsList[i].x
          - elements[i].clientWidth / 2}px`; // move the content to left as per the position of drop card
        elements[i].style.top = `${domCoordsList[i].y
          - elements[i].clientHeight / 2}px`; // move the content to top as per the position of drop card
      }
      if (domCoordsList[i].y - elements[i].clientWidth / 2 < -15) {
        elements[i].style.top = '-9999px';
        elements[i].style.left = '-9999px';
        elements[i].style.position = 'absolute';
      }
      if (domCoordsList[i].x - elements[i].clientWidth / 2 < 0) {
        elements[i].style.top = '-9999px';
        elements[i].style.left = '-9999px';
        elements[i].style.position = 'absolute';
      }
      if (domCoordsList[i].x - elements[i].clientHeight / 2 > 1170) {
        elements[i].style.top = '-9999px';
        elements[i].style.left = '-9999px';
        elements[i].style.position = 'absolute';
      }
      if (domCoordsList[i].y - elements[i].clientHeight / 2 > 516) {
        elements[i].style.top = '-9999px';
        elements[i].style.left = '-9999px';
        elements[i].style.position = 'absolute';
      }
    }
  };

  handleConnection = (value, card) => {
    const { onConnection } = this.props;
    onConnection(value, card);
  };

  render() {
    const events = {
      select: this.select,
      zoom: this.zoom,
      afterDrawing: this.afterDrawing,
    };

    const {
      connectDropTarget,
      graph,
      options,
      cards,
      minimiseNode,
      deleteCard,
      deleteMedia,
      onClickPlus,
      openSettings,
      updateCardName,
    } = this.props;
    return connectDropTarget(
      <div
        id="network"
        style={{
          height: '400px',
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <Graph
          graph={graph}
          options={options}
          events={events}
          getNetwork={this.initNetworkInstance}
        />
        {cards.map(card => (
          <GraphCard
            key={card.id}
            card={card}
            onConnection={this.handleConnection}
            reDraw={nodeId => minimiseNode(nodeId)}
            onDeleteCard={nodeId => deleteCard(nodeId)}
            onDelete={(index, cardId) => deleteMedia(index, cardId)}
            onClickPlus={(value, nodeId) => onClickPlus(value, nodeId)}
            onOpenSettings={(value, openCard) => openSettings(value, openCard)}
            updateCardName={(cardId, cardName) => updateCardName(cardId, cardName)
            }
          />
        ))}
      </div>,
    );
  }
}

export default DropTarget(Types.CARD, cardTarget, collect)(GraphCanvas);
